import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import Widget from '../widget';
import { MatMenuTrigger } from '@angular/material/menu';
import { ThemePalette } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import moment from 'moment';
import { PopupDeleteComponent } from '../../shared/popup-delete/popup-delete.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { verify } from 'crypto';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';

@Component({
  selector: 'app-operation-monitor',
  templateUrl: './operation-monitor.component.html',
  styleUrls: ['./operation-monitor.component.scss', './ops-monitor-media.component.scss']
})
export class OperationMonitorComponent  extends Widget implements OnInit {
  static override key = 101001;

  @ViewChildren('scrollItem') scrollItems!: QueryList<ElementRef>;
  @ViewChild('customTemplate') customTemplate: any;
  
  color: ThemePalette = 'primary';
  loader = false;
  rowData:any = [];
  currentlyOpenedItemIndex = -1;
  disabled = false;
  verify_checked_flg = false;
  allcommentData:any;
  allCommentsWithoutGroup:any = [];
  employee_key:any;
  commentData:any = {};
  subFiles:any;
  groupEditIndex:any;
  validationForForm = false;

  searchValue = ''
  editedValue: string = '';
  edit: any = false
  editIndex: any;
  subFilesName:any;
  selectedKpi:any = [];
  rowDataWithoutGroup:any = [];
  files:any;
  apiError:any;
  messageVerifyGroups:any;

  constructor(public deviceDetectionService: DeviceDetectionService) {
    super();
  }

  scrollBottom() {
    const itemsArray = this.scrollItems.toArray();
    const lastItem = itemsArray[itemsArray.length - 1];

    if (lastItem) {
      lastItem.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  // Onload
  override ngOnInit(): void {
    super.ngOnInit();

    var empkey: any = sessionStorage.getItem("employee_key");
    this.employee_key = JSON.parse(empkey);

    this.item.config["list_of_api"] = {'api_key' : '100700'};

    // Header Code 
    this.item.config['widget_header_config'].map((m:any)=> {
      if(m.value=='legends') {
        m['legends_data'] = [{'name':'NOT VERIFIED', 'icon':'ops_no_comments'},{'name':'COMMENTED', 'icon':'ops_comments'},{'name':'VERIFIED', 'icon': 'ops_verified'}]
      }
    });

    this.subs.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loader = false;
        this.loadApiData();
      })
    )
  }

  // LoadAPI Data
  loadApiData() {

    let api = this.item.config["list_of_api"] || {};

    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      const obj = {api_key: api.api_key}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = 'D';
  
      this.subs.push(
        this.httpClient.post(`${environment.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          
          if(res?.length>0) {
            this.rowDataWithoutGroup = res;
            // Select All Comments
            let postdata: any = {
              'report_typ': 'S',
              'comment_typ': 'DOM'
            }
            this.loadCommentsData(postdata);
          } else {
            this.rowDataWithoutGroup = [];
            this.rowData = [];
            this.loader = true;
          }
        },(err:any)=> {
          this.rowDataWithoutGroup = [];
          this.rowData = [];
          this.loader = true;
        })
      )
    };
  };

  loadCommentsData(postdata:any) {
    let defaultQuery: any = { comment_typ: 'DOM', api_key: "100132"};
    let query = Object.assign({}, defaultQuery, postdata, this.filterService.baseQuery);
    query['dashboard_key']= this.dashboardService.dashboardKey;

    this.subs.push(this.filterService.commentsQuery(query).subscribe((res: any) => {
      const messages = res ? res : [];

      // Select and Insert Query
      if(query.report_typ=='S' || query.report_typ=='I') {
        
        if(query.report_typ=='S') {
          this.allCommentsWithoutGroup = messages;

          let dayta = this.mergingKpiWithComments(messages, this.rowDataWithoutGroup);
          let rowData:any = this.createDataSet(dayta);
          this.rowData = [...rowData];
          this.loader = true;
        } else {

          const findLastElement = [...messages];
          this.allCommentsWithoutGroup.push(findLastElement.pop());

          let dayta = this.mergingKpiWithComments(this.allCommentsWithoutGroup, this.rowDataWithoutGroup);
          let rowData:any = this.createDataSet(dayta);
          this.flatRowData(rowData);

          this.selectedKpi = this.allCommentsWithoutGroup.filter((f:any)=> f.kpi_key == query.kpi_key && f.brand_key == query.brand_key);
          this.allcommentData = this.messageGrouping(this.selectedKpi);
        }

        setTimeout(() => {
          this.scrollBottom();
        }, 100);
      } else {
        // user_comments_key
        const fndIdx = this.allCommentsWithoutGroup.findIndex((f:any)=> f.user_comments_key==query.user_comments_key);
        const fndValue = this.allCommentsWithoutGroup[fndIdx];
        
        if(fndIdx!=-1 && fndValue) {
          if(query.report_typ=='D') {
            this.allCommentsWithoutGroup.splice(fndIdx, 1);
            let dayta = this.mergingKpiWithComments(this.allCommentsWithoutGroup, this.rowDataWithoutGroup);
            let rowData:any = this.createDataSet(dayta);
            this.flatRowData(rowData);
            this.allcommentData = this.messageGrouping(this.selectedKpi);
          } else if (query.report_typ=='U') {  
            this.allCommentsWithoutGroup[fndIdx]['comments'] = query.comments;
            this.allCommentsWithoutGroup[fndIdx]['update_timestamp'] = moment().format('MM-DD-YYYY');
          }

          this.selectedKpi = this.allCommentsWithoutGroup.filter((f:any)=> f.kpi_key == query.kpi_key && f.brand_key == query.brand_key);
          this.allcommentData = this.messageGrouping(this.selectedKpi);

        } else {
          this.selectedKpi = [];
          this.allcommentData = [];
        }
      } 

      // debugger
      // For Verification API 100701;
      let objVerify:any = {"report_typ": 'D'};
      if(query.report_typ=='S') {
        this.loadVerificationApi(objVerify);
      } else if(query.report_typ=='I' && query?.ops_verify_flg) {
        if(this.messageVerifyGroups.length>0) {
          const fndVerify = this.messageVerifyGroups.find((f:any)=> f.kpi_key == query.kpi_key && f.brand_key == query.brand_key);
          if(fndVerify) {
            objVerify["report_typ"] = 'U';
            objVerify["ops_verification_log_key"] = fndVerify?.ops_verification_log_key || '';
          } else {
            objVerify["report_typ"] = 'I';
          }
        } else {
          objVerify["report_typ"] = 'I';
        }

        objVerify['verify_flg'] = this.verify_checked_flg ? 'Y' : 'N';
        objVerify['kpi_key'] = query?.kpi_key || '';
        objVerify['period'] = this.filterService.baseQuery?.from_date;
        this.loadVerificationApi(objVerify);
      }


      this.searchValue = '';
      document.getElementById('comment-du-ops')?.focus();

      // setTimeout(() => {
      //   this.scrollBottom();
      // }, 100);
      
      // Insert Snackbar
      if(query.report_typ=='I') {
        this.dialogService.customSnackBar('success', 'green', 'Message Added Successfully');
      }
      // Delete Snackbar
      if(query.report_typ == 'D') {
        this.dialogService.customSnackBar('success', 'green', 'Message Deleted Successfully');
      }
      // Update Snackbar
      if(query.report_typ == 'U') {
        this.dialogService.customSnackBar('success', 'green', 'Message Updated Successfully');
      }

    }, error => {
      this.loader = true;

      const obj:any = {'D': 'deleting', 'I': 'inserting', 'U': 'updating'};
      if(query.report_typ=='I') {
        this.apiError = 'it was failed while ' + obj[query.report_typ] + ' a comments'
      }
    }))
  }

  // Flat the Row Data UI
  flatRowData(rowData:any) {
    this.rowData.forEach((res:any, idx:any)=> {
      if(res.key == this.files.key) {
        if(res?.value?.length>0) {
          res.value.map((m:any, i:any)=> {
            res.taken_action = rowData[idx].taken_action; 
            res.flag_action = rowData[idx].flag_action;
            m.msg_icon = rowData[idx].value[i].msg_icon;
            m.verification_flg = rowData[idx].value[i].verification_flg;
            // this.verify_checked_flg = m.verification_flg == 'Y' ? true : false;
          })
        }
      }
    })
  }

  mergingKpiWithComments(messages:any, rowData:any) {
    // if(rowData.length==0) return [];
    
    return rowData.map((m:any)=> {
      let fndData = messages.find((o:any)=> o.brand_key == m.brand_key && o.kpi_key == m.kpi_key);

      if(fndData) {
        let sorted_obj = _.orderBy(messages, ['insert_timestamp'], ['desc']);
        let fndLatestFlag = sorted_obj.find((f:any) => f.ops_verify_flg != null && f.brand_key == m.brand_key && f.kpi_key == m.kpi_key);

        if(fndLatestFlag) {
          m['verification_flg'] = fndLatestFlag.ops_verify_flg;
        } else {
          m['verification_flg'] = 'N';
        }
        m['comments_flg'] = true;
      } else {
        m['verification_flg'] = 'N'
        m['comments_flg'] = false;
      }

      return m
    });
  }

  createDataSet(rowData:any) {
    const groupedData = _.groupBy(rowData,'kpi_type');
    let rowDataKeyValue = Object.keys(groupedData).map(key => ({ key, value: groupedData[key] || [] }));

    rowDataKeyValue.map((obj:any)=> {
      obj['total_action'] = obj.value ? obj.value.length : 0;
      let sum = 0;
      let flag_action = true;

      if(obj.value?.length>0) {
        for (let index = 0; index < obj.value.length; index++) {
          const element = obj.value[index];
          
          if(element.verification_flg=='Y') {
            sum += 1;
          }

          if(element.status_flg == 'F' && element.verification_flg=='N') {
            flag_action = false;
          }

          
          if(element.comments_flg) {
            element['msg_icon'] = 'ops_comments'
          } else {
            element['msg_icon'] = 'ops_no_comments'
          }

          if(element.verification_flg=='Y') {
            element['msg_icon'] = 'ops_verified';
          }

        }
      }
      obj['taken_action'] = sum;
      obj['flag_action'] = flag_action;
    })

    return rowDataKeyValue;
  } 

  messageGrouping(messages:any) {
    let sortedAllcommentData = _.orderBy(messages, ['insert_timestamp'], ['asc']);

    const groupedData = sortedAllcommentData.reduce((acc, obj) => {

      let date = '';
      if (obj?.insert_timestamp) {
        date = moment(obj.insert_timestamp).format('DD-MMM-YYYY')
      } else if (obj.insert_time) {
        date = moment(obj.insert_time).format('DD-MMM-YYYY');
      } else {
        date = moment().format('DD-MMM-YYYY');
      }

      // Check if the date key already exists in accumulator, if not create new array
      if (!acc[date]) {
        acc[date] = [];
      }
      // Push the current object to the array of that date key
      acc[date].push(obj);
      return acc;
    }, {});

    return Object.keys(groupedData).map(key => ({ key, value: groupedData[key] || [] }));
  }

  getSubFileDetails(file:any, sub:any) {
    this.edit = false;
    this.subFiles = sub;
    this.files = file;
    this.subFilesName = sub.kpi_name;
    this.verify_checked_flg = sub.verification_flg == 'Y' ? true : false;
    
    this.searchValue = '';
    document.getElementById('comment-du-ops')?.focus();

    this.selectedKpi = this.allCommentsWithoutGroup.filter((f:any)=> f.kpi_key == sub.kpi_key && f.brand_key == sub.brand_key);
    this.allcommentData = this.messageGrouping(this.selectedKpi);
    setTimeout(() => {
    this.scrollBottom();
    }, 100);
  }

  editedChanges(updatedDate: any) {
    if(updatedDate == null) return false;
    return true;
  }

  getMSGHtml(data:any){
    data= data.replaceAll(' ','&nbsp;')
    data=data.replaceAll('\n','<br>')
    return data
  }
  
  validationForm($event:any) {
    if(!this.searchValue) this.validationForForm = true;
    if(this.searchValue) this.validationForForm = false;
  }

  insertMessage(searchValue: any, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.edit = false;
    if(!searchValue) {
      this.validationForForm = true;
      return
    } 

    if(searchValue) this.validationForForm = false;

    let postdata: any = {
      'report_typ': 'I',
      'comments': searchValue,
      'period': this.filterService.baseQuery?.from_date,
      'kpi_key' : this.subFiles ? this.subFiles.kpi_key : ''
    }

    this.loadCommentsData(postdata);
    this.searchValue = '';
  }

  insertMessageWithVerify(searchValue:any, $event:any) {
    // debugger
    this.edit = false;
    $event.preventDefault();
    $event.stopPropagation();

    if(!searchValue) {
      this.validationForForm = true;
      return
    } 

    if(searchValue) this.validationForForm = false;
    
    let postdata: any = {
      'report_typ': 'I',
      'comments': searchValue,
      'period': this.filterService.baseQuery?.from_date,
      'kpi_key' : this.subFiles ? this.subFiles.kpi_key : '',
      'ops_verify_flg' : this.verify_checked_flg ? 'N' : 'Y'
    }

    this.verify_checked_flg = postdata.ops_verify_flg == 'Y' ? true : false;

    this.loadCommentsData(postdata);
    this.searchValue = '';
  }

  closeMenu() {
    this.searchValue = '';
    this.editedValue = '';
    this.validationForForm = false;
    this.trigger?.closeMenu();
  }

  loadVerificationApi(checkedObj:any) {

    let defaultQuery: any = { comment_typ: 'DOM', api_key: "100701"};
    let query = Object.assign({}, defaultQuery, this.filterService.baseQuery, checkedObj);
    
    this.subs.push(this.filterService.commentsQuery(query).subscribe((res: any) => {
      const messages = res ? res : [];
      
      if(query.report_typ=='D') {
        if(messages.length>0) {
          this.messageVerifyGroups = messages;
        } else {
          this.messageVerifyGroups = [];
        }
      }

    }, err => {
      if (query.report_typ=='I' && err.status==200) {
        this._snackBar.open('Verification successfully added', '', {
          duration: 1000
        });
      }
    }));
  }

  setOpened(itemIndex: number) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex: number) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  getMenu(event:any, message :any, i:any, idx:any) {
    // debugger
    this.commentData['data'] = message;
    this.commentData['i'] = i;
    this.commentData['idx'] = idx;
    
    let po: DialogPosition = { left: (event.clientX-110) + 'px', top: event.clientY + 'px' };
    this.dialogServiceMat.closeAll();
    this.dialogServiceMat.open(this.customTemplate, { hasBackdrop: true, position: po, backdropClass: 'g-transparent-backdrop'});
  }

  sizeOftheDeletePopup() {
    let width = ''
    return width
  }
  
  editComment(message: any, event: any, i: any) {
    // debugger
    this.editedValue = this.commentData.data.comments
    this.editIndex = this.commentData.i
    this.groupEditIndex = this.commentData.idx
    this.edit = true;

    this.scrollToBlue(this.commentData.data.employee_key+i)
  }
  
  scrollToBlue(id:any) {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }, 500);
  }

  updateMessage(message:any, i:any, idx:any) {
    
    if(!this.editedValue.trim()) { this.editedValue = ''; return  }
    if (this.editedValue == '') return
    
    let postdata = {
      "user_comments_key": message.user_comments_key,
      "report_typ":  "U",
      "comments": this.editedValue,
      "kpi_key": message.kpi_key,
      "ops_verify_flg": message?.ops_verify_flg ? message.ops_verify_flg : null
    }
    
    this.loadCommentsData(postdata);
    
    this.edit = false;
  }
  
  // Delete Dialoag for YES or NO
  deleteDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {

    let dialogRef = this.dialogServiceMat.open(PopupDeleteComponent, {
      width: this.sizeOftheDeletePopup(),
      maxWidth: '90vw',
      disableClose: true,
      panelClass: "du-custom-modalbox",
      data:'comment'
    });

    dialogRef.afterClosed().subscribe((value) => {
      
      if(value == '1') {
        this.deleteComment()
      }
    });

  }

  // Delete Comment
  deleteComment() {
    let data = this.commentData['data'];
    let postdata = {
      "report_typ":  "D",
      "user_comments_key": data.user_comments_key,
      "kpi_key": data.kpi_key,
      "brand_key": data.brand_key
    }

    this.loadCommentsData(postdata);
  }

  // Time Stamp
  getTimeStamp(obj: any) {
    return moment(obj.insert_timestamp).format('LT'); 
  }
}
