import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { AgGridAngular } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
export class TodoItemNode {
  children!: TodoItemNode[];
  item: any;
  id: any;

}
export class TodoItemFlatNode {
  item: any = '';
  level: number = 0;
  expandable: boolean = false;
  id: any;
}


@Component({
  selector: 'app-pdf-export-popup',
  templateUrl: './pdf-export-popup.component.html',
  styleUrls: ['./pdf-export-popup.component.scss']
})
export class PdfExportPopupComponent implements OnInit, OnChanges {
  @Input() agGridApi: any;
  @Input() agColumnApi: any;
  @Input() rowData: any;
  @Input() groupData: any;
  @Input() columnDefs: any;
  @Input() columnDefs2: any;
  @Input() table: any;
  @Input() paymentGridData: any;
  data: any;
  PDF_HEADER_COLOR = "#f8f8f8";
  PDF_INNER_BORDER_COLOR = "#dde2eb";
  PDF_OUTER_BORDER_COLOR = "#babfc7";
  PDF_LOGO = "https://raw.githubusercontent.com/AhmedAGadir/ag-grid-todo-list-react-typescript/master/src/assets/new-ag-grid-logo.png";
  PDF_PAGE_ORITENTATION = "landscape";
  PDF_HEADING_ALIGNMENT = "center";
  PDF_WITH_HEADER_IMAGE = true;
  PDF_WITH_FOOTER_PAGE_COUNT = true;
  PDF_HEADER_HEIGHT = 25;
  PDF_HEADING = "Payment Calculator"
  PDF_ROW_HEIGHT = 15;
  PDF_ODD_BKG_COLOR = "#fcfcfc";
  PDF_HEADINGCOLOR = "black";
  PDF_EVEN_BKG_COLOR = "#ffffff";
  PDF_WITH_CELL_FORMATTING = true;
  PDF_WITH_COLUMNS_AS_LINKS = true;
  PDF_SELECTED_ROWS_ONLY = false;
  PDF_HEADER_LOGO = true;
  PDF_HEADER_NAME = true;
  PDF_FOOTER = true;
  DETAIL_DATA = 'Detail';
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  // sources
  treeControl!: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener!: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  // checked
  checklistSelection = new SelectionModel<TodoItemFlatNode>(
    true
  );
  TREE_DATA: any = [
    {
      name: 'Select All',
      children: [{ name: 'Apple' }, { name: 'Banana' }, { name: 'Fruit loops' }],
    },
  ];
  pdfPreviewData: SafeResourceUrl | undefined;
  showPreview: boolean = false
  @ViewChild('paymentGrid') paymentGrid!: AgGridAngular
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private sessionService: SessionService, private http: HttpClient, public filterService: FilterService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  toggle: boolean = false

  buildFileTree(obj: any, level: number): TodoItemNode[] {
    if (!obj) {
      return [];
    }
    return Object.keys(obj).sort().reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, nodeData: TodoItemFlatNode) => nodeData.expandable;

  hasNoContent = (_: number, nodeData: TodoItemFlatNode) =>
    nodeData.item === '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  brandName: any;
  brandLogo: any;
  dataChange(data: any) {
    if (data == 'Detail') {
      this.data = this.rowData
    } else {
      this.data = this.groupData
    }
    this.FormattingData()
  }
  FormattingData() {
    this.data = this.data.map((row: any) => {
      const formattedRow: any = {};
      this.columnDefs.forEach((colDef: any) => {
        const colKey = colDef.field;
        formattedRow[colKey] = this.formatCellValue(colDef, row[colKey]);
      });

      if (row.callRecords) {
        formattedRow.callRecords = row.callRecords.map((callRecord: any) => {
          const formattedCallRecord: any = {};
          this.columnDefs2.forEach((colDef: any) => {
            const colKey = colDef.field;
            formattedCallRecord[colKey] = this.formatCellValue2(colDef, callRecord[colKey]);
          });
          return formattedCallRecord;
        });
      }

      return formattedRow;
    });
  }
  ngOnInit(): void {
    this.data = this.rowData
    this.FormattingData()
    //  this.data = this.data.map((row: any) => {
    //   const formattedRow: any = {};
    //   this.columnDefs.forEach((colDef: any) => {
    //     const colKey = colDef.field;
    //     formattedRow[colKey] = this.formatCellValue(colDef, row[colKey]);
    //   });

    //   if (row.callRecords) {
    //     formattedRow.callRecords = row.callRecords.map((callRecord: any) => {
    //       const formattedCallRecord: any = {};
    //       this.columnDefs2.forEach((colDef: any) => {
    //         const colKey = colDef.field;
    //         formattedCallRecord[colKey] = this.formatCellValue2(colDef, callRecord[colKey]);
    //       });
    //       return formattedCallRecord;
    //     });
    //   }

    //   return formattedRow;
    // });



    this.columnDefs.map((i: any) => { return i.id = Math.random() })
    this.columnDefs2?.map((i: any) => { return i.id = Math.random() })
    this.TREE_DATA = []
    if (this.columnDefs2?.length < 1) {
      this.TREE_DATA.push({
        item: 'Select All',
        Checked: false,
        children: this.columnDefs.map((i: any) => {
          return {
            item: i.headerName,
            checked: false,
            id: i.id
          }
        }),
      })
    }
    else {
      this.TREE_DATA.push({
        item: 'Select Record Fields',
        Checked: false,
        children: this.columnDefs.map((i: any) => {
          return {
            item: i.headerName,
            checked: false,
            id: i.id

          }
        }),
      })
      if (this.columnDefs2) {
        this.TREE_DATA.push({
          item: 'Select Defect Column Fields',
          Checked: false,
          children: this.columnDefs2?.map((i: any) => {
            return {
              item: i.headerName,
              checked: false,
              id: i.id

            }
          }),
        })
      }

    }
    this.dataSource.data = this.TREE_DATA;
    this.brandName = this.sessionService.getClient().client_nm ? this.sessionService.getClient().client_nm : sessionStorage.getItem('brandName')
    this.brandLogo = this.sessionService.getClintLogo()
    this.fetchDataFromS3()
  }

  fetchDataFromS3(): void {

    const headers = {
      'Cache-Control': 'no-cache'
    };

    this.http.get(this.brandLogo, { headers }).subscribe(
      (data: any) => {
        console.log('Data from S3:', data);
        // You can handle the retrieved data here
      },
      (error: any) => {
        console.error('Error fetching data from S3:', error);
        console.log(error.error.text)
        // Handle errors here
      }
    );
  }

  // Helper function to format cell value using the value formatter
  formatCellValue(colDef: any, cellValue: any): any {
    if (colDef.field == "tran_date" && cellValue != '') {
      return moment(cellValue).format('MM/DD/YYYY')
    }
    if (colDef.headerName.includes("Date") && cellValue != ''  && cellValue != null) {
      return moment(cellValue).format('MM/DD/YYYY')
    }
    if (colDef.headerName.includes("File Name") && cellValue != '') {
      return cellValue.replaceAll('_'," ")
    }
    if (colDef.valueFormatter) {
      return colDef.valueFormatter({ value: cellValue });
    }
  
    return cellValue;
  }

  formatCellValue2(colDef: any, cellValue: any): any {
    if (colDef.valueFormatter) {
      return colDef.valueFormatter({ value: cellValue });
    }
    return cellValue;
  }

  ngOnChanges(): void {
  }

  editName: boolean = true

  editPdfTitle() {
    this.editName = false
  }

  submitFormHandler(event: any) {
    event.preventDefault();
    const printParams = {
      PDF_HEADING: this.PDF_HEADING,
      PDF_HEADINGCOLOR: this.PDF_HEADINGCOLOR,
      PDF_HEADER_COLOR: this.PDF_HEADER_COLOR,
      PDF_HEADING_ALIGNMENT: this.PDF_HEADING_ALIGNMENT,
      PDF_INNER_BORDER_COLOR: this.PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR: this.PDF_OUTER_BORDER_COLOR,
      PDF_LOGO: this.PDF_LOGO,
      PDF_PAGE_ORITENTATION: this.PDF_PAGE_ORITENTATION,
      PDF_WITH_HEADER_IMAGE: this.PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT: this.PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_HEADER_HEIGHT: this.PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT: this.PDF_ROW_HEIGHT,
      PDF_ODD_BKG_COLOR: this.PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR: this.PDF_EVEN_BKG_COLOR,
      PDF_WITH_CELL_FORMATTING: this.PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS: this.PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY: this.PDF_SELECTED_ROWS_ONLY
    };

    console.log(printParams, 'params')
  }

  onPDFColorChange(color: string) {
    this.PDF_HEADINGCOLOR = color;
  }
  onOddColorChange(color: string) {
    this.PDF_ODD_BKG_COLOR = color;
  }

  onEvenColorChange(color: string) {
    this.PDF_EVEN_BKG_COLOR = color;
  }

  parseInt(event: any) {
    return parseInt(event);
  }
  Value(event: Event) {
    return (event.target as HTMLInputElement).value;; //this wont work
  }
  toggleOrientation(event: any) {
    console.log(event)
    if (event.value == 'landscape') {
      this.PDF_PAGE_ORITENTATION = 'landscape'
    } else {
      this.PDF_PAGE_ORITENTATION = 'portrait'
    }
  }
  AlignmentValue(event: Event) {
    return (event.target as HTMLInputElement).value;; //this wont work
  }
  Checked(event: Event) {
    return (event.target as HTMLInputElement).checked; //this wont work
  }

  async downloadAsPDF(type?: any) {
    let newColumns: any = []
    let newColumns2: any = []
    // this.FormattingData()

    this.checklistSelection.selected.find((i: any) => {
      this.columnDefs.find((j: any) => {
        if (i.item == j.headerName && i.id == j.id) {
          newColumns.push(j)
        }
      })
    })

    this.checklistSelection.selected.find((i: any) => {
      this.columnDefs2.find((j: any) => {
        if (i.item == j.headerName && i.id == j.id) {
          newColumns2.push(j)
        }
      })
    })

    // const pdfTable = this.table.nativeElement;
    let items: any = []

    if (newColumns2.length > 0 && newColumns.length > 0) {
      for (let i = 0; i < this.data.length; i++) {
        const header = newColumns.map((col: any) => col.headerName?{ text:col.headerName,  alignment:col.cellStyle?.textAlign || 'left'}:"");
        let body = this.data.map((row: any) => newColumns.map((col: any) => row[col.field]?{ text: row[col.field],  alignment:col.cellStyle?.textAlign || 'left',color: '#101D42'||col.cellStyle.color }:''));
        body = body.splice(i, 1)
        // Add a dummy header name (space character) for the empty header row
        const emptyHeaderRow = newColumns.map((col: any) => '  ');
        // const body = this.columnDefs.map((col: any) => this.data[i][col.field])
        //       'noBorders': No borders are shown between the cells.
        // 'headerLineOnly': Only the header row has borders.
        // 'lightHorizontalLines': Horizontal lines are shown between rows, including the header row.
        // 'lightVerticalLines': Vertical lines are shown between columns.
        // 'lightGrid': Both horizontal and vertical lines are shown between rows and columns, including the header row.
        // 'grid': Similar to 'lightGrid', but with thicker lines.
        // 'headerLineOnly': Similar to 'grid', but only the header row has borders.
        // 'headerDarkVerticalLines': Vertical lines are shown between columns, and the header row has darker borders.
        // 'striped': Alternating rows are shaded with a lighter color for better readability.
        // 'borderless': The table has no borders, including the header row.
        if (i == 0) {
          const table = {
            table: {
              headerRows: 1,
              widths: newColumns.map((_: any) => 'auto'),
              body: [header, ...body],
            },
            layout: 'lightHorizontalLines'
          };
          items.push(table)
        } else {
          const table = {
            table: {
              headerRows: 1,
              widths: newColumns.map((_: any) => 'auto'),
              body: [header, ...body],
            },
            layout: 'lightHorizontalLines',

          };
          items.push(table)
        }

        if (this.data[i].callRecords) {
          let val = this.data[i].callRecords
          const header1 = newColumns2.map((col: any) => col.headerName);
          let body1: any = []
          if (val.length == 0) {
            body1[0] = []
            newColumns2.forEach((ele: any) => {
              body1[0].push('null')

            });
          } else {

            body1 = this.data[i].callRecords.map((row: any) => newColumns2.map((col: any) => row[col.field] ? row[col.field] : ''));
          }
          const data = {
            table: {
              headerRows: 1,
              widths: newColumns2.map((_: any) => 'auto'),
              body: [header1, ...body1],
            },
            layout: {
              hLineWidth: () => 1,
              vLineWidth: () => 1,
              hLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
              vLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
              paddingLeft: () => 5,
              paddingRight: () => 5,
              paddingTop: () => 5,
              paddingBottom: () => 5,
              // defaultBorder: false,
              fillColor: function (rowIndex: number, node: any, columnIndex: number) {
                return rowIndex === 0 ? '#CCCCCC' : null;
              },
            },
          }

          items.push(data)
        }
      }

      const svgDataURL = this.getSVGDataURL(this.brandLogo);

      console.log('SVG Data URL:', svgDataURL);

      // // Convert SVG to base64 image
      // const itemImg = this.svgToBase64(this.brandLogo)
      // console.log(itemImg, '-----------')

      let brand: any = JSON.parse(sessionStorage.getItem("SelectedBrandName") || '')
      let source: any = JSON.parse(sessionStorage.getItem("SelectedSourceName") || '') || ''
      let Quarter = this.filterService.baseQuery.from_date
      const documentDefinition: any = {
        pageOrientation: this.PDF_PAGE_ORITENTATION as PageOrientation,
        defaultStyle: {
          fontSize: this.PDF_PAGE_ORITENTATION == 'portrait' ? 8 : 10,
          color: this.PDF_PAGE_ORITENTATION === 'portrait' ? 'black' : 'black',
        },
        content: [

          {
            alignment: 'justify',
            columns: [
              [{
                width: '*',
                text: this.PDF_HEADER_NAME ? (brand.brand_name || '') : '', color: '#101D42', fontSize: 24, bold: true,
              },
              ], [

                { width: '*', text: source.source_nm.toUpperCase() + " Q" + moment(Quarter).quarter() + '-' + moment(Quarter).year(), alignment: 'center', color: '#101D42', fontSize: 16, bold: true },
              ],
              [this.PDF_HEADER_LOGO ? {
                // image: await this.getBase64ImageFromURL('/assets/logo.png'),
                image: await this.getBase64ImageFromURL(this.brandLogo),
                width: 150,
                // height: 50,
                alignment: 'right'
              } : {}
              ]]
          },
          { text: this.PDF_HEADING, alignment: this.PDF_HEADING_ALIGNMENT, color: this.PDF_HEADINGCOLOR, marginTop: this.PDF_HEADING_ALIGNMENT == 'left' ? 20 : 0, fontSize: 16, bold: true },
          { text: '\n\n' },
          ...items.flatMap((i: any, j: any, arr: any) => {
            // Determine font size and color based on table index
            const fontSize = this.PDF_PAGE_ORITENTATION == 'portrait' ? 14 : 14;
            const color = j % 2 === 0 ? 'blue' : 'blue';

            const elements: any[] = [
              {
                text: j % 2 === 0 ? `${i.table.body[1][0].text} - Record Details` : `Column Details`,
                // text: j % 2 === 0 ? `${i.table.body[1][0].text} - Record Details` : (this.data[i].callRecords > 0 ? `${i.table.body[1][0].text} - Record Details` : `Column Details`),
                // text: j % 2 === 0 ? `${i.table.body[1][0]} Group(${i.table.body[1][1]}):` : `${i.table.body[1][0]} Call Records:`,
                fontSize: fontSize,
                bold: true,
                color: color,
                // alignment: 'center',
              },
              i,
              { text: '\n\n' },
            ];

            // Apply pageBreak only to the last element when the index is odd and not the last item in the array
            // if ((j % 2 === 1 && j !== arr.length - 1)) {
            //   elements.push({ text: '\n\n', pageBreak: 'after' });
            // }
            return elements;
          })
        ],
        footer: (currentPage: number, pageCount: number) => {
          if (this.PDF_FOOTER) {
            return {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: 'center',
              fontSize: this.PDF_PAGE_ORITENTATION == 'landscape' ? 14 : 10,
              color: 'black',
              bold: true
            }
          } else return

        },

      };



      const dataUrl = await new Promise<string>((resolve) => {
        pdfMake.createPdf(documentDefinition).getDataUrl((result: string) => {
          resolve(result);
        });
      });

      // Now you can use the 'dataUrl' to display or download the PDF
      // For example, you can assign it to an iframe's src attribute to display it
      // const iframe = document.createElement('iframe');
      // iframe.src = dataUrl;
      // document.body.appendChild(iframe);

      // Set the data URL to the iframe source to display the PDF preview
      this.pdfPreviewData = this.domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);


      if (type == 'export') {
        const pdfDoc = pdfMake.createPdf(documentDefinition);
        // Generate the PDF as a data URL
        pdfDoc.getBlob((pdfBlob: Blob) => {
          // Set a custom file name for the generated PDF
          // const fileName = 'custom_filename.pdf';

          // Create a download link for the PDF
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(pdfBlob);
          downloadLink.download = this.PDF_HEADING ? this.brandName + "_" + this.PDF_HEADING : 'SCRAII_Pdf';

          // Trigger the download
          downloadLink.click();
        })
      }
    }


    else if (newColumns2.length > 0 && newColumns.length == 0) {
      for (let i = 0; i < this.data.length; i++) {
        const header = newColumns.map((col: any) => col.headerName ?{ text:col.headerName,  alignment:col.cellStyle?.textAlign || 'left'}:"");
        let body = this.data.map((row: any) => newColumns.map((col: any) => row[col.field]?{ text: row[col.field],  alignment:col.cellStyle?.textAlign || 'left',color:'#101D42' || col.cellStyle.color }:''));
        body = body.splice(i, 1)
        const emptyHeaderRow = newColumns.map((col: any) => '  ');
        if (this.data[i].callRecords) {
          let val = this.data[i].callRecords
          const header1 = newColumns2.map((col: any) => col.headerName);
          let body1: any = []
          if (val.length == 0) {
            body1[0] = []
            newColumns2.forEach((ele: any) => {
              body1[0].push('null')

            });
          } else {

            body1 = this.data[i].callRecords.map((row: any) => newColumns2.map((col: any) => row[col.field] ? row[col.field] : ''));
          }
          const data = {
            table: {
              headerRows: 1,
              widths: newColumns2.map((_: any) => 'auto'),
              body: [header1, ...body1],
            },
            layout: {
              hLineWidth: () => 1,
              vLineWidth: () => 1,
              hLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
              vLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
              paddingLeft: () => 5,
              paddingRight: () => 5,
              paddingTop: () => 5,
              paddingBottom: () => 5,
              fillColor: function (rowIndex: number, node: any, columnIndex: number) {
                return rowIndex === 0 ? '#CCCCCC' : null;
              },
            },
          }
          items.push(data)
        }
      }
      let brand: any = JSON.parse(sessionStorage.getItem("SelectedBrandName") || '')
      let source: any = JSON.parse(sessionStorage.getItem("SelectedSourceName") || '')
      let Quarter = this.filterService.baseQuery.from_date

      const documentDefinition: any = {
        pageOrientation: this.PDF_PAGE_ORITENTATION as PageOrientation,
        defaultStyle: {
          fontSize: this.PDF_PAGE_ORITENTATION == 'portrait' ? 8 : 10,
          color: this.PDF_PAGE_ORITENTATION === 'portrait' ? 'black' : 'black',
        },
        content: [

          {
            alignment: 'justify',
            columns: [
              [{
                width: '*',
                text: this.PDF_HEADER_NAME ? (brand.brand_name || '') : '', color: '#101D42', fontSize: 24, bold: true,
              },
              ], [
                { width: '*', text: source.source_nm.toUpperCase() + " Q" + moment(Quarter).quarter() + '-' + moment(Quarter).year(), alignment: 'center', color: '#101D42', fontSize: 16, bold: true },

              ],
              [this.PDF_HEADER_LOGO ? {
                // image: await this.getBase64ImageFromURL('/assets/logo.png'),
                image: await this.getBase64ImageFromURL(this.brandLogo),
                width: 150,
                // height: 50,
                alignment: 'right'
              } : {}
              ]]
          },
          { text: this.PDF_HEADING, alignment: this.PDF_HEADING_ALIGNMENT, color: this.PDF_HEADINGCOLOR, marginTop: this.PDF_HEADING_ALIGNMENT == 'left' ? 20 : 0, fontSize: 16, bold: true },
          { text: '\n\n' },
          ...items.flatMap((i: any, j: any, arr: any) => {
            // Determine font size and color based on table index
            const fontSize = this.PDF_PAGE_ORITENTATION == 'portrait' ? 14 : 14;
            const color = j % 2 === 0 ? 'blue' : 'blue';

            const elements: any[] = [
              {
                text: `Column Details`,
                // text: `${i.table.body[1][0]} Call Records:`,
                fontSize: fontSize,
                bold: true,
                color: color,
              },
              { text: '\n\n' },
              i,
              { text: '\n\n' },
            ];

            // Apply pageBreak only to the last element when the index is odd and not the last item in the array
            if ((j !== arr.length - 1)) {
              elements.push({ text: '\n\n', pageBreak: 'after' });
            }

            return elements;

          }),
        ],
        footer: (currentPage: number, pageCount: number) => {
          if (this.PDF_FOOTER) {
            return {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: 'center',
              fontSize: this.PDF_PAGE_ORITENTATION == 'landscape' ? 14 : 10,
              color: 'black',
              bold: true
            }
          } else return

        },
      };




      // Wrap the getDataUrl() method in a Promise to handle the callback
      const dataUrl = await new Promise<string>((resolve) => {
        pdfMake.createPdf(documentDefinition).getDataUrl((result: string) => {
          resolve(result);
        });
      });

      // Set the data URL to the iframe source to display the PDF preview
      this.pdfPreviewData = this.domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);


      if (type == 'export') {
        const pdfDoc = pdfMake.createPdf(documentDefinition);
        // Generate the PDF as a data URL
        pdfDoc.getBlob((pdfBlob: Blob) => {
          // Set a custom file name for the generated PDF
          // const fileName = 'custom_filename.pdf';

          // Create a download link for the PDF
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(pdfBlob);
          downloadLink.download = this.PDF_HEADING ? this.brandName + "_" + this.PDF_HEADING : 'SCRAII_Pdf';

          // Trigger the download
          downloadLink.click();
        })
      }
    }


    else if (newColumns2.length == 0 && newColumns.length > 0) {
      const header = newColumns.map((col: any) => col.headerName?{ text:col.headerName,  alignment:col.cellStyle?.textAlign || 'left'}:"");
      let body = this.data.map((row: any) => newColumns.map((col: any) => row[col.field] ? { text: row[col.field],  alignment:col.cellStyle?.textAlign || col.cellStyle?.['justify-content'] || 'left',color:'#101D42' ||col.cellStyle.color } : ''));
      const table = {
        table: {
          headerRows: 1,
          widths: newColumns.map((_: any) => 'auto'),
          body: [header, ...body],
        },
        layout: {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
          vLineColor: () => 'black', // Set the border color here (e.g., 'blue', '#0000ff', 'rgb(0, 0, 255)')
          paddingLeft: () => 5,
          paddingRight: () => 5,
          paddingTop: () => 5,
          paddingBottom: () => 5,
          fillColor: function (rowIndex: number, node: any, columnIndex: number) {
            return rowIndex === 0 ? '#CCCCCC' : null;
          },
        },
      };
      items.push(table)
      let brand: any = JSON.parse(sessionStorage.getItem("SelectedBrandName") || '')
      let source: any = JSON.parse(sessionStorage.getItem("SelectedSourceName") || '')
      let Quarter = this.filterService.baseQuery.from_date
      const documentDefinition: any = {
        pageOrientation: this.PDF_PAGE_ORITENTATION as PageOrientation,
        defaultStyle: {
          fontSize: this.PDF_PAGE_ORITENTATION == 'portrait' ? 8 : 10,
          color: this.PDF_PAGE_ORITENTATION === 'portrait' ? 'black' : 'black',
        },
        content: [

          {
            alignment: 'justify',
            columns: [
              [{
                width: '*',
                text: this.PDF_HEADER_NAME ? (brand.brand_name || '') : '', color: '#101D42', fontSize: 24, bold: true,
              },
              ], [

                { width: '*', text: source.source_nm.toUpperCase() + " Q" + moment(Quarter).quarter() + '-' + moment(Quarter).year(), alignment: 'center', color: '#101D42', fontSize: 16, bold: true },
              ],
              [this.PDF_HEADER_LOGO ? {
                // image: await this.getBase64ImageFromURL('/assets/logo.png'),
                image: await this.getBase64ImageFromURL(this.brandLogo),
                width: 150,
                // height: 50,
                alignment: 'right'
              } : {}
              ]]
          },
          { text: this.PDF_HEADING, alignment: this.PDF_HEADING_ALIGNMENT, color: this.PDF_HEADINGCOLOR, marginTop: this.PDF_HEADING_ALIGNMENT == 'left' ? 20 : 0, fontSize: 16, bold: true },
          { text: '\n\n' },
          ...items.flatMap((i: any, j: any, arr: any) => {
            // Determine font size and color based on table index
            const fontSize = this.PDF_PAGE_ORITENTATION == 'portrait' ? 14 : 14
            const color = j % 2 === 0 ? 'black' : 'black';

            const elements: any[] = [
              {
                text: `Payment Breakdown`,
                fontSize: fontSize,
                bold: true,
                color: color,
              },
              { text: '\n\n' },
              i,
              { text: '\n\n' },
            ];

            // Apply pageBreak only to the last element when the index is odd and not the last item in the array
            if (j % 2 === 1 && j !== arr.length - 1) {
              elements.push({ text: '\n\n', pageBreak: ((j > 0) || ((j) % 1 == 0)) ? 'after' : undefined });
            }

            return elements;

          })
        ],
        footer: (currentPage: number, pageCount: number) => {
          if (this.PDF_FOOTER) {
            return {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: 'center',
              fontSize: this.PDF_PAGE_ORITENTATION == 'landscape' ? 14 : 10,
              color: 'black',
              bold: true
            }
          } else return

        },
      };



      // Wrap the getDataUrl() method in a Promise to handle the callback
      const dataUrl = await new Promise<string>((resolve) => {
        pdfMake.createPdf(documentDefinition).getDataUrl((result: string) => {
          resolve(result);
        });
      });

      // Set the data URL to the iframe source to display the PDF preview
      this.pdfPreviewData = this.domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);



      if (type == 'export') {
        const pdfDoc = pdfMake.createPdf(documentDefinition);
        // Generate the PDF as a data URL
        pdfDoc.getBlob((pdfBlob: Blob) => {
          // Set a custom file name for the generated PDF
          // const fileName = 'custom_filename.pdf';

          // Create a download link for the PDF
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(pdfBlob);
          downloadLink.download = this.PDF_HEADING ? this.brandName + "_" + this.PDF_HEADING : 'SCRAII_Pdf';

          // Trigger the download
          downloadLink.click();
        })
      }
    }

  }

  getSVGDataURL(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function () {
        reject(new Error('Failed to fetch the SVG image.'));
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  svgToBase64(svgDataUrl: string) {
    const svgIndex = svgDataUrl.indexOf('<svg');
    const svgXml = svgDataUrl.substring(svgIndex);

    const canvas = document.createElement('canvas');
    const svgBlob = new Blob([svgXml], { type: 'image/svg+xml' });

    const url = URL.createObjectURL(svgBlob);
    const img = new Image();

    return new Promise((resolve) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx: any = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL('image/png');

        resolve(base64);
        URL.revokeObjectURL(url);
      };
      img.src = url;
    });
  }

  getBase64ImageFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  exportPDf() {
    this.downloadAsPDF('export');
  }

  getPreview() {
    this.showPreview = true
    this.editName = true
    this.downloadAsPDF()
  }

  checkAll() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
      }
      this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
    this.toggle = true
  }

  DecheckAll() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
      }
      this.treeControl.collapse(this.treeControl.dataNodes[i]);
    }
    this.toggle = false
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode) {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node) ? (this.checklistSelection.select(...descendants), (node.expandable == true && node.level == 0 ? this.treeControl.expandAll() : node.expandable == true && node.level != 0 ? this.treeControl.expand(node) : "")) : (this.checklistSelection.deselect(...descendants), node.expandable == true && node.level == 0 ? this.treeControl.collapseAll() : node.expandable == true && node.level != 0 ? this.treeControl.collapse(node) : '')

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child))
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        this.treeControl.expand(currentNode)
        return currentNode;
      }
    }
    return null;
  }


}
