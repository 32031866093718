

<!-- <div style="display: flex;flex-direction: row;justify-content: center;">
  <div class="legendLabel" *ngFor="let legend of legendValue"  >
        <div class="lName">
          <span  > {{ legend.name }}</span></div>
        <div  class="key-dot" [style.background]="legend.color"></div>
  </div>
</div> -->
<!-- legend -->
<div id="legendDiv" style="width: 100%;display: flex;justify-content: center;"></div>

<!-- chart container -->
<div  style="overflow: auto;" id='heatmapContainer' ></div>

<!-- <div style="display: flex;">
    <div id="Yaxis"></div>
<div  id='heatmapContainer' style="overflow:auto ;"></div></div> -->

<!-- Tooltip -->
<div id="ItSummeryTooltip">
    <div class="container chart_tooltip" *ngIf="this.tooltipVisible">
      <div class="row chart_tooltip_header ">
        <div class="col-12 chart_tooltip_header_data mt-1 mb-1">
            {{ mytooltipData?.source_name + ' / ' }}{{ mytooltipData?.period | date: 'dd MMM yyyy'}}
        </div>
      </div>
     
      <div class="mt-1 mb-5 pb-2" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
        <div class="row">
          <div class="col-12 d-flex m-auto  align-items-center">
            <div>
              <div class="tooltip_outlay" [style.background]="'#1363DF'">
              </div>
            </div>
            <div class="chart_tooltip_body_data ml-2"> DISPENSE FROM INVENTORY : {{mytooltipData?.inventory_qty ?
              numbedPipe(mytooltipData?.inventory_qty) : 0 }}
            </div>

            <div class="ml-2">
              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousMA == 'up'" />

              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousMA == 'down'" />
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex m-auto  align-items-center">
            <div>
              <div class="tooltip_outlay" [style.background]="'#EF933E'">
              </div>
            </div>
            <div class="chart_tooltip_body_data ml-2"> DISPENSE FROM SHIPMENT : {{mytooltipData?.dispense_qty ?
              numbedPipe(mytooltipData?.dispense_qty) : 0 }}
            </div>

            <div class="ml-2">
              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex m-auto  align-items-center">
            <div>
              <div class="tooltip_outlay" [style.background]="'red'">
              </div>
            </div>
            <div class="chart_tooltip_body_data ml-2"> DIFFERENCE : {{mytooltipData?.qty_difference ?
              numbedPipe(mytooltipData?.qty_difference)+'('+mytooltipData?.pct_difference + '%)' : numbedPipe(mytooltipData?.qty_difference)+'('+mytooltipData?.pct_difference + '%)' }}
            </div>

            <div class="ml-2">
              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
              <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex m-auto  align-items-center">
              <div>
                <div class="tooltip_outlay" [style.background]="'gray'">
                </div>
              </div>
              <div class="chart_tooltip_body_data ml-2"> INVENTORY ON HAND : {{mytooltipData?.inventory_on_hand ?
                numbedPipe(mytooltipData?.inventory_on_hand) : 0 }}
              </div>
  
              <div class="ml-2">
                <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                  *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
                <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                  *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>