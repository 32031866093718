import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellRendererAngularComp, ITooltipAngularComp } from 'ag-grid-angular';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { a } from 'aws-amplify';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogService } from 'src/app/services/dialog.service';
import { PipelineUpdatePopupComponent } from '../pipeline-update-popup/pipeline-update-popup.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Overlay, OverlayContainer, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import * as d3 from 'd3';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.scss'],

})
export class AlertIconComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {

  @ViewChild("statusPopupforAlert", { static: true }) statusPopupforAlert!: ElementRef;

  tooltipComponent:any = PipelineUpdatePopupComponent;

  public params!: ICellRendererParams;
  public valueToDisplay: any;

  constructor(
    public filterService: FilterService,
    private dialogService: DialogService,
    private overlayContainer: OverlayContainer,
    private _overlay: Overlay, 
    private _viewContainerRef: ViewContainerRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {
    this.filterService.hideTools.subscribe((r:any)=> {
      this._overlayRef.detach();
    })
  }
  
  displayCounter(date:any) {
   
  }

  @ViewChild("patientJourney", { static: true }) _dialogTemplate!: TemplateRef<any>;
  private _overlayRef!: OverlayRef;
  private _portal!: TemplatePortal;

  refresh(): boolean {
    return false;
  }

 preventCloseOnClickOut() {
    this.overlayContainer.getContainerElement().classList.add('disable-backdrop-click');
  }

  allowCloseOnClickOut(event:any) {
    event.stopPropagation()
    this.overlayContainer.getContainerElement().classList.remove('disable-backdrop-click');
  }
  onMenuItemClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation()
  }
  inputType:any
  data:any;
  allParams:any
  status_tick = "status_tick"
  status_pending = "status"
  trash = "trash"

  agInit(params: ICellRendererParams<any>): void {
    this.allParams = params
    let data:any = params
    
    let alertData = data.dataFromPatient.value
    
    if(alertData) {
      this.data = JSON.parse(alertData)
      this.data.forEach((s:any)=> {
        let filter = data.alertIcons.filter((re:any) => re.isSelect == true)
        let find = filter.find((a:any)=> a.col_value == s.Alert_Type)
        if(find) {
          Object.assign(s, find)
        }
      })
    }
    this.createTooltip()
  }
  
  aer:any = []
  updatesObj:any[]=[]

  matIconClick(event:any,a:any) {
    this.filterService.refreshfromAccodin = false
    this.filterService.alertsIconfromPatientCard = false
    this.filterService.toolTipAlertData = a
    this.filterService.toolTipAlertData['Patient_key'] = this.allParams['data'].patient_id;
    this.show()
  }

  ngAfterViewInit() {}

  createTooltip(){
    const position = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 8,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -8,
        }
    ]);
    this._overlayRef = this._overlay.create({
      positionStrategy: position,
      scrollStrategy: this._overlay.scrollStrategies.close(),
      panelClass: 'custom-tooltip',

    });
  }

  show(): void {
      this.filterService.hideTools.next(true)
      let containerPortal: TemplatePortal<any>;
      containerPortal = new TemplatePortal(this._dialogTemplate, this._viewContainerRef);
      this._overlayRef.attach(containerPortal);
  }

  ngOnDestroy() {
    // this._overlayRef.dispose();
  }
}
