import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';

@Component({
  selector: 'app-common-search-bar',
  templateUrl: './common-search-bar.component.html',
  styleUrls: ['./common-search-bar.component.scss']
})
export class CommonSearchBarComponent implements OnInit {

  @Input('placeholder') placeholder:any = "Search"
  @Output() searchResult = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onSearch(event:any){
    const result = event.target.value
    this.searchResult.emit(result)

  }
}
