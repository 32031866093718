import { DataService } from "./../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Component, HostListener, Injectable, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ClientModelService } from "../components/shared/client-model/client-model.service";
import { SessionService } from "../services/session.service";
import { ClientModelComponent } from "../components/shared/client-model/client-model.component";
import { UtilityService } from "../services/utility.service";
import { ConstantsService } from "../services/constants.service";
import { ClientRolesComponent } from "../components/shared/client-roles/client-roles.component";
import { DashboardService } from "../services/dashboard.service";
import { ConfigTypeSelectionComponent } from "../components/shared/config-type-selection/config-type-selection.component";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../services/api.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-general",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

@Injectable({
  providedIn: "root",
})

export class HomeComponent implements OnInit, OnDestroy {
  clientKey: any;
  clientName: any;
  roleKey: any;
  roleName: any;
  clients: any;
  roles: any;
  userName: string = "";
  reqSubcription: Subscription[] = [];
  
  constructor(
    private idle: Idle,
    private route: Router,
    private dialog: MatDialog,
    private dataService: DataService,
    public utilityService: UtilityService,
    private sessionService: SessionService,
    private constantsService: ConstantsService,
    public clientModelService: ClientModelService,
    public apiService: ApiService,
  ) {
    this.userName = this.sessionService.getUsername();
  }
  
  ngOnInit(): void {
    let clientObj: any;
    let client: any;

    // Checking Previous Client
    let clientData = sessionStorage.getItem("client")
    if (clientData) {
      clientObj = JSON.parse(clientData as any);

      if (clientObj && clientObj.length && clientObj[0].client) {
        client = clientObj[0].client;
        this.clientKey = client.client_key;
        this.clientName = client.client_nm;
        this.getClientRoles(this.clients);
      } else {
        this.getClient(this.userName);
      }
    } else {
      this.getClient(this.userName);
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe());
  }


  getClient(username: string) {
    this.clientModelService.getClientList(username).subscribe((client: any) => {

      if (!client) return this.clients=[] // If Not

      this.clients = client ? client : []
      
      sessionStorage.setItem('switchClient', JSON.stringify(this.clients))

      // Checking Client length 
      if (this.clients?.length > 1) {
        this.openClient(this.clients);
      } else {
        if (this.clients.length==1) {

          // Value added
          this.clientKey = this.clients[0].client_key;
          this.clientName = this.clients[0].client_nm;
          
          this.getClientRoles(this.clients[0]);
        }
      }
    }, err => { 
      console.log('client',err)
    });
  }

  getClientRoles(client:any) {
    // user roles checking
    let body = {
      username: this.userName,
      client_key: client?.client_key,
    };

    this.reqSubcription.push(this.clientModelService.getClientRoles(body).subscribe((roles:any) => {

      if(!roles) return this.roles=[];
      this.roles = roles ? roles : [];
      if (this.roles.length>1) {
        this.openClientRoles();
      } else {
        if(this.roles.length==1) {
          this.clientModelService.newSetRoleDetails(this.roles[0]);
          this.selectConfigType()
        }
      }
    }, err=> {
      console.log('user Role', err);
      this.selectConfigType()
    })) 
  }
 
  // Client Selection Popup
  openClient(clients:any) {
    let dialogRef = this.dialog.open(ClientModelComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
      panelClass: "du-custom-modalbox",
      data: {client: clients},
    });

    dialogRef.afterClosed().subscribe((value) => {
       
      if (value) {
        this.getClientClosed(value);
      }
    });
  }

  getClientClosed(value:any) {
    if (value) {
      this.clientModelService.newSetClientDetails(value);
      this.clientKey = value.selectedClient.client_key;
      this.clientName = value.selectedClient.client_nm;
      // this.getClientRoles(this.userName, this.clientKey);
      this.getModuleList();
    }
  }

  // Config Type DB/S3
  getDBType() {
    this.dataService.getConfigType().subscribe((res) => {
      let configs = res ? res : [];
      this.openConfigSelection(configs)
    });
  }
  
  // Config Type Popup
  openConfigSelection(configs:any) {
    
    let dialogRef = this.dialog.open(ConfigTypeSelectionComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: true,
      panelClass: "du-custom-modalbox",
      data: {configs: configs},
    });

    dialogRef.afterClosed().subscribe((value) => {
      
      if(value=='closeFromConfig') {
        if(this.roles.length>1) {
          this.openClientRoles();
        } else {
          this.openConfigSelection(configs)
        }
      } else if(value) {
        // storing client session and service
        let obj = {
          selectedConfig: value,
          selectedClient: this.clients[0]
        }
        this.clientModelService.newSetClientDetails(obj);
        this.getModuleList();
      }
    });
  }


  // Client Role Popup
  openClientRoles() {
    let dialogRef = this.dialog.open(ClientRolesComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
      panelClass: "du-custom-modalbox",
      data: {
        clients: this.clients || [],
        roles: this.roles || []
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if(value=='closeFromClientRole') {
        if(this.clients.length>1) {
          this.openClient(this.clients)
        } else {
          this.openClientRoles()
        }
      } else if (value) {
        this.selectConfigType();
      }
    });
  }

  selectConfigType() {
    if (environment.configType) {
      let configs = [{"config_typ": "DB"},{"config_typ": "S3"}];
      this.openConfigSelection(configs) 
      // this.getDBType();
    } else {
      let obj = {
        selectedConfig: 'S3',
        selectedClient: this.clients[0]
      }
      this.clientModelService.newSetClientDetails(obj);
      this.getModuleList();
    }
  }

  // navigate to dashboard and set to session userInformation and logos
  getModuleList() {
    let data:any = sessionStorage.getItem('userDisplay')
    let ab = JSON.parse(data)

    this.reqSubcription.push(this.dataService.getUserInfo(ab._value).subscribe((res:any)=> {
      if(res!=null) {
        sessionStorage.setItem('userInformation', JSON.stringify(res[0]))
        this.getLogo()
      }
    }));
  }

  getLogo() {
    // get Logos
    this.reqSubcription.push(this.apiService.getlogos().subscribe((res:any)=>{
      // console.log(res)
      if(res.length>0){
        sessionStorage.setItem('logos', JSON.stringify(res))
      }else{
        alert("brand logos not found")
      }

      // navigate to Dashboard
      this.route.navigate([`dashboard/`]);
    },(err:any)=>{alert(" brand logos not found")}))
  }

  open(work: any) {
    this.route.navigate([
      `dashboard/work/${work.work_key}/${work.version_number}`,
    ]);
  }
}
