<div style="font-family: 'Poppins';font-size: 12px;line-height: 16px;">
    <div style="font-weight: 600;" class="d-flex justify-content-between p-3">
        <span>Change Status</span>
        <span>ID: {{ this.dialogData.patient_id ? this.dialogData.patient_id : 'null' }}</span>
    </div>
    <hr>
    <span class="ml-3" style="font-weight: 600;">Select Status</span>
    
    <div class="tagGroupColor">
        <ng-select #sc style="font-size:12px;" class="userStatusCss" (change)="selectStatus(this.dialogData.patient_case_status)" [(ngModel)]="this.dialogData.patient_case_status"
          [clearable]="false" placeholder="Type to Search">
          <ng-option *ngFor="let option of patientStatusChange" [value]="option">
            {{ option['patient_status'] }}
          </ng-option>
        </ng-select>

        <!-- <select class="form-select form-selecta"
        [(ngModel)]="this.dialogData.patient_case_status"
        (change)="selectStatus(this.dialogData.patient_case_status)"
        >
            <option *ngFor="let opti of patientStatusChange" [ngValue]="opti['patient_status']">
                {{ opti['patient_status'] }}
            </option>
        </select> -->
        <br>
        <span style="font-weight: 600">Current Status : </span>
        <span> {{ concatCurrentData}}</span>

        <hr *ngIf="this.dataLoaded">
        <span style="padding: 5px;font-weight: 600;text-transform: capitalize;" *ngIf="this.dialogData.patient_case_status && this.dataLoaded">{{this.dialogData.patient_case_status + ' '+'statuses'}}</span>
        <br>
        <div class="reasonScroll" *ngIf="!dataLoaded" style="height: 200px;overflow-y: auto;padding: 5px; cursor: pointer !important;display: flex;align-items: center;justify-content: center;" >
            <div class="loader"></div>
        </div>
        <div *ngIf="dataLoaded">
            
            <div class="reasonScroll"  style="overflow-y: auto;padding: 5px; cursor: pointer !important;" >
                <p
                    (click)="selectReason(rean, idx)"
                    [ngClass]="{'highlighted' : this.dialogData.patient_reason_status == rean.patient_reason_code_desc}"
                    *ngFor="let rean of patientReasons;let idx= index;" 
                    style="display: block !important;"
                    [id]="rean.patient_reason_code_desc+idx"
                >
                    {{rean.patient_reason_code_desc}} 
                    <mat-icon *ngIf="this.dialogData.patient_reason_status == rean.patient_reason_code_desc" class="iconReason" [svgIcon]="selectedReasonAsIcon"></mat-icon>
                </p>
            </div>

        </div>
    </div>
    <div class="d-flex justify-content-end p-2 ml-3">
        <button mat-button [mat-dialog-close]="true" style="color: #1363DF; font-size: 12px;font-weight: 600;margin-right: 5px;">Cancel</button>
        <button mat-raised-button class="mat-raised-buttons" (click)="applyStatus($event,this.dialogData.patient_case_status, this.dialogData.patient_reason_status)">Apply</button>
    </div>
</div>