<div style="font-family: Poppins !important">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive">

      <!-- header  -->
      <div style="background-color: #ffff;">
        <icon-chart [headerConfig]="headerConfig" [iconList]="iconList" [data]="data" [pageKey]="pageKey"
          [heading]="heading" [chartName]="divId" [config]="SlopeChart" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item"></icon-chart>
      </div>
      <div><loading *ngIf="isLoading"> </loading></div> 
      <div style="height: 500px; width: auto; " *ngIf="noData">
        <span class="bg-text" >There is no data for this time period</span></div>
      <div #SlopeChart id="slopeChartDiv"  [style.display]="noData?'none':''"></div>

      <div id="d3SlopeChartTooltip">

        <div class="container chart_tooltip" *ngIf="isTooltip">
          <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column">
              <div class="chart_tooltip_header_heading mt-1 ml-2">
                {{ mytooltipData?.["name"] ? "KPIs" :"" }}
              </div>
              <div class="d-flex justify-content-between" style="color: white;">
                <div style="font-weight: bold;margin-left: 6px;margin-top: 3px;">{{mytooltipData?.["name"] ?
                  mytooltipData?.["name"] : mytooltipData?.["xVar"]}}</div>
                <div *ngIf="showMore && tooltiptype == 'sp'"> <mat-icon class="matIcon"
                    style=" height: auto !important; width: auto !important;color: #ffff;"
                    (click)="isTooltip = false">close</mat-icon></div>
              </div>
            </div>
          </div>


          <div class="mt-2 mb-5" *ngIf="tooltiptype =='sp' && hei > 140" [ngClass]="{
            'chart_tooltip_body-after1': dataTurn < 250 && mytooltipData.values.length == 1,
            'chart_tooltip_body-after2': dataTurn < 250 && mytooltipData.values.length == 2,
            'chart_tooltip_body-after3': dataTurn < 250 && mytooltipData.values.length >= 3,
            'chart_tooltip_body1': dataTurn >= 250 && mytooltipData.values.length == 1,
            'chart_tooltip_body2': dataTurn >= 250 && mytooltipData.values.length == 2,
            'chart_tooltip_body3': dataTurn >= 250 && mytooltipData.values.length >= 3
          }"
          >
            <!-- scorecard tooltip -->
            <div [class]="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'"
                class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
              </div>
            </div>
           <div *ngFor="let key of kpiKeys;">
            <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values[key];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== key">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color:#848484;">{{key}}</div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div>
          </div>
            <!-- <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values['Data Quality'];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== value2">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color: #848484;"> Data Quality </div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div> -->


            <!-- KPI Comparison tooltip- -->
            <div [class]="this.item.config.chart_type !='scorecard' ? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">



              <div *ngIf="this.item.config.chart_type !='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>

                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]): data[1] }}
                </div>

              </div>

            </div>

            <div *ngIf="showMore && tooltiptype == 'sp'" (click)="openDialog()"
              class="d-flex justify-content-center showMore_txt">
              more</div>

          </div>

          <div class="mt-2 mb-5" *ngIf="tooltiptype =='sp' && hei < 140" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <!-- scorecard tooltip -->
            <div [class]="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'"
                class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
              </div>
            </div>

            <div *ngFor="let key of kpiKeys;">
              <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values[key];let i=index"
                style="margin-left: -12px;">
                <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                  <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                    [style.background]="data?.[2]"></div>
                  <div *ngIf="data[2]== key">
                    <div *ngIf="tooltiptype != 'sp' && i==0" style="color:#848484;">{{key}} </div>
                    <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                      numbedPipe(data[1]) : data[1] }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values['Business KPIs'];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== value1">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color:#848484;">Business KPIs </div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div>
            <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values['Data Quality'];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== value2">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color: #848484;"> Data Quality </div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div> -->



            <!-- KPI Comparison tooltip- -->
            <div [class]="this.item.config.chart_type !='scorecard' ? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">



              <div *ngIf="this.item.config.chart_type !='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>

                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]): data[1] }}
                </div>

              </div>

            </div>

            <div *ngIf="showMore && tooltiptype == 'sp'" (click)="openDialog()"
              class="d-flex justify-content-center showMore_txt">
              more</div>
          </div>

          <div class="mt-2 mb-5" *ngIf="tooltiptype !='sp'" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-afterWP' :'chart_tooltip_bodyWP'">
            <!-- scorecard tooltip -->
            <div [class]="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard' && tooltiptype == 'sp'"
                class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
              </div>
            </div>
            <div *ngFor="let key of kpiKeys;">
              <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values[key];let i=index"
                style="margin-left: -12px;">
                <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                  <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                    [style.background]="data?.[2]"></div>
                  <div *ngIf="data[2]== key">
                    <div *ngIf="tooltiptype != 'sp' && i==0" style="color:#848484;">{{key}} </div>
                    <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                      numbedPipe(data[1]) : data[1] }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values['Business KPIs'];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== value1">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color:#848484;">Business KPIs </div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div>
            <div class=" pt-1 pb-1" *ngFor="let data of mytooltipData.values['Data Quality'];let i=index"
              style="margin-left: -12px;">
              <div *ngIf="this.item.config.chart_type =='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar && tooltiptype != 'sp'" class="tooltip_outlay"
                  [style.background]="data?.[2]"></div>
                <div *ngIf="data[2]== value2">
                  <div *ngIf="tooltiptype != 'sp' && i==0" style="color: #848484;"> Data Quality </div>
                  <div *ngIf="tooltiptype != 'sp'" class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ?
                    numbedPipe(data[1]) : data[1] }}</div>
                </div>
              </div>
            </div> -->


            <!-- KPI Comparison tooltip- -->
            <div [class]="this.item.config.chart_type !='scorecard' ? 'pt-1 pb-1':''"
              *ngFor="let data of mytooltipData.values;let i=index" style="margin-left: -12px;">



              <div *ngIf="this.item.config.chart_type !='scorecard'" class="col-12 d-flex m-auto  align-items-center">
                <div *ngIf="mytooltipData?.xVar" class="tooltip_outlay" [style.background]="data?.[2]"></div>

                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]): data[1] }}
                </div>

              </div>

            </div>

            <div *ngIf="showMore && tooltiptype == 'sp'" (click)="openDialog()"
              class="d-flex justify-content-center showMore_txt">
              more</div>
          </div>

        </div>
      </div>
    </article>
  </main>
</div>

<!-- Slope chart Tooltip Popup -->
<ng-template #showMoreData>
  <div class="popupSlope">
    <div class="row tooltip_popup_header">
      <div class="col-12 d-flex flex-column mb-2 mt-1">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <div class="chart_tooltip_header_heading mt-1 mb-1 ml-2">
              {{ mytooltipData?.["name"] ? "KPIs" :"" }}
            </div>
            <div class="ml-2" style="color: white;font-weight: bold;font-family: 'Poppins';align-self: center;">{{mytooltipData?.["name"] ?
              mytooltipData?.["name"] : mytooltipData?.["xVar"]}}</div>
              <div *ngIf="showMore"> <mat-icon class="matIcon" [mat-dialog-close]="true"
                style=" height: auto !important; width: auto !important;display: block;">close</mat-icon></div>
          </div>
          </div>
      </div>
    </div>
    <div class="row details-body" style="max-height: 300px;height:max-content;overflow-x: hidden;overflow-y: auto;">
      <div class="col-12 d-flex mt-1 ml-2"  style="align-items: center;" *ngFor="let data of totalTooltipData.values;let i=index">
        <div>
          <div class="tooltip_outlay" [style.background]="data[2]"></div>
        </div>
        <div class="mt-1 ml-2" style=" 
         font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #ffffff">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
        </div>
        <div class="ml-2">
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
            *ngIf="data[2] == 'up'" />
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
            *ngIf="data[2] == 'down'" />
        </div>
        <div>
        </div>
      </div>
    </div>
    <div [mat-dialog-close]="true" class="row details-body justify-content-center showLess_txt">less</div>
  </div>

</ng-template>