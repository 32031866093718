import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatMenuPanel } from '@angular/material/menu';
import { ICellRendererParams } from 'ag-grid-community';
import * as d3 from "d3";
import moment from 'moment';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FilterService } from 'src/app/services/filter.service';
import { SessionService } from 'src/app/services/session.service';
import * as uuid from "uuid";
import { IrCommectPopupComponent } from '../../ir-comment-popup/ir-comment-popup.component';
import { Subscription } from 'rxjs';
import jsPDF from 'jspdf';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-cluster-bar-chart',
  templateUrl: './cluster-bar-chart.component.html',
  styleUrls: ['./cluster-bar-chart.component.scss']
})

export class ClusterBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('clusterBarChartContainer', { static: true }) clusterclusterBarChartContainer!: ElementRef;
  @ViewChild('customTemplate') customTemplate: any;
  @Input('data') data: any
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  iconList: any = []
  @ViewChild('fs') fs!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  isActive = false;
  barData: any;
  props: any = {}
  currentVisibleData: any = [];
  divId: any = 'clusterBarChart';
  setting = "setting-sm"
  mytooltipData: any = '';
  tooltipVisible: boolean = false;
  initiateChart: Boolean = false
  dataTurn: number = 0;
  counter = 2;
  plus = "plus"
  minus = "frame 4"
  popoverTitle = 'Popover title';
  popoverMessage = 'Popover description';
  confirmClicked = false;
  cancelClicked = false;
  showBy: any
  noData:Boolean=false
  constructor(private filterService: FilterService, private newFilterService: NewFilterService, private currency: CurrencyPipe) {
  

    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }


  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.isFullscreen = false;
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      this.isActive = false;
    }
  }

//loade Method
isLoading = false;
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() {
    this.isLoading = true;
  }
 
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
    this.mytooltipData = myData

    if (this.dataTurn < 250) {
      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 'px')
        .style('right', (this.dataTurn + 25) + 'px')
        .style('left', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 'px')
        .style('left', (myX + 25) + 'px')
        .style('right', 'unset')
    }
    // if (this.isFullscreen == true) {
    //   d3.select("#d3BarTooltip")
    //     .style('top', myY + 27 + 'px')
    // } else { }

    this.tooltipVisible = true
  }

  private hideTooltip(myType: any): void {
    this.tooltipVisible = false
    // d3.select("#d3BarTooltip")
      // .style('visibility', 'hidden');
  }
  ngOnDestroy(): void {
    this.data = undefined
    this.barData = undefined
  }

  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
    window.addEventListener('orientationchange', (event: any) => {
      this.getBarChartData()
      if (window.orientation === 0 || window.orientation === 180) {
        // Portrait orientation
        // Call your specific portrait mode function or perform actions
      } else if (window.orientation === 90 || window.orientation === -90) {
        // Landscape orientation
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.start()
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getBarChartData();
    }
    }
  @HostListener("window:resize", ["$event"])
    onResize(event: Event) {
      setTimeout(() => {
        if(!this.isFullscreen)
        this.plotChart()
      }, 200);
    }

  closeFullscreen(): void {
    this.props.chartHeight = 500
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  fullscreenChanges(fullscreen:any){
    if(fullscreen==false){
      this.closeFullscreen()
      return
    }
    this.props.chartHeight = window.outerHeight - 60
    this.isFullscreen = fullscreen;
    this.isActive = fullscreen;
    this.plotChart()
}


// property & data for the chart
  getBarChartData(): void {
    this.barData = this.data;
    // if (this.barData.length>0 ) {
      this.props = {
        "colours": {"quantityShipped": '#1363DF', "movingAverage" : 'gray', "quantityShipped2": '#EF933E'},
        "period":this.filterService.report_type,
        "xAxisLabel": "Period",
        "yAxisLabel": "Quantity",
        "xAxisVar": "period",
        "yAxisVar": "inventory_qty",
        "yAxisVar2": "dispense_qty",
        "showBrush": true,
        "OnHand":'inventory_on_hand',
        "d3AxisFormatting": false,
        "yAxisFormatting": "-2~s",
        "axisFontSize": 12,
        "axisFontWeight": 700,
        "movingAveragePeriod": 1,
        "chartHeight": 500,
      "partialPeriod": false,
     }
      if (this.isFullscreen) {
        this.props.chartHeight = window.outerHeight - 60
      }
      if ( this.barData.length > 0) {
        if (Object.keys(this.barData[0]).indexOf("partial_period_flg") > -1) {
          if (this.barData.find((f: any) => f["partial_period_flg"] === "Y") !== undefined) {
            this.props.partialPeriod = true;
          }
        }
        this.noData =false
        setTimeout(() => {
          this.plotChart();
        }, 50);
      } else {
        this.isLoading = false
        this.noData=true
        this.barData = []
        this.plotChart();
      }
    // }
  }
// property & data for the chart
initiateCharts(): void {
  // only need to call this once on initialisation
  const myChart = this;
  const myClass = myChart.divId;

  const mySvg =  d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

  // this will be redundant once new 'no data' component is in place
  mySvg.append('text').attr('id', 'noDataMessage' + myClass);
  const defs = mySvg.append('defs');
  // brush clip path
  defs.append('clipPath').attr('id', 'brushClip' + myClass)
      .append('rect').attr('id', 'brushClipRect' + myClass);
  // insane amount of code for 1 drop shadow, copied from Observable
  const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
  filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
  filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
  filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
  filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
  const feMerge = filter.append('feMerge');
  feMerge.append('feMergeNode').attr('in', 'offsetBlur');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  // x, y axis and label particularPeriod line
  mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
  mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
  mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
  mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
  mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
  mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
  // chart brush and brush selection group
  mySvg.append('g').attr('id', 'chartGroup' + myClass);
  mySvg.append('g').attr('id', 'brushGroup' + myClass);
  mySvg.append('g').attr('id', 'brushSelectionGroup' + myClass);
  // handle lines (for brush)
  mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
  mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
  mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
  mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
  // legend is always just 2 elements so static, not dynamic
  mySvg.append('circle').attr('class', 'legendCircleBar' + myClass + ' legendItem' + myClass);
  mySvg.append('circle').attr('class', 'legendCircleBar1' + myClass + ' legendItem' + myClass);
  mySvg.append('circle').attr('class', 'legendCircleBar2' + myClass + ' legendItem' + myClass);
  mySvg.append('circle').attr('class', 'legendCircleLine' + myClass + ' legendItem' + myClass);
  mySvg.append('text').attr('class', 'legendLabelLine' + myClass + ' legendItem' + myClass);
  mySvg.append('text').attr('class', 'legendLabelBar' + myClass + ' legendItem' + myClass);
  mySvg.append('text').attr('class', 'legendLabelBar1' + myClass + ' legendItem' + myClass);
  mySvg.append('text').attr('class', 'legendLabelBar2' + myClass + ' legendItem' + myClass);
  this.initiateChart=true
}

// chart svg  plotChart rendering 
plotChart(): void {
  const myChart = this;
  const myClass = myChart.divId;
  const mySvg: any = d3.select('#svg_' + myClass);
  const width: any = mySvg.node().getBoundingClientRect().width;
  const height = this.props.chartHeight;
  const circleRadius = 4;
  // set height
  mySvg.attr("height", height);
  let margins: any = { left: 75, right: 40, top: 50, bottom: 10, brush: 52, mid: 85  };
  if(myChart.props.showBrush === false){
    // less if no brush
    margins.brush = 0;
    margins.mid = 50;
    margins.bottom = 0;
  }
  const chartHeight = height - margins.top - margins.bottom - margins.brush - margins.mid;
  const timeConverter: any = {"D": d3.timeDay, "W": d3.timeWeek, "M": d3.timeMonth, "Q": d3.timeMonth, "Y": d3.timeYear}
  // tickFormat is standard across 4 charts (area line, bar, line, combo)
  // could be added to properties or stored elsewhere
  const tickFormat: any = {"D": "%d %b %Y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
  // period defined in properties
  const timePeriod = this.props.period;
  // format then defined from tickFormat
  const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
  let chartData: any =  this.barData;
  // convert string to date
  chartData?.map((m: any) => m.date = new Date(m[myChart.props.xAxisVar]));
  // build date group and sort ascending
  let dateGroup = Array.from(d3.group(chartData, (g: any) => g.date));
  dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));
  const dateGroupDates: any = [];
  chartData = [];
  let monthlyTotals: any = [];
  // loop through date group
  dateGroup.forEach((d: any, i: any)  => {  
    // build the set
    dateGroupDates.push(d[0]);
    let myAverage: any = null;
    let mySlice = []
    // calculate and build monthly totals
    const monthlyTotal = d3.sum(d[1], (s: any) => s[myChart.props.yAxisVar]);
    const monthlyTotal2 = d3.sum(d[1], (s: any) => s[myChart.props.yAxisVar2]);
    const monthlyTotal3 = d3.sum(d[1], (s: any) => s['disp_failed_qty']);
    const monthlyTotal4 = d3.sum(d[1], (s: any) =>s['inv_failed_qty'] );
    
    monthlyTotals.push(monthlyTotal);
    // if there are more data elements PREVIOUS than moving average period
    // create the slice of the last x months for moving average calculation
    // for more info on moving average calculation ask Sitaram/Angular team
    if(monthlyTotals.length >= (myChart.props.movingAveragePeriod)){
      mySlice = monthlyTotals.slice(monthlyTotals.length - myChart.props.movingAveragePeriod, monthlyTotals.length);
    }
    // calculate the average
    // myAverage = mySlice.length === 0 ? null : d3.mean(mySlice)
    myAverage = d3.sum(d[1], (s: any) => s[myChart.props.OnHand]);
   const difference =d3.sum(d[1], (s: any) => s['difference']);
    chartData.push({
      date: d[0],
      quantityShipped: monthlyTotal,
      quantityShipped2: monthlyTotal2,
      inv_failed_qty:monthlyTotal3||0,
      disp_failed_qty:monthlyTotal4||0,
      movingAverage: myAverage,
      difference:difference,
      mySlice: mySlice
    });
  });
  const yScaleBrushRight: any = d3.scaleLinear().domain([0, 6]).range([margins.brush - margins.mid - 5, 0]);
  // if d3 axis formatting === false, set tick values, one per unique date
  const tickValues = myChart.props.d3AxisFormatting === true ? null : dateGroupDates;
  // if d3 axis formatting === true, recommend 6 ticks
  const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
  // calculate max and extent
  const yMax = d3.max(chartData, (m: any) => Math.max((m.quantityShipped2+m.inv_failed_qty),(m.quantityShipped+m.disp_failed_qty), m.movingAverage));
  let xExtent: any = d3.extent(chartData, (d: any) => d.date);
  const xMax = d3.max(chartData, (d: any) => d.date);
  //offset data to leave a gap left + right (as per design)
  // timeConverter decides what length of gap to have dependent on the time period
  // so for example, if timePeriod is day it is 1 day and so on
  xExtent[0] = timeConverter[timePeriod].offset(xExtent[0], -1);
  xExtent[1] = timeConverter[timePeriod].offset(xExtent[1], 1);
  // with a minimum bar with of around 25, calculate how many dates will fit
  let startingVisibleRows: any = parseInt(String((width - margins.left - margins.right) / 50));
  // check in case this is more than the data length
  startingVisibleRows = startingVisibleRows > chartData.length ? chartData.length : startingVisibleRows;
  // if there are more data points than startingVisibleRows, set brushStartX to zoom in initially
  const brushStartX = chartData.length === 0 ? 0 : (1-(startingVisibleRows/chartData.length)) * (width - margins.left - margins.right);
  // set brush
  const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[-10, 0], [width - margins.left - margins.right , margins.brush]])
    //@ts-ignore
    .on('start brush end',!myChart.props.showBrush ? null : brushed);
  // will become redundant when no data component in place
  mySvg.select('#noDataMessage' + myClass)
    .attr("x", margins.left + (width - margins.left - margins.right)/2)
    .attr("y", margins.top + (height - margins.top - margins.mid - margins.brush - margins.bottom)/2)
    .attr('font-family', 'Poppins')
    .attr('fill', '#101D42')
    .attr('font-size', '16px')
    .attr('font-weight', '500')
    .attr('text-anchor', 'middle')
    .text(chartData.length === 0 ? "There is no data for this time period" : "");
  //static legend (only ever 2 variables)
  const legendMid = (margins.top/2) -15;
//   mySvg.select('.legendCircleBar1' + myClass)
//   .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
//   .attr('cx', -circleRadius*17)
//   .attr('cy', legendMid)
//   .attr('r', circleRadius)
//   .attr('fill','red');

// mySvg.select('.legendLabelBar1' + myClass)
//   .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
//   .attr('x', - (circleRadius * 3)*5)
//   .attr('y', legendMid + 5)
//   .attr('r', circleRadius)
//   .attr('font-family', 'Poppins')
//   .attr('fill', '#101D42')
//   .attr('font-size', '12px')
//   .attr('font-weight', '500')
//   .text('FAILURES');

  mySvg.select('.legendCircleBar' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('cx', circleRadius-80)
    .attr('cy', legendMid)
    .attr('r', circleRadius)
    .attr('fill',myChart.props.colours.quantityShipped);

  mySvg.select('.legendLabelBar' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('x',( circleRadius * 3 )-80)
    .attr('y', legendMid + 5)
    .attr('r', circleRadius)
    .attr('font-family', 'Poppins')
    .attr('fill', '#101D42')
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .text('DISPENSE FROM INVENTORY');
  // measure the legend width so you know where to place next elements
  const quantityLegendWidth = (circleRadius * 3) + 30 + measureWidth('DISPENSE FROM INVENTORY', 12);

  mySvg.select('.legendCircleBar2' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('cx', circleRadius + quantityLegendWidth -90)
    .attr('cy', legendMid)
    .attr('r', circleRadius)
    .attr('fill',myChart.props.colours.quantityShipped2);

  mySvg.select('.legendLabelBar2' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('x', circleRadius + quantityLegendWidth-80)
    .attr('y', legendMid + 5)
    .attr('r', circleRadius)
    .attr('font-family', 'Poppins')
    .attr('fill', '#101D42')
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .text('DISPENSE FROM SHIPMENT');
  // measure the legend width so you know where to place next elements
  const quantityLegendWidth2 = (circleRadius * 3) + 25  + measureWidth('DISPENSE FROM SHIPMENT', 12);

  mySvg.select('.legendCircleLine' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('cx', (circleRadius  * 3 )+ (quantityLegendWidth2*2)  -90)
    .attr('cy', legendMid)
    .attr('r', circleRadius)
    .attr('fill',myChart.props.colours.movingAverage);

  mySvg.select('.legendLabelLine' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('x', (circleRadius * 3) + (quantityLegendWidth2*2)-80)
    .attr('y', legendMid + 5)
    .attr('r', circleRadius)
    .attr('font-family', 'Poppins')
    .attr('fill', '#101D42')
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .text('INVENTORY ON HAND');
  // measure the average legend width so you can centre the legend accurately
  const legendLabelWidth = (circleRadius * 3) + 15 + measureWidth('INVENTORY ON HAND', 12);
  // centre the legend
  mySvg.selectAll('.legendItem' + myClass)
    .attr('transform', 'translate(' + ((width - legendLabelWidth - quantityLegendWidth)/2) + ',0)');
  // set brush overlay fill to transparent
  mySvg.select('.overlay')
      .style('fill', 'transparent');
  // define clip rect dimensions
  mySvg.select('#brushClipRect' + myClass)
      .style('width', width - margins.left - margins.right +  20)
      .style('height', height)
      .attr('transform', 'translate(' + (margins.left-10) + ',0)');
  // set x and y scales
  const yScale = d3.scaleLinear().domain([0, Number(yMax)]).range([Number(height - margins.top - margins.bottom - margins.mid - margins.brush), 0]);
  // brush has smaller y scale
  const yScaleBrush = d3.scaleLinear().domain([0, Number(yMax)]).range([margins.brush, 0]);
  // x scale all for the brush and as a base, selected is current bar scale
  const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
  const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
  // x and y axis labels
  mySvg.select('#xAxisLabel' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('x', margins.left + ((width - margins.left - margins.right)/2))
    .attr('y', height - margins.bottom - margins.brush - 10)
    .attr('text-anchor', 'middle')
    .attr('font-family', 'Poppins')
    .attr('fill', '#737D88')
    .attr('font-size', '12px')
    // @ts-ignore
    .text(myChart.props.xAxisLabel);

  mySvg.select('#yAxisLabel' + myClass)
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .attr('transform','translate(13,' + (margins.top + ((height - margins.top - margins.bottom - margins.brush - margins.mid)/2)) + ') rotate(-90)')
    .attr('text-anchor', 'middle')
    .attr('font-family', 'Poppins')
    .attr('fill', '#737D88')
    .attr('font-size', '12px')
    // @ts-ignore
    .text(myChart.props.yAxisLabel);
  // x and y axis
  mySvg.select('#xAxis' + myClass)
      .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(tickValues).ticks(tickCount).tickFormat(xTickFormat))
      .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');

  mySvg.select('#yAxis' + myClass)
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5).tickFormat((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormatting)(d)))
      .attr('transform', 'translate(' + (margins.left-15) + ',' + margins.top + ')');
  //axis formatting
  mySvg.selectAll('.axis' + myClass + ' path')
    .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
    .style('stroke', '#E8EAEE');
  // set data depending on whether brush is needed
  const chartGroupData = myChart.props.showBrush === false ? ['chart']: ['brush', 'chart'];
  //brush and chart line definitions
  const lineBrush = d3.line()
      .defined((d: any) => d.movingAverage !== null)
      .x((d: any) => xScaleAll(d.date))
      .y((d: any) => yScaleBrush(d.movingAverage));

  const lineFocus = d3.line()
      .defined((d: any) => d.movingAverage !== null)
      .x((d: any) => xScale(d.date))
      .y((d: any) => yScale(d.movingAverage));
  // call brush and move to brushStartX (0 if dataset is small enough to show all)
  mySvg.select('#brushGroup' + myClass)
      .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight + margins.mid) + ')')
      .call(brush)
      .call(brush.move, [brushStartX -10, (width) - margins.left - margins.right]);
  // hid brush is set to not show
  if(myChart.props.showBrush === false){
    mySvg.select('#brushGroup' + myClass)
      .call(brush.move,null)
      .selectAll("*").remove();
  }
  // setting basic property of handle lines (x position changes with brush)
  mySvg.selectAll('.handleLines' + myClass)
    .attr('visibility', chartData.length === 0 || myChart.props.showBrush === false? 'hidden' : 'visible')
    .attr('y1', margins.top + chartHeight + margins.mid + (margins.brush - 12) / 2)
      .attr('y2', margins.top + chartHeight + margins.mid + 12 + (margins.brush - 12) / 2)
      .style('stroke', '#8A98AB')
      .style('stroke-width', '1')
      .attr('transform', 'translate(' + margins.left + ',0)');
  // brush selection styling
  mySvg.selectAll('#brushGroup' + myClass)
      .selectAll('.selection')
      .attr('fill', '#A0A0A0')
      .attr('fill-opacity', '0.2')
      .style('stroke', '#E8EAEE')
      .style('stroke-width', '1');
  // draw chart
  drawChart();

  function drawChart(): void {
    //x axis line and text styling (need to reset every time axis resets)
    mySvg.selectAll('#xAxis' + myClass + ' line')
      .attr('y1', '0')
      .attr('y2', -(height - margins.top - margins.bottom - margins.brush - margins.mid))
      .style('stroke', '#F0F3F6')
      .style('stroke-width', 1);

    mySvg.selectAll('.axis' + myClass + ' text')
      .style('font-weight', myChart.props.axisFontWeight)
      .style('font-family', "Poppins")
      .style('font-size', myChart.props.axisFontSize)

    mySvg.selectAll('#xAxis' + myClass + ' text')
      .style("text-anchor", "middle")
      .attr("dx", -5)
      .attr("dy", 5)
      .attr("transform", "");

    mySvg.selectAll('#yAxis' + myClass + ' line')
      .attr('x1', '0')
      .attr('x2', width - margins.right - margins.left)
      .style('stroke', '#F0F3F6')
      .style('stroke-width', 1);
    // calculate xBandwidth (using a linear scale here for brush)
    let ticksArray = tickValues === null ? xScale.ticks() : tickValues.filter((f: any) => f >= xScale.domain()[0] && f <= xScale.domain()[1]);
    ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
    const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
      ? width - margins.chartRight - margins.chartLeft : xScale(ticksArray[i+1]) - xScale(m) )

    const maxTextWidth = getMaxTextWidth();
    if(maxTextWidth > (xBandwidth - 5)){
      // if there is not enough space for ticks (overlap)
      // reset the font size
      let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;
      // slightly different positioning depending on whether d3 or tick per date point
      // if tick per date point, rotate by -45
      mySvg.selectAll('#xAxis' + myClass + ' text')
        .style("text-anchor", (myChart.props.d3AxisFormatting === true ? "middle" : "end" ))
        .attr("dy",  (myChart.props.d3AxisFormatting === true ? 5 : 1 ))
        .style("font-size", myFontSize)
        .attr("transform", "rotate(" + (myChart.props.d3AxisFormatting === true ? 0 : -45 ) + ")");
    }

    function getMaxTextWidth(){
      // looping through x axis and using getBBox to get tick width and return max
      let maxTextWidth =  0;
      mySvg.selectAll('#xAxis' + myClass + ' text').each(function(){
        // @ts-ignore
        const myNode = d3.select(this).node().getBBox();
        maxTextWidth = Math.max(maxTextWidth, myNode.width)
      })
      return maxTextWidth
    }
    if(myChart.props.showBrush === true){
      // if brush, get handle x positions and reset handle line x positions
      const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
      const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');

      mySvg.select('.handleLeftLine1' + myClass)
        .attr('x1', handleEastX + 3)
        .attr('x2', handleEastX + 3);

      mySvg.select('.handleLeftLine2' + myClass)
        .attr('x1', handleEastX + 7)
        .attr('x2', handleEastX + 7);

      mySvg.select('.handleRightLine1' + myClass)
        .attr('x1', handleWestX + 3)
        .attr('x2', handleWestX + 3);

      mySvg.select('.handleRightLine2' + myClass)
        .attr('x1', handleWestX + 7)
        .attr('x2', handleWestX + 7);
      // reset handle styling
      mySvg.select('#brushGroup' + myClass)
        .selectAll('.handle')
        .attr('fill', 'white')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('y', (margins.brush - 24) / 2)
        .attr('height', 24)
        .style('filter',  'url(#drop-shadow)');
    }
    // partial period line and label (reposition)
    // mySvg.select('.partialPeriodLine' + myClass)
    //   .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
    //   .attr("x1", margins.left + xScale(xMax))
    //   .attr("x2",margins.left + xScale(xMax))
    //   .attr("y1", margins.top)
    //   .attr("y2", chartHeight + margins.top)
    //   .attr('stroke', '#1363DF')
    //   .attr('stroke-width', 1)
    //   .attr('stroke-dasharray','2,2');

    // mySvg.select('.partialPeriodLineLabel' + myClass)
    //   .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
    //   .attr("transform", "translate(" + (margins.left + xScale(xMax)) + "," +
    //     (margins.top - 12) + ")")
    //   .attr("text-anchor", "middle")
    //   .attr("dy", 0)
    //   .attr('font-size', '8px')
    //   .attr('font-family', 'Poppins')
    //   .attr('font-weight', '400')
    //   .attr('fill', '#1363DF')
    //   .text('Partial period')
    //   .call(wrap, 35);
    // make sure you hide selection no data (redundant soon)
    mySvg.selectAll(".selection")
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible');
    // set data for Angular team
    myChart.currentVisibleData = [chartData, xScale.domain()];
    // finally the bars!!!
    // chart group - this is set up in a slightly odd way
    // chartGroupData shows brush and chart
    const chartGroup = mySvg.select('#chartGroup' + myClass)
        .selectAll('.chartGroup' + myClass)
        .data(chartGroupData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'chartGroup' + myClass);
          enter.append('path').attr('class', 'averageLine');
          enter.append('g').attr('class', 'barsGroup');
          return enter;
        });
    // clip bath for chart
    chartGroup.attr('clip-path', (d: any) => d === 'brush' ? '' : 'url(#brushClip' + myClass + ')');
    // average line for both (line scale different)
    chartGroup.select('.averageLine')
        .style('stroke', '#8A98AB')
        .style('fill', 'transparent')
        .style('stroke-width', 4)
        .attr('d', (d: any) => d === 'brush' ? lineBrush(chartData) : lineFocus(chartData))
        .attr('transform', (d: any) => 'translate(' + margins.left
            + ',' + (d === 'brush' ? (margins.mid + chartHeight + margins.top-2) : margins.top-2) + ')');
    // different transforms
    chartGroup.select('.barsGroup')
        .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? (margins.mid + chartHeight) : 0) + ')');

    const barGroup = chartGroup.select('.barsGroup')
        .selectAll('.barsGroupGroup')
        .data((d: any) => {
          // may date and type to data
          const myData = JSON.parse(JSON.stringify(chartData));
          myData.map((n: any) => n.date = new Date(n.date));
          myData.map((n: any) => n.type = d);
          return myData;
        })
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'barsGroupGroup');
          enter.append('path').attr('class', 'barsGroupItems totalRect');
          enter.append('path').attr('class', ' barsGroupItems barRect');
          enter.append('path').attr('class', 'barsGroupItems totalRect1');
          enter.append('path').attr('class', ' barsGroupItems barRect1');
          enter.append('circle').attr('class', 'barsGroupItems averageDot');
          // enter.append('line').attr('class', ' averageLine');
          return enter;
        });
        
        // average dot - different scale if brush
        barGroup.select('.averageDot')
        .attr('cx', (d: any) => d.type === 'brush' ? xScaleAll(d.date) : xScale(d.date))
        .attr('cy', (d: any) => d.type === 'brush' ? yScaleBrush(d.movingAverage) : yScale(d.movingAverage))
        .attr('fill', '#FFF0C9')
        .style('stroke', '#FFCD4A')
        .attr('r', (d: any) => d.movingAverage === null ? 0 : circleRadius)
        .style('stroke-width', 2  )
        .attr('transform', 'translate(' + margins.left + ',' + (margins.top - 2) + ')');
   
    // the bar rect (again different if brush)
    barGroup.select('.barRect')
    .attr('d', (d: any) => {
      const yPosition = d.type === 'brush' ? yScaleBrush(0) - yScaleBrush(d.inv_failed_qty) : yScale(0) - yScale(d.inv_failed_qty);
      return `M0,${yPosition} L0,${d.inv_failed_qty == 0 ? 0 : 4}
        Q0,0 4,0 L4,0 Q8,0 8,${d.inv_failed_qty == 0 ? 0 : 4} L8,${yPosition} Z`;
    })
    .attr('fill', (d: any) => d.inv_failed_qty == 0 ? 'white' : 'red')
    .attr('transform', (d: any) => `translate(${d.type === 'brush' ? xScaleAll(d.date) + 66 : xScale(d.date) + 66},${d.type === 'brush' ? (((d.inv_failed_qty == 0 ? 142 : (yScaleBrush(d.quantityShipped2) + margins.top)- (yScaleBrush(0) - yScaleBrush(d.inv_failed_qty))  ))) : (yScale(d.quantityShipped2) - (yScale(0) - yScale(d.inv_failed_qty))) + margins.top})`);
  
  barGroup.select('.totalRect')
    .attr('d', (d: any) => {
      const yPosition = d.type === 'brush' ? yScaleBrush(0) - yScaleBrush(d.quantityShipped2) : yScale(0) - yScale(d.quantityShipped2);
      return `M0,${yPosition} L0,${d.inv_failed_qty != 0 ? 0 : d.quantityShipped2 == 0 ? 0 : 4}
        Q0,0 4,0 L4,0 Q8,0 8,${d.inv_failed_qty != 0 ? 0 : d.quantityShipped2 == 0 ? 0 : 4} L8,${yPosition} Z`;
    })
    .attr('fill', myChart.props.colours.quantityShipped2)
    .attr('transform', (d: any) => `translate(${d.type === 'brush' ? xScaleAll(d.date) + 66 : xScale(d.date) + 66},${d.type === 'brush' ? (yScaleBrush(d.quantityShipped2) + margins.top) : (yScale(d.quantityShipped2) + margins.top)})`);
  
  barGroup.select('.barRect1')
    .attr('d', (d: any) => {
      const yPosition = d.type === 'brush' ? yScaleBrush(0) - yScaleBrush(d.disp_failed_qty) : yScale(0) - yScale(d.disp_failed_qty);
      return `M0,${yPosition} L0,${d.disp_failed_qty == 0 ? 0 : 4}
        Q0,0 4,0 L${8 - 4},0 Q8,0 8,${d.disp_failed_qty == 0 ? 0 : 4} L8,${yPosition} Z`;
    })
    .attr('fill', (d: any) => d.disp_failed_qty == 0 ? 'white' : 'red')
    .attr('transform', (d: any) => `translate(${d.type === 'brush' ? xScaleAll(d.date) + 76 : xScale(d.date) + 76},${d.type === 'brush' ? (((d.disp_failed_qty == 0 ? 142 : (yScaleBrush(d.quantityShipped) + margins.top)- (yScaleBrush(0) - yScaleBrush(d.disp_failed_qty))  ))): (yScale(d.quantityShipped) - (yScale(0) - yScale(d.disp_failed_qty))) + margins.top})`);
  
  barGroup.select('.totalRect1')
    .attr('d', (d: any) => {
      const yPosition = d.type === 'brush' ? yScaleBrush(0) - yScaleBrush(d.quantityShipped) : yScale(0) - yScale(d.quantityShipped);
      return `M0,${yPosition} L0,${d.inv_failed_qty != 0 ? 0 : d.quantityShipped == 0 ? 0 : 4}
        Q0,0 4,0 L4,0 Q8,0 8,${d.inv_failed_qty != 0 ? 0 : d.quantityShipped == 0 ? 0 : 4} L8,${yPosition} Z`;
    })
    .attr('fill', myChart.props.colours.quantityShipped)
    .attr('transform', (d: any) => `translate(${d.type === 'brush' ? xScaleAll(d.date) + 76 : xScale(d.date) + 76},${d.type === 'brush' ? (yScaleBrush(d.quantityShipped) + margins.top) : (yScale(d.quantityShipped) + margins.top)})`);
    // mySvg.selectAll('.averageLine')
    // .on('mouseover',  (event: any, d: any) => {
    //       chartGroup.select('.averageLine').style('stroke-width', 6)
    //       chartGroup.select('.barsGroup').style("opacity", 0.4)
      

    //   // myChart.showTooltip('Line', d, event.offsetX, event.offsetY, width);
    // })
    //   .on('mouseout',  () => {
    //     chartGroup.select('.averageLine').style('stroke-width', 4)
    //     chartGroup.select('.barsGroup').style("opacity", 1)
    //   });

    mySvg.selectAll('.barsGroupItems')
      .on('mouseover',  (event: any, d: any) => {
        if(d.type === 'brush')return
        const currentIndex = dateGroupDates.findIndex((f: any) => String(f) === String(d.date));
        let comparedToPreviousQS = undefined, comparedToPreviousMA = undefined;
        // calculate whether up/down/no change
        if(currentIndex > 0){
          const previousValue = chartData[currentIndex - 1].quantityShipped;
          if(previousValue < d.quantityShipped){
            comparedToPreviousQS = 'up';
          } else if (previousValue === d.quantityShipped) {
            comparedToPreviousQS = 'no change'
          } else {
            comparedToPreviousQS = 'down';
          }
          // same for moving average
          const previousValueAverage = chartData[currentIndex - 1].movingAverage;
          if(previousValueAverage < d.movingAverage){
            comparedToPreviousMA = 'up';
          } else if (previousValueAverage === d.movingAverage) {
            comparedToPreviousMA = 'no change'
          } else {
            comparedToPreviousMA = 'down';
          }
        }
        d.comparedToPreviousQS = comparedToPreviousQS;
        d.comparedToPreviousMA = comparedToPreviousMA;
        d.colours = myChart.props.colours;
        // pass data to tooltip
        myChart.showTooltip('Bar', d, event.offsetX, event.offsetY, width);
      })
      .on('mouseout',  () => {
        // hide tooltip
        myChart.hideTooltip('averageDot');
      });
  }
  function brushed(event: any): void {

    const selection = event.selection;
    if (selection !== null) {
      // hide tooltip
      myChart.hideTooltip('averageDot');
      // calculate xDomain based on selection
      const xDomain: any = selection.map(xScaleAll.invert);
      const dataRemaining: any = chartData.filter((f: any) => f.date >= xDomain[0] && f.date <= xDomain[1]);
      if(dataRemaining.length < 1) {
        // if no data in selection (zoomed too far), reset to previous selection
        const previousStart = xScaleAll(xScale.domain()[0]);
        const previousEnd = xScaleAll(xScale.domain()[1]);
        if(previousStart !== undefined && previousEnd !== undefined){
          mySvg.select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, myChart.props.showBrush === false ? null : [previousStart, previousEnd]);
        }
      } else {
        // set partial period visibility
        // only show if we are at the end of the dataset
        if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
          mySvg.select('.partialPeriodLine' + myClass)
            .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          mySvg.select('.partialPeriodLineLabel' + myClass)
            .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
        } else {
          mySvg.select('.partialPeriodLine' + myClass)
            .attr("visibility",  "hidden")
          mySvg.select('.partialPeriodLineLabel' + myClass)
            .attr("visibility","hidden")
        }
        // reset selected scale
        xScale.domain(xDomain);
        // reset xAxis
        mySvg.select('#xAxis' + myClass)
          .call(
            d3.axisBottom(xScale)
              .tickSizeOuter(0).tickFormat(xTickFormat).ticks(tickCount)
            .tickValues(tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1])))
          .attr('transform',  'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');
        // draw chart
        drawChart()
        }
    }
  }

  function measureWidth(myText: any, myFontSize: any): any {
    // used to measure width and therefore calculate where to place things
    const context: any = document.createElement('canvas').getContext('2d');
    context.font = myFontSize + 'poppins';
    return context.measureText(myText).width;
  }

  function wrap(text: any, width: any): void {
    // wraps to multi lines, from Observable/Mike Bostock
    text.selectAll('tspan').remove();
    let lineNumber = 0;
    const originalValue = text.text();
    text.each(function(): any {
      // @ts-ignore
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word = null;
      let line: any = [];
      const lineHeight = 1.1; // ems
      const dy = 0;
      let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'em');
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        // @ts-ignore
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          if (word.trim().length > 0){
            tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);

          }
        }
      }
    });
    // custom code to add .. rather than go to 2 lines (requested at some point)
    if (lineNumber > 1){
      text.selectAll('tspan').remove();
      text.attr('id', originalValue)
      text.text(originalValue.substr(0, 5) + '..');
    }
  }
  this.isLoading = false
}

// numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }
}