import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(
        private sessionService: SessionService
    ) { }

    async addBreadCrumb(table: any) {
        let breadCrumbs: any = this.sessionService.getBreadCrumbs();

        if (!breadCrumbs)
            breadCrumbs = {};

        if (!breadCrumbs[table.TableName])
            breadCrumbs[table.TableName] = table;

        this.sessionService.setBreadCrumbs(breadCrumbs);
    }

    async getBreadCrumbs() {
        return this.sessionService.getBreadCrumbs();
    }

    clearBreadCrumbs() {
        this.sessionService.setBreadCrumbs(null);
    }

    getPrimaryKey(primaryKeys:any[]) {
        if (primaryKeys && primaryKeys.length > 0) {
            let pks:any[] = [];

            primaryKeys.forEach((key: any) => {
                if (key.ColumnKey == 'PRI')
                    pks.push(key.ColumnName);
            });

            return pks.join(',');
        } else
            return null;
    }

    getPrimaryKeysWithValue(record:any, primaryKeys: any) {
        if (primaryKeys && Object.keys(primaryKeys).length > 0) {
            let data: any = {};

            primaryKeys.forEach((row:any) => {
                if (record[row.ColumnName] && row.ColumnKey == 'PRI') {
                    data[row.ColumnName] = record[row.ColumnName];
                }
            });

            return data;
        } else {
            return {};
        }
    }

    getColumnsToFilter(formObject: any) {
        if (formObject) {
            let columnToFilter: any = [];

            Object.keys(formObject).forEach((key: string) => {
                if (formObject[key] !== null) {
                    columnToFilter.push(key + ',' + formObject[key]);
                }
            });

            return columnToFilter.join('|');
        } else {
            return null;
        }
    }

    /**
     * Creating the Timestamp to add where ever we need
     */
    getTimeStampAsString() {
        let dateObject = new Date();

        let hours: string = '' + dateObject.getHours();
        let minutes: string = '' + dateObject.getMinutes();
        let seconds: string = '' + dateObject.getSeconds();

        let date: string = '' + dateObject.getDate();
        let fullyear: string = '' + dateObject.getFullYear();
        let month: string | number = dateObject.getMonth() + 1;

        if (hours.length < 2)
            hours = '0' + hours;

        if (minutes.length < 2)
            minutes = '0' + minutes;

        if (seconds.length < 2)
            seconds = '0' + seconds;

        if (month < 10)
            month = '0' + month;

        if (date.length < 2)
            date = '0' + date;

        return fullyear + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
    }

    generatePathWithBackslash(folderName: string) {
        var paths = folderName.split("|")
        var completePath = '';
        for (var i = paths.length - 1; i >= 0; i--) {
            if (i == paths.length - 2) {
                completePath = paths[i];
            } else {
                completePath = ' ' + paths[i] + ' ' + '>' + ' ' + completePath;
            }
        }
        return completePath;
    }

    formatBytes(bytes:any, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getRolePermissions(componentKey: string, userRole: any) {
        if (userRole && componentKey) {
            let role = userRole[componentKey];

            if (role) {
                return {
                    CanAdd: role && role.CanAdd && (role.CanAdd.toLowerCase() == 'y'),
                    CanDelete: role && role.CanDelete && (role.CanDelete.toLowerCase() == 'y'),
                    CanModify: role && role.CanModify && (role.CanModify.toLowerCase() == 'y')
                };
            } else {
                return {
                    CanAdd: false,
                    CanDelete: false,
                    CanModify: false
                };
            }
        } else {
            return {
                CanAdd: false,
                CanDelete: false,
                CanModify: false
            };
        }
    }
}
