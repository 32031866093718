import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-html-notes',
  templateUrl: './html-notes.component.html',
  styleUrls: ['./html-notes.component.scss']
})
export class WidgetHtmlNotesComponent implements OnInit {
  static key = 100202
  
  @Input('config') config:any = {}
  @Input('item') item:any
  constructor() { }

  ngOnInit(): void {
  }

}
