import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';;
import { report } from 'process';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-popup-status',
  templateUrl: './popup-status.component.html',
  styleUrls: ['./popup-status.component.scss']
})
export class PopupStatusComponent implements OnInit, OnDestroy {
  // patient_status="";
  selectedReasonAsIcon = "reason_tick";
  patient_status_key: any;
  patient_reason_code: any
  reqSubcription: Subscription[] = []
  api_key: any
  dialogData: any
  patientStatusChange: any[] = []
  patientReasons: any[] = []
  concatCurrentData: any
  dataLoaded = false;
  matDialogClose = false;

  constructor(
    public filterService: FilterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private newFilterService: NewFilterService
  ) {
  }

  ngOnInit(): void {
    this.newFilterService.currentData.subscribe(data => {
      this.dialogData = data;
    })

    this.api_key = this.dialogData.api_key
    this.concatCurrentData = this.dialogData.patient_case_status + '-' + this.dialogData.patient_reason_status

    if (this.dialogData) {
      this.loadApiData()
    }
  }
  patient_status: any
  selectStatus(opt: any) {
     
    let options = this.patientStatusChange.find((res: any) => res.patient_status == opt.patient_status)
    if (options) {
      this.dialogData.patient_case_status = options.patient_status
      this.patientReasons = options.patient_reason
      this.patient_reason_code = ''
      this.dialogData.patient_reason_status = ''
    }
  }

  selectReason(a: any, idx:any) {
    this.patient_status_key = a.patient_status_dim_key
    this.patient_reason_code = a.patient_reason_code
    this.dialogData.patient_reason_status = a.patient_reason_code_desc
  }
  

  applyStatus(event: MouseEvent,status: any, reason: any) {
    event.preventDefault();
    event.stopPropagation();
    
    let obj:any = {};

    obj["api_key"] = this.api_key;
    obj["patient_id"] = this.dialogData.patient_id || undefined;
    obj["report_typ"] = "U";
    obj["patient_status"] = status || undefined;
    obj["patient_status_reason_code"] = this.patient_reason_code

    if(this.patient_reason_code) {
      this.reqSubcription.push(this.filterService.frmExecutionQuery(obj).subscribe((res: any) => {
        if(res.length==0) {
          this.responseClearing()
        }
  
      }, (err: any) => {
        if (err.status == 200) {
          this.responseClearing();
        }
      }))
    }
  }

  responseClearing() {
    this.filterService.hoverDataRefresh.next(true)
    this.filterService.refreshStatusChangeData.next(this.dialogData)
    
    this.dialog.closeAll()

    let simpleSnackBarRef = this._snackBar.open('Changes Updated');
    setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 1000);
  }


  loadApiData() {

    let query:any = {}
    query["api_key"] = this.dialogData.api_key;
    query["report_typ"] = this.dialogData.report_typ || 'D'
    query["patient_status"] = null

    this.reqSubcription.push(this.filterService.frmUpdateList(query).subscribe((res: any) => {
      this.patientStatusChange = []
      Object.entries(_.groupBy(res, "patient_status")).forEach((m: any) => {
        this.patientStatusChange.push({
          patient_status: m[0],
          patient_reason: m[1],
        });
      });

      let options = this.patientStatusChange.find((res: any) => res.patient_status == this.dialogData.patient_case_status)

      if (options)
        this.patientReasons = options.patient_reason;

        // Scroll Into View
        this.dataLoaded = true;
        let fnd = this.patientReasons.findIndex((f:any)=> f.patient_reason_code_desc == this.dialogData.patient_reason_status)
        this.scrollToBlue(this.dialogData.patient_reason_status+fnd)

    }, (err: any) => {
      console.log('change status popup',err)
    }))
  }

  scrollToBlue(id:any) {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }, 500);
  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }
}
