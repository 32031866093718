<!-- GridCell  SparkLineContainer -->

<!-- INVENTORY DATE":"2021-11-25","SHIPMENT WEEK":"2021-11-25","SOURCE NAME" -->
<div class="inventory_bar" [ngStyle]="style" *ngIf="data" (click)="getMenu()">
  <!-- {Source_key: 3002,Location_key: 1007,dispense_quantity: 1,inventory_quantity: 1,Difference: 0,Dispense_Failed_Quantity: 0,Inventory_Failed_Quantity: 0,dispense_quantity_Cumulative: 101,inventory_quantity_Cumulative: 100,Cumulative_Difference: 1} -->
  <div *ngIf="!(objCondition(this.data))">
  <div style="display: flex;">
    <div style="padding: 5px;font-size: 12px;"><span> # Difference:</span>

      <svg  *ngIf="data?.['discrepancy']=='Y' && this.data['difference'] == 0"  style="margin-left: 5px;" width="12" height="12" viewBox="0 0 10 10" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 7C5.13807 7 5.25 6.88807 5.25 6.75C5.25 6.61193 5.13807 6.5 5 6.5C4.86193 6.5 4.75 6.61193 4.75 6.75C4.75 6.88807 4.86193 7 5 7Z"
          fill="#D11044" />
        <path
          d="M5 5V3M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5ZM5.25 6.75C5.25 6.88807 5.13807 7 5 7C4.86193 7 4.75 6.88807 4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75Z"
          stroke="#D11044" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg *ngIf="messages?.length>0" width="20" height="24" viewBox="5 -3 30 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4767_7235)">
          <circle cx="20" cy="16" r="10" fill="#FF6636" />
          <circle cx="20" cy="16" r="11" stroke="white" stroke-width="2" />
        </g>
        <g clip-path="url(#clip0_4767_7235)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M23.1978 21.3909L23.1236 21.6021C23.0792 21.7285 22.9405 21.7958 22.8137 21.7525L21.7555 21.3909H23.1978ZM22.9877 10.2626C23.5049 10.3899 23.8209 10.9124 23.6935 11.4296C23.5702 11.9304 23.0764 12.2425 22.5757 12.1462L22.3922 12.5339H25.2986C25.4334 12.5339 25.5433 12.6439 25.5433 12.7786V20.6569C25.5433 20.7916 25.4334 20.9015 25.2986 20.9015H17.5468C17.412 20.9015 17.3021 20.7916 17.3021 20.6569V12.7786C17.3021 12.6439 17.412 12.5339 17.5468 12.5339H21.852L22.135 11.936C21.864 11.7069 21.7301 11.3363 21.8207 10.9684C21.9481 10.4512 22.4706 10.1352 22.9877 10.2626ZM22.3922 12.5339H21.852L21.1321 14.0553C21.0745 14.1772 21.1268 14.3228 21.2487 14.3803C21.3706 14.4378 21.5161 14.3856 21.5737 14.2637L22.3922 12.5339ZM19.1127 19.0657H23.7327C23.8678 19.0657 23.9774 18.9562 23.9774 18.821C23.9774 18.6859 23.8678 18.5763 23.7327 18.5763H19.1127C18.9776 18.5763 18.868 18.6859 18.868 18.821C18.868 18.9561 18.9776 19.0657 19.1127 19.0657ZM19.1127 17.3162H23.7327C23.8678 17.3162 23.9774 17.2066 23.9774 17.0715C23.9774 16.9364 23.8678 16.8268 23.7327 16.8268H19.1127C18.9776 16.8268 18.868 16.9364 18.868 17.0715C18.868 17.2066 18.9776 17.3162 19.1127 17.3162ZM19.1127 15.5667H23.7327C23.8678 15.5667 23.9774 15.4571 23.9774 15.322C23.9774 15.1869 23.8678 15.0773 23.7327 15.0773H19.1127C18.9776 15.0773 18.868 15.1869 18.868 15.322C18.868 15.4571 18.9776 15.5667 19.1127 15.5667ZM16.8127 19.702V12.7786C16.8127 12.3738 17.1419 12.0446 17.5468 12.0446H20.1626L17.2507 11.0739C17.1229 11.0313 16.9839 11.1008 16.9412 11.2286L14.4691 18.6445C14.4267 18.7718 14.4952 18.9101 14.6221 18.9534L16.8127 19.702Z"
            fill="white" />
        </g>
        <defs>
          <filter id="filter0_d_4767_7235" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4767_7235" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4767_7235" result="shape" />
          </filter>
          <clipPath id="clip0_4767_7235">
            <rect width="12" height="12" fill="white" transform="translate(14 10)" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div style="margin: auto;margin-right: 15px;font-size: 10px;">{{numbedPipe(data['difference'])}}</div>
  </div>
  <app-spark-line-bar-grid [type]="'normal'"  [maxValue]="maxValue" [dynamicId]="getId(paramsData.data['period'],paramsData.colDef.field)"
    [data]="data"></app-spark-line-bar-grid>
<div *ngIf="Cumulative">
    <div style="margin: 0 7px;text-align: center;width: auto;"><hr></div>
     
    <div style="text-align: center;font-size: 13px;font-weight: 600;line-height: 6px;" ><span>Cumulative</span> </div>
    <div style="display: flex;">
      <div style="padding: 5px;font-size: 12px;"><span> # Difference:</span>
      </div>
      <div style="margin: auto;margin-right: 15px;font-size: 10px; ">{{numbedPipe(data['cumulative_qty_difference'])}}</div>
    </div>
    <app-spark-line-bar-grid  [type]="'cumulative'"   [maxValue]="maxValue2" [dynamicId]="getId(paramsData.data['period'],paramsData.colDef.field+'clone')"
    [data]="data"></app-spark-line-bar-grid>
  </div>
   </div> 
  <div *ngIf="objCondition(this.data)" [style.margin-top]="calculateMarginTop()" >
    <div style="text-align: center;"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9998 9.00023V13.0002M11.9998 17.0002H12.0098M10.6151 3.89195L2.39019 18.0986C1.93398 18.8866 1.70588 19.2806 1.73959 19.6039C1.769 19.886 1.91677 20.1423 2.14613 20.309C2.40908 20.5002 2.86435 20.5002 3.77487 20.5002H20.2246C21.1352 20.5002 21.5904 20.5002 21.8534 20.309C22.0827 20.1423 22.2305 19.886 22.2599 19.6039C22.2936 19.2806 22.0655 18.8866 21.6093 18.0986L13.3844 3.89195C12.9299 3.10679 12.7026 2.71421 12.4061 2.58235C12.1474 2.46734 11.8521 2.46734 11.5935 2.58235C11.2969 2.71421 11.0696 3.10679 10.6151 3.89195Z" stroke="#101D42" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </div>
<div style="font-size: 13px;text-align: center;font-family: 'Poppins';">There is no data available</div>
  </div> 
  <!-- <div _ngcontent-xgh-c224="" id="sparkLineBarChart">
    <svg height="32" style="background-color: transparent; position: absolute;width: 198px;">
      <g class="barGroupsparkLineBarChart" transform="translate(0,5)">
        <text class="barLabel" font-family="Poppins" fill="#6C758F" font-size="10px" x="5" y="5.5"
          font-weight="500">Inventory:</text>
        <rect class="barRect" x="60" rx="2"  [style.width]="(data['INVENTORY QTY DISPENSED']/maxValue)*100" height="6" fill="#EF933E"
          stroke="#EF933E"></rect>
        <text class="barValue" x="190" y="5.5" font-family="Poppins" fill="#101D42" font-size="10px" text-anchor="end"
          font-weight="500">{{getData(data['INVENTORY QTY DISPENSED'])}} </text>
      </g>
      <g class="barGroupsparkLineBarChart" transform="translate(0,21)">
        <text class="barLabel" font-family="Poppins" fill="#6C758F" font-size="10px" x="5" y="5.5"
          font-weight="500">Shipment:</text>
        <rect class="barRect" x="60" rx="2" [style.width]="(data['DISPENSE QTY SHIPPED']/maxValue)*100" height="6" fill="#645DD7"
          stroke="#645DD7"></rect>
        <text class="barValue" x="190" y="5.5" font-family="Poppins" fill="#101D42" font-size="10px" text-anchor="end"
          font-weight="500">{{ getData(data['DISPENSE QTY SHIPPED'])}}</text>
      </g>
    </svg>
  </div> -->
</div>

<!-- popup Comment Menu   [hasBackdrop]="false" -->
<mat-menu #menu="matMenu">
  <div class="laptopContent" *ngIf="!deviceDetectionService.isMobile">

    <div class="header">
      <div (click)="$event.stopPropagation()" style="margin:6px auto 2px 18px !important;width: 100%;">
        <span>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?"Overall": paramsData.data['period'] | date:'dd MMMM,yyyy'}}</span>
        <h2 style=" margin-top: -5px !important; font-family: 'poppins';">{{"Detailed information"}}</h2>
      </div>
      <button mat-icon-button class="close-button"  (click)="closeDialog()">
        <mat-icon class="close-icon" style=" height: auto !important; width: auto !important;"
         >close</mat-icon>
      </button>
    </div>
    <div class="leftDiv" #Container (click)="$event.stopPropagation()">

      <table style="width:100%">
        <tr>
          <td>SP:</td>
          <td [matTooltip]="paramsData.colDef.field" matTooltipPosition="above" class="b dotedtext " >{{paramsData.colDef.field||' NO NAME'}}</td>

        </tr>
        <tr>
          <td>Difference %:</td>
          <td class='b'>0%</td>

        </tr>
        <tr>
          <td>Total Difference:</td>
          <td class='b'>{{ numbedPipe(data?.['Difference'])||0}}</td>

        </tr>
        <tr>
          <td>Inventory Dispenses:</td>
          <td class='b'>{{numbedPipe(data?.['inventory_quantity'])||"Not Available"}}</td>

        </tr>
        <tr>
          <td>Shipment Dispenses:</td>
          <td class='b'>{{numbedPipe(data?.['dispense_quantity'])||"Not Available"}}</td>

        </tr>
        <tr *ngIf="Cumulative">
          <td>Total Cumulative Difference:</td>
          <td class='b'>{{ numbedPipe(data?.['Cumulative_Difference'])||0}}</td>

        </tr>
        <tr *ngIf="Cumulative">
          <td> Cumulative Inventory Dispenses:</td>
          <td class='b'>{{numbedPipe(data?.['inventory_quantity_Cumulative'])||"Not Available"}}</td>

        </tr>
        <tr *ngIf="Cumulative">
          <td> Cumulative Shipment Dispenses:</td>
          <td class='b'>{{numbedPipe(data?.['dispense_quantity_Cumulative'])||"Not Available"}}</td>

        </tr>
        <tr>
          <td>Week-Ending:</td>
          <td class='b'>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?"Overall":paramsData.data['period'] | date:'MM.dd.yyyy'}}</td>

        </tr>
        <tr>
          <td>Inventory Failures:</td>
          <td class='b'>{{data?.['Inventory_Failed_Quantity']?data['Inventory_Failed_Quantity']:'0' +" FAILURES" }}</td>

        </tr>
      </table>
      <div class="leftbottomDiv" *ngIf="data?.['discrepancy']=='Y' && this.data['Difference'] == 0">
        <div style="width: 100%; float:left ">
          <span  *ngIf="data?.['discrepancy']=='Y'&& this.data['Difference'] == 0" style="color: red !important; font-size: 12px">
            <svg style="margin: 1px 0px 3px 1px;" width="12" height="12" viewBox="0 0 10 10" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 7C5.13807 7 5.25 6.88807 5.25 6.75C5.25 6.61193 5.13807 6.5 5 6.5C4.86193 6.5 4.75 6.61193 4.75 6.75C4.75 6.88807 4.86193 7 5 7Z"
                fill="#D11044" />
              <path
                d="M5 5V3M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5ZM5.25 6.75C5.25 6.88807 5.13807 7 5 7C4.86193 7 4.75 6.88807 4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75Z"
                stroke="#D11044" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Has differences at locations level.</span>
            <span  *ngIf="data?.['discrepancy']=='N'" style="color: green !important; font-size: 12px">No differences at locations level.</span>
        </div>

        <!-- <div style="width: 40%; float:right">
          <button mat-raised-button color="primary" style=" border-radius: 7px;">Locations view</button>
        </div> -->
      </div>
    </div>
    <div class="rightDiv" (click)="$event.stopPropagation()">
      <h4>Comments: {{numbedPipe(messages?.length)}}</h4>

      <div  [style]="getStyles()" (click)="$event.stopPropagation()">
        <div id="content">
        <div *ngFor="let message of messages; let i = index">
          <div style="display: flex; margin-top:8px">
            <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
              <!-- menu for comments -->
          <ng-template #customTemplate let-options="options" > <!--[hasBackdrop]="false"-->>
            <button mat-menu-item style="text-align: left;" mat-dialog-close
              (click)="editComment(message,$event,i)"><mat-icon svgIcon="edit"></mat-icon>  <span style="font-family: 'poppins';">Edit</span></button>
            <button mat-menu-item style="text-align: left;" mat-dialog-close (click)="DelDialog('300ms', '150ms')">
              <mat-icon svgIcon="delete"></mat-icon> <span style="font-family: 'poppins';">Delete</span></button>
          </ng-template>

         
            <div class="card"
              style="background-color:#F6F8FB;margin-right:8px ;border-radius: 0px 5px 5px 3px;padding: 5px; position: relative;">
              <div style="display: flex;">
                <div style="width: 212px;font-size: 10px;font-weight: 600; float: left;">
                  <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                  <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                  <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                </div>
                <div style="width: 82px;font-size: 9px;font-weight: 400; float: right;">
                  <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                </div>
              </div>
              <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                <span *ngIf="!edit">{{ message.comments }}</span>
                <textarea  rows="4" cols="33" *ngIf="edit && editIndex==i" [(ngModel)]="editedValue" type="text" id="edit"
                  [value]="message.comments"></textarea>
                  <div style="width: 100%; text-align: end;">
                <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                  (click)="SaveEdit(message ,i)"><mat-icon>check</mat-icon></button>
                <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                  (click)="edit=false"><mat-icon>close</mat-icon></button>
                </div>
              </div>
               <!-- Delete Popup -->
          <ng-template #DeleteDialog>
            <div>
              <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                  <mat-icon class="close-icon"
                    style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                </button>
              </div>
              <div style="height: 40px; margin: 10px auto; text-align: center;">
                Are you sure you want to delete this comment?
              </div>
              <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                  mat-dialog-close  cdkFocusInitial >Cancel</button>
                <button mat-raised-button style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
                  (click)="deleteComment(message ,i)">Delete </button>

              </div>
            </div>
          </ng-template>
            </div>
            <mat-icon (click)="openDialog($event)" *ngIf="employee_key._value == message.employee_key"
              style="height: 30px !important; width: 30px !important; margin: auto !important"
              svgIcon="menu" (click)="getcomentData(message,i)"></mat-icon>
          </div>
         
        </div>
      </div>
      </div>

      <!-- bottom input box  -->
      <div style="width: 100%; height: 10%;" (click)="$event.stopPropagation()">

        <input id="comment"
          style=" font-size: 12px;color: #8d8b8b;width: 86%;float: left; margin: 7px 0px 1px 5px;height: 27px;"
          type="search" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)"
          class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue">

        <mat-icon style="width: auto;float: right;" class="matIcons" (click)="messageData(searchValue ,$event)"
          svgIcon="send"></mat-icon>

      </div>
      <div class="rightbottomDiv" *ngIf="!(paramsData?.params?.sourceName)">
        <div style="width: 60%; float:left ;margin-top: 6px;">
          <mat-slide-toggle  [(ngModel)]="isChecked" (click)="$event.stopPropagation()"> <span style="font-family: poppins !important;">Reconcile</span></mat-slide-toggle>
        </div>

        <div style="width: 40%; float:right;text-align: end;">
          <button mat-raised-button [disabled]="!isChecked" color="primary" style="height: 30px !important  ;    margin-top: 6px; width: 30px;  border-radius: 7px;"
            (click)="$event.stopPropagation()"><span
              style="margin: -18px -12px;position: absolute;">Apply</span></button>
        </div>
      </div>
    </div>

  </div>

  <div class="mobileContent" *ngIf="deviceDetectionService.isMobile" (click)="$event.stopPropagation()">
    <div class="header" style="height: auto !important; display: block !important;">
      <div class="d-flex justify-content-between align-items-center p-2">
        <div>
          <span>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?"Overall": paramsData.data['period'] | date:'dd MMMM,yyyy'}}</span>
        </div>
        <div>
          <button mat-icon-button class="close-button" style="margin:0px !important;" (click)="closeDialog()">
            <mat-icon class="close-icon" style="height: auto !important; width: auto !important;"
             >close</mat-icon>
          </button>
        </div>
      </div>
    </div>


    <div>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 style="font-family: 'poppins';display: contents;">{{"Detailed information"}}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table style="width:100%; height: 100px;">
            <tr>
              <td>SP:</td>
              <td [matTooltip]="paramsData.colDef.field" matTooltipPosition="above" class="b dotedtext " >{{paramsData.colDef.field||' NO NAME'}}</td>
    
            </tr>
            <tr>
              <td>Difference %:</td>
              <td class='b'>0%</td>
    
            </tr>
            <tr>
              <td>Total Difference:</td>
              <td class='b'>{{ numbedPipe(data['Difference'])||0}}</td>
    
            </tr>
            <tr>
              <td>Inventory Dispenses:</td>
              <td class='b'>{{numbedPipe(data['inventory_quantity'])||"Not Available"}}</td>
    
            </tr>
            <tr>
              <td>Shipment Dispenses:</td>
              <td class='b'>{{numbedPipe(data['dispense_quantity'])||"Not Available"}}</td>
    
            </tr>
            <tr *ngIf="Cumulative">
              <td>Total Cumulative Difference:</td>
              <td class='b'>{{ numbedPipe(data['Cumulative_Difference'])||0}}</td>
    
            </tr>
            <tr *ngIf="Cumulative">
              <td> Cumulative Inventory Dispenses:</td>
              <td class='b'>{{numbedPipe(data['inventory_quantity_Cumulative'])||"Not Available"}}</td>
    
            </tr>
            <tr *ngIf="Cumulative">
              <td> Cumulative Shipment Dispenses:</td>
              <td class='b'>{{numbedPipe(data['dispense_quantity_Cumulative'])||"Not Available"}}</td>
    
            </tr>
            <tr>
              <td>Week-Ending:</td>
              <td class='b'>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?"Overall":paramsData.data['period'] | date:'MM/dd/yyyy'}}</td>
    
            </tr>
            <tr>
              <td>Inventory Failures:</td>
              <td class='b'>{{data?.['Inventory_Failed_Quantity']?data['Inventory_Failed_Quantity']:'0' +" FAILURES" }}</td>
    
            </tr>
          </table>
          <div class="leftbottomDivinMobile">
            <span  *ngIf="data?.['discrepancy']=='Y'" style="color: red !important; font-size: 12px">
              <svg style="margin: 1px 0px 3px 1px;" width="12" height="12" viewBox="0 0 10 10" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5 7C5.13807 7 5.25 6.88807 5.25 6.75C5.25 6.61193 5.13807 6.5 5 6.5C4.86193 6.5 4.75 6.61193 4.75 6.75C4.75 6.88807 4.86193 7 5 7Z"
                  fill="#D11044" />
                <path
                  d="M5 5V3M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5ZM5.25 6.75C5.25 6.88807 5.13807 7 5 7C4.86193 7 4.75 6.88807 4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75Z"
                  stroke="#D11044" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Has differences at locations level.</span>
              <span  *ngIf="data?.['discrepancy']=='N'" style="color: green !important; font-size: 12px">No differences at locations level.</span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <div class="rightDivinMobile" (click)="$event.stopPropagation()">
      <h4>Comments: {{numbedPipe(messages?.length)}}</h4>

      <div  [style]="getStyles()" (click)="$event.stopPropagation()">
        <div id="content">
        <div *ngFor="let message of messages; let i = index">
          <div style="display: flex; margin-top:8px">
            <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
              <!-- menu for comments -->
          <ng-template #customTemplate let-options="options" > <!--[hasBackdrop]="false"-->
            <button mat-menu-item style="text-align: left;" mat-dialog-close
              (click)="editComment(message,$event,i)"><mat-icon svgIcon="edit"></mat-icon>  <span style="font-family: 'poppins';">Edit</span></button>
            <button mat-menu-item style="text-align: left;" mat-dialog-close (click)="DelDialog('300ms', '150ms')">
              <mat-icon svgIcon="delete"></mat-icon> <span style="font-family: 'poppins';">Delete</span></button>
          </ng-template>

         
            <div class="card"
              style="background-color:#F6F8FB;margin-right:8px ;border-radius: 0px 5px 5px 3px;padding: 5px; position: relative;">
              <div style="display: flex;">
                <div style="width: 212px;font-size: 10px;font-weight: 600; float: left;">
                  <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                  <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                  <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                </div>
                <div style="width: 82px;font-size: 9px;font-weight: 400; float: right;">
                  <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                </div>
              </div>
              <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                <span *ngIf="!edit">{{ message.comments }}</span>
                <textarea  rows="4" cols="33" *ngIf="edit && editIndex==i" [(ngModel)]="editedValue" type="text" id="edit"
                  [value]="message.comments"></textarea>
                  <div style="width: 100%; text-align: end;">
                <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                  (click)="SaveEdit(message ,i)"><mat-icon>check</mat-icon></button>
                <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                  (click)="edit=false"><mat-icon>close</mat-icon></button>
                </div>
              </div>
               <!-- Delete Popup -->
          <ng-template #DeleteDialog>
            <div>
              <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                  <mat-icon class="close-icon"
                    style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                </button>
              </div>
              <div style="height: 40px; margin: 10px auto; text-align: center;">
                Are you sure you want to delete this comment?
              </div>
              <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                  mat-dialog-close  cdkFocusInitial >Cancel</button>
                <button mat-raised-button style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
                  (click)="deleteComment(message ,i)">Delete </button>

              </div>
            </div>
          </ng-template>
            </div>
            <mat-icon (click)="openDialog($event)" *ngIf="employee_key._value == message.employee_key"
              style="height: 30px !important; width: 30px !important; margin: auto !important"
              svgIcon="menu" (click)="getcomentData(message,i)"></mat-icon>
          </div>
         
        </div>
      </div>
      </div>

      <!-- bottom input box  -->
      <div style="width: 100%; height: 10%;" (click)="$event.stopPropagation()">

        <input id="comment"
          style=" font-size: 12px;color: #8d8b8b;width: 86%;float: left; margin: 7px 0px 1px 5px;height: 27px;"
          type="search" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)"
          class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue">

        <mat-icon style="width: auto;float: right;" class="matIcons" (click)="messageData(searchValue ,$event)"
          svgIcon="send"></mat-icon>

      </div>
      <div class="rightbottomDiv" *ngIf="!(paramsData?.params?.sourceName)">
        <div style="width: 60%; float:left ;margin-top: 6px;">
          <mat-slide-toggle  [(ngModel)]="isChecked" (click)="$event.stopPropagation()"> <span style="font-family: poppins !important;">Reconcile</span></mat-slide-toggle>
        </div>

        <div style="width: 40%; float:right;text-align: end;">
          <button mat-raised-button [disabled]="!isChecked" color="primary" style="height: 30px !important  ;    margin-top: 6px; width: 30px;  border-radius: 7px;"
            (click)="$event.stopPropagation()"><span
              style="margin: -18px -12px;position: absolute;">Apply</span></button>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
