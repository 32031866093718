import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import * as cloud from 'd3-cloud';
import { mark } from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import { ColDef } from 'ag-grid-community';
import { CurrencyPipe } from '@angular/common';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { EnhancementService } from 'src/app/services/enhancements-service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-bullet-chart',
  templateUrl: './bullet-chart.component.html',
  styleUrls: ['./bullet-chart.component.scss']
})
export class BulletChartComponent implements OnInit, OnChanges {
  @ViewChild('bulletChartContainer', { static: true }) bulletChartContainer!: ElementRef
  @ViewChild('bulletChartTooltip', { static: true }) bulletChartTooltip!: ElementRef
  @Input('item') item: any
  @Input('data') data: any
  @Input('totalData') totalData: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @Input('pageKey') pageKey: any
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  iconList: any
  renderLinePopup: boolean = false
  isActive = false;
  lineColour: any
  BulletChartData: any=[];
  props: any;
  divId: any = "";
  selectedType = 'D'
  sourceName: any; locationName: any
  timeCycleData: any = []
  sName: any
  lName: any
  linecolor: any
  noData:boolean=false
  dataTurn: any
  tooltip = false
  enhancedCount: any = 0
  totalPayout: any = 0
  coreAmount: any = 0
  initiateChart: boolean = false
  editData: boolean = false
  mytooltipData:any
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }

  closeFullscreen(): void {
    this.props.chartHeight =this.item.config['chart_type']=='bullet'?250: 500
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
  clientKey: any
  employeeKey: any
  constructor(private currency: CurrencyPipe, private filterServiceOld: FilterService, private route: ActivatedRoute,public enhancementService:EnhancementService) {
    this.filterServiceOld.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }


//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  public defaultColDef: ColDef<any> = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: true,
    suppressMenu:true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    autoHeight:true,  
    wrapText:true
  };
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
    // console.log(myData)
    // if(this.item.config['chart_type']=='bullet')return
    this.mytooltipData = myData
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
    this.tooltip=true
    if(this.item.config['chart_type']=='bullet')
    {if (this.dataTurn < 250) {
      d3.select(this.bulletChartTooltip.nativeElement).select("#d3bulletTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 20 + 'px')
        .style('right', (this.dataTurn +20) + 'px')
        .style('left', 'unset')
        .style('bottom', 'unset')
    }
    else if (this.dataTurn > 250) {

    d3.select(this.bulletChartTooltip.nativeElement).select("#d3bulletTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (  myY + 20) + 'px')
        .style('left', (myX+30) + 'px')
        .style('right', 'unset')
        .style('bottom', 'unset')
    }}else{
      d3.select(this.bulletChartTooltip.nativeElement).select("#d3bulletTooltip")
      .style('visibility', 'visible')
      .style('position', 'absolute')
      .style('top', (  myY + 20) + 'px')
      .style('left', (myX+30) + 'px')
      .style('right', 'unset')
      .style('bottom', 'unset')
    }
  }

  private hideTooltip(myType: any): void {
    this.tooltip = false
    // d3.select("#d3WordCloudTooltip")
      // .style('visibility', 'hidden');
  }
  


  public columnDefs: any = [
    { minWidth:135, field: 'file_type', headerName: 'Filetype',cellStyle:{'display': 'flex',},headerClass: 'text-left' },
    {  minWidth:140, field: 'max_payment', headerName: 'Target payment',headerClass: 'text-right',cellStyle:{'display': 'flex','justify-content': 'right','align-items': 'center','text-align': 'center',} , valueGetter: (params: any) =>  this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.data['max_payment']), valueFormatter: (params: any) =>  this.enhancementService.currentQuarterFlag?'-': params.value },
    {  minWidth:140, field: 'corrected_amount', headerName: 'Payment after corrections',headerClass: 'text-right',cellStyle:{'display': 'flex','justify-content': 'right','align-items': 'center','text-align': 'center',},valueGetter: (params: any) =>  this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.data['corrected_amount']),  valueFormatter: (params: any) => this.enhancementService.currentQuarterFlag?'-': params.value },
    {  minWidth:100, field: 'delay_penalty', headerName: 'Delay penalty', headerClass: 'text-right',cellStyle:{'display': 'flex','justify-content': 'right','align-items': 'center','text-align': 'center',},valueGetter: (params: any) =>  this.enhancementService.currentQuarterFlag?'-': (params.data['delay_penalty']+'%'), valueFormatter: (params: any) =>this.enhancementService.currentQuarterFlag?'-':  params.value },
    {  minWidth:140, field: 'amount_payable', headerName: 'Payment earned', headerClass: 'text-right',cellStyle:{'display': 'flex','justify-content': 'right','align-items': 'center','text-align': 'center',},valueGetter: (params: any) =>  this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.data['amount_payable']), valueFormatter: (params: any) => this.enhancementService.currentQuarterFlag?'-': params.value },
  ];
  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.divId = this.item.config?.divid
    this.initiateCharts();
  }
  editDatafn() {
    this.editData = true
  }

  saveData() {
    this.totalPayout = this.totalData?.[0]?.core_services_amount + parseFloat(this.enhancedCount)
    this.editData = false
    this.insetData()
  }

  getInputData(e: any) {
    this.enhancedCount = e.target.value
  }
  reqSubcription: Subscription[] = [];
  enahcedKey: any = 0

  updateData() {
    // debugger
    var empkey: any      = sessionStorage.getItem("employee_key");
    let employee: any    = JSON.parse(empkey);
    this.employeeKey     = employee?._value
    const obj:any = {
      api_key   : 100197,
      report_typ: "D",
    }
    if(this.enhancementService.currentQuarterFlag ) {
      obj["table_nm_version"] = 'curr'
    } else {
      obj["table_nm_version"] = 'hist'
    }
    this.reqSubcription.push(this.filterServiceOld.executebulletQuery(obj).subscribe((resp: any) => {
      // debugger
      if (resp.length > 0) {
        this.enahcedKey    = resp[0].enhanced_services_key ? resp[0].enhanced_services_key : null
        this.coreAmount    = resp[0].core_services_amount || 0
        this.enhancedCount = resp[0].enhanced_services_amount || 0
        this.totalPayout   = resp[0].total_payout || 0
      }
      if (resp.length == 0) {
        // this.enahcedKey = resp[0].enhanced_services_key ? resp[0].enhanced_services_key : null
        this.coreAmount    = 0
        this.enhancedCount = 0
        this.totalPayout   = 0
      }
    }))
  }

  insetData() {
    const obj:any = {
      api_key: 100197,
      report_typ: "U" ,
      enhanced_services_amount: parseFloat(this.enhancedCount),
    }
    if(this.enhancementService.currentQuarterFlag ) {
      obj["table_nm_version"] = 'curr'
    } else {
      obj["table_nm_version"] = 'hist'
    }
    // enhanced_services_key: this.enahcedKey
    // report_typ: this.enahcedKey ? "U" : "I",

    this.reqSubcription.push(this.filterServiceOld.executebulletQuery(obj).subscribe(
      (resp: any) => {
      if(resp)
      {
        console.log(resp)

      } else {

      }
    },
    (error:any) => {
      console.log(error)
    }
    ))
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue != changes['data']?.previousValue && this.initiateChart) {
      this.getBulletChartData();

    }
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select(this.bulletChartContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append('g').attr("class", "xAxis" + myClass);
    this.initiateChart = true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    // define core variables
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    let height = myChart.props.chartHeight;
    const margins = { left: 15, right: 15, top: 60, bottom: 20};
    const bulletMin = 70;
    if((height - margins.top - margins.bottom)/myChart.BulletChartData.length < bulletMin){
      // height changes if needed
      height = margins.top + margins.bottom + (myChart.BulletChartData.length * bulletMin)
    }
    mySvg.attr("height", height);
    // gather y ticks and define y scale
    const yTicks: any = [];
    myChart.BulletChartData.forEach((d: any) => yTicks.push(d[myChart.props.groupVar]));
    const yScale: any = d3.scaleBand().domain(yTicks).range([0,height - margins.top - margins.bottom])
    // get x max and define x scale
    let xMax: any = d3.max(myChart.BulletChartData, (d: any) =>
      Math.max(d[myChart.props.bulletVar], d[myChart.props.maxVar]));
    let xScale = d3.scaleLinear().domain([0,xMax])
      .range([0,width - margins.left - margins.right]);
    // add another tick to xScale if last one not > data max
    const xTicks = xScale.ticks(6);
    if(xTicks[xTicks.length - 1] < xMax){
      const interval = xTicks[1] - xTicks[0];
      xMax = xTicks[xTicks.length - 1] + interval;
      xScale.domain([0,xMax])
    }
    if(!myChart.props.multiAxis){ 
    // define axes (define + format)
    mySvg.select(".xAxis" + myClass)
      .call(d3.axisBottom(xScale).tickSizeOuter(0).ticks(6,myChart.props.targetFormat))
      .attr("transform", "translate(" + margins.left + "," + (height - margins.bottom) + ")")

    mySvg.selectAll(".xAxis" + myClass + " text")
      .attr('font-family', 'Poppins')
      .attr('fill', '#8A98AB')
      .attr('font-size', '10px');

    mySvg.selectAll(".xAxis" + myClass + " path")
      .style('display', 'none');

    mySvg.selectAll(".xAxis" + myClass + " line")
      .attr('y1', 0)
      .attr('y2',-(height - margins.top - margins.bottom))
      .attr('stroke', '#E7E7E7') //dark color
      // .attr('stroke', '#727272')  // light color
      .attr('stroke-dasharray', '4,4');
      myChart.BulletChartData.map((m: any) => m.width = xScale(m[myChart.props.maxVar]))
    }
    // clearing previous class
    mySvg.selectAll('.chartGroup' + myClass).remove()
    // map the width
    // append data to bullet group
    const chartGroup = mySvg
      .selectAll('.chartGroup' + myClass)
      .data(myChart.BulletChartData)
      .join((group: any) => {
        const enter = group.append('g').attr('class', 'chartGroup' + myClass);
        enter.append('g').attr('class', function(d: any) {return 'xAxis' + (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        enter.append('path').attr('class', function(d: any) {return'backgroundRect'+ (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        enter.append('path').attr('class', function(d: any) {return'bulletRect'+ (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        enter.append('rect').attr('class', function(d: any) {return'targetLine'+ (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        enter.append('text').attr('class', function(d: any) {return'targetLabel'+ (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        enter.append('text').attr('class', function(d: any) {return'bulletLabel'+ (d[myChart.props.groupVar]).replaceAll(' ','_') + myClass});
        return enter;
      });
      
      // define core vars
    const chartWidth = width - margins.left - margins.right;
    const bulletHeight = yScale.bandwidth() - (myChart.props.multiAxis ? 45 : 30);
    const targetHeight = bulletHeight * 0.4;
    const multiAxis = myChart.props.multiAxis
    // define axes (define + format)
    // let xmScale: any
    if (myChart.props.multiAxis) {
      chartGroup.each(function (d: any, i: any) {
        let xMax1: any = d3.max([d], (d1: any) => Math.max(d1[myChart.props.maxVar], d1[myChart.props.targetVar]));//* (1.05)
        // console.log(xMax1)
        const xmScale = d3.scaleLinear().domain([0, xMax1]).range([0, chartWidth]);
        // add another tick to xScale if last one not > data max
        const xTicks1 = xmScale.ticks(6);
        if (xTicks1[xTicks1.length - 1] < xMax1) {
          const interval = xTicks1[1] - xTicks1[0];
          xMax1 = xTicks1[xTicks1.length - 1] + interval;
          xmScale.domain([0, xMax1])
        }
        const className = (d[myChart.props.groupVar]).replaceAll(' ', '_') + myClass
        myChart.BulletChartData.map((m: any) => m.width = xmScale(m[myChart.props.bulletVar]))
        myChart.BulletChartData.map((m: any) => m.maxWidth = chartWidth );// xmScale(m[myChart.props.maxVar]))
        drawDhart(className,xmScale,xTicks1.length)
      });
      chartGroup.attr('transform', (d: any, i: any) => `translate(0, ${i * 5})`); // Translate each group verticall

    }else{
        chartGroup.each(function (d: any, i: any) {
        
          const className = (d[myChart.props.groupVar]).replaceAll(' ', '_') + myClass
          drawDhart(className,xScale ,xTicks.length)
        });
      }
  function drawDhart(className:any,xmScale:any,ticketCount:any){ 

    if(myChart.props.multiAxis){  // Update the x-axis
      chartGroup.select('.xAxis' +className)
      .call(d3.axisBottom(xmScale).tickSizeOuter(0).ticks(6, myChart.props.targetFormat))
      .attr("transform", (d: any) => "translate(" + margins.left + "," + (margins.top + yScale(d[myChart.props.groupVar]) + bulletHeight + 14) + ")");
      
      
      chartGroup.selectAll('.xAxis' +className + " text")
      .attr('font-family', 'Poppins')
      .attr('fill', '#8A98AB')
      .attr('x', (d: any, i: any) => i == ticketCount ? -7 : 0)
      .attr('y', 2)
      .attr('font-size', '10px')
      .text((d: any) => d3.format(myChart.props.xAsistargetFormat)(d));
      
      chartGroup.selectAll('.xAxis' +className + " path")
      .style('display', 'none');
      
      chartGroup.selectAll('.xAxis' +className + " line")
      .attr('y1', 0)
      .attr('y2', -(14 + bulletHeight))
      .attr('stroke', '#727272')
      .attr('stroke-dasharray', '4,4');}
    

    // left hand label
    chartGroup.select(".bulletLabel"+className)
      .attr("x", margins.left-10)
      .attr("y", (d: any) => margins.top  + yScale(d[myChart.props.groupVar]) - 10)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '12px')
      .attr('font-weight', '500')
      .text((d: any) => d[myChart.props.groupVar]);
    // background rect a path for rounded edge
    chartGroup.select(".backgroundRect"+className)
      .attr('fill', '#E7EFF8')
      .attr("d", (d: any) =>"M 0,0 L " + (d.maxWidth -12) + ",0 Q "+ d.maxWidth
    + ", 0, " + d.maxWidth + ",12 L " + d.maxWidth + ", " + (bulletHeight - 12)
    + " Q " + d.maxWidth + ", " + bulletHeight + ", " + (d.maxWidth - 12) +
    ", " + bulletHeight + " L 0, " + bulletHeight + " Z")
      .attr("transform", (d: any) => "translate(" + margins.left + "," +
        (margins.top  + yScale(d[myChart.props.groupVar]) + 6) + ")");
    // bullet rect a path for rounded edge
      chartGroup.select(".bulletRect"+className)
      .style('display',myChart.props.bar?'':'none')
      .attr('fill',  (d:any, i:any) =>  (d.buffer_records < d.actual_records ) ? myChart.props.targetFill[1] : myChart.props.targetFill[0])
      .attr("d", (d: any) => "M 0,0 L " + (d.width == 0 ? 0 : d.width- 4) + ",0 Q " + d.width
        + ", 0, " + d.width + ",4 L " + d.width + ", " + (targetHeight - 4)
        + " Q " + d.width + ", " + targetHeight + ", " + (d.width == 0 ? 0 : d.width- 4) +
        ", " + targetHeight + " L 0, " + targetHeight + " Z")
      .attr("transform", (d: any) => "translate(" + margins.left + "," +
        (margins.top  + yScale(d[myChart.props.groupVar]) + 6 + ((bulletHeight-targetHeight)/2)) + ")")
      .on("mouseover", (event: any, d: any) => {
        myChart.showTooltip('bar',d,event.offsetX, event.offsetY,width)
      })
      .on("mouseout",() => {
        myChart.hideTooltip("bar");
      });
   
    // target line
    chartGroup.select(".targetLine" +className)
      .attr("rx", 1.5)
      .attr("ry", 1.5)
      .attr('fill', "#FF9533")
      .attr("x", (d: any) => margins.left + xmScale(d[myChart.props.targetVar])- 1.5)
      .attr("y", (d: any) => margins.top + yScale(d[myChart.props.groupVar])
      + 6 + (bulletHeight * 0.2))
      .attr("width", 3)
      .attr("height", bulletHeight * 0.6)
      .on("mouseover", (event: any, d: any) => {
        myChart.showTooltip('line',d,event.offsetX, event.offsetY,width)
      })
      .on("mouseout",() => {
        myChart.hideTooltip("line");
      });
    // target label
    chartGroup.select(".targetLabel"+className)
      .attr("rx", 1.5)
      .attr("text-anchor", "middle")
      .attr('fill', "#8A98AB")
      .attr('font-family', 'Poppins')
      .attr('font-size', '10px')
      .attr('font-weight', '500')
      // .attr("x", (d: any) => (margins.left +(multiAxis ? xmScale(d[myChart.props.targetVar])- 1.5: xScale(d[myChart.props.targetVar]) - 1.5))== (width-15)?(margins.left +(multiAxis ? xmScale(d[myChart.props.targetVar])- 1.5: xScale(d[myChart.props.targetVar]) - 1.5)):(margins.left +(multiAxis ? xmScale(d[myChart.props.targetVar])- 12: xScale(d[myChart.props.targetVar]) - 12)))
      .attr("x", (d: any) => ( (margins.left + xmScale(d[myChart.props.targetVar]) - 1.5) > 40 ) ? ((margins.left + xmScale(d[myChart.props.targetVar]) - 1.5) >= (width-25)?(margins.left + xmScale(d[myChart.props.targetVar]) - 1.5)-20:(margins.left + xmScale(d[myChart.props.targetVar]) - 1.5)) : 40 )
      .attr("y", (d: any) => margins.top + yScale(d[myChart.props.groupVar])
        + 3.5)
      .text((d: any) =>
        myChart.props.targetLabel
        +(myChart.props.targetVarLabel? d[myChart.props.targetVarLabel] :d3.format(myChart.props.targetFormat)(d[myChart.props.targetVar])) +myChart.props.surfixLabel)
      }
    drawLegend();
    function drawLegend() {
      // define legend data and append
      const legendData = myChart.props.targetFill
      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      // just a circle and a label here
      legendGroup.attr("transform", "translate(" + (margins.left) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any,i:number) => 'circle_legendItem' + d.replaceAll('#','_')+myClass)
        .attr('fill', (d: any,i:number) => d)
        .attr('r', 4);

      legendGroup.select('.legendLabel')
        .attr('x', 12)
        .attr('id', (d: any,i:number) => 'legendItem' +d.replaceAll('#','_')+myClass)
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any,i:number) => myChart.props?.legendLabel?.[i]?.toString().toUpperCase())
    }
    let legendX = 0;
    mySvg.selectAll('.legendLabel').each(function (): any {
      //@ts-ignore
      const myObject = this;
      const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
      d3.select(myObject).attr('x', legendX + 12);
      mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
      legendX += (30 + myWidth)
    });

    myChart.stop(10).then(() => myChart.isLoading = false);
  }
// property & data for the chart
  getBulletChartData(){
   
    
    // this.BulletChart.getBulletChartData().subscribe((res:any)=>{
    this.BulletChartData = this.data
//     this.BulletChartData = this.item.config['chart_type']=='bullettable' ?[
//       {
//         "source_key": 3007,
//         "file_type": "SP Status",
//         "max_payment": 81000,
//         "corrected_amount": 32473,
//         "delay_penalty": 5.38,
//         "amount_payable": 30725.95,
//         "qtr_end_date": "2023-12-31T00:00:00"
//     },
//     {
//         "source_key": 3007,
//         "file_type": "SP Dispense",
//         "max_payment": 88800,
//         "corrected_amount": 88740,
//         "delay_penalty": 0.07,
//         "amount_payable": 88677.88,
//         "qtr_end_date": "2023-12-31T00:00:00"
//     },
//     {
//         "source_key": 3007,
//         "file_type": "SP Inventory",
//         "max_payment": 18000,
//         "corrected_amount": 17589,
//         "delay_penalty": 2.28,
//         "amount_payable": 17187.97,
//         "qtr_end_date": "2023-12-31T00:00:00"
//     },
//     {
//         "source_key": 3007,
//         "file_type": "SP Competitor Dispense",
//         "max_payment": 235800,
//         "corrected_amount": 222995,
//         "delay_penalty": 0.41,
//         "amount_payable": 222080.72,
//         "qtr_end_date": "2023-12-31T00:00:00"
//     },
// ]  :[
//       {
//         "brand_key": 200107.0,
//         "source_key": 3007.0,
//         "max_records": 348.0,
//         "buffer_records": 70.0,
//         "buffer_pct": 20.0,
//         "qtr_end_date": "2023-12-31",
//         "actual_records": 65.0,
//         "display_nm": "Delayed records % vs actual %"
//       },
//       {
//         "brand_key": 200107.0,
//         "source_key": 3007.0,
//         "max_records": 348.0,
//         "buffer_records": 35.0,
//         "buffer_pct": 10.0,
//         "qtr_end_date": "2023-12-31",
//         "actual_records": 300.0,
//         "display_nm": "Fixed records % vs actual %"
//       }]
      let date=this.filterServiceOld.baseQuery.from_date
    this.props = this.item.config['chart_type']=='bullettable'?{
      chartHeight: 500,
      multiAxis:true,
      groupVar: "file_type",
      bulletVar: "amount_payable",
      targetVar: "max_payment",
      maxVar:"max_payment",
      targetVarLabel: undefined,
      targetLabel: "Max:",
      surfixLabel:' ',
      targetFormat: "$.4~s",
      xAsistargetFormat: "$.2~s",
      targetFill:["#1363DF"],
      legendLabel: ["Amount Payable "],
      bar:this.enhancementService.currentQuarterFlag?false:true
    }:{
      chartHeight: 250,
      multiAxis:true  ,
      groupVar: "file_type_name",
      bulletVar: "actual_records",
      targetVar: "buffer_records",
      targetVarLabel: "buffer_pct",
      maxVar:"max_records",
      targetLabel: "",
      surfixLabel:"% Buffer",
      targetFormat: "$.2~s",
      xAsistargetFormat: ".2~s",
      targetFill:["#1363DF", "#D11044"],
      legendLabel:['Records within Buffer Limit','Records when over Buffer Limit'],
      bar:true
      }
      this.data=this.BulletChartData
    // @ts-ignore
    if ( this.BulletChartData.length > 0) {
      this.noData=false
      setTimeout(() => {
        this.plotChart();
      }, 100);
     
     }else{
      this.noData=true
      this.isLoading=false
      }
    // });
    if(this.item.config['chart_type']=='bullettable'){
      setTimeout(() => {
        this.enhancedCount = this.totalData[0]?.enhanced_services_amount ?  this.totalData[0]?.enhanced_services_amount  : 0
        this.totalPayout = this.totalData[0]?.total_payout ?  this.totalData[0]?.total_payout : 0
        this.coreAmount = this.totalData?.[0]?.core_services_amount ? this.totalData?.[0]?.core_services_amount : 0
        // this.updateData()
    },300)
  }
  }
  // numberFormat
  numberFormating(num: number) {
    return d3.format("$.4~s")(num)
    // const map = [
    //   { suffix: 'T', threshold: 1e12 },
    //   { suffix: 'B', threshold: 1e9 },
    //   { suffix: 'M', threshold: 1e6 },
    //   { suffix: 'K', threshold: 1e3 },
    //   { suffix: '', threshold: 1 },
    // ];
    // const found = map.find((x) => Math.abs(num) >= x.threshold);
    // if (found) {
    //   const formatted = (num / found.threshold).toFixed(2) + found.suffix;
    //   return formatted;
    // }
    // return num;
  }

  numbedPipe(value: any) {
    return this.currency.transform(value, "", "", "1.0-2");
    //  return   this.numberpipe.transform(value)
  }
}