import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pending-information-popup',
  templateUrl: './pending-information-popup.component.html',
  styleUrls: ['./pending-information-popup.component.scss']
})
export class PendingInformationPopupComponent implements OnInit {
  @Input('pendingInformation') pendingInfo:any=[]
  @Output() showPendingTooltip = new EventEmitter();

  ngOnInit(): void {
    
  }
  hidePendingTooltip() {
    this.showPendingTooltip.next(false);
  }

}
