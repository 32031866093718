<!-- <div class="container-fluid"> -->
<div class="searchcontainer ">
    <svg width="100%" height="280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="280" fill="url(#paint0_linear_5_2931)" />
        <path d="M1114 0H1278V280H944L1114 0Z" fill="url(#paint1_linear_5_2931)" />
        <path d="M984 0H1078L908 280H814L984 0Z" fill="url(#paint2_linear_5_2931)" />
        <path d="M924 0H948L778 280H754L924 0Z" fill="url(#paint3_linear_5_2931)" />
        <path
            d="M1152.31 229.117V95.4624L1162.26 79.1914H1181.26L1209.45 90.9908V208.778C1205.04 313.958 1296.41 279.092 1296.41 281.416C1296.41 283.276 1285.28 293.426 1279.71 298.268C1258.98 313.145 1230.71 310.855 1217.4 310.855C1177.15 308.237 1152.31 270.132 1152.31 229.117Z"
            fill="url(#paint4_linear_5_2931)" />
        <path
            d="M1166.13 70.4339C1159.8 76.804 1153.06 89.2989 1152.3 95.1099C1159.67 87.2068 1164.68 83.4695 1177.33 83.4695C1189.99 83.4695 1198.48 92.4742 1232.45 113.758C1275.59 140.788 1324.14 167.727 1324.14 220.029C1324.14 270.134 1256.2 303.632 1244.3 310.218C1251.67 309.753 1327.01 302.56 1355.03 261.9C1366.33 245.509 1374.67 215.699 1370.69 187.218C1366.72 158.736 1359.85 145.601 1343.5 129.132C1312.09 97.5133 1268.19 70.1864 1254.18 62.2922C1242.11 55.4929 1229.49 44.4769 1205.44 49.2629C1190.72 53.4278 1172.45 64.0848 1166.13 70.4339Z"
            fill="url(#paint5_linear_5_2931)" />
        <defs>
            <linearGradient id="paint0_linear_5_2931" x1="9.0985" y1="-4.0514e-06" x2="1354.8" y2="210.262"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#337DF0" />
                <stop offset="1" stop-color="#1363DF" />
            </linearGradient>
            <linearGradient id="paint1_linear_5_2931" x1="787.322" y1="0.00204036" x2="1289.02" y2="28.623"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#337DF0" />
                <stop offset="1" stop-color="#1363DF" />
            </linearGradient>
            <linearGradient id="paint2_linear_5_2931" x1="657.322" y1="0.0020086" x2="1159.02" y2="28.6229"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#337DF0" />
                <stop offset="1" stop-color="#1363DF" />
            </linearGradient>
            <linearGradient id="paint3_linear_5_2931" x1="597.322" y1="0.00200801" x2="1099.02" y2="28.623"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#337DF0" />
                <stop offset="1" stop-color="#1363DF" />
            </linearGradient>
            <linearGradient id="paint4_linear_5_2931" x1="1289.53" y1="110.256" x2="1148.89" y2="118.267"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36AAFF" />
                <stop offset="0.4875" stop-color="#1363DF" />
                <stop offset="1" stop-color="#2B47D7" />
            </linearGradient>
            <linearGradient id="paint5_linear_5_2931" x1="1262" y1="48.0937" x2="1262" y2="310.218"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#5CE9FC" />
                <stop offset="0.346875" stop-color="#1396DF" />
                <stop offset="0.669792" stop-color="#1363DF" />
                <stop offset="1" stop-color="#131BDF" />
            </linearGradient>
        </defs>
    </svg>

    <div class="centered">
        <div class="SearchHeading">Hi, how can we help you? </div>
        <div class="SearchsubHeading">Search for answers or browse our knowledge base</div>
        <div class="input-group mt-3">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><svg width="18" height="18" viewBox="0 0 18 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
                            stroke="#8A98AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <input type="text" class="form-control" placeholder="Start typing your question or keyword..."
                aria-label="Username" aria-describedby="basic-addon1">
        </div>
    </div>
</div>

<div class="container-fluid ">
    <div class=" mt-4 mb-4">
        <div class="SearchHeading" style="color: #101D42;">Documentation Categories </div>
        <div class="SearchsubHeading" style="color: #8A98AB;">Browse our knowledge base to find answers to your
            questions</div>
    </div>
    <div class="row">

        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M31.9999 35.9997L27.9999 31.9997M31.9999 35.9997C33.8624 35.2913 35.6491 34.398 37.3333 33.333M31.9999 35.9997V42.6663C31.9999 42.6663 36.0399 41.933 37.3333 39.9997C38.7733 37.8397 37.3333 33.333 37.3333 33.333M27.9999 31.9997C28.7094 30.1589 29.6029 28.3944 30.6666 26.733C32.2202 24.249 34.3834 22.2037 36.9506 20.7918C39.5178 19.3799 42.4035 18.6482 45.3333 18.6663C45.3333 22.293 44.2933 28.6663 37.3333 33.333M27.9999 31.9997H21.3333C21.3333 31.9997 22.0666 27.9597 23.9999 26.6663C26.1599 25.2263 30.6666 26.6663 30.6666 26.6663M21.9999 37.9997C19.9999 39.6797 19.3333 44.6663 19.3333 44.6663C19.3333 44.6663 24.3199 43.9997 25.9999 41.9997C26.9466 40.8797 26.9333 39.1597 25.8799 38.1197C25.3617 37.625 24.679 37.3392 23.9629 37.317C23.2468 37.2949 22.5478 37.538 21.9999 37.9997Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></div>
                    <mat-card-title>Getting Started</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M42.6666 44C42.6666 42.1392 42.6666 41.2089 42.4369 40.4518C41.9199 38.7473 40.586 37.4134 38.8814 36.8963C38.1244 36.6667 37.194 36.6667 35.3332 36.6667H28.6666C26.8058 36.6667 25.8755 36.6667 25.1184 36.8963C23.4139 37.4134 22.08 38.7473 21.5629 40.4518C21.3333 41.2089 21.3333 42.1392 21.3333 44M37.9999 26C37.9999 29.3137 35.3136 32 31.9999 32C28.6862 32 25.9999 29.3137 25.9999 26C25.9999 22.6863 28.6862 20 31.9999 20C35.3136 20 37.9999 22.6863 37.9999 26Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <mat-card-title>Account and profile</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M27.9999 31.3338L30.6666 34.0005L36.6666 28.0005M42.6666 32.0005C42.6666 38.5451 35.528 43.305 32.9306 44.8203C32.6354 44.9925 32.4878 45.0786 32.2795 45.1233C32.1178 45.158 31.882 45.158 31.7203 45.1233C31.512 45.0786 31.3645 44.9925 31.0693 44.8203C28.4719 43.305 21.3333 38.5451 21.3333 32.0005V25.6239C21.3333 24.5579 21.3333 24.0249 21.5076 23.5668C21.6616 23.162 21.9119 22.8008 22.2368 22.5145C22.6046 22.1904 23.1036 22.0032 24.1018 21.6289L31.2509 18.948C31.528 18.8441 31.6666 18.7921 31.8092 18.7715C31.9357 18.7532 32.0641 18.7532 32.1906 18.7715C32.3332 18.7921 32.4718 18.8441 32.749 18.948L39.898 21.6289C40.8962 22.0032 41.3953 22.1904 41.763 22.5145C42.0879 22.8008 42.3382 23.162 42.4922 23.5668C42.6666 24.0249 42.6666 24.5579 42.6666 25.6239V32.0005Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <mat-card-title>Privacy and Security</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M32 30V25.3333M32 34.6667H32.0133M25.3333 40V43.114C25.3333 43.8245 25.3333 44.1797 25.479 44.3622C25.6056 44.5208 25.7977 44.6131 26.0007 44.6129C26.2342 44.6127 26.5116 44.3908 27.0663 43.9469L30.247 41.4024C30.8967 40.8826 31.2216 40.6228 31.5833 40.4379C31.9043 40.274 32.2459 40.1541 32.599 40.0817C32.9969 40 33.4129 40 34.245 40H37.6C39.8402 40 40.9603 40 41.816 39.564C42.5686 39.1805 43.1805 38.5686 43.564 37.816C44 36.9603 44 35.8402 44 33.6V26.4C44 24.1598 44 23.0397 43.564 22.184C43.1805 21.4314 42.5686 20.8195 41.816 20.436C40.9603 20 39.8402 20 37.6 20H26.4C24.1598 20 23.0397 20 22.184 20.436C21.4314 20.8195 20.8195 21.4314 20.436 22.184C20 23.0397 20 24.1598 20 26.4V34.6667C20 35.9066 20 36.5266 20.1363 37.0353C20.5062 38.4156 21.5844 39.4938 22.9647 39.8637C23.4734 40 24.0934 40 25.3333 40Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                    <mat-card-title>In App and E-mail Alerts</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M42.6666 42.6667V33.3333M31.9999 42.6667V29.3333M21.3333 42.6667L21.3333 37.3333M33.8755 22.7033L40.7667 25.2876M30.3983 23.2012L22.9336 28.7998M44.0808 24.5858C44.8618 25.3668 44.8618 26.6332 44.0808 27.4142C43.2997 28.1953 42.0334 28.1953 41.2524 27.4142C40.4713 26.6332 40.4713 25.3668 41.2524 24.5858C42.0334 23.8047 43.2997 23.8047 44.0808 24.5858ZM22.7475 28.5858C23.5285 29.3668 23.5285 30.6332 22.7475 31.4142C21.9664 32.1953 20.7001 32.1953 19.919 31.4142C19.138 30.6332 19.138 29.3668 19.919 28.5858C20.7001 27.8047 21.9664 27.8047 22.7475 28.5858ZM33.4141 20.5858C34.1952 21.3668 34.1952 22.6332 33.4141 23.4142C32.6331 24.1953 31.3668 24.1953 30.5857 23.4142C29.8047 22.6332 29.8047 21.3668 30.5857 20.5858C31.3668 19.8047 32.6331 19.8047 33.4141 20.5858Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <mat-card-title>Charts and Metrics</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
        <div class="col-sm-6 padding  text-white">
            <mat-card class="example-card">
                <mat-card-header>
                    <div mat-card-avatar><svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" />
                            <path
                                d="M28.5269 41.8275L29.3061 43.5801C29.5378 44.1018 29.9158 44.5451 30.3944 44.8562C30.873 45.1672 31.4316 45.3328 32.0024 45.3327C32.5733 45.3328 33.1318 45.1672 33.6104 44.8562C34.089 44.5451 34.4671 44.1018 34.6987 43.5801L35.478 41.8275C35.7554 41.2056 36.222 40.6872 36.8113 40.346C37.4044 40.0039 38.0904 39.8582 38.7713 39.9297L40.678 40.1327C41.2456 40.1927 41.8183 40.0868 42.3269 39.8278C42.8355 39.5688 43.258 39.1678 43.5432 38.6734C43.8288 38.1794 43.9648 37.6131 43.9348 37.0432C43.9048 36.4733 43.7101 35.9244 43.3743 35.4631L42.2454 33.9119C41.8434 33.3555 41.6287 32.6858 41.6321 31.9993C41.6319 31.3148 41.8487 30.6478 42.2513 30.0942L43.3802 28.5431C43.7161 28.0817 43.9108 27.5328 43.9408 26.9629C43.9707 26.3931 43.8347 25.8267 43.5491 25.3327C43.2639 24.8383 42.8414 24.4373 42.3328 24.1783C41.8243 23.9193 41.2515 23.8134 40.6839 23.8734L38.7773 24.0764C38.0964 24.1479 37.4103 24.0022 36.8173 23.6601C36.2268 23.317 35.76 22.7958 35.4839 22.1712L34.6987 20.4186C34.4671 19.8969 34.089 19.4536 33.6104 19.1425C33.1318 18.8315 32.5733 18.6659 32.0024 18.666C31.4316 18.6659 30.873 18.8315 30.3944 19.1425C29.9158 19.4536 29.5378 19.8969 29.3061 20.4186L28.5269 22.1712C28.2508 22.7958 27.784 23.317 27.1936 23.6601C26.6005 24.0022 25.9144 24.1479 25.2336 24.0764L23.321 23.8734C22.7534 23.8134 22.1806 23.9193 21.672 24.1783C21.1635 24.4373 20.741 24.8383 20.4558 25.3327C20.1702 25.8267 20.0342 26.3931 20.0641 26.9629C20.0941 27.5328 20.2888 28.0817 20.6247 28.5431L21.7536 30.0942C22.1561 30.6478 22.3729 31.3148 22.3728 31.9993C22.3729 32.6839 22.1561 33.3509 21.7536 33.9045L20.6247 35.4556C20.2888 35.917 20.0941 36.4659 20.0641 37.0358C20.0342 37.6056 20.1702 38.172 20.4558 38.666C20.7413 39.1601 21.1638 39.5609 21.6723 39.8199C22.1808 40.0789 22.7534 40.1849 23.321 40.1253L25.2276 39.9223C25.9085 39.8508 26.5946 39.9965 27.1876 40.3386C27.7803 40.6807 28.2492 41.202 28.5269 41.8275Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M32.0001 35.9993C34.2092 35.9993 36.0001 34.2085 36.0001 31.9993C36.0001 29.7902 34.2092 27.9993 32.0001 27.9993C29.7909 27.9993 28.0001 29.7902 28.0001 31.9993C28.0001 34.2085 29.7909 35.9993 32.0001 35.9993Z"
                                stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <mat-card-title>Troubleshooting</mat-card-title>
                    <mat-card-subtitle>In a laoreet purus. Integer turpis quam, laoreet id orci nec, ultrices lacinia
                        nunc. Aliquam erat volutpat</mat-card-subtitle>
                    <div class="exp">Explore ></div>
                </mat-card-header>


            </mat-card>
        </div>
    </div>
</div>
<div class=" mt-4 mb-4">
    <div class="SearchHeading" style="color: #101D42;">Frequently Asked Questions</div>
    <div class="SearchsubHeading" style="color: #8A98AB;">In a laoreet purus. Integer turpis quam, laoreet id orci nec,
        ultrices lacinia nunc. Aliquam erat volutpat</div>
</div>

<mat-expansion-panel style="width: 42%; margin:10px auto; border-radius: 16px;" >
    <mat-expansion-panel-header>
        <mat-panel-title>
            How do I create a new account?
        </mat-panel-title>
    </mat-expansion-panel-header>
    <hr>
    Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
    pretium sed non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum ultricies laoreet,
    augue eros luctus sapien, ut euismod leo tortor ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
    tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in, porttitor at metus.
    Mauris ac hendrerit metus. Phasellus mattis lectus commodo felis egestas, id accumsan justo ultrices. Phasellus
    aliquet, sem a placerat dapibus, enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus placerat,
    urna.
   
</mat-expansion-panel>

<mat-expansion-panel style="width: 42%; margin:10px auto; border-radius: 16px;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            How to set alerts for different modules of the application?
        </mat-panel-title>
    </mat-expansion-panel-header>
    <hr>
    Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
    pretium sed non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum ultricies laoreet,
    augue eros luctus sapien, ut euismod leo tortor ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
    tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in, porttitor at metus.
    Mauris ac hendrerit metus. Phasellus mattis lectus commodo felis egestas, id accumsan justo ultrices. Phasellus
    aliquet, sem a placerat dapibus, enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus placerat,
    urna.

</mat-expansion-panel>

<mat-expansion-panel style="width: 42%; margin:10px auto; border-radius: 16px;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            How to defend my account?
        </mat-panel-title>
    </mat-expansion-panel-header>
    <hr>
    Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
    pretium sed non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum ultricies laoreet,
    augue eros luctus sapien, ut euismod leo tortor ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
    tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in, porttitor at metus.
    Mauris ac hendrerit metus. Phasellus mattis lectus commodo felis egestas, id accumsan justo ultrices. Phasellus
    aliquet, sem a placerat dapibus, enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus placerat,
    urna.

</mat-expansion-panel>

<mat-expansion-panel style="width: 42%; margin:10px auto; border-radius: 16px;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            What is payment calculator and how to use it?
        </mat-panel-title>
    </mat-expansion-panel-header>
    <hr>
    Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
    pretium sed non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum ultricies laoreet,
    augue eros luctus sapien, ut euismod leo tortor ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
    tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in, porttitor at metus.
    Mauris ac hendrerit metus. Phasellus mattis lectus commodo felis egestas, id accumsan justo ultrices. Phasellus
    aliquet, sem a placerat dapibus, enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus placerat,
    urna.

</mat-expansion-panel>

<mat-expansion-panel style="width: 42%; margin:10px auto; border-radius: 16px;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Can I integrate 3rd party software into my business profile?
        </mat-panel-title>
    </mat-expansion-panel-header>
    <hr>
    Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
    pretium sed non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum ultricies laoreet,
    augue eros luctus sapien, ut euismod leo tortor ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
    tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in, porttitor at metus.
    Mauris ac hendrerit metus. Phasellus mattis lectus commodo felis egestas, id accumsan justo ultrices. Phasellus
    aliquet, sem a placerat dapibus, enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus placerat,
    urna.

</mat-expansion-panel>

<div style="width: 42%; margin:10px auto; border-radius: 16px; background-color: #F1F4FC;">
    <mat-card  style=" background-color: #F1F4FC;padding: 32px 29px !important;">
        
            <mat-card-title style="text-align: center ;color: #101D42; font-weight: 600;">Still need some help?</mat-card-title>
            <mat-card-subtitle>You can create a ticket, describe your problem in <br> details and our manager will help you out</mat-card-subtitle>
            
            <mat-card-actions style="margin: auto; text-align: center;margin-top: 10px;">
               <button mat-raised-button  style="height: 45px;width: 190px;background: #1363DF; color: #fff;border-radius: 12px;">Create a ticket</button>
              </mat-card-actions>

    </mat-card>
</div>
<br>
<div class=" mt-4 mb-4">
    <div class="SearchsubHeading" style="color: #8A98AB;">Power By</div>
    <div class="SearchHeading" style="color: #101D42;">DataUnveil</div>
</div>
<br>
<br>



<div class="helpIcon" matTooltip="Help"   matTooltipPosition="left"  (click)="HelpIcon=!HelpIcon">
   
<svg *ngIf="HelpIcon==false" (click)="openDialog($event)" viewBox="100.87 62.847 63.832 63.282" width="63.832" height="63.282">
  <defs>
    <linearGradient id="paint0_linear_5_2931" x1="80" y1="79" x2="-6.36212" y2="39.0383" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"></stop>
      <stop offset="1" stop-color="white"></stop>
    </linearGradient>
  </defs>
  <circle cx="44" cy="44" r="33.015" fill="#D0E0F9" transform="matrix(0.966709, 0, 0, 0.958386, 90.251153, 52.319165)" ></circle>
  <circle cx="133.153" cy="94.854" r="26.845" fill="#1363DF"></circle>
  <circle cx="44" cy="44" r="25.411" fill="url(#paint0_linear_5_2931)" fill-opacity="0.3" style="mix-blend-mode: overlay;" transform="matrix(0.899208, 0, 0, 0.884881, 93.62092, 55.80354)"></circle>
  <path d="M 131.329 97.216 L 127.733 100.617 C 127.231 101.092 126.98 101.329 126.77 101.346 C 126.588 101.361 126.411 101.291 126.295 101.157 C 126.162 101.003 126.172 100.67 126.19 100.003 L 126.236 98.299 C 126.252 97.7 125.752 97.268 125.137 97.182 C 123.649 96.971 122.505 95.846 122.325 94.414 C 122.295 94.178 122.303 93.896 122.318 93.331 L 122.455 88.258 C 122.504 86.422 122.53 85.505 122.921 84.804 C 123.265 84.187 123.802 83.686 124.454 83.372 C 125.195 83.015 126.151 83.015 128.066 83.015 L 136.497 83.015 C 138.412 83.015 139.37 83.015 140.092 83.372 C 140.726 83.686 141.234 84.187 141.546 84.804 C 141.9 85.505 141.875 86.422 141.826 88.258 L 141.701 92.846 M 141.377 104.863 L 138.942 103.21 C 138.599 102.978 138.428 102.861 138.24 102.779 C 138.074 102.706 137.898 102.652 137.718 102.62 C 137.515 102.584 137.302 102.584 136.877 102.584 L 134.83 102.584 C 133.553 102.584 132.916 102.584 132.434 102.345 C 132.011 102.136 131.672 101.802 131.464 101.392 C 131.228 100.924 131.244 100.312 131.278 99.089 L 131.353 96.342 C 131.385 95.118 131.402 94.507 131.663 94.039 C 131.893 93.628 132.25 93.294 132.684 93.084 C 133.179 92.846 133.817 92.846 135.093 92.846 L 141.474 92.846 C 142.75 92.846 143.388 92.846 143.87 93.084 C 144.292 93.294 144.632 93.628 144.839 94.039 C 145.075 94.507 145.059 95.118 145.025 96.342 L 144.945 99.307 C 144.917 100.325 144.904 100.834 144.719 101.236 C 144.474 101.771 144.019 102.196 143.455 102.418 C 143.031 102.584 142.501 102.584 141.439 102.584 L 141.377 104.863 Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="fill: rgb(21, 109, 226);" transform="matrix(0.999745, -0.022586, 0.022586, 0.999745, -2.087606, 3.043137)"></path>
</svg>

<svg *ngIf="HelpIcon==true" (click)="this.dialog.closeAll();" viewBox="100.87 62.847 63.832 63.282" width="63.832" height="63.282">
  <defs>
    <linearGradient id="paint0_linear_5_2931" x1="80" y1="79" x2="-6.36212" y2="39.0383" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"></stop>
      <stop offset="1" stop-color="white"></stop>
    </linearGradient>
  </defs>
  <circle cx="44" cy="44" r="33.015" fill="#D0E0F9" transform="matrix(0.966709, 0, 0, 0.958386, 90.251153, 52.319165)" ></circle>
  <circle cx="133.153" cy="94.854" r="26.845" fill="#1363DF"></circle>
  <circle cx="44" cy="44" r="25.411" fill="url(#paint0_linear_5_2931)" fill-opacity="0.3" style="mix-blend-mode: overlay;" transform="matrix(0.899208, 0, 0, 0.884881, 93.62092, 55.80354)"></circle>
  <path d="M 142.058 99.577 L 136.61 93.223 L 142.058 86.868 C 142.66 86.167 142.66 85.028 142.058 84.327 L 141.332 83.479 C 140.73 82.778 139.754 82.778 139.152 83.479 L 133.705 89.834 L 128.257 83.479 C 127.655 82.778 126.679 82.778 126.077 83.479 L 125.351 84.327 C 124.75 85.028 124.75 86.167 125.351 86.868 L 130.799 93.223 L 125.351 99.577 C 124.75 100.279 124.75 101.418 125.351 102.119 L 126.077 102.966 C 126.679 103.667 127.655 103.667 128.257 102.966 L 133.705 96.612 L 139.152 102.966 C 139.754 103.667 140.73 103.667 141.332 102.966 L 142.058 102.119 C 142.66 101.418 142.66 100.279 142.058 99.577 Z" fill="white"  data-bx-origin="0.542 0.5"></path>
</svg> 
</div>





<ng-template #chatWindow >
    <!-- chartWindow Header -->
    <div id="header" class="header">
        <img class="imageCss" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjdtuk4n5eHPxS9XB4BGI0yvQVqRsvakAOmg&usqp=CAU" alt="Profile">
        <div (click)="$event.stopPropagation()" style="margin:6px auto 2px 18px !important;width: 100%;">
          <div style=" font-size: 14px;color: #FFF;">{{"Darshan Chakraborty"}}</div>
          <span style="font-size: 12px;color: #7DB0FF;">{{'Costumer Service Manager'}}</span>
        </div>
        <button mat-icon-button class="close-button"  (click)="matDialogClose()">
          <mat-icon class="close-icon" style=" height: auto !important; width: auto !important;"
           >close</mat-icon>
        </button>
      </div>
    <div class="rightDiv" (click)="$event.stopPropagation()">
       
  
        <div  [style]="{ width: '100%' , height :  '80%' , 'overflow-y':'auto',padding: '5px'}" (click)="$event.stopPropagation()">
  
          <div  *ngFor="let message of messages; let i = index">
            <div [style]="{display: 'flex', margin: '0px 45px' ,'text-align':message.name =='you'?'right':'left'}">
                <div style="width: 100%">
                  <span style="font-family: 'Poppins'!important;font-size: 10px;font-weight: 600;">{{ message.name || "No UserName"}}</span>
                  <span style="font-family: 'Poppins'!important; font-size: 9px;font-weight: 400;">{{message.time }}</span>
                </div>
                
              </div>
            <div style="display: flex; ">
              <img *ngIf="message.name!='you'" class="smalimageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
           
              <div class="card"
              [style]="{'background-color':message.name =='you'?'#1363DF':'#F6F8FB','margin': '0px 6px' ,'border-radius': message.name !='you'?'12px 12px 12px 0px':'12px 12px 0px 12px',padding: '5px', position: 'relative'}">
                <div [style]="{'font-size': '12px',color:message.name =='you'?'#FFF': '#8d8b8b','font-family': 'Poppins'}"><span >{{ message.message }}</span></div>
              </div>
              <img *ngIf="message.name=='you'" class="smalimageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
            </div>
           
          </div>
        </div>
        <div style="width: 94%; height: 17% !important; display: flex; background-color: #1363DF1A;     border-radius: 16px; margin: auto;" (click)="$event.stopPropagation()">
            <div style="margin-top: 13px; "><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5834 4.37919V13.7494C14.5834 16.2807 12.5313 18.3327 10 18.3327C7.46872 18.3327 5.41669 16.2807 5.41669 13.7493V4.72157C5.41669 3.03403 6.78471 1.66602 8.47224 1.66602C10.1598 1.66602 11.5278 3.03403 11.5278 4.72157V13.7041C11.5278 14.5479 10.8438 15.2319 10 15.2319C9.15625 15.2319 8.47224 14.5479 8.47224 13.7041V5.54199" stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </div>
            <input id="comment"
              style=" font-size: 12px;color: #8d8b8b;width: 86%;float: left; margin: 7px 0px 1px 5px;height: 37px !important;    border: none;background-color:#1363df00;;"
              type="text" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)"
              class="" placeholder="Add comment here" [(ngModel)]="searchValue">
    <div style="margin-top: 13px; "  (click)="messageData(searchValue ,$event)">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.74958 10.2501L16.4996 1.50014M7.8559 10.5235L10.046 16.1552C10.2389 16.6513 10.3354 16.8994 10.4744 16.9718C10.5949 17.0346 10.7384 17.0347 10.859 16.972C10.9981 16.8998 11.0949 16.6518 11.2884 16.1559L16.7803 2.08281C16.955 1.63516 17.0424 1.41133 16.9946 1.26831C16.9531 1.1441 16.8556 1.04663 16.7314 1.00514C16.5884 0.957356 16.3646 1.0447 15.9169 1.21939L1.84379 6.71134C1.3479 6.90486 1.09995 7.00163 1.02769 7.14071C0.965054 7.26129 0.965139 7.40483 1.02792 7.52533C1.10034 7.66433 1.3484 7.7608 1.84452 7.95373L7.47619 10.1438C7.5769 10.183 7.62725 10.2026 7.66965 10.2328C7.70724 10.2596 7.7401 10.2925 7.76691 10.3301C7.79715 10.3725 7.81673 10.4228 7.8559 10.5235Z" stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
                
    
          </div>
       
        
      </div>
  </ng-template>
  <!-- </div> -->