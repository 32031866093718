import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, } from '@angular/core';
import 'ag-grid-enterprise';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-Sparklines-grid',
  templateUrl: './sparklines-grid.component.html',
  styleUrls: ['./sparklines-grid.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class SparklinesGridComponent implements OnInit, OnDestroy {
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @Input('rawData') rawData: any 
  @Input('pageKey') pageKey: any
  @Input('item') item: any = {}
  @Input('data') data!: any
  noData: boolean = false
  rolling_Days: number = 0
  loader: Boolean = true
  @Input('fieldData') fieldData: any
  @Input('commentsData') commentsData: any
  reqSubcription: Subscription[] = []
  @ViewChild('pivotGridDiv', { static: true }) pivotGridDiv!: ElementRef
  @ViewChild('GridDiv', { static: true }) GridDiv!: ElementRef
  public autoGroupColumnDef: ColDef = {
    // minWidth: 200,

  };
  iconList: any = []
  isActive = false;
  divId: any = 'barGridDiv';
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  private gridApi!: GridApi;
  rowData: any
  expandCollapseIcon = "expand"
  expandOrCollapse = "Expand All"
  columnDefs :any
  gridHeight = "570px"
  columnApi: any
  constructor(private newFilterService: NewFilterService, private filterService: FilterService) {
    this.filterService.filterQuery.subscribe((query: any) => {
      this.onBtShowLoading()
    })
    this.newFilterService.showBy.subscribe((query: any) => {
      this.onBtShowLoading()
    })
  }
  
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  onBtShowLoading() {
      this.loader=true
    // this.gridApi?.showNoRowsOverlay();
  }

  onBtHide() {
    setTimeout(() => {

      this.loader=false
    }, 500);
    // this.gridApi?.hideOverlay();
  }

  exportXls() {
    this.gridApi.exportDataAsExcel({});
  }

  public defaultColDef: ColDef<any> = {
    minWidth: 120,
    resizable: true,
    sortable: false,
  };

  expandCollapse() {
    if (this.expandCollapseIcon == "collapse") {
      this.expandCollapseIcon = "expand"
      this.expandOrCollapse = "Expand All"
      this.gridApi.collapseAll()

    } else {
      this.expandCollapseIcon = "collapse"
      this.expandOrCollapse = "Collapse All"
      this.gridApi.expandAll();
    }
  }

  closeFullscreen(): void {
    this.gridHeight = "570px"
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
    }, 100);
  }

  ngOnInit(): void {
    this.onBtShowLoading()
    this.iconList = this.item.config?.icon ? this.item.config.icon : this.iconList

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue !== changes['data'].previousValue) {
      // Data is initially set, wait for it to be available
      // this.waitForData().then((data: any) => {
        this.updateGrid(this.data);
      // });

    }
   }

  waitForData(): Promise<any> {
    return new Promise((resolve) => {
      const checkDataInterval = setInterval(() => {
        if (this.data && this.data.length > 0) {
          clearInterval(checkDataInterval);
          resolve(this.data);
        } else {
          this.columnDefs = [];
          this.rowData = [];
          this.noData = false;
          this.loader = false;
        }
      }, 3000); // Adjust the interval as needed
    });
  }

  updateGrid(data: any): void {
    // console.log(data);
    
    this.columnDefs = this.fieldData;
    // this.gridApi.setRowData(data);
    this.rowData = data;
    if (data.length == 0) { this.noData = true; } else { this.noData = false; }
    this.loader = false
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}


