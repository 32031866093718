<div class="appEditGridPopup">
    <!-- header -->
    <div class="d-flex justify-content-between p-3">
        <h4 class="h4-header-popup">{{ selectedColumn=='2' ? fileData.file_type_name + " Buckets" : 'KPI Values'}}</h4>
        <span class="onClosebtn">
            <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
        </span>
    </div>

    <!-- When No Bucket -->
    <div *ngIf="!newData || newData.length==0 && selectedColumn=='2'">
        <div style="text-align: center; margin-top: 50px;font-family: 'Poppins';">
            <p>No buckets yet.</p>
            <button type="button" class="btn btn-primary mt-3 btn-sm bottom-btns bg-save-btn"
                (click)="addBucketData()">+ Add bucket</button>
        </div>
    </div>

    <!-- when here are buckets -->
    <div class="body_bucket">
        <!-- For Buckts -->
        <div *ngIf="selectedColumn=='2' && newData.length>0">
            <div *ngFor="let flr of newData; let idx=index;" class="row">

                <!-- Operator -->
                <div class="col-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="fntSize_bucket">Operator</label>
                        <mat-select class="fntSize_bucket form-control" [(ngModel)]="selectedBucketRange">
                            <mat-option *ngFor="let slt of operatorData" [selected]="flr.selected"
                                [value]="slt.selected">{{slt.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col-5">
                    <div class="row">
                        <div class="col-6">
                            <!-- Days 1 -->
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="fntSize_bucket">From Days</label>
                                <input type="email" noENotationInput [(ngModel)]="flr.from_days"
                                    class="form-control fntSize_bucket" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Days">
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- Days 2 -->
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="fntSize_bucket">To Days</label>
                                <input type="email" noENotationInput [(ngModel)]="flr.to_days"
                                    class="form-control fntSize_bucket" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Days">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Payment -->
                <div class="col-2">
                    <div class="form-group mr-1" style="position: relative">
                        <label for="exampleInputEmail1" class="fntSize_bucket">Payment</label>
                        <input type="text" noENotationInput class="form-control fntSize_bucket"
                            [(ngModel)]="flr.pymt_pct" style="width: 65px;" id="exampleInputEmail1"
                            aria-describedby="emailHelp" placeholder="100">
                        <span class="fntSize_bucket"
                            style="position: absolute;bottom: 8px;right: 8px;color: grey;">%</span>
                    </div>
                </div>

                <!-- Delete -->
                <div class="col-1" style="align-self: center;">
                    <div class="form-group" style="margin: 10px 0 0;">
                        <span style="width: 100px;">
                            <span style="cursor: pointer !important;" (click)="deleteData(flr, idx)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4"
                                        stroke="#D11044" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>

            </div>
            <div class="mb-3">
                <span class="addIcon_bucket" (click)="addBucketData()">+ Add bucket</span>
            </div>
        </div>

        <!-- For KPIs -->
        <div *ngIf="selectedColumn=='1'">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="fntSize_bucket">Inventory reconciliation</label>
                        <input type="email" noENotationInput [(ngModel)]="fileData.ir_days"
                            class="form-control fntSize_bucket" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Days">
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mr-1" style="position: relative">
                        <label for="exampleInputEmail1" class="fntSize_bucket">Data service fee</label>
                        <input type="text" noENotationInput class="form-control fntSize_bucket"
                            [(ngModel)]="fileData.data_service_fee" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="0">
                        <span class="fntSize_bucket"
                            style="position: absolute;bottom: 8px;right: 8px;color: grey;">$</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- bottom bottons -->
    <div class="bottom_edit_popup">
        <hr>
        <div class="d-flex bd-highlight align-items-center m-2">
            <div class="mr-auto p-2 bd-highlight"><span (click)="clearAll($event)" class="clearAllBtn"
                    [style.display]="!newData.data || newData.data.length==0 ? 'none' : 'block'"
                    style="cursor: pointer;">Clear all</span></div>
            <div class="p-2 bd-highlight">
                <button type="button" class="btn btn-outline-primary bottom-btns outline-cancel-btn mr-2"
                    mat-dialog-close>Cancel</button>
            </div>
            <div class="p-2 bd-highlight">
                <button type="button" class="btn btn-primary btn-sm bottom-btns bg-save-btn" (click)="saveChanges()">Save Changes</button>
            </div>
        </div>
    </div>

</div>