import {NestedTreeControl} from '@angular/cdk/tree';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

@Component({
  selector: 'app-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})

export class TreeMenuComponent implements OnInit, OnChanges {

  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();

  @Input('menuItems') menuItems:any[] = []
  @Output('onSelect') onSelect = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (this.menuItems.length>0)
      this.dataSource.data = this.menuItems
  }
  onClick(item:any) {
    this.onSelect.emit(item)
  }
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
}
