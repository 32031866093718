
<div  style="font-family: Poppins !important">
    <main>
<article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;overflow: auto;">
    <div style=" background-color: #fff;">
      <div >
                <div *ngIf="iconList">
                    <icon-chart [headerConfig]="item.config?.widget_header_config" [iconList]="iconList" [data]="data" [pageKey]="pageKey" [heading]="heading"
                        [chartName]="divId" [config]="controlChartDiv" [expand]="fs" [fullscreen]="isFullscreen"
                        [item]="item"></icon-chart>
             
        </div>
        <div><loading *ngIf="isLoading"> </loading></div> 
    </div>
    <div style="margin: 0 14px; overflow: auto;">
    <div *ngIf="item.config.chart_type=='multi'" style="height: 36px;text-align: center;border-radius: 10px;margin-bottom: 10px; border: 2px dashed #1766ddfe;background-color: #1363DF0D;color: #1766ddfe;"  (click)="openKpiDialog()">
        <span style="line-height: 31px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M14.9091 6.54547H9.45453V1.09091C9.45453 0.488726 8.9658 0 8.36362 0H7.63638C7.0342 0 6.54547 0.488726 6.54547 1.09091V6.54547H1.09091C0.488726 6.54547 0 7.0342 0 7.63638V8.36362C0 8.9658 0.488726 9.45453 1.09091 9.45453H6.54547V14.9091C6.54547 15.5113 7.0342 16 7.63638 16H8.36362C8.9658 16 9.45453 15.5113 9.45453 14.9091V9.45453H14.9091C15.5113 9.45453 16 8.9658 16 8.36362V7.63638C16 7.0342 15.5113 6.54547 14.9091 6.54547Z" fill="#1363DF"/>
             </svg>
             Change Control Chart Selection </span>
        </div>
        <div >
            <div [style.display]="noData?'none':''" id="controlChartDiv" #controlChartDiv></div>
            <div style="height: 500px; width: auto; " *ngIf="noData">
                <span class="bg-text">There is no data for this time period</span>
            </div>
        </div>
        <div id="d3controlChartTooltip">
            <div *ngIf="this.tooltipVisible">
                <div [class]="Data.comments=='yes'?'container chart_tooltip':'containertooltip chart_tooltip'"  *ngFor="let Data of mytooltipData.values;let i=index">
                    <div  *ngIf="Data.comments!='yes'" class=" chart_tooltip_header" style="max-width: 200px;">
                        <div class="col-12 d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1">
                                {{Data?.period | date: 'dd MMM yyyy'}}
                            </div>
                            <div  style="width: 200px;" class="chart_tooltip_header_data ">{{ Data.file_type_name || Data.kpi_nm  }}</div>
                        </div>
                    </div>
                    <div  *ngIf="Data.comments=='yes'" class="row chart_tooltip_header">
                        <div class="col-12 d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1">
                                {{Data?.period | date: 'dd MMM yyyy'}}
                            </div>
                            <div style="width: 200px;" class="chart_tooltip_header_data ">{{ Data.kpi_nm }}</div>
                        </div>
                    </div>
                    <div class="mt-1 mb-1"
                        [ngClass]="(dataTurn < 550) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                        <div *ngIf="Data.comments!='yes'" class=" mt-1 pb-3" >
                            <div style="color:#FFF; font-size: 13px; margin-left: 12px; font-weight: 600;">
                                {{Data.source_name}} </div>
                            <div class="col-12 d-flex m-auto  align-items-center">
                                <div>
                                    <!-- <img src="../../../../assets/RedCircle.png" /> -->
                                    <div class="tooltip_outlay" [style.background]="colors[Data.status]"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2">{{ numbedPipe(Data.kpi_value||0) }}
                                </div>
                                <div class="ml-2" >
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                                        *ngIf="Data.change== 'up'" />
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                                        *ngIf="Data.change == 'down'" />
                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"MEAN" }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <!-- <div> -->
                                        <mat-icon [ngStyle]="{'color':'gray','width': '8px'  } " style="margin-top: 3px !important;" svgIcon="line_circle"></mat-icon>
                                        
                                            
                                        <!-- <div class="tooltip_outlay" [style.background]="'#FFF'"></div> -->
                                    <!-- </div> -->
                                    <div class="chart_tooltip_body_data ml-2">{{numbedPipe(Data?.average)||0 +' Avg.'}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"THRESHOLDS" }}
                                </div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <!-- <div> -->
                                        <!-- <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="" svgIcon="dashed_circle"></mat-icon> -->
                                        <div class="tooltip_outlay" [style.background]="'#c8c8c8'"></div>
                                    <!-- </div> -->
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.thresholdRange?.[0])||''}} -
                                        {{ numbedPipe(Data?.thresholdRange?.[1])||''}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"CONTROL LIMITS"
                                    }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <!-- <div> -->
                                        <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon>
                                        <!-- <div class="tooltip_outlay" [style.background]="'#FFF'"></div> -->
                                    <!-- </div> -->
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.lowerLimit)||''}} -
                                        {{ numbedPipe(Data?.upperLimit)||''}} </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="Data.comments=='yes'" style="width: 485px;display: flex;">
                        <div class=" mt-1" style="margin-left: -12px;width: 35%;border-right: 1px dotted gray;">
                            <div style="color:#FFF; font-size: 13px; margin-left: 12px; font-weight: 600;">
                                {{Data.source_name}} </div>
                            <div class="col-12 d-flex m-auto  align-items-center">
                                <div>
                                    <!-- <img src="../../../../assets/RedCircle.png" /> -->
                                    <div class="tooltip_outlay" [style.background]="colors[Data.status]"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2">{{ numbedPipe(Data.kpi_value||0) }}
                                </div>
                                <div class="ml-2" >
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                                        *ngIf="Data.change== 'up'" />
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                                        *ngIf="Data.change == 'down'" />
                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"MEAN" }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color':'gray','width': '8px'  } " style="margin-top: 3px !important;" svgIcon="line_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2">{{numbedPipe(Data?.average)||0+' Avg.'}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"THRESHOLDS" }}
                                </div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <div>
                                        <div class="tooltip_outlay" [style.background]="'#c8c8c8'"></div>
                                    </div>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.thresholdRange?.[0])||''}} -
                                        {{ numbedPipe(Data?.thresholdRange?.[1])||''}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"CONTROL LIMITS"
                                    }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.lowerLimit)||''}} -
                                        {{ numbedPipe(Data?.upperLimit)||''}} </div>

                                </div>
                            </div>
                            
                        </div>
                        <div class="rightDiv" (click)="$event.stopPropagation()">
                            <div style="border-bottom: 1px dashed gray;">  <span style="font-size: 11px;color: #FFF;margin-left :5px">Annotations: {{numbedPipe(Data.messages?.length)}}</span>
                            </div>
                              <div  [style]="getStyles()" (click)="$event.stopPropagation()">
                                <div id="content">
                                <div *ngFor="let message of Data.messages; let i = index">
                                  <div style="display: flex ; border-top: 1px dashed gray;">
                                    <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                                     
                                 
                                    <div class="card" style="background-color: black !important; color:#F6F8FB;padding: 5px; position: relative;width: 300px;">
                                      <div style="display: flex;">
                                        <div style="width: 170px;font-size: 10px;font-weight: 600; float: left;">
                                          <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                                          <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                                          <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                                        </div>
                                        <div style="width: 82px;font-size: 8px;font-weight: 400; float: right;">
                                          <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                                        </div>
                                      </div>
                                     <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                                    <span [innerHtml]="getMSGHtml(message.comments)" ></span>
                                   
                                  </div>
                                  
                                    </div>
                                    
                                  </div>
                                 
                                </div>
                              </div>
                              </div>
                              
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="d3pincontrolChartTooltip">
            
                <div  [class]="Data.comments=='yes'?'container chart_tooltip':'containertooltip chart_tooltip'" *ngFor="let Data of pinningData.values;let i=index">
                    <div *ngIf="Data.comments!='yes'" class=" chart_tooltip_header" style="max-width: 200px;">
                        <div class="col-12 d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1">
                                {{Data?.period | date: 'dd MMM yyyy'}} <span style="color: blue; margin-left: 4px;">pinned</span> 
                            </div>
                            <div style="width: 200px;" class="chart_tooltip_header_data ">{{ Data.kpi_nm }}</div>
                        </div>
                    </div>
                    <div *ngIf="Data.comments=='yes'" class="row chart_tooltip_header">
                        <div class="col-12 d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1">
                                {{Data?.period | date: 'dd MMM yyyy'}} <span style="color: blue; margin-left: 4px;">pinned</span> 
                            </div>
                            <div style="width: 200px;" class="chart_tooltip_header_data ">{{ Data.kpi_nm }}</div>
                        </div>
                    </div>
                    <div class="mt-1 mb-1"
                        [ngClass]="(dataTurn < 550) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                        <div *ngIf="Data.comments!='yes'" class=" mt-1 pb-3" >
                            <div style="color:#FFF; font-size: 13px; margin-left: 12px; font-weight: 600;">
                                {{Data.source_name}} </div>
                            <div class="col-12 d-flex m-auto  align-items-center">
                                <div>
                                    <!-- <img src="../../../../assets/RedCircle.png" /> -->
                                    <div class="tooltip_outlay" [style.background]="colors[Data.status]"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2">{{ numbedPipe(Data.kpi_value||0) }}
                                </div>
                                <div class="ml-2" >
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                                        *ngIf="Data.change== 'up'" />
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                                        *ngIf="Data.change == 'down'" />
                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"MEAN" }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color':'gray','width': '8px'  } " style="margin-top: 3px !important;" svgIcon="line_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2">{{numbedPipe(Data?.average)+' Avg.'}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"THRESHOLDS" }}
                                </div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <div>
                                        <div class="tooltip_outlay" [style.background]="'#c8c8c8'"></div>
                                    </div>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.thresholdRange?.[0]||'')}} -
                                        {{ numbedPipe(Data?.thresholdRange?.[1]||'')}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"CONTROL LIMITS"
                                    }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.lowerLimit)||''}} -
                                        {{ numbedPipe(Data?.upperLimit)||''}} </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="Data.comments=='yes'" style="width: 485px;display: flex;">
                        <div class=" mt-1" style="margin-left: -12px;width: 35%;border-right: 1px dotted gray;">
                            <div style="color:#FFF; font-size: 13px; margin-left: 12px; font-weight: 600;">
                                {{Data.source_name}} </div>
                            <div class="col-12 d-flex m-auto  align-items-center">
                                <div>
                                    <!-- <img src="../../../../assets/RedCircle.png" /> -->
                                    <div class="tooltip_outlay" [style.background]="colors[Data.status]"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2">{{ numbedPipe(Data.kpi_value||0) }}
                                </div>
                                <div class="ml-2" >
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                                        *ngIf="Data.change== 'up'" />
                                    <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                                        *ngIf="Data.change == 'down'" />
                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"MEAN" }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color':'gray','width': '8px'  } " style="margin-top: 3px !important;" svgIcon="line_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2">{{numbedPipe(Data?.average)+' Avg.'}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"THRESHOLDS" }}
                                </div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <div>
                                        <div class="tooltip_outlay" [style.background]="'#c8c8c8'"></div>
                                    </div>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.thresholdRange?.[0])||''}} -
                                        {{ numbedPipe(Data?.thresholdRange?.[1])||''}} </div>

                                </div>
                            </div>
                            <div class="">
                                <div class="textCss">{{"CONTROL LIMITS"
                                    }}</div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon>
                                    <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(Data?.lowerLimit)||''}} -
                                        {{ numbedPipe(Data?.upperLimit)||''}} </div>

                                </div>
                            </div>
                            
                        </div>
                        <div class="rightDiv" (click)="$event.stopPropagation()">
                            <div style="border-bottom: 1px dashed gray;">  <span style="font-size: 11px;color: #FFF;margin-left :5px">Annotations: {{numbedPipe(pinningmessages?.length)}}</span>
                            </div>
                              <div  [style]="getStyles()" (click)="$event.stopPropagation()">
                                <div id="content">
                                <div *ngFor="let message of pinningmessages; let i = index">
                                  <div style="display: flex ; border-top: 1px dashed gray;">
                                    <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                                     
                                 
                                    <div class="card" style="background-color: black !important; color:#F6F8FB;padding: 5px; position: relative;width: 300px;">
                                      <div style="display: flex;">
                                        <div style="width: 170px;font-size: 10px;font-weight: 600; float: left;">
                                          <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                                          <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                                          <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                                        </div>
                                        <div style="width: 82px;font-size: 8px;font-weight: 400; float: right;">
                                          <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                                        </div>
                                      </div>
                                     <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                                    <span [innerHtml]="getMSGHtml(message.comments)"></span>
                                   
                                  </div>
                                  
                                    </div>
                                    
                                  </div>
                                 
                                </div>
                              </div>
                              </div>
                              
                            </div>
                    </div>
                    </div>
                </div>
            
        </div>


        <!-- Annotation tooltip -->
        <div id="d3annotationsTooltip">
            <div *ngIf="Annotations && filterService.annotations" >
                <div class="container chart_tooltip" >
                    <div class="row chart_tooltip_header">
                        <div class="col-12 d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1" style="margin-left: 21px;">
                            {{myAnnotationsData?.period | date: 'dd MMM yyyy'}}</div>
                            <div class="chart_tooltip_header_data ">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_8824_9155)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2644 15.1879L12.1655 15.4694C12.1062 15.638 11.9213 15.7277 11.7522 15.6699L10.3414 15.1879H12.2644ZM11.9843 0.350104C12.6739 0.519917 13.0952 1.21657 12.9254 1.90614C12.7609 2.57382 12.1025 2.99004 11.4349 2.86157L11.1903 3.37857H15.0655C15.2452 3.37857 15.3917 3.52514 15.3917 3.70482V14.2091C15.3917 14.3888 15.2452 14.5354 15.0655 14.5354H4.72972C4.55003 14.5354 4.40347 14.3888 4.40347 14.2091V3.70482C4.40347 3.52514 4.55003 3.37857 4.72972 3.37857H10.47L10.8473 2.58135C10.4859 2.27585 10.3075 1.78176 10.4283 1.29117C10.5981 0.601604 11.2947 0.180292 11.9843 0.350104ZM11.1903 3.37857H10.47L9.51009 5.40707C9.43337 5.56967 9.503 5.76367 9.66556 5.84039C9.82816 5.9171 10.0222 5.84748 10.0989 5.68492L11.1903 3.37857ZM6.81759 12.0876H12.9776C13.1578 12.0876 13.3038 11.9415 13.3038 11.7614C13.3038 11.5812 13.1578 11.4351 12.9776 11.4351H6.81759C6.63741 11.4351 6.49134 11.5812 6.49134 11.7614C6.49134 11.9415 6.63744 12.0876 6.81759 12.0876ZM6.81759 9.75492H12.9776C13.1578 9.75492 13.3038 9.60885 13.3038 9.42867C13.3038 9.24848 13.1578 9.10242 12.9776 9.10242H6.81759C6.63741 9.10242 6.49134 9.24848 6.49134 9.42867C6.49134 9.60885 6.63744 9.75492 6.81759 9.75492ZM6.81759 7.42226H12.9776C13.1578 7.42226 13.3038 7.2762 13.3038 7.09601C13.3038 6.91582 13.1578 6.76976 12.9776 6.76976H6.81759C6.63741 6.76976 6.49134 6.91582 6.49134 7.09601C6.49134 7.2762 6.63744 7.42226 6.81759 7.42226ZM3.75097 12.936V3.70482C3.75097 3.16501 4.18991 2.72607 4.72972 2.72607H8.21741L4.33497 1.43182C4.1645 1.37501 3.97912 1.4677 3.92228 1.63817L0.626124 11.526C0.569562 11.6957 0.660905 11.8801 0.830124 11.9379L3.75097 12.936Z" fill="#1363DF"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_8824_9155">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                                    {{'Add Annotation'}}
                                        <mat-icon  (click)="hideTooltip('annotation')" style="float: right;margin-top: -13px;">close</mat-icon>
                                    </div>
                        </div>
                    </div>
                    <div style="width: 500px;" >

                       
                        <div class="leftDiv" #Container (click)="$event.stopPropagation()">
                    
                            <div class="mt-1 mb-1"
                            [ngClass]="(dataTurn < 550) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                            <div class=" d-flex  flex-column"  sty>
                                <div style="color:#FFF; font-size: 13px; margin-left: 12px; font-weight: 600;">
                                    {{myAnnotationsData.source_name}} </div>
                                <div class="col-12 d-flex m-auto  align-items-center">
                                    <div>
                                        <!-- <img src="../../../../assets/RedCircle.png" /> -->
                                        <div class="tooltip_outlay" [style.background]="colors[myAnnotationsData.status]"></div>
                                    </div>
                                    <div class="chart_tooltip_body_data ml-2">{{ numbedPipe(myAnnotationsData.kpi_value||0) }}
                                    </div>
                                    <div class="ml-2" >
                                        <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                                            *ngIf="myAnnotationsData.change== 'up'" />
                                        <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                                            *ngIf="myAnnotationsData.change == 'down'" />
                                    </div>
                                </div>
                                <div class="">
                                    <div class="textCss">{{"MEAN" }}</div>
                                    <div class="col-12 d-flex m-auto  align-items-center">
                                        <mat-icon [ngStyle]="{'color':'gray','width': '8px'  } " style="margin-top: 3px !important;" svgIcon="line_circle"></mat-icon>
                                        <div class="chart_tooltip_body_data ml-2">{{numbedPipe(myAnnotationsData?.average)+' Avg.'}} </div>
    
                                    </div>
                                </div>
                                <div class="">
                                    <div class="textCss">{{"THRESHOLDS" }}
                                    </div>
                                    <div class="col-12 d-flex m-auto  align-items-center">
                                        <div>
                                            <div class="tooltip_outlay" [style.background]="'#c8c8c8'"></div>
                                        </div>
                                        <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(myAnnotationsData?.thresholdRange?.[0])||0}} -
                                            {{ numbedPipe(myAnnotationsData?.thresholdRange?.[1])||0}} </div>
    
                                    </div>
                                </div>
                                <div class="">
                                    <div class="textCss">{{"CONTROL LIMITS"
                                        }}</div>
                                    <div class="col-12 d-flex m-auto  align-items-center">
                                        <mat-icon [ngStyle]="{'color': 'gray' ,'width': '8px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon>
                                        <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(myAnnotationsData?.lowerLimit)||0}} -
                                            {{ numbedPipe(myAnnotationsData?.upperLimit)||0}} </div>
    
                                    </div>
                                </div>  </div>
                            </div>
    
                          
                        </div>
                        <div class="rightDiv" (click)="$event.stopPropagation()">
                        <div style="border-bottom: 1px dashed gray;">  <span style="font-size: 11px;color: #FFF;margin-left :5px">Annotations: {{numbedPipe(messages?.length)}}</span>
                        </div>
                          <div  [style]="getStyles()" (click)="$event.stopPropagation()">
                            <div id="content">
                            <div *ngFor="let message of messages; let i = index">
                              <div style="display: flex ; border-top: 1px dashed gray;">
                                <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                                  <!-- menu for comments -->
                                  <mat-menu #menuBeforeCard="matMenu" xPosition="before">
                                    <button mat-menu-item style="text-align: left;" (click)="editComment(message,$event,i)">
                                      <mat-icon style="width: 20px;" svgIcon="edit"></mat-icon>  
                                      <span style="font-family: 'poppins';font-size: 12px;">Edit</span>
                                    </button>
                                      
                                    <button mat-menu-item style="text-align: left;" (click)="DelDialog('300ms', '150ms')">
                                      <mat-icon style="width: 20px;" svgIcon="delete"></mat-icon>
                                      <span style="font-family: 'poppins';font-size: 12px;">Delete</span>
                                    </button>
                                </mat-menu>
                    
                             
                                <div class="card" style="background-color: black !important; color:#F6F8FB;padding: 5px; position: relative;width: 300px;">
                                  <div style="display: flex;">
                                    <div style="width: 170px;font-size: 10px;font-weight: 600; float: left;">
                                      <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                                      <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                                      <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                                    </div>
                                    <div style="width: 82px;font-size: 8px;font-weight: 400; float: right;">
                                      <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                                    </div>
                                  </div>
                                  <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                                    <span *ngIf="!edit" [innerHtml]="getMSGHtml(message.comments)"></span>
                                    <textarea  rows="4" cols="33" *ngIf="edit && editIndex==i" style="border: 1px solid rgb(221, 221, 221);border-radius: 10px;padding: 5px;width: 100%;" [(ngModel)]="editedValue" maxlength="250" type="text" id="edit"
                                      [value]="message.comments" (keydown.enter)="SaveEdit(message ,i)"></textarea>
                                      <div  *ngIf="edit && editIndex==i"style="width: 100%;font-size: 9px;line-height: 0px;margin-top: 4px;text-align: right;" >{{editedValue.length+'/250'}}</div>
                                      <div style="width: 100%;display: flex;justify-content: flex-end;">
                                        <span *ngIf="edit && editIndex==i && editedValue==''" style="color: #d02e2e;margin: auto;">Please type a message to continue</span>
                                    <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                                      (click)="SaveEdit(message ,i)"><mat-icon>check</mat-icon></button>
                                    <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                                      (click)="edit=false"><mat-icon>close</mat-icon></button>
                                    </div>
                                  </div>
                                   <!-- Delete Popup -->
                              <ng-template #DeleteDialog>
                                <div>
                                  <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                                    <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                                    <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                                      <mat-icon class="close-icon"
                                        style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                                    </button>
                                  </div>
                                  <div style="height: 40px; margin: 10px auto; text-align: center;">
                                    Are you sure you want to delete this comment?
                                  </div>
                                  <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                                    <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                                    <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                                    <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                                      mat-dialog-close  cdkFocusInitial >Cancel</button>
                                    <button mat-raised-button style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
                                      (click)="deleteComment(message ,i)">Delete </button>
                    
                                  </div>
                                </div>
                              </ng-template>
                                </div>
                                <mat-icon [matMenuTriggerFor]="menuBeforeCard"  *ngIf="employee_key._value == message.employee_key && !edit"
                                  style="height: 30px !important; width: 30px !important; margin: auto !important"
                                  svgIcon="menu" (click)="getcomentData(message,i)"></mat-icon>
                              </div>
                             
                            </div>
                          </div>
                          </div>


                          <div class="bottomBoth">
                            <div style="width: 100%; display: flex;">
                              <div style="width: 90%;"  class="matline">
                              <mat-form-field  style="width:100% ;"  appearance="outline">
                                <textarea 
                                  matInput id="comment"
                                  style="font-size: 12px;color: #fff;margin: 5px 0px;"
                                  class="firstTabinIRScrolls"
                                  cdkTextareaAutosize
                                  placeholder="Add comment here"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1"
                                  [(ngModel)]="searchValue"
                                  [maxlength]="'250'"
                                  (click)="$event.stopPropagation()" 
                                  (keydown.enter)="messageData(searchValue ,$event)"
                                  cdkAutosizeMaxRows="3">
                                </textarea>
                              </mat-form-field>
                               <!-- <input id="comment" style="font-size: 12px;color: #fff;background-color:black" class="form-control"
                               type="text" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)" class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue" [maxlength]="commentCount"> -->
                              </div>
                               <div style="display:flex;align-items:center;width: 13%;font-size: 9px; color: #fff;margin-top: 5px;padding-left: 4px;" ><span>{{searchValue.length+'/'+250}}</span></div>
                           </div>
                            <div style="display:flex;align-items:center;">
                                <mat-icon class="matIcons"  style="margin-right:-11px;" (click)="messageData(searchValue ,$event)" svgIcon="send"></mat-icon>
                            </div>
                          </div>
                    
                          <!-- bottom input box  -->
                          <!-- <div style="width: 100%; height: 10%; display: flex; align-items: center;" (click)="$event.stopPropagation()"> -->
                          <!-- <div>  <mat-form-field  style="width:80% ;" class="matline"  appearance="outline">
                                <textarea 
                                  matInput id="comment"
                                  class="firstTabinIRScrolls"
                                  cdkTextareaAutosize
                                  placeholder="Add comment here"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1"
                                  [(ngModel)]="searchValue"
                                  [maxlength]="'250'"
                                  (click)="$event.stopPropagation()" 
                                  (keyup.enter)="messageData(searchValue ,$event)"
                                  cdkAutosizeMaxRows="3">
                                </textarea>
                              </mat-form-field></div> -->
                            <!-- <input id="comment"
                              style="  background-color: black; font-size: 12px;color: #8d8b8b;width: 73%;float: left; margin: 7px 0px 1px 5px;height: 27px;"
                              type="search" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)"
                              class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue" maxlength="250"> -->
                       <!-- <div style="font-size: 8px;color: white;margin-top: 10px;padding-left: 7px;position: absolute;" ><span>{{searchValue.length+'/250'}}</span></div >
                          <div><mat-icon style="width: auto;float: right;" class="matIcons" (click)="messageData(searchValue ,$event)"
                              svgIcon="send"></mat-icon></div>  
                    
                          </div> -->
                          <!-- ending -->
                        </div>
                    
                      </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
    </div>
</article>
</main></div>




<!-- Kpi Popup html -->
<ng-template #prompt style="height: 200px; width: auto;">

    <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;">
        <div class="px-3 py-2" style="display: flex;">
            <h2 style="font-weight: 600;font-family: 'Poppins';margin: 0 !important;">{{'  KPI Selection '}}</h2>
            <span style="margin: 4px 14px;">|</span> 
            <h4 style="font-weight: 600;font-family: Poppins;padding: 5px;margin: 0 !important;">{{filterService.kpidateRange(filterService.report_type,filterService.baseQuery.to_date)|| 'no dates Found'}}</h4>
        </div>
       <!-- <div class="px-3 py-2">
           <h2 style="font-weight: 500;font-family: 'Poppins';margin: 0 !important;">{{"  KPI Selection "}}</h2>
       </div> -->
       <div class="px-3 py-2">
        <div class="timecontainer ">
        <div class=" d-flex justify-content-between" id='heatMapDiv'>
        <div style="font-family: Poppins;" *ngFor="let item of kpitimecycle"  [matTooltipPosition]="'above'"
        [matTooltip]="item.label" [ngClass]="{'highlight': filterService.report_type===item.value}" class="cycleHover">
        {{item.value}}
    </div></div></div>
        <!-- <h4 style="font-weight: 500;font-family: 'Poppins';margin: 0 !important;">{{" Timecycle "}}:{{filterService.report_type}}</h4> -->
    </div>
       <div class="px-3 py-2" style="margin: 0 0 -5px 0px !important;">
           <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" (click)="filterService.isDataLoaded = false " >close</mat-icon>
       </div>
    </div>
 <div style="margin: 10px;overflow: auto !important;">
    <app-kpi-breakdown-heatmap-chart *ngIf="filterService.isDataLoaded" [type]="'chart'" [item]="undefined" [data]="popUpdata"></app-kpi-breakdown-heatmap-chart>
</div> 

</ng-template>