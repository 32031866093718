import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from "exceljs";
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelExportService {

  constructor( private currency :CurrencyPipe) { }
  convertCsvToExcelBuffer = (csvString: string) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row: string) => {
      return row.split(",")
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    return rawExcel
  }

//  export the raw JSON data

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_'  +moment(new Date()).format("DD-MMM-YYYY-hh.mma") + EXCEL_EXTENSION);
  }

 

//  custom Export excel using the columnDef
  public async customExportExcel(json: any[], excelFileName: string ,columnDef:any) {
  sessionStorage.setItem("data", JSON.stringify(json));
    let data: any = sessionStorage.getItem("data");
    data = JSON.parse(data);
    json = this.exportToXLSX(columnDef, data);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("data");

    sheet.columns = columnDef

    for (let i = 0; i < json.length; i++) {
      const jsonRow = json[i];
      sheet.addRow(jsonRow);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcel(buffer, excelFileName);
  }

  private saveAsExcel(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION
    );
  }

  exportToXLSX(columnDef: any, data: any) {
   let dateVar:any
   let dateBol:Boolean =false
    data.forEach( (val: any, index: any) => {

      columnDef.forEach((type: any) => {
        if (type.type == 'date') {
          val[type.key] = moment(val[type.key]).format('MM-DD-yyyy')
          dateVar=type.key
          dateBol=true
        }
        if (type.type == 'Quater') {
          val[type.key] ='Q'+ moment(val[type.key]).quarter()+" "+ moment(val[type.key]).format('yyyy')
        }
        if (type.type == 'percentage') {
          val[type.key] = (val[type.key] * 100 )+"%"
        }
        if (type.type == 'percent') {
          val[type.key] = val[type.key] +"%"
        }
        if (type.type == 'currency') {
          val[type.key] = '$'+ this.currency.transform(val[type.key], '', '', '1.0-2')
        }
        if (type.type == 'number') {
          val[type.key] =  this.currency.transform(val[type.key], '', '', '1.0-2')
        }
        if (type.type == 'masking') {
          val[type.key] =  '-'
        }
      });
      if (data.length == index + 1) {

      }
    });
  if(dateBol){
    data = data.sort((a:any, b:any) => moment(b[dateVar]).diff(moment(a[dateVar])));
  }
    return  data
    // sessionStorage.setItem("data", JSON.stringify(data));
  }




}