import { MatComponentsModule } from '../../mat-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBodyComponent } from './app-body/app-body.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { RouterModule } from '@angular/router';
// import { UserMenuComponent } from '../dashboard/user-menu/user-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserMenuComponent } from '../dashboard/user-menu/user-menu.component';



@NgModule({
  declarations: [
    AppBodyComponent,
    AppFooterComponent,
    AppHeaderComponent,
    DefaultLayoutComponent,
    UserMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule
  ],
  exports:[
    DefaultLayoutComponent,
    AppBodyComponent,
    AppFooterComponent,
    AppHeaderComponent
  ]
})
export class AppLayoutModule { }
