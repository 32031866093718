<div class="container">
    <div class="buttons">
      <button class="list" (click)="list($event ,'list')"><i class="fa fa-list">list</i></button>
      <button class="grid" (click)="list($event ,'grid')"><i class="fa fa-th-large"> grid</i></button>
    </div>
    <div class="wrapper" id="wrapper">
      <div class="col">
        Column #1
      </div>
      <div class="col">
        Column #2
      </div>
      <div class="col">
        Column #3
      </div>
      <div class="col">
        Column #4
      </div>
    </div>
  </div>