import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ConstantsService } from './constants.service';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseURL: string = this.constantsService.API_URL_USER;

    constructor(public constantsService: ConstantsService, private apiService:ApiService) { }

    /*
     *  Read user role
     */
    getRole(username: string, clientKey:string) {
        let requestObject = {
            API_URL: `api/get-user-role`,
            BODY: {
                'username': username,
                'client_key': clientKey
            }
        };
        return this.apiService.post(requestObject.API_URL, requestObject.BODY)
    }
}
