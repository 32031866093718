<div class="widget-container" #pieChartDiv>
  <!-- Headings -->
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background: white;">
      <div>

        <icon-chart [headerConfig]="item.config?.widget_header_configuration" [data]="this.pieData" [pageKey]="pageKey"
          [heading]="heading" [chartName]="'pieChartDiv'" [config]="pieC" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item" (screenChanges)="screenChanges($event)"></icon-chart>

      </div>

      <hr>
      <!-- Grid -->
      <br>

      <div>
        <loading *ngIf="isLoading"> </loading>
      </div>

      <div style="height: 200px; width: auto; " *ngIf="noData">
        <span class="bg-text">There is no data for this time period</span>
      </div>

      <div #pieC [style.display]="noData?'none':''">
        <div class="mains" *ngIf="this.isDataLoaded" >

          <div class="chart" >
            <div class="total">
              <div *ngIf="chartType == 'pieChart'" class="label">Total</div>
              <div *ngIf="chartType == 'pieChart'" class="value">{{ total }} records</div>
              <div [id]='DonutId+"pie"' class="chart-container"></div>
            </div>
            <div [id]='DonutId' class="chart-container"></div>
            <!-- <duc-tooltip [config]=" $any(config.tooltip)" [data]="data"></duc-tooltip> -->
          </div>

          <div class="legend" style="width: 100%;height: 200px; overflow: auto;">
            <div *ngFor="let item of legendData" class="group">
              <button class="color" [style]="'background: ' + item.color + ';border-color: ' + item.color"
                (click)="test()">&nbsp;</button>
              <div class="text">
                <span class="label">{{ item.label }}</span>
                <span *ngIf="item.info" class="info">{{ item.info }}</span>
              </div>

            </div>

          </div>

        </div>
      </div>
    </article>
  </main>
</div>