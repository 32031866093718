import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { FilterComponent } from '../../widget/filter/filter.component';
import { FilterService } from 'src/app/services/filter.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { AlertService } from 'src/app/services/alert-service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { query } from '@angular/animations';
import { DialogService } from 'src/app/services/dialog.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-kpi-alerts',
  templateUrl: './kpi-alerts.component.html',
  styleUrls: ['./kpi-alerts.component.scss']
})

export class KpiAlertsComponent implements OnInit, OnDestroy {
  // static key = 100901;

  options1: any[] = ['SP', 'HUB', 'Walgreen'];
  options2: any[] = ['Amber', 'Accredo', 'Ardon', 'BioPlus'];
  options3: any[] = ['Texas, Austin', 'Chicago, North Carolina', 'Amritsar'];
  options4: any[] = ['Recieved Records', 'Patients', 'Lagging Patients', 'Quantity Shipped', 'Shipped Patients'];
  options5: any[] = ['Email', 'InApp']
  options6: any[] = ['Jupitrol', 'Dupixient', 'Rezorock']
  options7: any[] = ['Weekly', 'Monthly', 'Quarterly']
  myForm!: FormGroup;
  employeeKey: any;
  clientKey: any;
  uniqueSourceTypes: any = []
  uniquekpiNames: any = []
  uniqueLocations: any = []
  uniqueSourceNames: any = []
  panelOpenState = false;
  formControl: any = []
  @Input("item") item: any;
  iconList: any;
  @Input("pageKey") pageKey: any;
  heading: any = "KPI Alerts";
  @ViewChild('therapyDiv', { static: true }) therapyDiv!: ElementRef;
  @ViewChild("fs") fs!: ElementRef;
  reqSubcription: Subscription[] = [];
  prescriberOfficeList: any = [];
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  isActive: boolean = false;
  opt: any
  valueProp = "_id"
  labelProp = "name"
  currentField: any
  deleteIcon = "delete"
  addIcon = "add_circle"
  apiList: any;
  previousModel: any;
  selectedModel: any;
  indexArray = [1];
  formRange!: FormGroup<any>;
  showByFilterObj: any = []
  indexPosition: any = 0
  dataSet: any = [];
  currentSelection: any = ''
  data = this.fB.array([this.createItem()]);
  sourceTypeList: any = [];
  currentBrand: any;
  sourceNameList: any = [];
  locationLists: any = [];
  kpiLists: any = [];
  sourceNamesList: any = [];
  savedSubsItems: any = [];
  selectedBrand: any;
  groupedData: any = [];
  uniqueData: any = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  middlePosition: MatSnackBarHorizontalPosition = 'center';
  alertKeyValue: any = 'KPI'
  alertTypeKey: any = 0
  nullSourceType: boolean = false
  nullSourceName: boolean = false
  nulllocationValues: boolean = false
  nullAlerts: boolean = false
  nullTimeCycle: boolean = false
  isLoading = false;
  @ViewChild('search') searchTextBox!: ElementRef;
  totalSourceNames: any = [];
  totalLocationNames: any = [];

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("alertSettingNew", { static: true }) alertSettingNew!: ElementRef;

  constructor(private fB: FormBuilder, public newFilterService: NewFilterService, private router: ActivatedRoute,
    public deviceDetectionService: DeviceDetectionService,
    public alertService: AlertService,
    private filterServiceOld: FilterService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private navigationService: NavigationService

  ) {
    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })

  }

  //loader Method
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }

  start() { this.isLoading = true; }

  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.alertSettingNew.nativeElement);
    
    this.start()
    this.alertKeyValue = this.item.config?.alert_name_key
    this.reqSubcription.push(this.filterServiceOld.filterQuery.subscribe(
      (query: any) => {
        this.options1 = []
        this.options2 = []
        this.options3 = []
        this.options4 = []
        this.dataSet = []
        this.formControl = []
        this.data.clear()
        this.groupedData = []
        this.start()
        this.currentBrand = query.brand_key
        // this.getKPIList()
        this.getSourceList()
      }))
    // this.getBrandData()
  }

  get rows(): FormArray {
    return this.myForm.get('rows') as FormArray;
  }

  ngOnChanges() {
  }

  createRow(): FormGroup {
    return this.fB.group({
      item1: ['', Validators.required],
      item2: ['', Validators.required],
      item3: [''],
      item4: [],
      item5: ['', Validators.required],
      item6: [''],
      item7: ['', Validators.required],
      item8: [''],
      location_flag: "N",
      time_cycle_flag: "N",
      sourceNameSearchList: [''],
      locationSearchList: ['']
    });
  }

  // groupDataByLocationKey(data: any) {
  //   const groupedData: { [key: string]: any } = {};

  //   data.forEach((item: any) => {
  //     const alertKey = item.alert_key ? String(item.alert_key) : 'null';
  //     const locationKey = item.du_sp_location_key != null ? String(item.du_sp_location_key) : 'null';
  //     const dataSourceKey = item.data_source_dim_key ? String(item.data_source_dim_key) : 'null';

  //     const groupKey = `${alertKey}_${locationKey}_${dataSourceKey}`;

  //     if (!groupedData[groupKey]) {
  //       groupedData[groupKey] = { ...item };
  //       groupedData[groupKey].time_cycle_dim_key = item.time_cycle_dim_key ? [item.time_cycle_dim_key] : [];
  //     } else {
  //       if (item.time_cycle_dim_key && !groupedData[groupKey].time_cycle_dim_key.includes(item.time_cycle_dim_key)) {
  //         groupedData[groupKey].time_cycle_dim_key.push(item.time_cycle_dim_key);
  //       }

  //       if (item.in_app_subscription_flg === '1' || (item.in_app_subscription_flg === '0' && groupedData[groupKey].in_app_subscription_flg !== '1')) {
  //         groupedData[groupKey].in_app_subscription_flg = item.in_app_subscription_flg;
  //       }

  //       if (item.email_subscription_flg === '1' || (item.email_subscription_flg === '0' && groupedData[groupKey].email_subscription_flg !== '1')) {
  //         groupedData[groupKey].email_subscription_flg = item.email_subscription_flg;
  //       }
  //     }
  //   });

  //   let item = [
  //     {
  //       "client_dim_key": 2001,
  //       "brand_dim_key": 200102,
  //       "source_type": "SP",
  //       "data_source_dim_key": 3007,
  //       "du_sp_location_key": 2017,
  //       "alert_key": 200102,
  //       "time_cycle_dim_key": [100102],
  //       "employee_dim_key": 100114,
  //       "email_subscription_flg": "1",
  //       "in_app_subscription_flg": "1"
  //   },
  //     {
  //       "client_dim_key": 2001,
  //       "brand_dim_key": 200102,
  //       "source_type": "SP",
  //       "data_source_dim_key": 3007,
  //       "du_sp_location_key": 2017,
  //       "alert_key": 200102,
  //       "time_cycle_dim_key": [100103],
  //       "employee_dim_key": 100114,
  //       "email_subscription_flg": "0",
  //       "in_app_subscription_flg": "1"
  //   },
  //     {
  //       "client_dim_key": 2001,
  //       "brand_dim_key": 200102,
  //       "source_type": "SP",
  //       "data_source_dim_key": 3007,
  //       "du_sp_location_key": 2017,
  //       "alert_key": 200102,
  //       "time_cycle_dim_key": [100104],
  //       "employee_dim_key": 100114,
  //       "email_subscription_flg": "1",
  //       "in_app_subscription_flg": "0"
  //   },
  //   ]

  //   return Object.values(groupedData);
  // }

  groupDataByLocationKey(originalData: any) {
    const groupedData: any = [];

    originalData.forEach((item: any) => {
      const existingGroup = groupedData.find((group: any) => {
        return (
          group.client_dim_key === item.client_dim_key &&
          group.brand_dim_key === item.brand_dim_key &&
          group.source_type === item.source_type &&
          group.data_source_dim_key === item.data_source_dim_key &&
          group.du_sp_location_key === item.du_sp_location_key &&
          group.alert_key === item.alert_key &&
          group.employee_dim_key === item.employee_dim_key &&
          group.email_subscription_flg === item.email_subscription_flg &&
          group.in_app_subscription_flg === item.in_app_subscription_flg
        );
      });

      if (existingGroup) {
        existingGroup.time_cycle_dim_key.push(item.time_cycle_dim_key);
      } else {
        const newGroup = { ...item, time_cycle_dim_key: [item.time_cycle_dim_key] };
        groupedData.push(newGroup);
      }
    });

    return groupedData
    // You can now use the groupedData in your component
  }




  removeDuplicates(data: any[]): any[] {
    const uniqueData: any[] = [];
    data.forEach(item => {
      const exists = uniqueData.some(existingItem => existingItem.du_sp_location_key === item.du_sp_location_key);
      if (!exists) {
        uniqueData.push(item);
      }
    });

    return uniqueData;
  }

  getSourceNamesList(source_type: any, sectionIndex: any, sectionInIndex: any, item2: any, item3: any) {
    let findArray: any = []
    let sourceNameArray: any = []
    let locationSavedListArray: any = []
    let newLocList: any = []

    setTimeout(() => {
      this.start()
      this.sourceTypeListDetails?.find((i: any) => {
        if (i) {
          if (i.source_type.source_type == source_type) {
            const data = this.formRange.get('data') as FormArray;
            const section = data?.at(sectionIndex)?.get('sections') as FormArray;
            const sectionToUpdate = section?.at(sectionInIndex);
            sectionToUpdate?.get('item6')?.patchValue(i.soureNamesList);
            sectionToUpdate?.get('sourceNameSearchList')?.patchValue(i.soureNamesList);

            item2?.forEach((type: any) => {
              i?.soureNamesList?.forEach((j: any) => {
                if (j?.source_key == type) {
                  findArray.push(j)
                }
              })
            })

            // findArray = this.removeDuplicatesfromList(findArray, 'source_key')

            if (findArray?.length == sectionToUpdate?.get('item6')?.value.length) {
              findArray.unshift('all');
              sectionToUpdate?.get('item2')?.patchValue(findArray);
            } else {
              sectionToUpdate?.get('item2')?.patchValue(findArray);
            }

            item2?.forEach((loc: any) => {
              this.totalLocationNames?.forEach((j: any) => {
                if (j?.data_source_dim_key == loc) {
                  newLocList?.push(j)
                }
              })
            })

            newLocList = this.removeDuplicatesfromList(newLocList, 'sp_location_key')
            sectionToUpdate?.get('item4')?.patchValue(newLocList);
            sectionToUpdate?.get('locationSearchList')?.patchValue(newLocList);
            item3?.forEach((i: any) => {
              newLocList?.forEach((j: any) => {
                if (j?.sp_location_key == i) {
                  locationSavedListArray?.push(j)
                }
              })
            })


            locationSavedListArray = this.removeDuplicatesfromList(locationSavedListArray, 'sp_location_key')
            if (locationSavedListArray?.length == sectionToUpdate?.get('item4')?.value.length) {
              locationSavedListArray?.unshift('all');
              sectionToUpdate?.get('item3')?.patchValue(locationSavedListArray);
            } else {
              sectionToUpdate?.get('item3')?.patchValue(locationSavedListArray);
            }

          }
        }

      })
      this.stop(100).then(() => this.isLoading = false);
    }, 8000)


    // this.alertService.getAllSourceNames(this.currentBrand, source_type)?.subscribe(
    //   (resp: any) => {
    //     if (resp) {
    //       const data = this.formRange.get('data') as FormArray;
    //       const section = data.at(sectionIndex).get('sections') as FormArray;
    //       const sectionToUpdate = section.at(sectionInIndex);
    //       sectionToUpdate.get('item6')?.patchValue(resp);
    //       sectionToUpdate.get('sourceNameSearchList')?.patchValue(resp);

    //       item2.forEach((i: any) => {
    //         resp.forEach((j: any) => {
    //           if (j.source_key == i) {
    //             findArray.push(j)
    //           }
    //         })
    //       })

    //       findArray = this.removeDuplicatesfromList(findArray, 'source_key')
    //       findArray.forEach((k: any) => {
    //         sourceNameArray.push(k.source_nm)
    //       })

    //       const commaSeparatedString = sourceNameArray.join(', ');
    //       if (findArray.length == sectionToUpdate.get('item6')?.value.length) {
    //         findArray.unshift('all');
    //         sectionToUpdate.get('item2')?.patchValue(findArray);
    //       } else {
    //         sectionToUpdate.get('item2')?.patchValue(findArray);
    //       }

    //       this.alertService.getLocationsDataList(this.currentBrand, commaSeparatedString).subscribe((responseData: any) => {
    //         if (responseData) {
    //           this.locationLists = responseData
    //           sectionToUpdate.get('item4')?.patchValue(this.locationLists);
    //           sectionToUpdate.get('locationSearchList')?.patchValue(this.locationLists);
    //           item3.forEach((i: any) => {
    //             responseData.forEach((j: any) => {
    //               if (j.sp_location_key == i) {
    //                 locationSavedListArray.push(j)
    //               }
    //             })
    //           })
    //           locationSavedListArray = this.removeDuplicatesfromList(locationSavedListArray, 'sp_location_key')
    //           if (locationSavedListArray.length == sectionToUpdate.get('item4')?.value.length) {
    //             locationSavedListArray.unshift('all');
    //             sectionToUpdate.get('item3')?.patchValue(locationSavedListArray);

    //           } else {
    //             sectionToUpdate.get('item3')?.patchValue(locationSavedListArray);

    //           }

    //         }
    //       })

    //     }
    //     this.stop(10).then(() => this.isLoading = false);
    //   }
    // )
  }

  removeDuplicatesfromList(array: any[], key: string): any[] {
    const uniqueMap = new Map();
    array.forEach(item => {
      const keyValue = item[key];
      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, item);
      }
    });

    return Array.from(uniqueMap.values());
  }

  getBrandData() {
    this.start()
    this.reqSubcription.push(this.alertService.getBrandData().subscribe((resp: any) => {
      this.options6 = resp
      this.currentBrand = resp[0].brand_key
      this.getSourceList()
    }))
  }

  onBrandChange(item: any) {
    this.currentBrand = item.value.brand_key
    this.selectedBrand = item.value
    this.getSourceList()
  }

  sourceTypeListDetails: any = []

  getSourceList() {
    this.start()
    
    this.reqSubcription.push(this.alertService.getSoruceList(this.currentBrand).subscribe(
      (resp: any) => {
        if (resp)
          this.sourceTypeList = resp
        if (this.sourceTypeList) {
          const outputString = this.sourceTypeList.map((item: any) => item?.source_type).join(',');

          this.sourceTypeList?.forEach((j: any) => {
            this.reqSubcription.push(this.alertService.getAllSourceNames(this.currentBrand, j?.source_type)?.subscribe((responseItem: any) => {
              if (responseItem) {
                this.sourceTypeListDetails.push({
                  source_type: j,
                  soureNamesList: responseItem
                })
              }
            }))
          })

          this.reqSubcription.push(this.alertService.getAllSourceNames(this.currentBrand, outputString)?.subscribe(
            (response: any) => {
              if (response) {
                this.totalSourceNames = response
                const locstring = response.map((item: any) => item?.source_nm).join(',');
                this.reqSubcription.push(this.alertService.getLocationsDataList(this.currentBrand, locstring).subscribe(
                  (responseData: any) => {
                    this.totalLocationNames = responseData
                  }))

              }
              // this.stop(10).then(() => this.isLoading = false);
            }
          ));
        }
        this.getKPIList()
      },
      (error: any) => {
        this.sourceTypeList = [
          {
            "source_type": "HUB"
          },
          {
            "source_type": "SP"
          },
        ]
      }
    ))

  }

  // getKPIList() {
  //   this.kpiLists = []
  //   this.alertService.getKPIDataList()?.subscribe((resp: any) => {
  //     if (resp)
  //       this.kpiLists = resp
  //     this.getSavedSubscription()
  //   })
  // }

  getKPIList() {
    this.start()
    this.kpiLists = []
    this.options1 = []
    this.options2 = []
    this.options3 = []
    this.options4 = []
    this.dataSet = []
    this.formControl = []
    this.data.clear()
    this.groupedData = []
    this.reqSubcription.push(this.alertService.getEventsAPI(this.currentBrand, this.alertKeyValue).subscribe((resp: any) => {
      this.kpiLists = resp
      this.kpiLists.find((i: any) => {
        if (i.alert_type_key) {
          this.alertTypeKey = i.alert_type_key
        }
      })
      this.getSavedSubscription()
    }))
  }

  disableOnLoad: boolean = true

  getSavedSubscription() {
    this.start()
    let data: any = sessionStorage.getItem("employee_key")
    const employeeKeyObject = JSON.parse(data);
    const employeeValue = employeeKeyObject._value;
    const obj = {
      employee_key: employeeValue,
      brand_key: this.currentBrand,
      alert_type: this.alertKeyValue
    }

    this.reqSubcription.push(this.alertService.getSavedSubscription(obj).subscribe(
      (resp: any) => {
        this.start()
        this.savedSubsItems = resp
        if (resp) {
          this.options1 = []
          this.options2 = []
          this.options3 = []
          this.options4 = []
          this.dataSet = []
          this.formControl = []
          this.data.clear()
          this.groupedData = []

          resp = this.savedSubsItems
          this.groupedData = this.groupDataByLocationKey(resp);
          this.dataSet = this.groupedData
          const sourceTypeSet = new Set<string>();
          const kpiName = new Set<string>();
          const sourceName = new Set<string>();
          const locations = new Set<string>();

          for (const item of this.dataSet) {

            // Source Type Filter
            if (item.source_type != "") {
              sourceTypeSet.add(item.source_type);
            }

            // Source Type Filter
            if (item.alert_key != '') {
              kpiName.add(item.alert_key);
            }

            // Source Name Filter
            if (item.data_source_dim_key != '') {
              sourceName.add(item.data_source_dim_key);
            }

            // Location Filter
            if (item.du_sp_location_key != '') {
              locations.add(item.du_sp_location_key);
            }
          }

          this.uniqueSourceTypes = Array.from(sourceTypeSet);
          this.uniqueLocations = Array.from(locations);
          this.uniqueSourceNames = Array.from(sourceName);
          this.uniquekpiNames = Array.from(kpiName);

          this.options1 = this.uniqueSourceTypes
          this.options2 = this.uniqueSourceNames
          this.options3 = this.uniqueLocations
          this.options4 = this.kpiLists

          this.options4.forEach((i: any, k: any) => {
            this.formControl.push({
              dropdownName: i.alert_nm,
              alertDescription: i.alert_desc,
              alert_key: i.alert_key,
              alert_type_key: i.alert_type_key,
              location_alert_applicable_flg: i.location_alert_applicable_flg,
              time_cycle_flg: i.time_cycle_flg,
              checked: false,
              location_applicable: false,
              time_cycle_applicable: false,
              totalObj: [],
              sections: []
            })
          })

          this.formControl.map((i: any) => {
            this.dataSet.forEach((j: any) => {

              if (i.alert_key == j.alert_key) {
                i.totalObj.push(j)
              }
            })
          })

          let source_typeWMQ: any = ""
          let source_typeWMQInApp: any = ""
          let source_typeWMQInEmail: any = ""
          let source_typeW: any = ""
          let source_typeWInApp: any = ""
          let source_typeWInEmail: any = ""
          let source_typeM: any = ""
          let source_typeMInApp: any = ""
          let source_typeMInEmail: any = ""
          let source_typeQ: any = ""
          let source_typeQInApp: any = ""
          let source_typeQInEmail: any = ""
          let source_typeWM: any = ""
          let source_typeWMInApp: any = ""
          let source_typeWMInEmail: any = ""
          let source_typeWQ: any = ""
          let source_typeWQInApp: any = ""
          let source_typeWQInEmail: any = ""
          let source_typeMQ: any = ""
          let source_typeMQInApp: any = ""
          let source_typeMQInEmail: any = ""
          let source_typeNill: any = ""

          let data_source_nameWMQ: any = []
          let data_source_nameWMQInApp: any = []
          let data_source_nameWMQInEmail: any = []
          let data_source_nameW: any = []
          let data_source_nameWInApp: any = []
          let data_source_nameWInEmail: any = []
          let data_source_nameM: any = []
          let data_source_nameMInApp: any = []
          let data_source_nameMInEmail: any = []
          let data_source_nameQ: any = []
          let data_source_nameQInApp: any = []
          let data_source_nameQInEmail: any = []
          let data_source_nameWM: any = []
          let data_source_nameWMInApp: any = []
          let data_source_nameWMInEmail: any = []
          let data_source_nameWQ: any = []
          let data_source_nameWQInApp: any = []
          let data_source_nameWQInEmail: any = []
          let data_source_nameMQ: any = []
          let data_source_nameMQInApp: any = []
          let data_source_nameMQInEmail: any = []
          let data_source_nameNill: any = []

          let locations_dataWMQ: any = []
          let locations_dataWMQInApp: any = []
          let locations_dataWMQInEmail: any = []
          let locations_dataW: any = []
          let locations_dataWInApp: any = []
          let locations_dataWInEmail: any = []
          let locations_dataM: any = []
          let locations_dataMInApp: any = []
          let locations_dataMInEmail: any = []
          let locations_dataQ: any = []
          let locations_dataQInApp: any = []
          let locations_dataQInEmail: any = []
          let locations_dataWM: any = []
          let locations_dataWMInApp: any = []
          let locations_dataWMInEmail: any = []
          let locations_dataWQ: any = []
          let locations_dataWQInApp: any = []
          let locations_dataWQInEmail: any = []
          let locations_dataMQ: any = []
          let locations_dataMQInApp: any = []
          let locations_dataMQInEmail: any = []
          let locations_dataNill: any = []

          let source_list: any = []
          let source_type_list: any = []

          this.formControl.forEach((i: any) => {
            source_typeWMQ = ""
            source_typeWMQInApp = ""
            source_typeWMQInEmail = ""
            source_typeW = ""
            source_typeWInApp = ""
            source_typeWInEmail = ""
            source_typeM = ""
            source_typeMInApp = ""
            source_typeMInEmail = ""
            source_typeQ = ""
            source_typeQInApp = ""
            source_typeQInEmail = ""
            source_typeWM = ""
            source_typeWMInApp = ""
            source_typeWMInEmail = ""
            source_typeWQ = ""
            source_typeWQInApp = ""
            source_typeWQInEmail = ""
            source_typeMQ = ""
            source_typeMQInApp = ""
            source_typeMQInEmail = ""

            data_source_nameWMQ = []
            data_source_nameWMQInApp = []
            data_source_nameWMQInEmail = []
            data_source_nameW = []
            data_source_nameWInApp = []
            data_source_nameWInEmail = []
            data_source_nameM = []
            data_source_nameMInApp = []
            data_source_nameMInEmail = []
            data_source_nameQ = []
            data_source_nameQInApp = []
            data_source_nameQInEmail = []
            data_source_nameWM = []
            data_source_nameWMInApp = []
            data_source_nameWMInEmail = []
            data_source_nameWQ = []
            data_source_nameWQInApp = []
            data_source_nameWQInEmail = []
            data_source_nameMQ = []
            data_source_nameMQInApp = []
            data_source_nameMQInEmail = []

            locations_dataWMQ = []
            locations_dataWMQInApp = []
            locations_dataWMQInEmail = []
            locations_dataW = []
            locations_dataWInApp = []
            locations_dataWInEmail = []
            locations_dataM = []
            locations_dataMInApp = []
            locations_dataMInEmail = []
            locations_dataQ = []
            locations_dataQInApp = []
            locations_dataQInEmail = []
            locations_dataWM = []
            locations_dataWMInApp = []
            locations_dataWMInEmail = []
            locations_dataWQ = []
            locations_dataWQInApp = []
            locations_dataWQInEmail = []
            locations_dataMQ = []
            locations_dataMQInApp = []
            locations_dataMQInEmail = []

            source_list = []
            source_type_list = []

            i.totalObj.forEach((j: any) => {
              if (i.alert_key == j.alert_key) {
                source_type_list = this.sourceTypeList

                // Weekly,Monthly,Quarterly Combinations
                if ((((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104, 100102]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103, 100102])))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeWMQ = j.source_type
                  // if (data_source_nameWMQ.length > 0) {

                  //   data_source_nameWMQ.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWMQ.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWMQ.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWMQ.length > 0) {

                  //   locations_dataWMQ.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWMQ.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWMQ.push(j.du_sp_location_key)
                  // }


                  if (!data_source_nameWMQ.includes(j?.data_source_dim_key)) {
                    data_source_nameWMQ.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWMQ.includes(j?.du_sp_location_key)) {
                    locations_dataWMQ.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWMQ,
                    "item2": data_source_nameWMQ,
                    "item3": locations_dataWMQ,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104, 100102]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103, 100102])))) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeWMQInApp = j.source_type

                  // if (data_source_nameWMQInApp.length > 0) {

                  //   data_source_nameWMQInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWMQInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWMQInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWMQInApp.length > 0) {

                  //   locations_dataWMQInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWMQInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWMQInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWMQInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameWMQInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWMQInApp.includes(j?.du_sp_location_key)) {
                    locations_dataWMQInApp.push(j?.du_sp_location_key);
                  } else {
                  }



                  const obj = {
                    "item1": source_typeWMQInApp,
                    "item2": data_source_nameWMQInApp,
                    "item3": locations_dataWMQInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102, 100104]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104, 100102]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102, 100103]))) || (JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103, 100102])))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeWMQInEmail = j.source_type

                  // if (data_source_nameWMQInEmail.length > 0) {
                  //   data_source_nameWMQInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWMQInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWMQInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWMQInEmail.length > 0) {
                  //   locations_dataWMQInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWMQInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWMQInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWMQInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameWMQInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWMQInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataWMQInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWMQInEmail,
                    "item2": data_source_nameWMQInEmail,
                    "item3": locations_dataWMQInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Weekly Combinations
                if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeW = j.source_type
                  // locations_dataW = locations_dataW.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });
                  // data_source_nameW = data_source_nameW.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });
                  // if (data_source_nameW.length > 0) {

                  //   data_source_nameW?.find((data: any) => {
                  //     if (data == j?.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       // data_source_nameW.push(j.data_source_dim_key)
                  //       if (!data_source_nameW.includes(j?.data_source_dim_key)) {
                  //         data_source_nameW?.push(j?.data_source_dim_key);
                  //       }

                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameW.push(j.data_source_dim_key)
                  // }

                  if (!data_source_nameW.includes(j?.data_source_dim_key)) {
                    data_source_nameW.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataW.includes(j?.du_sp_location_key)) {
                    locations_dataW.push(j?.du_sp_location_key);
                  } else {
                  }


                  // locations_dataW = locations_dataW.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });
                  // data_source_nameW = data_source_nameW.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });

                  const obj = {
                    "item1": source_typeW,
                    "item2": data_source_nameW,
                    "item3": locations_dataW,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }


                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102])) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeWInApp = j.source_type

                  if (data_source_nameWInApp.length > 0) {

                    data_source_nameWInApp.forEach((data: any) => {
                      if (data == j.data_source_dim_key) {
                        return
                      } else {
                        data_source_nameWInApp.push(j.data_source_dim_key)
                      }
                    })
                  }
                  else {
                    data_source_nameWInApp.push(j.data_source_dim_key)
                  }

                  if (locations_dataWInApp.length > 0) {

                    locations_dataWInApp.forEach((data: any) => {
                      if (data == j.du_sp_location_key) {
                        return
                      } else {
                        locations_dataWInApp.push(j.du_sp_location_key)
                      }
                    })
                  } else {
                    locations_dataWInApp.push(j.du_sp_location_key)
                  }


                  const obj = {
                    "item1": source_typeWInApp,
                    "item2": data_source_nameWInApp,
                    "item3": locations_dataWInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeWInEmail = j.source_type
                  // locations_dataWInEmail = locations_dataWInEmail.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });
                  // data_source_nameWInEmail = data_source_nameWInEmail.filter((value: any, index: any, self: any) => {
                  //   return self.indexOf(value) === index;
                  // });
                  // if (data_source_nameWInEmail.length > 0) {
                  //   data_source_nameWInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWInEmail.length > 0) {
                  //   locations_dataWInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameWInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataWInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWInEmail,
                    "item2": data_source_nameWInEmail,
                    "item3": locations_dataWInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Monthly Combinations
                if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeM = j.source_type
                  // if (data_source_nameM.length > 0) {

                  //   data_source_nameM.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameM.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameM.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataM.length > 0) {

                  //   locations_dataM.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataM.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataM.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameM.includes(j?.data_source_dim_key)) {
                    data_source_nameM.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataM.includes(j?.du_sp_location_key)) {
                    locations_dataM.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeM,
                    "item2": data_source_nameM,
                    "item3": locations_dataM,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103])) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeMInApp = j.source_type

                  // if (data_source_nameMInApp.length > 0) {

                  //   data_source_nameMInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMInApp.length > 0) {

                  //   locations_dataMInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameMInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMInApp.includes(j?.du_sp_location_key)) {
                    locations_dataMInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMInApp,
                    "item2": data_source_nameMInApp,
                    "item3": locations_dataMInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeMInEmail = j.source_type

                  // if (data_source_nameMInEmail.length > 0) {
                  //   data_source_nameMInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMInEmail.length > 0) {
                  //   locations_dataMInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameMInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataMInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMInEmail,
                    "item2": data_source_nameMInEmail,
                    "item3": locations_dataMInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Quarterly Combinations
                if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeQ = j.source_type
                  // if (data_source_nameQ.length > 0) {

                  //   data_source_nameQ.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameQ.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameQ.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataQ.length > 0) {

                  //   locations_dataQ.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataQ.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataQ.push(j.du_sp_location_key)
                  // }


                  if (!data_source_nameQ.includes(j?.data_source_dim_key)) {
                    data_source_nameQ.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataQ.includes(j?.du_sp_location_key)) {
                    locations_dataQ.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeQ,
                    "item2": data_source_nameQ,
                    "item3": locations_dataQ,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104])) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeQInApp = j.source_type

                  // if (data_source_nameQInApp.length > 0) {

                  //   data_source_nameQInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameQInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameQInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataQInApp.length > 0) {

                  //   locations_dataQInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataQInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataQInApp.push(j.du_sp_location_key)
                  // }
                  if (!data_source_nameQInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameQInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataQInApp.includes(j?.du_sp_location_key)) {
                    locations_dataQInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeQInApp,
                    "item2": data_source_nameQInApp,
                    "item3": locations_dataQInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104])) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeQInEmail = j.source_type
                  if (!data_source_nameQInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameQInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataQInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataQInEmail.push(j?.du_sp_location_key);
                  } else {
                  }
                  // if (data_source_nameQInEmail.length > 0) {
                  //   data_source_nameQInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameQInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameQInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataQInEmail.length > 0) {
                  //   locations_dataQInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataQInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataQInEmail.push(j.du_sp_location_key)
                  // }

                  const obj = {
                    "item1": source_typeQInEmail,
                    "item2": data_source_nameQInEmail,
                    "item3": locations_dataQInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Weekly,Monthly Combinations
                if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102]))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeWM = j.source_type
                  // if (data_source_nameWM.length > 0) {

                  //   data_source_nameWM.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWM.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWM.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWM.length > 0) {

                  //   locations_dataWM.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWM.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWM.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWM.includes(j?.data_source_dim_key)) {
                    data_source_nameWM.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWM.includes(j?.du_sp_location_key)) {
                    locations_dataWM.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWM,
                    "item2": data_source_nameWM,
                    "item3": locations_dataWM,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102]))) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeWMInApp = j.source_type

                  // if (data_source_nameWMInApp.length > 0) {

                  //   data_source_nameWMInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWMInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWMInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWMInApp.length > 0) {

                  //   locations_dataWMInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWMInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWMInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWMInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameWMInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWMInApp.includes(j?.du_sp_location_key)) {
                    locations_dataWMInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWMInApp,
                    "item2": data_source_nameWMInApp,
                    "item3": locations_dataWMInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100103])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100102]))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeWMInEmail = j.source_type

                  // if (data_source_nameWMInEmail.length > 0) {
                  //   data_source_nameWMInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWMInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWMInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWMInEmail.length > 0) {
                  //   locations_dataWMInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWMInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWMInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWMInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameWMInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWMInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataWMInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWMInEmail,
                    "item2": data_source_nameWMInEmail,
                    "item3": locations_dataWMInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Monthly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Weekly,Quarterly Combinations
                if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102]))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeWQ = j.source_type
                  // if (data_source_nameWQ.length > 0) {

                  //   data_source_nameWQ.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWQ.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWQ.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWQ.length > 0) {

                  //   locations_dataWQ.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWQ.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWQ.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWQ.includes(j?.data_source_dim_key)) {
                    data_source_nameWQ.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWQ.includes(j?.du_sp_location_key)) {
                    locations_dataWQ.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWQ,
                    "item2": data_source_nameWQ,
                    "item3": locations_dataWQ,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102])))) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeWQInApp = j.source_type

                  // if (data_source_nameWQInApp.length > 0) {

                  //   data_source_nameWQInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWQInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWQInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWQInApp.length > 0) {

                  //   locations_dataWQInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWQInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWQInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWQInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameWQInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWQInApp.includes(j?.du_sp_location_key)) {
                    locations_dataWQInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWQInApp,
                    "item2": data_source_nameWQInApp,
                    "item3": locations_dataWQInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if ((((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100102, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100102])))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeWQInEmail = j.source_type

                  // if (data_source_nameWQInEmail.length > 0) {
                  //   data_source_nameWQInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameWQInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameWQInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataWQInEmail.length > 0) {
                  //   locations_dataWQInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataWQInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataWQInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameWQInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameWQInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataWQInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataWQInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeWQInEmail,
                    "item2": data_source_nameWQInEmail,
                    "item3": locations_dataWQInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // Quarterly,Monthly Combinations
                if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103]))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeMQ = j.source_type
                  // if (data_source_nameMQ.length > 0) {

                  //   data_source_nameMQ.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQ.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQ.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQ.length > 0) {

                  //   locations_dataMQ.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQ.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQ.push(j.du_sp_location_key)
                  // }


                  if (!data_source_nameMQ.includes(j?.data_source_dim_key)) {
                    data_source_nameMQ.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQ.includes(j?.du_sp_location_key)) {
                    locations_dataMQ.push(j?.du_sp_location_key);
                  } else {
                  }


                  const obj = {
                    "item1": source_typeMQ,
                    "item2": data_source_nameMQ,
                    "item3": locations_dataMQ,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103]))) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeMQInApp = j.source_type

                  // if (data_source_nameMQInApp.length > 0) {

                  //   data_source_nameMQInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQInApp.length > 0) {

                  //   locations_dataMQInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMQInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameMQInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQInApp.includes(j?.du_sp_location_key)) {
                    locations_dataMQInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMQInApp,
                    "item2": data_source_nameMQInApp,
                    "item3": locations_dataMQInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100103, 100104])) || JSON.stringify(j.time_cycle_dim_key) == (JSON.stringify([100104, 100103]))) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeMQInEmail = j.source_type

                  // if (data_source_nameMQInEmail.length > 0) {
                  //   data_source_nameMQInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQInEmail.length > 0) {
                  //   locations_dataMQInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMQInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameMQInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataMQInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMQInEmail,
                    "item2": data_source_nameMQInEmail,
                    "item3": locations_dataMQInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": ['Weekly', 'Quarterly'],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // When No Time Cycle Array Present but value null
                if (((j.time_cycle_dim_key[0] == null) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 1))) {
                  source_typeMQ = j.source_type
                  // if (data_source_nameMQ.length > 0) {

                  //   data_source_nameMQ.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQ.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQ.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQ.length > 0) {

                  //   locations_dataMQ.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQ.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQ.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMQ.includes(j?.data_source_dim_key)) {
                    data_source_nameMQ.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQ.includes(j?.du_sp_location_key)) {
                    locations_dataMQ.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMQ,
                    "item2": data_source_nameMQ,
                    "item3": locations_dataMQ,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email", "InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": null,
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((j.time_cycle_dim_key[0] == null) && (j.email_subscription_flg == 0) && (j.in_app_subscription_flg == 1))) {
                  source_typeMQInApp = j.source_type

                  // if (data_source_nameMQInApp.length > 0) {

                  //   data_source_nameMQInApp.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQInApp.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQInApp.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQInApp.length > 0) {

                  //   locations_dataMQInApp.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQInApp.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQInApp.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMQInApp.includes(j?.data_source_dim_key)) {
                    data_source_nameMQInApp.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQInApp.includes(j?.du_sp_location_key)) {
                    locations_dataMQInApp.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMQInApp,
                    "item2": data_source_nameMQInApp,
                    "item3": locations_dataMQInApp,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["InApp"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": null,
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }
                else if (((j.time_cycle_dim_key[0] == null) && (j.email_subscription_flg == 1) && (j.in_app_subscription_flg == 0))) {
                  source_typeMQInEmail = j.source_type

                  // if (data_source_nameMQInEmail.length > 0) {
                  //   data_source_nameMQInEmail.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameMQInEmail.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameMQInEmail.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataMQInEmail.length > 0) {
                  //   locations_dataMQInEmail.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataMQInEmail.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataMQInEmail.push(j.du_sp_location_key)
                  // }

                  if (!data_source_nameMQInEmail.includes(j?.data_source_dim_key)) {
                    data_source_nameMQInEmail.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataMQInEmail.includes(j?.du_sp_location_key)) {
                    locations_dataMQInEmail.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": source_typeMQInEmail,
                    "item2": data_source_nameMQInEmail,
                    "item3": locations_dataMQInEmail,
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": ["Email"],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": null,
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }

                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj);
                  }
                }

                // When No Time cycle Exists
                if ((!j.time_cycle_dim_key)) {
                  source_typeNill = j.source_type
                  // if (data_source_nameNill.length > 0) {

                  //   data_source_nameNill.forEach((data: any) => {
                  //     if (data == j.data_source_dim_key) {
                  //       return
                  //     } else {
                  //       data_source_nameNill.push(j.data_source_dim_key)
                  //     }
                  //   })
                  // }
                  // else {
                  //   data_source_nameNill.push(j.data_source_dim_key)
                  // }

                  // if (locations_dataNill.length > 0) {

                  //   locations_dataNill.forEach((data: any) => {
                  //     if (data == j.du_sp_location_key) {
                  //       return
                  //     } else {
                  //       locations_dataNill.push(j.du_sp_location_key)
                  //     }
                  //   })
                  // } else {
                  //   locations_dataNill.push(j.du_sp_location_key)
                  // }


                  if (!locations_dataNill.includes(j?.data_source_dim_key)) {
                    locations_dataNill.push(j?.data_source_dim_key);
                  } else {
                  }

                  if (!locations_dataNill.includes(j?.du_sp_location_key)) {
                    locations_dataNill.push(j?.du_sp_location_key);
                  } else {
                  }

                  const obj = {
                    "item1": "",
                    "item2": [],
                    "item3": [],
                    "item4": this.options3,
                    "locationSearchList": this.options3,
                    "item5": [],
                    "item6": source_list,
                    "sourceNameSearchList": source_list,
                    "item7": [],
                    "item8": source_type_list,
                    "location_flag": j.location_alert_applicable_flg,
                    "time_cycle_flag": j.time_cycle_flg,
                  }
                  if (i.sections.every((section: any) => section.item2 !== data_source_nameW && section.item3 !== locations_dataW)) {
                    return i.sections.push(obj), i.checked = false
                  }
                }
              }
            })

          })


          this.formRange = this.fB.group({
            data: this.data,
          });

          this.formControl.forEach((i: any) => {
            i.sections = i.sections.filter((value: any, index: any, self: any) =>
              index === self.findIndex((obj: any) =>
                JSON.stringify(obj.item1) === JSON.stringify(value.item1) &&
                JSON.stringify(obj.item2) === JSON.stringify(value.item2) &&
                JSON.stringify(obj.item3) === JSON.stringify(value.item3) &&
                JSON.stringify(obj.item5) === JSON.stringify(value.item5) &&
                JSON.stringify(obj.item7) === JSON.stringify(value.item7)
              )
            );

            if (i.sections.length > 0) {
              i.checked = true
            }

          });

          this.formControl.forEach((i: any) => {
            i.sections.forEach((value: any) => {
              value.item2 = Array.from(new Set(value.item2));
              value.item3 = Array.from(new Set(value.item3));
              value.location_flag = i.location_alert_applicable_flg;
              value.time_cycle_flag = i.time_cycle_flg;

            });
          });

          this.formControl?.map((v: any, sectionIndex: any) => {
            v.sections?.map((r: any, sectionInIndex: any) => {
              this.start()
              r.item8?.find((b: any) => {
                if (b.source_type == r.item1) {
                  return r.item1 = b, this.getSourceNamesList(r.item1.source_type, sectionIndex, sectionInIndex, r.item2, r.item3)
                }
              })

            })
          })

          if (this.formControl !== '') {
            if (this.formControl.length) {
              this.data.removeAt(0);
              for (let i = 0; i < this.formControl.length; i++) {
                const item = this.formControl[i];
                const newItem = this.createItem();
                newItem.patchValue(item);

                const sectionsControl = newItem.get('sections') as FormArray;
                sectionsControl.clear();

                if (item.sections && item.sections.length > 0) {
                  for (let j = 0; j < item.sections.length; j++) {
                    const section = item.sections[j];
                    const newSection = this.createRow();
                    newSection.patchValue(section);
                    sectionsControl.push(newSection);
                  }
                } else {
                  // Push an empty section if no sections exist by default
                  // const newSection = this.createRow();
                  // sectionsControl.push(newSection);
                }

                this.data.push(newItem);
              }
            } else {
              // this.formControl has no items
            }
          }

          if (this.savedSubsItems.length <= 0) {
            this.stop(10).then(() => this.isLoading = false);
          }
        } else {

        }
      }, (error: any) => {
        console.log(error)
        this.stop(10).then(() => this.isLoading = false);
      }
    ))
    this.disableOnLoad = true

  }

  insertAlertData(obj: any) {
    let data: any = sessionStorage.getItem("employee_key")
    const employeeKeyObject = JSON.parse(data);
    const employeeValue = employeeKeyObject._value;
    let payload: any = {
      "configs": obj,
      "brand_key": this.currentBrand,
      "employee_key": employeeValue,
      "alert_type_key": this.alertTypeKey
    }
    if (obj.length == 0 && this.dataSet.length <= 0) {
      this.snackBar.open("Please add a alert to subscribe!", '', {
        horizontalPosition: this.middlePosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: ['warning-class']
      });
    }
    else {
      this.start()
      this.reqSubcription.push(this.alertService.insertAlerts(payload).subscribe(
        (resp: any) => {
          if (resp) {
            if (obj.length > 0 && this.dataSet.length > 0) {
              this.snackBar.open("Subscription updated successfully!", '', {
                horizontalPosition: this.middlePosition,
                verticalPosition: this.verticalPosition,
                duration: 3000,
                panelClass: ['success-class']
              });
              this.getKPIList()
              this.stop(10).then(() => this.isLoading = false);
            }
            else {
              // this.snackBar.open("Alerts subscribed successfully!", '', {
              this.snackBar.open("Subscription updated successfully!", '', {
                horizontalPosition: this.middlePosition,
                verticalPosition: this.verticalPosition,
                duration: 3000,
                panelClass: ['success-class']
              });
              this.getKPIList()
              this.stop(10).then(() => this.isLoading = false);

            }
          }
          else {
            this.snackBar.open("Subscription failed!", '', {
              horizontalPosition: this.middlePosition,
              verticalPosition: this.verticalPosition,
              duration: 3000,
              panelClass: ['error-class']
            });
            this.stop(10).then(() => this.isLoading = false);
          }
        }, (error: any) => {
          if (obj.length == 0 && this.dataSet.length > 0) {
            // this.snackBar.open("Alerts unsubscribed!", '', {
            this.snackBar.open("Subscription updated successfully!", '', {
              horizontalPosition: this.middlePosition,
              verticalPosition: this.verticalPosition,
              duration: 3000,
              panelClass: ['success-class']
            });
            this.getKPIList()
            this.stop(10).then(() => this.isLoading = false);
          }
          else {
            this.snackBar.open("Alerts subscription failed!", '', {
              horizontalPosition: this.middlePosition,
              verticalPosition: this.verticalPosition,
              duration: 3000,
              panelClass: ['error-class']
            });
            this.stop(10).then(() => this.isLoading = false);
          }

        }))
    }

  }

  alertDescription: any = ''
  getCurrentKPIName(item: any, idx: any) {
    this.currentSelection = item.value.dropdownName
    this.alertDescription = item.value.alert_description
  }

  removeRow(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }

  getControl(rowIndex: any, fieldName: string): FormControl {
    return this.rows.at(rowIndex).get(fieldName) as FormControl;
  }

  filteredOptions1(rowIndex: any) {
    const item1Value = this.getControl(rowIndex, 'item1').value.toLowerCase();
    return this.options1.filter(option => option.toLowerCase().includes(item1Value));
  }

  filteredOptions2(rowIndex: any) {
    const item3Value = this.getControl(rowIndex, 'item2').value.toLowerCase();
    return this.options2.filter(option => option.toLowerCase().includes(item3Value));
  }

  filteredOptions3(rowIndex: any) {
    const item3Value = this.getControl(rowIndex, 'item3').value.toLowerCase();
    return this.options3.filter(option => option.toLowerCase().includes(item3Value));
  }

  filteredOptions4(rowIndex: any) {
    const item4Value = this.getControl(rowIndex, 'item4').value.toLowerCase();
    return this.options4.filter(option => option.toLowerCase().includes(item4Value));
  }
  filteredOptions5(rowIndex: any) {
    const item5Value = this.getControl(rowIndex, 'item5').value.toLowerCase();
    return this.options5.filter(option => option.toLowerCase().includes(item5Value));
  }
  filteredOptions6(rowIndex: any) {
    const item6Value = this.getControl(rowIndex, 'item6').value.toLowerCase();
    return this.options5.filter(option => option.toLowerCase().includes(item6Value));
  }

  addRowWithData(data: any) {
    const rows = this.myForm.get('rows') as FormArray;
    const newRow = this.createRow();
    newRow.patchValue(data);
    rows.push(newRow);
  }

  // Reset Alert Data
  resetSettings() {
    this.getKPIList()
  }

  // Save Alert Data
  saveSettings() {
    let data: any = sessionStorage.getItem("employee_key")
    const employeeKeyObject = JSON.parse(data);
    const employeeValue = employeeKeyObject._value;
    let FinalData: any = []
    this.data.value.forEach((i: any) => {
      i?.sections?.forEach((j: any) => {
        let alertArray: any = []

        if (Array.isArray(j.item7) && j.item7?.length > 0) {
          j.item7?.map((z: any) => {
            if (z == 'Weekly') {
              alertArray.push('100102')
            }
            if (z == 'Monthly') {
              alertArray.push('100103')
            }
            if (z == 'Quarterly') {
              alertArray.push('100104')

            }
          })

        }


        if (j.item3.length > 0) {

          j?.item3.filter((v: any) => v?.sp_location_key)?.forEach((m: any) => {
            const obj = {
              "client_key": this.clientKey,
              "data_source_key": m?.data_source_dim_key,
              "alert_key": i.alert_key,
              "time_cycle_key": alertArray.length > 0 ? alertArray : null,
              "alert_type_key": this.alertTypeKey,
              "brand_key": this.currentBrand,
              "employee_key": employeeValue,
              "email_subscription_flg": j.item5.includes('Email') ? 1 : 0,
              "in_app_subscription_flg": j.item5.includes('InApp') ? 1 : 0,
              "sp_location_key": m?.sp_location_key ? m.sp_location_key : null
            }
            FinalData.push(obj)
          })
        }
        else {
          j?.item2.filter((v: any) => v?.source_key)?.forEach((m: any) => {
            const obj = {
              "client_key": this.clientKey,
              "data_source_key": m.source_key,
              "alert_key": i.alert_key,
              "alert_type_key": this.alertTypeKey,
              "time_cycle_key": alertArray.length > 0 ? alertArray : null,
              "brand_key": this.currentBrand,
              "employee_key": employeeValue,
              "email_subscription_flg": j.item5.includes('Email') ? 1 : 0,
              "in_app_subscription_flg": j.item5.includes('InApp') ? 1 : 0,
              "sp_location_key": null
            }
            FinalData.push(obj)
          })
        }

      })
    })

    // const mergedData = FinalData.reduce((acc: any, obj: any) => {
    //   const existingObject = acc.find((item: any) => item.alert_key === obj.alert_key && item.data_source_key === obj.data_source_key && item.sp_location_key == item.sp_location_key);

    //   if (existingObject) {
    //     // If an existing object is found, update the flags based on priority
    //     existingObject.email_subscription_flg = Math.max(existingObject.email_subscription_flg, obj.email_subscription_flg);
    //     existingObject.in_app_subscription_flg = Math.max(existingObject.in_app_subscription_flg, obj.in_app_subscription_flg);
    //   } else {
    //     // If no existing object is found, add the current object to the accumulator
    //     acc.push(obj);
    //   }

    //   return acc;
    // }, []);

    // let sendData = FinalData.filter((item: any, index: any, self: any) => (index === self.findIndex((obj: any) => obj['sp_location_key'] === item['sp_location_key'])))
    // sendData = sendData.filter((i: any) => i.sp_location_key)
    this.insertAlertData(FinalData)
  }

  // Parent FormControl
  get dataControls() {
    this.nullSourceName = false
    this.nullSourceType = false
    this.nulllocationValues = false
    this.nullAlerts = false
    this.nullTimeCycle = false

    this.data?.value.find((i: any) => {
      i.sections?.forEach((i: any) => {
        if (i.item1 == "") {
          this.nullSourceType = true
        }
        if (i.item2 == "" || i.item2.length == 0) {
          this.nullSourceName = true
        }
        if (i.item5 == "" || i.item5.length == 0) {
          this.nullAlerts = true
        }
        // if (i.location_flag == 'Y') {
        //   if (i.item4.length > 0) {
        //     if (i.item3.length == 0 || i.item3 == "") {
        //       this.nulllocationValues = true
        //     }
        //   }
        // }
        if (i.time_cycle_flag == 'Y') {

          if (i.item7?.length == 0 || i.item7 == "") {
            this.nullTimeCycle = true
          }
        }
      })
    })
    return this.formRange?.controls["data"] as FormArray;
  }



  // Form Control To Get Sections
  getSectionControls(index: number) {
    const dataControl = this.formRange.get('data') as FormArray;
    const itemControl = dataControl?.at(index) as FormGroup;
    return itemControl?.get('sections') as FormArray;
  }


  createItem(): FormGroup {
    return this.fB.group({
      dropdownName: ["Show By Name", Validators.required],
      alertDescription: '',
      alert_key: [""],
      checked: false,
      sections: this.fB.array([this.createRow()])
    });
  }

  createDynamicArray(): FormGroup {
    return this.fB.group({
      legendName: ["Legend Name", Validators.required],
      legendKeyName: ["Key Name", Validators.required],
      legendColor: ["", Validators.required]
    });
  }

  // Add New filter Name Dynamic
  addRow(item: any) {
    this.disableOnLoad = true
    this.indexPosition = this.dataControls.length
    this.data.push(this.createItem());
  }

  // Delete New filter Name Dynamic
  deleteRow(x: any) {
    this.disableOnLoad = false
    var controls = this.formRange.controls["data"] as FormArray;
    controls.removeAt(x);
    this.formControl?.setValue({
      controls: controls.value,
    })
  }

  // Add New Legends Dynamic
  addSection(item: any, idx: any, j: any) {
    this.disableOnLoad = false
    let locationApplicable: any = 'N'
    let timeCycleApplicable: any = 'N'
    const dataControl = this.formRange.controls["data"] as FormArray;
    const sectionsControl = dataControl.at(idx).get('sections') as FormArray;
    sectionsControl.push(this.createRow());
    let filterBlock = this.dataSet.filter((i: any) => i.alert_nm == this.currentSelection)

    this.data.value.find((i: any, sectionIndex: any) => {
      const sourceTypeSet = new Set<string>();
      const sourceName = new Set<string>();
      const locations = new Set<string>();

      for (const item of filterBlock) {

        // Source Type Filter
        if (item.source_type != "") {
          sourceTypeSet.add(item.source_type);
        }

        // Source Name Filter
        if (item.data_source_dim_key != '') {
          sourceName.add(item.data_source_dim_key);
        }

        // Location Filter
        if (item.sp_location_name != '') {
          locations.add(item.sp_location_name);
        }
      }

      this.uniqueSourceTypes = Array.from(sourceTypeSet);
      this.uniqueLocations = Array.from(locations);
      this.uniqueSourceNames = Array.from(sourceName);

      this.options1 = this.uniqueSourceTypes
      this.options2 = this.uniqueSourceNames
      this.options3 = this.uniqueLocations
    })

    this.kpiLists.find((i: any) => {
      if (i.alert_nm == this.currentSelection) {
        if (i.location_alert_applicable_flg == 'Y') {
          locationApplicable = i.location_alert_applicable_flg
        }
        if (i.time_cycle_flg) {
          timeCycleApplicable = i.time_cycle_flg
        }

      }
    })
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j + 1);
        sectionToUpdate.get('item4')?.patchValue(this.options3);
        sectionToUpdate.get('locationSearchList')?.patchValue(this.options3);
        sectionToUpdate.get('item6')?.patchValue(this.options2);
        sectionToUpdate.get('sourceNameSearchList')?.patchValue(this.options2);
        sectionToUpdate.get('item8')?.patchValue(this.sourceTypeList);
        sectionToUpdate.get('location_flag')?.patchValue(locationApplicable);
        sectionToUpdate.get('time_cycle_flag')?.patchValue(timeCycleApplicable);
      }
    })
  }

  getAddSubsSection(item: any, idx: any,) {
    this.disableOnLoad = false
    let locationApplicable: any = 'N'
    let timeCycleApplicable: any = 'N'
    const dataControl = this.formRange.controls["data"] as FormArray;
    const sectionsControl = dataControl.at(idx).get('sections') as FormArray;
    sectionsControl.push(this.createRow());

    let filterBlock = this.dataSet.filter((i: any) => i.alert_nm == item.value.dropdownName)
    this.kpiLists.find((i: any) => {
      if (i.alert_nm == this.currentSelection) {
        if (i.location_alert_applicable_flg == 'Y') {
          locationApplicable = i.location_alert_applicable_flg
        }
        if (i.time_cycle_flg) {
          timeCycleApplicable = i.time_cycle_flg
        }

      }
    })

    this.data.value.find((i: any, sectionIndex: any) => {
      const sourceTypeSet = new Set<string>();
      const sourceName = new Set<string>();
      const locations = new Set<string>();

      for (const item of filterBlock) {

        // Source Type Filter
        if (item.source_type != "") {
          sourceTypeSet.add(item.source_type);
        }

        // Source Name Filter
        if (item.data_source_dim_key != '') {
          sourceName.add(item.data_source_dim_key);
        }

        // Location Filter
        if (item.sp_location_name != '') {
          locations.add(item.sp_location_name);
        }
      }

      this.uniqueSourceTypes = Array.from(sourceTypeSet);
      this.uniqueLocations = Array.from(locations);
      this.uniqueSourceNames = Array.from(sourceName);

      this.options1 = this.uniqueSourceTypes
      this.options2 = this.uniqueSourceNames
      this.options3 = this.uniqueLocations
    })
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(0);
        sectionToUpdate.get('item4')?.patchValue(this.options3);
        sectionToUpdate.get('locationSearchList')?.patchValue(this.options3);
        sectionToUpdate.get('item6')?.patchValue(this.options2);
        sectionToUpdate.get('sourceNameSearchList')?.patchValue(this.options2);
        sectionToUpdate.get('item8')?.patchValue(this.sourceTypeList);
        sectionToUpdate.get('location_flag')?.patchValue(locationApplicable);
        sectionToUpdate.get('time_cycle_flag')?.patchValue(timeCycleApplicable);
      }
    })
  }

  isArrayAndEmpty(control: any): boolean {
    return Array.isArray(control?.value) && control?.value.length <= 0;
  }

  // Delete New Legends Dynamic
  deleteSection(i: any, j: any) {
    this.disableOnLoad = false
    var controls = this.formRange.controls["data"] as FormArray;
    const sectionsControl = controls.at(i).get('sections') as FormArray;
    sectionsControl.removeAt(j)
  }

  // Show By Filter Name Change
  onShowByNameChange(event: any) {
    const controls = this.formRange.controls['data'] as FormArray
    this.formControl?.setValue({
      controls: controls.value
    })
  }

  // Change Source Type Filter
  onSourceTypeChange(event: any, idx: number, j: any) {
    this.reqSubcription.push(this.alertService.getAllSourceNames(this.currentBrand, event.value.source_type).subscribe((resp: any) => {
      if (resp) {
        this.sourceNameList = resp
        this.previousData = []
        this.data.value.find((i: any, sectionIndex: any) => {
          if (sectionIndex == idx) {
            const data = this.formRange.get('data') as FormArray;
            const section = data.at(sectionIndex).get('sections') as FormArray;
            const sectionToUpdate = section.at(j);
            sectionToUpdate.get('item2')?.patchValue([])
            sectionToUpdate.get('item6')?.patchValue(this.sourceNameList);
            sectionToUpdate.get('sourceNameSearchList')?.patchValue(this.sourceNameList);
            sectionToUpdate.get('item4')?.patchValue([]);
            sectionToUpdate.get('locationSearchList')?.patchValue([]);
          }
        })
      }

    }))
  }

  currentEventOption: any = ''
  currentEventIndex: any = ''
  currentSecIndex: any = ''

  onSourceNameChange(event: any, idx: number, j: number) {
    this.disableOnLoad = false
    this.currentEventOption = event
    this.currentEventIndex = idx
    this.currentSecIndex = 'source'
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j);
        sectionToUpdate.get('item3')?.patchValue([]);

        if (this.currentEventSourceType == "all" && this.currentEventSourceSelected) {
          let pushItem: any = []
          pushItem.push('all')
          sectionToUpdate.get('item6')?.value.forEach((i: any) => {
            pushItem.push(i)
          })
          sectionToUpdate.get('item2')?.patchValue(pushItem);
          const outputString = sectionToUpdate.get('item2')?.value?.filter((item: any) => item && item.source_nm).map((item: any) => item.source_nm).join(',');
          this.reqSubcription.push(this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
            if (resp) {
              this.locationLists = resp
              sectionToUpdate.get('item4')?.patchValue(this.locationLists);
              sectionToUpdate.get('locationSearchList')?.patchValue(this.locationLists);
            }
          }))

        }

        else if (this.currentEventSourceType == "all" && !this.currentEventSourceSelected) {
          sectionToUpdate.get('item2')?.patchValue([]);
          const outputString = sectionToUpdate.get('item2')?.value?.filter((item: any) => item && item.source_nm).map((item: any) => item.source_nm).join(',');
          this.reqSubcription.push(this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
            if (resp) {
              this.locationLists = resp
              sectionToUpdate.get('item4')?.patchValue(this.locationLists);
              sectionToUpdate.get('locationSearchList')?.patchValue(this.locationLists);
            }
          }))
        }

        else if (this.currentEventSourceType != 'all') {
          const sortedList = event.source.value.filter((item: any) => item && item.source_nm)
          // if (sectionToUpdate.get('item6')?.value.length == sortedList.length) {
          //   let pushItem: any = []
          //   pushItem.push('all')
          //   sectionToUpdate.get('item6')?.value.forEach((i: any) => {
          //     pushItem.push(i)
          //   })
          //   sectionToUpdate.get('item2')?.patchValue(pushItem);
          // }
          // else {
          // this.previousData.push(sortedList)
          // sectionToUpdate.get('item2')?.patchValue(this.previousData);
          // }

          if (sectionToUpdate.get('sourceNameSearchList')?.value.length == sortedList.length) {
            let pushItem: any = []
            pushItem.push('all')
            sectionToUpdate.get('sourceNameSearchList')?.value.forEach((i: any) => {
              pushItem.push(i)
            })
            sectionToUpdate.get('item2')?.patchValue(pushItem);
            const outputString = sortedList.filter((item: any) => item && item.source_nm).map((item: any) => item?.source_nm).join(',');
            this.reqSubcription.push(this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
              if (resp) {
                this.locationLists = resp
                this.data.value.find((i: any, sectionIndex: any) => {
                  if (sectionIndex == idx) {
                    const data = this.formRange.get('data') as FormArray;
                    const section = data.at(sectionIndex).get('sections') as FormArray;
                    const sectionToUpdate = section.at(j);
                    sectionToUpdate.get('item4')?.patchValue(this.locationLists);
                    sectionToUpdate.get('locationSearchList')?.patchValue(this.locationLists);
                  }
                })
              }
            }))
          }
          else {
            if (this.previousData) {
              this.previousData = this.previousData?.filter((i: any) => i != 'all')
            }
            let NewString: any = []
            NewString = this.previousData ? this.previousData : []
            event.source.value.filter((i: any) => i?.source_nm).forEach((j: any) => {
              NewString.push(j)
            })
            if (this.previousData.length > 0) {
              sectionToUpdate.get('item2')?.patchValue(this.previousData);
              this.previousData = NewString
            } else {
              sectionToUpdate.get('item2')?.patchValue(NewString);
              this.previousData = NewString
            }
            if (this.previousData) {
              this.previousData = this.removeDuplicatesFn(this.previousData)
            }
            const outputString = NewString.filter((item: any) => item && item.source_nm).map((item: any) => item?.source_nm).join(',');
            this.reqSubcription.push(this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
              if (resp) {
                this.locationLists = resp
                this.data.value.find((i: any, sectionIndex: any) => {
                  if (sectionIndex == idx) {
                    const data = this.formRange.get('data') as FormArray;
                    const section = data.at(sectionIndex).get('sections') as FormArray;
                    const sectionToUpdate = section.at(j);
                    sectionToUpdate.get('item4')?.patchValue(this.locationLists);
                    sectionToUpdate.get('locationSearchList')?.patchValue(this.locationLists);
                  }
                })
              }
            }))
          }

        }
      }
    })
  }

  onLocationchange(event: any, idx: any, j: any) {
    this.disableOnLoad = false
    this.currentEventOption = event
    this.currentEventIndex = idx
    this.currentSecIndex = 'location'
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j);

        if (this.currentEventLocation == "all" && this.currentEventLocationSelected) {
          let pushItem: any = []
          pushItem.push('all')
          sectionToUpdate.get('item4')?.value.forEach((i: any) => {
            pushItem.push(i)
          })
          sectionToUpdate.get('item3')?.patchValue(pushItem);
        }

        else if (this.currentEventLocation == "all" && !this.currentEventLocationSelected) {
          sectionToUpdate.get('item3')?.patchValue([]);
        }

        else if (this.currentEventLocation != 'all') {
          const sortedList = event.source.value.filter((item: any) => item && item.sp_location_name)
          // if (sectionToUpdate.get('item4')?.value.length == sortedList.length) {
          //   let pushItem: any = []
          //   pushItem.push('all')
          //   sectionToUpdate.get('item4')?.value.forEach((i: any) => {
          //     pushItem.push(i)
          //   })
          //   sectionToUpdate.get('item3')?.patchValue(pushItem);
          // }
          // else {
          //   // sectionToUpdate.get('item3')?.patchValue(sortedList);
          // }

          if (sectionToUpdate.get('locationSearchList')?.value.length == sortedList.length) {
            let pushItem: any = []
            pushItem.push('all')
            sectionToUpdate.get('locationSearchList')?.value.forEach((i: any) => {
              pushItem.push(i)
            })
            sectionToUpdate.get('item3')?.patchValue(pushItem);
          }

          else {
            let NewString: any = []
            NewString = this.previousData ? this.previousData : []
            event.source.value.filter((i: any) => i?.sp_location_name).forEach((j: any) => {
              NewString.push(j)
            })
            this.previousData = this.previousData?.filter((i: any) => i != 'all')
            if (this.previousData.length > 0) {
              sectionToUpdate.get('item3')?.patchValue(this.previousData);
            } else {
              sectionToUpdate.get('item3')?.patchValue(NewString);
              this.previousData = NewString
            }
            this.previousData = this.removeDuplicatesFn(this.previousData)
          }
        }
      }
    })
  }

  getCurrentSourceNameSection(event: any, idx: any, j: any) {
    const selectedSourceName = event.value;
  }

  getCurrentLocationNameSection(event: any, idx: any, j: any) {
    let sortedArray: any = []
    event.value.item2.map((source: any) => {
      this.dataSet.forEach((i: any) => {
        if (source == i.data_source_dim_key && this.currentSelection == i.alert_nm) {
          sortedArray.push(i)
        }
      })

    })

    const locations = new Set<string>();

    for (const item of sortedArray) {
      // Location Filter
      if (item.sp_location_name != '') {
        locations.add(item.sp_location_name);
      }
    }

    let uniqueLocations = Array.from(locations);

    this.options3 = uniqueLocations


    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j);
        sectionToUpdate.get('item4')?.patchValue(this.options3);
        sectionToUpdate.get('locationSearchList')?.patchValue(this.options3);
      }
    })

  }

  removeAllAlerts(item: any, idx: any) {
    let count = 0
    this.disableOnLoad = false
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        section.clear()
      }
    })

    this.data.value.find((i: any) => {
      if (i.sections.length > 0) {
        count = count + 1
      }
      else {

      }

      if (count > 0) {
        this.disableOnLoad = false
      } else {
        if (this.dataSet.length <= 0) {
          this.disableOnLoad = true
        } else {
          this.disableOnLoad = false
        }
      }

    })

  }

  onTypeChange() {
    this.disableOnLoad = false
  }

  currentEventSourceType: any = ''
  currentEventSourceSelected: boolean = false
  currentEventLocation: any = ''
  currentEventLocationSelected: boolean = false

  getSelectAllChange(event: any, idx: number, j: number, type: any) {
    if (type == 'sourceName') {
      this.currentEventSourceType = event.source.value
      this.currentEventSourceSelected = event.source._selected
      if (!event.source._selected) {
        this.previousData = this.previousData?.filter((i: any) => i.source_nm != event.source.value.source_nm)
        this.previousData = this.removeDuplicatesFn(this.previousData)
        // this.data.value.find((i: any, sectionIndex: any) => {
        //   if (sectionIndex == idx) {
        //     const data = this.formRange.get('data') as FormArray;
        //     const section = data.at(sectionIndex).get('sections') as FormArray;
        //     const sectionToUpdate = section.at(j);
        //     sectionToUpdate.get('item2')?.patchValue([]);
        //   }
        // })
      } else {
        this.previousData?.push(event.source.value)
        this.previousData = this.previousData?.filter((i: any) => i?.source_nm)
        this.previousData = this.removeDuplicatesFn(this.previousData)
        // this.data.value.find((i: any, sectionIndex: any) => {
        //   if (sectionIndex == idx) {
        //     const data = this.formRange.get('data') as FormArray;
        //     const section = data.at(sectionIndex).get('sections') as FormArray;
        //     const sectionToUpdate = section.at(j);
        //     sectionToUpdate.get('item2')?.patchValue([]);
        //   }
        // })
      }

    }
    else if (type == 'location') {
      this.currentEventLocation = event.source.value
      this.currentEventLocationSelected = event.source._selected
      if (!event.source._selected) {
        this.previousData = this.previousData?.filter((i: any) => i.sp_location_name != event.source.value.sp_location_name)
        this.previousData = this.removeDuplicatesFn(this.previousData)
        // this.data.value.find((i: any, sectionIndex: any) => {
        //   if (sectionIndex == idx) {
        //     const data = this.formRange.get('data') as FormArray;
        //     const section = data.at(sectionIndex).get('sections') as FormArray;
        //     const sectionToUpdate = section.at(j);
        //     sectionToUpdate.get('item2')?.patchValue([]);
        //   }
        // })
      } else {
        this.previousData.push(event.source.value)
        this.previousData = this.previousData?.filter((i: any) => i?.sp_location_name)
        this.previousData = this.removeDuplicatesFn(this.previousData)
      }
    }
    // if (type == 'sourceName') {
    //   console.log(event, idx, j, type)
    //   if (event.source.value == 'all' && event.source._selected) {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item2')?.patchValue(sectionToUpdate.get('item6')?.value);

    //         const outputString = sectionToUpdate.get('item2')?.value?.map((item: any) => item.source_nm).join(',');
    //         this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
    //           if (resp) {
    //             this.locationLists = resp
    //             sectionToUpdate.get('item4')?.patchValue(this.locationLists);
    //           }
    //         })
    //       }
    //     })
    //   }
    //   else {
    //     console.log("else")
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item2')?.patchValue([]);

    //         const outputString = sectionToUpdate.get('item2')?.value?.map((item: any) => item.source_nm).join(',');
    //         this.alertService.getLocationsDataList(this.currentBrand, outputString).subscribe((resp: any) => {
    //           if (resp) {
    //             this.locationLists = resp
    //             sectionToUpdate.get('item4')?.patchValue(this.locationLists);
    //           }
    //         })
    //       }
    //     })
    //   }

    // }

    // else if (type == 'location') {
    //   if (event.value[0] == 'all') {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item3')?.patchValue(sectionToUpdate.get('item4')?.value);
    //       }
    //     })
    //   }
    //   else {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item3')?.patchValue([]);
    //       }
    //     })
    //   }

    // }

    // else if(type == 'alert')
    // {
    //   if (event.value[0] == 'all') {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item5')?.patchValue(["InApp","Email"]);
    //       }
    //     })
    //   }
    //   else {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item5')?.patchValue([]);
    //       }
    //     })
    //   }

    // }
    // else if(type == 'timeCycle')
    // {
    //   if (event.value[0] == 'all') {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item7')?.patchValue(["Weekly", "Monthly", "Quarterly"]);
    //       }
    //     })
    //   }
    //   else {
    //     this.data.value.find((i: any, sectionIndex: any) => {
    //       if (sectionIndex == idx) {
    //         const data = this.formRange.get('data') as FormArray;
    //         const section = data.at(sectionIndex).get('sections') as FormArray;
    //         const sectionToUpdate = section.at(j);
    //         sectionToUpdate.get('item7')?.patchValue([]);
    //       }
    //     })
    //   }

    // }


  }

  openedChangeSource(e: any, idx: any, j: any, type: any) {
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j);
        this.previousData = sectionToUpdate.get('item2')?.value
      }
    })

  }

  openedChangeLocation(e: any, idx: any, j: any, type: any) {
    this.data.value.find((i: any, sectionIndex: any) => {
      if (sectionIndex == idx) {
        const data = this.formRange.get('data') as FormArray;
        const section = data.at(sectionIndex).get('sections') as FormArray;
        const sectionToUpdate = section.at(j);
        this.previousData = sectionToUpdate.get('item3')?.value
      }
    })
  }

  removeDuplicatesFn(inputArray: any[]): any[] {
    return inputArray.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }


  searchControl = new FormControl();
  previousData: any = []
  handleSearch(idx: number, j: number, type: any): void {
    let filldata: any = []
    if (type == 'location') {

      this.data.value.find((i: any, sectionIndex: any) => {
        if (sectionIndex == idx) {
          const data = this.formRange.get('data') as FormArray;
          const section = data.at(sectionIndex).get('sections') as FormArray;
          const sectionToUpdate = section.at(j);
          if(sectionToUpdate?.get('item3')?.value.length > 0) {
            sectionToUpdate?.get('item3')?.value.map((j: any) => {
              this.previousData.push(j)
            })
          }
        }
      })
      if(this.previousData.length > 0)
      {
        filldata = this.removeDuplicatesFn(this.previousData)
      }
      const searchTerm = this.searchControl.value.toLowerCase();

      // Assuming your data structure has an array named 'item4' containing location options
      const allLocations = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('locationSearchList')?.value;

      if (allLocations) {
        const filteredLocations = allLocations.filter((option: any) =>
          option.sp_location_name.toLowerCase().includes(searchTerm)
        );

        // Update the filtered locations in your form control
        const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
        if (filldata.length == filteredLocations.length) {

          sectionToUpdate?.get('item4')?.patchValue(filteredLocations);
          sectionToUpdate?.get('item3')?.patchValue(filldata)
          // this.previousData = filldata
        } else {
          filldata = filldata.filter((i: any) => i.sp_location_name)
          sectionToUpdate?.get('item4')?.patchValue(filteredLocations);
          sectionToUpdate?.get('item3')?.patchValue(filldata)
          this.previousData = filldata
          if (filldata.length == sectionToUpdate?.get('locationSearchList')?.value.length) {
            let item: any = []
            item = this.previousData
            item.unshift('all')
            sectionToUpdate?.get('item3')?.patchValue(item)
          }
        }
      }
    }

    if (type == 'source') {

      this.data.value.find((i: any, sectionIndex: any) => {
        if (sectionIndex == idx) {
          const data = this.formRange.get('data') as FormArray;
          const section = data.at(sectionIndex).get('sections') as FormArray;
          const sectionToUpdate = section.at(j);
          if(sectionToUpdate?.get('item2')?.value.length > 0) {
            sectionToUpdate?.get('item2')?.value.map((j: any) => {
              this.previousData.push(j)
            })
          }
        }
      })

      if(this.previousData.length > 0)
      {
        filldata = this.removeDuplicatesFn(this.previousData)
      }


      const searchTerm = this.searchControl.value.toLowerCase();

      // Assuming your data structure has an array named 'item6' containing location options
      const allLocations = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('sourceNameSearchList')?.value;

      if (allLocations) {
        const filteredLocations = allLocations.filter((option: any) =>
          option.source_nm.toLowerCase().includes(searchTerm)
        );

        // Update the filtered locations in your form control
        const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
        if (filldata.length == filteredLocations.length) {
          sectionToUpdate?.get('item6')?.patchValue(filteredLocations);
          sectionToUpdate?.get('item2')?.patchValue(filldata)
          // this.previousData = filldata
        } else {
          filldata = filldata.filter((i: any) => i.source_key)
          sectionToUpdate?.get('item6')?.patchValue(filteredLocations);
          sectionToUpdate?.get('item2')?.patchValue(filldata)
          this.previousData = filldata
          if (filldata.length == sectionToUpdate?.get('sourceNameSearchList')?.value.length) {
            let item: any = []
            item = this.previousData
            item.unshift('all')
            sectionToUpdate?.get('item2')?.patchValue(item)
          }
        }

      }

    }

  }


  handleKeydown(event: KeyboardEvent, idx: number, j: number, type: any): void {
    if (type == 'location') {
      if (event.key === 'Backspace' && !this.searchControl.value) {
        const searchTerm = this.searchControl.value.toLowerCase();
        const allLocations = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('locationSearchList')?.value;

        if (allLocations) {
          const filteredLocations = allLocations.filter((option: any) =>
            option.sp_location_name.toLowerCase().includes(searchTerm)
          );
          const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
          sectionToUpdate?.get('item4')?.patchValue(filteredLocations);
        }
      }
    }

    if (type == 'source') {

      if (event.key === 'Backspace' && !this.searchControl.value) {
        const searchTerm = this.searchControl.value.toLowerCase();
        const allLocations = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('sourceNameSearchList')?.value;

        if (allLocations) {
          const filteredLocations = allLocations.filter((option: any) =>
            option.source_nm.toLowerCase().includes(searchTerm)
          );
          const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
          sectionToUpdate?.get('item6')?.patchValue(filteredLocations);
        }
      }

    }
  }

  onSelectClosed(idx: number, j: number, type: string): void {
    this.previousData = this.removeDuplicatesFn(this.previousData)

    // this.data.value.find((i: any, sectionIndex: any) => {
    //   if (sectionIndex == idx) {
    //     const data = this.formRange.get('data') as FormArray;
    //     const section = data.at(sectionIndex).get('sections') as FormArray;
    //     const sectionToUpdate = section.at(j);
    //     if (type == 'source') {
    //       sectionToUpdate.get('item2')?.patchValue(this.previousData);
    //     }

    //     else if (type == 'location') {

    //       sectionToUpdate.get('item3')?.patchValue(this.previousData);
    //     }
    //   }
    // })
    this.clearSearch(idx, j, type);
    // this.previousData = []
  }


  clearSearch(idx: number, j: number, type: any): void {
    if (type == 'location') {
      console.log(this.previousData, 'data')
      const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
      const allLocations = sectionToUpdate?.get('locationSearchList')?.value;
      sectionToUpdate?.get('item3')?.patchValue(this.previousData);
      sectionToUpdate?.get('item4')?.patchValue(allLocations);
    }

    if (type == 'source') {
      console.log(this.previousData, 'data')
      const allLocations = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('sourceNameSearchList')?.value;
      const sectionToUpdate = this.formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString());
      sectionToUpdate?.get('item2')?.patchValue(this.previousData);
      sectionToUpdate?.get('item6')?.patchValue(allLocations);

    }
    this.searchControl.reset();
    // this.previousData = []
  }

  @ViewChild("deleteDialog", { static: true }) deleteDialog!: ElementRef;
  @ViewChild("saveDialog", { static: true }) saveDialog!: ElementRef;
  @ViewChild("cancelDialog", { static: true }) cancelDialog!: ElementRef;
  selectedItem: any = ''
  selectedIndex: any = 0
  openDialog(type: any, item?: any, idx?: any) {
    if (type == 'delete') {
      // this.dialogService.openDialog(this.deleteDialog, '100')
      this.selectedItem = item
      this.selectedIndex = idx
      this.removeDelete()
    }
    else if (type == 'save') {
      // this.dialogService.openDialog(this.saveDialog, '100')
      this.saveSettings()
    }
    else if (type == 'cancel') {
      // this.dialogService.openDialog(this.cancelDialog, '100')
      this.resetSettings()
    }
  }

  removeDelete() {
    console.log("true")
    this.removeAllAlerts(this.selectedItem, this.selectedIndex)
    this.dialogService.closeAll()
  }

  cancelDialogPopup() {
    this.dialogService.closeAll()

  }

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger.closeMenu();
  }

  getInfoDetails(item: any, idx: any) {
    this.currentSelection = item.value.dropdownName
    this.alertDescription = item.value.alertDescription
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }

}

