import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-h-bar-chart",
  templateUrl: "./h-bar-chart.component.html",
  styleUrls: ["./h-bar-chart.component.scss"],
})
export class HBarChartComponent implements OnInit, OnDestroy {
  static key = 100701;
  
  @Input("item") item: any;
  @Input("height") height: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Input("dynamic") dynamic!: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("HBarCharDiv", { static: true }) HBarCharDiv!: ElementRef;
  config: any;
  data: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  sourceName: any;
  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute
  ) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
  }
  ngOnInit(): void {

     

    let height = this.item?.config?.['height_configuration']?.value
    if(height != 'fixed' || height != 'Fixed') {
      const observer = new ResizeObserver((e: any) => {
        this.onSizeChange.emit(e[0].contentRect.height);
      });
      observer.observe(this.HBarCharDiv.nativeElement);
    }
    this.loadlineConfig();
    if(this.dynamic==true){
      this.loadApiData()
    }
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData(sourceName?: string) {
    let api = this.item.config["list_of_api"] || {};
    if((this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {

      // API Payload
      let obj:any = {"api_key" : api}

      this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
        if(res!=null) {
          this.isDataLoaded = true;
          this.data = res == null ? [] : res;
        }
      }, (error: any) => {
        this.data = [];
        console.log(error)
      }));
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
