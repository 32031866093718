import { NgModule, Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DefaultLayoutComponent } from "../layout/default-layout/default-layout.component";
import { DashboardComponent } from "./dashboard.component";
import { PagesComponent } from "./pages/pages.component";
import { WorkDisplayComponent } from "./work-display-component/work-display.component";
const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
  },
  {
    path: ":module",
    component: DashboardComponent,
  },

  // Default Pages
  {
    path: ":module/page",
    pathMatch: 'full',
    component: PagesComponent,
  },
  {
    path: ":module/:cardName",
    pathMatch: 'full',
    component: PagesComponent,
  },
  {
    path: ":module/:cardName/:FRM",
    pathMatch: 'full',
    component: PagesComponent,
  },

  // Alert Page
  {
    path: ":module/page/alert/config",
    pathMatch: 'full',
    component: PagesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
