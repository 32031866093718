<span (click)="clicktoNavigation(option, selectedColumn)" *ngIf="selectedColumn == alertsColumn ? true : option[selectedColumn]!=null && this.findData" style="display: flex;justify-content: center;align-items: center;">
    <span *ngIf="this.findData.isIconisPrefix" style="margin: 2px 10px 0 0;">
        <mat-icon style="width: 18px;height: 18px;" [svgIcon]="this.findData.isIconisPrefixIconName" [style.color]="this.findData.isIconisPrefixIconColor"></mat-icon>
    </span>
    <span [style.color]="findData.isTextColorCondition ? findData.isTextColor : '#000'">
        <span>{{findData.isPrefix ? findData.isPrefix : '' }}</span>
        <span *ngIf="selectedColumn == alertsColumn">
            <span *ngFor="let a of alertsData" style="padding: 6px;">
                <mat-icon (click)="matIconClick($event,a)" [matBadge]="a.Count == 1 ? '' : a.Count" matBadgeColor="warn" style="width: 24px; height: 24px;cursor: pointer;" [svgIcon]="a.icon" [style.color]="a.color"></mat-icon>
            </span>
        </span>
        <span *ngIf="option[selectedColumn] == null" style="padding: 6px;color: #bbb;"> {{ selectedColumn == alertsColumn ? option[selectedColumn] == null ? 'No Alerts' : '' : '' }}</span>
        <span> {{ selectedColumn != alertsColumn ? option[selectedColumn] : '' }}</span>
        <span>{{findData.issuffix ? findData.issuffix : '' }}</span>
    </span>
    
    <span *ngIf="this.findData.isIconisSuffix" style="padding: 15px;margin: 2px 0px 0 10px;">
        <mat-icon style="width: 18px;height: 18px;" [svgIcon]="this.findData.isIconisSuffixIconName" [style.color]="this.findData.isIconisSuffixIconColor"></mat-icon>
    </span>
</span>

<!-- <button >Hover me</button> -->

<!-- <div id="d3LineTooltip"  style="position: fixed; z-index: 99999;">
    <app-pipeline-update-popup *ngIf="toolTipss" [NetworkChartTooltip]="false"></app-pipeline-update-popup>
</div> -->

<ng-template #patientJourney>
    <div class="example-dialog-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <app-pipeline-update-popup [NetworkChartTooltip]="false"></app-pipeline-update-popup>
    </div>
</ng-template>