import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertDialogService } from "./alert-dialog.service";
import { AlertPopupComponent } from "../components/shared/charts/alert-popup/alert-popup.component";
import { CustomSnackbarComponent } from "../components/shared/custom-snackbar/custom-snackbar.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  animal!: string;
  name!: string;
  dialogRef: any;
  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar,public alertPopup: AlertDialogService) {}

  public openDialog(content: any, width?: any, val?: any, height?:any): void {
    if (width == null) width = "80%";
    this.dialogRef = this.dialog.open(content, {
      width: width || "80%",
      data: val || null,
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
  public openAlertDialog(message: any, width?: any, height?:any): void {
    if (width == null) width = "35%";
    this.dialogRef = this.dialog.open(AlertPopupComponent, {
      width: width || "50%",
      data: message || null,
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
  public closeModal() {
    this.dialogRef.close();
  }
  public closeAll() {
    this.dialogRef.close();
  }

  public openSnackBar(message: string, action: string) {
    this.openAlertDialog(message)
    // alert(message);
    // this._snackBar.open( " ⓘ " + message   , action, {
    //   horizontalPosition: 'center',
    //   verticalPosition: 'top',
    //   duration: 5000,
    //   panelClass:['warning-class']
    // });
  }

  customSnackBar(report_type: any, color: any, message:any) {
    // this._snackBar.openFromComponent(CustomSnackbarComponent, {
    //   duration: report_type == 'error' ? 6000 : 3000,
    //   data: {
    //     action: report_type,
    //     message: message
    //   },
    //   panelClass: ['api-' + color + '-snackbar']
    // })
  }
  public SnackBar(message: string, action: string) {
    // alert(message);
    this._snackBar.open(  message   , action, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass:['warning-class']
    });
  }
}
