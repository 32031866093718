import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RouterHandlerService } from 'src/app/services/router-handler.service';

@Component({
  selector: 'custom-header',
  template: `
  <div>
    <div style="text-align: left;" >
      <span (click)="getValue(params)" (touchstart)="getValue(params)" [matTooltip]="params.displayName"   matTooltipPosition="above" style="display: block; white-space: nowrap;width: 13em;overflow: hidden; text-overflow: ellipsis;">
        {{ params.displayName }} 
        
        <span style="margin-left: 2px;">
          <svg  *ngIf="!source" viewBox="304.108 149.795 14.365 13.76" width="14.365" height="13.76">
            <defs>
              <clipPath id="clip0_3828_6435">
                <rect width="6" height="6" fill="white" transform="translate(0 6) rotate(-90)"></rect>
              </clipPath>
            </defs>
            <g clip-path="url(#clip0_3828_6435)" transform="matrix(2.622176, 0, 0, 2.293355, 303.423619, 149.795229)" style="">
              <path d="M0.270804 0.0806081C0.250457 0.129387 0.261939 0.185478 0.298978 0.222786L3.07645 3.00001L0.299248 5.77721C0.261939 5.81452 0.250726 5.87113 0.270817 5.91939C0.290896 5.96817 0.338635 6 0.391326 6L2.73915 6C2.77384 6 2.80698 5.98617 2.8315 5.96191L5.70107 3.09234C5.72637 3.06677 5.73916 3.03339 5.73916 2.99999C5.73916 2.9666 5.72638 2.93321 5.70107 2.90765L2.8315 0.0380782C2.80698 0.0138173 2.77384 -1.26899e-05 2.73915 -1.26884e-05L0.391326 -1.25857e-05C0.338634 -3.55172e-07 0.290895 0.0318296 0.270804 0.0806081Z" fill="#1363DF"></path>
            </g>
          </svg>
        </span>
      </span>
    </div>
  </div>
  `,
})
export class CustomHeader implements IHeaderAngularComp {
  public params: any;
  item: any;
  config: any;
  moduleName: any;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  sourceName: any;
  source: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private routerHandlerService: RouterHandlerService,
    private navigationService: NavigationService,
  ) {

    this.route.params.subscribe((p: any) => {

      this.moduleName = p['module'] || '';
      this.source = p["cardName"] || '';

      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);

        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
      });
    });

  }

  agInit(params: IHeaderParams): void {
    // debugger
    this.params = params;
    this.item = this.params?.item;
    this.config = this.item?.['config'];
  }

  refresh(params: IHeaderParams) {
    return false;
  }

  getValue(val: any) {

    if (this.source) {
      return
    }
    let myData = { 'source_nm': val.displayName.toString().toLowerCase() }

    // Set Navigation
    const config = this.config?.nav_configuration.controls?.[0];
    let nav = {
      dbJSON: config?.dbJSON,
      s3JSON: config?.s3JSON,
      pageName: config?.pageName,
      isDashboardLevel: config?.isDashboardLevel
    }
    sessionStorage.setItem("navDetailInfo", JSON.stringify(nav));
    this.routerHandlerService.storingPayloadAndFilterForGrid(config, myData, '');
    this.sourceName = myData['source_nm'];

    let query = {
      tab_id: 0,
      cl_key: this.clientKey,
      md_key: this.moduleKey,
      ds_key: this.dashboardKey
    }

    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${this.sourceName}`, query);

    // this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/${this.sourceName}`]);
  }
}
