<div class="widget-container" #StatusCodeDiv>
  <!-- Headings -->
  <main >
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
      <div>

        <icon-chart 
          [headerConfig]="item.config.widget_header_config" 
          [fullscreen]="isFullscreen"
          [data]="apiDataStatus" 
          [iconList]="iconList" 
          [expand]="fs" 
          [pageKey]="pageKey" 
          [item]="widgets"
          [apiKey]="100180" 
          [heading]="heading" 
          chartName="statusCodesUI" 
        ></icon-chart>

      </div>

        <br>
        <!-- Grid -->
        <div>
          <ag-grid-angular 
            #statusGrid 
            style="width: 100%;" 
            class="ag-theme-alpine status_codes"
            [columnDefs]="columnDefs" 
            [defaultColDef]="defaultColDef" 
            [autoGroupColumnDef]="autoGroupColumnDef"
            [pivotHeaderHeight]="0" 
            [rowData]="rowData" 
            (gridReady)="onGridReady($event)" 
            [rowHeight]="35"
            (firstDataRendered)="onFirstDataRendered($event)"
          ></ag-grid-angular>
        </div>
    </article>
  </main>
</div>