import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as d3 from "d3";
import { FilterService } from 'src/app/services/filter.service';
import { ControlChartComponent } from '../control-chart/control-chart.component';
import { DialogService } from 'src/app/services/dialog.service';
import { RouterHandlerService } from 'src/app/services/router-handler.service';
import { NavigationService } from 'src/app/services/navigation.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-kpi-breakdown-heatmap-chart',
  templateUrl: './kpi-breakdown-heatmap-chart.component.html',
  styleUrls: ['./kpi-breakdown-heatmap-chart.component.scss']
})
export class KpiBreakdownHeatmapComponent implements OnInit, OnChanges {

  @ViewChild('kpiBreakdownContainer', { static: true }) kpiBreakdownContainer!: ElementRef
  @Input('item') item: any
  @Input('type') type: any
  @Input('data') data: any
  @Input('columnDetails') columnDetails: any
  @Input('config') config: any
  @Input('heading') heading: string = ''
  @Input('pageKey') pageKey: any
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  KpiBreakdownHeatmapChartData: any=[];
  props: any;
  divId: any = "kpiBreakdownChartDiv";
  iconList: any
  noData:boolean=false
  window=window
  // chartHeight: number = 500
  renderLinePopup: boolean = false
  isActive = false;
  dataTurn: any
  mytooltipData: any
  isTooltip: boolean = false
  initiateChart: boolean = false
  clientKey: any
  moduleKey: any
  dashboardKey: any
  compare: any;
  moduleName:any;
  decryptedParams:any = {};
  queryParams:any;

  constructor(
    public dialogService:DialogService, 
    public filterService: FilterService, 
    private currency: CurrencyPipe, 
    private route: ActivatedRoute, 
    private router: Router,
    private routerHandlerService: RouterHandlerService,
    private navigationService: NavigationService

  ) {
    
    this.route.params.subscribe((p: any) => {
      this.queryParams = p;
      this.moduleName = p['module'] || '';
      
      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });
    
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }
  closeFullscreen(): void {
    this.props.chartHeight = 500
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }


//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  private showTooltip(myX: any, myY: any, myData: any, chartWidth: any, chartHeight: any): void {
    if(this.type=='chart')return
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
    this.mytooltipData = myData
    this.isTooltip = true
    if (this.dataTurn < 250) {
      d3.select("#d3HBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY - 40) + 'px')
        .style('right', (this.dataTurn + 48) + 'px')
        .style('left', 'unset')
    } else if (this.dataTurn > 250) {

      d3.select("#d3HBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY - 40) + 'px')
        .style('left', (myX + 40) + 'px')
        .style('right', 'unset')
    }
    if (this.isFullscreen == true) {
      d3.select("#d3HBarTooltip")
        .style('top', myY - 50 + 'px')
    } else { }


  }

  private hideTooltip(): void {
    this.isTooltip = false
    d3.select("#d3HBarTooltip")
      .style('visibility', 'hidden')
  }

  public compareClick(myType?: any, myData?: any, currentSelections?: any): void {
    // debugger
    // if (this.type != 'chart') {
    // sessionStorage.setItem("currentSelections", JSON.stringify(this.props.currentSelections));
    //   this.props.currentSelections = currentSelections
    // }
    // this.lookBackUsingDateFilter(this.item?.config?.db_pages_two)

    if(myType == "route" && this.props.currentSelections.length <= 1){
      this.dialogService.openSnackBar(" Select a minimum of 2 KPIs and a maximum of 4 KPIs.","")
      return
    }
    if (myType == "route" && this.props.currentSelections.length > 0) {

      let myData: any = {};
      myData['source_nm'] = []
      myData[this.props.kpino] = []

      this.props.currentSelections.forEach((val: any) => {
        let fndData = this.KpiBreakdownHeatmapChartData.find((o:any)=> o[this.props.columnVar]?.toString().toLowerCase() == val[this.props.columnVar]?.toString().toLowerCase())
        myData[this.props.kpino].push(fndData[this.props.kpino])
        myData['source_nm'].push(val[this.props.secondLevelVar])
      });

      myData['source_nm'] = [...new Set(myData['source_nm'])]
      myData[this.props.kpino] = [...new Set(myData[this.props.kpino])]

      sessionStorage.setItem("currentSelections", JSON.stringify(this.props.currentSelections));

      let configPages = this.item?.['config'];
      if(configPages?.nav_configuration) {
        const config0 = configPages?.nav_configuration?.['controls'].find((o:any)=> o.chartType.includes('Multi'));
        
        if (this.type !=undefined&&this.type != 'chart') {
          this.navigationImplementation(config0, 'multi');
          sessionStorage.setItem('multiChartControlItemConfig', JSON.stringify(config0))
          this.routerHandlerService.storingPayloadAndFilterForGrid(config0, myData, '');
        }
      } else {
        let storeConfigred:any = sessionStorage.getItem('multiChartControlItemConfig');
        storeConfigred = JSON.parse(storeConfigred);
        this.routerHandlerService.storingPayloadAndFilterForGrid(storeConfigred, myData, '');
      }
      // sessionStorage.setItem("selectedKPIcontrol", JSON.stringify(myData));
      // this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/comparison/multi`], { queryParams: myData, queryParamsHandling: 'merge', skipLocationChange: false });

      if(this.type == 'chart'){
        
        // this.filterService.duplicateParameter
        let updatesObj: any = Object.entries(myData).map(([key, value]) => ({ key, value }));
        if(updatesObj.length) {
          for (let idx = 0; idx < updatesObj.length; idx++) {
            let whole: any = Object.entries(this.filterService.backupAllSingleAndMulti).map(([key, value]) => ({ key, value }));
            if(whole.length) {
              let fndData = whole.find((fnd:any)=> fnd.key == updatesObj[idx].key)
              if(fndData) {
                let flrData = fndData.value.filter((f:any) => updatesObj[idx].value.find((o:any)=> o == f[fndData.key]))
                this.filterService.previousModel[updatesObj[idx].key] = flrData.length ? flrData : this.filterService.previousModel[updatesObj[idx].key]

                if(updatesObj[idx].key == 'source_nm') {
                  this.filterService.duplicateParameter['source_key'] = ""
                  flrData.forEach((lo:any)=> {
                    this.filterService.duplicateParameter['source_key'] += lo.source_key + ','
                  })

                  this.filterService.duplicateParameter['source_key'].slice(0,
                  this.filterService.duplicateParameter['source_key'].lastIndexOf(","));
                }

              }
            }
          }
          this.filterService.setFilterValue(this.filterService.previousModel, this.queryParams);
          
        }
        // this.filterService.resetFilter();
        // window.location.reload();

      }

    } else if (myType == "route") {
      this.dialogService.openSnackBar("KPI not selected","")
    }
  }

  getRandomId() {
    const getRandomNumber = (min: number, max: number): number =>
      Math.floor(Math.random() * (max - min + 1) + min);

    const alphabet = "qwertyuiopasdfghjklzxcvbnm";
    let res = "";

    for (let i = 0; i < 5; i++) {
      res += alphabet[getRandomNumber(0, 25)];
    }
    return res;
  }

  lookBackUsingDateFilter(arr:any) {
    if(arr){
      sessionStorage.setItem("dashboardControl", JSON.stringify(arr));
    }
  }

  private notCompareClick(myData: any): void {
    // debugger
    let config = this.item?.['config'];
    
    if(config?.nav_configuration) {
      const config0 = config?.nav_configuration?.['controls'].find((o:any)=> o.chartType.includes('Single'));
      this.navigationImplementation(config0, 'single')
      this.routerHandlerService.storingPayloadAndFilterForGrid(config0, myData, '');
    }
    // this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/comparison/single`], { queryParams: query, queryParamsHandling: 'merge', skipLocationChange: false });
  }

  navigationImplementation(config0:any, cardId:any) {
    let nav = {
      dbJSON: config0?.dbJSON,
      s3JSON: config0?.s3JSON,
      pageName: config0?.pageName,
      isDashboardLevel: config0?.isDashboardLevel
    }
    this.lookBackUsingDateFilter(config0?.dbJSON);
    sessionStorage.setItem("navDetailInfo", JSON.stringify(nav));

    let query:any = Object.assign({}, this.decryptedParams, {cardId : cardId}, {tab_id: 0})
    let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'comparison'}`, queryMaped);
  }


  ngOnInit(): void {
    this.start()
    this.iconList = this.item?.config.icon ? this.item?.config.icon : this.iconList
    this.initiateCharts();


  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getKpiBreakdownHeatmapChartData();
    }
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select(this.kpiBreakdownContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr("width", '100%')
      .style('background-color', 'white');

    mySvg.append("line").attr("id", "topLine")
    this.initiateChart = true
    if (this.type == 'chart') {
      this.getKpiBreakdownHeatmapChartData();
    }
  }
// chart svg  plotChart rendering
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    let width: any = mySvg.node().getBoundingClientRect().width;
    const rightArrowViewBox = '0 0 20 20';
    const rightArrowPath = 'M18.6364 8.18184H11.8182V1.36364C11.8182 0.610908 11.2073 0 10.4545 0H9.54548C8.79275 0 8.18184 0.610908 8.18184 1.36364V8.18184H1.36364C0.610908 8.18184 0 8.79275 0 9.54548V10.4545C0 11.2073 0.610908 11.8182 1.36364 11.8182H8.18184V18.6364C8.18184 19.3891 8.79275 20 9.54548 20H10.4545C11.2073 20 11.8182 19.3891 11.8182 18.6364V11.8182H18.6364C19.3891 11.8182 20 11.2073 20 10.4545V9.54548C20 8.79275 19.3891 8.18184 18.6364 8.18184Z';
    const downArrowViewBox = '0 0 20 20';
    const downArrowPath = 'M14.9091 1.85966e-05H9.45453C9 4.32323e-05 8.9658 0 8.36362 0L7.63638 1.85966e-05C7.0342 1.85966e-05 7 1.85967e-05 6.54547 1.85966e-05H1.09091C0.488726 1.85966e-05 0 0.488746 0 1.09093V1.81816C0 2.42035 0.488726 2.90907 1.09091 2.90907H6.54547C7 2.90907 7.0342 2.90929 7.63638 2.90929H8.36362C8.9658 2.90929 9 2.90907 9.45453 2.90907H14.9091C15.5113 2.90907 16 2.42035 16 1.81816V1.09093C16 0.488746 15.5113 1.85966e-05 14.9091 1.85966e-05Z';
    let height = 0;
    const margins = { leftColumn: 140,  left: 0, right: 0, top: 70, bottom: 20};
    const rowHeight = 36;
    const rectHeight = 20;
    const depthWidth = 35;
    let currentY = 0;
    let maxDepth: any = 0;
    let maxRight: any = 0;
    let columnSet: any = new Set();
    let kpiRanges: any = {};
    const colorScale = d3.scaleOrdinal().domain(myChart.props.colorLabels).range(myChart.props.colors);
    myChart.KpiBreakdownHeatmapChartData.forEach((d: any) => columnSet.add(d[myChart.props.columnVar]));
    columnSet = Array.from(columnSet);
    if(width < (margins.leftColumn + (columnSet.length * myChart.props.columnMinWidth))){
      width = margins.leftColumn + (columnSet.length * myChart.props.columnMinWidth);
      mySvg.attr("width", width + "px");
    }
    columnSet.forEach((d: any) => {
      const myDomain = myChart.KpiBreakdownHeatmapChartData.find((f: any) => f[myChart.props.columnVar] === d  && f[myChart.props.secondLevelVar] === ("ALL " + f[myChart.props.topLevelVar]));
      kpiRanges[d] = {'kpi_key': myDomain[myChart.props.kpino],"threshold": [myDomain[myChart.props.thresholdVars[0]],myDomain[myChart.props.thresholdVars[1]]],
        "limit": [myDomain[myChart.props.limitVars[0]],myDomain[myChart.props.limitVars[1]]], "value": +myDomain[myChart.props.numberVar]}
    })

    let hierarchyData: any = buildHierarchyData();
    // process repeated here as I cannot clone the hierarchy data format.
    hierarchyData = d3.hierarchy(hierarchyData);
    if(!myChart.props.expandAll){
      hierarchyData.descendants().forEach(function(d: any){
        if (d.depth >= 1 && d.children !== undefined){
          d._children = d.children;
          d.children = [];
        }
      });
    }
    currentY = 0;
    let chartData = hierarchyData.descendants();
    chartData = chartData.filter((f: any) => f.depth > 0);
    maxDepth = d3.max(chartData, (d: any) => d.depth);
    let currentChartData: any = [];
    const xScale: any = d3.scaleBand().domain(columnSet).range([0, width - margins.left - margins.leftColumn - margins.right])

    mySvg.select("#topLine")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", margins.top)
      .attr("y2", margins.top)
      .attr("stroke-width", 1)
      .attr("stroke", "#F0F3F6");

    drawChart(chartData);

    function drawChart(currentChartData: any){

      myChart.props.currentSelections.map((m: any, i: any) => m.index = (i + 1));
      const newHeight = margins.top + margins.bottom + (currentChartData.length + 1) * rowHeight;
      mySvg.attr('height',myChart.type=='chart'? newHeight-45 :newHeight);

      // dates header group
      const columnHeaderGroup = mySvg
        .selectAll('.columnHeaderGroup' + myClass)
        .data(columnSet)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'columnHeaderGroup' + myClass);
          enter.append('text').attr('class', 'columnHeaderLabel');
          return enter;
        });
      columnHeaderGroup
        .select('.columnHeaderLabel')
        .attr('x', (d: any) =>  xScale(d) + xScale.bandwidth()/2)
        .attr('y', margins.top - 20)
        .attr('text-anchor', 'middle')
        .attr('font-size', 12)
        .attr('font-weight', 700)
        .attr('font-family', 'Poppins')
        .attr('fill', '#000000')
        .text((d: any) => d)
        .attr('transform', 'translate(' + (margins.left + margins.leftColumn) + ',0)');
      const myChartData: any = [];

      currentChartData.forEach((d: any) => {
        if(d.depth === 1){
          myChartData.push(d);
          d.children.forEach((c: any) => {
            const myChild = currentChartData.find((f: any) => f.depth === 2 && f.data.name === c.data.name);
            if(myChild !== undefined){
              myChartData.push(myChild)
            }
          })
        }
      })
      myChartData.map((m: any, i: any) => m.y = i * rowHeight);
      myChartData.forEach((d: any) => {
        d.data.values.map((m: any) => {
          const isSelected = myChart.props.currentSelections.find((f: any) =>
            f[myChart.props.columnVar].toLowerCase() === m.name.toLowerCase()
            && f[myChart.props.secondLevelVar].toLowerCase() === d.data.name.toLowerCase());
          m.selected = isSelected;
          m.selectedIndex = isSelected === undefined ? "" : isSelected.index;
        })
      })
      // hierarchy nodes group
      const nodeGroup = mySvg
        .selectAll('.nodeGroup' + myClass)
        .data(myChartData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'nodeGroup' + myClass);
          enter.append('line').attr('class', 'nodeHorizontalLine');
          enter.append('line').attr('class', 'nodeLine');
          enter.append('circle').attr('class', 'nodeIconCircle');
          enter.append('svg').attr('class', 'nodeIconSvg').append('path').attr('class', 'nodeIcon');
          enter.append('rect').attr('class', 'nodeIconRect');
          enter.append('text').attr('class', 'nodeLabel');
          enter.append('g').attr('class', 'columnsGroup');
          return enter;
        });

      nodeGroup
        .attr('transform', 'translate(0,' + margins.top + ')');

      nodeGroup.select('.nodeHorizontalLine')
        .attr('y1', (d: any) => d.y + rowHeight)
        .attr('y2', (d: any) => d.y + rowHeight)
        .attr('stroke', '#F0F3F6')
        .attr('stroke-width', 1)
        .attr('x1', 0)
        .attr('x2', width);

      nodeGroup.select('.columnsGroup')
        .attr('transform', 'translate(' + (margins.left + margins.leftColumn) + ',0)');

      nodeGroup.select('.nodeIconCircle')
        .attr('cx', (d: any) => (d.depth - 1) * depthWidth + margins.left + 8)
        .attr('cy', (d: any) => d.y  + (rowHeight / 2))
        .attr('fill', (d: any) => d.children === undefined ? 'transparent' : (d._children === undefined ? '#E8EAEE' : 'transparent'))
        .attr('pointer-events', 'none')
        .attr( 'r',  8);

      nodeGroup.select('.nodeIcon')
        .attr('fill', (d: any) => d._children === undefined ? '#8A98AB' : '#1363DF')
        .attr( 'd', (d: any) => d.children === undefined ? '' : (d._children === undefined ? downArrowPath : rightArrowPath));

      nodeGroup.select('.nodeIconRect')
        .attr('x', (d: any) => (d.depth - 1) * depthWidth + margins.left)
        .attr('y', (d: any) => d.y + (rowHeight - 18)/2)
        .attr('fill', 'transparent')
        .attr('cursor', 'pointer')
        .attr( 'width', (d: any) => d.children === undefined ? 0 : 18)
        .attr( 'height', (d: any) => d.children === undefined ? 0 : 18)
        .on('click', nodeClick);

      nodeGroup.select('.nodeIconSvg')
        .attr( 'pointer-events', 'none')
        .attr('x', (d: any) => (d.depth - 1) * depthWidth + margins.left + 2 + (d._children === undefined ? 1 : 0))
        .attr('y', (d: any) => d.y + (rowHeight - 12)/2  + (d._children === undefined ? 5.5 : 0))
        .attr('viewBox',  (d: any) => d._children === undefined ? downArrowViewBox : rightArrowViewBox)
        .attr( 'width', (d: any) => d.children === undefined ? 0 : 12)
        .attr( 'height', (d: any) => d.children === undefined ? 0 : 12);

      nodeGroup
        .select('.nodeLine')
        .attr('y1', (d: any) => d.y +  rowHeight/2)
        .attr('y2', (d: any) => d.y + rowHeight/2
          + (d.children === undefined ? 0 : d.children.length * rowHeight))
        .attr('stroke',  '#8A98AB')
        .attr('stroke-width', 1)
        .attr('x1', 8)
        .attr('x2', (d: any) => d.children === undefined ? 12 : 8);

      nodeGroup
        .select('.nodeLabel')
        .style('text-transform', 'capitalize')
        .attr('x',  (d: any) => d.children === undefined ? -15 :  25)
        .attr('y', (d: any) => d.children === undefined ? 4 : 5)
        .attr('cursor', 'pointer')
        .attr('font-size', (d: any) => d.children === undefined ? 10 : 12)
        .attr('font-weight', 700)
        .attr('font-family', 'Poppins')
        .text((d: any) =>   d.data.name)
        .attr(
          'transform',
          (d: any) =>
            'translate(' + ((d.depth - 1) * depthWidth + margins.left) + ',' + (d.y + (rowHeight/2)) + ')'
        )
        .on('click', nodeClick);

      const columnsGroup = nodeGroup
        .select('.columnsGroup')
        .selectAll('.columnsGroup' + myClass)
        .data(function(d: any) {
          const myValues = d.data.values;
          myValues.map((m: any) => m.y = d.y);
          myValues.map((m: any) => m.parent = d.data.name);
          return myValues;
        })
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'columnsGroup' + myClass);
          enter.append('rect').attr('class', 'columnRect');
          enter.append('rect').attr('class', 'columnSelectedRect');
          enter.append('text').attr('class', 'columnRectNumber');
          enter.append('circle').attr('class', 'selectedCircle');
          enter.append('text').attr('class', 'selectedCircleText');
          return enter;
        });

      columnsGroup
        .attr("cursor", "pointer")
        .on("mousemove", (event: any, d: any) => {
            d3.select(event.currentTarget).attr("opacity", 0.8)
            const tooltipData = {data: d, columnRanges: kpiRanges[d.name]};
            myChart.showTooltip(event.offsetX, event.offsetY,tooltipData, width, height);
        })
        .on("mouseout", () => {
          d3.selectAll('.columnsGroup' + myClass).attr("opacity", 1)
          myChart.hideTooltip();
        })
        .on("click", (event: any, d: any) => {
          myChart.hideTooltip();
          if (myChart.props.showSelections) {
            const myType: any = d.selected ? "de-select" : "select";
            if (myType === "de-select" || (myType === "select" && myChart.props.currentSelections.length < myChart.props.selectionLimit)) {
              let myData: any = {};
              myData[myChart.props.columnVar] = d.name;
              myData[myChart.props.secondLevelVar] = d.parent;

              if (myType === "select") {
                myChart.props.currentSelections.push(myData);
              } else {
                myChart.props.currentSelections =
                  myChart.props.currentSelections.filter((f: any) =>
                    !(f[myChart.props.columnVar].toLowerCase() === d.name.toLowerCase()
                      && f[myChart.props.secondLevelVar].toLowerCase() === d.parent.toLowerCase()))
              }
              drawChart(chartData);
              myChart.compareClick(myType, myData, myChart.props.currentSelections)
            }else{myChart.dialogService.openSnackBar("Select a minimum of 2 KPIs and a maximum of 4 KPIs.","")}
          } else {
            let myData: any = {};
            // myData[myChart.props.columnVar] = d.name;
            myData[myChart.props.kpino] = kpiRanges[d.name][myChart.props.kpino];
            myData['source_nm'] = d.parent;
            myChart.notCompareClick(myData)
          }
        })

      columnsGroup
        .select('.selectedCircle')
        .attr("display", (d: any) => myChart.props.showSelections && d.selected ? "block" : "none")
        .attr('cx', (d: any) => xScale(d.name) + xScale.bandwidth() - 8 - 5 - 7)
        .attr('cy', (d: any) => d.y  + (rowHeight-rectHeight)/2 + rectHeight/2)
        .attr('fill', "white")
        .attr("r", 7);

      columnsGroup
        .select('.selectedCircleText')
        .attr("display", (d: any) => myChart.props.showSelections && d.selected ? "block" : "none")
        .attr('x', (d: any) => xScale(d.name) + xScale.bandwidth() - 8 - 5 - 7)
        .attr('y', (d: any) => d.y  + 4 + (rowHeight-rectHeight)/2 + rectHeight/2)
        .attr('fill', "#1363DF")
        .attr('text-anchor', 'middle')
        .attr('font-size', 10)
        .attr('font-weight', 600)
        .attr('font-family', 'Poppins')
        .text((d: any) => d.selectedIndex);

      columnsGroup
        .select('.columnSelectedRect')
        .attr("rx",3)
        .attr("ry",3)
        .attr("display", (d: any) => myChart.props.showSelections && d.selected ? "block" : "none")
        .attr('x', (d: any) => xScale(d.name) + 4)
        .attr('fill', "none")
        .attr("stroke", "#1363DF")
        .attr("stroke-dasharray", "4,4")
        .attr('y', (d: any) =>  d.y  - 2 +  (rowHeight-rectHeight)/2)
        .attr("width", xScale.bandwidth() - 8)
        .attr('height', rectHeight + 4);

      columnsGroup
        .select('.columnRect')
        .attr("rx",3)
        .attr("ry",3)
        .attr('x', (d: any) => xScale(d.name) + 6)
      .attr('fill', (d: any) => d.fill)
        .attr('y', (d: any) =>  d.y  +  (rowHeight-rectHeight)/2)
        .attr("width", xScale.bandwidth() - 12)
        .attr('height', rectHeight);

      columnsGroup
        .select('.columnRectNumber')
        .attr('x', (d: any) => xScale(d.name) + xScale.bandwidth()/2)
        .attr('fill', 'white')
        .attr('y', (d: any) =>  d.y  +  (rowHeight-rectHeight)/2 + (rectHeight/2) + 5)
        .attr('text-anchor', 'middle')
        .attr('font-size', 12)
        .attr('font-weight', 700)
        .attr('font-family', 'Poppins')
        .text((d: any) => d.value === null ? "" : d3.format(myChart.props.numberFormat)(d.value));


      function nodeClick(event: any, d: any): void {
        const currentNode = hierarchyData
          .descendants()
          .find(
            (f: any) =>
              f.depth === d.depth &&
              d.data.name === f.data.name &&
              d.parent.data.name === f.parent.data.name
          );
        currentY = 0;
        updateHierarchy(hierarchyData, currentNode);
        chartData = hierarchyData.descendants();
        chartData = chartData.filter((f: any) => f.depth > 0);
        maxDepth = d3.max(chartData, (d: any) => d.depth);
        if (maxDepth === 1) {
          maxDepth = 2;
        }
        drawChart(chartData);
      }
    }
    function updateHierarchy(childrenArray: any, selectedNode: any): void {
      // if there are children of some sort..
      if (childrenArray.children !== undefined) {
        // if this is the selected node
        if (
          childrenArray.depth === selectedNode.depth &&
          childrenArray.data.name === selectedNode.data.name &&
          childrenArray.parent.data.name === selectedNode.parent.data.name
        ) {
          if (childrenArray._children === undefined) {
            childrenArray._children = childrenArray.children;
            childrenArray.children = [];
          } else {
            childrenArray.children = childrenArray._children;
            childrenArray._children = undefined;
          }
        }
        childrenArray.children.forEach(function(c: any): void {
          c.y = currentY;
          currentY += rowHeight;
          if (c.children !== undefined) {
            updateHierarchy(c, selectedNode);
          }
        });
      }
    }

    function buildHierarchyData(): any {
      const topLevels = Array.from(d3.group(myChart.KpiBreakdownHeatmapChartData, (d: any) => d[myChart.props.topLevelVar]));
      let myChildren: any = [];
      topLevels.forEach((d: any) => {
        const secondLevels =  Array.from(d3.group(d[1], (g: any) => g[myChart.props.secondLevelVar]));
        let secondLevelChildren: any = [];
        secondLevels.forEach((c: any) => {
          const myValues: any = [];
          columnSet.forEach((s: any) => {
            const matchingColumn = c[1].find((f: any) => f[myChart.props.columnVar] === s);
            if(matchingColumn !== undefined){
              const myLimits = [matchingColumn[myChart.props.limitVars[0]], matchingColumn[myChart.props.limitVars[1]]];
              const myThresholds = [matchingColumn[myChart.props.thresholdVars[0], matchingColumn[myChart.props.thresholdVars[1]]]]
              const value = matchingColumn[myChart.props.numberVar];
              const buffer = matchingColumn[myChart.props.buffer_range] === null ? 0 : matchingColumn[myChart.props.buffer_range];
              myValues.push({
                name: s,
                value,
                status: getStatus(myLimits,myThresholds,value, buffer),
                fill: colorScale(getStatus(myLimits,myThresholds,value,buffer)),
                dataLowerLimit: matchingColumn[myChart.props.limitVars[0]],
                dataUpperLimit: matchingColumn[myChart.props.limitVars[1]],
                dataLowerThreshold: matchingColumn[myChart.props.thresholdVars[0]],
                dataUpperThreshold: matchingColumn[myChart.props.thresholdVars[1]]
              })
            } else {
              myValues.push({
                name: s,
                value: null,
                status: "none",
                fill: "#e6e6e6",
                dataLowerLimit: null,
                dataUpperLimit: null,
                dataLowerThreshold: null,
                dataUpperThreshold: null
              });
            }
          })
          secondLevelChildren.push({
            name: c[0],
            values: myValues
          })
        })
        const totalValues: any = [];
        columnSet.forEach((s: any) => {
          const matchingColumns = d[1].filter((f: any) => f[myChart.props.columnVar] === s);
          if(matchingColumns.length > 0){
            const myTotal = kpiRanges[s].value;
            totalValues.push({
              name: s,
              value: kpiRanges[s].value,
              status: getStatus(kpiRanges[s].limit, kpiRanges[s].threshold, myTotal,0),
              fill: colorScale(getStatus(kpiRanges[s].limit, kpiRanges[s].threshold, myTotal,0)),
              dataLowerLimit: kpiRanges[s].limit[0],
              dataUpperLimit: kpiRanges[s].limit[1],
              dataLowerThreshold: kpiRanges[s].threshold[0],
              dataUpperThreshold: kpiRanges[s].threshold[1]
            })
          }
        })
        // secondLevelChildren.unshift({
        //   name: `ALL ${d[0].toUpperCase()}`,
        //   values: totalValues
        // })
        myChildren.push({
          name: d[0],
          children: secondLevelChildren,
          values: []
        })
      })

      function getStatus(myLimits: any, myThresholds: any, value: any, buffer: number){
        const limitRange = myLimits[1] - myLimits[0];
        const limitBufferValue = limitRange * buffer;
        const limitBuffer = [myLimits[0] + limitBufferValue, myLimits[1] - limitBufferValue];
        if(myThresholds[0] === null || myThresholds[1] === null){
          if(value < myLimits[0] || value > myLimits[1]){
            return "anomaly"
          } else if (value < limitBuffer[0] || value > limitBuffer[1]){
            return "buffer values"
          } else {
            return "normal"
          }
        }
        if(value < myLimits[0] || value < myThresholds[0]
          || value > myLimits[1] || value > myThresholds[1]){
          return "anomaly"
        } else if (value < limitBuffer[0] || value > limitBuffer[1]){
          return "buffer values"
        } else {
          return "normal"
        }
      }
      return {'name': 'root', 'children': myChildren};
    }

    drawLegend(myChart.props.colorLabels)
    function drawLegend(legendData: any){

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(10,15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any, i: any) => 'circle_legendItem' + i)
        .attr('fill', (d: any) =>colorScale(d))
        .attr('r', 8);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any, i: any) => 'legendItem' + i)
        .attr('y', 4.5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => d.toUpperCase())

      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function(): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + 14);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (38 + myWidth)
      });
    }
    this.stop(10).then(() => this.isLoading = false);
  }
// property & data for the chart
  getKpiBreakdownHeatmapChartData() {
    // this.KpiBreakdownHeatmapChartData=[
    //   {
    //     "source_type" : "HUB",
    //     "source_nm" : "MCKESSON HUB",
    //     "kpi_value" : 513,
    //     "kpi_name" : "Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "RxCrossroads",
    //     "kpi_value" : 1052,
    //     "kpi_name" : "Shipments",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "RxCrossroads",
    //     "kpi_value" : 223,
    //     "kpi_name" : "Shipped Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Amber",
    //     "kpi_value" : 79,
    //     "kpi_name" : "Shipped Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Onco360",
    //     "kpi_value" : 414,
    //     "kpi_name" : "Shipped Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Biologics",
    //     "kpi_value" : 324,
    //     "kpi_name" : "Shipped Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "RxCrossroads",
    //     "kpi_value" : 43950,
    //     "kpi_name" : "Qty Shipped",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Amber",
    //     "kpi_value" : 13980,
    //     "kpi_name" : "Qty Shipped",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Onco360",
    //     "kpi_value" : 107370,
    //     "kpi_name" : "Qty Shipped",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Biologics",
    //     "kpi_value" : 72060,
    //     "kpi_name" : "Qty Shipped",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Onco360",
    //     "kpi_value" : 1000,
    //     "kpi_name" : "Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Amber",
    //     "kpi_value" : 397,
    //     "kpi_name" : "Shipments",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Onco360",
    //     "kpi_value" : 310,
    //     "kpi_name" : "Shipments",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Biologics",
    //     "kpi_value" : 1924,
    //     "kpi_name" : "Shipments",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Amber",
    //     "kpi_value" : 96,
    //     "kpi_name" : "Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "RxCrossroads",
    //     "kpi_value" : 157,
    //     "kpi_name" : "Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   },
    //   {
    //     "source_type" : "SP",
    //     "source_nm" : "Biologics",
    //     "kpi_value" : 383,
    //     "kpi_name" : "Patients",
    //     "lower_threshold" : "300",
    //     "upper_threshold" : "2000",
    //     "lower_control_limit" : "500",
    //     "upper_control_limit" : "3000"
    //   }
    // ]
    this.KpiBreakdownHeatmapChartData = this.data
    // this.data = this.KpiBreakdownHeatmapChartData
    this.props = {
      topLevelVar: 'source_type',
      secondLevelVar: 'source_name',
      columnVar: 'kpi_name',
      colorVar: 'file_status', // variable used for color - must match values in properties.color AND match a column in the data
      numberVar: 'kpi_value', // count variable (for root children only but sum passed to tooltip)
      kpino: 'kpi_key',
      thresholdVars: ["lower_threshold", "upper_threshold"],
      limitVars: ["lower_control_limit", "upper_control_limit"],
      bufferPercent: 0.025,
      colors: ["#D11044", "#FFCD4A", "#3AC97C","#e6e6e6"],
      colorLabels: ["anomaly", "buffer values", "normal",'No Data'],
      showSelections: false,
      numberFormat: ",",
      currentSelections: [],
      selectionLimit: 4,
      expandAll: true
    }
    // @ts-ignore
     
    if ( this.KpiBreakdownHeatmapChartData.length > 0) {
      this.noData = false
      setTimeout(() => {
        if (this.type == 'chart') {
          let currentSelections: any = sessionStorage.getItem("currentSelections");
          this.props.currentSelections = JSON.parse(currentSelections);
          this.props.showSelections = true
        }
        this.plotChart();
      }, 100);
    }else{
      this.stop(10).then(() => this.isLoading = false);
      this.noData = true
    }
  }
  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }

  getPeriodtext(){
    switch (this.filterService.report_type) {
      case 'D': return "Last Day"
      case 'W': return "Last Week"
      case 'M': return "Last Month"
      case 'Q': return "Last Quarter"
      case 'Y': return "Last Year"


    }
  }
}