<!-- <app-loader-component>fsafasf</app-loader-component> -->

<div class="widget-container patient_card" #patientCardDiv >
  <mat-tab-group (selectedTabChange)="onChange($event)">
    <div *ngFor="let config of tabHeadingArray;let index = index">
      <mat-tab class="patient-tabs" [label]="config.startValue">
        
        <div class="card patient-card" style="width:-webkit-fill-available;min-height: 100px;height:auto;" >
          <div class="card-body" *ngIf="config.selectStyle == 'Form' && tableData?.length > 0">
            <div class="row">
              <div class="col-md-6 col-lg-3 col-sm-6 mb-2 d-flex" *ngFor="let data of tableData">
                <div class="col-6 data_heading" matTooltip="{{data?.id?.length > 17 ? data?.id?.replaceAll('_', ' ') : ''}}" [style.text-align]="config.alignment_value">{{data?.id?.replaceAll('_', ' ')}}</div>
                <span class="pl-0">:</span>
                <div class="col-6 data_value" matTooltip="{{data?.value?.length > 17 ? data?.value : ''}}" [style.text-align]="config.index_position">{{data?.value}}</div>
              </div>
            </div>
            <div class="row pt-2 text-center" *ngIf="showButtons && tableData.length > 0">
              <div *ngIf="!showRecords">
                <a (click)="getTotalRecords($event)" class="show_more">Show More</a>
                <mat-icon class="ml-1 pt-2" style="color:#1363DF;cursor: pointer;min-height: 85%;"
                  (click)="getTotalRecords($event)">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="showRecords">
                <a (click)="getTrimmedRecords()" class="show_less">Show Less</a>
                <mat-icon class="ml-1 pt-2" style="color:#1363DF;cursor: pointer;"
                  (click)="getTrimmedRecords()">keyboard_arrow_up</mat-icon>
              </div>
            </div>

          </div>

          <div class="card-body error_style" *ngIf="config?.selectStyle == 'Form' && tableData?.length == 0 && error"> No Data Found</div>

          <div class="card-body tab_style_table" *ngIf="config?.selectStyle == 'Tab' && tabStyleData?.length > 0" style="overflow:auto;">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th *ngFor="let data of tabStyleData" [style.text-align]="config.alignment_value">{{data.id.replaceAll('_', ' ')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td *ngFor="let data of tabStyleData" [style.text-align]="config.index_position">{{data.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body error_style" *ngIf="config?.selectStyle == 'Tab' && tabStyleData?.length == 0 && error"> No Data Found</div>

          <div class="card-body" *ngIf="config.selectStyle == 'selectStyle' && tableData.length > 0">
            <div class="row">
              <div class="col-3 mb-2 d-flex text-center" *ngFor="let data of tableData">
                <div class="col-6 data_heading" [style.text-align]="config.alignment_value">{{data.id.replaceAll('_', ' ')}}:</div>
                <div class="col-6 data_value pl-0" [style.text-align]="config.index_position">{{data.value}}</div>
              </div>
            </div>
            <div class="row pt-2 text-center" *ngIf="showButtons">
              <div *ngIf="!showRecords">
                <a (click)="getTotalRecords($event)" class="show_more">Show More</a>
                <mat-icon class="ml-1 pt-2" style="color:#1363DF;cursor: pointer;min-height: 85%"
                  (click)="getTotalRecords($event)">keyboard_arrow_down</mat-icon>
              </div>
              <div *ngIf="showRecords">
                <a (click)="getTrimmedRecords()" class="show_less">Show Less</a>
                <mat-icon class="ml-1 pt-2" style="color:#1363DF;cursor: pointer;"
                  (click)="getTrimmedRecords()">keyboard_arrow_up</mat-icon>
              </div>
            </div>

          </div>

          <div class="card-body error_style" *ngIf="config?.selectStyle == 'selectStyle' && tableData?.length == 0 && error"> No Data Found</div>
          

        </div>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>

