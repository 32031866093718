import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-detail-card",
  templateUrl: "./detail-card.component.html",
  styleUrls: ["./detail-card.component.scss"],
})
export class WidgetDetailCardComponent implements OnInit {
  static key = 100803;
  
  @Input("item") item: any;
  @Input("heading") heading: string = "";
  @Input("height") height: any = {};

  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("detailCard", { static: true }) detailCard!: ElementRef;
  
  reqSubcription: Subscription[] = [];
  isActive = false;
  locationId:any;
  check_icon = "check_icon"
  gridArr:any = []

  constructor(private dialog: MatDialog, 
    private route: ActivatedRoute, 
    private filterService: FilterService, 
    private dataService: DataService, 
    private httpClient: HttpClient, 
    private apiService: ApiService) {
      this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      }))
    }
  

  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.detailCard.nativeElement);

    // this.route.params.subscribe((p:any) => {
    //   let cardName = p["cardName"];
      // debugger
      // if(cardName=='pwt') {
      //     this.route.queryParams.subscribe((params:any) => {
      //         this.locationId = JSON.parse(params.queryFilter)
      //     })
      // }
    // })
  }

  loadApiData() {

    let api = 200202 || {};
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      // API Payload
      let obj:any = { "api_key" : api }
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = 'D';

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          if(res && res.length>0) {
            const keysToMatch = this.item.config.list_of_data || [ 'Address','Phone','Email','NPI','Preferred SP'];
            let queryFilter = Object.entries(res[0]).map(([key, value]) => ({ key, value }));
            let ifQuery = queryFilter.filter((fnd:any)=>  keysToMatch.some((matchedKey:any) => matchedKey.toString().toLowerCase() === fnd.key.toString().toLowerCase()))
            this.gridArr = [...ifQuery]
          } 
        },(err:any)=>{
          console.log('detail widget',err)
        })
      )
    }
  }

  ngOnDestroy () {
    this.reqSubcription.forEach(sub => sub.unsubscribe())
  }

}
