import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { report } from 'process';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-follow-up-popup',
  templateUrl: './follow-up-popup.component.html',
  styleUrls: ['./follow-up-popup.component.scss']
})
export class FollowupPopupComponent implements OnInit {

  // Variables
  brandKeyData:any;
  cancel_x = "cancel_x";
  paramsData:any;
  followType:any = '';
  selectedFollowDate= moment().format()
  selectedFollowOutComes:any = 'outcome1';
  selectedFollowUpResolve:any = 'N';
  followUpFlag=false;
  dataLoads=false;
  moment=moment;
  params:any;
  folwUpDetail:any;
  loaderApi = false;
  request:any;
  selectedBrand:any;
  createdBy:any;
  createdDate:any;
  minDate =  new Date();
  selectDataLoaded= false;
  visibleData = false;
  selectedAllData = [];

  followUpReasonAllData = [
    {"name": "Follow-up with HCP"},
    {"name": "Follow-up with HUB"},
    {"name": "Missing Information - Patient"},
    {"name": "Missing Information - HCP"}
  ]
  followUpResolvedAllData = [
    {"name": "Yes" , "value": 'Y'},
    {"name": "No", "value": 'N'}
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public filterService: FilterService,
    private dialogRef: MatDialogRef<FollowupPopupComponent>,
    private apiService: ApiService,
    private httpClient: HttpClient,
  ) {
    this.paramsData = passedData
    this.params = passedData.params
  }

  ngOnInit(): void {
    // debugger
    this.folwUpDetail = this.paramsData.data;

    if(this.paramsData.toBeShow==false){
      
      this.followType = this.folwUpDetail?.['follow_up_reason'] || this.followUpReasonAllData[0].name;
      this.selectedFollowDate = moment(this.folwUpDetail['due_date']).format();
      this.selectedFollowUpResolve = this.folwUpDetail['highlight_flg'] || this.followUpResolvedAllData[0].value;
  
      this.createdBy = this.folwUpDetail['created_by'] || '';
            
      if(this.folwUpDetail['created_date'].charAt(0) == 0) {
        let date = this.folwUpDetail['created_date'].split('T');
        let newDate = date[0].split("-");
        newDate[0] = Number(newDate[0]);
        newDate = newDate.toString().replaceAll(',', '-');
        this.createdDate = moment(newDate, 'YY-MM-DD').format('MM/DD/YYYY');
      } else {
        this.createdDate = moment(this.folwUpDetail['created_date']).format('MM/DD/YYYY');
      }

    } else {
      this.followType = this.followUpReasonAllData[0].name;
      this.selectedFollowDate = moment().format();
      this.selectedFollowUpResolve = this.followUpResolvedAllData[0].value;
    }

    this.selectDataLoaded = true;
  };

  saveAndCreate() {
    // debugger
    this.dataLoads = true;
    this.loaderApi = true;
    let request:any = {
      "api_key": "100606",
      "follow_up_reason" : this.followType,
      "patient_id" : this.params.data.patient_id 
    }

    if(this.paramsData.toBeShow) {
      request['report_typ'] = "I"
      request["follow_up_start_date"] = moment(this.selectedFollowDate).format('YYYY-MM-DD');
      request['highlight_flg'] = "N"
    } else {
      request['report_typ'] = "U"
      request['patient_follow_up_key'] = this.folwUpDetail['patient_follow_up_key']
      request['out_come'] = '';
      request['highlight_flg'] = this.selectedFollowUpResolve
    }
    
    let query2 = Object.assign({}, this.filterService.baseQuery, request);

    this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
      this.request = request;
      this.selectedForAllData();
      this.loaderApi =true;
      this.visibleData = true;
      // this.function()
    },(err:any)=>{
      if(err.status==200) {
        this.selectedForAllData();
        this.request = request;
        this.loaderApi =true;
        this.visibleData = true;
        // this.function()
      } else {
        this.loaderApi=false;
        this.visibleData = true;
      }
    });
  }

  selectedForAllData() {
    let request:any = {
      "api_key": "100606",
      "report_typ": "D",
      "patient_id" : this.params.data.patient_id 
    }

    let query2 = Object.assign({}, this.filterService.baseQuery, request);

    this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
      this.selectedAllData = res ? res : [];
      this.function();
    },(err:any)=>{
      this.function();
    });
  }

  gotItforValue() {
    if(this.loaderApi) {
      this.dialogRef.close(this.selectedAllData)
    } else {
      this.dialogRef.close()
    }
  }

  function() {
    const circleLoader:any = document.querySelector('.circle-loader');
    const checkmark:any = document.querySelector('.checkmark');
    
    circleLoader.classList.toggle('load-complete');
    checkmark.style.display = (checkmark.style.display === 'none' || checkmark.style.display === '') ? 'block' : 'none';
  };
}
