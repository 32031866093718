<div #myElement>
    <div id="myDiv" [class.colorTest]="show" *ngIf="cardDisplay">
        <div class="container" style="padding: 5px 10px;" [style.height]="show ? 'auto' : this.height.height - 30 + 'px'" [class.show]="show">
            <div class="d-flex bd-highlight" *ngFor="let a of presData" style="padding-top: 5px;font-size: 14px;">
                <div class="me-auto bd-highlight">
                    <span style="color: #5D657D; text-transform: capitalize" > {{a.key ? a.key.replaceAll('_', ' ') : ''}} </span>
                </div>
                <div style="direction: rtl;">
                    <span style="color: #363636;overflow-wrap: anywhere;">{{a.value == null ? '' :  a.value}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end" *ngIf="presData" style="margin: 5px 5px 0 0;">
            <span (click)="show = !show" style="color: #1363DF;">{{ show ? 'Show less': 'Show More' }}</span>
            <mat-icon (click)="show = !show" style="color: #1363DF;margin-top: -4px !important;cursor: pointer;">{{ show ? 'keyboard_arrow_up': 'keyboard_arrow_down' }} </mat-icon>
        </div>
    </div>
</div>

<div [class.colorTest]="show" *ngIf="!cardDisplay">
    <div [style.height]="show ? 'auto' : 100 + 'px'" [class.show]="show" class="overflows">
        <div class="d-flex bd-highlight" *ngFor="let a of presData" style="padding-top: 5px;font-size: 14px;white-space: nowrap;">
            <div class="me-auto bd-highlight">
                <span style="color: #5D657D; text-transform: capitalize" > {{a.key ? a.key.replaceAll('_', ' ') : ''}} </span>
            </div>
            <div style="direction: rtl;text-align: right;white-space: pre-wrap;">
                <span style="color: #363636;overflow-wrap: anywhere;">{{a.value == null ? '' :  a.value}}</span>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="presData" style="margin: 5px 5px 0 0; background-color: #fff;">
        <span (click)="showBtnForLes()" style="color: #1363DF;">{{ show ? 'Show less': 'Show More' }}</span>
        <mat-icon (click)="showBtnForLes()" style="color: #1363DF;margin-top: -4px !important;cursor: pointer;">{{ show ? 'keyboard_arrow_up': 'keyboard_arrow_down' }} </mat-icon>
    </div>

</div>