export default {
  title: 'Dispense Map',
  map: {
    id: 'dispense-map',
    view: {
      legend: 'dispense-map-legend',
      tooltip: 'dispense-map-tooltip-wrapper',
    },
  },
  legend: {
    id: 'dispense-map-legend',
    data: {
      source: 'service',
    },
    view: {
      editable: true,
    },
  },
  tooltip: {
    id: 'dispense-map-tooltip',
    data: {
      source: 'service',
    },
    view: {
      template: 'custom',
    },
  }
}