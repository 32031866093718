<div class="widget-container">
    <!-- Headings -->
    <main >
        <article #fs fullscreen-able [class.is-active]="isActive" [style.padding]="isActive ? '15px' : '5px'">

            <div class="m-1 mb-2">
                <!-- Header Configuration -->
                <icon-chart 
                    [headerConfig]="item.config?.widget_header_configuration"
                    [heading]="heading" 
                    [config]="gridList" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                ></icon-chart>
            </div>
            <hr>

            <div [style.height]="this.deviceDetectionService.isDesktop ? height ? (this.height.height - 90) + 'px' : '100%' : '250px'" style="overflow-y: auto;">
                <div *ngFor="let st of gridArr" class="m-3">
                    <div class="mb-2">
                        <span class="spanPt">
                            {{ st.pt_status}}
                        </span>
                    </div>
                    
                    <div class="mb-2">
                        <span class="headName">
                            {{ st.pt_name}}
                        </span>
                    </div>
    
                    <div class="mb-2">
                        <span class="d-flex justify-content-between">
                            <span class="headTime headName">
                                {{ st.pt_date}}
                            </span>
                            <span class="headName fontStatus d-flex" [style.color]="st.pt_curr == 'Resolved' ? '#8A98AB' : '#3AC97C'">
                                
                                <mat-icon *ngIf="st.pt_curr != 'Resolved'" [svgIcon]="check_icon" style="width: 16px;height: 16px;" class="mr-2"></mat-icon>
                                
                                {{ st.pt_curr }}
                            </span>
                        </span>
                    </div>
    
                    <hr>
                </div>
            </div>
            


            
        </article>
    </main>
</div>