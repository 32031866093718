<div class="widget-container" #alertSubscriptions>
    <!-- Headings -->
    <main>
        <article #fs fullscreen-able [class.is-active]="isFullscreen" [class.isFulScreen]="isFullscreen"
            style="background-color: #fff;">
            <div class="mb-3">
                <icon-chart 
                    [headerConfig]="item.config['widget_header_config']" 
                    [pageKey]="pageKey" 
                    [expand]="fs"
                    [fullscreen]="isFullscreen" 
                    [item]="item" 
                    >
                </icon-chart>
            </div>

            <div *ngIf="!loader" style="width: 100%; height: 200px;">
                <loading></loading>
            </div>

            <div *ngIf="loader && rowData.length==0" style="height: 200px; width: auto;">
                <span class="bg-texts">There is no data for this time period</span>
            </div>

            <div *ngIf="loader && rowData.length>0">
                <div class="row mb-3" >
                    <div class="col-12 mb-5">
                        <div class="mb-3" *ngFor="let file of rowData; let idx = index;">
                            <mat-expansion-panel 
                                class="mx-2 du-expansionPanelMat du-file-header-disabled"
                                (opened)="setOpened(idx)"
                                (closed)="setClosed(idx)"
                                hideToggle="true">
                                <!-- Header -->
                                <mat-expansion-panel-header class="du-expansion-panel-header-file ">
                                    <mat-panel-title class="mx-2 d-flex align-items-center bd-highlight" *ngIf="!deviceDetectionService.isMobile">
                                        <div class="bd-highlight" style="min-width: 200px;padding: 10px 10px 15px;">
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="du-file-name">{{ file.key }}</span>
                                                <span class="du-file-status-badge" [style.background]="file.flag_action ? '#3AC97C' : '#D11044'"></span>
                                            </div>
                                            <div class="d-flex">
                                                <span class="du-file-action-text" [style.border]="file.flag_action ? '1px solid #3AC97C' : '1px solid #D11044'">Action taken <span class="du-file-statusCount" [style.background]="file.flag_action ? '#3AC97C' : '#D11044'">{{ file.taken_action + ' of ' + file.total_action }}</span></span>
                                            </div>
                                        </div>

                                        <div class="vr du-vr"></div>
                                        
                                        <div class="p-2 bd-highlight">
                                            <div class="d-flex flex-wrap" *ngIf="file.value?.length>0">
                                                <div *ngFor="let sub of file.value; let i = index;" class="du-status-border">  
                                                    <div class="d-flex align-items-center">
                                                        <span style="min-width: 130px;">
                                                            <div class="d-flex align-items-center">
                                                                <span class="du-file-sub-name">{{ sub.kpi_name }}</span>
                                                                <span class="du-file-status-badge" [style.background]="sub.status_flg == 'P' ? '#3AC97C' : '#D11044'"></span>
                                                            </div>
                                                        </span>
                                                        <span class="du-file-header-enabled" (click)="getSubFileDetails(file, sub);$event.stopPropagation();" [matMenuTriggerFor]="subFiles">
                                                            <mat-icon style="height: 20px;width: 20px;overflow: visible;" [svgIcon]="sub.msg_icon"></mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="ms-auto p-2 bd-highlight">
                                            <!-- <span class="du-file-header-enabled">
                                                <span *ngIf="currentlyOpenedItemIndex === idx">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.785 15.2779C15.655 15.3321 15.5054 15.3015 15.4059 15.2027L7.99998 7.79612L0.594097 15.202C0.494608 15.3015 0.343663 15.3314 0.214956 15.2778C0.0848804 15.2243 0 15.097 0 14.9565V8.6956C0 8.60309 0.0368804 8.51472 0.101576 8.44934L7.75376 0.797155C7.82194 0.729689 7.91096 0.69558 8.00002 0.69558C8.08907 0.69558 8.17809 0.729656 8.24628 0.797155L15.8985 8.44934C15.9632 8.51472 16 8.60309 16 8.6956V14.9565C16 15.097 15.9151 15.2243 15.785 15.2779Z" fill="#8A98AB"/>
                                                    </svg>
                                                </span>
                                                
                                                
                                                <span *ngIf="currentlyOpenedItemIndex !== idx">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.785 0.722146C15.655 0.667885 15.5054 0.698504 15.4059 0.797276L7.99998 8.20388L0.594097 0.797993C0.494608 0.698504 0.343663 0.668602 0.214956 0.722178C0.0848804 0.775722 0 0.903026 0 1.04354V7.3044C0 7.39691 0.0368804 7.48528 0.101576 7.55066L7.75376 15.2028C7.82194 15.2703 7.91096 15.3044 8.00002 15.3044C8.08907 15.3044 8.17809 15.2703 8.24628 15.2028L15.8985 7.55066C15.9632 7.48528 16 7.39691 16 7.3044V1.04354C16 0.903026 15.9151 0.775722 15.785 0.722146Z" fill="#A4B1C1"/>
                                                    </svg>                                                    
                                                </span>
                                            </span> -->
                                        </div>
                                    </mat-panel-title>

                                    <mat-panel-title class="mx-2 d-flex align-items-center bd-highlight" style="display: block !important;" *ngIf="deviceDetectionService.isMobile">
                                        <div class="bd-highlight" style="min-width: 200px;padding: 10px 10px 15px;">
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="du-file-name">{{ file.key }}</span>
                                                <span class="du-file-status-badge" [style.background]="file.flag_action ? '#3AC97C' : '#D11044'"></span>
                                            </div>
                                            <div class="d-flex">
                                                <span class="du-file-action-text" [style.border]="file.flag_action ? '1px solid #3AC97C' : '1px solid #D11044'">Action taken <span class="du-file-statusCount" [style.background]="file.flag_action ? '#3AC97C' : '#D11044'">{{ file.taken_action + ' of ' + file.total_action }}</span></span>
                                            </div>
                                        </div>
                                        
                                        <hr>
                                        <!-- <div class="vr du-vr"></div> -->
                                        
                                        <div class="p-2 bd-highlight">
                                            <div  *ngIf="file.value?.length>0">
                                                <div *ngFor="let sub of file.value; let i = index;" class="du-status-border">  
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <span style="min-width: 130px;">
                                                            <div class="d-flex align-items-center">
                                                                <span class="du-file-sub-name">{{ sub.kpi_name }}</span>
                                                                <span class="du-file-status-badge" [style.background]="sub.status_flg == 'P' ? '#3AC97C' : '#D11044'"></span>
                                                            </div>
                                                        </span>
                                                        <span class="du-file-header-enabled" (click)="getSubFileDetails(file, sub);$event.stopPropagation();" [matMenuTriggerFor]="subFiles">
                                                            <mat-icon style="height: 20px;width: 20px;overflow: visible;" [svgIcon]="sub.msg_icon"></mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- Body -->
                            </mat-expansion-panel>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </main>
</div>

<mat-menu #subFiles="matMenu">
    <div class="card" style="min-width: 350px;border: none;width: auto;overflow: hidden;" (click)="$event.stopPropagation()">
        <div class="card-header d-flex justify-content-between align-items-center"
            style="border-bottom: none;background: #F4F6F9;font-family: 'Poppins';">
            <span>
                <!-- <span style="display: block;color: #737D88; font-size: 12px; font-family: 'Poppins';line-height: 15px;">Comments</span> -->
                <span style="font-weight: 600;font-family: 'Poppins';">{{ subFilesName || ''}}</span>
            </span>

            <span (click)="closeMenu()" style="cursor: pointer;">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9602 1.92751L8.0001 5.88759L4.04001 1.92751C3.60281 1.49031 2.89317 1.49031 2.45597 1.92751L1.92799 2.45549C1.4908 2.89268 1.4908 3.60233 1.92799 4.03952L5.88808 7.99961L1.92799 11.9597C1.4908 12.3969 1.4908 13.1065 1.92799 13.5437L2.45597 14.0717C2.89317 14.5089 3.60281 14.5089 4.04001 14.0717L8.0001 10.1116L11.9602 14.0717C12.3974 14.5089 13.107 14.5089 13.5442 14.0717L14.0722 13.5437C14.5094 13.1065 14.5094 12.3969 14.0722 11.9597L10.1121 7.99961L14.0722 4.03952C14.5094 3.60233 14.5094 2.89268 14.0722 2.45549L13.5442 1.92751C13.107 1.49031 12.3974 1.49031 11.9602 1.92751Z" fill="#8A98AB"/>
                </svg>                    
            </span>
        </div>

        <div style="margin: 0 15px;"><span class="comments-du-dom">Comments: <span style="color: #101D42;">{{ this.selectedKpi.length }}</span></span></div>
        
        <div class="card-body du-cmt-div-scrollbar" style="font-family: 'Poppins';height: 300px;overflow-y: auto;margin: 0 5px;padding-bottom: 0;box-shadow: none;padding: 0 20px;">
            <div *ngFor="let cmt of allcommentData; let idx = index;" class="my-2" #scrollItem>
                <div class="parentOfspan-du">
                    <hr style="border-color: #c0c0c0">
                    <span class="spanOfDash-du"> {{ cmt.key }} </span>
                </div>
                <div *ngIf="cmt.value?.length>0">
                    <div *ngFor="let subCmt of cmt.value; let i = index;" class="mt-2" #scrollItem [id]="subCmt.employee_key+i">

                        <div class="d-flex">
                            <div class="d-flex" style="width: 100%;">
                                <div class="mr-2"><img class="imageCss imageCss1" style="margin-top: 5px;" src="assets/man.png" alt="Profile"></div>
                                <div style="background-color: #F6F8FB;min-width: 265px;width: 100%;max-width: 450px;padding: 5px;border-radius: 10px;">
                                    <div class="d-flex justify-content-between">
                                        <span class="card-title fontForHeader" style="margin-bottom: 0;white-space: nowrap;">
                                            <span style="font-family: 'Poppins'!important;font-size: 12px;">{{ subCmt.employee_name || "No UserName"}}</span>
                                            <span style="font-family: 'Poppins'!important;font-size: 12px;" *ngIf="subCmt?.ops_verify_flg">
                                                • <span style="font-weight: 500;color: #70778E;">{{ subCmt.ops_verify_flg == 'Y' ? 'Verified' : 'Unverify' }}</span>
                                            </span>
                                            <span *ngIf="employee_key._value == subCmt.employee_key" style="color: #1363DF;font-size: 10px;margin-left: 3px;">(You)</span>
                                            <span *ngIf="editedChanges(subCmt.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 9px;margin-left: 2px;">{{' Edited'}}</span>
                                        </span>
                                        <span class="card-subtitle" style="white-space: nowrap;">{{ getTimeStamp(subCmt) }}</span>
                                    </div>

                                    <div style="font-size: 12px;font-family: 'Poppins'!important;">
                                        <p class="card-text" style="margin-bottom: 0 !important;" *ngIf="!edit || editIndex!=i || groupEditIndex!=idx" [innerHtml]="getMSGHtml(subCmt.comments)"></p>
                                        
                                        <textarea  *ngIf="edit && editIndex==i && groupEditIndex==idx" 
                                            rows="4" 
                                            cols="33" 
                                            class="firstTabinIRScrolls"
                                            style="border: 1px solid rgb(221, 221, 221);border-radius: 10px;padding: 5px;width: 100%;" 
                                            [(ngModel)]="editedValue" 
                                            maxlength="250" 
                                            type="text" 
                                            id="edit"
                                            (keydown.enter)="updateMessage(subCmt, i, idx)"
                                            [value]="subCmt.comments">
                                        </textarea>

                                        <div *ngIf="edit && editIndex==i && groupEditIndex==idx" 
                                            style="width: 100%;font-size: 9px;line-height: 0px;margin-top: 4px;text-align: right;">
                                            {{editedValue.length+'/250'}}
                                        </div>
                                        
                                        <div style="width: 100%;display: flex;justify-content: flex-end;">
                                            <span *ngIf="edit && editIndex==i  && groupEditIndex==idx && editedValue==''" 
                                                style="color: #d02e2e;margin: auto;"
                                            >
                                                Please type a message to continue
                                            </span>
                                        
                                            <button mat-icon-button 
                                                *ngIf="edit && editIndex==i && groupEditIndex==idx" 
                                                color="primary"
                                                (click)="updateMessage(subCmt, i, idx)">
                                                    <mat-icon>check</mat-icon>
                                            </button>

                                            <button mat-icon-button 
                                                *ngIf="edit && editIndex==i && groupEditIndex==idx" 
                                                color="primary"
                                                (click)="edit=false">
                                                    <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <mat-icon 
                                    *ngIf="employee_key._value == subCmt.employee_key"
                                    style="cursor: pointer;margin-right: -10px;margin-top: 5px;width: 20px;height: 20px;"
                                    svgIcon="menu" (click)="getMenu($event, subCmt, i, idx)">
                                </mat-icon>
                            </div>

                            <ng-template #customTemplate let-options="options" >
                                <button mat-menu-item mat-dialog-close (click)="editComment(subCmt,$event,i)">
                                    <mat-icon style="width: 20px;height: 20px;" svgIcon="edit"></mat-icon>  <span style="font-family: 'poppins';font-size: 12px;">Edit</span>
                                </button>
                                <button mat-menu-item mat-dialog-close *ngIf="!commentData['data'].ops_verify_flg" (click)="deleteDialog('300ms', '150ms')">
                                  <mat-icon style="width: 20px;height: 20px;" svgIcon="delete"></mat-icon> <span style="font-family: 'poppins';font-size: 12px;">Delete</span>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center m-3 mb-4 du-parent-ops-input-icon">
            <div class="mr-2"><img class="imageCss imageCss1" src="assets/man.png" alt="Profile"></div>
            <div style="position: relative;width: 100%;">
                <mat-form-field class="custom-form-field" [class.du-custom-form-field-validation]="!this.searchValue && this.validationForForm" style="width:100%;" appearance="outline">
                    <textarea 
                        matInput
                        id="comment-du-ops"
                        class="firstTabinIRScrolls"
                        cdkTextareaAutosize
                        placeholder="Add comment here"
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        [(ngModel)]="searchValue" maxlength="250"
                        (ngModelChange)="validationForm($event)"
                        (keydown.enter)="insertMessage(searchValue ,$event)"
                        cdkAutosizeMaxRows="3">
                    </textarea>
                </mat-form-field>
                <span style="position: absolute;top: 35px;left: 0;font-size: 12px;color: #D11044;" *ngIf="!this.searchValue && this.validationForForm">
                    This field is mandatory
                     <!-- in case of changing the status -->
                </span>
            </div>
            <span class="svg-du-ops-input-icon" style="padding: 0 5px;" (click)="insertMessage(searchValue ,$event)" matTooltip="Send">
                <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4995 13.5002L20.9995 3.00017M10.6271 13.8282L13.2552 20.5862C13.4867 21.1816 13.6025 21.4793 13.7693 21.5662C13.9139 21.6415 14.0862 21.6416 14.2308 21.5664C14.3977 21.4797 14.5139 21.1822 14.7461 20.5871L21.3364 3.69937C21.5461 3.16219 21.6509 2.8936 21.5935 2.72197C21.5437 2.57292 21.4268 2.45596 21.2777 2.40616C21.1061 2.34883 20.8375 2.45364 20.3003 2.66327L3.41258 9.25361C2.8175 9.48584 2.51997 9.60195 2.43326 9.76886C2.35809 9.91354 2.35819 10.0858 2.43353 10.2304C2.52043 10.3972 2.81811 10.513 3.41345 10.7445L10.1715 13.3726C10.2923 13.4196 10.3527 13.4431 10.4036 13.4794C10.4487 13.5115 10.4881 13.551 10.5203 13.5961C10.5566 13.647 10.5801 13.7074 10.6271 13.8282Z" stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                    
            </span>
            <mat-icon [matTooltip]="verify_checked_flg ? 'Unverify & Send' : 'Verify & Send'" style="overflow: visible;color: rgb(58, 201, 124);cursor: pointer;" (click)="insertMessageWithVerify(searchValue ,$event)">save_outline</mat-icon>
            <div style="font-size: 9px;" class="searchValue-du-chractors" >{{searchValue.length+'/250'}}</div>
        </div>

        <!-- <div class="card-header d-flex justify-content-between align-items-center" style="border-bottom: none;background: #F4F6F9;font-family: 'Poppins';">
            <span class="d-flex align-items-center">
                <span style="font-weight: 600;font-family: 'Poppins';margin: 0 15px 0 0;">{{ 'Verified' || ''}}</span>
                <mat-slide-toggle
                    class="example-margin-du"
                    [color]="color"
                    [disabled]="this.selectedKpi.length==0"
                    [(ngModel)]="verify_checked_flg">
                </mat-slide-toggle>
            </span>

            <span style="cursor: pointer;">
                <button mat-flat-button (click)="saveVerification(verify_checked_flg)" [disabled]="this.selectedKpi.length==0" class="du-msg-btn-confirm">Save</button>
            </span>
        </div> -->
    </div>
</mat-menu>
