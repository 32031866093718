import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from "d3";

@Component({
  selector: 'app-spark-line-bar-grid',
  templateUrl: './spark-line-bar-grid.component.html',
  styleUrls: ['./spark-line-bar-grid.component.scss']
})
export class SparkLineBarGridComponent implements OnInit {
  sparkLineBarData:any;
  props: any;
  divId: any = "sparkLineBarChart";
  @Input('dynamicId') dynamicId: any 
  @Input('maxValue') maxValue: any
  @Input('data') data: any
  @Input('type') type: any

  constructor() { }
  @ViewChild('ComborBar', { static: true }) ComborBar!: ElementRef;
  ngOnInit(): void {
    this.divId = this.dynamicId
    this.initiateCharts();
    this.getSparkLineBarChartData();
  }
  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    d3.select(this.ComborBar.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'transparent')
      .style('margin-left', '8px');
      
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = 180 || mySvg.node().getBoundingClientRect().width;
    const barHeight = 16;
    const margins = { left: 0, right: 0, top: 0, bottom: 0, label: 55, value: 27};
    const height = (myChart.props.barVars.length * barHeight) + margins.top;
    const numberFormat = d3.format(this.props.numberFormat);

    mySvg.attr("height", height);

    let barData: any = [];
    let maxFailed = 0, maxOther = 0;
    myChart.props.barVars.forEach((d: any, i: any) => {
      const myStack = d3.stack()
        .keys(d)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)
        ([myChart.sparkLineBarData]);
      maxFailed = Math.max(maxFailed,myChart.sparkLineBarData[d[0]])
      maxOther = Math.max(maxOther,myChart.sparkLineBarData[d[1]])
      myStack.map((m: any, index: any) => m.fill = myChart.props.barColors[i][index])
      myStack.map((m: any, index: any) => m.borderRadius = index === (myStack.length - 1) ? 1 : 0)
      barData.push({
        label: myChart.props.barLabels[i],
        data: myStack
      })
    })

    margins.right = measureWidth(isNaN(maxOther) ? "" : maxOther,14) - (isNaN(maxOther) ? 0 : 3)
   //(measureWidth(isNaN(maxFailed) ? "" : "(" + maxFailed + ")",14));
    margins.value = measureWidth(isNaN(maxFailed) ? "Not Available" : "",14)
      + margins.right + measureWidth(isNaN(maxFailed) ? "" : "(" + maxFailed + ")",12);

    const xScale = d3.scaleLinear()
      .domain([0, myChart.props.maxValue])
      .range([0, width - margins.left - margins.label - margins.value]);

    barData.forEach((d: any) =>
      d.data.forEach((m: any, i: any) => {
        m.start = xScale(m[0][0]);
        m.width = xScale(m[0][1] - m[0][0]);
        if(m.width < 1 && (m[0][1] - m[0][0]) > 0) {
          m.width = 1;
          d.data.filter((f: any, index: any) => index > i).forEach((e: any) => {
            e.extraX = (1 - xScale(m[0][1] - m[0][0]));
          })
        }
      }));

      const barGroup = mySvg
        .selectAll('.barGroup' + myClass)
        .data(barData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'barGroup' + myClass);
          enter.append('text').attr('class', 'barLabel');
          enter.append('g').attr('class', 'barStackGroup');
          return enter;
        });

      barGroup.select('.barLabel')
        .attr('font-family', 'Poppins')
        .attr('fill', '#6C758F')
        .attr('font-size', '10px')
        .attr('y', (barHeight/2) + 2)
        .attr('font-weight', '500')
        .text((d: any) => d.label + ':')

      barGroup
        .attr('transform', (d: any, i: any) => 'translate(' + (margins.left) + ',' + (margins.top + (i * barHeight)) + ')')

      const barStackGroup = barGroup.select('.barStackGroup')
        .selectAll('.barStackGroupItems' + myClass)
        .data((d: any) =>  d.data)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'barStackGroupItems' + myClass);
          enter.append('path').attr('class', 'barRect');
          enter.append('text').attr('class', 'barValue');
          return enter;
        });


      barStackGroup.select('.barRect')
        .attr("d", (d: any) =>  "M0,0 L"
          + (d.width - d.borderRadius) +
          ",0 Q"+ d.width
          + ",0  " + d.width + " " + d.borderRadius + " L " + d.width
          + " " + (8 - d.borderRadius) + " Q " + d.width + ", 8 "
          + (d.width - d.borderRadius) +
          ",8 L 0,8 Z")
        .attr('transform',  (d: any) => "translate(" + (margins.left + margins.label
        + d.start + (d.extraX === undefined ? 0 : d.extraX)) + "," + ( (barHeight/2) - 5) + ")")
        .attr('fill', (d: any) => d.fill)
        .attr('stroke-width', 0);

      barStackGroup.select('.barValue')
        .attr('x', (d: any) =>  width - (margins.right + 4)- (margins.left + 4) + (d.index === 1 ? 1 : -1))
        .attr('y', (barHeight/2) + 2)
        .attr('font-family', 'Poppins')
        .attr('fill', (d: any) => d.index === 0 && isNaN(d[0].data[d.key]) === false ? d.fill : '#101D42')
        .attr('font-size', (d: any) => d.index === 0 ? 8 : 10)
        .attr('text-anchor', (d: any) => d.index === 1 ? "start" : "end")
        .attr('font-weight', '500')
        .text((d: any) => isNaN(d[0].data[d.key]) ? (d.index === 1 ? "" :'Not Available') :  d[0].data[d.key] === 0  && d.index === 0 ? ""
          : (d.index === 0 ? "(" : "") + numberFormat(d[0].data[d.key]) + (d.index === 0 ? ")" : "") );

      function measureWidth(myText: any, myFontSize: any): any {
        const context: any = document.createElement('canvas').getContext('2d');
        context.font = myFontSize + 'poppins';
        return context.measureText(myText).width;
      }
    }
// property & data for the chart
    getSparkLineBarChartData(){
      this.sparkLineBarData= this.data
      this.props = {
        chartHeight: 64,
        barVars:this.type=='normal'?[["inventory_failed_quantity","inventory_quantity"],["dispense_failed_quantity","dispense_quantity"]]:[["inventory_failed_quantity","inventory_cumulative_qty"],["dispense_failed_quantity","dispense_cumulative_qty"]],
        barLabels:this.type=='normal'?["Inventory","Shipment"]:["IQC","DQC"],
        barColors:[["#D11044","#1363DF"],["#D11044","#EF933E"]],//"#645DD7" inventory
        maxValue : this.maxValue || 12000 , numberFormat: ","     }
      // @ts-ignore
      if (this.sparkLineBarData !== undefined){
        this.plotChart();
      }
  }
 
}
