import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppLayoutModule } from "../layout/app-layout.module";
import { DashboardRoutingModule } from "./dashboard.routing.module";
import { WidgetModule } from "../widget/widget.module";
import { MatComponentsModule } from "../../mat-components.module";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WorkDisplayComponent } from "./work-display-component/work-display.component";
import { AgGridModule } from "ag-grid-angular";
import { TopnavComponent } from "./topnav/topnav.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DashboardComponent } from "./dashboard.component";
import { PagesComponent } from "./pages/pages.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { SharedComponentModule } from "../shared/shared.module";
import { ChartsModule } from "../shared/charts/charts.module";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { TruncatePipe } from "src/app/pipe/dot-truncate";
import { MatTabsModule } from "@angular/material/tabs";
import { WidgetDirective } from "../widget/widget.directive";
import { FilterButton } from "../widget/filter-button/filter-button";
@NgModule({
  declarations: [
    WorkDisplayComponent,
    TopnavComponent,
    DashboardComponent,
    TruncatePipe,
    PagesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    MatComponentsModule,
    AppLayoutModule,
    DashboardRoutingModule,
    NgMultiSelectDropDownModule,
    WidgetModule,
    MatDatepickerModule,
    FlexLayoutModule,
    OverlayModule,
    MatExpansionModule,
    MatIconModule,
    SharedComponentModule,
    ChartsModule,
    MatAutocompleteModule,
    MatMenuModule,
    NgSelectModule,
    MatTabsModule,
    NgMultiSelectDropDownModule.forRoot(),
    WidgetDirective,
    FilterButton
  ],
  exports: [
    DashboardRoutingModule,
    WorkDisplayComponent,
    TopnavComponent,
    WidgetModule,
    OverlayModule,
    PagesComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule {}
