import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { PipelineUpdatePopupComponent } from '../pipeline-update-popup/pipeline-update-popup.component';
import * as d3 from 'd3';
import { Overlay, OverlayContainer, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-accordin-column',
  templateUrl: './accordin-column.component.html',
  styleUrls: ['./accordin-column.component.scss']
})
export class AccordinColumnComponent implements OnInit {
  @ViewChild("patientJourney", { static: true }) _dialogTemplate!: TemplateRef<any>;
  private _overlayRef!: OverlayRef;
  private _portal!: TemplatePortal;
  tooltipComponent:any = PipelineUpdatePopupComponent;
  @Input('option') option: any;
  @Input('selectedColumn') selectedColumn: any;
  @Input('item') item: any;
  findData:any;
  clientKey:any
  moduleKey:any
  dashboardKey:any
  cardName:any
  cardId:any;
  alertsColumn:any;
  alertsData:any;
  test =false
  routerQueryParam:any

  constructor(
    public filterService:FilterService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private overlayContainer: OverlayContainer,
    private _overlay: Overlay, 
    private _viewContainerRef: ViewContainerRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {
      this.filterService.hideTools.subscribe((r:any)=> {
        this._overlayRef.detach();
      })
    }
    
  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      this.clientKey = p["clientKey"];
      this.moduleKey = p["moduleKey"];
      this.dashboardKey = p["dashboardKey"];
      this.cardName = p["cardName"];
      this.cardId = p["cardId"];

      if(this.cardName=='pwt') {
        this.route.queryParams.subscribe((params:any) => {
          this.routerQueryParam = params
        })
      }

    })

    this.createTooltip()
    
    this.findData = this.item.list_of_api_col.find((res:any)=> res.api_resp_column_nm == this.selectedColumn)
    if(this.findData) {
      if(this.findData.isActionColumn) {
        this.alertsColumn = this.findData.api_resp_column_nm
        let alertsData = this.option[this.alertsColumn]
        if(alertsData) {
          this.alertsData = JSON.parse(this.option[this.alertsColumn])

          this.alertsData.forEach((s:any)=> {
            let filter = this.findData.alertSelectionPosibleValues.filter((re:any) => re.isSelect == true)
            let find = filter.find((a:any)=> a.col_value == s.Alert_Type)
            if(find) {
              Object.assign(s, find)
            }
          })

        }
      }
    }

    setTimeout(() => {
      this.test = true
    }, 200);

}


  clicktoNavigation(option:any, selectedColumn:any) {
    let refreshData:any = this.item.list_of_api_col.find((res:any)=> res.api_resp_column_nm == selectedColumn)
    if(refreshData) {
      if(refreshData.isLinkCol) {
        refreshData.linkSelectionPossibleValues.forEach((m:any)=> {
          if(m.page_nm == refreshData.isLinkColCard) {
            
            Object.assign(m, {config: refreshData.margingLinkColName})
            sessionStorage.setItem("navbar_prName", option[selectedColumn]);
            // this.filterService.prName = option[selectedColumn]
            if(refreshData.isLinkColCard.toLowerCase() == "patient card") {
              sessionStorage.setItem("navLinkPaCard", JSON.stringify(m));
              this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/${'paCard'}/${option[refreshData.selectNavigatePart]}`], { state: { message: m } });
            } else {
              sessionStorage.setItem("navLinkPrCard", JSON.stringify(m));

              if(this.cardName=='pwt') {
                let query = {
                  pageName: "Prescriber Card",
                  presId: option[refreshData.selectNavigatePart]
                }
                
                let a = Object.assign({}, this.routerQueryParam, query)

                this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/pwt/${'prCard'}`],{ queryParams: a, queryParamsHandling: 'merge', skipLocationChange: false });
              } else {
                this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}/${'prCard'}/${option[refreshData.selectNavigatePart]}`]);
              }
            }
          }
        })
      }
    }
  }
  highlightSearchText(data:any) {

  }
  
  toolTipss = false

  matIconClick(event:any,a:any) {
    this.filterService.refreshfromAccodin = true
    this.filterService.alertsIconfromPatientCard = false
    this.filterService.toolTipAlertData = a
    this.show()
  }

  trackByFn(index: number, row: any): number {
    return row.id;
  }

  hideTooltip(): void {
    this.toolTipss = false
    d3.select("#d3LineTooltip")
      .style('visibility', 'hidden');
  }


  createTooltip(){
    const position = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 8,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -8,
        }
    ]);
    this._overlayRef = this._overlay.create({
      positionStrategy: position,
      scrollStrategy: this._overlay.scrollStrategies.close(),
      panelClass: 'custom-tooltip',

    });
  }

  show(): void {
      this.filterService.hideTools.next(true)
      let containerPortal: TemplatePortal<any>;
      containerPortal = new TemplatePortal(this._dialogTemplate, this._viewContainerRef);
      this._overlayRef.attach(containerPortal);
  }
}
