import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FilterService } from '../services/filter.service';

@Directive({
  selector: '[noENotationInput]'
})
export class NoENotationInputDirective {
  private readonly minValue: number = 1; // Minimum value, set to 0
  private readonly maxValue: number = 100; // Maximum value, set to 100

  constructor(private el: ElementRef, private filterService:FilterService) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove any non-numeric characters except the minus sign and dot
    const numericValue = value.replace(/[^\d.-]/g, '');

    // Check if the resulting value is a valid number
    const num = Number(numericValue);
    if (!isNaN(num)) {
      this.validateAndSetInput(num);
    } else {
      // Clear the input if the value is invalid
      this.el.nativeElement.value = '';
    }
  }

  private validateAndSetInput(num: number) {
    // Ensure the number is within the specified range
    const clampedNum = Math.min(Math.max(num, 0), this.maxValue, 100);

    // Update the input value with the validated number
    this.el.nativeElement.value = clampedNum.toString();

    if(clampedNum.toString() == '0') {
      this.filterService.inputValueOfPagenation.next(false)
    } else {
      this.filterService.inputValueOfPagenation.next(true)
    }
    // Update the input element's attributes with min and max values
    this.el.nativeElement.min = this.minValue.toString();
    this.el.nativeElement.max = this.maxValue.toString();
  }
}