<div class="widget-container" #wordCloud>
    <!-- <app-word-cloud-chart 
    style="width: 100%;"
    [config]="config"
    [headerConfig]="this.item.config.widget_header_config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
  
    >
    </app-word-cloud-chart> -->
    <app-horizontal-bar-chart
    style="width: 100%;"
    [config]="config"
    [headerConfig]="this.item.config.widget_header_config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
    ></app-horizontal-bar-chart>
</div>
