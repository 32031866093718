import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';


@Component({
  selector: 'app-validation-input',
  template: `
  <style>
    .numeric-input {
      box-sizing: border-box;
      padding-left: var(--ag-grid-size);
      width: 200px;
      height: 46px;
      border: 2px solid #ddd;
      border-radius: 10px;
    }
  </style> 

<div class="input-container">
  <input #input (keydown)="onKeyDown($event)" (input)="onInput($event)" [(ngModel)]="value" class="numeric-input"/>
</div>

  `,
})

export class ValidationInputComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value!: string;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  onloadValue:any;

  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    const isCharacter = params.eventKey && params.eventKey.length === 1;
    this.cancelBeforeStart = !!(
      isCharacter && '1234567890'.indexOf(params.eventKey!) < 0
    );
  }

  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;
    const eventKey = params.eventKey;

    if (eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (eventKey && eventKey.length === 1) {
      // if a letter was pressed, we start with the letter
      startValue = eventKey;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.onloadValue = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue():any {
    return this.value;
  }

  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.isBackspace(event) || this.isDot(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.finishedEditingPressed(event) && !this.isNumericKey(event)) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  onInput(event: any): void {
    if(event.target.value) {
      this.value = event.target.value;
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();

        this.highlightAllOnFocus = false;
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }

      this.input.element.nativeElement.focus();
    });
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isNumericKey(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  private isBackspace(event: any) {
    return event.key === KEY_BACKSPACE;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private isDot(event: any) {
    return event.key === '.';
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
