
import moment from 'moment';

import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';


@Component({
  selector: 'heatmap-chart',
  templateUrl: './heat-map-chart.component.html',
  styleUrls: ['./heat-map-chart.component.scss']
})


export class HeatMapChartComponent implements OnInit, OnChanges, AfterViewInit {
  heatMapData: any;
  divId: any = 'heatMapDiv';
  width: number = 0;
  height: number = 0;

  constructor(private host:ElementRef) { }

  @Input('chartData') chartData: any;
  @Input('heading') heading:string = ''


  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('heatMapDiv',{static:true}) heatMapDiv!:ElementRef


  @Input('x_axis_column') x_axis_column:any
  @Input('color_column') color_column:any
  @Input('value_column') value_column:any
  @Input('hierarchyLevels') hierarchyLevels:any[] = []

  ngOnInit(): void {
    const observer = new ResizeObserver((e:any) => {
        this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.heatMapDiv.nativeElement);
    this.initiateCharts();
  }

  ngAfterViewInit(): void {
     // this.heatMapData = this.chartData;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['chartData']) {
        this.heatMapData = changes['chartData'].currentValue;

        this.rerenderGraph();
      }
  }



  refreshGraph(data: any) {
    d3.selectAll('#heatMapheatMapDiv').remove();
    this.heatMapData = data;
    if (this.heatMapData) {
      this.renderHeatMap();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderHeatMap();
  }
  rerenderGraph() {
    if (this.heatMapData) {
        this.initiateCharts();
        this.renderHeatMap();
    }
  }

  showTooltip(panelData: any, myX: any, myY: any): void {
  }

  hideTooltip(): void {
  }
// chart svg  plotChart rendering 
  renderHeatMap(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#heatMap' + myClass);
    const margin = { left: 10, right: 10, top: 115, bottom: 10 };
    const width = mySvg.node().getBoundingClientRect().width;
    const collapseIconPath = 'M1.25 3.13215V1.25024H18.75V3.13215H1.25ZM7.79999 6.85371L6.87747 7.77623L10 10.8988L13.1225 7.77623L12.2 6.85371L10.6495 8.40425L10.6514 4.41187H9.34858L9.35053 8.40425L7.79999 6.85371ZM3.125 14.0237V16.5237H16.875V14.0237H3.125ZM1.25 12.1487V18.3987H18.75V12.1487H1.25Z';
    const expandIconPath = 'M16.875 3.125H3.125V5.625H16.875V3.125ZM1.25 1.25V7.5H18.75V1.25H1.25ZM7.79999 14.9711L6.87747 15.8937L10 19.0162L13.1225 15.8937L12.2 14.9711L10.6495 16.5217L10.6514 12.5293H9.34858L9.35053 16.5217L7.79999 14.9711ZM1.25 11.2496V9.36768H18.75V11.2496H1.25Z';
    const expandCollapseViewBox = '0 0 20 20';
    const keyIconPath = 'M11.999 9.28662H2.00011C1.52673 9.28718 1.07291 9.47547 0.738179 9.8102C0.403452 10.1449 0.215157 10.5988 0.2146 11.0721V16.0716C0.215781 17.113 0.630015 18.1114 1.36643 18.8479C2.10284 19.5843 3.10128 19.9985 4.14272 19.9997H9.85636C10.8978 19.9985 11.8962 19.5843 12.6327 18.8479C13.3691 18.1114 13.7833 17.113 13.7845 16.0716V11.0721C13.7839 10.5988 13.5956 10.1449 13.2609 9.8102C12.9262 9.47547 12.4723 9.28718 11.999 9.28662ZM8.07085 14.511V16.7858C8.07085 16.8805 8.03322 16.9713 7.96625 17.0383C7.89928 17.1052 7.80845 17.1429 7.71375 17.1429H6.28534C6.19063 17.1429 6.0998 17.1052 6.03283 17.0383C5.96586 16.9713 5.92823 16.8805 5.92823 16.7858V14.511C5.74641 14.3049 5.62794 14.0506 5.58703 13.7788C5.54613 13.5069 5.58453 13.2291 5.69763 12.9785C5.81074 12.728 5.99373 12.5154 6.22466 12.3663C6.45559 12.2171 6.72465 12.1378 6.99954 12.1378C7.27443 12.1378 7.54349 12.2171 7.77442 12.3663C8.00535 12.5154 8.18834 12.728 8.30145 12.9785C8.41455 13.2291 8.45295 13.5069 8.41205 13.7788C8.37114 14.0506 8.25267 14.3049 8.07085 14.511Z';
    const keyIconPath2 = 'M6.9996 0.00177002C5.57944 0.00334849 4.2179 0.568203 3.2137 1.57241C2.2095 2.57661 1.64464 3.93815 1.64307 5.3583V8.57222H3.78568V5.3583C3.78568 4.50592 4.12429 3.68845 4.72701 3.08572C5.32974 2.48299 6.14721 2.14438 6.9996 2.14438C7.85198 2.14438 8.66946 2.48299 9.27218 3.08572C9.87491 3.68845 10.2135 4.50592 10.2135 5.3583C10.2135 5.45301 10.2511 5.54384 10.3181 5.61081C10.3851 5.67778 10.4759 5.7154 10.5706 5.7154H11.999C12.0937 5.7154 12.1846 5.67778 12.2515 5.61081C12.3185 5.54384 12.3561 5.45301 12.3561 5.3583C12.3546 3.93814 11.7897 2.57658 10.7855 1.57237C9.78132 0.568165 8.41976 0.00332019 6.9996 0.00177002Z';
    const keyIconPath3 = 'M14.999 9.28662H5.00011C4.52673 9.28718 4.07291 9.47547 3.73818 9.8102C3.40345 10.1449 3.21516 10.5988 3.2146 11.0721V16.0716C3.21578 17.113 3.63002 18.1114 4.36643 18.8479C5.10284 19.5843 6.10128 19.9985 7.14272 19.9997H12.8564C13.8978 19.9985 14.8962 19.5843 15.6327 18.8479C16.3691 18.1114 16.7833 17.113 16.7845 16.0716V11.0721C16.7839 10.5988 16.5956 10.1449 16.2609 9.8102C15.9262 9.47547 15.4723 9.28718 14.999 9.28662V9.28662ZM11.0708 14.511V16.7858C11.0708 16.8805 11.0332 16.9713 10.9663 17.0383C10.8993 17.1052 10.8085 17.1429 10.7137 17.1429H9.28534C9.19063 17.1429 9.0998 17.1052 9.03283 17.0383C8.96586 16.9713 8.92823 16.8805 8.92823 16.7858V14.511C8.74641 14.3049 8.62794 14.0506 8.58703 13.7788C8.54613 13.5069 8.58453 13.2291 8.69763 12.9785C8.81074 12.728 8.99373 12.5154 9.22466 12.3663C9.45559 12.2171 9.72465 12.1378 9.99954 12.1378C10.2744 12.1378 10.5435 12.2171 10.7744 12.3663C11.0053 12.5154 11.1883 12.728 11.3014 12.9785C11.4145 13.2291 11.453 13.5069 11.412 13.7788C11.3711 14.0506 11.2527 14.3049 11.0708 14.511V14.511Z';
    const keyIconPath4 = 'M13.7861 1.57118C12.7819 0.566982 11.4204 0.00212779 10.0002 0.000549316C8.58005 0.00209949 7.2185 0.566945 6.21429 1.57115C5.33448 2.45096 4.79192 3.60507 4.66996 4.83257C4.65263 5.00653 4.64375 5.18196 4.64355 5.3583V8.57222H6.78617V5.36681C6.78626 5.36357 6.7863 5.36033 6.7863 5.35708C6.7863 5.24242 6.79243 5.12838 6.8045 5.01542C6.88241 4.2891 7.20615 3.60707 7.7275 3.08572C8.33023 2.48299 9.1477 2.14438 10.0001 2.14438C10.8525 2.14438 11.6699 2.48299 12.2727 3.08572C12.8754 3.68845 13.214 4.50592 13.214 5.3583C13.214 5.36155 13.214 5.3648 13.2141 5.36804V8.571H15.3568V5.35708C15.3552 3.93693 14.7903 2.57539 13.7861 1.57118Z';


    const keyIconViewBox = '0 0 14 20';
    const sortIconPath = 'M4.80018 0.470105L8.30022 5.13686C8.58765 5.51929 8.31442 6.06996 7.83364 6.06996H6.07585V17.1534C6.07585 17.4759 5.82273 17.7368 5.5003 17.7368H3.16696C2.84453 17.7368 2.58362 17.4759 2.58362 17.1534V6.06996H0.833581C0.353224 6.06996 0.0792901 5.51968 0.36703 5.13686L3.86708 0.470105C4.08696 0.176143 4.5803 0.176143 4.80018 0.470105ZM15.4092 11.9165H17.167C17.6477 11.9165 17.921 12.4671 17.6335 12.8496L14.1335 17.5163C13.9009 17.8272 13.4333 17.8276 13.2004 17.5163L9.70031 12.8495C9.41257 12.4667 9.68646 11.9164 10.1669 11.9164H11.9169V0.832968C11.9169 0.51054 12.1778 0.249634 12.5002 0.249634H14.8336C15.156 0.249634 15.4092 0.51054 15.4092 0.832968V11.9165Z';
    const sortIconViewBox = '0 0 18 18';
    const rightArrowViewBox = '0 0 20 20';
    const rightArrowPath = 'M18.6364 8.18184H11.8182V1.36364C11.8182 0.610908 11.2073 0 10.4545 0H9.54548C8.79275 0 8.18184 0.610908 8.18184 1.36364V8.18184H1.36364C0.610908 8.18184 0 8.79275 0 9.54548V10.4545C0 11.2073 0.610908 11.8182 1.36364 11.8182H8.18184V18.6364C8.18184 19.3891 8.79275 20 9.54548 20H10.4545C11.2073 20 11.8182 19.3891 11.8182 18.6364V11.8182H18.6364C19.3891 11.8182 20 11.2073 20 10.4545V9.54548C20 8.79275 19.3891 8.18184 18.6364 8.18184Z';
    const downArrowViewBox = '0 0 20 20';
    const downArrowPath = 'M14.9091 1.85966e-05H9.45453C9 4.32323e-05 8.9658 0 8.36362 0L7.63638 1.85966e-05C7.0342 1.85966e-05 7 1.85967e-05 6.54547 1.85966e-05H1.09091C0.488726 1.85966e-05 0 0.488746 0 1.09093V1.81816C0 2.42035 0.488726 2.90907 1.09091 2.90907H6.54547C7 2.90907 7.0342 2.90929 7.63638 2.90929H8.36362C8.9658 2.90929 9 2.90907 9.45453 2.90907H14.9091C15.5113 2.90907 16 2.42035 16 1.81816V1.09093C16 0.488746 15.5113 1.85966e-05 14.9091 1.85966e-05Z';
    let chartDrawing = false;
    const dateCol = this.x_axis_column

    let legendIconStatus = 'HIDE';
    let dateSortStatus = 'ascending';

    this.heatMapData.properties = {
        requiredDateFormat: "%d %b",
        requiredDayFormat: '%a',
        hierarchyLevels: this.hierarchyLevels,
        mostRecentDateColor: '#D0E0F9',
        notInColorSet: ['Not Expected'],
        colorVar: this.color_column,
        numberVar: this.value_column,
        dateVar: this.x_axis_column
    };

    const myProperties: any = myChart.heatMapData.properties;
    this.heatMapData.data.map((m: any) => m.date = m[myProperties.dateVar]);

    // depth width + height for hierarchy elements
    const depthWidth = 40;
    const rowHeight = 28;

    let legendCircleRadius = 10;
    
    if(window.innerWidth< 992) {
      legendCircleRadius = 7 

    }
    
    let legendX = legendCircleRadius;
    // define date set
    const dateFormat = d3.timeFormat(myProperties.requiredDateFormat);
    let dateSet: any = new Set();

    myChart.heatMapData.data.forEach((d: any) => dateSet.add(d[myProperties.dateVar]));

    dateSet = Array.from(dateSet);
    dateSet = dateSet.sort((a: any, b: any) => d3.ascending(convertDate(a), convertDate(b)));
    // define color scale
    const statusArray: any = [];
    const statusIDArray: any = [];
    const colorArray: any = [];
    const descriptionMap: any = {};
    const hierarchyLevelTypes: any = {};

    myChart.heatMapData.colors.forEach((d: any): void => {
      statusArray.push(d.status);
      statusIDArray.push(d.status.replace(/ /g,'').toLowerCase());
      colorArray.push(d.Hex);
      descriptionMap[d.status] = d.description;
    });
    let legendSelected: any = statusIDArray;
    const colorScale: any = d3.scaleOrdinal().domain(statusArray).range(colorArray);
    // build dictionary mapping values to hierarchy levels
    myProperties.hierarchyLevels.forEach(function(d: any): void {
      const levelSet = new Set();
      myChart.heatMapData.data.forEach((f: any) => levelSet.add(f[d]));
      Array.from(levelSet).forEach((a: any) => (hierarchyLevelTypes[a] = d));
    });
    // draw legend
    drawLegend(19);

    // prepare data
    let maxDepth: any = 0;
    let currentY = 0;
    let hierarchyData: any = buildHierarchyData();
    // building all Expanded and all Collapsed for icons to save process time.
    const baseHierarchy = JSON.parse(JSON.stringify(hierarchyData));
    // process repeated here as I cannot clone the hierarchy data format.
    hierarchyData = d3.hierarchy(hierarchyData);
    hierarchyData.descendants().forEach(function(d: any){
      if (d.depth >= 1 && d.children !== undefined){
        d._children = d.children;
        d.children = [];
      }
    });
    currentY = 0;
    addYValue(hierarchyData);
    let chartData = hierarchyData.descendants();
    chartData = chartData.filter((f: any) => f.depth > 0);

    maxDepth = d3.max(chartData, (d: any) => d.depth);
    let currentChartData: any = [];

    drawChart(chartData, 1000);

    function drawChart(myChartData: any, transitionTime: number): void {
      chartDrawing = true;
      currentChartData = myChartData;
      const newHeight = margin.top + margin.bottom + (myChartData.length + 1) * rowHeight;
     
      mySvg.interrupt().transition().duration(200).attr('height', newHeight);

      // dates header group
      const datesHeaderGroup = mySvg
        .selectAll('.datesHeaderGroup' + myClass)
        .data(dateSet)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'datesHeaderGroup' + myClass);
          enter.append('rect').attr('class', 'dateHeaderObject dateHeaderRect');
          enter.append('text').attr('class', 'dateHeaderObject dateHeaderLabel');
          return enter;
        });

      datesHeaderGroup
        .select('.dateHeaderRect')
        .attr('x', dateSortStatus === 'ascending' ? width : 0)
        .attr('y', -(rowHeight * 1.5) - 5)
        .attr('height', newHeight - margin.top - margin.bottom)
        // @ts-ignore
        .attr('fill', (d: any) => (convertDate(d) < d3.max(dateSet, (m: any) => convertDate(m))
          ? 'transparent' : myProperties.mostRecentDateColor))
        .attr('stroke-width', 0)
        .attr('stroke', myProperties.notInColorSet.length > 0 ? colorScale(myProperties.notInColorSet[0]) : 'black')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      datesHeaderGroup
        .select('.dateHeaderLabel')
        .attr('x', dateSortStatus === 'ascending' ? width : 0)
        .attr('y', -rowHeight / 2 - 15)
        .attr('text-anchor', 'middle')
        .attr('font-size', 12)
        .attr('font-weight', 700)
        .attr('fill', '#101D42')
        .text((d: any) => dateFormat(convertDate(d)))
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // hierarchy nodes group
      const nodeGroup = mySvg
        .selectAll('.nodeGroup' + myClass)
        .data(myChartData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'nodeGroup' + myClass);
          enter.append('line').attr('class', 'nodeLine');
          enter.append('circle').attr('class', 'nodeIconCircle');
          enter.append('svg').attr('class', 'nodeIconSvg').append('path').attr('class', 'nodeIcon');
          enter.append('rect').attr('class', 'nodeIconRect');
          enter.append('text').attr('class', 'nodeLabel');
          enter.append('g').attr('class', 'datesGroup');
          return enter;
        });

      nodeGroup.select('.datesGroup')
        .attr('transform', 'translate(' + (dateSortStatus === 'ascending' ? width : 0) + ',0)');

      nodeGroup.select('.nodeIconCircle')
        .attr('cx', (d: any) => (d.depth - 1) * depthWidth + margin.left + 8)
        .attr('cy', (d: any) => d.y + margin.top - (rowHeight / 2) - 1 + 8)
        .attr('fill', (d: any) => d.children === undefined ? 'transparent' : (d._children === undefined ? '#E8EAEE' : 'transparent'))
        .attr('pointer-events', 'none')
        .attr( 'r',  8);

      nodeGroup.select('.nodeIcon')
        .attr('fill', (d: any) => d._children === undefined ? '#8A98AB' : '#1363DF')
        .attr( 'd', (d: any) => d.children === undefined ? '' : (d._children === undefined ? downArrowPath : rightArrowPath));

      nodeGroup.select('.nodeIconRect')
        .attr('x', (d: any) => (d.depth - 1) * depthWidth + margin.left)
        .attr('y', (d: any) => d.y + margin.top - (rowHeight / 2) - 1 )
        .attr('fill', 'transparent')
        .attr('cursor', 'pointer')
        .attr( 'width', (d: any) => d.children === undefined ? 0 : 18)
        .attr( 'height', (d: any) => d.children === undefined ? 0 : 1818)
        .on('mouseover', function(event: any, d: any): void {
          if (d.depth < maxDepth) {
            // @ts-ignore
            d3.select(this).interrupt().transition().duration(100).attr('fill-opacity', 0.5);
          }
        })
        .on('mouseout', function(): void {
          // @ts-ignore
          d3.select(this).interrupt().transition().duration(50).attr('fill-opacity', 1);
        })
        .on('click', nodeClick);

      nodeGroup.select('.nodeIconSvg')
        .attr( 'pointer-events', 'none')
        .attr('x', (d: any) => (d.depth - 1) * depthWidth + margin.left + 2 + (d._children === undefined ? 1 : 0))
        .attr('y', (d: any) => d.y + margin.top - (rowHeight / 2)  + 1 + (d._children === undefined ? 5.5 : 0))
        .attr('viewBox',  (d: any) => d._children === undefined ? downArrowViewBox : rightArrowViewBox)
        .attr( 'width', (d: any) => d.children === undefined ? 0 : 12)
        .attr( 'height', (d: any) => d.children === undefined ? 0 : 12);

      nodeGroup
        .select('.nodeLine')
        .attr('y1', (d: any) => d.y + (d.children === undefined ? -8
          : (d.children.length > 0 && d.children[0].children === undefined ? -8 : 16 / 2 - 2)))
        .attr('y2', (d: any) => d.y + (d.children === undefined ? -8
          : (d.children.length > 0 && d.children[0].children === undefined ? d.children.length * rowHeight : 16 / 2 - 2)))
        .attr('stroke', (d: any) => d.children === undefined ? '#8A98AB'
          : (d.children.length > 0 && d.children[0].children === undefined ? '#8A98AB' : '#F0F3F6'))
        .attr('stroke-width', 1)
        .attr('x1', (d: any) => (d.depth - 1) * depthWidth - (d.children === undefined ? 32
          : (d.children.length > 0 && d.children[0].children === undefined ? -8 : 0)))
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      nodeGroup
        .select('.nodeLabel')
        .style('text-transform', 'capitalize')
        .attr('x',  (d: any) => d.children === undefined ? -15 :  25)
        .attr('y', (d: any) => 16 / 2 - (d.children === undefined ? 10.5 : 9))
        .attr('cursor', 'pointer')
        .attr('font-size', (d: any) => d.children === undefined ? 12 : 14)
        .attr('font-family', "Poppins")
        .attr('font-weight', 600)
        .text((d: any) =>   d.data.name)
        .attr(
          'transform',
          (d: any) =>
            'translate(' + ((d.depth - 1) * depthWidth + margin.left) + ',' + (d.y + margin.top) + ')'
        )
        .on('mouseover', function(event: any, d: any): void {
          if (d.depth < maxDepth) {
            // @ts-ignore
            d3.select(this).interrupt().transition().duration(100).attr('fill-opacity', 0.5);
          }
        })
        .on('mouseout', function(): void {
          // @ts-ignore
          d3.select(this).interrupt().transition().duration(50).attr('fill-opacity', 1);
        })
        .on('click', nodeClick);

      function nodeClick(event: any, d: any): void {
        const currentNode = hierarchyData
          .descendants()
          .find(
            (f: any) =>
              f.depth === d.depth &&
              d.data.name === f.data.name &&
              d.parent.data.name === f.parent.data.name
          );
        currentY = 0;
        updateHierarchy(hierarchyData, currentNode);
        chartData = hierarchyData.descendants();
        chartData = chartData.filter((f: any) => f.depth > 0);
        maxDepth = d3.max(chartData, (d: any) => d.depth);
        if (maxDepth === 1) {
          maxDepth = 2;
        }
        drawChart(chartData, 0);

      }

      // hierarchy dates group
      const datesGroup = nodeGroup
        .select('.datesGroup')
        .selectAll('.datesGroup' + myClass)
        .data(function(d: any) {
          const myValues = d.data.values;
          myValues.map((m: any) => m.y = d.y);
          return myValues;
        })
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'datesGroup' + myClass);
          enter.append('rect').attr('class', 'dateRect');
          enter.append('text').attr('class', 'dateRectNumber');
          enter.append('g').attr('class', 'splitRectGroup');
          return enter;
        });

      datesGroup.select('.splitRectGroup')
        .attr('transform',  'translate(' + (dateSortStatus === 'ascending' ? width : 0) + ',0)')

      datesGroup
        .select('.dateRect')
        .attr('x', dateSortStatus === 'ascending' ? width : 0)
        .attr('fill-opacity', 1)
        .attr(
          'class',
          (d: any) => 'dateRect dateRectItem ' + (typeof d[myProperties.colorVar] === 'object' ? '' : d[myProperties.colorVar].replace(/ /g, '').toLowerCase())
        )
        .on('mousemove', function(event: any, d: any): void {
          myChart.showTooltip(d, event.offsetX, event.offsetY);
        })
        .on('mouseout', function(): void {
          myChart.hideTooltip();
        })
        .attr('fill', (d: any) => {
          return typeof d[myProperties.colorVar] === 'object' ?
            (d[myProperties.colorVar].length > 1 ? 'transparent' : colorScale(d[myProperties.colorVar][0]))
            : colorScale(d[myProperties.colorVar])})
        .attr('cursor', 'pointer')
        .attr('y', (d: any) => (-rowHeight / 2)  + d.y + margin.top)
        .attr('height', 18);

      datesGroup
        .select('.dateRectNumber')
        .attr('x', dateSortStatus === 'ascending' ? width : 0)
        .attr('fill-opacity', 1)
        .attr(
          'class',
          (d: any) => 'dateRectNumber dateRectItem ' + (typeof d[myProperties.colorVar] === 'object' ? ''
            // : d[myProperties.colorVar])
            : d[myProperties.colorVar].replace(/ /g, '').toLowerCase())
        )
        .attr('pointer-events', 'none')
        .attr('y', (d: any) => (16 / 2 - 8) + d.y + margin.top)
        .attr('text-anchor', 'middle')
        .attr('font-size', '12')
        .attr('font-weight', 700)
        .attr('fill', 'white')
        .text((d: any) => (typeof d[myProperties.colorVar] === 'object' ? '' :  (d[myProperties.numberVar] === null ? "" : d3.format(",")(d[myProperties.numberVar]))));


      datesGroup
        .select('.splitRectGroup')
        .attr('fill', (d: any) => (typeof d[myProperties.colorVar] === 'object' ? 'transparent'
          : colorScale(d[myProperties.colorVar])));

      // hierarchy split dates group
      const splitRectGroup = datesGroup
        .select('.splitRectGroup')
        .selectAll('.splitRectGroup' + myClass)
        .data(function(d: any): any {
          if (typeof d[myProperties.colorVar] === 'object') {
            const myRects: any = [];
            d[myProperties.colorVar].forEach(function(c: any, i: any): void {
              myRects.push({
                myIndex: i,
                value: c,
                rectCount: d[myProperties.colorVar].length,
                y: d.y + (-rowHeight / 2)
              });
            });
            return myRects;
          } else {
            return [];
          }
        })
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'splitRectGroup' + myClass);
          enter.append('rect').attr('class', 'splitDateRect');
          return enter;
        });

      splitRectGroup
        .select('.splitDateRect')
        .attr('fill-opacity', 1)
        .attr('class', (d: any) => 'splitDateRect dateRectItem ' + d.value.replace(/ /g, '').toLowerCase())
        .attr('fill', (d: any) => colorScale(d.value))
        .attr('height', 18)
        .attr('y', (d: any) =>  d.y + margin.top)
        .attr('opacity', function(): any {
          // @ts-ignore
          const myItem: any = this;
          const matchingSelected = myItem.className.baseVal
            .split(' ')
            .filter((f: any) => legendSelected.indexOf(f) > -1);
          return matchingSelected.length === 0 ? 0 : 1;
        })
        .on('mousemove', function(event: any, d: any): void {
          myChart.showTooltip(d, event.offsetX, event.offsetY);
        })
        .on('mouseout', function(): void {
          myChart.hideTooltip();
        });

      // position and size of right hand elements dependent on maxRight position of hierarchy elements
      let maxRight: any = 0;
      let dateRectWidth = 0;

      // calculate max right
      d3.selectAll('.nodeLabel').each(function(): void {
        const myLabel: any = this;
        const myBounds: any = d3.select(myLabel).node().getBoundingClientRect();
        maxRight = Math.max(myBounds.right, maxRight);
      });
      maxRight -= margin.left + mySvg.node().getBoundingClientRect().x;
      maxRight += 5;

      d3.selectAll('.nodeLine')
        .attr('x2', function(d: any) {
          const x1:any = +d3.select(this).attr('x1');
          return d.children === undefined ? (x1 + 4)
            : (d.children.length > 0 && d.children[0].children === undefined ? x1 : maxRight + 5);
        });
      // define x scale and rect width
      const xScale: any = d3
        .scaleBand()
        .domain(dateSet)
        .range([0, width - margin.left - maxRight - 5 - margin.right]);

      dateRectWidth = xScale.bandwidth() - 8;

      function getDelay(myDate: any){
        const dateIndex = dateSet.indexOf(myDate);

        if(dateSortStatus === 'ascending'){
          return dateIndex * (transitionTime / 5);
        } else {
          return (dateSet.length - dateIndex) * (transitionTime / 5);
        }
      }

      d3.selectAll('.dateHeaderRect')
        .attr("opacity",0)
        .transition()
        .delay((d: any) => getDelay(d))
        .duration(transitionTime)
        .attr("opacity",1)
        .attr('width', dateRectWidth + 6)
        .attr('x', (d: any) => xScale(d) + maxRight + 2);

      if (width > legendX + maxRight + margin.left) {
        const translateLegend = (margin.left + maxRight + (width - legendX - maxRight - margin.right) / 2);
        d3.selectAll('.legendIconTooltipItem' + myClass)
          .attr('transform', 'translate(' + (translateLegend - 72) + ',35)');

        d3.select('#legendIconSvg' + myClass)
          .attr('x', translateLegend - 40)
          .attr('y', 10);

        d3.select('#legendIconSvgRect' + myClass)
          .attr('transform', 'translate(' + (translateLegend - 40) + ',10)');

        d3.select('#legendBackgroundRect' + myClass)
          .attr('transform', 'translate(' + (translateLegend - 60) + ',2)');

        d3.selectAll('.legendGroup' + myClass).attr(
          'transform',
          'translate(' + translateLegend + ',0)'
        );
      } else {
        // just move to margin if not enough space
        d3.selectAll('.myLegendGroup' + myClass).attr('transform', 'translate(' + margin.left + ',0)');
      }
      // change x, width/transform depending
      d3.selectAll('.dateRect')
        .attr("opacity",0)
        .attr('width', dateRectWidth)
        .transition()
        .delay((d: any) => getDelay(d[myProperties.dateVar]))
        .duration(transitionTime)
        .attr('opacity', function(): any {
          // @ts-ignore
          const myItem: any = this;
          const matchingSelected = myItem.className.baseVal
            .split(' ')
            .filter((f: any) => legendSelected.indexOf(f) > -1);
          return matchingSelected.length === 0 ? 0 : 1;
        })
        .attr('x', (d: any) => xScale(d[myProperties.dateVar]));


      d3.selectAll('.dateRectNumber')
        .attr("opacity",0)
        .transition()
        // .delay((d: any) => getDelay(d.date))
        .delay((d: any) => getDelay(d[myProperties.dateVar]))
        .duration(transitionTime)
        .attr('opacity', function(): any {
          // @ts-ignore
          const myItem: any = this;
          const matchingSelected = myItem.className.baseVal
            .split(' ')
            .filter((f: any) => legendSelected.indexOf(f) > -1);
          return matchingSelected.length === 0 ? 0 : 1;
        })
        .attr('x', (d: any) => xScale(d[myProperties.dateVar]) + (dateRectWidth / 2));

      d3.selectAll('.dateHeaderLabel')
        .attr("opacity",0)
        .transition()
        .delay((d: any) => getDelay(d))
        .duration(transitionTime)
        .attr("opacity",1)
        .attr('x', (d: any) => xScale(d) + (dateRectWidth / 2) + maxRight + 4);

      d3.selectAll('.splitDateRect')
        .attr('width', function (d: any) {
          d.splitWidth = (dateRectWidth / d.rectCount);
          if (d.rectCount > 1){
            const totalGaps = 6 * (d.rectCount - 1);
            d.splitWidth = d.splitWidth - (totalGaps / d.rectCount);
          }
          return d.splitWidth;
        })
        .attr('x', (d: any) => d.myIndex * (d.splitWidth + 6));

      d3.selectAll('.splitRectGroup')
        .attr("opacity",0)
        .transition()
        .delay((d: any) => getDelay(d[myProperties.dateVar]))
        .duration(transitionTime)
        .attr("opacity",1)
        .attr('transform', (d: any) => 'translate(' + xScale(d[myProperties.dateVar]) + ',0)')
        .on('end',(d: any, i: any) => {
          if(i === (dateSet.length - 1)){
            chartDrawing = false;
          }
        });


      d3.selectAll('.datesGroup')
        .attr("opacity",0)
        .transition()
        .delay(getDelay(dateSortStatus === 'ascending' ? dateSet[0] : dateSet[dateSet.length - 1]))
        .duration(transitionTime)
        .attr("opacity",1)
        .attr('transform', 'translate(' + (maxRight + margin.left + 5) + ',0)');
    }

    function updateHierarchy(childrenArray: any, selectedNode: any): void {
      // if there are children of some sort..
      if (childrenArray.children !== undefined) {
        // if this is the selected node
        if (
          childrenArray.depth === selectedNode.depth &&
          childrenArray.data.name === selectedNode.data.name &&
          childrenArray.parent.data.name === selectedNode.parent.data.name
        ) {
          if (childrenArray._children === undefined) {
            childrenArray._children = childrenArray.children;
            childrenArray.children = [];
          } else {
            childrenArray.children = childrenArray._children;
            childrenArray._children = undefined;
          }
        }
        childrenArray.children.forEach(function(c: any): void {
          c.y = currentY;
          currentY += rowHeight;
          if (c.children !== undefined) {
            updateHierarchy(c, selectedNode);
          }
        });
      }
    }

    function drawLegend(legendY: any): void {
      // draw legend
      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(statusArray)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendItem legendCircle');
          enter.append('text').attr('class', 'legendItem legendLabel');
          return enter;
        });

      legendGroup
        .select('.legendCircle')
        .attr('fill-opacity', 1)
        .attr('id', (d: any, i: any) => 'circle_legendLabel' + i)
        .attr('cy', legendY)
        .attr('r', legendCircleRadius)
        .attr('fill', (d: any) => colorScale(d))
        .on('mouseover', function (d: any) {
          // @ts-ignore
          d3.select(this).attr('cursor', (d: any) =>  (chartDrawing === true ? 'not-allowed': (myProperties.notInColorSet.indexOf(d) === -1 ? 'pointer' :  'default')))
        })
        .attr('cursor', (d: any) =>  (chartDrawing === true ? 'not-allowed': (myProperties.notInColorSet.indexOf(d) === -1 ? 'pointer' :  'default')))

        .on('click', function(event: any, d: any): void {
          if (myProperties.notInColorSet.indexOf(d) === -1  && chartDrawing === false) {
            legendClick(d);
          }
        });

      legendGroup
        .select('.legendLabel')
        .attr('fill-opacity', 1)
        .attr('font-size', 12)
        .attr('font-weight', 500)
        .style('text-transform', 'uppercase')
        .attr('id', (d: any, i: any) => 'legendLabel' + i)
        .attr('y', legendY + 4)
        .attr('fill', '#101D42')
        .attr('cursor', (d: any) => (chartDrawing === true ? 'not-allowed': (myProperties.notInColorSet.indexOf(d) === -1 ? 'pointer' :  'default')))
        .text((d: any) => descriptionMap[d])
        .on('click', function(event: any, d: any): void {
          if (myProperties.notInColorSet.indexOf(d) === -1 && chartDrawing === false)  {
            legendClick(d);
          }
        });

      function legendClick(d: any): void {
        if (legendIconStatus === 'HIDE') {
          if (legendSelected.indexOf(d.replace(/ /g,'').toLowerCase()) > -1) {
            removeLegendItem(d.replace(/ /g,'').toLowerCase());
          } else {
            addLegendItem(d.replace(/ /g,'').toLowerCase());
          }
        } else {
          legendSelected = JSON.parse(JSON.stringify(myProperties.notInColorSet));
          legendSelected = legendSelected.map((m: any) => m = m.replace(/ /g,'').toLowerCase());
          legendSelected.push(d.replace(/ /g,'').toLowerCase());
        }

        d3.selectAll('.legendItem')
          .interrupt()
          .transition()
          .duration(1000)
          .attr('fill-opacity', (d: any) => (legendSelected.indexOf(d.replace(/ /g,'').toLowerCase()) > -1 ? 1 : 0.3));

        resetDateRectOpacity();

        function removeLegendItem(myItem: any): void {
          legendSelected = legendSelected.filter((f: any) => f !== myItem);
        }
        function addLegendItem(myItem: any): void {
          legendSelected.push(myItem);
        }
      }
      legendX = legendCircleRadius;

      d3.selectAll('.legendLabel').each(function (): void {
        const myItem: any = this;
        const myWidth: any = document.getElementById(myItem.id)?.getBoundingClientRect().width;
        d3.select('#circle_' + myItem.id).attr('cx', legendX);
        d3.select(myItem).attr('x', legendX + legendCircleRadius + 8);
        legendX += myWidth + legendCircleRadius * 2 + 28;
      });
      legendX -= legendCircleRadius * 2;

      d3.select('#legendBackgroundRect' + myClass)
        .attr('width',legendX + 80)
        .attr('height', 36)
        .attr('rx', 24)
        .attr('ry', 24)
        .attr('fill', 'transparent')
        .attr('stroke', '#E8EAEE')
        .attr('stroke-width', '2px');

      d3.select('#iconTooltipRect' + myClass)
        .attr('pointer-events', 'none')
        .attr('height', 14)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('stroke-width', 0.5)
        .attr('fill', 'white')
        .attr('stroke', '#A0A0A0');

      d3.select('#legendIconTooltipRect' + myClass)
        .attr('pointer-events', 'none')
        .attr('height', 14)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('stroke-width', 0.5)
        .attr('fill', 'white')
        .attr('stroke', '#A0A0A0');

      d3.select('#legendIconTooltipText' + myClass)
        .attr('pointer-events', 'none')
        .attr('font-size', 8)
        .attr('dy', 10)
        .attr('dx', 2)
        .text('test exciting text content');

      d3.select('#iconTooltipText' + myClass)
        .attr('pointer-events', 'none')
        .attr('font-size', 8)
        .attr('dy', 10)
        .attr('dx', 2)
        .text('test exciting text content');

      d3.selectAll('.iconTooltipItem' + myClass)
        .attr('visibility', 'hidden');

      d3.selectAll('.legendIconTooltipItem' + myClass)
        .attr('visibility', 'hidden');

      d3.select('#expandCollapseIcon' + myClass)
        .attr( 'pointer-events', 'none')
        .attr('fill', '#1363DF')
        .attr( 'd', expandIconPath);

      d3.select('#expandCollapseIconSvg' + myClass)
        .attr('x',  width - 36)
        .attr('y', 8)
        .attr( 'pointer-events', 'none')
        .attr('viewBox', expandCollapseViewBox)
        .attr( 'width', 20)
        .attr( 'height', 20);

      d3.select('#expandCollapseIconSvgCircle' + myClass)
        .attr('r', 18)
        .attr('cy', 19)
        .attr('fill', 'transparent')
        .attr('stroke', '#E8EAEE')
        .attr('stroke-width', '2px')
        .attr('transform', 'translate(' + (width - 26) + ',0)')

      d3.select('#expandCollapseIconSvgRect' + myClass)
        .attr('class', 'expandedHM')
        .attr( 'fill', 'transparent')
        .attr( 'width', 20)
        .attr( 'height', 20)
        .attr('transform', 'translate(' + (width - 36) + ',8)')
        .attr( 'cursor', 'pointer');


      d3.select( '#expandCollapseIconSvgRect' +  myClass)
        .on('mouseover', function (): void {
          d3.selectAll('.iconTooltipItem' + myClass)
            .attr('transform', 'translate(' + (width - 50) + ',35)')
            .attr('visibility', 'visible');
          let tooltipText;
          if (d3.select(this).classed('expandedHM')){
            tooltipText = 'Expand All';
          } else {
            tooltipText = 'Collapse All';
          }
          d3.select('#iconTooltipRect' + myClass).attr('width', 50);
          d3.select('#iconTooltipText' + myClass).text(tooltipText);
          d3.select(this).interrupt().transition().duration(100).attr('fill-opacity', 0.5);
        })
        .on('mouseout', function () : void {
          d3.selectAll('.iconTooltipItem' + myClass).attr('visibility', 'hidden');
          d3.select(this).interrupt().transition().duration(50).attr('fill-opacity', 1);
        })
        .on('click', function(){
          const myObject = this;
          let myHierachy;
          if (d3.select(myObject).classed('expandedHM')){
            d3.select('#expandCollapseIcon' + myClass).attr( 'd', collapseIconPath);
            d3.select(myObject).classed('expandedHM', false);
            d3.select(myObject).classed('collapsedHM', true);
            d3.select('#iconTooltipText' + myClass).attr('visibility', 'visible').text('Collapse All');
            myHierachy = d3.hierarchy(JSON.parse(JSON.stringify(baseHierarchy)));
          } else {
            d3.select('#expandCollapseIcon' + myClass).attr( 'd', expandIconPath);
            d3.select(myObject).classed('expandedHM', true);
            d3.select(myObject).classed('collapsedHM', false);
            d3.select('#iconTooltipText' + myClass).attr('visibility', 'visible').text('Expand All');
            myHierachy = d3.hierarchy(JSON.parse(JSON.stringify(baseHierarchy)));
            myHierachy.descendants().forEach(function(d){
              if (d.depth >= 1 && d.children !== undefined){
                // @ts-ignore
                d._children = d.children;
                d.children = [];
              }
            });
          }
          hierarchyData = myHierachy;
          currentY = 0;
          addYValue(myHierachy);
          chartData = myHierachy.descendants();
          chartData = chartData.filter((f: any) => f.depth > 0);
          drawChart(chartData, 0);
        });


      d3.select('#sortIconSvgCircle' + myClass)
        .attr('r', 18)
        .attr('cy', 19)
        .attr('fill', 'transparent')
        .attr('stroke', '#E8EAEE')
        .attr('stroke-width', '2px')
        .attr('transform', 'translate(' + (width - 82) + ',0)');

      d3.select('#sortIcon' + myClass)
        .attr('fill', '#1363DF')
        .attr( 'pointer-events', 'none')
        .attr( 'd', sortIconPath);

      d3.select('#sortIconSvg' + myClass)
        .attr('x',  width - 92)
        .attr('y', 8)
        .attr( 'pointer-events', 'none')
        .attr('viewBox', sortIconViewBox)
        .attr( 'width', 20)
        .attr( 'height', 20);

      d3.select('#sortIconSvgRect' + myClass)
        .attr( 'fill', 'transparent')
        .attr( 'width', 20)
        .attr( 'height', 20)
        .attr('transform', 'translate(' + (width - 92) + ',8)')
        .attr( 'cursor', 'pointer');

      d3.select('#sortIconSvgRect' + myClass)
        .on('mouseover', function(): void {
          d3.selectAll('.iconTooltipItem' + myClass)
            .attr('transform', 'translate(' + (width - 128) + ',35)')
            .attr('visibility', 'visible');
          const tooltipText = dateSortStatus === 'descending' ? ' sort dates Ascending' : 'sort dates Descending';
          d3.select('#iconTooltipRect' + myClass).attr('width', 94);
          d3.select('#iconTooltipText' + myClass).text(tooltipText);
          d3.select(this).interrupt().transition().duration(100).attr('fill-opacity', 0.5);
        })
        .on('mouseout', function(): void {
          d3.selectAll('.iconTooltipItem' + myClass).attr('visibility', 'hidden');
          d3.select(this).interrupt().transition().duration(50).attr('fill-opacity', 1);
        })
        .on('click', function(): void {
          if (dateSortStatus === 'ascending') {
            dateSortStatus = 'descending';
            d3.select('#iconTooltipText' + myClass).attr('visibility', 'visible').text('sort dates Ascending');
            dateSet = dateSet.sort((a: any, b: any) => d3.descending(convertDate(a), convertDate(b)));
            drawChart(currentChartData, 1000);
          } else {
            dateSortStatus = 'ascending';
            d3.select('#iconTooltipText' + myClass).attr('visibility', 'visible').text('sort dates Descending');
            dateSet = dateSet.sort((a: any, b: any) => d3.ascending(convertDate(a), convertDate(b)));
            drawChart(currentChartData, 1000);
          }
        });


      d3.select('#legendIcon' + myClass)
        .attr( 'pointer-events', 'none')
        .attr( 'd', keyIconPath3);

      d3.select('#legendIcon2' + myClass)
        .attr( 'pointer-events', 'none')
        .attr( 'd', keyIconPath4);

      d3.select('#legendIconSvg' + myClass)
        .attr( 'pointer-events', 'none')
        .attr('viewBox', keyIconViewBox)
        .attr('fill', '#1363DF')
        .attr( 'width', 18)
        .attr( 'height', 18);

      d3.select('#legendIconSvgRect' + myClass)
        .attr( 'fill', 'transparent')
        .attr( 'width', 20)
        .attr( 'height', 20)
        .attr( 'cursor', 'pointer')
        .on('mouseover', function(): void {
          d3.selectAll('.legendIconTooltipItem' + myClass)
            .attr('visibility', 'visible');
          const tooltipText = (legendIconStatus === 'SHOW' ? ' HIDE' :  'SHOW') + ' on Legend click';
          d3.select('#legendIconTooltipRect' + myClass).attr('width', 100);
          d3.select('#legendIconTooltipText' + myClass).text(tooltipText);
          d3.select(this).interrupt().transition().duration(100).attr('fill-opacity', 0.5);
        })
        .on('mouseout', function(): void {
          d3.selectAll('.legendIconTooltipItem' + myClass).attr('visibility', 'hidden');
          d3.select(this).interrupt().transition().duration(50).attr('fill-opacity', 1);
        })
        .on('click', function(): void {
          if (legendIconStatus === 'SHOW') {
            legendIconStatus = 'HIDE';
            d3.select('#legendIconTooltipText' + myClass).attr('visibility', 'visible').text(' SHOW on Legend click');
            d3.select('#legendIcon' + myClass)
                .attr( 'pointer-events', 'none')
                .attr( 'd', keyIconPath3);

            d3.select('#legendIcon2' + myClass)
                .attr( 'pointer-events', 'none')
                .attr( 'd', keyIconPath4);
        } else {
            legendIconStatus = 'SHOW';
            d3.select('#legendIconTooltipText' + myClass).attr('visibility', 'visible').text(' HIDE on Legend click');
            d3.select('#legendIcon' + myClass)
                .attr( 'pointer-events', 'none')
                .attr( 'd', keyIconPath);

            d3.select('#legendIcon2' + myClass)
                .attr( 'pointer-events', 'none')
                .attr( 'd', keyIconPath2);
        }
        });
    }

    function addYValue(childrenArray: any): void {
      // adds Y value to base data so consistent with hierarchy structure (only called on initial draw)
      if (childrenArray.children !== undefined) {
        childrenArray.children.forEach(function(c: any): void {
          c.y = currentY;
          currentY += rowHeight;
          if (c.children !== undefined) {
            addYValue(c);
          }
        });
      }
    }
    function resetDateRectOpacity(): void {
      d3.selectAll('.dateRectItem')
        .interrupt()
        .transition()
        .duration(1000)
        .attr('opacity', function(): any {
          const myItem: any = this;
          const matchingSelected = myItem.className.baseVal
            .split(' ')
            .filter((f: any) => legendSelected.indexOf(f) > -1);
          return matchingSelected.length === 0 ? 0 : 1;
        });
    }

    function buildHierarchyData(): any {
      // build d3 group
      const arg: any = [myChart.heatMapData.data];
      myProperties.hierarchyLevels.forEach((col: any) => {
        const func = function(data: any): any {
          return data[col];
        };
        arg.push(func);
      });
      let groupedData: any = d3.group.apply(null, arg);
      groupedData = Array.from(groupedData);
      // build hierarchy data
      const myHierarchyData = { name: 'root', children: [] };

      myHierarchyData.children = getChildren(groupedData, 'root');
      return myHierarchyData;

      function getChildren(childrenArray: any, parentName: any): any {
        const myChildren: any = [];
        childrenArray.forEach(function(d: any): any {
          let currentChildren = [];
          let myValues = [];
          const myType = hierarchyLevelTypes[d[0]];
          if (d[1][0] === undefined) {
            currentChildren = getChildren(Array.from(d[1]), d[0]);
            // drill down hierarchy until you get to d[1][0] === undefined.
            // get all child data
            let allValues = myChart.heatMapData.data.filter((f: any) => f[myType] === d[0]);
            if (hierarchyLevelTypes[parentName] !== undefined) {
              allValues = myChart.heatMapData.data.filter(
                (f: any) => f[myType] === d[0] && f[hierarchyLevelTypes[parentName]] === parentName
              );
            }
            // add a record for each date (including colors of children + sum of records
            dateSet.forEach(function(s: any): void {
              const dateValues = allValues.filter((f: any) => f[myProperties.dateVar] === s);
              let colorSet: any = new Set();
              dateValues.forEach((d: any) => colorSet.add(d[myProperties.colorVar]));
              colorSet = Array.from(colorSet);
              colorSet = colorSet.filter((f: any) => myProperties.notInColorSet.indexOf(f) === -1);
              if (colorSet.length === 0) {
                colorSet = myProperties.notInColorSet;
              }
              const myEntry: any = { brand: dateValues[0]?.brand};
              myEntry[myProperties.dateVar] = s;
              myEntry[myProperties.numberVar] = d3.sum(dateValues, (s: any) => +s[myProperties.numberVar]);
              myEntry[myProperties.colorVar] = colorSet;
              myValues.push(myEntry);
            });
          } else {
            // when no children left, add values
            myValues = d[1];
            myValues.map((m: any) => (m.level_type = myType));
          }
          const myNewEntry = { name: d[0], children: currentChildren, values: myValues };
          myChildren.push(myNewEntry);
        });
        return myChildren;
      }
    }

    function convertDate(myDate: any): any {
      // converts date
      //const dateSplit = myDate.split('-');
      //return new Date(dateSplit[2], +dateSplit[0] - 1, dateSplit[1]);
      return new Date(myDate);
    }
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const mySection: any = document.getElementById(this.divId);
    const myClass = this.divId;
    this.height = 0; // 500 is random height, height is fixed to data later on
    let mySvg: any = d3.select('#heatMap' + myClass);

    if (mySvg._groups[0][0] === null) {
      mySvg =  d3.select('#' + myClass)
        .append('svg')
        .attr('id', 'heatMap' + myClass)
        .attr('width', '100%')
        .attr('height', this.height)
        .style('background-color', 'white');

      mySvg.append('text')
        .attr('id', 'legendInteraction' + myClass);

      mySvg.append('text')
        .attr('id', 'dateSorting' + myClass);

      mySvg.append('circle')
        .attr('id', 'expandCollapseIconSvgCircle' + myClass);

      mySvg.append('svg')
        .attr('id', 'expandCollapseIconSvg' + myClass)
        .append('path')
        .attr('id', 'expandCollapseIcon' + myClass);

      mySvg.append('rect')
        .attr('id', 'expandCollapseIconSvgRect' + myClass);

      mySvg.append('circle')
        .attr('id', 'sortIconSvgCircle' + myClass);

      mySvg.append('svg')
        .attr('id', 'sortIconSvg' + myClass)
        .append('path')
        .attr('id', 'sortIcon' + myClass);

      mySvg.append('rect')
        .attr('id', 'sortIconSvgRect' + myClass);

      mySvg.append('rect')
        .attr('id', 'legendBackgroundRect' + myClass);

      const legendIcon =  mySvg.append('svg')
        .attr('id', 'legendIconSvg' + myClass);

      legendIcon.append('path')
        .attr('id', 'legendIcon' + myClass);

      legendIcon.append('path')
        .attr('id', 'legendIcon2' + myClass);

      mySvg.append('rect')
        .attr('id', 'legendIconSvgRect' + myClass);

      mySvg.append('rect')
        .attr('class', 'iconTooltipItem' + myClass)
        .attr('id', 'iconTooltipRect' + myClass);

      mySvg.append('text')
        .attr('class', 'iconTooltipItem' + myClass)
        .attr('id', 'iconTooltipText' + myClass);

      mySvg.append('rect')
        .attr('class', 'legendIconTooltipItem' + myClass)
        .attr('id', 'legendIconTooltipRect' + myClass);

      mySvg.append('text')
        .attr('class', 'legendIconTooltipItem' + myClass)
        .attr('id', 'legendIconTooltipText' + myClass);


    }
  }
}
