<!--  NEW LOGIN SCREEN -->
<div class="container-fluid resetPass">
    <div class="row">
        <!-- Login Form Block -->
        <div class="du_login_box col-lg-6 col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-between"
            style="height:100vh">
            <!-- SCRAII LOGO -->
            <div class="row mt-2 pl-4">
                <img class="pt-2" src="../../../../assets/SCRAII_LoginLogo.svg" style="width: 25%" />
            </div>
            <!-- LOGIN FORM -->
            <div class="row d-flex justify-content-center"
                style="width: -webkit-fill-available;top: 25% !important;position: absolute;">
                <div class="card shadow-lg w-75">
                    <!-- Reset Password -->
                    <div class="card-body" [hidden]="!showReset">
                        <div class="row get_started">
                            Reset password
                        </div>
                        <div class="row mt-2 get_started_subheading">
                            Create new password to your account.
                        </div>
                        <div class="row mt-3">
                            <form #f="ngForm">
                                <div class="form-input">
                                    <label class="login_label">Enter Verification Code</label> <i class="fa fa-info-circle ml-3" matTooltip="Enter verification code received through email" matTooltipPosition="above" matTooltipClass="logintooltip" aria-hidden="true"></i>
                                    <div class="input-icons d-flex">
                                        <input class="login_input" type="text" [(ngModel)]='verification_code'
                                            placeholder="Enter verification code" name="verificationCode" required />
                                    </div>
                                </div>

                                <div class="form-input mt-3">
                                    <label class="login_label">New Password</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5" type="{{ showPassword ? 'text' : 'password' }}"
                                            [(ngModel)]='new_update_password' placeholder="Enter new password"
                                            name="newPassword" required />
                                        <img draggable="false" (click)="togglePasswordVisibility()" class="iconLogin"
                                            [src]="!showPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'"
                                            style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class="form-input mt-3">
                                    <label class="login_label">Confirm Password</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5"
                                            type="{{ showConfirmPassword ? 'text' : 'password' }}"
                                            [(ngModel)]='confirm_update_password' placeholder="Enter confirm password"
                                            name="newPassword" required />
                                        <img draggable="false" (click)="toggleConfirmPasswordVisibility()" class="iconLogin"
                                            [src]="!showConfirmPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'" style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class="form-input row error_msg mt-2 mb-2">
                                    {{updatePasswordMessage}}
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button type="submit" class="login_button btn btn-primary"
                                            (click)="updatePassword()">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-12 d-flex justify-content-center">
                                        <a class="back_to_login pl-1" href="javascript:void(0)"
                                            (click)="showLogin()">Back to login</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Reset Password -->
                    <div class="card-body" [hidden]='!isResetPasswordSuccess'>
                        <div class="row get_started">
                            Success!!
                        </div>
                        <div class="row mt-3">
                           <div class="col-12">Your Password has been reset successfully.</div>
                           <div class="col-12 mt-3" style="color: blue;">Redirecting back to Login Screen...</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FOOTER -->
            <div class="row d-flex justify-content-center footer">
                <div class="mb-2">Copyright © 2024 All Rights Reserved by <b>Data Unveil</b></div>
            </div>
        </div>
        <!-- Login Side Image -->
        <div class=" col-lg-6 col-xs-12 col-sm-12 col-md-6">
            <img class="du_login_newIMG"
            *ngFor="let image of images; let i = index"
            [src]="image"
            alt="Slide {{ i }}"
            [@fadeAnimation]="currentImageIndex === i"
            [style.z-index]="currentImageIndex === i ? 1 : 0"
          />
        </div>
    </div>
</div>