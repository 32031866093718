import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef, FirstDataRenderedEvent, GridApi, GridReadyEvent, IDetailCellRendererParams, RowHeightParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import 'ag-grid-enterprise';
import { ApiService } from 'src/app/services/api.service';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { DetailGridEditingComponent } from '../../shared/grid/detail-grid-editing/detail-grid-editing.component';
import { ValidationInputComponent } from './validation-input.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-data-grid-editing',
  templateUrl: './data-grid-editing.component.html',
  styleUrls: ['./data-grid-editing.component.scss']
})
export class DataGridEditingComponent implements OnInit, OnDestroy {
  static key = 100810;

  @Input("item") item: any = {};
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("dataEditGridDiv", { static: true }) dataEditGridDiv!: ElementRef;
  @Input("pageKey") pageKey: any;
  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive = false;
  reqSubcription: Subscription[] = [];
  rowData:any[]=[];
  private gridApi!: GridApi;
  loader = false;
  constructor(
    public filterService: FilterService, 
    private apiService: ApiService,
    private httpClient: HttpClient,
    private currency: CurrencyPipe
  ) { 
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((q:any)=> {
      this.loader = true;
      this.loadApiData()
    }))
    
    // Refresing Grid after updated value from the buckets
    this.reqSubcription.push(this.filterService.dataPartnerRefreshingGrid.subscribe((res:any)=> {
      if(res) {
        this.gridApi.setRowData(this.rowData)
      }
    }))

    this.reqSubcription.push(this.filterService.editingDataGrid.subscribe((res:any)=> {
      if(res) {
        this.gridApi.setFocusedCell(1, res.data.source_nm);
        this.gridApi.startEditingCell({rowIndex: 1, colKey: res.data.source_nm});
      }
    }))
  }

  // Grid options for ag-Grid
  gridOptions:any = {
    enableColResize: true
  };
  moment = moment
  public detailCellRenderer: any = DetailGridEditingComponent;
  public columnDefs: ColDef[] = [];
  public detailRowHeight = 50;

  public defaultColDef: ColDef = {
    flex: 1,
    initialWidth: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    headerClass: 'my-dataEdit-class',
    resizable: true,
    sortable: true,
    suppressMenu:true,
  };
  public themeClass: string = "ag-theme-quartz";
  public paginationPageSizeSelector: number[] | boolean = [10, 20, 50, 100];
 
  public getRowHeight: (params: RowHeightParams) => number | undefined | null = (params: RowHeightParams) => {
    if (params.node && params.node.detail) {
      let acutalHeight = 200;
      let defaultSpaceAdj = 61;
      let allDetailRowHeight = ((params.data.callRecords.length * acutalHeight) + (params.data.callRecords.length ==0 ? 91 : defaultSpaceAdj ));
      let bucketHeight = 0;

      if(params.data.callBucketRecords.length > 2) {
        bucketHeight = ((params.data.callBucketRecords.length-2) * 30)-7;
      };
      let totelHeight = (allDetailRowHeight + bucketHeight);
      return totelHeight
    }
  };

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api; 
  }

  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.dataEditGridDiv.nativeElement);
    
    this.loadConfiguration();
  }

  loadConfiguration() {

    this.columnDefs = []
    let cfg = this.item.config;

    console.log(cfg.edit_data_grid)

    cfg.edit_data_grid.forEach((res: any) => {
      res.property_CB.forEach((cb: any) => {
        if (cb.name == "Add Column") {
          if (cb.value) {
            var coldef: any = {
              headerName: res.api_resp_column_display_nm,
              field: res.api_resp_column_nm,
              cellClass: 'cell-wrap-text-editing',
              autoHeight: true,
              menuTabs: [],
              minWidth: 200
            };

            if(res.groupRendererSelection) {
              coldef['cellRenderer'] = 'agGroupCellRenderer'
              coldef["minWidth"] = 150;
            }
            
            if(res.isCellEditCondition) {
              coldef['editable'] = true;
              coldef['cellEditor'] = ValidationInputComponent
            }

            if (res.isAlignment && res.isTextColorCondition) {
              coldef["cellStyle"] = {
                color: res.isTextColor,
                textAlign: res.isAlignment.toLowerCase(),
                display: 'inline-grid'
              };
            } else if (res.isAlignment && !res.isTextColorCondition) {
              coldef["cellStyle"] = {
                textAlign: res.isAlignment.toLowerCase(),
                display: 'inline-grid'
              };
            } else if (res.isTextColorCondition) {
              coldef["cellStyle"] = { color: res.isTextColor };
            }

            // Data Types and Prefix and suffiz;
            let isPrefix = res.isPrefix || "";
            let isSuffix = res.issuffix || "";

            if (res.isDataType == "date") {
              coldef["valueGetter"] = (params: any) => params.data[res.api_resp_column_nm] ? isPrefix + "" + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + "" + isSuffix : "";
            } else if (res.isDataType == "integer") {
              if(res.isFormats=='Currency') {
                coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? this.currency.transform(params.value) + "" + isSuffix : "";
              } else {
                coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? isPrefix + "" + this.numbedPipe(params.value) + "" + isSuffix : "";
              }
            } else {
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value + "" + isSuffix : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + "" + isSuffix : "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toUpperCase() + "" + isSuffix : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toLowerCase() + "" + isSuffix : "";
                }
              }
            }

            coldef['cellRendererParams'] = (params: any) => { 
              params['configs'] = this.item.config;
              return params;
            }

            this.columnDefs.push(coldef);
          }
        }
      });
    });
  }

  numbedPipe(value: any) {
    return this.currency.transform(value, "", "", "1.0-2");
    //  return   this.numberpipe.transform(value)
  }

  loadApiData() {
    // debugger
    let api = this.item.config['list_of_api'].api_key
    if(api) {
      if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
        // API Payload
        let obj:any = {"api_key" : api}
        let query = Object.assign({}, this.filterService.baseQuery, obj)
        query["report_typ"] = 'D';
  
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          let rowdata = res ? res : [];
          this.fileTypeData(rowdata);
        },(err:any)=>{
          console.log('edit - grid '+ api,err)
          this.loader=false;
        });
      }
    }
  }

  fileTypeData(rowdata:any) {
    let api = this.item.config['list_of_api_file_level'].api_key
    if(api) {
      // API Payload
      let obj:any = {"api_key" : api}
      let query1 = Object.assign({}, this.filterService.baseQuery, obj)
      query1["report_typ"] = 'D';
  
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res:any)=> {
        let fileData = res ? res : [];
        this.bucketData(rowdata, fileData)
      },(err:any)=>{
        console.log('edit - grid '+ api,err)
        this.loader=false;
      });
    }
  }

  bucketData(rowdata:any, fileData:any) {
    let api = this.item.config['list_of_api_bucket_level'].api_key
    if(api) {
      // API Payload
      let obj:any = {"api_key" : api}
      let query2 = Object.assign({}, this.filterService.baseQuery, obj)
      query2["report_typ"] = 'D';
  
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
        let bucketData = res ? res : [];
        this.groupingAlltogether(rowdata, fileData, bucketData)
      },(err:any)=>{
        console.log('edit - grid '+ api,err)
        this.loader=false;
      });
    }
  }

  groupingAlltogether(rowdata:any, fileData:any, bucketData:any) {
    var arrCallRecordLength = 0;
    var arrBucketRecordLength = 0;
    
    for (let index = 0; index < rowdata.length; index++) {
      arrCallRecordLength = 0; 
      arrBucketRecordLength = 0; 
      const element = rowdata[index];
      const flrData = fileData.filter((flr:any)=> flr.brand_key == element.brand_key && flr.source_key==element.data_source_key)
      // callRecords
      arrCallRecordLength += flrData.length;
      Object.assign(rowdata[index], {'record_detail' : flrData})

      element['record_detail'].forEach((resRec:any)=> {
        const flrBuck = bucketData.filter((bck:any)=> bck.brand_key==resRec.brand_key&&bck.file_type_key==resRec.file_type_key&&bck.source_key==resRec.source_key)
        arrBucketRecordLength += flrBuck.length;
        Object.assign(resRec, {'bucket_detail': flrBuck})
      })

      // find the length For Auto Height in Detail grid
      element['callRecords'] = [];
      element['configuration'] = this.item.config;
      for (let idx = 0; idx < arrCallRecordLength; idx++) {
        element['callRecords'].push({})
      }
      element['callBucketRecords'] = [];
      for (let idx = 0; idx < arrBucketRecordLength; idx++) {
        element['callBucketRecords'].push({})
      }
      

    }

    this.rowData = [...rowdata]
    this.loader=false;
    console.log(this.rowData)
  }

  exportXls(event:any) {
    if(event) {
      this.gridApi.exportDataAsExcel({
        fileName: this.item.config['report_heading'] || 'List Data', // Set your desired file name here
      });
    }
  }

  screenChanges(event:any) {
    this.isActive = event
    this.isFullscreen = event
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    // console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    // debugger
    let payload:any = {};
    let columDef = event.api['columnModel'].columnDefs
    let field:any = event.colDef.field;

    if(event.value=="" || event.oldValue == event.value) {
      event.value = event.oldValue;
      event.data[field] = event.oldValue;
      this.gridApi.refreshCells();
      return
    } 
    
    if(event.value) {
      payload[field] = event.value;
      payload['source_key'] = event.data['data_source_key'];
      payload['brand_key'] = event.data['brand_key'];

      columDef.forEach((res:any)=> {
        if(field!=res['field'])
          payload[res['field']] = event.data[res['field']]
      })

      this.updateSourceLevel(payload);
    }
  }

  updateSourceLevel(payload:any) {
    let api = this.item.config['list_of_api'].api_key
    if(api) {
      // API Payload
      let obj:any = {"api_key" : api}
      let query2 = Object.assign({}, this.filterService.baseQuery, obj, payload)
      query2["report_typ"] = 'U';
  
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
        if(res.length==0) return console.log('success');
        
      },(err:any)=>{
        console.log('UPDATE - Edit Grid '+ api, err)
      });
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
