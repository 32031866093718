import Amplify from 'aws-amplify';
import { QRCodeModule } from 'angularx-qrcode';
import { ChartsModule } from './components/shared/charts/charts.module';
import { AuthModule } from './components/auth/auth.module';
import { AppLayoutModule } from './components/layout/app-layout.module';
import { WidgetModule } from './components/widget/widget.module';
import {MatCard, MatCardModule} from '@angular/material/card';
import { MatComponentsModule } from './mat-components.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { GridsterModule } from 'angular-gridster2';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService} from './services/data.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardModule } from './components/dashboard/dashbaord.module';
import { SharedServicesModule } from './services/shared.services.module';
import { AgGridModule } from 'ag-grid-angular';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FilterService } from './services/filter.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { ConstantsService } from './services/constants.service';
import { NewFilterService } from './services/new-filter.service';
import { SpinnerService } from './services/spinner.service';
import { MyInterceptor } from './services/my.interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { ExcelExportService } from './services/excelExport.service';
import { NumberPipe } from './pipe/number.pipe';
import { SharedComponentModule } from './components/shared/shared.module';
import { CsvExportService } from './services/csvexport.service';
import { LoaderToastModuleModule } from './components/loader-toast-module/loader-toast-module.module';
import { NavigationService } from './services/navigation.service';
import { UserActivityService } from './services/useractivity.service';
import { DeviceDetectionService } from './services/detectipad.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleService } from './services/idle.service';
import { RouterHandlerService } from './services/router-handler.service';

Amplify.configure({
	Auth: environment.AMPLIFY,
});

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    QRCodeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    GridsterModule,
    ReactiveFormsModule,
    MatComponentsModule,
    AuthModule,
    DashboardModule,
    WidgetModule,
    AppLayoutModule,
    SharedServicesModule.forRoot(),
    ChartsModule,
    AgGridModule,
    MatCardModule,
    MatTabsModule,
    SharedComponentModule,
    LoaderToastModuleModule,
    NgIdleKeepaliveModule.forRoot()
   ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    DataService,
    ExcelExportService,
    DatePipe,
    NumberPipe,
    CurrencyPipe,
    FilterService,
    CsvExportService,
    NewFilterService,
    UserActivityService,
    NavigationService,
    DeviceDetectionService,
    SpinnerService,
    { provide: MatDialogRef, useValue: {}},
    ConstantsService,
    IdleService,
    RouterHandlerService
  ],
  exports:[
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
