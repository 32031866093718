import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import cloud from 'd3-cloud';
import {mark} from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import { FilterService } from 'src/app/services/filter.service';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-word-cloud-chart',
  templateUrl: './word-cloud-chart.component.html',
  styleUrls: ['./word-cloud-chart.component.scss']
})
export class WordCloudChartComponent implements OnInit {
  @ViewChild('wordCloudContainer', { static: true }) wordCloudContainer!: ElementRef
  @ViewChild('WordCloudChart') WordCloudChart!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  WordCloudChartData:any;
  props: any;
  divId: any = "WordCloudChartDiv";
  initiateChart:Boolean=false
  noData: boolean = false
  mytooltipData:any
  dataTurn:any
  tooltip:any
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event:any) {
    if (this.isFullscreen == true) {
      this.props.fontSizeRange = [8, 30]
      this.closeFullscreen();
    }
  }
  constructor(private filterService :FilterService ) {
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
   }
  

//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
    this.mytooltipData = myData
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
    this.tooltip=true
    if (this.dataTurn < 250) {
      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 30 + 'px')
        .style('right', (this.dataTurn + 20) + 'px')
        .style('left', 'unset')
        .style('bottom', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (  myY + 30) + 'px')
        .style('left', (myX+20) + 'px')
        .style('right', 'unset')
        .style('bottom', 'unset')
    }
  }

  private hideTooltip(myType: any): void {
    this.tooltip = false
    d3.select("#d3WordCloudTooltip")
      .style('visibility', 'hidden');
  }
  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
    // this.getWordCloudData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getWordCloudData();
    }
  }
  closeFullscreen(): void {
    this.isFullscreen = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } 
    this.props.chartHeight=500
    this.isActive = false;
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    // const mySvg = d3.select('#' + myClass)
    const mySvg = d3.select(this.wordCloudContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("class", "wordsGroup")
    this.initiateChart=true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    const margins = { left: 10, right: 10, top: 30, bottom: 10};
    let myCloud: any = null;

    if(myCloud !== null){
      myCloud.stop();
    }

    mySvg.attr("height", height);

    let words: any = d3.rollup(myChart.WordCloudChartData,(v: any) => d3.sum(v, (s: any) => s[myChart.props.sizeVar]), (d: any) => d[myChart.props.wordVar])
    words = Array.from(words);
    words = words.map((m: any, i: any) => m = {text: m[0].replace(/_/g,' '), value: m[1],fill: myChart.props.colors[i % myChart.props.colors.length]});

    const sizeExtent: any = d3.extent(words, (s: any) => Math.sqrt(s.value))

    const fontSizeScale = d3.scaleLinear()
      .domain(sizeExtent)
      .range(myChart.props.fontSizeRange)

    words.map((m: any) => m.size =  fontSizeScale(Math.sqrt(m.value)));
   myCloud = cloud()
    .size([width, height])
     .padding(2)
     .font("Poppins")
     .rotate(d => ~~(Math.random() * 2) * 90)
    .fontSize((d: any) => d.size)
     .fontWeight(700)
    .words(words)
    .on("end", draw)
    .start();

    function draw() {
      const wordGroup = mySvg
        .select('.wordsGroup')
        .selectAll('.wordGroup' + myClass)
        .data(words)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'wordGroup' + myClass);
          enter.append('text').attr('class', 'wordText');
          return enter;
        });

      wordGroup.select(".wordText")
        .attr("font-family", "Poppins")
        .attr("fill", (d: any) => d.fill)
        .style("font-size", (d: any) => d.size+ "px")
        .style("font-weight", 700)
        .attr("text-anchor", "middle")
        .attr("transform", (d: any) => `translate(${d.x + (width/2)},${d.y + height/2}) rotate(${d.rotate})`)
        .text((d: any) => d.text)
        .on("mouseover", (event:any, d: any) => {
            myChart.showTooltip('wordCloud', d, event.offsetX, event.offsetY, width);
        })
        .on("mouseout", () => {
          myChart.hideTooltip("wordCloud");
        });;
      ;
    }
    this.stop(10).then(() => this.isLoading = false);

  }
// property & data for the chart
  getWordCloudData(){
    // this.filterService.getSampleJsonData("wordCloud").subscribe((res:any)=>{
      this.WordCloudChartData=this.data
      this.props = {
        chartHeight: 500,
        wordVar:  this.config.xAxisVar,//"field_name",
        sizeVar:  this.config.yAxisVar,//"defect_count",
        fontSizeRange: [8, 30],
        "colors": ["#8C23E6", "#E223E6", "#FFCD4A", "#645DD7", "gold", "pink", "purple", "orange", "green", "blue", "red","brown","grey"],
      }
      if(this.isFullscreen){
        this.props.chartHeight = window.outerHeight -60
        this.props.fontSizeRange = [12, 40]
      }
      // @ts-ignore
      if ( this.WordCloudChartData.length > 0){
        this.noData =false
        setTimeout(() => {
          this.plotChart();
        }, 50);
      }else{
        this.WordCloudChartData.length
        this.plotChart();
        this.noData = true
      }
    // });  
  }
}
