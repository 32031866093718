import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Inject, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams, ITooltipParams, RowGroupingDisplayType } from 'ag-grid-community';
import moment from 'moment';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { UnderScoreDetailsComponent } from './under-score-details.component';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { CustomTooltipComponent } from './tooltip';

@Component({
  selector: 'app-payment-record-details',
  templateUrl: './payment-record-details.component.html',
  styleUrls: ['./payment-record-details.component.scss']
})
export class PaymentRecordDetailsComponent implements OnInit, OnDestroy {
  static key = 100801;
  
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  @ViewChild("paymentRecordDetail") paymentRecordDetail!: ElementRef;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  
  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive = false;
  noData: Boolean = false;
  childData:any=[];
  parentData:any=[];
  data:any;
  rowData:any;
  loader:Boolean =true
  constructor(
    public filterService: FilterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private newFilterService: NewFilterService,
    private router: Router,
    private route: ActivatedRoute,
    private numberPipe: NumberPipe,
    private currency: CurrencyPipe,
    private dataService: DataService,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private userState:UserActivityService
  ) { 
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loader = true;
      this.loadApiData()
    }))

    this.route.params.subscribe((p: any) => {
      this.clientKey = p["clientKey"];
      this.moduleKey = p["moduleKey"];
      this.dashboardKey = p["dashboardKey"];
      this.cardName = p["cardName"];
      this.cardId = p["cardId"];
    })
  }
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2000;
  clientKey:any;
  moduleKey:any;
  dashboardKey:any;
  cardName:any;
  cardId:any;
  params:any
  defaultValue:any;
  // rowData:any=[]
  heading:any
  public columnDefs: ColDef[] = []
  public detailRowHeight = 50;
  parentConfig = [
    // { api_resp_column_nm: 'arrow', api_resp_column_display_nm: ''},
    // { api_resp_column_nm: 'file_nm', api_resp_column_display_nm: 'Filename'},
    // { api_resp_column_nm: 'file_type', api_resp_column_display_nm: 'Filetype'},
    // { api_resp_column_nm: 'column_nm', api_resp_column_display_nm: 'Column Name'},
    // { api_resp_column_nm: 'no_of_errors', api_resp_column_display_nm: '# of errors'},
    // { api_resp_column_nm: 'no_of_failures', api_resp_column_display_nm: '# of failures'}
  ];

  childConfig = [
    // { api_resp_column_nm: 'column_value', api_resp_column_display_nm: 'Column value'},
    // { api_resp_column_nm: 'validation_name', api_resp_column_display_nm: 'Validation name'},
    // { api_resp_column_nm: 'dv_error_type', api_resp_column_display_nm: 'Error type'},
    // { api_resp_column_nm: 'error_message', api_resp_column_display_nm: 'Error message'},
    // { api_resp_column_nm: 'days_open', api_resp_column_display_nm: 'Days Open'},
    // { api_resp_column_nm: 'processed_date', api_resp_column_display_nm: 'Processed date'},
    // { api_resp_column_nm: 'file_record_number', api_resp_column_display_nm: 'File record'}
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    sortable: true,
    suppressMenu:true,
    wrapText:true
  };
  
  reqSubcription: Subscription[] = [];
  
  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };
  public groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  public gridApi!: GridApi;
  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    // this.onBtShowLoading();
  }
 
  // Grid options for ag-Grid
  gridOptions:any = {
    rowStyle: this.getRowStyleInParent.bind(this), // Bind the function to the component
  };

  // Function to define row styles
  getRowStyleInParent(params: any): any {
    return { padding: '5px 0' }; // Add space between rows (e.g., 5px top and bottom)
  }

  // Detail Grid gridConfiguration
  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [],
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        cellClass: 'recordErrorPayment'
      },
      suppressDragLeaveHidesColumns: true,
      rowHeight:50,
      getRowStyle: this.getRowStyle.bind(this)
    },
    getDetailRowData: (params: any) => {
      params.successCallback(params.data.callRecords);
    },
  }

  ngOnInit(): void {
    setTimeout(() => {
      // Auto Size from Element
      const observer = new ResizeObserver((e: any) => {
        this.onSizeChange.emit(e[0].contentRect.height);
      });
      observer.observe(this.paymentRecordDetail?.nativeElement);
    }, 500);


    let parentCfg = this.item.config['list_of_api_col_parent'];
    let detailCfg = this.item.config['list_of_api_col_detail'];
    // console.log('parentCfg',parentCfg)
    // console.log('detailCfg',detailCfg)
     
    // this.loadParentConfig(parentCfg)
    // this.loadChildConfig(detailCfg)
  }
  screenChanges(fullscreen: any) {
    this.isFullscreen = fullscreen
    this.isActive = fullscreen
  }
  exportXls(event:any) {
    if(event) {
      this.gridApi.exportDataAsExcel({
        fileName: this.item.config['report_heading'] || 'List Data', // Set your desired file name here
      });
    }
  }
  // Parent Grid Configuration
  loadParentConfig(data:any) {
    
    this.columnDefs = []
    data[0]['agGroupCellRenderer'] = true;
    
    if(data.length) {
      data.forEach((res: any) => {
        res.property_CB.forEach((cb: any) => {
          if (cb.name == "Add Column") {
            if (cb.value) {
              
              var coldef: any = {
              headerName: res.api_resp_column_display_nm,
              field: res.api_resp_column_nm,
              cellClass: 'cell-wrap-text',
              autoHeight: true,
              menuTabs: [],
              minWidth: 100
              };

              if(res.agGroupCellRenderer) {
                // coldef["cellRenderer"] = 'agGroupCellRenderer';
              }
              
              if(res.api_resp_column_nm == 'file_nm' || res.api_resp_column_nm == 'file_name') {
                coldef["cellRenderer"] = UnderScoreDetailsComponent;
                coldef["minWidth"] = 400;
              }

              // Alignment
              if (res.isAlignment && res.isTextColorCondition) {
                coldef["cellStyle"] = { color: res.isTextColor, textAlign: res.isAlignment.toLowerCase()};
              } else if (res.isAlignment && !res.isTextColorCondition) {
                coldef["cellStyle"] = { textAlign: res.isAlignment.toLowerCase()};
              } else if (res.isTextColorCondition) {
                coldef["cellStyle"] = { color: res.isTextColor };
              }
  
              // Data Types
              if (res.isDataType == "date") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueGetter"] = (params: any) => params.data[res.api_resp_column_nm] ? a + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + b : "";
              }

              // Integer
              if (res.isDataType == "integer") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueFormatter"] = (params: any) => a + " " + params.value + " " + b;
              }
  
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value + " " + b : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + " " + b: "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toUpperCase() + " " + b : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toLowerCase() + " " + b : "";
                }
              }

              this.columnDefs.push(coldef);
  
            }
          }
        })
      })
    }

  }
  
  // Child Grid Configuration
  loadChildConfig(data:any) {

    if(data.length) {
      data.forEach((res: any) => {
        res.property_CB.forEach((cb: any) => {
          if (cb.name == "Add Column") {
            if (cb.value) {
              
              var coldef: any = {
                headerName: res.api_resp_column_display_nm,
                field: res.api_resp_column_nm,
                cellClass: 'cell-wrap-text',
                autoHeight: true,
                menuTabs: [],
                minWidth: 150,
              };
        
              if (res.api_resp_column_nm == "file_record_number") { 
                coldef["minWidth"] = 100;
                // coldef["maxWidth"] = 100;
              }
              if (res.api_resp_column_nm == "days_open") { 
                coldef["minWidth"] = 100;
                // coldef["maxWidth"] = 100;
              }

              if(res.api_resp_column_nm == 'file_nm' || res.api_resp_column_nm == 'file_name') {
                coldef["cellRenderer"] = UnderScoreDetailsComponent;
                coldef["minWidth"] = 350;
              }
      
              if (res.api_resp_column_nm == "error_message") { 
                coldef["minWidth"] = 350;
              }
              
              if (res.api_resp_column_nm == "processed_date") { 
                coldef["valueGetter"] = (params: any) => params.data[res.api_resp_column_nm] ? moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " : "";
              }
      
              // Alignment
              if (res.isAlignment && res.isTextColorCondition) {
                coldef["cellStyle"] = { color: res.isTextColor, textAlign: res.isAlignment.toLowerCase()};
              } else if (res.isAlignment && !res.isTextColorCondition) {
                coldef["cellStyle"] = { textAlign: res.isAlignment.toLowerCase()};
              } else if (res.isTextColorCondition) {
                coldef["cellStyle"] = { color: res.isTextColor };
              }
  
              // Data Types
              if (res.isDataType == "date") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueGetter"] = (params: any) => params.data[res.api_resp_column_nm] ? a + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + b : "";
              }

              // Integer
              if (res.isDataType == "integer") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueFormatter"] = (params: any) => a + "" + this.currency.transform(Number(params.value), "", "", "1.0-2") + "" + b;
              }
  
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value + "" + b : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + "" + b: "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toUpperCase() + "" + b : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase() + "" + b : "";
                }
              }
              
              this.detailCellRendererParams.detailGridOptions.columnDefs.push(coldef);
  
            }
          }
        })
      })
    }
  }


  // API For Parent And Child Data
  loadApiData() {
    
    this.parentData = [];
    this.childData = [];

    let parent = this.item.config["list_of_api_parent"] || {};
    
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      let obj1:any = {"api_key" : 100252}

      let query1 = Object.assign({}, this.filterService.baseQuery, obj1)
      query1["report_typ"] = this.filterService.report_type;
      query1["report_key"] = undefined
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res:any)=> {
          let parentData = res == null ? [] : res;
          if(parentData.length>0) {
            this.childApiData(parentData)
          } else {
            this.rowData = [];
            this.loader = false;
          }
        },(err:any)=>{
          console.log('Payment Error Record Parent', err)
          this.rowData = [];
          this.loader = false;
        })
      )
    }
  }

  childApiData(parentData:any) {
   let config:any= this.userState.getData()
    let detail = this.item.config["list_of_api_detail"] || {};
    let obj2:any = {"api_key" : config['detailConfig'].api_key}

    let query2 = Object.assign({}, this.filterService.baseQuery, obj2)
    query2["report_typ"] = this.filterService.report_type;
    if(!config['detailConfig'].report_key){
    query2["report_key"] = 1}
    this.reqSubcription.push(
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
        let childData  = res == null ? [] : res;
        if(childData.length>0) {
          this.noData=false
          this.Dynamicoldef(childData,parentData )
          // this.getData(childData, parentData)
        } else {
          this.noData=true
          this.rowData =[]
          this.loader = false;
        }
      },(err:any)=>{
        console.log('Payment Error Record Child', err)
        this.rowData = [];
        this.loader = false;
        this.noData=true
      })
    )
  }
  
  filterValue: any;
  // Quick Filter for Type to Search Filter
  onQuickFilterChanged(event: any) {
    this.filterValue = event;
    this.gridApi.setQuickFilter(this.filterValue);
  }
  
  Dynamicoldef(data: any,dataType:any) {
    let coldef: any = []
    const defectDataKeys = Object.keys(data[0]);
    let datatype: any = dataType
  
    defectDataKeys.forEach((col: any,index:any) => {
      let type: any = datatype.find((key: any) => key.column_nm == col)
      if (type != undefined && type['data_type'] == 'number') {
        coldef.push({ headerName: type['header_name'], field: col, minWidth: 130,  valueFormatter: (params: any) => this.currency.transform(Number(params.value), "", "", "1.0-2"), cellStyle: { textAlign: type['alignment'] }, headerClass: 'texts-' + type['alignment'] })

      } else if (type != undefined && type['data_type'] == 'date') {
        coldef.push({ headerName: type['header_name'], field: col, minWidth: 130,valueFormatter: (params: any) => moment(params.value).format("MM/DD/YYYY"), cellStyle: { textAlign: type['alignment'] }, headerClass: 'texts-' + type['alignment'] })

      } else if (type != undefined && type['data_type'] == 'currency') {
        coldef.push({ headerName: type['header_name'], field: col, minWidth: 130, valueFormatter: (params: any) => this.currency.transform(Number(params.value)), cellStyle: { textAlign: type['alignment'] }, headerClass: 'texts-' + type['alignment'] })

      } else if (type != undefined && type['data_type'] == 'percentage') {
        coldef.push({ headerName: type['header_name'], field: col, minWidth: 130, valueFormatter: (params: any) => params.value + '%', cellStyle: { textAlign: type['alignment'] }, headerClass: 'texts-' + type['alignment'] })

      } else {
        coldef.push({ headerName: type['header_name'] ? type['header_name'] :this.initCap(col), field: col, minWidth: 130, })
      }
      if(type['tooltip']=='T' || type['tooltip']=='t'){
        coldef[index].tooltipValueGetter=(p: ITooltipParams) => `<span> ${p.value}</span>`
        coldef[index]['tooltipComponent'] = CustomTooltipComponent
      }
    });
    this.columnDefs = coldef
    this.rowData = data;
    this.loader = false;
  }
  
 initCap(str: string): string {
  str=str.toLowerCase().replace(/_/g,' ')
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
      return match.toUpperCase();
    });
  }
  getData(childData:any, parentData:any) {
    
    for (let index = 0; index < parentData.length; index++) {
      const parent = parentData[index];
      parent['callRecords'] = [];
      
      const fndChild = childData.filter((child:any)=> 
        parent['file_nm']==child['file_nm'] 
      && parent['column_nm']==child['column_nm'] 
      && parent['file_type']==child['file_type'] 
      && parent['processed_date']==child['processed_date']);

      if(fndChild) {
        parent['callRecords'] = [...fndChild];
      }
    }
    this.rowData = [...parentData]
    this.loader = false;
  }

  // Function to define row styles
  getRowStyle(params: any): any {
    if (params.node.rowIndex % 2 === 0) {
      // Even row
      return { background: '#FAFAFB' }; // Set your background color
    } else {
      // Odd row
      return {};
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
