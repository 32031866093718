import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-transaction-detail-popup",
  templateUrl: "./transaction-detail-popup.component.html",
  styleUrls: ["./transaction-detail-popup.component.scss"],
})
export class TransactionDetailPopupComponent implements OnInit, OnChanges {
  @Input("transactionName") transactionName: any = "Unknown Transaction";
  @Input("transactionData") transactionData: any = [{ key: "No Data Found", Value: [] }];
  @Output() showGanttTooltip = new EventEmitter();
  obj = [
    {
      Key: "General",
      Value: [
        { Key: "StatusDate", Value: "2021-08-26" },
        { Key: "Duration", Value: "515" },
      ],
    },
    {
      Key: "Prescriber",
      Value: [
        { Key: "PrescriberNPI", Value: "1366474892" },
        { Key: "PrescriberName", Value: "BOGLARKAGYURKOCZA" },
        { Key: "PrescriberAddress", Value: "1275YORKAVENUENEWYORKNY" },
      ],
    },
    {
      Key: "Status",
      Value: [
        { Key: "StatusDate", Value: "2021-08-26" },
        { Key: "StatusCodes", Value: "AS01" },
        { Key: "StatusDescription", Value: "SHIPPEDTOPATIENT" },
      ],
    },
  ];

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.transactionName == 'status')
    {

      this.transactionName = "Active " + this.transactionName.charAt(0).toUpperCase() + this.transactionName.slice(1)
    } else {
      this.transactionName = this.transactionName.charAt(0).toUpperCase() + this.transactionName.slice(1)
    }
    if (this.transactionName == "unknown" || this.transactionName == null || this.transactionName == undefined || this.transactionName == '') {
      this.hideTooltip();
    }
  }
  ngOnInit(): void { }

  hideTooltip() {
    this.showGanttTooltip.next(false);
  }

  ngOnDestroy() {

  }
}
