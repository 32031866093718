
  <div  style="font-family: Poppins !important">
    <main>
      <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
        <div class="m-1 mb-2">
          <icon-chart 
              [headerConfig]="headerConfig" 
              [iconList]="iconList" 
              [data]="HbarChartData" 
              [pageKey]="pageKey" 
              [heading]="heading"
              [chartName]="divId" 
              [config]="SlopeChart" 
              [expand]="fs" 
              [fullscreen]="isFullscreen"
              [item]="item"
            ></icon-chart>
        </div>
        <div *ngIf="item?.config?.list_of_api == 100610" ><hr></div>
        <div><loading *ngIf="isLoading"> </loading></div> 
        
        <!-- <hr> -->
        <div style="height: 500px; width: auto; " *ngIf="noData">
          <span class="bg-text" >There is no data for this time period</span></div>
        <div  #SlopeChart id="HBarChartDiv" [style.display]="noData?'none':''" ></div>
       
        <div id="d3hbarTooltip">
            <div class="container chart_tooltip" *ngIf="tooltip" >
              <div class="row chart_tooltip_header">
                <div class="col-8 d-flex flex-column mt-1 mb-1">
                  <div class="chart_tooltip_header_heading ">
                    {{mytooltipData?.name }}
                  </div>
                 
                </div>
              </div>
              <div class="mt-1 " [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                  <div class="w-100 d-flex mb-1 align-items-left">
                    <div>
                      <div class="tooltip_outlay" [style.background]="'#1360D8'" ></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2 ">{{numberFormating(mytooltipData?.value)}}</div>
                    
                  </div>
                  <div class="w-100 d-flex mb-1 align-items-left">
                    <div>
                      <div class="tooltip_outlay" [style.background]="'#FFB039'" ></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2 mb-1">{{numberFormating(mytooltipData?.value2)}}</div>
                    
                  </div>
              </div>
    
            </div>
          </div>
      </article>
    </main>
  </div>