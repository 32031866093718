import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-custom-kpi',
  templateUrl: './custom-kpi.component.html',
  styleUrls: ['./custom-kpi.component.scss']
})
export class CustomKpiComponent implements OnInit, OnDestroy {
  static key = 100805;
  @Input('config') config: any = {}
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  kpi_1_value: any
  reqSubcription: Subscription[] = []
  isOpen: boolean = false;
  infoData: any
  loader: boolean = true;
  cardName: any;
  cardId: any
  isPortrait = false
  constructor(private filterService: FilterService,
    private dataService: DataService,
    private currency: CurrencyPipe,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private newFilterService: NewFilterService,
    ) {

      this.reqSubcription.push(this.filterService.filterQuery.subscribe((query:any)=>{
        this.loadApiData();
      }))

      this.newFilterService.showBy.subscribe((r:any)=> {
        this.showByText = r ? r : ''
      })
  }
  
  customHeader:any;
  kpi1Value:any='';
  kpi2Value:any ='';
  unit:any="";
  dbData:any;
  showByText:any;
  presId:any;
  headerName:any;

  ngOnInit(): void {
   
    this.customHeader = this.item.config?.kpi_header_value?.headerControls?.[0]; 
     
    this.route.params.subscribe((p: any) => {
      let clientKey = p["clientKey"];
      let moduleKey = p["moduleKey"];
      let dashboardKey = p["dashboardKey"];
      this.cardName = p["cardName"];
      this.cardId = p["cardId"];

      // if(this.cardName == 'prCard') {
      //   // this.presId = this.cardId 
      // } else if (this.cardName=='pwt') {
      //   this.route.queryParams.subscribe((params:any) => {
      //     if(this.cardId == 'prCard') {
      //       // this.presId = params.presId 
      //     }
      //   })
      // }
    })

    
  }

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger.closeMenu();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.item) {
      if (!this.item.config['icon'])
        this.item.config['icon'] = { name: 'question_circle' }
    }
  }
  getInfoDetail() {
     
    let data = {
      api_key: this.item.config?.custom_kpi_value_api?.api_key,
      widget_key: this.item.config.widgetKey,
      page_key: this.pageKey,
      report_typ: 'D',
      brand_key: this.filterService.baseQuery?.brand_key
    }
    this.reqSubcription.push(this.dataService.getInfoDetail(data).subscribe((res: any) => {
      this.infoData = res && res[0] ? res[0] : { info_header: "No Info Data Found" }
    }))
    return this.isOpen = !this.isOpen
  }


  getStyle(): string {
    return 'background-color:#9900ff'
  }
  getValue(item: any, value: any) {
    return item ? item[value] : ''
  }
  numbedPipe(value: any) {
    if (value == 'NA') return value
    return this.currency.transform(parseInt(value), '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }

 
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortrait = window.innerWidth < window.innerHeight;
    if (this.isPortrait) {
    } else {
    }
  }

  ngAfterViewInit() {
    this.checkOrientation()
  }

  cancel() {
    document.getElementById("setting")?.click()
  }

  loadApiData() {
    this.loader = true;
    let kpi1 = this.item.config['custom_kpi_value_api']
    console.log('this.item.config',this.item.config)

    if (kpi1.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj:any = {"api_key" : kpi1.api_key } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          if(res!=null) {
            // res = [
            //   {
            //       "default_qty": 8472051164.0,
            //       "default_qty_suffix": "Dupi Test",
            //       "converted_qty": 8585.99,
            //       "converted_qty_prefix": "Packs"
            //   }
            // ]
            this.dbData = res[0]
    
            this.headerName = res[0]?.[this.customHeader?.headerNameDrop]
            this.kpi1Value = res[0]?.kpi_value_1
            this.kpi2Value = res[0]?.kpi_value_2
            // this.kpi2Value = '38'
            this.unit = res[0]?.uom_1
    
            // showby UOM
            let swapUOM:any = this.item?.config?.['kpi_header_value']?.controls
            let fndIdx:any;
            if(this.showByText) {
              if(swapUOM.length) {
                swapUOM.find((f:any, index:any)=> {
                  if (this.dbData?.[f.prefixNameDrop]?.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (this.dbData?.[f.suffixNameDrop]?.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (f.suffixName.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (f.prefixName.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  }
    
                  if (fndIdx >= 0 && fndIdx < swapUOM.length) {
                    const itemToMove = swapUOM[fndIdx];
                    swapUOM = [itemToMove, ...swapUOM.slice(0, fndIdx), ...swapUOM.slice(fndIdx + 1)];
                  }
                  this.item.config['kpi_header_value'].controls = [...swapUOM]
                })
              }
            }
          }
          this.loader=false;
        }, (err: any) =>{
          this.loader=false;
          console.log(err)
        })
      )
    }
  }

  isEmptyObject(obj:any) {
    return Object.keys(obj).length === 0;
  }
  
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }
}
