
<div id="ganttChart" #GanttChartMainContainer></div>

<div id="d3Tooltip"> 
  <app-transaction-detail-popup *ngIf="showGanttTooltip" [transactionName]="transactionName" [transactionData]="transactionData" (showGanttTooltip)="toolTipHide()"></app-transaction-detail-popup>
  <app-simple-change-popup *ngIf="showChangeTooltip" [transactionName]="transactionName" [transactionData]="transactionData" (showChangeTooltip)="toolTipHide()"></app-simple-change-popup>
  <app-pending-information-popup *ngIf="showPendingTooltip" [pendingInformation]="pendingInformation" (showPendingTooltip)="tooltipPendingHide()"></app-pending-information-popup>
  <div *ngIf="showPointerTooltip" (mouseout)="showPointerTooltip = !showPointerTooltip">
    <div class="pointer_container p-1">
      <div class="pointer_text">
        {{pointerTooltipData.replaceAll('br>', ': ').replaceAll('<','').replaceAll('undefined days','0')}}
      </div>
    </div>
  </div>
</div>