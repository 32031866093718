<div style="border-radius: 10px;">
  <div>
    <div class="p-2" style="background-color: #F6F8FB;border-radius: 10px 10px 0 0 ;" >
        <div class="d-flex justify-content-between align-items-center">
            <div>
              <span style="font-size: 14px;">{{ paramsData.data['period'] =="1900-01-01T00:00:00"?overAllDate(): paramsData.data['period'] | date:'dd MMMM, yyyy'}}</span>
              <h2 style="font-family: 'poppins';margin:0px !important;line-height: 15px;font-weight: 600;font-size: 16px;">{{"Detailed information"}}</h2>
            </div>
            <div class="p-2" style="margin: 0 0 -5px 0px !important;">
              <mat-icon (click)="closeInst()" class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
            </div>
        </div>
    </div>
    
    <div class="ir-comment-popup-desktop">
      <div class="row" style="padding: 10px 0 0;">
          <div class="col-5 mycontent-left">
            <div style="overflow: auto;" [style.height]="this.deviceDetectionService.isMobile ? '170px' : 'auto' ">
              <table style="font-size: 13px;" >
                  <tr>
                    <td >{{(paramsData?.params?.sourceName)? ((filterService.showByKey && filterService.showByKey=='N') ? 'NDC:' :  'Location:'):('SP:') }}</td>
                    <td  [matTooltip]="paramsData.colDef.headerName" matTooltipPosition="above" class="b dotedtext " >{{paramsData.colDef.headerName||' NO NAME'}}</td>
          
                  </tr>
                  <tr *ngIf="(paramsData?.params?.sourceName)">
                    <td>NCPDP:</td>
                    <td class='b'>{{data['ncpdp_no']||"Not Available"}}</td>
          
                  </tr>
                  <tr *ngIf="(paramsData?.params?.sourceName)">
                    <td>Location NPI:</td>
                    <td class='b'>{{data['npi_no']||"Not Available"}}</td>
          
                  </tr>
                  <tr>
                    <td>Difference %:</td>
                    <td class='b'>{{NegnumbedPipe((data['difference']/data['dispense_quantity'])*100) || 0}}%</td>
          
                  </tr>
                  <tr>
                    <td>Total Difference:</td>
                    <td class='b'>{{ numbedPipe(data['difference'])||0}}</td>
          
                  </tr>
                  <tr>
                    <td>Inventory Dispenses:</td>
                    <td class='b'>{{numbedPipe(data['inventory_quantity'])||"Not Available"}}</td>
          
                  </tr>
                  <tr>
                    <td>Shipment Dispenses:</td>
                    <td class='b'>{{numbedPipe(data['dispense_quantity'])||"Not Available"}}</td>
          
                  </tr>
                  <tr *ngIf="Cumulative">
                    <td> Cumulative Difference:</td>
                    <td class='b'>{{numbedPipe(data['cumulative_qty_difference'])||"Not Available"}}</td>
          
                  </tr>
                  <tr *ngIf="Cumulative">
                    <td> Cumulative Inventory Dispenses:</td>
                    <td class='b'>{{numbedPipe(data['inventory_cumulative_qty'])||"Not Available"}}</td>
          
                  </tr>
                  <tr *ngIf="Cumulative">
                    <td> Cumulative Shipment Dispenses:</td>
                    <td class='b'>{{numbedPipe(data['dispense_cumulative_qty'])||"Not Available"}}</td>
          
                  </tr>
                  <tr>
                    <td>Week-Ending:</td>
                    <td class='b'>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?overAllDate():paramsData.data['period'] | date:'MM/dd/yyyy'}}</td>
          
                  </tr>
                  <tr>
                    <td>Inventory Failures:</td>
                    <td class='b'>{{data?.['Inventory_Failed_Quantity']?data['Inventory_Failed_Quantity']:'0' +" FAILURES" }}</td>
          
                  </tr>
              </table>
            </div>
          </div>
          <div class="col-7">
              <div >
                  <span>Comments: {{numbedPipe(messages?.length)}}</span>
                  <div style="overflow: auto;margin: 0 5px;min-width: 50px;"  [style.height.px]="this.deviceDetectionService.isMobile ? '130' : paramsData?.params?.sourceName ?(Cumulative?'335':'230'): (Cumulative?'335':'180') " > <!--  [style]="getStyles()" -->
                      <div id="content">
                      <div *ngFor="let message of messages; let i = index">
                        <div style="display: flex; margin-top:8px;">
                          <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                            <!-- menu for comments -->
                              
                            <mat-menu #menuBeforeCard="matMenu" xPosition="before">
                                <button mat-menu-item style="text-align: left;" (click)="editComment(message,$event,i)">
                                  <mat-icon style="width: 20px;" svgIcon="edit"></mat-icon>  
                                  <span style="font-family: 'poppins';font-size: 12px;">Edit</span>
                                </button>
                                  
                                <button mat-menu-item style="text-align: left;" (click)="DelDialog('300ms', '150ms')">
                                  <mat-icon style="width: 20px;" svgIcon="delete"></mat-icon>
                                  <span style="font-family: 'poppins';font-size: 12px;">Delete</span>
                                </button>
                            </mat-menu>
  
              
                       
                          <div class="card"
                            style="background-color:#F6F8FB;margin-right:8px ;border-radius: 0px 5px 5px 3px;padding: 5px; position: relative;width: 80%;">
                            <div style="display: flex;justify-content: space-between;">
                              <div style="width: 212px;font-size: 10px;font-weight: 600; float: left;">
                                <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                                <span *ngIf="employee_key._value == message.employee_key" style="color: blue;margin: 2px;">(You)</span>
                                <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                              </div>
                              <div style="width: 82px;font-size: 9px;font-weight: 400; float: right;white-space: nowrap;">
                                <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                              </div>
                            </div>
                            <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                              <span *ngIf="!edit || editIndex!=i" [innerHtml]="getMSGHtml(message.comments)" ></span>
                              <textarea  rows="4" cols="33" *ngIf="edit && editIndex==i" style="border: 1px solid rgb(221, 221, 221);border-radius: 10px;padding: 5px;width: 100%;" [(ngModel)]="editedValue" maxlength="250" type="text" id="edit"
                              (keydown.enter)="SaveEdit(message ,i)"
                              [value]="message.comments"></textarea>
                              <div  *ngIf="edit && editIndex==i"style="width: 100%;font-size: 9px;line-height: 0px;margin-top: 14px;text-align: right;" >{{editedValue.length+'/250'}}</div>
                               <div style="width: 100%;display: flex;justify-content: flex-end;">
                                  <span *ngIf="edit && editIndex==i && editedValue==''" style="color: #d02e2e;margin: auto;">Please type a message to continue</span>
                              <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                                (click)="SaveEdit(message ,i)"><mat-icon>check</mat-icon></button>
                              <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                                (click)="edit=false"><mat-icon>close</mat-icon></button>
                              </div>
                            </div>
                             <!-- Delete Popup -->
                        <ng-template #DeleteDialog>
                          <div>
                            <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                              <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                              <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                                <mat-icon class="close-icon"
                                  style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                              </button>
                            </div>
                            <div style="height: 40px; margin: 10px auto; text-align: center;">
                              Are you sure you want to delete this comment?
                            </div>
                            <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                              <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                              <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                              <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                                mat-dialog-close  cdkFocusInitial >Cancel</button>
                              <button mat-raised-button style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
                                (click)="deleteComment()">Delete </button>
              
                            </div>
                          </div>
                        </ng-template>
                          </div>
                          <mat-icon [matMenuTriggerFor]="menuBeforeCard" *ngIf="employee_key._value == message.employee_key && !edit"
                            style="align-self: center;cursor: pointer;"
                            svgIcon="menu" (click)="getcomentData(message,i)"></mat-icon>
  
                            
                        </div>
                       
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  
      <div class="row mb-2">
          <div class="col-5" style="place-self: center;" >
              <div class="leftBottomBody" >
                  <span  *ngIf="data['discrepancy']=='Y' && this.data['difference'] == 0" style="color: #D11044 !important; font-size: 12px">
                      <svg style="margin: 1px 0px 3px 1px;" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M5 7C5.13807 7 5.25 6.88807 5.25 6.75C5.25 6.61193 5.13807 6.5 5 6.5C4.86193 6.5 4.75 6.61193 4.75 6.75C4.75 6.88807 4.86193 7 5 7Z"
                              fill="#D11044"
                          />
                          <path
                              d="M5 5V3M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5ZM5.25 6.75C5.25 6.88807 5.13807 7 5 7C4.86193 7 4.75 6.88807 4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75Z"
                              stroke="#D11044" stroke-linecap="round" stroke-linejoin="round" 
                          />
                      </svg>
                      Has differences at locations level.
                  </span>
                  <span *ngIf="data['discrepancy']=='N'" style="color: green !important; font-size: 12px">No differences at locations level.</span>
              </div>
          </div>
          <div class="col-7" style="place-self: center;">
            <div class="bottomBoth">
              <div style="width: 100%; display: flex;">
                <div style="width: 90%;" class="matlines"> 
                  <mat-form-field  style="width:100% ;"   appearance="outline">
                      <textarea 
                        matInput id="comment"
                        #searchInput="ngModel"
                        class="firstTabinIRScrolls"
                        cdkTextareaAutosize
                        placeholder="Add comment here"
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        [(ngModel)]="searchValue" maxlength="250"
                        (click)="$event.stopPropagation()" (keydown.enter)="messageData(searchValue ,$event)"
                        cdkAutosizeMaxRows="3"
                        required>
                      </textarea>
                   </mat-form-field>
                  <!-- <input id="comment" style="font-size: 12px;color: #8d8b8b;" class="form-control"
                 type="text" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)" class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue" maxlength="250"> -->
                </div>
                 <div style=" display: flex;align-items: center; width: 10%;font-size: 9px;margin-top: 5px;padding-left: 5px;" ><span>{{searchValue.length+'/250'}}</span> </div>
             </div>
              <div style=" display: flex; align-items: center;">
                  <mat-icon class="matIcons" (click)="messageData(searchValue ,$event)" svgIcon="send"></mat-icon>
              </div>
            </div>
  
            <!-- <div *ngIf="searchInput.invalid && (searchInput.dirty || searchInput.touched)" style="height:20px">
              <span *ngIf="searchInput.errors?.['required']" style="color: red;">This field is required.</span>
              <span *ngIf="searchInput.errors?.['maxlength']" style="color: red;">Maximum {{ 250 }} characters allowed.</span>
            </div> -->
            <div class="bottomBoth" *ngIf="!(paramsData?.params?.sourceName)">
              <div>
                <mat-slide-toggle  [(ngModel)]="isChecked" (click)="$event.stopPropagation()"> <span style="font-family: poppins !important;">Reconcile</span></mat-slide-toggle>
              </div>
              <div>
                <button mat-raised-button [disabled]="!isChecked" color="primary" style="padding: 13px" (click)="$event.stopPropagation()"><span
                    style="margin: -18px -12px;position: absolute;font-family: 'Poppins' !important;">Apply</span></button>
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="ir-comment-popup-tablet">
      <mat-tab-group class="irCommentTab" [selectedIndex]="selectedTabIndex" (selectedIndexChange)="seletedTab($event)">
        <mat-tab >
          <ng-template mat-tab-label>{{'Information'}}</ng-template>
          <div class="firstTabinIR">
  
            <div class="firstTabinIRScroll" style="overflow: auto;padding: 15px 0;text-align: center;text-align: -webkit-center;">
              <table  style="font-size: 13px;" >
                <tr>
                  <td >{{(paramsData?.params?.sourceName) ? ((filterService.showByKey && filterService.showByKey=='N') ? 'NDC:' :  'Location:'):('SP:') }}</td>
                  <td [matTooltip]="paramsData.colDef.field" matTooltipPosition="above" class="b dotedtextMobile " >{{paramsData.colDef.field||' NO NAME'}}</td>
        
                </tr>
                <tr *ngIf="(paramsData?.params?.sourceName)">
                  <td>NCPDP:</td>
                  <td class='b'>{{data?.['ncpdp_no']||"Not Available"}}</td>
        
                </tr>
                <tr *ngIf="(paramsData?.params?.sourceName)">
                  <td>Location NPI:</td>
                  <td class='b'>{{data?.['npi_no']||"Not Available"}}</td>
        
                </tr>
                <tr>
                  <td>Difference %:</td>
                  <td class='b'>{{NegnumbedPipe((data['difference']/data['dispense_quantity'])*100) ||0}}%</td>
        
                </tr>
                <tr>
                  <td>Total Difference:</td>
                  <td class='b'>{{ numbedPipe(data['difference'])||0}}</td>
        
                </tr>
                <tr>
                  <td>Inventory Dispenses:</td>
                  <td class='b'>{{numbedPipe(data['inventory_quantity'])||"Not Available"}}</td>
        
                </tr>
                <tr>
                  <td>Shipment Dispenses:</td>
                  <td class='b'>{{numbedPipe(data['dispense_quantity'])||"Not Available"}}</td>
        
                </tr>
                <tr *ngIf="Cumulative">
                  <td> Cumulative Difference:</td>
                  <td class='b'>{{numbedPipe(data['cumulative_qty_difference'])||"Not Available"}}</td>
        
                </tr>
                <tr *ngIf="Cumulative">
                  <td> Cumulative Inventory Dispenses:</td>
                  <td class='b'>{{numbedPipe(data['inventory_cumulative_qty'])||"Not Available"}}</td>
        
                </tr>
                <tr *ngIf="Cumulative">
                  <td> Cumulative Shipment Dispenses:</td>
                  <td class='b'>{{numbedPipe(data['dispense_cumulative_qty'])||"Not Available"}}</td>
        
                </tr>
                <tr>
                  <td>Week-Ending:</td>
                  <td class='b'>{{ paramsData.data['period'] =="1900-01-01T00:00:00"?overAllDate():paramsData.data['period'] | date:'MM/dd/yyyy'}}</td>
        
                </tr>
                <tr>
                  <td>Inventory Failures:</td>
                  <td class='b'>{{data?.['Inventory_Failed_Quantity']?data['Inventory_Failed_Quantity']:'0' +" FAILURES" }}</td>
        
                </tr>
              </table>
            </div>
            <div class="leftBottomBodyMobile">
              <span  *ngIf="data['discrepancy']=='Y'" style="color: #D11044 !important; font-size: 12px">
                  <svg style="margin: 1px 0px 3px 1px;" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M5 7C5.13807 7 5.25 6.88807 5.25 6.75C5.25 6.61193 5.13807 6.5 5 6.5C4.86193 6.5 4.75 6.61193 4.75 6.75C4.75 6.88807 4.86193 7 5 7Z"
                          fill="#D11044"
                      />
                      <path
                          d="M5 5V3M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5ZM5.25 6.75C5.25 6.88807 5.13807 7 5 7C4.86193 7 4.75 6.88807 4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75Z"
                          stroke="#D11044" stroke-linecap="round" stroke-linejoin="round" 
                      />
                  </svg>
                  Has differences at locations level.
              </span>
              <span *ngIf="data['discrepancy']=='N'" style="color: green !important; font-size: 12px">No differences at locations level.</span>
            </div>
          </div>
  
        </mat-tab>
        <mat-tab >
          <ng-template mat-tab-label>Comments: {{numbedPipe(messages?.length)}}</ng-template>
            <div style="padding: 5px;">
              <!-- <h4>Comments: {{numbedPipe(messages?.length)}}</h4> -->
              <div class="secondTabHeight" > <!--  [style]="getStyles()" -->
                <div id="content">
                <div *ngFor="let message of messages; let i = index">
                  <div style="display: flex; margin-top:8px;">
                    <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                      <!-- menu for comments -->
                        
                      <mat-menu #menuBeforeCard="matMenu" xPosition="before">
                        <button mat-menu-item style="text-align: left;" (click)="editComment(message,$event,i)">
                          <mat-icon style="width: 20px;" svgIcon="edit"></mat-icon>  
                          <span style="font-family: 'poppins';font-size: 12px;">Edit</span>
                        </button>
                          
                        <button mat-menu-item style="text-align: left;" (click)="DelDialog('300ms', '150ms')">
                          <mat-icon style="width: 20px;" svgIcon="delete"></mat-icon>
                          <span style="font-family: 'poppins';font-size: 12px;">Delete</span>
                        </button>
                      </mat-menu>
        
                 
                    <div class="card messageScrollandCss">
                      <div style="display: flex;justify-content: space-between;">
                        <div style="width: 212px;font-size: 10px;font-weight: 600; float: left;line-height: 12px;margin: 0 0 5px;">
                          <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName"}}</span>
                          <span *ngIf="employee_key._value == message.employee_key" style="color: blue;margin: 2px;">(You)</span>
                          <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                        </div>
                        <div style="width: 82px;font-size: 9px;font-weight: 400; float: right;line-height: 12px;direction: rtl;white-space: nowrap;">
                          <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span>
                        </div>
                      </div>
                      <div style="font-size: 12px;color: #8d8b8b;font-family: 'Poppins'!important;">
                        <span *ngIf="!edit || editIndex!=i" [innerHtml]="getMSGHtml(message.comments)"></span>
                        <textarea  rows="4" cols="33" *ngIf="edit && editIndex==i" style="border: 1px solid rgb(221, 221, 221);border-radius: 10px;padding: 5px;width: 100%;" [(ngModel)]="editedValue" maxlength="250" type="text" id="edit"
                        (keydown.enter)="SaveEdit(message ,i)"
                          [value]="message.comments"></textarea>
                          <div *ngIf="edit && editIndex==i" style="width: 100%;font-size: 9px;line-height: 0px;margin-top: 4px;text-align: right;" >{{editedValue.length+'/250'}}</div>
                          <div style="width: 100%;display: flex;justify-content: flex-end;">
                            <span *ngIf="edit && editIndex==i && editedValue==''" style="color: #d02e2e;margin: auto;">Please type a message to continue</span>
                        <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                          (click)="SaveEdit(message ,i)"><mat-icon>check</mat-icon></button>
                        <button mat-icon-button *ngIf="edit && editIndex==i" color="primary"
                          (click)="edit=false"><mat-icon>close</mat-icon></button>
                        </div>
                      </div>
                       <!-- Delete Popup -->
                  <ng-template #DeleteDialog>
                    <div>
                      <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                        <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                        <button mat-icon-button class="close-button" [mat-dialog-close]="true" style="margin-right: 1px;">
                          <mat-icon class="close-icon"
                            style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                        </button>
                      </div>
                      <div style="height: 40px; margin: 10px auto; text-align: center;">
                        Are you sure you want to delete this comment?
                      </div>
                      <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                        <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                        <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                        <button mat-raised-button style="background-color: white !important;color: #1363DF;border: 1px solid;"
                          mat-dialog-close  cdkFocusInitial >Cancel</button>
                        <button mat-raised-button style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;" mat-dialog-close
                          (click)="deleteComment()">Delete </button>
        
                      </div>
                    </div>
                  </ng-template>
                    </div>
                    <mat-icon [matMenuTriggerFor]="menuBeforeCard" *ngIf="employee_key._value == message.employee_key && !edit"
                      style="align-self: center;cursor: pointer;"
                      svgIcon="menu" (click)="getcomentData(message,i)"></mat-icon>
                  </div>
                 
                </div>
              </div>
            </div>
            <div style="place-self: center;">
              <div class="bottomBoth bottomBothMobile">
                <div>
                  <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
                </div>
                <div style="width: 100%; display: flex;align-items: center;">
                <div style="width: 90%;"> <input id="comment"  #searchInput="ngModel" style="font-size: 12px;color: #8d8b8b;" class="form-control"
                 type="text" (click)="$event.stopPropagation()" (keyup.enter)="messageData(searchValue ,$event)" class="form-control" placeholder="Add comment here" [(ngModel)]="searchValue" maxlength="250"></div>
                 <div style="font-size: 9px;padding-left: 5px;" >{{searchValue.length+'/250'}}</div>
             </div>
                <div>
                    <mat-icon class="matIcons" (click)="messageData(searchValue ,$event)" svgIcon="send"></mat-icon>
                </div>
              </div>
              <!-- <div *ngIf="searchInput.invalid && (searchInput.dirty || searchInput.touched)&& searchInput" style="height:20px">
                <span *ngIf="searchInput.errors?.['required']" style="color: red;">This field is required.</span>
                <span *ngIf="searchInput.errors?.['maxlength']" style="color: red;">Maximum {{ 250 }} characters allowed.</span>
              </div> -->
              <div class="bottomBoth" *ngIf="!(paramsData?.params?.sourceName)">
                <div>
                  <mat-slide-toggle  [(ngModel)]="isChecked" (click)="$event.stopPropagation()"> <span style="font-family: poppins !important;">Reconcile</span></mat-slide-toggle>
                </div>
                <div>
                  <button mat-raised-button [disabled]="!isChecked" color="primary" style="padding: 15px" (click)="$event.stopPropagation()"><span
                      style="margin: -18px -12px;position: absolute;">Apply</span></button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>