import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  public apiBaseUrl: any;
  module: any;
  moduleKey:any

  linkPageNavigation:any = {}
  getPageKey:any = [];
  dashboardKey:any;
  
  constructor(private apiService: ApiService) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getModuleDetails() {
    return this.module;
  }
  setModuleDetails(module: any) {
    this.module = module;
  }

  getModuleAccess(obj: any) {
    let body = {
      username: obj.username,
      client_key: obj.client_key,
    };
    let url = `api/get-module-access`;
    return this.apiService.post(url, body);
  }

  getDashboardAccess(obj: any) {
    let body = {
      username: obj.username,
      client_key: obj.client_key,
      module_key: obj.module_key,
    };
    let url = `api/get-dashboard-access`;
    return this.apiService.post(url, body);
  }

  getPageAccess(obj: any) {
    let url = `api/get-page-access`;
    return this.apiService.post(url, obj);
  }

  getPageDetails(body: any) {
    let url = `kpi/get-json-file-data`;
    return this.apiService.post(url, body);
  }

  getRefreshedLastDate(obj:any) {
    if (!obj['brand_key']) return new Observable((ob) => ob.next(undefined));
    let url = `kpi/get-last-refreshed-date`;
    return this.apiService.post(url, obj);
  }

  getAllUserForImpersonate() {
    let url = `api/get-all-users`
    return this.apiService.get(url)
  }
}
