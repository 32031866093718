export default {
  type: 'group',
  name: 'overview',
  encode: {
    enter: {
      fill: { value: 'transparent' }
    },
    update: {
      height: { signal: 'max(overviewHeight, 30)' },
      width: { signal: 'width' },
      y: { signal: 'detailHeight + 70' },
    }
  },
  signals: [{
    name: 'brush', init: '[0, width]',
    on: [{
      events: '@overview:pointerdown',
      update: '[x(), x()]'
    }, {
      events: '[@overview:pointerdown, window:pointerup] > window:pointermove!',
      update: '[brush[0], clamp(x(), 0, width)]'
    }, {
      events: { signal: 'brushDelta' },
      update: 'clampRange([brushAnchor[0] + brushDelta, brushAnchor[1] + brushDelta], 0, width)'
    }, {
      events: { signal: 'leftHandle' },
      update: 'clampRange([leftHandle, brush[1]], 0, width)',
    }, {
      events: { signal: 'rightHandle' },
      update: 'clampRange([brush[0], rightHandle], 0, width)',
    }, {
      events: { signal: 'width', },
      update: '[0, width]',
    }]
  }, {
    name: 'brushAnchor', value: null,
    on: [{ events: '@brush:pointerdown', update: 'slice(brush)' }]
  }, {
    name: 'brushClick', value: 0,
    on: [{ events: '@brush:pointerdown', update: 'x()' }]
  }, {
    name: 'brushDelta', value: 0,
    on: [{
      events: '[@brush:pointerdown, window:pointerup] > window:pointermove!',
      update: 'x() - brushClick'
    }]
  }, {
    name: 'leftHandle',
    on: [{ events: '[@leftHandle:pointerdown, window:pointerup] > window:pointermove!',
      update: 'x()' }],
  }, {
    name: 'rightHandle',
    on: [{ events: '[@rightHandle:pointerdown, window:pointerup] > window:pointermove!',
      update: 'x()' }],
  }, {
    name: 'detailDomain',
    push: 'outer',
    on: [{
      events: { signal: 'brush' },
      update: 'span(brush) ? invert("xOverview", brush) : null'
    }]
  }],

  scales: [{
    name: 'xOverview',
    type: 'time',
    range: 'width',
    domain: { data: 'series', field: 'date' },
  }, {
    name: 'yOverview',
    type: 'linear',
    range: { signal: '[max(overviewHeight, 30), 0]' },
    domain: { data: 'series', field: 'value' },
    nice: true, zero: true
  }],

  marks: [{
    type: 'line',
    from: { data: 'series' },
    encode: {
      enter: {
        strokeWidth: { value: 2 },
        interpolate: { value: 'monotone' },
      },
      update: {
        x: { scale: 'xOverview', field: 'date' },
        y: { scale: 'yOverview', field: 'value' },
        stroke: { signal: 'color' },
      },
    }
  }, {
    type: 'rect',
    name: 'brushBorder',
    encode: {
      enter: {
        x: { value: 0 },
        y: { value: 0 },
        stroke: { value: '#E8EAEE' },
      },
      update: {
        width: { signal: 'width' },
        height: { signal: 'max(overviewHeight, 30)' },
      }
    }
  }, {
    type: 'rect',
    name: 'brush',
    encode: {
      enter: {
        y: { value: 0 },
        fill: { value: '#333' },
        fillOpacity: { value: 0.2 },
        cursor: { value: 'move' },
      },
      update: {
        height: { signal: 'max(overviewHeight, 30)' },
        x: { signal: 'brush[0]' },
        x2: { signal: 'brush[1]' }
      }
    }
  }, {
    type: 'group',
    encode: {
      update: {
        x: { signal: 'brush[0]' },
        y: { signal: '(max(overviewHeight, 30) - 30)/2' },
      },
    },
    marks: [{
      type: 'rect',
      name: 'leftHandle',
      encode: {
        enter: {
          x: { value: -6 },
          y: { value: 0 },
          height: { value: 30 },
          width: { value: 12 },
          stroke: { value: 'grey' },
          strokeWidth: { value: 1 },
          fill: { value: '#FFFFFF' },
          cornerRadius: { value: 8 },
          cursor: { value: 'ew-resize' },
        },
      },
    }, {
      type: 'rule',
      interactive: false,
      encode: {
        enter: {
          x: { value: -2 },
          y: { value: 7 },
          y2: { value: 23 },
          stroke: { value: 'grey' },
        }
      },
    }, {
      type: 'rule',
      interactive: false,
      encode: {
        enter: {
          x: { value: 2 },
          y: { value: 7 },
          y2: { value: 23 },
          stroke: { value: 'grey' },
        }
      },
    }]
  }, {
    type: 'group',
    encode: {
      update: {
        x: { signal: 'brush[1]' },
        y: { signal: '(max(overviewHeight, 30) - 30)/2' },
      },
    },
    marks: [{
      type: 'rect',
      name: 'rightHandle',
      encode: {
        enter: {
          x: { value: -6 },
          y: { value: 0 },
          height: { value: 30 },
          width: { value: 12 },
          stroke: { value: 'grey' },
          strokeWidth: { value: 1 },
          fill: { value: '#FFFFFF' },
          cornerRadius: { value: 8 },
          cursor: { value: 'ew-resize' },
        },
      },
    }, {
      type: 'rule',
      interactive: false,
      encode: {
        enter: {
          x: { value: -2 },
          y: { value: 7 },
          y2: { value: 23 },
          stroke: { value: 'grey' },
        }
      },
    }, {
      type: 'rule',
      interactive: false,
      encode: {
        enter: {
          x: { value: 2 },
          y: { value: 7 },
          y2: { value: 23 },
          stroke: { value: 'grey' },
        }
      },
    }]
  }]
}