import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from "d3";

@Component({
  selector: 'app-stacked-areabar',
  templateUrl: './stacked-areabar.component.html',
  styleUrls: ['./stacked-areabar.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class StackedAreabarComponent implements OnInit {
  @ViewChild("StackAreaBarContainer", { static: true })StackAreaBarContainer!: ElementRef;
  @Input("data") data: any;
  @Input("pageKey") pageKey: any;
  @Input("item") item: any;
  @Input("config") config: any;
  @Input("headerConfig") headerConfig: any;
  @Input("heading") heading: string = "";
  @ViewChild("fs") fs!: ElementRef;
  isActive = false;
  noData: Boolean = false
  currentVisibleData: any = [];
  @HostBinding("class.is-fullscreen") isFullscreen = false;
    chartData:any=[]
    props:any;
    divId: any = "StackAreaBar";
    // currentVisibleData: any = [];
    constructor() { }
    @HostListener("fullscreenchange", ["$event"])
    @HostListener("webkitfullscreenchange", ["$event"])
    @HostListener("mozfullscreenchange", ["$event"])
    @HostListener("MSFullscreenChange", ["$event"])
    screenChange(event:any) {
      if (this.isFullscreen == true) {
        this.closeFullscreen();
        // this.isFullscreen = false
      }
    }
    closeFullscreen(): void {
      this.props.chartHeight = 500
      this.isFullscreen = false;
      this.isActive = false;
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      setTimeout(() => {
        this.plotChart()
      }, 100);
    }
    ngOnInit(): void {
      this.divId = this.item.config.divid
      this.initiateCharts();
      this.getStackAreaBarChartData();
    }

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
  }

  private hideTooltip(myType: any): void {

    d3.select("#d3Tooltip")
      .style('visibility', 'hidden');
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select(this.StackAreaBarContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);
    const defs = mySvg.append('defs');

    defs
      .append('clipPath')
      .attr('id', 'brushClip' + myClass)
      .append('rect')
      .attr('id', 'brushClipRect' + myClass);

    const filter = defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('width', 10)
      .attr('height', 24);
    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 1)
      .attr('result', 'blur');
    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('result', 'offsetBlur');
    filter
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', '#000000')
      .attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    mySvg
      .append('g')
      .attr('id', 'xAxis' + myClass)
      .attr('class', 'axis' + myClass);
    mySvg
      .append('g')
      .attr('id', 'yAxis' + myClass)
      .attr('class', 'axis' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
    mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg.append('g').attr('id', 'chartGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass);
    mySvg.append('g').attr('id', 'brushSelectionGroup' + myClass);
       // legend stuff
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    

    //tooltip groupss
    mySvg.append('line').attr('class', 'dotLine' + myClass);
    mySvg.append('g').attr('class', 'areaDotsGroup' + myClass);
    mySvg.append('g').attr('class', 'lineDotsGroup' + myClass);

    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
      mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg
      .append('circle')
      .attr('class', 'legendCircleBar' + myClass + ' legendItem' + myClass);
    mySvg
      .append('circle')
      .attr('class', 'legendCircleLine' + myClass + ' legendItem' + myClass);
    mySvg
      .append('text')
      .attr('class', 'legendLabelLine' + myClass + ' legendItem' + myClass);
    mySvg
      .append('text')
      .attr('class', 'legendLabelBar' + myClass + ' legendItem' + myClass);

    // legend stuff
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    // const circleRadius = 4;

    mySvg.attr('height', height);

    const timeConverter: any = {
      D: d3.timeDay,
      W: d3.timeWeek,
      M: d3.timeMonth,
      Q: d3.timeMonth,
      Y: d3.timeYear,
      PCQ: d3.timeMonth,
    };
    const tickFormat: any = {
      D: '%d %b %Y',
      W: '%d %b %Y',
      M: '%b %Y',
      Q: '%b %Y',
      Y: '%Y',
      PCQ: 'Q%q %b, %Y',
    };
    const timePeriod = this.props.period;
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
    let chartData: any = this.chartData;
    // @ts-ignore
    chartData.map((m: any) => (m.date = new Date(m[myChart.props.xAxisVar])));
  
    ///////////////

    let sourceSet = new Set();
    // build source set from group var
    chartData.forEach((d: any) => sourceSet.add(d[myChart.props.stackVar]));
    let sourceSets = Array.from(sourceSet);
    // console.log(sourceSets)
  
    const lineColors:any = {}, lineColorsLight:any = {};
    // define colours now we have unique list of groups
    sourceSet.forEach((d: any, i: any) => {
   
      lineColors[d] = myChart.props.barFill[i];
      lineColorsLight[d] = myChart.props.barFill[i]
    })
    // ////////////////
    let dateGroup = Array.from(d3.group(chartData, (g: any) => g.date));
    dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));

    
    const dateGroupDates: any = []
    let BarChartData: any = [];
    let AreaChartData:any  = [];
  
    let tooltipData: any = {};
    let barVars: any = new Set();
    let stackVars: any = new Set();
 
    myChart.chartData.forEach((d: any) => {
      barVars.add(d[myChart.props.xAxisVar]);
      stackVars.add(d[myChart.props.stackVar]);
    });


    let stackData: any = [];
    Array.from(barVars).forEach((d: any) => {
     
      const myData: any = { name: d };
      Array.from(stackVars).forEach((v: any) => {
        const myValue = d3.sum(this.chartData, (s: any) => 
           s[myChart.props.xAxisVar] === d &&
            s[myChart.props.stackVar] === v
            ? s[myChart.props.yAxisVar]
            : 0 );
            myData[v] = myValue;
        });
        stackData.push(myData);
      });
     
    stackData = d3
      .stack()
      .keys(Array.from(myChart.props.barKey))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)(stackData);


    let stackVars_area: any = new Set();

    myChart.chartData.forEach((d: any) => {
      barVars.add(d[myChart.props.xAxisVar]);
      stackVars_area.add(d[myChart.props.stackVar]);
    });
    
    let AstackData: any = [];
    Array.from(barVars).forEach((d: any,i:any) => {
     if(tooltipData[String(d[0])] === undefined){
        tooltipData[String(d[0])] = {};
      }
      tooltipData[String(d[0])].date = d[0];
      // tooltipData[String(d[0])].date = d[0];
      const myData: any = { name: d };
      Array.from(stackVars_area).forEach((v: any) => {
        const myValue = d3.sum(this.chartData, (s: any) =>
          s[myChart.props.xAxisVar] === d && s[myChart.props.stackVar] === v
            ? s[myChart.props.yAxisVar]
            : 0
        );
        myData[v] = myValue;
      });
      AstackData.push(myData);
        tooltipData["Date -  " + d] = myData
      // tooltipData[String(d[0])]["RT-" + d] = myData === undefined ? 0 : myData[myChart.props.yAxisVar];
      // tooltipData.push(myData)
     
    });
 
    AstackData = d3
      .stack()
      .keys(Array.from(myChart.props.areaKey))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)(AstackData);

    //area data
    const tickValues =
      myChart.props.d3AxisFormatting === true ? null : dateGroupDates;
   
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    const yMax = d3.max(AstackData, (d: any) => d3.max(d, (m: any) => m[1]));
   
  
    let xExtent: any = d3.extent(chartData, (d: any) => d.date);
    const xMax = d3.max(chartData, (d: any) => d.date);

    xExtent[0] = timeConverter[timePeriod].offset(xExtent[0], -1);
    xExtent[1] = timeConverter[timePeriod].offset(xExtent[1], 1);
   
    let margins = {
      left: 75,
      right: 40,
      top: 90,
      bottom: 10,
      brush: 52,
      mid: 80,
      axis: 40
    };
    if (String(this.props.axisRotation).includes('90')) {
      margins.mid += 15;
    }
    if (myChart.props.showBrush === false) {
      margins.brush = 0;
      margins.mid = 50;
      margins.bottom = 0;
    }
    //@ts-ignore
    let startingVisibleRows: any = parseInt(
      //@ts-ignore
      (width - margins.left - margins.right) / 25
      //@ts-ignore
    );
    startingVisibleRows =
      startingVisibleRows > chartData.length
        ? chartData.length
        : startingVisibleRows;
    const brushStartX =
      chartData.length === 0
        ? 0
        : (1 - startingVisibleRows / chartData.length) *
          (width - margins.left - margins.right);
    const chartHeight =
      height - margins.top - margins.bottom - margins.brush - margins.mid;

    const brush: any = d3
      .brushX()
      .handleSize(10)
      .extent([
        [0, 0],
        [width - margins.left - margins.right, margins.brush],
      ])
      //@ts-ignore
      .on('start brush end', !myChart.props.showBrush ? null : brushed);

    mySvg
      .select('#noDataMessage' + myClass)
      .attr('x', margins.left + (width - margins.left - margins.right) / 2)
      .attr(
        'y',
        margins.top +
          (height -
            margins.top -
            margins.mid -
            margins.brush -
            margins.bottom) /
            2
      )
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(
        chartData.length === 0 ? 'There is no data for this time period' : ''
      );

    mySvg.select('.overlay').style('fill', 'transparent');

    mySvg
      .select('#brushClipRect' + myClass)
      .style('width', width - margins.left - margins.right)
      .style('height', height)
      .attr('transform', 'translate(' + margins.left + ',0)');

    const yScale = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([
        Number(
          height - margins.top - margins.bottom - margins.mid - margins.brush
        ),
        0,
      ]);
    const yScaleHeight = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([0, height - margins.top - margins.bottom])
   
    const yScaleBrush = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([margins.brush, 0]);
   
    const xScaleAll: any = d3
      .scaleTime()
      .range([0, width - margins.left - margins.right])
      .domain(xExtent);
      
    const xScaleSelected: any = d3
      .scaleTime()
      .range([0, width - margins.left - margins.right])
      .domain(xExtent);

      ////////////////
      
    const extraYs: any = {};
    stackData.forEach((d: any, i: any) => {
     
      const myKey = d.key;
      const isLast = i === stackData.length - 1;
      d.map((m: any) => (m.value = m[1] - m[0]));
      d.map((m: any) => (m.height = yScaleHeight(m.value)));
      d.forEach((s: any, index: any) => {
        
        let myExtraY = 0;
        if (s.height < 2 && s.value > 0) {
          myExtraY = (2 - s.height);
          s.height = 2;
          extraYs[index] =
            extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY;
        }
        let myFill = myChart.props.barFill[myKey];
        if (myFill === undefined) {
          myFill = myChart.props.stackColors[i];
          myChart.props.barFill[myKey] = myFill;
          myChart.props.barLabels[myKey] = myKey;
        }
       
        BarChartData.push({
          date: new Date(s['data'].name),
          start: s[0],
          value: s[1],
          height: s.height,
          fill: myFill,
          extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
          totalValue: s[1],
          borderRadius: isLast ? 2 : 0,
          groupIndex: index,
          stackIndex: i,
          errorType: myKey,
        });
      });
    });

    dateGroup.forEach((d: any, i: any) => {
      dateGroupDates.push(d[0]);
    });
  

  //  
    AstackData.forEach((d: any, i: any) => {
      const myKey = d.key;
      const isLast = i === stackData.length - 1;
      d.map((m: any) => (m.value = m[1] - m[0]));
      d.map((m: any) => (m.height = yScaleHeight(m.value)));
      d.forEach((s: any, index: any) => {
       
        let myExtraY = 0;
        if (s.height < 2 && s.value > 0) {
          myExtraY = (2 - s.height);
          s.height = 2;
          extraYs[index] =
            extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY;
        }
        let myFill = myChart.props.barFill[myKey];
        if (myFill === undefined) {
          myFill = myChart.props.stackColors[i];
          myChart.props.barFill[myKey] = myFill;
          myChart.props.barLabels[myKey] = myKey;
        }
        
        AreaChartData.push({
          date: new Date(s['data'].name),
          start: s[0],
          value: s[1],
          height: s.height,
          fill: myFill,
          extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
          totalValue: s[1],
          borderRadius: isLast ? 2 : 0,
          groupIndex: index,
          stackIndex: i,
          errorType: myKey,
        });
      });
    });
    dateGroup.forEach((d: any, i: any) => {
      dateGroupDates.push(d[0]);
    });

    mySvg
      .select('#xAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('x', margins.left + (width - margins.left - margins.right) / 2)
      .attr('y', height - margins.bottom - margins.brush - 6)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.xAxisLabel);

    mySvg
      .select('#yAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr(
        'transform',
        'translate(13,' +
          (margins.top +
            (height -
              margins.top -
              margins.bottom -
              margins.brush -
              margins.mid) /
              2) +
          ') rotate(-90)'
      )
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.yAxisLabel);

    mySvg
      .select('#xAxis' + myClass)
      .call(
        d3
          .axisBottom(xScaleSelected)
          .tickSizeOuter(0)
          .tickValues(tickValues)
          .ticks(tickCount)
          .tickFormat(xTickFormat)
      )
      .attr(
        'transform',
        'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')'
      );

    mySvg
      .select('#yAxis' + myClass)
      .call(
        d3
          .axisLeft(yScale)
          .tickSizeOuter(0)
          .ticks(5)
          .tickFormat((d: any) =>
            d === 0 ? '' : d3.format(myChart.props.yAxisFormatting)(d)
          )
      )
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg
      .selectAll('.axis' + myClass + ' path')
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .style('stroke', '#E8EAEE');

      //////////////////////onhover
      // this is the mouseover line, only visible at mouseover, setting key properties
      mySvg.select('.dotLine' + myClass)
      .attr('visibility', 'hidden')
      .attr('pointer-events', 'none')
      .attr('stroke', '#A0A0A0')
      .attr('fill', '#A0A0A0')
      .attr('stroke-width', 2)
      .attr('y1', 0)
      .attr('y2', height - margins.top - margins.bottom)
      .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

    const chartGroupData =
      myChart.props.showBrush === false ? ['chart'] : ['brush', 'chart'];

      const areaBrush: any =  d3.area()
       .curve(d3.curveLinear)
        .x((d: any) => xScaleAll(new Date(d.data.name)))
        .y0((d: any) =>  yScaleBrush(d[1]))
        .y1( (d: any) =>  yScaleBrush(d[0]))
      
      const area: any = d3.area()
       .curve(d3.curveLinear)
        .x((d: any) => xScaleSelected(new Date(d.data.name)))
        .y0((d: any) =>  yScale(d[1]))
        .y1( (d: any) =>  yScale(d[0]))

    mySvg
      .select('#brushGroup' + myClass)
      .attr(
        'transform',
        'translate(' +
          margins.left +
          ',' +
          (margins.top + chartHeight + margins.mid) +
          ')'
      )
      .call(brush)
      .call(brush.move, [brushStartX, width - margins.left - margins.right]);


    if (myChart.props.showBrush === false) {
      mySvg
        .select('#brushGroup' + myClass)
        .call(brush.move, null)
        .selectAll('*')
        .remove();
    }

    mySvg
      .selectAll('.handleLines' + myClass)
      .attr(
        'visibility',
        chartData.length === 0 || myChart.props.showBrush === false
          ? 'hidden'
          : 'visible'
      )
      .attr(
        'y1',
        margins.top + chartHeight + margins.mid + (margins.brush - 12) / 2
      )
      .attr(
        'y2',
        margins.top + chartHeight + margins.mid + 12 + (margins.brush - 12) / 2
      )
      .style('stroke', '#8A98AB')
      .style('stroke-width', '1')
      .attr('transform', 'translate(' + margins.left + ',0)');
      
    // legend functionality with buttons
    // same on combo/line/area line
    // see line chart for comments/explanations
    let currentLegendSet: any = [];
    currentLegendSet = currentLegendSet.concat(sourceSets);
    let currentLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
    // console.log(currentLegendItems)
    let legendIndexLeft = 0;
    let legendIndexRight = currentLegendItems.length;
    const legendWidth = d3.sum(currentLegendItems, (s: any) => measureWidth(s.toUpperCase(), 12)  + 40)
    mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
    if (legendWidth < (width - 60)){
      mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
    } else {
      currentLegendItems = [];
      let currentWidth = 0;
      currentLegendSet.forEach((d: any, i: any) => {
        currentWidth += (measureWidth(d.toUpperCase(), 12) + 40);
        if (currentWidth < (width - 60)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }
    // more legend - see line
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 21)
      .attr('y', 35 + 5.5)
      .text('<');
    // more legend - see line
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft += 1;
        legendIndexRight += 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (currentLegendSet.length - 1)){
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 21)
      .attr('y', 35 + 5.5)
      .text('>');

    drawLegend(BarChartData.length === 0 ? [] : currentLegendItems)

    drawChart();

    mySvg
      .selectAll('#brushGroup' + myClass)
      .selectAll('.selection')
      .attr('fill', '#A0A0A0')
      .attr('fill-opacity', '0.2')
      .style('stroke', '#E8EAEE')
      .style('stroke-width', '1');

    function drawLegend(currentLegendItems: any): void {
      // draw legend, similar in all cases, see area line for full explanation
      const legendGroup = mySvg
        .select('#legendGroup' + myClass)
        .selectAll('.legendGroup' + myClass)
        .data(currentLegendItems)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d.replace(/ /g, '') + myClass)
        .attr('cy', 35)
        .attr('fill', (d: any) => lineColors[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + d.replace(/ /g, '') + myClass)
        .attr('y', 35 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) => d.toUpperCase().replace(/_/g, ' '));

      let legendX = 30;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject).attr('x', legendX);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        legendX += (40 + measureWidth(d.toUpperCase(), 12));
      });

      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }
  
    function drawChart(): void {
      mySvg
        .selectAll('#xAxis' + myClass + ' line')
        .attr('y1', '0')
        .attr(
          'y2',
          -(height - margins.top - margins.bottom - margins.brush - margins.mid)
        )
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);

      mySvg
        .selectAll('.axis' + myClass + ' text')
        //@ts-ignore
        .style('font-weight', myChart.props.axisFontWeight)
        .style('font-family', 'Poppins')
        //@ts-ignore
        .style('font-size', myChart.props.axisFontSize);

      mySvg
        .selectAll('#xAxis' + myClass + ' text')
        .style('text-anchor', 'middle')
        .attr('dx', -5)
        .attr('dy', 5)
        .attr('transform', '');

      const xBandwidth =
        dateGroupDates.length > 1
          ? xScaleSelected(dateGroupDates[1]) -
            xScaleSelected(dateGroupDates[0])
          : 0;
      const maxTextWidth = getMaxTextWidth();
      if (maxTextWidth > xBandwidth - 5) {
        let myFontSize =
          xBandwidth < myChart.props.axisFontSize + 2
            ? xBandwidth - 2
            : myChart.props.axisFontSize;
        mySvg
          .selectAll('#xAxis' + myClass + ' text')
          .style(
            'text-anchor',
            myChart.props.d3AxisFormatting === true ? 'middle' : 'end'
          )
          //@ts-ignore
          .attr('dy', myChart.props.d3AxisFormatting === true ? 5 : 1)
          .style('font-size', myFontSize)
          //@ts-ignore
          .attr(
            'transform',
            'rotate(' +
              (myChart.props.d3AxisFormatting === true ? 0 : -45) +
              ')'
          );
      }

      function getMaxTextWidth() {
        let maxTextWidth = 0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function () {
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width);
        });
        return maxTextWidth;
      }

      mySvg
        .selectAll('#yAxis' + myClass + ' line')
        .attr('x1', '0')
        .attr('x2', width - margins.right - margins.left)
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);


      if (myChart.props.showBrush === true) {
        const handleEastX = +mySvg
          .select('#brushGroup' + myClass)
          .select('.handle--e')
          .attr('x');
        const handleWestX = +mySvg
          .select('#brushGroup' + myClass)
          .select('.handle--w')
          .attr('x');

        mySvg
          .select('.handleLeftLine1' + myClass)
          .attr('x1', handleEastX + 3)
          .attr('x2', handleEastX + 3);

        mySvg
          .select('.handleLeftLine2' + myClass)
          .attr('x1', handleEastX + 7)
          .attr('x2', handleEastX + 7);

        mySvg
          .select('.handleRightLine1' + myClass)
          .attr('x1', handleWestX + 3)
          .attr('x2', handleWestX + 3);

        mySvg
          .select('.handleRightLine2' + myClass)
          .attr('x1', handleWestX + 7)
          .attr('x2', handleWestX + 7);

        mySvg
          .select('#brushGroup' + myClass)
          .selectAll('.handle')
          .attr('fill', 'white')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('y', (margins.brush - 24) / 2)
          .attr('height', 24)
          .style('filter', 'url(#drop-shadow)');
      }

      mySvg
        .selectAll('.selection')
        .attr('visibility', BarChartData.length === 0 ? 'hidden' : 'visible');

      
      myChart.currentVisibleData = [chartData, xScaleSelected.domain()];
      
      ////////////////////////////bar creation end start
      const chartGroup = mySvg
          .select('#chartGroup' + myClass)
          .selectAll('.chartGroup' + myClass)
          .data(chartGroupData)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'chartGroup' + myClass);
            enter.append('g').attr('class', (d : any) => d === 'brush' ? 'averageLine' + d : 'averageLine' + d);
            enter.append('g').attr('class', 'barsGroup');
            return enter;
          });

      chartGroup.attr('clip-path', (d: any) =>
          d === 'brush' ? '' : 'url(#brushClip' + myClass + ')'
          );
          chartGroup
            .select('.averageLinechart')
            .attr(
              'transform',
              (d: any) =>
                'translate(0,' +
                (d === 'brush' ? margins.mid + chartHeight : 0) +
                ')'
            );
            // for brush line chart
      chartGroup.select('.averageLinebrush')
            .attr(
              'transform',
              (d: any) => 'translate(0, 0)');
      
      const areaGroup = chartGroup
              .select('.averageLinechart')
              .selectAll('.areasGroupGroup')
              .data(AstackData)
              .join((group: any) => {
                const enter = group.append('g').attr('class', 'areasGroupGroup');
                  enter.append('path').attr('class', 'areaGroupItems averageDot');
                return enter;   
              })   
              // for brush
      const areabrush = chartGroup
              .select('.averageLinebrush')
              .selectAll('.areasGroupGroup')
              .data(AstackData)
              .join((group: any) => {
                const enter = group.append('g').attr('class', 'areasGroupGroup');
                  enter.append('path').attr('class', 'areaGroupItems averageDot');
                return enter;   
              })

      areaGroup.select('.averageDot')
              .attr('d',area)
              .attr('fill', (d: any) => d.type === 'brush' ? 'none': myChart.props.barFill[myChart.props.barLabels[d.key]])
              .style('stroke-width', 1)
              .attr(
                'transform',
                (d: any) =>
                  'translate(' +
                  margins.left +
                  ',' +
                  (d === 'brush'
                    ? margins.mid + chartHeight + margins.top 
                    : margins.top) +
                  ')'
              );
              // For brush chart
      areabrush.select('.averageDot')
              .attr('d',(d:any) => areaBrush(d))
              .attr('fill', (d: any) => myChart.props.barFill[myChart.props.barLabels[d.key]])
              .style('stroke-width', 1)
              .attr(
                'transform',
                (d: any) =>
                  'translate(' +
                  margins.left +
                  ',' +
                  (margins.mid + chartHeight + margins.top ) +
                  ')'
              );
      /////////////////////////////////bar creation starts
      chartGroup
              .select('.barsGroup')
              .attr(
                'transform',
                (d: any) =>
                  'translate(0,' +
                  (d === 'brush' ? margins.mid + chartHeight : 0) +
                  ')'
              );
      
      const barGroup = chartGroup
              .select('.barsGroup')
              .selectAll('.barsGroupGroup')
              .data((d: any) => {
                
                const myData = JSON.parse(JSON.stringify(BarChartData));
                myData.map((n: any) => (n.date = new Date(n.date)));
                myData.map((n: any) => (n.type = d));
                return myData;
              })
              .join((group: any) => {
                const enter = group.append('g').attr('class', 'barsGroupGroup');
                  enter.append('rect').attr('class', 'barsGroupItems totalRect');
                return enter;
              });
      
      
      barGroup.select('.totalRect')
              .attr('x', (d: any) =>
                d.type === 'brush' ? xScaleAll(new Date(d.date))  : xScaleSelected(new Date(d.date)) 
              )
              .attr('y', (d: any) =>
                d.type === 'brush' ? yScaleBrush(d.totalValue) : yScale(d.totalValue)
              )
              .attr('width', 14)
              .attr('height', (d: any) =>
                d.type === 'brush'
                  ? yScaleBrush(d.start) - yScaleBrush(d.totalValue)
                  : yScale(d.start) - yScale(d.totalValue)
              )
              .attr('fill', (d: any) =>d.fill)
              .attr(
                'transform',
                (d: any) =>
                  'translate(' +
                  margins.left +
                  ',' +
                  (d === 'brush'
                    ? margins.mid + chartHeight + margins.top 
                    : margins.top) +
                  ')'
              );
    
    }

    function brushed(event: any): void {
      const selection = event.selection;
         // Check if the bar's x-coordinate falls within the brushed area.
      if (selection !== null) {
          // re-style selection box for the brush selection
          mySvg.selectAll('#brushSelectionGroup' + myClass)
          .selectAll('.selection').attr('class', 'selection lineSelectionBox');

        const xDomain: any = selection.map(xScaleAll.invert);
        const dataRemaining: any = chartData.filter((f: any) => f.date >= xDomain[0] && f.date <= xDomain[1]).length;
        // const dataRemaining: any = chartData.filter(
        //   (f: any) => f.date >= xDomain[0] && f.date <= xDomain[1]
        // );
        if (dataRemaining.length < 2 && dataRemaining !== chartData.length) {
          const previousStart = xScaleAll(xScaleSelected.domain()[0]);
          const previousEnd = xScaleAll(xScaleSelected.domain()[1]);
            mySvg
              .select('#brushGroup' + myClass)
              //@ts-ignore
              .call(
                brush.move,
                myChart.props.showBrush === false
                  ? null
                  : [previousStart, previousEnd]
              );
        
        } else {
          // if (String(xDomain[1]) === String(xScaleAll.domain()[1])) {
          //   mySvg
          //     .select('.partialPeriodLine' + myClass)
          //     .attr(
          //       'visibility',
          //       myChart.props.partialPeriod === true ? 'visible' : 'hidden'
          //     );
          //   mySvg
          //     .select('.partialPeriodLineLabel' + myClass)
          //     .attr(
          //       'visibility',
          //       myChart.props.partialPeriod === true ? 'visible' : 'hidden'
          //     );
          // } else {
          //   mySvg
          //     .select('.partialPeriodLine' + myClass)
          //     .attr('visibility', 'hidden');
          //   mySvg
          //     .select('.partialPeriodLineLabel' + myClass)
          //     .attr('visibility', 'hidden');
          // }
          xScaleSelected.domain(xDomain);
          mySvg
            .select('#xAxis' + myClass)
            // @ts-ignore
            .call(
              d3
                .axisBottom(xScaleSelected)
                .tickSizeOuter(0)
                .tickFormat(xTickFormat)
                .ticks(tickCount)
                .tickValues(
                  tickValues === null
                    ? null
                    : tickValues.filter(
                        (f: any) => f >= xDomain[0] && f <= xDomain[1]
                      )
                )
            )
            
            .attr(
              'transform',
              'translate(' +
                margins.left +
                ',' +
                (margins.top + chartHeight) +
                ')'
            );

          drawChart();
        }
      }
    }
  
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function (): any {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', 0)
          .attr('dy', dy + 'em');
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          // @ts-ignore
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0) {
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word);
            }
          }
        }
      });
      if (lineNumber > 1) {
        text.selectAll('tspan').remove();
        text.attr('id', originalValue);
        text.text(originalValue.substr(0, 5) + '..');
      }
    }
  }
// property & data for the chart
  async getStackAreaBarChartData(): Promise<void>{
    // this.barChartService.getBarChartData().subscribe((response: any) => {
    // if (response) {
      if(this.item.config.chart_type =='stackedmulti'){
      this.chartData =  await fetch('assets/sample/areastackmulti.json').then(a => a.json())
    }else{
    this.chartData =  await fetch('assets/sample/areastacksingle.json').then(a => a.json())
    // this.chartData= await fetch('assets/sample/IRData.json').then(a => a.json())
      }
         
      if(this.item.config.chart_type =='stackedmulti'){
      this.props = {
        // "colours": {"quantityShipped": '#1363DF', "movingAverage" : '#FFD951'},
        period: 'PCQ',
        xAxisLabel: 'Period',
        yAxisLabel: 'KPI Value',
        xAxisVar: 'period',
        yAxisVar: 'kpi_value',
        stackVar: 'kpi_name',
        showBrush: true,
        barKey: ['PENDING', 'ACTIVE','DISCONTINUE','CANCELLED'],
        areaKey: ['PENDING AVG', 'ACTIVE AVG','DISCONTINUE AVG','CANCELLED AVG'],
        barFill: {
          'PENDING': '#5BD69B',
          'ACTIVE': '#101D42',
          'DISCONTINUE': '#F8B194',
          'CANCELLED': '#F67180',
          'PENDING AVG':'#E7F8EE',
          'ACTIVE AVG':'#D6DFE5',
          'DISCONTINUE AVG':'#FDE3E6',
          'CANCELLED AVG':'#FEEFEA',
  
        }, 
        barLabels: {
          'PENDING': 'PENDING',
          'ACTIVE': 'ACTIVE',
          'DISCONTINUE': 'DISCONTINUE',
          'CANCELLED': 'CANCELLED',
          'PENDING AVG':'PENDING AVG',
          'ACTIVE AVG':'ACTIVE AVG',
          'DISCONTINUE AVG':'DISCONTINUE AVG',
          'CANCELLED AVG':'CANCELLED AVG',
        }, 
  
        d3AxisFormatting: false,
        yAxisFormatting: '-2~s',
        axisFontSize: 11,
        axisFontWeight: 700,
        chartHeight: 500,
      };}else{
        this.props = {
        period: 'PCQ',
        xAxisLabel: 'My X Axis Label',
        yAxisLabel: 'My Y Axis Label',
        xAxisVar: 'period',
        yAxisVar: 'kpi_value',
        stackVar: 'kpi_name',
        showBrush: true,
        barKey: ['Refill Patients', 'New Patients'],
        areaKey: ['Total Refill', 'Total New Patients'],
        barFill: {
          'Refill Patients': '#3DC5FF',
          'New Patients': '#1363DF',
          'Total Refill':'#D8F3FF',
          'Total New Patients':'#D0E0F9',
        }, //'W': "deeppink","FP" : "skyblue",
        barLabels: {
          'Refill Patients': 'Refill Patients',
          'New Patients': 'New Patients',
          'Total Refill':'Total Refill',
          'Total New Patients':'Total New Patients',
        }, //'W': "Warnings","FP" : "Failures But Processed"
        d3AxisFormatting: false,
        yAxisFormatting: '-2~s',
        axisFontSize: 12,
        axisFontWeight: 700,
        movingAveragePeriod: 1,
        chartHeight: 500,
        partialPeriod: false,
      };
      }
      if (this.chartData !== undefined && this.chartData.length > 0) {
      setTimeout(() => {
        this.noData = false
        this.plotChart();
      }, 100);}else{
        this.chartData = [];
        this.plotChart();
        this.noData = true
      }
  }



}
