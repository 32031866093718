import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CellClickedEvent, ColDef, GetMainMenuItemsParams, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, MenuItemDef, RowModelType, SortModelItem } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { local } from 'd3';
import _, { filter } from 'lodash';
import { camelCase } from 'lodash';
import moment from 'moment';
import { catchError, debounceTime, Observable, of, Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FilterService } from 'src/app/services/filter.service';
import { AlertIconComponent } from '../../shared/alert-icon/alert-icon.component';
import { HoverOverComponent } from '../../shared/hover-over/hover-over.component';
import { PatientValueGetterComponent } from '../../shared/patient-value-getter/patient-value-getter.component';
import { PipelineTooltipLinkComponent } from '../../shared/pipeline-tooltip-link/pipeline-tooltip-link.component';
import { saveAs } from 'file-saver'
import { UserActivityService } from 'src/app/services/useractivity.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { HoverOverClickComponent } from '../../shared/hover-over-click/hover-over-click.component';
import { ExcelExportService } from 'src/app/services/excelExport.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { PatientsFollowUpComponent } from '../../shared/patient-value-getter/patients-follow-up.component';
import { PatientsFlagsComponent } from '../../shared/patient-value-getter/patients-flag.component';
import { RouterHandlerService } from 'src/app/services/router-handler.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: "app-server-side-list",
  templateUrl: "./server-side-list.component.html",
  styleUrls: ["./server-side-list.component.scss"],
})

export class ServerSideListComponent implements OnInit, OnDestroy {
  static key = 100502;
  
  reqSubcription: Subscription[] = [];
  infoData: any;
  isDataLoading: any;
  @Input("pageKey") pageKey: any;
  @Input("paginates") paginates: any;
  @Input("dataLoadLocation") dataLoadLocation = false;
  @Input("item") item: any = {};
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("PatientListDiv", { static: true }) PatientListDiv!: ElementRef;
  @ViewChild("patientJourney", { static: true }) patientJourney!: ElementRef;
  public gridApi!: GridApi;
  public gridColumnApi: any;
  isDataLoaded = false;
  phiToggle: any;
  phiToggleName: any;
  headerIcons: any;
  inputTypeBox: any;
  heading = "Processing Status";
  cardName: any;
  cardId: any;
  userState: any;
  locationName: any;
  accountKey: any;
  state: any;
  refSource: any;
  routerQueryParam: any;
  prescriberId: any;
  lagging_indicator: any;
  rx_type: any;
  rowHeight = 50;
  loadingTemplate = `<span class="ag-overlay-loading-center">Loading...</span>`;
  noRowsTemplate = `<span>Loading...</span>`;
  // public overlayNoRowsTemplate =    '<div style="display: block;background:  rgba(255, 255, 255) ;width: 100%;height:calc(100% - 60px);position: fixed;bottom: 0;left: 0;z-index: 1000;" class="d-flex align-items-center justify-content-center"><div style="-webkit-animation: frames 1s infinite linear;animation: frames 1s infinite linear;background: transparent;border: 0.5vw solid #FFF;border-radius: 100%;border-color:#984898 #984898 #984898 #2549CA;width: 8vw;height: 8vw;opacity: 1.6;padding: 0;position: absolute;z-index: 999;border-style: solid solid none dotted;animation: rotation 1s linear infinite;"></div><div id="spinnertext" >Loading...</div></div>';
  emptyArray: any;
  testData: any;
  isPhiData: any[] = [];
  gridsize: any = 30;
  sliderValue = 1;
  columnDefs: ColDef[] = [];
  public defaultExportParams = {};
  public defaultColDef: ColDef<any> = {
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    headerClass: "my-header-class",
  };
  headerNames: any = [];
  rowStyle = { "border-bottom": "#f3f3f3 1px solid" };
  paginationPageSize = 8;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  patientIdArray: any = [];
  phiChecked = true;
  pendingStatusData: any[] = [];
  nonPendingStatusData: any[] = [];
  pendingFirstLoaded = true;
  menuTabs = ["filterMenuTab", "generalMenuTab", "columnsMenuTab"];
  menuTabss = ["generalMenuTab", "columnsMenuTab"];
  sliderActivityConfig: any;
  paginateConig: any;
  from_day:any;
  to_day:any;
  loader:Boolean=true;
  moduleName:any;
  decryptedParams:any;
  
  constructor(
    public filterService: FilterService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private routerHandlerService: RouterHandlerService,
    private dataService: DataService,
    private userActivityService: UserActivityService,
    private newFilterService: NewFilterService,
    private mixpanelService: MixpanelService,
    public deviceDetectionService: DeviceDetectionService,
    private constantsService: ConstantsService,
    private cdr: ChangeDetectorRef,
    private excelService: ExcelExportService,
    private httpClient: HttpClient, 
    private apiService: ApiService,
    private navigationService: NavigationService
  ) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        // this.loadApiData();
        this.onBtShowLoading();
        this.loadApiDataCount();
        this.serverSidePagination()
      })
    );

    this.reqSubcription.push(
      this.filterService.hoverDataRefresh.subscribe((res: any) => {
        this.localApi();
      })
    );

    // this.reqSubcription.push(a
    //   this.filterService.refreshAlertDelete.subscribe((res: any) => {
    //     // this.refreshAlertData();
    //     this.onBtShowLoading()
    //   })
    // );

    this.reqSubcription.push(
      this.filterService.refreshStatusChangeData.subscribe((res: any) => {
        // this.onBtShowLoading()
        this.loadStatusChange(res);
      })
    );

    // this.filterService.isPHIFunc.subscribe((res:any)=> {
    //   this.isPHIFunc(res)
    // })

    // this.filterService.onQuickFilter.subscribe((res:any)=> {
    //   this.onQuickFilterChanged(res)
    // })

    // this.filterService.onPageSizeChange.subscribe((res:any)=> {
    //   this.onPageSizeChanged(res)
    // })

    // this.filterService.exportXls.subscribe((res:any)=> {
    //   this.exportXls()
    // })

    // Journey Popup in Mobile and IPAD
    this.filterService.getDataPatientJourneyDataHold.subscribe((res: any) => {
      this.journeyPopupData(res)
    })

    this.filterService.flagUpdateOnscreen.subscribe((res:any)=> {
      this.gridApi.refreshCells({
        force: true,
        suppressFlash: true // Optionally prevent flashing effect
      });
      
      // this.filterService.gridApi?.setRowData(this.rowData);
    })
  }
  
  onBtShowLoading() {
    this.loader=true
  }
  onBtHide() {
    this.loader =false;
  }
  
  ngOnInit(): void {
    // config for grid
    this.onBtShowLoading();
    this.loadDataNewGridConfig();

    this.isDataLoading = "Loading...";
    this.userState = this.userActivityService.getData();
    if (this.userState.PHI) {
      if (this.userState.PHI == "yes") {
        this.filterService.phiChecked = true;
      } else {
        this.filterService.phiChecked = false;
      }
    } else {
      this.filterService.phiChecked = true;
    }
    this.defaultExportParams = {
      columnGroups: true,
      fileName: this.item.config["report_heading"],
    };

    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });

    let cfg = this.item.config;
     
    this.paginationPageSize = cfg["default_pagenation"] || 10;
    // console.log('df', cfg["height_configuration"])
    const dyHeight = cfg["height_configuration"]?.["value"];

    if (dyHeight != "fixed") {
      // Auto Size from Element
      const observer = new ResizeObserver((e: any) => {
        this.onSizeChange.emit(e[0].contentRect.height);
      });
      observer.observe(this.PatientListDiv?.nativeElement);
    }
    this.cdr.detectChanges();

    // Load Data from API

    if (this.filterService.locationPresId) {
      this.loadApiData();
    }
  }
  
  startIndex:any;
  endIndex:any;

  refreshAlertData() {
    // Call API and handle the response
    this.reqSubcription.push(
      this.getRowApiData(this.startIndex, this.endIndex).subscribe((data: any) => {
          
          // this.rowData = data && Array.isArray(data) ? data : [];
          // this.gridApi?.setRowData(this.rowData);
          
          setTimeout(() => {
            this.gridApi.refreshCells(); // Refresh cells safely
          }, 100);
        }, (err: any) => {
          console.error('Error fetching data:', err);
        })
      );
  }

  prCardId: any;

  updateSetting(event: any) {
    
    this.onBtShowLoading()
    
    this.gridsize = event.value;
    if (this.gridsize == this.filterService.sliderValue) {
      let api = this.item.config["list_of_api"] || {};
      let carIds;
      this.prCardId = "";

      this.isDataLoaded = false;
      this.isDataLoading = "Loading...";

      let obj:any = {
        activity_since_days: this.filterService.sliderValue || undefined,
        // prescriber_id: this.prCardId || undefined,
        report_typ: this.report_type || "D",
        prescriber_office_location: this.locationName || undefined
      };

      // if(this.accountKey) {
      //   obj['hco_npi_key'] = this.accountKey
      // }

      let query = Object.assign({}, this.filterService.baseQuery, obj)

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          let cfg = this.item.config;
          this.filterService.rowData = res == null ? [] : res;
          let resData: any = res == null ? [] : res;
          // this.rowData = resData.sort((a: any, b: any) => {
          //   if (
          //     a[cfg.selected_column[0].api_resp_column_nm] >
          //     b[cfg.selected_column[0].api_resp_column_nm]
          //   ) {
          //     return 1;
          //   }
            
          //   if (
          //     a[cfg.selected_column[0].api_resp_column_nm] <
          //     b[cfg.selected_column[0].api_resp_column_nm]
          //   ) {
          //     return -1;
          //   }
          //   return 0;
          // });

          // if (this.rowData.length > 0) {
          //   this.isDataLoaded = true;
          // } else {
          //   this.isDataLoaded = false;
          //   this.isDataLoading = "No Data";
          // }
        },(err:any)=>{
          console.log(err)
        })
      )

      // this.filterService.gridApi?.setRowData(this.rowData);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    // this.refreshGrid();
  }

  refreshGrid() {
    if (this.gridApi) {
      // Refresh the ag-Grid component
      let a = this.gridColumnApi?.columnModel?.columnDefs?.length;
      if (a < 5) {
        this.gridApi.sizeColumnsToFit();
      } else {
        const allColumnIds: string[] = [];
        this.gridColumnApi.getColumns()!.forEach((column: any) => {
          // column.minWidth = 150;
          if (column.colId == "current_status") {
            column.minWidth = 260;
          }
          if (column.colId == "alert" || column.colId == "alert_col_value") {
            column.minWidth = 230;
          }
          allColumnIds.push(column.getId());
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, false);
      }
    }
  }

  public rowModelType: RowModelType = 'infinite';
  public cacheBlockSize = 100;
  public cacheOverflowSize = 2;
  public maxConcurrentDatasourceRequests = 2;
  public infiniteInitialRowCount = 1;
  public maxBlocksInCache = 2;
  rowData: any = [];

  public themeClass: string = 'ag-theme-alpine'; // Updated to a more commonly used theme
  iGetRowsParams:any;

  getRowApiData(startRow: number, endRow: number): Observable<any> {
    let api = this.item.config["list_of_api"] || {};
    
    let obj: any = {
      activity_since_days: this.filterService.sliderValue || undefined,
      report_typ: this.filterService.report_type || "D",
      prescriber_office_location: this.locationName || undefined,
      api_key: api.api_key,
      startIndex: startRow,
      pageSize: endRow - startRow, // Ensure correct page size for pagination
      patient_id: this.filterValue || undefined
    };
  
    let query = Object.assign({}, this.filterService.baseQuery, obj);
    
    // Replace this with your actual API call and URL
    return this.httpClient.post<any[]>(`${this.apiService.apiBaseUrl}/kpi/get-patient-list`, query);
  }
  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.filterService.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
 
    this.gridApi.paginationSetPageSize(this.paginationPageSize);
    this.gridApi.setQuickFilter(this.filterValue);
    this.refreshGrid();

    this.gridApi.showLoadingOverlay();
  }
  
  overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading...</span>';
  overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No data to show</span>';

  serverSidePagination() {
    
    const datasource: IDatasource = {
      getRows: (params: IGetRowsParams) => {

        console.log(`Requesting data from row ${params.startRow} to ${params.endRow}`);
        
        this.startIndex = params.startRow
        this.endIndex = params.endRow
        
        this.onBtShowLoading();
        this.gridApi.showLoadingOverlay();
        // Call API and handle the response
        this.reqSubcription.push(
          this.getRowApiData(params.startRow, params.endRow).subscribe(
            (data: any) => {

              
              this.rowData = data && Array.isArray(data) ? data : [];

              if (this.rowData.length === 0) {
                // No data, tell grid to stop loading and show no rows overlay
                params.successCallback([], params.startRow);
                this.gridApi.showNoRowsOverlay();
                
              } else {
                let rowCount = params.startRow + this.rowData.length;
                let lastRow = this.rowData.length < (params.endRow - params.startRow)
                  ? rowCount  // Indicates the end of the data
                  : -1;      // Indicates more data may be available

                params.successCallback(this.rowData, lastRow);
                this.gridApi.hideOverlay();
                setTimeout(() => {
                  this.gridApi.refreshCells();
                }, 100);
              }
              this.onBtHide();
          },
          (err: any) => {
            this.gridApi.hideOverlay();
            this.onBtHide();
            console.error('Error fetching data:', err);
            params.failCallback();
          })
        );
      }
    };
    
    this.gridApi.setDatasource(datasource);
  }

  setTheDataServerSide(data:any) {
    const datasource: IDatasource = {
      getRows: (params: IGetRowsParams) => {
        this.rowData = data && Array.isArray(data) ? data : [];

        if (this.rowData.length === 0) {
          // No data, tell grid to stop loading and show no rows overlay
          params.successCallback([], params.startRow);
          this.gridApi.showNoRowsOverlay();
          this.onBtHide();
        } else {
          let rowCount = params.startRow + this.rowData.length;
          let lastRow = this.rowData.length < (params.endRow - params.startRow)
            ? rowCount  // Indicates the end of the data
            : -1;      // Indicates more data may be available

          params.successCallback(this.rowData, lastRow);
          this.gridApi.hideOverlay();

          setTimeout(() => {
            this.onBtHide();
            this.gridApi.refreshCells();
          }, 100);
        }
      }
    };
    
    this.gridApi.setDatasource(datasource);
    
    setTimeout(() => {
      this.gridApi.refreshCells(); // Refresh cells safely
    }, 100);
  }

  localApi() {
    let cfg = this.item.config;
    let api_key = "100201";
    let reports: any[] = cfg["report_status_column"];
    let patient_id: any = null;
    if (reports) {
      reports.forEach((a: any) => {

        let obj:any = {
          activity_since_days: this.filterService.sliderValue || undefined,
          // prescriber_id: this.prCardId || undefined,
          report_typ: a.value,
          prescriber_office_location: this.locationName || undefined,
          // hcp_state: this.state || undefined,
          // ref_source: this.refSource || undefined,
          // lagging_indicator: this.lagging_indicator || undefined,
          // rx_type: this.rx_type || undefined,
          // from_day: this.from_day || undefined,
          // to_day: this.to_day == 'null' ? null : this.to_day,
          api_key: api_key,
          patient_id: patient_id,
        };

        // if(this.accountKey) {
        //   obj['hco_npi_key'] = this.accountKey
        // }

        this.reqSubcription.push(
          this.filterService.frmExecutionQuery(obj).subscribe(
            (res: any) => {
              let historyData: any[] = [];
              if (res) {
                historyData = [];
                Object.entries(_.groupBy(res, "du_patient_id")).forEach(
                  (m: any) => {
                    historyData.push({
                      patient_id: m[0],
                      patient_status_history: m[1],
                    });
                  }
                );
                if (a.value == "P") {
                  sessionStorage.setItem(
                    "pending",
                    JSON.stringify(historyData ? historyData : [])
                  );
                } else {
                  sessionStorage.setItem(
                    "non-pending",
                    JSON.stringify(historyData ? historyData : [])
                  );
                }
              }
            },
            (err) => {
              this.noRowsTemplate = `<span>No Data</span>`;
            }
          )
        );
      });
      // }
    }
  }

  onPageSizeChanged(res: any) {
    this.paginationPageSize = res;
    this.gridApi.paginationSetPageSize(res);
  }

  sliderValueBinds(event: any) {
    if (event) {
      this.sliderValue = event;
      this.filterService.sliderValue = this.sliderValue;
      
      this.refreshAlertData();
      // this.onBtShowLoading();
    }
  }

  loadDataNewGridConfig() {
    this.columnDefs = []
    let cfg = this.item.config;
    console.log(cfg)
    // this.heading = cfg["report_heading"] || "";
    // this.phiToggle = cfg["toggle-alignment"]["value"];
    // this.phiToggleName = cfg["phi_heading"] || "PHI Column";
    // this.headerIcons = cfg["multi_icon"];
    // this.inputTypeBox = cfg["input_type_search"]["value"];
    // this.sliderActivityConfig = cfg["activity_since"]?.["value"];
    // this.paginateConig = cfg["pagination"]?.["value"];
    this.report_type =
      cfg["default_selected_report_typ"]?.["value"].toUpperCase();
    
    // debugger

    // cfg.selected_column.sort(function (a: any, b: any) {
    //   return a.arrayIndex - b.arrayIndex;
    // });

    cfg.selected_column.forEach((res: any) => {
      res.property_CB.forEach((cb: any) => {
        if (cb.name == "Add Column") {
          if (cb.value) {
            var coldef: any = {
              headerName: res.api_resp_column_display_nm,
              field: res.api_resp_column_nm,
              hide: false,
              cellClass: "cell-wrap-text",
              cacheQuickFilter: true,
              autoHeight: true,
              menuTabs: this.menuTabs,
              minWidth: 150,
              configuration: res
            };

            if (res.api_resp_column_nm == "current_status") {
              coldef.minWidth = 260;
            }
            
            if (res.api_resp_column_nm == "payer_nm") {
              coldef.minWidth = 200;
            }
            
            if (res.api_resp_column_nm == "prescriber_address") {
              coldef.minWidth = 250;
            }
            
            if (res.api_resp_column_nm == "prescriber_id") {
              coldef.minWidth = 300;
            }
            
            if (res.api_resp_column_nm == "prescriber_nm") {
              coldef.minWidth = 200;
            }

            if (res.api_resp_column_nm == "alert" || res.api_resp_column_nm == "alert_col_value") {
              coldef.minWidth = 230;
            }

            if (res.api_resp_column_nm == "alert") {
              coldef["menuTabs"] = this.menuTabss;
            }

            if (res.isAlignment && res.isTextColorCondition) {
              coldef["cellStyle"] = {
                color: res.isTextColor,
                textAlign: res.isAlignment.toLowerCase(),
              };
            } else if (res.isAlignment && !res.isTextColorCondition) {
              coldef["cellStyle"] = {
                textAlign: res.isAlignment.toLowerCase(),
              };
            } else if (res.isTextColorCondition) {
              coldef["cellStyle"] = { color: res.isTextColor };
            }

            // Data Types
            if (res.isDataType == "date") {
              let a = res.isPrefix || "";
              let b = res.issuffix || "";
              coldef["valueGetter"] = (params: any) => {
                return params.data?.[res.api_resp_column_nm] ? a + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + b : ""
              };
            }
            
            if (res.isDataType == "integer") {
              let a = res.isPrefix || "";
              let b = res.issuffix || "";
              coldef["valueFormatter"] = (params: any) => params.value ?  a + " " + params.value + " " + b : '';
            }

            // String for Init cap and upper and lower
            if (res.isDataType == "string") {
              let a = res.isPrefix || "";
              let b = res.issuffix || "";
              if (res.isTextCase == "from_db") {
                coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value + " " + b : "";
              } else if (res.isTextCase == "init") {
                coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + " " + b : "";
              } else if (res.isTextCase == "cap") {
                coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toUpperCase() + " " + b : "";
              } else {
                coldef["valueFormatter"] = (params: any) => params.value ? a + " " + params.value.toLowerCase() + " " + b : "";
              }
            }

            // Conditional Formatter
            if (res.conditional_format == true || res.isActionIcon == true) {
              coldef["cellRendererFramework"] = PatientValueGetterComponent;
              coldef["cellRendererParams"] = (params: any) => {
                if(!params.data) return;
                let selectedData:any;
                let selectedStyles:any;
                let columnClickData:any;
                
                if (res.conditional_formatting_value) {
                  let abData = res.conditional_formatting_value.filter((flr: any) => flr.isSelect == true);
                  selectedData = abData;
                }

                if (res.isActionColumn == true) {
                  if (res.isSelectionTypeColumn == "Column Click") {
                    columnClickData = {
                      api_key: res.isSelectionColumnAPI,
                      params: res.isSelectionColumnParameter,
                    };
                  }
                }

                // icon for Actions
                if (res.isActionIcon == true) {
                  selectedStyles = {
                    icon: res.isActionIconName,
                    color: res.isActionIconColor,
                    position: res.isActionPosition,
                    extraText: {
                      prefix: res.isPrefix,
                      suffix: res.issuffix,
                    },
                  };
                  
                  if (res.isEventsInIcon == true) {
                    let iconsClick = {
                      iconType: res.isSelectionTypeIcon,
                      iconApi: res.isSelectionIconAPI,
                      iconPara: res.isSelectionIconParameter,
                    };
                  
                    Object.assign(selectedStyles, {iconsClickEvent: iconsClick});
                  }
                }
                


                return this.cellRendererValueGetter(selectedData, params.data[res.condition_reference_column], params.value, selectedStyles) || "" + params.value;
              };
            }

            // Actions For Column
            if (res.isActionColumn == true) {
              if (res.isSelectionTypeColumn == "Column Hover Over") {
                if (res.isSelectionColumnAPI) {
                  (coldef["tooltipComponent"] = HoverOverComponent),
                    (coldef["tooltipValueGetter"] = (params: any) => {
                      let formatData = res.conditional_formatting_value;
                      let selectedvalue = {
                        api_key: res.isSelectionColumnAPI,
                        parameter: res.isSelectionColumnParameter,
                      };

                      let component: any;
                      if (res.currentStatusPupups) {
                        component = res.currentStatusPupups;
                      }
                      return this.tooltipValueGetter(
                        params,
                        selectedvalue,
                        formatData,
                        component
                      );
                    });
                }
              } else if (res.isSelectionTypeColumn == "Alerts") {
                if (res.alertSelectionPosibleValues) {
                  sessionStorage.setItem(
                    "alertIcons",
                    JSON.stringify(res.alertSelectionPosibleValues)
                  );
                  coldef["cellRendererFramework"] = AlertIconComponent;
                  coldef["cellRendererParams"] = (params: any) => {
                    return {
                      alertIcons: res.alertSelectionPosibleValues,
                      dataFromPatient: params,
                    };
                  };
                }
              }
            }

            if(res.isFollowUp) {
              coldef["cellRendererFramework"] = PatientsFollowUpComponent;
              coldef["minWidth"] = 260;
            }
            
            if(res.isFlag) {
              // coldef["cellRendererFramework"] = PatientsFlagsComponent;
              // coldef["minWidth"] = 60;
            }
            
            this.columnDefs.push(coldef);
          }
        }

        if (cb.name == " Hidden" || cb.name == "Hidden") {
          if (cb.value) {
            this.headerNames.push(res.api_resp_column_nm);
          }
        }
      });
    });

    // Hide Flag Column
    this.columnDefs = this.columnDefs.filter((f:any)=> f.field!='follow_up_highlight_flg');
    this.columnDefs = this.columnDefs.filter((f:any)=> f.field!='source_type');

    setTimeout(() => {
      this.gridApi.refreshCells({ force: true }); // Refresh cells safely
    }, 100);
  }

  getDataColor(params: any, valueFormat: any) {
    return { params, valueFormat };
  }

  // Mat Icon Dynamically set with cell Renderer Value Getter
  cellRendererValueGetter(
    rangeValue: any,
    param: any,
    value: any,
    selectedStyles: any
  ) {

    if (!selectedStyles) {
      if (!rangeValue) return null;
    }
    
    if (rangeValue && !selectedStyles) {
      for (let idx in rangeValue) {
        let r = rangeValue[idx];
        if (r.col_value == param) {
          let icon: any = r.icon;
          let color: any = r.color;
          return { icon, value, color, selectedStyles };
        }
      }
    } else if (rangeValue && selectedStyles) {
      let fnd: any = rangeValue.find((res: any) => res.col_value == param)
      if (fnd) {
        for (let idx in rangeValue) {
          let r = rangeValue[idx];
          let icon = '';
          let color = '';

          if (r.col_value.toLowerCase() == param.toLowerCase()) {
            icon = r.icon;
            color = r.color;
            return { icon, value, color, selectedStyles };
          }
        }
      } else {
        return { selectedStyles };
      }
    } else if (selectedStyles) {
      return { value, selectedStyles };
    }
  }

  iconClickRendererValueGetter(
    params: any,
    api_key: any,
    parameter: any,
    style: any
  ) {
    return { params, api_key, parameter, style };
  }

  tooltipValueGetter(
    params: any,
    selectedValue: any,
    formatValue: any,
    component: any
  ) {
    const lineBreak = true;
    return { params, selectedValue, formatValue, component, lineBreak }; // params.data['patient_id']
  }

  loadFirstTime = false;

  loadApiData() {
     
    let api = this.item.config["list_of_api"] || {};
    this.filterService.patientListApi = this.item.config["list_of_api"] || {};
    this.isDataLoaded = false;

    if (api.api_key && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {

      let obj:any = {
        activity_since_days: this.filterService.sliderValue || undefined,
        report_typ: this.report_type || "D",
        prescriber_office_location: this.locationName || undefined,
        api_key: api.api_key,
        startIndex: 1,
        pageSize: 100,
      };

      let query = Object.assign({}, this.filterService.baseQuery, obj)

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${'kpi/get-patient-list'}`, query).subscribe((res:any)=> {
          let cfg = this.item.config;
          let resData: any = res == null ? [] : res;
          this.filterService.rowData = res == null ? [] : res;
          
          // Sorting
          this.rowData = resData.sort((a: any, b: any) => {
            if (
              a[cfg.selected_column[0].api_resp_column_nm] >
              b[cfg.selected_column[0].api_resp_column_nm]
            ) {
              return 1;
            }
            if (
              a[cfg.selected_column[0].api_resp_column_nm] <
              b[cfg.selected_column[0].api_resp_column_nm]
            ) {
              return -1;
            }
            return 0;
          });

          if (this.rowData.length > 0) {
            this.isDataLoaded = true;
          } else {
            this.isDataLoaded = false;
            this.isDataLoading = "No Data";
          }
          // API For Local Status for Hover Over
          // if (!this.loadFirstTime) {
          //   this.localApi();
          //   this.loadFirstTime = true;
          // }
          this.onBtHide();
        },(err:any)=>{
          this.onBtHide();
          this.isDataLoading = "No Data";
        })
      )

    }
  }

  rowDataCount:any;

  loadApiDataCount() {  
    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      let obj:any = {
        activity_since_days: this.filterService.sliderValue || undefined,
        report_typ: this.filterService.report_type || "D",
        prescriber_office_location: this.locationName || undefined,
        api_key: 6002
      };

      let query = Object.assign({}, this.filterService.baseQuery, obj)

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${'kpi/get-execution-details'}`, query).subscribe((res:any)=> {
          this.rowDataCount = res == null ? 0 : res[0]['patient_count'];
        },(err:any)=>{
          console.log("")
        })
      )

    }
  }

  isPHIFunc(event: any) {
    this.filterService.phiChecked = event;

    let phi = "no";
    if (this.filterService.phiChecked) {
      phi = "yes";
    } else {
      phi = "no";
    }

    this.userActivityService.setData({ PHI: phi });

    if (this.filterService.phiChecked) {
      this.mixpanelService.track("PHI Checked");
      this.gridColumnApi.setColumnsVisible(this.headerNames, true);
    } else {
      this.mixpanelService.track("PHI UnChecked");
      this.gridColumnApi.setColumnsVisible(this.headerNames, false);
      // if (this.columnDefs.length - this.headerNames.length < 8) {
      //   this.gridApi.sizeColumnsToFit();
      // }
    }
  }

  // Static Icons
  status_tick = "status_tick";
  status_na = "status_na";
  status_pending = "status";
  status_circle = "status_circle";

  valueToDisplay: any;
  report_type: any;
  patientId: any;

  onCellClicked(event: any) {
    // debugger
    if(event?.colDef?.configuration?.isLinkCol) {

      // Storing for Patient Card if there!
      let resData = {
        patient_case_status: event.data.case_status,
        patient_reason_status: event.data.status_reason,
        patient_id: event.data.patient_id,
      };
      this.newFilterService.setPatientStatusData(resData);
      this.newFilterService.setPatientStatusBaseQuery(this.filterService.baseQuery);

      // Set Navigation
      let conf0 = event?.colDef?.configuration
      let nav = {
        dbJSON : conf0.dbJSON,
        s3JSON : conf0.s3JSON,
        pageName : conf0.s3JSON?.page_nm,
        isDashboardLevel : conf0.isDashboardLevel
      }

      // Prescriber Name Header
      if(nav.pageName?.toString().replace(/\s+/g, '').toLowerCase() == 'prescribercard') {
        // Prescriber Name
        let prName = 'prescriber_nm'
        if(event.data[prName]) {
          sessionStorage.setItem("navbar_prName", event.data[prName]);
        }
      } 

      
      this.routerHandlerService.navDetailInfo(nav); 
      this.routerHandlerService.storingPayloadAndFilterForGrid(conf0, event.data, this.cardName);

      let query:any = Object.assign({}, this.decryptedParams, {cardId : event.data[conf0.selectNavigatePart]}, {tab_id: 0}, {page: 'list'});
      
      if(nav.pageName?.toString().replace(/\s+/g, '').toLowerCase() == 'patientcard') {
        query['crds']='pacard'
      }

      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${nav.pageName}`, queryMaped);
    }
  }

  
  journeyPopupData(data: any) {
     
    let cfg = this.item.config;
    cfg.selected_column.forEach((res: any) => {
      res.property_CB.forEach((cb: any) => {
        if (cb.name == "Add Column") {
          if (cb.value) {
            if (data.colDef.field == res.api_resp_column_nm) {
              if (res.isActionColumn == true) {
                // if(!this.deviceDetectionService.isDesktop) {
                if (res.isSelectionTypeColumn == "Column Hover Over") {
                  if (res.isSelectionColumnAPI) {

                    let formatData = res.conditional_formatting_value;
                    let selectedvalue = {
                      api_key: res.isSelectionColumnAPI,
                      parameter: res.isSelectionColumnParameter,
                    };

                    let component: any;
                    if (res.currentStatusPupups) {
                      component = res.currentStatusPupups;
                    }

                    let dialogRef = this.dialog.open(HoverOverClickComponent, {
                      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
                      // disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
                      panelClass: "du-custom-modalbox",
                      data: this.tooltipValueGetter(
                        data,
                        selectedvalue,
                        formatData,
                        component
                      )
                    });

                    dialogRef.afterClosed().subscribe((value) => {
                       

                    });
                  }
                }
                // }

              }
            }
          }
        }
      });
    });
  }

  loadApiForCellClickData(
    formatData: any,
    selectedvalue: any,
    component: any
  ) { }

  highlightSearchTextRenderer(params: any) {
    const value = params.value;
    const quickFilter = params.api.getQuickFilter();
    if (!quickFilter) {
      return value;
    }
    const parts = value.split(new RegExp(`(${quickFilter})`, "gi"));
    return parts
      .map((part: any) =>
        part.toLowerCase() === quickFilter.toLowerCase()
          ? `<span class="highlight">${part}</span>`
          : part
      )
      .join("");
  }

  filterValue: any;
  // Quick Filter for Type to Search Filter
  onQuickFilterChanged(event: any) {
    this.filterValue = event;
    // this.gridApi.setQuickFilter(this.filterValue);
  }

  serverSideSearchEmits(event: any) {
    // Call API and handle the response
    if(this.filterValue || this.filterValue=='') {
      
      this.filterValue = this.filterValue.split(/[\|\s]+/).join(',');
      this.onBtShowLoading();
      this.reqSubcription.push(
        this.getRowApiData(0, 100).subscribe((data: any) => {
            this.setTheDataServerSide(data)
            // this.filterValue='';
          }, (err: any) => {
            this.onBtHide();
            console.error('Error fetching data:', err);
          })
        );
    }
  }

  exportXls() {
    let query:any = this.filterService.baseQuery;
    query["export_nm"] = this.item.config['report_heading'];
    query["api_key"] = 6002;
    query["report_typ"] = this.filterService.report_type;

    this.reqSubcription.push(
      this.dataService.getExportExcel(query).pipe(debounceTime(1000)).subscribe((res: any) => {

        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Export.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, err => {
        alert("Unable to Download the Excel. Please Try Again...")
      })
    );
    
    // this.gridApi.exportDataAsExcel({
    //   fileName: this.item.config['report_heading'] || 'List Data', // Set your desired file name here
    // });

    // this.gridApi.exportDataAsExcel({});
    // this.excelService.customExportExcel(this.rowData, this.item.config['report_heading'] || 'grid', this.columnDefs)
  }


  dataforTesting: any[] = [];

  getInfoDetail() {
    let data = {
      api_key: this.item.config.list_of_api?.api_key || 0,
      widget_key: this.item.config.widgetKey,
      page_key: this.pageKey,
      brand_key: this.filterService.baseQuery?.brand_key
    };
    this.reqSubcription.push(
      this.dataService.getInfoDetail(data).subscribe((res: any) => {
        this.infoData =
          res && res[0] ? res[0] : { info_header: "No Info Data Found" };
      })
    );
  }

  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive = false;

  openFullscreen() {
    this.isFullscreen = true;
    const el = this.fs.nativeElement;
    if (!document.fullscreenElement) {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      }
    }
    this.isActive = true;
  }

  closeFullscreen() {
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  loadStatusChange(data: any) {
    let arr = this.rowData.findIndex(
      (res: any) => res.patient_id == data.patient_id
    );
    let assignData = {
      case_status: data.patient_case_status,
      status_reason: data.patient_reason_status,
      current_status:
        data.patient_case_status + "-" + data.patient_reason_status,
    };
    Object.assign(this.rowData[arr], assignData);

    this.filterService.gridApi?.setRowData(this.rowData);
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }

  getMainMenuItems(params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
    //  
    const defaultItems: any = params.defaultItems.slice();
    let pinSubMenuIndex: any = defaultItems.findIndex((item: any) => item === 'pinSubMenu');
    if (pinSubMenuIndex >= 0) {
      defaultItems[pinSubMenuIndex] = null;
      defaultItems[pinSubMenuIndex + 1] = null;
    }
    return defaultItems;
  }
}

function sortData(sortModel: SortModelItem[], data: any[]) {
  const sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  // do an in memory sort of the data, across all the fields
  const resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (let k = 0; k < sortModel.length; k++) {
      const sortColModel = sortModel[k];
      const valueA = a[sortColModel.colId];
      const valueB = b[sortColModel.colId];
      // this filter didn't find a difference, move onto the next one
      if (valueA == valueB) {
        continue;
      }
      const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    // no filters found a difference
    return 0;
  });
  return resultOfSort;
}