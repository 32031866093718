import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-small-bar-widget-chart",
  templateUrl: "./small-bar-chart.component.html",
  styleUrls: ["./small-bar-chart.component.scss"],
})
export class SmallBarChartComponent implements OnInit, OnDestroy  {
  static key = 100606;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  @Input("dynamic") dynamic!: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("smallBarChart", { static: true }) smallBarChart!: ElementRef;

  config: any;
  data: any;
  heading: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  constructor(private filterService: FilterService, private httpClient: HttpClient, private apiService: ApiService) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
  }
  ngOnInit(): void {
    // debugger
    this.loadlineConfig();
    const observerone = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observerone.observe(this.smallBarChart.nativeElement);
    if(this.dynamic==true){
      this.loadApiData()
    }
   }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData(reportType?: string) {
    let api = this.item.config["list_of_api"] || {};
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payload
      let obj:any = {"api_key" : api}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          
          this.isDataLoaded = true
          this.data = res == null ? [] : res
          
        },(err:any)=>{
          console.log(err)
          this.data = []
          this.isDataLoaded = true;
        })
      )

      // this.reqSubcription.push(
      //   this.filterService.executeQuery(obj).subscribe(
      //     (res: any) => {
      //       this.isDataLoaded = true;
      //       this.data = res == null ? [] : res;
      //     },
      //     (error: any) => {
      //       this.data = [];
      //     }
      //   )
      // );
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }
}
