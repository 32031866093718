import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { LicenseManager } from  'ag-grid-enterprise'
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043433 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data Unveil )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SCRAII )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SCRAII )_need_to_be_licensed___( SCRAII )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 June 2024 )____[v2]_MTcxNzcxNDgwMDAwMA==8211f96f946c59b6a3dfa3ea03a04f25")
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { NewFilterService } from 'src/app/services/new-filter.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';

@Component({
  selector: 'pivot-grid',
  templateUrl: './pivot-grid.component.html',
  styleUrls: ['./pivot-grid.component.scss']
})
export class PivotGridComponent implements OnInit, OnDestroy {
  @Input('columnDef') columnDef: any
  @Input('data') data: any
  @Input('item') item: any
  @Input('pagination') pagination!: any
  @Input('pivot') pivot: any
   contextMenu:boolean=false
  @Input('dateFormat') dateFormat!: any
  reqSubcription: Subscription[] = []
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('pivotGridDiv', { static: true }) pivotGridDiv!: ElementRef
  public autoGroupColumnDef: ColDef = {
    headerName: 'Group',
    minWidth: 200,
    pinned: 'left',
  }

  public autoGroupColumnDefInMobile: ColDef = {
    headerName: 'Group',
    minWidth: 200,
  }

  private gridColumnApi!: ColumnApi;
  private gridApi!: GridApi;
  rowData: any
  isDataLoaded = false
  expandCollapseIcon = "expand"
  expandOrCollapse = "Expand All"
  public columnDefs: ColDef[] = []

  constructor(private filterService: FilterService, public deviceDetectionService: DeviceDetectionService) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      // this.loadApiData()
    }))
  }

  public processSecondaryColGroupDef: (colGroupDef: ColGroupDef) => void = (
    colGroupDef: ColGroupDef
  ) => {
    if(this.dateFormat==true){
    let val = this.item.config.time_cycle.find((f: any) => f.value == (this.filterService.report_type || 'D'))
    colGroupDef.headerName = moment(colGroupDef.headerName).format(val.format || 'DD MMM yyyy')
  }
  };

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll()
  }

  autoSizeAll() {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridColumnApi.autoSizeAllColumns();
  }

  exportXls(headerName?:string) {
    this.gridApi.exportDataAsExcel({ fileName: "Summary "+headerName + "_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString()});
  }
  public defaultColDef: ColDef<any> = {
    // flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: true,
    suppressMenu: true
  };
  public defaultColDefchild: ColDef  = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: true,
    suppressMenu: this.pagination ? false : true
  };

  expandCollapse() {
    if (this.expandCollapseIcon == "collapse") {
      this.expandCollapseIcon = "expand"
      this.expandOrCollapse = "Expand All"
      this.gridApi.collapseAll()

    } else {
      this.expandCollapseIcon = "collapse"
      this.expandOrCollapse = "Collapse All"
      this.gridApi.expandAll();
    }
  }

  ngOnInit(): void {
    this.contextMenu  = this.pagination==true ? false : true 
    this.defaultColDefchild.suppressMenu=this.contextMenu 
    this.defaultColDefchild.filter= 'agTextColumnFilter'
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.pivotGridDiv.nativeElement);
    this.pivot = (this.pivot == false) ? this.pivot : true
    this.columnDefs = this.columnDef
    let val = this.item.config?.time_cycle?.find((f: any) => f.value == (this.filterService.report_type || 'D'))
    this.rowData = this.data
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"].currentValue != changes["data"].previousValue) {
      this.rowData = this.data
  }
  if (changes["columnDef"].currentValue != changes["columnDef"].previousValue) {
    this.columnDefs = this.columnDef
}
  }

}

