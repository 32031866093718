<div class="alert_settings">
  <div class="offcanvas-header ">
    <div class="row" style="width: -webkit-fill-available !important;">
      <div class="col-9 text-start">
        <h5 class="offcanvas-title alert_settings_title" id="offcanvasExampleLabel">Alert Settings</h5>
      </div>
      <div class="col-3 text-end">

      </div>
    </div>
  </div>
  <div class="btn-close-bg">
    <mat-icon class="mat-btn-close alert_clear" data-bs-dismiss="offcanvas">clear</mat-icon>
  </div>
  <hr />
  <div class="offcanvas-body">
    <div class="container-fluid" style="overflow-y: auto;height:550px">

      <div class="row">
        <div class="col-12 d-flex">
          <div class="col-8"></div>
          <div class="col-2 text-center">
            <span class="alert_points">E-Mail</span>
          </div>
          <div class="col-2 text-center">
            <span class="alert_points">In App</span>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngFor="let item of alertSettingsObj">
        <div class="col-12 d-flex justify-content-center">
          <div class="col-8">
            <div class="row">
              <div class="col-2 text-start ml-0 pl-0 d-flex justify-content-center align-items-center  ">
                <mat-icon class="innerIcon" [svgIcon]="item.icon"></mat-icon>
                <div class="alert_icons" [style.opacity]="item.opacity" [style.background-color]="item.iconColor"></div>
              </div>
              <div class="col-10 text-start d-flex flex-column justify-content-center">
                <div class="item_name">{{item.alert_name}}</div>
                <div class="item_description">{{item.description}}</div>
              </div>
            </div>
          </div>

          <div class="col-2 text-center d-flex justify-content-center">
            <mat-checkbox *ngIf="item.alert_name != 'Status Changes'"
              [checked]="item.email_subscription_flg == 'Y' ? true : false" [disabled]="item.isSelectedEmail"
              [color]="item.checkboxColor" (change)="handleChange($event, item,'Email')">
            </mat-checkbox>
          </div>
          <div class="col-2 text-center d-flex justify-content-center">
            <mat-checkbox *ngIf="item.alert_name != 'Status Changes'"
              [checked]="item.app_subscription_flg == 'Y' ? true : false" [disabled]="item.isSelectedApp"
              [color]="item.checkboxColor" (change)="handleChange($event, item,'App')">
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="item.Status_Data">
          <mat-tree class="mt-3 ml-3" [dataSource]="item.dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <div class="row" style="width: -webkit-fill-available !important">
                <div class="col-12 d-flex">

                  <div class="col-8 text-start" style="width: -webkit-fill-available;" matTreeNodePadding
                    matTreeNodePaddingIndent="25">
                    <button mat-icon-button disabled></button>
                    <span class="tree_text">{{ node.label }}</span>
                  </div>

                  <div class="col-2 text-center m-auto">
                    <mat-checkbox class="checklist-leaf-node" style="padding-right:8px;" color="primary"
                      [checked]="checklistSelectionEmail.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node,'email');"></mat-checkbox>
                  </div>
                  <div class="col-2 text-center m-auto">
                    <mat-checkbox class="checklist-leaf-node" style="padding-right:8px;" color="primary"
                      [checked]="checklistSelectionApp.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node,'app');"></mat-checkbox>
                  </div>

                </div>
              </div>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild">

              <div class="row" style="width: -webkit-fill-available !important">
                <div class="col-12 d-flex">

                  <div class="col-8 text-start" style="width: -webkit-fill-available;" matTreeNodePadding
                    matTreeNodePaddingIndent="20">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                      <mat-icon class="mat-icon-rtl-mirror" *ngIf="treeControl.isExpanded(node)" style="color: grey;">
                        remove_circle_outline
                      </mat-icon>
                      <mat-icon class="mat-icon-rtl-mirror" *ngIf="!treeControl.isExpanded(node)"
                        style="color: #1363DF;">
                        add_circle_outline
                      </mat-icon>
                    </button>
                    <span class="tree_start_node">{{ node.label }}</span>
                    <hr />
                  </div>

                  <div class="col-2 text-center m-auto">
                    <mat-checkbox style="padding-right:8px;" [checked]="descendantsAllSelectedEmail(node)" color="warn"
                      [indeterminate]="descendantsPartiallySelectedEmail(node)"
                      (change)="todoItemSelectionToggle(node,'email')">
                    </mat-checkbox>
                  </div>
                  <div class="col-2 text-center m-auto">
                    <mat-checkbox style="padding-right:8px;" [checked]="descendantsAllSelectedApp(node)" color="warn"
                      [indeterminate]="descendantsPartiallySelectedApp(node)"
                      (change)="todoItemSelectionToggle(node,'app')">
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </mat-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>

  </div>
  <div class="offcanvas-end">
    <div class="container-fluid" style="width: -webkit-fill-available;position: absolute;bottom: 0;">

      <div class="row mt-3 mb-3">
        <div class="col-2"></div>
        <div class="col-4">
          <button class="reset_button" style="width:-webkit-fill-available;border: 1px solid #1363DF" mat-raised-button
            color="basic" (click)="resetSettings()">Reset Settings</button>
        </div>
        <div class="col-4">
          <button class="save_button" style="width:-webkit-fill-available;background-color: #1363DF;" mat-raised-button
            color="primary" (click)="saveSettings()">Save Changes</button>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</div>