import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { MatComponentsModule } from "./../../mat-components.module";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AsyncPipe, CommonModule, NgFor } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ClientModelComponent } from "./client-model/client-model.component";
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { ClientRolesComponent } from "./client-roles/client-roles.component";
import { CustomInputTypeModule } from "./custom-input-type/custom-input-type.module";
import { CommonFilterComponent } from "./common-filter/common-filter.component";
import { CommonSearchBarComponent } from "./common-search-bar/common-search-bar.component";
import { HoverOverComponent } from "./hover-over/hover-over.component";
import { ChartsModule } from "./charts/charts.module";
import { PipelineTooltipLinkComponent } from "./pipeline-tooltip-link/pipeline-tooltip-link.component";
import { AlertsTabComponent } from "./alerts-tab/alerts-tab.component";
import { PipelineUpdatePopupComponent } from "./pipeline-update-popup/pipeline-update-popup.component";
import { MatStepperModule } from "@angular/material/stepper";
import { PatientValueGetterComponent } from "./patient-value-getter/patient-value-getter.component";
import { PopupStatusComponent } from "./popup-status/popup-status.component";
import { AlertSettingsComponent } from './alert-settings/alert-settings.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import { MatTreeModule } from "@angular/material/tree";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
// import { TransactionDetailPopupComponent } from './transaction-detail-popup/transaction-detail-popup.component';
import { PrescriberChangePopupComponent } from './prescriber-change-popup/prescriber-change-popup.component';
import { AlertIconComponent } from "./alert-icon/alert-icon.component";
import {MatBadgeModule} from '@angular/material/badge';
import { CustomTooltipDirective } from "src/app/directives/custom-tooltip.directive";
import { ScrollableItemDirective } from "src/app/directives/scrollable-item.directive";
import { AccordinColumnComponent } from "./accordin-column/accordin-column.component";
import { AlertIconsCommomComponent } from "./alert-icons-commom/alert-icons-commom.component";
import {OverlayModule} from '@angular/cdk/overlay';
import { LazyLoadDirective } from "src/app/directives/lazy-load.directive";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ConfigTypeSelectionComponent } from "./config-type-selection/config-type-selection.component";
import { WidgetHeaderConfigComponent } from "./widget-header-config/widget-header-config.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AgRecordDetailsComponent } from "./ag-record-details/ag-record-details.component";
import { RouterModule } from "@angular/router";
import { SelectionPopupComponent } from "./selection-popup/selection-popup.component";
import { HoverOverClickComponent } from "./hover-over-click/hover-over-click.component";
import { IrCommectPopupComponent } from "./ir-comment-popup/ir-comment-popup.component";
import { PopupDeleteComponent } from "./popup-delete/popup-delete.component";
import { LongPressDirective } from "src/app/directives/long-press.directive";
import { FollowupPopupComponent } from "./follow-up-popup/follow-up-popup.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { MatExpansionModule } from "@angular/material/expansion";
import { EdiitingGridPopupComponent } from './grid/ediiting-grid-popup/ediiting-grid-popup.component';
import { ValueAsIconSnippetComponent } from './value-as-icon-snippet/value-as-icon-snippet.component';
import { CustomChildGroupComponent } from './custom-child-group/custom-child-group.component';
import { PatientsFollowUpComponent } from "./patient-value-getter/patients-follow-up.component";
import { PatientsFlagsComponent } from "./patient-value-getter/patients-flag.component";
import { DetailGridEditingComponent } from "./grid/detail-grid-editing/detail-grid-editing.component";
import { ProgressIndefinite } from "./progress-indicator/indefinite";
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
@NgModule({
  declarations: [
    ClientModelComponent,
    ClientRolesComponent,
    ConfigTypeSelectionComponent,
    CommonFilterComponent,
    CommonSearchBarComponent,
    // PipelineTooltipLinkComponent,
    AlertsTabComponent,
    // PipelineUpdatePopupComponent,
    HoverOverComponent,
    PatientValueGetterComponent,
    PopupStatusComponent,
    AlertSettingsComponent,
    PrescriberChangePopupComponent,
    AlertIconComponent,
    // CustomTooltipDirective,
    LazyLoadDirective,
    ScrollableItemDirective,
    AccordinColumnComponent,
    // AlertIconsCommomComponent,
    WidgetHeaderConfigComponent,
    SelectionPopupComponent,
    HoverOverClickComponent,
    IrCommectPopupComponent,
    PopupDeleteComponent,
    AgRecordDetailsComponent,
    LongPressDirective,
    FollowupPopupComponent,
    ValueAsIconSnippetComponent,
    CustomChildGroupComponent,
    PatientsFollowUpComponent,
    PatientsFlagsComponent,
    EdiitingGridPopupComponent,
    DetailGridEditingComponent,
    CustomSnackbarComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    MatComponentsModule,
    AgGridModule,
    ChartsModule,
    MatButtonModule,
    MatDialogModule,
    CustomInputTypeModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatSelectModule,
    MatOptionModule,
    MatBadgeModule,
    OverlayModule,
    DragDropModule,
    MatSlideToggleModule,
    MatSliderModule,
    NgSelectModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    NgFor,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    NgxMaskModule.forRoot(),
    MatExpansionModule,
    NgSelectModule,
    ProgressIndefinite
  ],

  exports: [
    CommonFilterComponent,
    CommonSearchBarComponent,
    // PipelineTooltipLinkComponent,
    AlertsTabComponent,
    // PipelineUpdatePopupComponent,
    MatStepperModule,
    PopupStatusComponent,
    PatientValueGetterComponent,
    AlertSettingsComponent,
    // TransactionDetailPopupComponent,
    PrescriberChangePopupComponent,
    AccordinColumnComponent,
    AlertIconComponent,
    // AlertIconsCommomComponent,
    WidgetHeaderConfigComponent,
    SelectionPopupComponent,
    HoverOverClickComponent,
    IrCommectPopupComponent,
    PopupDeleteComponent,
    AgRecordDetailsComponent,
    FollowupPopupComponent,
    ValueAsIconSnippetComponent,
    CustomChildGroupComponent,
    PatientsFollowUpComponent,
    PatientsFlagsComponent,
    EdiitingGridPopupComponent,
    DetailGridEditingComponent
  ],
  bootstrap: [PipelineUpdatePopupComponent],
  providers: [{ provide: MatDialogRef, useValue: {} }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedComponentModule {}
