<div class="widget-container" #pivotGridDiv>
  <loading *ngIf="loader"></loading>
  <div>
    <icon-chart 
        [headerConfig]="this.item.config.widget_header_config" 
        [iconList]="iconList" 
        [data]="rowData" 
        [pageKey]="pageKey" 
        [heading]="item.config['report_heading']"
        [chartName]="'pivotGrid'" 
        [config]="aggrid" 
        [item]="item"
        (expendCol)="expendCol($event)"
      ></icon-chart>
  </div>
  
  <!-- <div style="display:flex;flex-direction:row">
    <span style="flex:1;"><h2 style="font-weight: 500;">{{item.config['report_heading'] || ' No Header'}}</h2></span>
    <span>
      <mat-icon [svgIcon]="expandCollapseIcon" (click)="expandCollapse()" [matTooltip]="expandOrCollapse"  class="matExpandCollapseBtn"></mat-icon>
      <mat-icon matTooltip="Setting" *ngIf="item.config['list_of_api'].api_key==100142"  class="matIcons" svgIcon="setting" [matMenuTriggerFor]="setting"></mat-icon>
      <mat-icon matTooltip="Export List"  class="matIcons" svgIcon="dotmenu_circle" [matMenuTriggerFor]="menu"></mat-icon>
    </span>
  </div> -->

  <mat-menu #menu="matMenu">
    <button mat-menu-item  (click)="exportXls()"><mat-icon svgIcon="xls" ></mat-icon>Export XLS</button>
  </mat-menu>

  <mat-menu #setting="matMenu">
    <div mat-subheader style="background-color: #e8e8e8;margin: 3px;border-radius: 8px 8px 0px 0px;padding: 10px;margin: 2px;" > Table view</div>
    <div style="margin: 10px; cursor: pointer;"  (click)="showByValue('source')"> By SP  </div>
    <div style="margin: 10px; cursor: pointer;" (click)="showByValue('NDC')" > By NDCs </div>
  </mat-menu>


  <div class="example-wrapper" #aggrid>
    <ag-grid-angular
      #aggrid
      style="width:100%;height: 380px;padding: 10px;"
      class="ag-theme-alpine-dark test overFlowAdd"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [processPivotResultColGroupDef]="processSecondaryColGroupDef"
      [pivotMode]="true"
      [animateRows]="true"
      [rowHeight]="20"
      [rowData]="rowData"
      [suppressDragLeaveHidesColumns]="true"
      [sideBar]="sideBar"
      [pivotHeaderHeight]="0"
      (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
      <!-- [overlayNoRowsTemplate]="overlayNoRowsTemplate" -->
  </div>
</div>
