<div class="widget-container" #PatientListDiv>
    <loading *ngIf="loader"></loading>
    <!-- Headings -->
    <main >
        <article #fs fullscreen-able [class.is-active]="isActive" [style.padding]="isActive ? '15px' : '0px'" class="gridScrollScreen" [ngClass]="{'gridScrollScreens':deviceDetectionService.isMobile}">

            <div>

                <icon-chart 
                    [headerConfig]="item.config?.widget_header_configuration"
                    [rowDataLength]="this.rowData" 
                    [data]="this.rowData" 
                    [pageKey]="pageKey" 
                    [heading]="heading"
                    [chartName]="'GridList'" 
                    [config]="gridList" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                    (typeToSearch)="onQuickFilterChanged($event)"
                    (onPageSizeChange)="onPageSizeChanged($event)"
                    [sliderValue]="sliderValue"
                    (sliderValueEmitter)="sliderValueBinds($event)"
                    (phiFromGrid)="isPHIFunc($event)"
                    (exportXls)="exportXls()"
                ></icon-chart>

            </div>

            <br>

            <!-- Grid -->
            <div #gridList>
                <ag-grid-angular 
                    style="width:100%;" 
                    class="ag-theme-balham patientGrid" 
                    #agGrid
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef" 
                    [rowStyle]="rowStyle" 
                    [animateRows]="true"
                    [rowHeight]="rowHeight"
                    [paginationPageSize]="paginationPageSize"
                    [tooltipMouseTrack]="true" 
                    [cacheQuickFilter]="true"
                    [excludeHiddenColumns]="true"
                    [pagination]="paginates==false ? false : true" 
                    [defaultExcelExportParams]="defaultExportParams"
                    [rowData]="rowData"
                    (cellClicked)="onCellClicked($event)"
                    [getMainMenuItems]="getMainMenuItems"
                    [suppressDragLeaveHidesColumns]="true"
                    (gridReady)="onGridReady($event)" 
                >
                </ag-grid-angular>
                <!-- [overlayNoRowsTemplate]="overlayNoRowsTemplate" -->
            </div>
        </article>
    </main>
</div>

<ng-template #patientJourney>
    <div style="position: relative;" *ngIf="isDataLoaded">
        <mat-icon class="close-icon" style="float: right;margin: 5px;" [mat-dialog-close]="true">close</mat-icon>


        <div class="stepper-bg" *ngIf="valueToDisplay && report_type == 'P'">
            <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
                Status change history - {{patientId}}
            </div>
            <hr>
            <div class="px-3" style="display: flex;">
                <div *ngFor="let a of valueToDisplay; let lastItem = last;" style="display: table; margin: 0 auto;">
                    <div class="text-center pt-2 px-2 pb-4" style="position: relative;min-width: 100px; width: auto;">
                        <div>
                            <mat-icon
                                [ngStyle]="{'color': a.stage_status == 'Success' ? '#3AC97C' : a.stage_status == 'NA' ? '#E8EAEE' : '#FFCD4A' } "
                                style="width: 30px;height: 30px;z-index: 1;"
                                [svgIcon]="a.stage_status == 'Success' ? status_tick : a.stage_status == 'NA' ? status_na: status_pending ">
                            </mat-icon>
                            <span class="horizantal-line" style="width: 100%;" *ngIf="!lastItem"></span>
                        </div>
                        <span [ngStyle]="{'color' : a.stage_status=='NA' ? '#8A98AB' : '#101D42'}"
                            style="font-size: 10px;line-height: 12px;">{{a.du_status_group}}</span>
                        <br>
                        <span style="font-size: 12px;line-height: 10px;color: #8A98AB">{{a.status_date?
                            '(':''}}{{a.status_date ? ( a.status_date | date: 'dd.MM.YY' ) : ''}}{{a.status_date?
                            ')':''}}</span>
                        <!-- <span style="font-size: 12px;line-height: 10px;color: #8A98AB">{{a.status_date ? ( a.status_date | date ) : ''}}</span> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="stepper-bg" *ngIf="valueToDisplay && report_type == 'C'">
            <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
                Status change history - {{patientId}}
            </div>
            <hr>
            <div class="px-3" style="display: flex;">
                <!-- <hr> -->
                <div *ngFor="let a of valueToDisplay; let lastItem = last;" class="text-center"
                    style="display: table;margin: 0 auto;">
                    <div class="text-center pt-2 px-2 pb-4 align-items-center justify-content-center"
                        style="position: relative;">
                        <div>
                            <mat-icon [ngStyle]="{'color': a.color}" style="width: 30px;height: 30px;z-index: 1;"
                                [svgIcon]="status_circle">
                            </mat-icon>
                            <span class="horizantal-line"
                                [ngStyle]="{'width': valueToDisplay.length == 2 ? '150%' : '100%'}"
                                *ngIf="!lastItem"></span>
                        </div>
                        <div>
                            <span style="font-size: 10px;line-height: 12px;">{{a.curr_status}}</span>
                            <br>
                            <span style="font-size: 12px;line-height: 10px;color: #8A98AB">{{a.curr_du_status_date?
                                '(':''}}{{a.curr_du_status_date ? ( a.curr_du_status_date | date: 'dd.MM.YY' ) :
                                ''}}{{a.curr_du_status_date? ')':''}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="stepper-bg" *ngIf="valueToDisplay ==''">
            <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
                Status change history - {{patientId}}
            </div>
            <hr>
            <div class="px-3" style="display: flex;">
                <!-- <hr> -->
                <div style="display: table; margin: 0 auto;">
                    <div class="text-center pt-2 px-2 pb-4" style="position: relative;">
                        No History
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col">
            <div class="timeline-steps aos-init aos-animate p-3">
                <div class="timeline-step"  *ngFor="let a of dataforTesting">
                    <div class="timeline-content" style="z-index: 999;" >
                        <span class="no_of_days">{{a.no_of_days == null ? '' : a.no_of_days + ' days' }}</span>
                        <mat-icon
                            [ngStyle]="{'color': a.stage_status == 'Success' ? '#3AC97C' : a.stage_status == 'NA' ? '#E8EAEE' : '#FFCD4A' } "
                            style="width: 30px;height: 30px;"
                            [svgIcon]="a.stage_status == 'Success' ? status_tick : a.stage_status == 'NA' ? status_na: status_pending ">
                        </mat-icon>

                        <p class="mt-2 mb-1" [ngStyle]="{'color' : a.stage_status=='NA' ? '#8A98AB' : '#101D42'}" style="font-size: 10px;line-height: 12px;">{{a.du_status_group}}</p>
                        <p style="font-size: 12px;line-height: 10px;color: #8A98AB" class="text-muted mb-0 mb-lg-0">{{'(20.01.2022)'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</ng-template>