import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/services/filter.service";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { WidgetService } from "src/app/services/widget.service";

@Component({
  selector: "app-filter-badges",
  templateUrl: "./filter-badges.component.html",
  styleUrls: ["./filter-badges.component.scss"],
})
export class FilterBadgesComponent implements OnInit, OnDestroy {
  static key = 100301;

  @Input("config") config: any = {};
  @Input("item") item: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
 
  xMark = "x_mark";
  label = "";
  reqSubcription: Subscription[] = [];
  localData: any = [];
  objectData: any = [];
  badgesDefault:any;
  flagOnSide = false;
  dynItemWidth = 'auto';

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent!: ElementRef<any>;
  @ViewChild("filterBadgesDiv", { read: ElementRef }) filterBadgesDiv!: ElementRef<any>;

  private div!: HTMLElement;
  

  constructor(
    public filterService: FilterService, 
    public deviceDetectionService: DeviceDetectionService,
    private cdr: ChangeDetectorRef,
    private widgetService: WidgetService
  ) {};

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateWidth();
  }
  
  ngAfterViewInit() {
    this.calculateWidth()
  }

  calculateWidth() {
    if(!this.deviceDetectionService.isDesktop) return 'auto';
    if(!this.filterBadgesDiv) {this.flagOnSide = false; return 'auto';}
    if(!this.item) {this.flagOnSide = false; return 'auto';}
    let dynItemWidth = ((this.item.cols-5) * 22);

    const element = this.filterBadgesDiv.nativeElement;
    let minWidth = window.innerWidth / element.offsetWidth;
    
    if(minWidth <= 2 && this.widgetService.pageHeaderWidgets?.length>1) {
      if(this.headerExistsOnText()) {
        this.flagOnSide = true;
        this.dynItemWidth = ((window.innerWidth/2)-200) + 'px';
      } else {
        // this.flagOnSide = false;
        // this.dynItemWidth = ((window.innerWidth/2)-200) + 'px';
      }
    } else {
      this.flagOnSide = false;
      this.dynItemWidth = 'auto';
    }

    this.cdr.detectChanges();
  }

  headerExistsOnText() {
   return this.widgetService.pageHeaderWidgets.find((o:any)=> o.key == 100201);
  }

  ngOnInit(): void {
    this.label = this.item.config["report_heading"];

    this.badgesDefault =[...this.filterService.badges]
    this.cdr.detectChanges();
  }

  checkData() {
    
    let filterData = this.filterService.badges.filter(
      (flr: any) => flr.path == "multiSelection"
    );
    if (filterData) {
      let findData = filterData.map((fnd: any) => fnd.value != fnd.length);
      if (findData) {
        this.filterService.badgeCheck = true;
      } else {
        this.filterService.badgeCheck = false;
      }
    }
  }

  resetFIlter() {
    this.filterService.resetFilter();
  }

  selectedValues(event: any) {
    
    this.localData = [];
    this.objectData = [];
    if (event.selectedData?.length >= 1) {
      event.selectedData.forEach((res: any) => {
        if (res[event.labelProp]) {
          let a = {
            key: event.labelProp,
            value: res[event.labelProp],
          };
          this.localData.push(a);
        }
      });
    } else {
      
      if (event.key == "date_range" || event.key == "time_cycle") {
        this.localData = [];
      } else {
        let a = {
          key: event.labelProp,
          value: event.selectedData[event.labelProp],
        };
        this.localData.push(a);
      }
    }
    this.cdr.detectChanges();
  }

  trackByFnMessage(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
