import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { PipelineUpdatePopupComponent } from '../pipeline-update-popup/pipeline-update-popup.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-alert-icons-commom',
  templateUrl: './alert-icons-commom.component.html',
  styleUrls: ['./alert-icons-commom.component.scss']
})
export class AlertIconsCommomComponent implements OnInit {
  @Input('alertData') alertData: any
  @Input('alertIcons') alertIcons: any
  @Input('patientId') patientId: any
  data: any
  tooltipComponent: any = PipelineUpdatePopupComponent;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardName: any;
  employeeKey: any;
  cardId: any;
  baseQueryFromSession: any

  constructor(private filterService: FilterService,
    private router: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    public filterServiceNew: NewFilterService,
    public navigationService: NavigationService,
  ) {
    this.filterService.dataRefreshinTooltip.subscribe((sdf: any) => {
      this.getAlertsforPatientJourney()
    })

    this.router.params.subscribe((p: any) => {
      this.cardName = p['cardName'] || '';

      this.router.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.patientId = decryptedParams["cardId"];
        this.cardId = decryptedParams["cardId"];
      });
    });

    // this.router.params.subscribe((r: any) => {
    //   this.clientKey = r["clientKey"];
    //   this.moduleKey = r["moduleKey"];
    //   this.dashboardKey = r["dashboardKey"];
    //   this.cardName = r["cardName"];
    //   this.cardId = r["cardId"];

    //   if (this.cardName == "paCard") {
    //     this.patientId = this.cardId
    //   } else if (this.cardName == "pwt") {
    //     this.router.queryParams.subscribe((params: any) => {
    //       this.patientId = params.paId
    //     })
    //   }
    // })
  }

  ngOnInit(): void {
    this.getAlertsforPatientJourney();

    this.filterService.refreshAlertDelete.subscribe((res: any) => {
      this.getAlertsforPatientJourney();
    })
  }

  matIconClick(event: any, a: any) {
    Object.assign(a, { id: this.patientId });
    this.filterService.toolTipAlertData = a
    this.filterService.baseQuery = this.filterServiceNew.getPatientStatusBaseQuery()
    document.getElementsByClassName('cdk-overlay-pane custom-tooltip')[0]?.setAttribute('style', 'visibility:visible')
    document.getElementById('d3Tooltip')?.setAttribute('style', 'visibility:hidden;left:unset;right:unset;top:unset;bottom:unset;position:absolute')
  }

  dataLoaded = false
  getAlertsforPatientJourney() {
    this.dataLoaded = false
    this.employeeKey = sessionStorage?.getItem("employee_key");
    if (this.employeeKey) {
      let key = JSON.parse(this.employeeKey)
      this.employeeKey = key["_value"]
    }
    const obj = {
      api_key: 100135,
      report_typ: "R",
      client_key: this.clientKey || "",
      patient_id: this.patientId,
      activity_since_days: this.filterService.sliderValue,
      employee_key: this.employeeKey ? this.employeeKey : '',
    }
    this.filterServiceNew.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        this.dataLoaded = true
        this.alertData = resp[0]?.alert_col_value
        if (this.alertData) {
          this.data = JSON.parse(this.alertData)
          this.alertIcons.forEach((i: any) => {
            if (i.dropdownName == 'Delayed Shipment') {
              return i.dropdownName = 'Shipment Delayed'
            }
          })
          this.data.forEach((s: any) => {
            let find = this.alertIcons.find((a: any) => a.dropdownName == s.Alert_Type)
            if (find) {
              Object.assign(s, find)
            }
          })
        }

      }
    })

  }

  refresh(): boolean {
    return false;
  }

  preventCloseOnClickOut() {
    this.overlayContainer.getContainerElement().classList.add('disable-backdrop-click');
  }

  allowCloseOnClickOut(event: any) {
    event.stopPropagation()
    this.overlayContainer.getContainerElement().classList.remove('disable-backdrop-click');
  }
  onMenuItemClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation()
  }
}
