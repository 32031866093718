<div style="background: #F6F8FA;border-radius: 10px;font-family: 'Poppins !important';padding: 10px 20px;overflow-y: auto;"
    class="editScroll">
    <div *ngIf="record_detail.length==0" style="height: 71px;text-align: center;padding-top: 25px;">
        No Data
    </div>
    <div *ngIf="record_detail.length>0">
        <div *ngFor="let file of record_detail;let idx=index;"
            style="border: 2px solid rgba(232, 234, 238, 1); padding: 10px;border-radius: 10px;margin: 10px 0;">
            <div class="row">
                <div class="col-12">
                    <p class="h4-header">{{file.file_type_name}}</p>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 col-sm-12 col-lg-5 col-md-12 col-xl-5">
                    <div style="border: 1px solid #ddd;border-radius: 10px;">
                        <!-- KPIs header -->
                        <div class="d-flex justify-content-between caption-top" style="padding: 10px 10px;">
                            <span style="font-weight: 600;color: #101D42;">KPIs</span>
                            <span class="editIcon" (click)="editBuckets(file.bucket_detail, file, idx, '1')">
                                <mat-icon style="width: 16px; height: 16px;overflow: visible;"
                                    [svgIcon]="editIcon"></mat-icon>
                                <span> Edit</span>
                            </span>
                        </div>
                        <hr class="hrHeaderDiv">

                        <!-- KPIs Table -->
                        <div style="padding: 5px 10px 10px;height: 101px;">
                            <p class="headerKpis" *ngIf="file.ir_days">Inventory reconciliation</p>
                            <p class="headerKpis headerKpisValue headerKpisValueBottom">{{file.ir_days ? file.ir_days +
                                ' days' : ''}}</p>
                            <p class="headerKpis">Data service fee</p>
                            <p class="headerKpis headerKpisValue">{{ file.data_service_fee ? '$' +
                                file.data_service_fee+'.00' : '0.00'}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-lg-7 col-md-12 col-xl-7">
                    <div style="border: 1px solid #ddd;border-radius: 10px;">
                        <!-- Bucket header -->
                        <div class="d-flex justify-content-between caption-top" style="padding: 10px 10px;">
                            <span style="font-weight: 600;color: #101D42;">Buckets</span>
                            <span class="editIcon" (click)="editBuckets(file.bucket_detail, file, idx, '2')"
                                *ngIf="file.bucket_detail.length>0">
                                <mat-icon style="width: 16px; height: 16px;overflow: visible;"
                                    [svgIcon]="editIcon"></mat-icon>
                                <span> Edit</span>
                            </span>
                            <span class="editIcon" (click)="editBuckets(file.bucket_detail, file, idx, '2')"
                                *ngIf="file.bucket_detail.length==0">
                                <mat-icon style="width: 16px; height: 16px;overflow: visible;"
                                    [svgIcon]="addIcon"></mat-icon>
                                <span> Create buckets</span>
                            </span>
                        </div>
                        <hr class="hrHeaderDiv">

                        <!-- Bucket Table  -->
                        <table class="table table-sm bucketTable" style="margin-bottom: 0px;">
                            <thead>
                                <tr>
                                    <th class="col-4">#</th>
                                    <th class="col-4">Bucket</th>
                                    <th class="col-4">Payment</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="file.bucket_detail.length>0">
                                <tr *ngFor="let tr of file.bucket_detail; let i = index;">
                                    <td>{{ i+1 }}</td>
                                    <td>{{ tr.from_days + ' - ' + tr.to_days + ' days'}}</td>
                                    <td>{{tr.pymt_pct+'%'}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="file.bucket_detail.length==0">
                                <tr>
                                    <td colspan="3" style="text-align: center;">You have no buckets yet</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>