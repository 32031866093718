import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-linechart',
  templateUrl: './multi-linechart.component.html',
  styleUrls: ['./multi-linechart.component.scss']
})
export class MultiLinechartComponent implements OnInit {
 public wrapper:any
  constructor() { }

  ngOnInit(): void {}

  list(event:any,type:any){
    this.wrapper = document.getElementById("wrapper");
    if(type == "list"){
      event.preventDefault();
      this.wrapper.classList.add("list");
    }else{
      event.preventDefault();
      this.wrapper.classList.remove("list");
    }
  }
}
