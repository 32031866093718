import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatMenuPanel } from '@angular/material/menu';
import { ICellRendererParams } from 'ag-grid-community';
import * as d3 from "d3";
import moment from 'moment';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FilterService } from 'src/app/services/filter.service';
import { SessionService } from 'src/app/services/session.service';
import * as uuid from "uuid";
import { IrCommectPopupComponent } from '../../ir-comment-popup/ir-comment-popup.component';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-barchart-api',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})

export class BarChartTempComponent implements OnInit, OnDestroy {
  getId(value: any, par: any) {
    let str = moment(value).format('DD-MM-YYYY')
    let id = str + par.replaceAll(' ',"_")
    return id
  }
  messages: any = []
  searchValue: string = '';
  editedValue: string = '';
  data: any
  style: any
  sparkLineBarData: any;
  props: any;
  divId: any = "sparkLineBarChart";
  pluscolor = '#D8F4E5'
  plusbordercolor: any = '#3AC97C'
  minuscolor = '#F6CFDA'
  minusbordercolor: any = '#D11044'
  discrepancycolor = '#1363DF1A'
  discrepancybordercolor = '#1363DF'
  sparkLineBarChart: any
  @ViewChild("menu") menu!: MatMenuPanel;
  @ViewChild("menus") menus!: MatMenuPanel;
  maxValue: any = 50
  maxValue2: any = 200
  show: any
  isChecked: boolean = false
  edit: any = false
  editIndex: any
  paramsData: any
  employee_key: any
  CommentData: any
  commentIndex: any
  Cumulative:boolean=false
  @ViewChild('customTemplate') customTemplate: any;
  @ViewChild('DeleteDialog') DeleteDialog: any;
  reqSubcription: Subscription[] = [];

  getMenu() {
    if( Object.keys(this.data).length==0) return
    let dialogRef = this.dialog.open(IrCommectPopupComponent, {
      height: this.deviceDetectionService.isMobile ? '90%' : 'auto',
      width: this.deviceDetectionService.isMobile ? '90%' : '70%',
      disableClose: true,
      panelClass: "du-custom-modalbox",
      data: {
        params: this.paramsData,
        messages: this.messages
      }
    });

    dialogRef.afterClosed().subscribe((value) => {
      
      this.messages = value
    });

    // return this.menu;
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  panelOpenState = false;
  constructor(public sessionService: SessionService, public filterService: FilterService,
    public dialog: MatDialog, private currency: CurrencyPipe,
    private dashboardService: DashboardService,
    public deviceDetectionService: DeviceDetectionService) {
    var empkey: any = sessionStorage.getItem("employee_key");
    this.employee_key = JSON.parse(empkey);
  }
  agInit(params: ICellRendererParams): void {
    this.paramsData = params
    // console.log(this.Cumulative)
    this.Cumulative = (this.paramsData.params.item.config?.cumulative?.value)=='true'
    // console.log(this.Cumulative ,this.paramsData.params.item.config?.cumulative?.value)
    function strToObj(e: any) { var obj = new Function("return" + e); return obj() };
    this.data = params?.value ? params?.value : []
    this.data.source_key
    // sorting the data based on the keys
    let dateFormat:any= this.filterService.report_type
    this.messages = this.paramsData?.params?.commentsData?.filter((student: any) => student.data_source_key == this.data.source_key && moment(student.period).format("yyyy-MM-DD") == moment(this.paramsData.data.period).format("yyyy-MM-DD") && (dateFormat==student.time_cycle) &&( this.filterService.showByKey=='N'? (student?.ndc_key == this.data?.ndc_key) : (student?.sp_location_key == this.data?.location_key)))
    let maxData = Math.max(this.data['dispense_quantity'], this.data['inventory_quantity'])
    let maxData2 = Math.max(this.data['dispense_cumulative_qty'], this.data['inventory_cumulative_qty'])
    // this.maxValue = maxData
    // this.maxValue2 = maxData2
    if (this.maxValue < maxData) {
      this.maxValue = maxData
    }
    if (this.maxValue2 < maxData2) {
      this.maxValue2 = maxData2
    }
     if( Object.keys(this.data).length==0){
        this.style = { 'height':this.Cumulative? '150px':"75px", 'width': '200px', 'background-color': '#E8EAEE', 'border': '2px solid #8A98AB' , 'border-radius': '7px' }
      }else if (this.data['discrepancy'] == 'Y' && this.data['difference'] == 0) {
      this.style = { 'height': this.Cumulative? '150px':"75px", 'width': '200px', 'background-color': this.discrepancycolor, 'border': '2px solid' + this.discrepancybordercolor, 'border-radius': '7px' }
    } else if (this.data['difference'] == 0) {
      this.style = { 'height': this.Cumulative? '150px':"75px", 'width': '200px', 'background-color': this.pluscolor, 'border': '2px solid' + this.plusbordercolor, 'border-radius': '7px' }
    } else {
      this.style = { 'height': this.Cumulative? '150px':"75px", 'width': '200px', 'background-color': this.minuscolor, 'border': '2px solid' + this.minusbordercolor, 'border-radius': '7px' }

    }
    document.getElementById('comment')?.focus()
  }
  ngOnInit(): void {
  }
  calculateMarginTop(): string {
    return `calc(100% - ${this.Cumulative ? '75%' : '95%'})`;
  }

  messageData(value: any, event: any) {
    event.stopPropagation()
    if (value == '') return
    let insert: any
    insert = this.paramsData.params.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "INSERT")

    let postdata: any = {
      "report_typ": insert.report_typ || "I",
      "comments": value,
      "data_source_key": this.data.source_key || undefined,
      "period": moment(this.paramsData.data.period).format("yyyy-MM-DD"),
    }
    if (this.paramsData?.params?.sourceName) {
      postdata["location_key"] = this.data.location_key
    }
    this.loadCommentsData(postdata)
    this.searchValue = ''
    document.getElementById('comment')?.focus()
  }
  getCurrentDate(date: any) {
    if (date) {
      return moment(date).format('MM/DD/YYYY HH:mm')
    } else {
      return moment().format('MM/DD/YYYY HH:mm')
    }
  }
  getData(data: any) {
    let val = isNaN(data) ? 'Not Available' : data
    return val
  }
  deleteComment(data: any, index: any) {
    let deletert: any
    deletert = this.paramsData.params.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "DELETE")

    let postdata = {
      "report_typ": deletert.report_typ || "D",
      "user_comments_key": this.CommentData.user_comments_key
    }
    this.loadCommentsData(postdata)
    this.messages.splice(this.commentIndex, 1);
  }
  stop(event: any) {
    event.stopPropagation()
    event.preventDefault()
  }
  editComment(message: any, event: any, i: any) {
    this.stop(event);
    this.editedValue = this.CommentData.comments
    this.editIndex = this.commentIndex
    this.edit = true
  }
  SaveEdit(message: any, index: any) {
    let update: any
    update = this.paramsData.params.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "UPDATE")

    let postdata = {
      "user_comments_key": message.user_comments_key,
      "report_typ": update.report_typ || "U",
      "comments": this.editedValue,
    }
    this.loadCommentsData(postdata)
    this.messages[index].comments = this.editedValue
    this.messages[index].update_timestamp = moment().format('MM/DD/YYYY HH:mm')
    this.edit = false

  }
  openDialog(event: any) {
    let po: DialogPosition = { left: event.clientX + 'px', top: event.clientY + 'px' };
    this.dialog.closeAll();
    this.dialog.open(this.customTemplate, { hasBackdrop: true, position: po, });
  }
  DelDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(this.DeleteDialog, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
  loadCommentsData(data: any) {

    data['comment_typ'] = 'IR'
    data['api_key'] = this.paramsData.params.item.config['comments_api'].api_key || 100132;
    data['dashboard_key']= this.dashboardService.dashboardKey;

    this.reqSubcription.push(this.filterService.commentsQuery(data).subscribe((res: any) => {
      if (data.report_typ == 'I') {
        let commentsvalue = res ? res : []
        this.messages = commentsvalue
        this.myFunction()
      }
    }))

  }

  myFunction(): void {
    setTimeout(() => {

      const element: any = document.getElementById("content");
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 200);
  }
  getcomentData(message: any, i: any) {
    this.CommentData = message
    this.commentIndex = i
  }
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }
  getStyles() {

    return { width: "100%", height: this.paramsData?.params?.sourceName ? "75%" : "62%", 'overflow-y': "auto" }
  }
  editedChanges(updatedDate: any) {
    if (updatedDate == null) { return false } else {
      let start_Date: any = moment(updatedDate);
      let end_Date: any = moment(new Date());
      let totalDays: any = end_Date.diff(start_Date, 'days');
      if (totalDays <= this.paramsData.params.item.config.no_of_days.value) {

        return true
      } else {
        return false
      }
    }
  }

  objCondition(obj: any): boolean {
    return Object.keys(obj).length == 0
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}