import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertSubscriptionService } from '../../services/alert-subscription.service';
import { Subscription, debounceTime } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-alert-multi-select',
  templateUrl: './alert-multi-select.component.html',
  styleUrls: ['./alert-multi-select.component.scss']
})
export class AlertMultiSelectComponent implements OnInit {

  @Input('field') field:any;
  @Input('allFields') allFields:any;
  @Input('data') data:any;
  @Input('selectAll') selectAll:any;
  @Input('valueChanges') valueChanges:any;

  @Output('emitSelectedValue') emitSelectedValue = new EventEmitter<any>();
  
  valueProp = "";
  labelProp = "";
  label: any;
  currentField: any;
  parentKey: any = "";
  placeholder: any;
  selectedOption: any[] = [];
  option:any;
  opt:any;
  fieldKey:any;
  oncloseCondition = false;
  isChecked = false;
  selectAllLabel = 'Select All';
  enteredText:any;
  firstTime = true;
  isAddChecked=false;
  itemsShowLimit = 2;
  reqSubcription: Subscription[] = [];
  key:any;

  validationOnParentChecks = false;

  // Define a variable to hold the subscription
  public parentControlSubscription: Subscription | undefined;

  constructor(
    private alertSubscriptionService: AlertSubscriptionService,
  ) {
  }

  ngOnInit(): void {
    // Get Value from configuration
    this.key = this.field.key
    this.opt = this.field.templateOptions || {};
    this.labelProp = this.opt.labelProp
    this.valueProp = this.opt.valueProp
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl

    this.option = this.selectAll ? this.selectAll[this.key] : this.alertSubscriptionService.allOnLoadDataForFields[this.key];
    
    if (this.currentField.parentKey) {
      const parentData = this.data[this.currentField.parentKey];
      if (
        !parentData ||
        (typeof parentData === 'object' && Object.keys(parentData).length === 0) || // Check for empty object
        (Array.isArray(parentData) && parentData.length === 0) // Check for empty array
      ) {
        this.validationOnParentChecks = true;
      }
    } else {
      this.validationOnParentChecks = false;
    }

    this.selectedOption = this.data[this.key];
    // debugger
    this.isChecked = (this.selectedOption?.length == this.option?.length) ? true : false;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['valueChanges'] && changes['valueChanges'].currentValue !== changes['valueChanges'].previousValue) {

      let curVal = changes['valueChanges'].currentValue;
      let selectVal = curVal.valueChange;

      let fndKey = this.allFields.find((o:any)=> o.parentKey == selectVal?.key)

      if(fndKey && this.valueChanges.position == selectVal?.position && fndKey.key == this.key) {
        this.option = this.selectAll ? this.selectAll[this.key] : this.alertSubscriptionService.allOnLoadDataForFields[this.key];
        
        this.selectedOption = this.selectedOption.filter((f:any)=> this.option.find((o:any)=> o[this.valueProp] == f[this.valueProp]));
        this.selectionChange();

        this.isChecked = (this.selectedOption?.length == this.option?.length) ? true : false;
        this.validationOnParentChecks = false;
      }
    }
  }

  validation() {
    if(!this.selectedOption) return true;
    if(this.selectedOption.length==0) return true;
    return false;
  }

  // Load API Data
  loadApiData(method: string, url: string, key: string, data: any) {

    this.reqSubcription.push(this.alertSubscriptionService.loadApiData(method, url, key, data).subscribe((data: any) => {
      
      if(!data || data.length==0) {
        this.setValueWhenNoData();
        return
      } 
      
      this.option = data;
      this.selectedOption = [];
      this.field.formControl?.setValue(this.selectedOption);
      this.isChecked = (this.selectedOption?.length == this.option?.length) ? true : false;
  
    }, (err:any)=> {
      // when there is no data in filter
      this.setValueWhenNoData();
    }))
  }

  setValueWhenNoData() {
    this.option=[];
    this.selectedOption = [];
    this.field.formControl?.setValue({ [this.key as string]: ''})
  }

  selectOption() {
    this.isChecked = (this.selectedOption?.length == this.option?.length) ? true : false;
    this.selectAllLabel = 'Select All';
  }

  // Excel Filter to get data
  onFilterTextBoxChanged($event: any) {
    return ($event.target as HTMLInputElement).value;
  }

  validationForField = false;

  // drop down close to get details
  onClose() {
    this.isChecked = (this.selectedOption?.length == this.option?.length) ? true : false;
    if(!this.selectedOption || this.selectedOption.length==0) {
      this.validationForField = true;
    } else {
      this.validationForField = false;
    }
    this.selectionChange();
  }


  selectAllClick() {
    this.isChecked = !this.isChecked
    if(this.isChecked) {
      this.selectedOption = this.option;
    } else {
      this.selectedOption = [];
    }

    this.selectAllLabel = 'Select All';

  }

  selectionChange() {

    let obj = {
      key : this.key,
      value : this.selectedOption
    }
    this.emitSelectedValue.emit(obj);
  }
}
