<div class="login-wrapper">
  <div class="w-100 globalNav">
      <div class="navbar-area sticky-top">
          <div class="main-nav">
              <div class="w-100 header_home_main">
                  <nav class="navbar navbar-expand-md navbar-light">
                      <a class="navbar-brand" href="index.html">
                          <img src="assets/SCRAII_Logo.png" alt="Logo" />
                      </a>
                  </nav>
              </div>
          </div>
      </div>
  </div>

  <div class="login-container">
      <div class="row mx-0">
          <div class="col-xl-6 col-lg-8 col-md-9 col-sm-12 card flex-row mx-auto px-0">
              <div class="img-left d-none d-md-flex"></div>
                <div class="card-body">
                    <div style="text-align: center;font-family: Poppins;">
                        <div class="col-lg-12 col-sm-12 col-12 p-0">
                            <h4 class="title mt-3 mb-4" style="font-family: 'Poppins' !important;">
                                Impersonation 
                            </h4>

                            <div style="height: 120px;">
                                <ng-select 
                                    class="userSelectionSingle" 
                                    [items]="allUsers" 
                                    [multiple]="false"
                                    [clearable]="false"
                                    [(ngModel)]="selectedUsername" 
                                    bindLabel="usr_display_nm"
                                >
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.usr_display_nm}}
                                    </ng-template>
                                </ng-select>

                            </div>
                        </div>

                        <button type="submit" class="btn btnBtn btn-block" (click)="impersonate()">Impersonate</button>
                        <button type="submit" class="btn btnBtn btn-block" (click)="goBack()">Go Back</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>