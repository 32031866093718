import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DeviceDetectionService } from 'src/app/services/detectipad.service'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-progress-indefinite',
  template: `
    <ng-container *ngIf="this.deviceDetectionService.isDesktop">
      <div class="animation"></div>
    </ng-container>
  `,
  styleUrls: ['./indefinite.scss']
})

export class ProgressIndefinite {
  constructor (public deviceDetectionService: DeviceDetectionService) {
  }
}