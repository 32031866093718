import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as d3 from 'd3';
import * as lodash from 'lodash'
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';
import { Arc, DefaultArcObject } from 'd3'
import { style } from '@angular/animations';
import { Vega, Legend, Tooltip } from '@dataunveil/ducharts';
import { ChartsModule } from '../../shared/charts/charts.module';
import widgetModel from '../widget.model';

@Component({
  // standalone: true,
  // imports: [Vega, Legend, Tooltip,CommonModule,ChartsModule,],
  selector: 'app-pie-chart-card',
  templateUrl: './pie-chart-card.component.html',
  styleUrls: ['./pie-chart-card.component.scss']
})
export class PieChartCardComponent implements OnInit, OnDestroy {
  static key = 100708;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("pieChartDiv", { static: true }) pieChartDiv!: ElementRef;
  @ViewChild("fs") fs!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  @Input("dynamic") dynamic!: any;
  data: any
  heading: any;
  isActive = false;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  sourceName: any;
  pieData: any;
  config: any;
  isLoading: boolean = false;
  noData: boolean = false;
  FilterString: any = 'History Patient Count'
  DonutId: string = 'chart'
  total: any
  legendData: any;
  chartType: any;
  constructor(private filterService: FilterService, private currency: CurrencyPipe, private httpClient: HttpClient, private apiService: ApiService) {
    // Filter Loads
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData();
      this.isLoading = true
    }));
    this.reqSubcription.push(
      this.filterService.chartshowBy.subscribe((apiKey: any) => {
        if (apiKey.widget == this.item['key']) {
          if (this.item['config']['chart_type'].name != 'DonutpieChart') {
            this.isLoading = true
            this.FilterString = apiKey.value
            this.pieData = this.data.filter((f: any) => f.type == apiKey.value)
            this.pieConfig(this.item.config.divid);
            setTimeout(() => {
              this.isLoading = false
            }, 200);
          }
        }
      }))
  }

  ngOnInit(): void {
    this.isLoading = true
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.pieChartDiv.nativeElement);
    this.loadlineConfig();
    if (this.dynamic == true) {
      this.loadApiData()
    }
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData(api_key?: any) {
    // debugger
    let api = this.item.config["list_of_api"] || {};

    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payload
      let obj: any = { "api_key": api_key ? api_key : api }
      this.isDataLoaded = false;
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          this.data = res
          this.pieData = this.item['config']['chart_type'].name == 'pieChart' ? res.filter((f: any) => f.type == this.FilterString) : res
          this.isDataLoaded = true
          this.isLoading = false
          if (res.length == 0) { this.noData = true } else { this.noData = false }

          this.pieConfig(this.item.config.divid);
        }, (err: any) => {
          console.log(err)
          this.pieData = []
          this.noData = true
          this.isLoading = false
          this.isDataLoaded = true;
          this.pieConfig(this.item.config.divid);
        })
      )


    }
  }
  screenChanges(event: any) {
    this.isActive = event
    this.isFullscreen = event
    this.config = {}
    this.pieConfig(this.item.config.divid)

  }

  pieConfig(name: string) {

    this.DonutId = 'chart' + name
    this.total = lodash.sumBy(this.pieData, 'value');
    this.chartType = this.item['config']['chart_type'].name;

    if (this.item['config']['chart_type'].name == 'DonutpieChart') {

      setTimeout(() => {
        this.donutPieConfig(this.data, 'type')
        let piedata:any=this.groupByAndSum(this.data, 'label' )
        this.bindChart(name+'pie',piedata,'P');
        let donutdata:any=this.groupByAndSum(this.data, 'type')
        this.bindChart(name,donutdata,"D");
      }, 50);
    } else {
      setTimeout(() => {
        this.donutPieConfig(this.pieData, 'category')
        let donutdata:any=this.groupByAndSum(this.pieData, 'category')
        // debugger
        this.bindChart(name,donutdata,"D");
      }, 50);
    }

  }
  donutPieConfig(data: any, label: string) {

    this.total = lodash.reduce(data, (acc, v) => acc + v.value, 0)
    const colorScheme: any = ['#FFB039', '#5F5196', '#2CB239', '#7AD045', '#25D3EB', '#1566DE', '#7848FF', '#CB04DC', '#FA195C', '#9E0000', '#EACD37', '#DD630B', '#BCD03F', '#14BDA9', '#8A05F3', '#D9476A', '#2DADB6', '#E468C2', '#199CD4', '#0934CC'];
    var index: any = 0
    this.legendData = lodash.map(data, d => {
      index = index + 1
      return {
        label: d[label],
        info: label=='category'?d3.format('.2%')(d.value / this.total)+' ('+d.value+')':`${d.value} days (${d3.format('.0%')(d.value / this.total)})`,
        color: colorScheme[index - 1],
      }
    })
  }

  bindChart(id: any,data:any,chartType:string) {

    d3.select('#chart' + id).select('svg').remove()
    const colors: any = ['#FFB039', '#5F5196', '#2CB239', '#7AD045', '#25D3EB', '#1566DE', '#7848FF', '#CB04DC', '#FA195C', '#9E0000', '#EACD37', '#DD630B', '#BCD03F', '#14BDA9', '#8A05F3', '#D9476A', '#2DADB6', '#E468C2', '#199CD4', '#0934CC']
    var datatest: any[] = data 
    for (let index = 0; index < datatest.length; index++) {
      datatest[index]['color'] = colors[index]
    }
   
    let width = chartType=='P'?(this.isFullscreen ? 200 : 120):(this.isFullscreen ? 300 : 200),
      height =chartType=='P'?(this.isFullscreen ? 200 : 120): (this.isFullscreen ? 300 : 200);

    var outerRadius =chartType=='P'? 0 : (this.isFullscreen ? 120 : 70)
    var innerRadius =chartType=='P'?(this.isFullscreen ? 100 : 60): (this.isFullscreen ? 150 : 100)
    var pie1 = d3
      .pie()
      .value((d: any) => {
        return d.value;
      })
      .sort(null);

    let arc: Arc<any, DefaultArcObject> = d3
      .arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)
      .cornerRadius(0)
      .padAngle(0.01);

    var outerArc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);

    var svg: any = d3
      .select('#chart' + id)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var path = svg
      .selectAll('path')
      .data(pie1(datatest as any))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d: any) {
        return d.data.color;
      });

    path
      .transition()
      .duration(1000)
      .attrTween('d', function (d: any) {
        const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function (t: any) {
          return arc(interpolate(t));
        };
      });

    var restOfTheData = function () {
      var text = svg
        .selectAll('text')
        .data(pie1(datatest as any))
        .enter()
        .append('text')
        .transition()
        .duration(200)
        .attr('transform', function (d: any) {
          return 'translate(' + arc.centroid(d) + ')';
        })
        .attr('dy', '.4em')
        .attr('text-anchor', 'middle')
        .text(function (d: any) {
          return  chartType=='P'? d.data.label:undefined;
        })
        .style('fill', '#fff')
        .style('font-family', 'poppins')
        .style('font-size', '9px');



    };

    setTimeout(restOfTheData, 100);
  }

  groupByAndSum(data: any, key: any) {
    return Object.values(data.reduce((result: any, currentValue: any) => {
      const name = currentValue[key];
      result[name] = result[name] || { ...currentValue, value: 0, color: '' };
      result[name].value += currentValue.value;
      return result;
    }, {}));
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
  test() { }
}
