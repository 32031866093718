<div class="widget-container" #pivotGridDiv>
    <!-- <div style="display:flex;flex-direction:row">
      <span style="flex:1;"><h2 style="font-weight: 500;">{{item.config['report_heading'] || ' No Header'}}</h2></span>
      <span>
        <mat-icon  class="MenuStyle" svgIcon="dotmenu_circle"   [matMenuTriggerFor]="menu"></mat-icon>
        <mat-icon [svgIcon]="expandCollapseIcon" (click)="expandCollapse()" [matTooltip]="expandOrCollapse" matTooltipClass="expand-tooltip" class="matExpandCollapseBtn"></mat-icon>
      </span>
     
    </div> -->
  <mat-menu #menu="matMenu">
    <button mat-menu-item  (click)="exportXls()"><mat-icon svgIcon="xls" ></mat-icon>Export XLS</button>
  </mat-menu>

  <ag-grid-angular *ngIf="pivot === true"
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine-dark center pivotGridManualCss"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="this.deviceDetectionService.isMobile ? autoGroupColumnDefInMobile :autoGroupColumnDef"
    [processPivotResultColGroupDef]="processSecondaryColGroupDef"
    [pivotMode]="true"
    [animateRows]="true"
    [suppressDragLeaveHidesColumns]="true"
    [rowData]="rowData"
    [suppressMenuHide]="true"
    [suppressExpandablePivotGroups]="false"
    [pivotHeaderHeight]="0"
    suppressMenu = true
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular>

  <ag-grid-angular  *ngIf="pivot === false"
    style="width: 100%;height: 300px;"
    class="ag-theme-alpine-dark center pivotGridManualCss"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDefchild"
    [suppressMenuHide]="true"
    [suppressDragLeaveHidesColumns]="true"
    [rowData]="rowData"
    [pagination]="pagination"
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular>
  
</div>