import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core'
import { StateService } from '@dataunveil/ducharts'
import { Vega, Legend, Tooltip } from '@dataunveil/ducharts'
import * as _ from 'lodash';
import * as d3 from 'd3'

import config from './config'
import { Subscription } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { FilterService } from 'src/app/services/filter.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ChartsModule } from '../../shared/charts/charts.module'
import { CommonModule } from '@angular/common'
import { Arc, DefaultArcObject } from 'd3'

@Component({
  standalone: true,
  imports: [Vega, Legend, Tooltip,CommonModule, FormsModule,ChartsModule],
  selector: 'app-time-to-therapy',
  template: `
<div class="widget-container"  #donutPieChartDiv>
  <!-- Headings -->
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background: white;">
      <div class="m-1 mb-2">
        <icon-chart [headerConfig]="item.config?.widget_header_configuration" [data]="this.data" [pageKey]="pageKey"
          [heading]="heading" [chartName]="'donutPieChartDiv'" [config]="DonutpieC" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item"></icon-chart>
      </div>

      <hr>
      <!-- chart -->
      <br>
      <div *ngIf="isLoading" ><loading *ngIf="isLoading"> </loading></div> 
      <div style="height: 200px; width: auto; " *ngIf="noData">
       <span class="bg-text" >There is no data for this time period</span></div>
      <div #DonutpieC [style.display]="noData?'none':''" class='mains'>
      <div class=" chart col-lg-5 col-xl-5 col-md-5 col-sm-12">
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ total }} records</div>
        </div>
        <duc-vega [config]="config.vega" [data]="data"></duc-vega>
           <!-- <duc-tooltip [config]=" $any(config.tooltip)" [data]="data"></duc-tooltip> -->
      </div>
      <div class="col-lg-7 col-xl-7 col-md-7 col-sm-12" style="padding: 10px 0; overflow: auto;">
      <duc-legend  style="flex-wrap: wrap;  display: flex; flex-direction: column;justify-content: unset;" [config]="$any(config.legend)" [data]="legendData"></duc-legend></div>
      <!-- <div class="chart">
        <duc-vega [config]="config.donut" [data]="data"></duc-vega>
           <duc-tooltip [config]="config.tooltip"></duc-tooltip>
        <div class="inset">
        <div id='chart' class="chart-container"></div>
        </div>
      </div>
      <duc-legend [config]="$any(config.legend)" [data]="legendData"></duc-legend> -->
    </div >
    
   
    </article>
  </main>
</div>
  
  `,
  styleUrls: ['./time-to-therapy.scss']
})
export class TimeToTherapy {
  // static key = 100708;
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("donutPieChartDiv", { static: true }) donutPieChartDiv!: ElementRef;
  @Input("dynamic") dynamic!: any;
  @ViewChild(Vega) vega!: Vega
  @ViewChild("fs") fs!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive = false;
  reqSubcription: Subscription[] = [];
  legendData: any[] = [];
  data: any = [];
  config: any = config;
  heading: string = '';
  total: number = 0;
  isLoading: boolean = false;
  noData: boolean = false;
  isDataLoaded: boolean = false;
  constructor(protected stateService: StateService, private filterService: FilterService, private httpClient: HttpClient, private apiService: ApiService) {

    // Filter loads
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
        this.isLoading = true

      })
    );

  }
  ngOnInit() {
    this.isLoading = true
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.donutPieChartDiv.nativeElement);
    this.heading = this.item.config["report_heading"];
    // this.donutPieConfig()

    this.reqSubcription.push(this.stateService.get(this.config.legend.id, 'disabled')
      .subscribe((state: any) => this.#onLegendUpdate(state.disabled)))
      if(this.dynamic==true){
        this.loadApiData()
      }
  }

  #onLegendUpdate(state: any) {
    console.log(state)
  }

  loadApiData() {

    let api = this.item.config["list_of_api"] || {};
    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payload
      let obj: any = { "api_key": api }
      this.isDataLoaded = false;
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          this.data = res == null ? [] : res
          
          this.isDataLoaded = true
          this.isLoading = false
          if (res.length == 0) { this.noData = true } else { this.noData = false }
          this.donutPieConfig()
        }, (err: any) => {
          console.log(err)
          this.data = []
          this.noData = true
          this.isLoading = false
          this.isDataLoaded = true;
        })
      )


    }
  }

  async donutPieConfig() {

    // this.data = await fetch('data/time-to-therapy/data.json').then(a => a.json())
    this.total = _.reduce(this.data, (acc, v) => acc + v.value, 0)
    const colorScheme:any= [ '#FFB039','#2CB239', '#7AD045', '#25D3EB',  '#1566DE', '#7848FF', '#CB04DC', '#FA195C', '#9E0000', '#EACD37', '#DD630B', '#BCD03F', '#14BDA9', '#8A05F3', '#D9476A', '#2DADB6', '#E468C2', '#199CD4', '#0934CC'];
    var index:any=0
    this.legendData = _.map(this.data, d => {
      return {
        label: d.type,
        info: d3.format('.2%')(d.value / this.total),
        color: this.vega.colorScale(d.type),
      }
    })
    // this.legendData = _.map(this.data, d => {
    //   index= index+1
    //   return {
    //     label: d.type,
    //     info: `${d.value} days (${d3.format('.0%')(d.value / this.total)})`,
    //     color:  colorScheme[index-1],//this.vega.colorScale(d.type),
    //   }
    // })
    this.bindChart();
  }
  groupByAndSum(array:any, key:any) {
    return Object.values(array.reduce((result:any, currentValue:any) => {
      const name = currentValue[key];
      result[name] = result[name] || { ...currentValue, value: 0 ,color:''};
      result[name].value += currentValue.value;
      return result;
    }, {}));
  }

  bindChart() {
    d3.select('#chart').select('svg').remove()
    const colors:any =  [ '#FFB039','#5F5196','#2CB239', '#7AD045', '#25D3EB',  '#1566DE', '#7848FF', '#CB04DC', '#FA195C', '#9E0000', '#EACD37', '#DD630B', '#BCD03F', '#14BDA9', '#8A05F3', '#D9476A', '#2DADB6', '#E468C2', '#199CD4', '#0934CC']
    var datatest:any[] =this.groupByAndSum(this.data,'label')
    for (let index = 0; index < datatest.length; index++) {
      datatest[index]['color']=colors[index]
    }    
    // datatest[1]['color']='#2CB239'
    // datatest[2]?.['color']='#25D3EB'

    console.log(datatest)
    var width = 120,
      height = 120;

    var outerRadius = 0;
    var innerRadius = 60;
    var pie1 = d3
      .pie()
      .value((d: any) => {
        return d.value;
      })
      .sort(null);

    let arc: Arc<any, DefaultArcObject> = d3
      .arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)
      .cornerRadius(0)
      .padAngle(0.01);

    var outerArc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);

    var svg: any = d3
      .select('#chart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      var path = svg
      .selectAll('path')
      .data(pie1(datatest as any))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d:any) {
        return d.data.color;
      });

    path
      .transition()
      .duration(1000)
      .attrTween('d', function (d:any) {
        const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function (t:any) {
          return arc(interpolate(t));
        };
      });

    var restOfTheData = function () {
      var text = svg
        .selectAll('text')
        .data(pie1(datatest as any))
        .enter()
        .append('text')
        .transition()
        .duration(200)
        .attr('transform', function (d:any) {
          return 'translate(' + arc.centroid(d) + ')';
        })
        .attr('dy', '.4em')
        .attr('text-anchor', 'middle')
        .text(function (d:any) {
          return d.data.label ;
        })
        .style('fill', '#fff')
        .style('font-family', 'poppins')
        .style('font-size', '9px');


  
    };

    setTimeout(restOfTheData, 100);
  }
}