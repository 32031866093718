import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-popup',
  template: `<div class="popupcontainer">
                 <p class='textstyle'> <img  src="./../../../../assets/svgIcons/info_circle.svg"/>    {{data}}</p> 
                <div class="du-btn du-confirm-btn create-align float-none" mat-dialog-close>
                  <i class="fa fa-check-circle fa-icon du-mt-2 "></i><span class="du-btn-text">Ok</span>
                </div>
              </div>`,
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
