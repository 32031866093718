import { Component, Input } from '@angular/core'
import * as d3 from 'd3'
import { Tooltip } from '@dataunveil/ducharts'
import { CommonModule, CurrencyPipe } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'

const timeFormatter = d3.timeFormat('%a, %d %b %Y')

@Component({
  standalone: true,
  imports: [Tooltip, CommonModule, MatIconModule],
  selector: 'app-status-cell-info-dem',
  template: `
    <ng-container *ngIf="Object.keys(data.details).length">
      <div class="header" *ngIf="header">
        <div class="date">{{ timeFormatter(data.xKey) }}</div>
        <div class="title">Transaction status</div>
      </div>
      <div class="main">
        <div class="statuses">
          <div class="status"><span><mat-icon class='matIcons' svgIcon="check" ></mat-icon></span>SENT: {{ numbedPipe(data.details.sent_records) }}
           <span >
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data.details.sent_records_trend == 'up'" />
                  <span  *ngIf="data.details.sent_records_trend == 'nochange'" style='font-size:28px;color:#1363df;'>-</span>
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data.details.sent_records_trend == 'down'" />
          </span>
              </div>
          <div class="status"><span><mat-icon class='matIcons' svgIcon="crossdem" ></mat-icon></span>ON HOLD: {{ numbedPipe(data.details.on_hold) }}
          <span >
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data.details.on_hold_trend == 'up'" />
                  <span  *ngIf="data.details.on_hold_trend == 'nochange'" style='font-size:28px;color:#1363df;'>-</span>
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data.details.on_hold_trend == 'down'" />
          </span>
        </div>
          <!-- <div class="status"><span>&#x2714; </span>SENT: {{ numbedPipe(data.details.sent_records) }}</div>
          <div class="status"><span>&#x274C; </span>ON HOLD: {{ numbedPipe(data.details.on_hold) }}</div> -->
          <!-- <div class="problems">
            <div class="status">
              <div><span>&#x274C;</span>FAILURES: {{ data.details.failures }}</div>
            </div>
            <div class="status">
              <div><span>&#x274C;</span>ERRORS: {{ data.details.errors }}</div>
            </div>
            <div class="status">
              <div><span>&#x274C;</span>ON HOLD: {{ data.details.on_hold }}</div>
            </div>
          </div> -->
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./cell-info.scss']
})
export class CellInfo {
  @Input() data: any
  @Input() header: any

  constructor(private currency: CurrencyPipe) {
  }

  protected readonly timeFormatter = timeFormatter
  Object = Object

  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2')
  }
}