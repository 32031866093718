import { FilterService } from "src/app/services/filter.service";
import { DataService } from "src/app/services/data.service";
import {
  Component,
  ViewEncapsulation,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import * as _ from "lodash";
import { MatMenuTrigger } from "@angular/material/menu";
import { IconService } from "src/app/services/icon.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";

@Component({
  selector: "app-work-display",
  templateUrl: "./work-display.component.html",
  styleUrls: ["./work-display.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WorkDisplayComponent implements OnInit {
  //variable
  workConfig: any;
  frameworkComponents: any;
  pagename: any;
  workKey: any;
  version: any;
  selectedWork: any;

  userName: string = "";
  moduleKey: any;
  clientKey: any;
  dashboardKey: any;
  pages: any;
  workName = "";
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
  constructor(
    private dataService: DataService,
    private iconService: IconService,
    private filterService: FilterService,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private dashboardService: DashboardService
  ) {
    this.loadWorkConfig();
  }

  ngOnInit(): void {}

  loadWorkConfig() {
    this.router.params.subscribe((p) => {
      this.workKey = p["workKey"];
      this.version = p["version"];
      this.dataService
        .getWorkDetail(this.workKey, this.version)
        .subscribe((data: any) => {
          this.selectedWork = data[0];
          this.workName = this.selectedWork["work_nm"] || "";
          //get Work Pages
          this.dataService
            .getPagesByWorkId(this.workKey, this.version)
            .subscribe(
              (res) => {
                this.pages = res || [];
                this.pages = _.sortBy(this.pages, "page_seq_no");
                for (let idx in this.pages) {
                  if (
                    this.pages[idx].page_config_details == "[]" ||
                    this.pages[idx].page_config_details == ""
                  ) {
                    this.pages[idx].page_config_details = [];
                  } else {
                    this.pages[idx].page_config_details = JSON.parse(
                      this.pages[idx].page_config_details
                    );
                  }
                }
              },
              (err) => {
                console.error(
                  "Work-display",
                  "get Page list - Work key:",
                  this.workKey,
                  err
                );
              }
            );
        });
    });
  }
}
