import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import moment from 'moment';
import { debounceTime, Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserActivityService } from 'src/app/services/useractivity.service';

@Component({
  selector: 'app-single-date',
  templateUrl: './single-date.component.html',
  styleUrls: ['./single-date.component.scss']
})
export class SingleDateComponent extends FieldType implements OnInit {

  dynamicSelect= new FormControl();
  opt: any
  valueProp = "_id";
  labelProp = "selectedDate";
  label:any;
  dataSourceUrl:any;
  parentKey:any="";
  placeholder:any;
  isRestriction:any;
  showBy:any;
  currentField:any;

  minDate!: Date;
  maxDate!: Date;
  selectedDate = new FormControl(new Date());

  cardName:any;
  dashboardKey:any;
  queryFilterData:any;

  private parentControlSubscription: Subscription | undefined;
  reqSubcription: Subscription[] = [];

  selectedType:any;
  tabId:any;
  
  constructor(
    private filterService:FilterService,
    private userActivityService:UserActivityService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private navigationService: NavigationService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.route.params.subscribe((p: any) => {
      this.route.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.tabId = params["tab_id"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.cardName = p["cardName"];

      });
    });

    this.currentField = this.field;
    this.opt = this.field.templateOptions || {};
    this.placeholder = this.opt.placeHolder;
    this.label = this.opt.label;
    this.dataSourceUrl = this.opt.dataSourceUrl;
    this.isRestriction = this.opt.isRestriction;
    this.showBy = this.opt.showBy;

    // Time Cycle From Widgets
    this.reqSubcription.push(this.filterService.kpiBreakDownSelectedType.subscribe((report_typ:any)=> {
      this.selectedType = report_typ;

      if(this.model[this.currentField.parentKey]) {
        this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
      }
    }));
    
    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
  
        if (this.parentControlSubscription) {
          this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
        }
  
        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];
  
          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }
  
          if(this.fndParent()) {
            if (this.dataSourceUrl == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              if(this.model[this.currentField.parentKey]) {
                this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model);
              }
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
    }
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  loadApiData(method:string, url:string,key:string,data:any) {
    
    if(this.selectedType) {
      this.reqSubcription.push(this.filterService.loadData(method,url,key,data, this.dashboardService.dashboardKey, this.selectedType, this.dashboardService.getPageKey[Number(this.tabId)]).subscribe((data:any)=>{
        
        
        this.minDate = new Date(data[0]?.from_dt);
        this.maxDate = new Date(data[0]?.to_dt);

        // Defalut Load for reset default
        sessionStorage.setItem('date-single-default-load', JSON.stringify({[this.key as string] : moment(data[0]?.to_dt).format('MM-DD-YYYY')}));

        // UserState
        let userStateData: any = this.userActivityService.getUserStateSingleDate();
        if(userStateData) {
          let dateUserState = new Date(userStateData[this.key as string])
          this.selectedDate.patchValue(dateUserState);
        } else {
          this.selectedDate.patchValue(this.maxDate);
        }

        let ApplyDate = {
          "from_date" : this.selectedDate.value,
          "to_date" : this.selectedDate.value,
        }
        // Set Value for Formly form
        this.field.formControl?.setValue(ApplyDate);
        
        // set Badges
        this.filterService.setBadgeValue(this.key as string, moment(this.selectedDate.value).format('MM/DD/YY'),"singleDateSelection", this.showBy)

      }, err=> {
        this.minDate = new Date();
        this.maxDate = new Date();
      }))
    }
  }

  changeDateSelection($event:any) {

    let ApplyDate = {
      "from_date" : this.selectedDate.value,
      "to_date" : this.selectedDate.value,
    }
    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);

    // User State
    let ab = { [this.key as string] : moment($event.value).format('MM-DD-YYYY')};
    this.userActivityService.setUserStateSingleDate(ab);

    // set Badges
    this.filterService.setBadgeValue(this.key as string, moment($event.value).format('MM/DD/YY'),"singleDateSelection", this.showBy)
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }
}
