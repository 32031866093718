import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-cluster-barchart-card',
  templateUrl: './cluster-barchart-card.component.html',
  styleUrls: ['./cluster-barchart-card.component.scss']
})
export class ClusterBarchartCardComponent implements OnInit, OnDestroy {
  static key = 100804;
  
  @Input('item') item:any
  @Input('pageKey') pageKey:any
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('clusterbarChartDiv',{static:true}) clusterbarChartDiv!:ElementRef
  
  config:any
  data:any
  heading:any
  isDataLoaded=false
  reqSubcription: Subscription[] = []
  constructor(
    private filterService: FilterService,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private newFilterService :NewFilterService
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query:any)=>{
      this.loadApiData(this.filterService.showByKey)
    })
    )
    this.reqSubcription.push(
      this.newFilterService.showBy.subscribe((report_type: any) => {
        this.loadApiData(report_type);
  
      }))
   }
  ngOnInit(): void {
    this.item

    const observer = new ResizeObserver((e:any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.clusterbarChartDiv.nativeElement);
    this.loadlineConfig()
  }

  loadlineConfig(){
    this.heading = this.item.config['report_heading']
//     this.config={
//       colours: {
//         Biologics: "#8C23E6",
//         Onco360: "#E223E6",
//         Amber: "#FFCD4A",
//         RxCrossroads: "#645DD7",
//         TEST1: "gold",
//         TEST2: "black",
//         TEST3: "purple",
//         TEST4: "orange"
//     },
//     period: "weekly",
//     xAxisLabel: this.item.config['x_axis']['api_resp_column_display_nm'],
//     yAxisLabel: this.item.config['y_axis']['api_resp_column_display_nm'],
//     xAxisVar: this.item.config['x_axis']['api_resp_column_display_nm'].toLowerCase(),
//     yAxisVar: this.item.config['y_axis']['api_resp_column_nm'],
//     value_column:this.item.config['value_column']['api_resp_column_nm'],
//     // xAxisLabel: "My X Axis Label",
//     // yAxisLabel: "My Y Axis Label",
//     // xAxisVar: "Period-Date",
//     // yAxisVar: "QuantityShipped",
//     displayType: "value",
// }
}


  loadApiData(report_type?:string) {
    // let api = this.item.config['list_of_api'] || {};
    let api :any={}
    api['api_key']='100133'
    
    if(api.api_key && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {
      let obj:any = {"api_key" : api.api_key}

      obj["report_typ"] = report_type ? report_type : this.filterService.defaultReport_type;
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      console.log( 'cluster chart',query["report_typ"])
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.isDataLoaded = true
          this.data = res == null ? [] : res
        }, (err: any) =>{
          this.data = [];
          console.log(err)
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res:any)=>{
      //   this.isDataLoaded = true
      //   this.data = res == null ? [] : res
      // },(error:any)=>{
      //   this.data = []
      //   // console.error( "Bar Chart   ",error)
      // }))
    }
}

ngOnDestroy(): void {
  this.reqSubcription.forEach(res=> res.unsubscribe())
}
}
