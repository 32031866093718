import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType} from '@ngx-formly/core';
import { DataService } from '../../../services/data.service';
@Component({
  selector: 'widget-hidden',
  template: `
    <input type="hidden"
        [formControl]="formInput"
        />
        [formlyAttributes]="field"
  `,
})
export class HiddenField extends FieldType  implements OnInit {
  constructor(
    private dataService:DataService
    ){
    super()
  }
 opt:any
 //default prop setting
 currentField:any
 parentKey:any=""
 onValueChangeUpdate :any 
 formInput  = new FormControl()


   ngOnInit(): void {
    this.opt = this.field.templateOptions || {};
    this.currentField = this.field
    let ds = this.to['dataSourceUrl']
    if (!ds) {
      return;
    }
    // let field:any = this.field
    if (this.currentField.parentKey! != "") {
      (this.field.hooks as any).afterViewInit = (f:any) => {
          const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
          
          parentControl?.valueChanges.subscribe((val:any) =>{
            this.dataService.getApiColumns(this.currentField['configKey'],this.opt.parameter,val[this.opt.parameter]).subscribe(data=>{
              this.field.formControl?.setValue(data)
              this.model[this.field.key as string] = data
            });
          })
        }
     } else {
        this.dataService.getApiColumns(this.currentField['configKey'],this.opt.parameter,this.model['widgetKey']).subscribe((data:any)=>{
          if (data != null) {
            this.field.formControl?.setValue(data)
          }
        })
    }
  }


  selectionChange(ctrl:any,inputObj:any){
    //  let obj = this.opt.options.find((o:any)=>o[this.valueProp] == ctrl.formControl.value)
    //  if (obj && this.onValueChangeUpdate) {
    //     ctrl.formControl.parent.controls[this.onValueChangeUpdate.key].setValue(obj[this.onValueChangeUpdate.labelProp])
    //     //ctrl.model[this.onValueChangeUpdate.key] = obj[this.onValueChangeUpdate.labelProp]
    // }
  }
}


