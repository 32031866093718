import { Injectable } from "@angular/core";
import moment from "moment";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class EnhancementService {

    currentQuarterFlag = false;
    
    // All page Information
    private allPageInfo = new BehaviorSubject<any>(null);
    allPageInformation = this.allPageInfo.asObservable();

    sendAllPageInfoData(data: any) {
        this.allPageInfo.next(data);
    }


    // Function to parse date strings to Date objects
    parseDate(dateStr: any) {
        return new Date(dateStr);
    }

    convertQuarter(date: any) {
        const dateLength = new Date(date).getMonth() + 1;
        let mathCeil = Math.ceil(dateLength);
        return 'Q' + (mathCeil / 3) + new Date(date).getFullYear();
    }

    findCurrentQuarter(dbQuarter:any, selectedQuarter:any) {

        // Format DB Data
        dbQuarter.qtr_start_dt = moment(dbQuarter?.qtr_start_dt).format('YYYY-MM-DD');
        dbQuarter.qtr_end_dt = moment(dbQuarter?.qtr_end_dt).format('YYYY-MM-DD');

        const dbStartDate = new Date(dbQuarter?.qtr_start_dt).getTime();
        const dbEndDate = new Date(dbQuarter?.qtr_end_dt).getTime();
        const selectedStartDate = new Date(selectedQuarter?.from_date).getTime();
        const selectedEndDate = new Date(selectedQuarter?.to_date).getTime();
        // debugger
        const isDbStartDateInRange = dbStartDate <= selectedStartDate && dbEndDate >= selectedEndDate;
        return isDbStartDateInRange
    }
}
