<div class="offcanvas offcanvas-end alterts_config offcanvass" tabindex="-1" id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Alerts</h5>
    <!-- <mat-icon class="text-end justify-content-end mr-3" matTooltip="Delete All" mat-button [matMenuTriggerFor]="menu"
      style="font-size: 15px !important;width: 15px;cursor: pointer;" [svgIcon]="trash" (click)="handleClick1()">
    </mat-icon>
    <mat-menu #menu="matMenu">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 alert_popup_delete">
            Are you sure you want to delete all alerts?
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <div class="col-6 text-start">
              <a class="text-start" style="color:#1363DF" mat-menu-item>No</a>
            </div>
            <div class="col-6 text-end justify-content-end">
              <a class="text-end" style="color:#D11044" mat-menu-item (click)="deleteAll()">Yes</a>
            </div>
          </div>
        </div>
      </div>
    </mat-menu> -->

  </div>
  <div class="btn-close-bg">
    <mat-icon class="mat-btn-close" data-bs-dismiss="offcanvas">clear</mat-icon>
  </div>
  <hr />
  <div class="offcanvas-body" id="alert-body">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item" *ngFor="let data of dataObj;let i=index"
        [style.border-left]="'6px solid ' + data.color">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button text-decoration-none alert_heading" type="button"
            [ngClass]="{ 'collapsed': i != 0 }" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#alerts'+i" aria-expanded="true" [attr.aria-controls]="'alerts'+i">
            {{data.heading}} : {{data.values?.length ? data.values?.length : 0 || data?.statusValues?.length ?
            data?.statusValues?.length : 0 }}
          </button>
          <hr />
        </h2>
        <div [id]="'alerts'+i" class="accordion-collapse collapse" [ngClass]="{ 'show': i == 0 }"
          [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample">
          <div *ngIf="data.heading != 'Status Change' && data.heading != 'Shipment Delayed'">

            <div class="accordion-body">

              <div class="row content_heading mb-2 mt-2">
                <div class="col-3 text-center">Patient ID</div>
                <div class="col-4 text-center">Prescriber Name</div>
                <div class="col-3 text-center">Date</div>
                <div class="col-1"></div>
              </div>
              <div class="row mb-2" *ngFor="let obj of data.values; let j =index">
                <div class="col-3 text-center">{{obj.id}}</div>
                <div class="col-4 text-center prescriber_name">{{obj.prescriber}}</div>
                <div class="col-4 text-center">{{obj.date}}</div>
                <div class="col-1">
                  <mat-icon matTooltip="Delete" mat-button
                    style="font-size: 15px !important;width: 15px;cursor: pointer;" [svgIcon]="trash"
                    (click)="DelDialog1('300ms', '150ms',obj,data)">
                  </mat-icon>
                  <!-- <mat-menu #menu="matMenu">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12 alert_popup_delete">
                          Are You Sure You Want To Delete?
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex">
                          <div class="col-6 text-start">
                            <a class="text-start" style="color:#1363DF" mat-menu-item>No</a>
                          </div>
                          <div class="col-6 text-end justify-content-end">
                            <a class="text-end" style="color:#D11044" mat-menu-item
                              (click)="updateAlertsNetwork(obj,data)">Yes</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-menu> -->
                  <ng-template #DeleteDialogAlert1>
                    <div>
                      <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                        <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete
                          Alert</span>
                        <button mat-icon-button class="close-button" [mat-dialog-close]="true"
                          style="margin-right: 1px;">
                          <mat-icon class="close-icon"
                            style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                        </button>
                      </div>
                      <div style="height: 40px; margin: 10px auto; text-align: center;">
                        Are you sure you want to delete?
                      </div>
                      <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                        <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                          <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                        <button mat-raised-button
                          style="background-color: white !important;color: #1363DF;border: 1px solid;" mat-dialog-close
                          cdkFocusInitial>Cancel</button>
                        <button mat-raised-button
                          style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;"
                          mat-dialog-close (click)="updateAlertsNetwork()">Delete</button>

                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="data.heading == 'Status Change'">

            <div class="accordion-body">

              <div class="mt-2" *ngFor="let update of data.statusValues; let idx =index">

                <div class="row text-start update_tooltip_body_id mb-2">{{update.id}}</div>
                <div class="row">
                  <div class="col-4 d-flex flex-column text-center">
                    <div class="">
                      <h3 class="update_tooltip_body_heading">From</h3>

                    </div>
                    <div class="row">
                      <div class="col-3"><mat-icon style="color: green;" [svgIcon]="status_tick">circle</mat-icon></div>
                      <div class="col-9 update_tooltip_body_from">{{update.from}}</div>
                    </div>

                  </div>
                  <div class="col-4 d-flex flex-column text-center">
                    <div class="">
                      <h3 class="update_tooltip_body_heading">To</h3>

                    </div>
                    <div class="row">
                      <div class="col-3"><mat-icon style="color: yellow;" [svgIcon]="status_pending">circle</mat-icon>
                      </div>
                      <div class="col-9 update_tooltip_body_to">{{update.to}}</div>
                    </div>

                  </div>
                  <div class="col-2 d-flex flex-column text-center">
                    <div class="">
                      <h3 class="update_tooltip_body_heading">Date</h3>

                    </div>
                    <div class="row">
                      <div class=" update_tooltip_body_date">{{update.date}}</div>
                    </div>

                  </div>
                  <div class="col-2 text-center">
                    <mat-icon matTooltip=" Delete" mat-button
                      style="font-size: 15px !important;width: 15px;cursor: pointer;" [svgIcon]="trash"
                      (click)="DelDialog('300ms', '150ms',update,data)">
                    </mat-icon>
                    <!-- <mat-menu #menu="matMenu">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-12 alert_popup_delete">
                            Are You Sure You Want To Delete?
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex">
                            <div class="col-6 text-start">
                              <a class="text-start" style="color:#1363DF" mat-menu-item>No</a>
                            </div>
                            <div class="col-6 text-end justify-content-end">
                              <a class="text-end" style="color:#D11044" mat-menu-item
                                (click)="updateAlertsNetwork(update,data)">Yes</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-menu> -->
                    <ng-template #DeleteDialogAlert>
                      <div>
                        <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                          <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete
                            Alert</span>
                          <button mat-icon-button class="close-button" [mat-dialog-close]="true"
                            style="margin-right: 1px;">
                            <mat-icon class="close-icon"
                              style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                          </button>
                        </div>
                        <div style="height: 40px; margin: 10px auto; text-align: center;">
                          Are you sure you want to delete?
                        </div>
                        <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                          <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                          <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                          <button mat-raised-button
                            style="background-color: white !important;color: #1363DF;border: 1px solid;"
                            mat-dialog-close cdkFocusInitial>Cancel</button>
                          <button mat-raised-button
                            style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;"
                            mat-dialog-close (click)="updateAlertsNetwork()">Delete</button>

                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <hr class="update_tooltip_hr mt-1 mb-1" />

              </div>
            </div>
          </div>
          <div *ngIf="data.heading == 'Shipment Delayed'">
            <div class="accordion-body">

              <div class="row content_heading mb-2 mt-2">
                <div class="col-5 text-start">Patient ID</div>
                <div class="col-5 text-start">Expected Next Fill Date</div>
                <div class="col-1"></div>
              </div>
              <div class="row mb-2" *ngFor="let obj of data.values; let j =index">
                <div class="col-5 text-start">{{obj.id}}</div>
                <div class="col-5 text-start">{{obj.date}}</div>
                <div class="col-1 text-end">
                  <mat-icon matTooltip="Delete" mat-button
                    style="font-size: 15px !important;width: 15px;cursor: pointer;" [svgIcon]="trash"
                    (click)="DelDialog1('300ms', '150ms',obj,data)">
                  </mat-icon>
                  <!-- <mat-menu #menu="matMenu">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12 alert_popup_delete">
                          Are You Sure You Want To Delete?
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex">
                          <div class="col-6 text-start">
                            <a class="text-start" style="color:#1363DF" mat-menu-item>No</a>
                          </div>
                          <div class="col-6 text-end justify-content-end">
                            <a class="text-end" style="color:#D11044" mat-menu-item
                              (click)="updateAlertsNetwork(obj,data)">Yes</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-menu> -->
                  <ng-template #DeleteDialogAlert1>
                    <div>
                      <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                        <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete
                          Alert</span>
                        <button mat-icon-button class="close-button" [mat-dialog-close]="true"
                          style="margin-right: 1px;">
                          <mat-icon class="close-icon"
                            style=" height: auto !important; width: auto !important; margin-top: -18px;">close</mat-icon>
                        </button>
                      </div>
                      <div style="height: 40px; margin: 10px auto; text-align: center;">
                        Are you sure you want to delete?
                      </div>
                      <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                        <!-- <span  mat-dialog-close cdkFocusInitial style="color: #1363DF;" >Cancel</span>
                          <span  mat-dialog-close (click)="deleteComment(message ,i)" style="color: red; margin-left: 5px;">Delete</span> -->
                        <button mat-raised-button
                          style="background-color: white !important;color: #1363DF;border: 1px solid;" mat-dialog-close
                          cdkFocusInitial>Cancel</button>
                        <button mat-raised-button
                          style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;"
                          mat-dialog-close (click)="updateAlertsNetwork()">Delete</button>

                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>