import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { HttpClient } from "@angular/common/http";
import { ApiService } from 'src/app/services/api.service';
import { CommonModule } from '@angular/common';
import { Venn, Legend, Tooltip } from '@dataunveil/ducharts';
import { ChartsModule } from '../../shared/charts/charts.module';
@Component({
  standalone: true,
  imports: [CommonModule, Venn, Legend, Tooltip , ChartsModule],
  selector: 'app-venn-chart-card',
  templateUrl: './venn-chart-card.component.html',
  styleUrls: ['./venn-chart-card.component.scss']
})
export class VennChartCardComponent implements OnInit, OnDestroy {
  static key = 100706;
  
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("vennChartDiv", { static: true }) vennChartDiv!: ElementRef;
  @ViewChild("fs") fs!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  heading: any;
  isActive = false;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  vennData:any;
  config:any;
  isLoading: boolean = false;
  noData: boolean = false;
  constructor(private filterService: FilterService,private httpClient: HttpClient, private apiService: ApiService) {

    // Filter loads
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
    this.isLoading = true

      })
    );

  }
  ngOnInit(): void {
    this.isLoading = true

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.vennChartDiv.nativeElement);
    this.loadlineConfig();
    this.configOfVenn();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData() {

    let api = this.item.config["list_of_api"] || {};
    // this.isDataLoaded = false;
    // this.httpClient.get("../../assets/fixtures/venn.json").subscribe((data:any) =>{
    //   this.vennData = data ? data : [];
    //   this.isDataLoaded = true;
    //   this.configOfVenn();
    // }, err=> {
    //   console.log('venn',err)
    //   this.vennData = [];
    //   this.configOfVenn();
    // })

    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payload
      let obj:any = {"api_key" : api}
      this.isDataLoaded = false;
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.vennData = res == null ? [] : res
          this.isDataLoaded = true
          this.isLoading = false
          if (res.length == 0) { this.noData = true } else { this.noData = false }
          this.configOfVenn();;          
        },(err:any)=>{
          console.log(err)
          this.vennData = []
          this.noData = true
          this.isLoading = false
          this.isDataLoaded = true;
          this.configOfVenn();;
        })
      )

   
    }
  }

 screenChanges(event:any){
  this.isActive = event
  this.isFullscreen = event
  this.config={}
  this.configOfVenn()
 }

  configOfVenn() {

    this.config = {
      title: this.heading,
      data: this.vennData,
      components: [{
        id: 'services-received-legend',
        type: 'Legend',
        data: {
          source: 'service',
        },
        view: {
          editable: true,
        },
      }, {
        id: 'services-received-venn',
        type: 'VennChart',
        data: {
          type: 'series',
        },
        view: {
          width : this.isFullscreen ? 700: 400,
          height:this.isFullscreen ? 700: 500,
          keyAccessor: 'service_type',
          valueAccessor: 'patient_id',
          colorScheme: ['#3941FF', '#E64028', '#E68328', '#BCD03F'],
          legend: 'services-received-legend',
          tooltip: 'services-received-tooltip',
        }
      }, {
        id: 'services-received-tooltip',
        type: 'Tooltip',
        data: {
          source: 'service',
        },
        view: {
          template: 'custom',
        },
      }],
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}