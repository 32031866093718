import { HttpClient } from '@angular/common/http'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import * as _ from 'lodash';
import { Subscription } from 'rxjs'
import { ApiService } from 'src/app/services/api.service'
import { FilterService } from 'src/app/services/filter.service'

@Component({
  selector: 'app-donut-chart-card',
  templateUrl: './donut-chart-card.component.html',
  styleUrls: ['./donut-chart-card.component.scss']
})
export class WidgetDonutChartCardComponent implements OnInit, OnDestroy {
  static key = 100103
  @Input('item') item: any = {}
  data: any[] = []
  //default api result
  donutWidth = 8
  heading = 'Processing Status'
  subHeading = ''
  headPercentage = '0'
  headRatio = ''
  loader: boolean = true
  reqSubcription: Subscription[] = []
  //variables
  chartData: any[] = []
  firstParam: any
  secondParam: any

  constructor (private filterService: FilterService, private httpClient: HttpClient, private apiService: ApiService) {
  }

  ngOnInit () {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData()
        this.loader = true
      })
    )
    this.loadChartConfig()
    this.loadApiData()
  }

  ngOnChanges (changes: any) {
    if (changes.item.currentValue != changes.item.previousValue) {
      this.loadChartConfig()
    }
  }

  loadChartConfig () {
    this.data = []
    let config: any[] = this.item.config['kpi_value_api_col']
    config?.map((c: any) => {
      //only selected column to be considered
      if (c['isSelected'] && c['isSelected'] == true) {
        this.data.push({
          id: c['api_column_list_key'],
          colName: c['api_resp_column_nm'],
          color: c['color'] || '#ddd',
          name: c['api_resp_column_display_nm'] || '',
          value: 0,
          showInchart: c['showInChart']
        })
      }
    })
  }

  loadApiData () {
    let kpi = this.item.config['kpi_value_api']
    if (kpi && kpi.api_key && !this.filterService.isEmptyObject(this.filterService.baseQuery)) {
      // API Payload
      let obj: any = { 'api_key': kpi.api_key }

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query['report_typ'] = this.filterService.report_type

      this.reqSubcription.push(
        this.httpClient.post(`${ this.apiService.apiBaseUrl }/${ this.apiService.executionUrl }`, query).subscribe((res: any) => {
          if (res && res.length > 0) {

            let data = res[0]
            //assign the value to the selected parameter
            this.data.map((e) => {
              e['value'] = data[e['colName']] || 0
            })
          } else {
            this.data.map((e) => {
              e['value'] = 0
            })
          }
          this.loader = false
          this.getChartData(this.data)

        }, (err: any) => {
          this.clearChartData()
          // console.log(err)
          this.loader = false
        })
      )
    }
  }

  clearChartData () {
    this.headRatio = ''
    this.headPercentage = ''
    this.data.map(a => a.value = 0)
  }

  getChartData (res: any[]) {
    this.firstParam = undefined
    this.secondParam = undefined
    //sort the array based on value
    if (res.length == 0) return
    res = _.sortBy(res, ['value'])
    this.chartData = []
    //find the max value
    const maxItem = _.maxBy(res, 'value')
    this.firstParam = maxItem
    this.subHeading = maxItem.name
    res.map((d: any) => {
      if (d.showInchart) {
        this.chartData.push([
          { data: d.value, color: d.color },
          { data: maxItem.value - d.value, color: 'white' }
        ])
        if ((maxItem.value - d.value) >= 0 && maxItem.name != d.name) {
          this.subHeading = d.name + ' vs ' + this.firstParam.name
          this.secondParam = d
        }
      }
    })

    if (this.firstParam && this.secondParam) {
      let percentagevalue = ((this.secondParam.value / ((this.firstParam.value == 0) ? 1 : this.firstParam.value)) * 100)
      this.headPercentage = (percentagevalue == 0) ? (percentagevalue.toFixed(0) + '%') : (percentagevalue.toFixed(1) + '%')
      this.headRatio = '(' + this.secondParam.value + '/' + this.firstParam.value + ')'
    } else {
      this.headPercentage = ''
      this.headRatio = ''
    }
  }

  getValue (item: any, value: any) {
    return item ? item[value] : ''
  }

  ngOnDestroy () {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }
}