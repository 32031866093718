<!-- // Prescriber Change // -->
<div class=" card prescriber_change_popup">
    <div class="container">
        <div class="row mt-3">
            <div class="col-10 text-startd d-flex flex-column">
                <div>

                    <img src="../../../../assets/Transaction.png" />
                    <span class="prescriber_heading">{{prescriberData?.heading}}</span>
                </div>
                <div>
                    <span class="date_prescriber ml-3">{{prescriberData.date}}</span>
                </div>
            </div>
            <hr class="heading_divider mt-2 mr-3" />
            <div class="mt-1 d-flex flex-column mb-2">
                <div class="date_prescriber">From</div>
                <div class="title_prescriber mt-1">{{prescriberData.from}}</div>
                <div class="date_prescriber mt-1">To</div>
                <div class="title_prescriber mt-1">{{prescriberData.to}}</div>
            </div>
        </div>
    </div>
</div>