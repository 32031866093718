import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
  } from '@angular/core'
  import { HttpClient } from '@angular/common/http'
  import { ActivatedRoute, Router } from '@angular/router'
  import { MatDialog } from '@angular/material/dialog'
  import { Subscription } from 'rxjs'
  import * as _ from 'lodash';
  
  import { ApiService } from 'src/app/services/api.service'
  import { FilterService } from 'src/app/services/filter.service'
  import { Action, WidgetService } from 'src/app/services/widget.service'
  import { DialogService } from 'src/app/services/dialog.service'
  import { DataService } from '../../services/data.service'
  import { ExcelExportService } from '../../services/excelExport.service'
  import { StateService } from '@dataunveil/ducharts'
  
//   import { UnderlyingDataComponent } from '../shared/underlying-data/underlying-data.component'
  import { WidgetConfig } from './widget.model'
import { EnhancementService } from 'src/app/services/enhancements-service'
import { MatMenuTrigger } from '@angular/material/menu'
import { SessionService } from 'src/app/services/session.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DashboardService } from 'src/app/services/dashboard.service'
import { DeviceDetectionService } from 'src/app/services/detectipad.service'
import { NavigationService } from 'src/app/services/navigation.service'
  
  @Component({
    standalone: true,
    template: '',
  })
  export default abstract class Widget implements OnInit, OnDestroy {
    static key: number
    protected el = inject(ElementRef)
    protected filterService = inject(FilterService)
    protected sessionService = inject(SessionService)
    protected enhancementService = inject(EnhancementService)
    protected apiService = inject(ApiService)
    protected widgetService = inject(WidgetService)
    protected dialogService = inject(DialogService)
    public dialogServiceMat = inject(MatDialog)
    protected chartService = inject(StateService)
    protected dataService = inject(DataService)
    protected excelService = inject(ExcelExportService)
    protected httpClient = inject(HttpClient)
    protected route = inject(ActivatedRoute)
    protected router = inject(Router)
    protected _snackBar = inject(MatSnackBar)
    protected dashboardService = inject(DashboardService)
    protected navigationService = inject(NavigationService)

    @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
    
    get id () {
      return WidgetService.getId(this.item)
    }
  
    get apiKey () {
      return WidgetService.getApiKey(this.item)
    }
  
    @Input('pageKey') pageKey: any
    config: any
    data: any
    isLoading = true
    @Input('item') item!: WidgetConfig
    @HostBinding('class.is-fullscreen') isFullscreen = false
    subs: Subscription[] = []
    widgetInfo: { info_header: string, info_text: string } | undefined
    size!: { width: number, height: number}
    @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  
    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange () {
      this.isFullscreen = !!document.fullscreenElement
    }
  
    ngOnInit () {
      this.subs.push(this.widgetService.get(this.id).subscribe((action: Action) => {
        const handler = this[action.name as keyof this]
        if (handler && _.isFunction(handler)) handler.bind(this)(action.state)
      }))
  
      const observer = new ResizeObserver((e: any) => {
        this.onSizeChange.emit()
        this.size = e[0].contentRect
      })
      observer.observe(this.el.nativeElement)
    }
  
    toggleFullscreen (_nextState: boolean) {
      const el = this.el.nativeElement
      const nextState = _.isNil(_nextState) ? !document.fullscreenElement : _nextState
      if (nextState) {
        if (el.requestFullscreen) el.requestFullscreen()
        else if (el.mozRequestFullScreen) el.mozRequestFullScreen()
        else if (el.msRequestFullscreen) el.msRequestFullscreen()
      } else document.exitFullscreen()
    }
  
    getInfoDetail () {
      const query = {
        page_key: this.pageKey,
        widget_key: (<typeof Widget>this.constructor).key,
        api_key: this.apiKey,
        brand_key: this.filterService.baseQuery?.brand_key
      }
  
      this.dataService.getInfoDetail(query).subscribe((res: any) => {
        this.widgetInfo = res?.[0] || { info_header: 'No Info Data Found' }
      })
    }
  
    showUnderlyingData () {
    //   this.dialogServiceMat.open(UnderlyingDataComponent, {
    //     data: {
    //       data: this.data,
    //       item: this.item,
    //       columnDefs: this.config.pivotGrid.columnDefs,
    //       dateFormat: this.config.pivotGrid.dateFormat,
    //       pivot: this.config.pivotGrid.pivot,
    //     },
    //     width: '80%',
    //     disableClose: true,
    //   })
    }
  
    excelExport () {
    //   this.excelService.save(this.data, this.item.config.report_heading, this.config.excelExport.columnDefs)
    }
  
    ngOnDestroy () {
      this.subs.forEach(sub => sub.unsubscribe())
    }
  }