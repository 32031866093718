import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';
import { PivotGridComponent } from '../../shared/charts/pivot-Grid/pivot-grid.component';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-ir-heat-map-card',
  templateUrl: './ir-heat-map-card.component.html',
  styleUrls: ['./ir-heat-map-card.component.scss']
})
export class IRHeatMapCardComponent implements OnInit , OnDestroy{
  static key = 100806;
  
  @ViewChild("IrHeatmapDiv", { static: true }) IrHeatmapDiv!: ElementRef;
  @ViewChild('PivotGrid', { static: false }) pivotGrid!: PivotGridComponent;
  @Input("data") data: any;
  @Input("pageKey") pageKey: any;
  @Input("item") item: any;
  @ViewChild("fs") fs!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive: boolean = false;
  isLoading: boolean = true;
  noData: boolean = false;
  isDataLoaded: boolean = false;
  reqSubcription: Subscription[] = [];
  columnDefs: any = [];
  gridData: any = [];
  gridConfig: any = {}
  pivot: boolean = false;
  config: any={ };
  selectedValue='percent';
  dateFormat: boolean = false;
  headerName:string =''
  kpitimecycle = [
    {
      "value": "D",
      "format": "DD MMM YYYY",
      "label": "Daily"
    },
    {
      "value": "W",
      "format": "DD MMM YYYY",
      "label": "Weekly"
    },
    {
      "value": "M",
      "format": "MMM YYYY",
      "label": "Monthly"
    },
    {
      "value": "Q",
      "format": "MMM YYYY",
      "label": "Quarterly"
    }
    ,
    {
      "value": "Y",
      "format": "YYYY",
      "label": "Yearly"
    }
  ]
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.isFullscreen = false;
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      this.isActive = false;
    }
  }
  

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  constructor(private filterService: FilterService,
    private httpClient: HttpClient,
    private apiService: ApiService, private currency:CurrencyPipe,private newFilterService :NewFilterService) {
      this.filterService.changeDisplays.subscribe((res: any) => {
        this.changeDisplayType(res)
      })
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.isLoading=true
        this.loadApiData(this.filterService.showByKey);
      })
    );
    this.reqSubcription.push(
      this.newFilterService.showBy.subscribe((reoprt_type:any) => {
        this.isLoading = true
        this.loadApiData(reoprt_type);
      }))
  }

  ngOnInit(): void {
    this.selectedValue=this.item.config?.widget_header_config?.find((fnd: any) => fnd.value == "selection_toggle").toggle_btn[0].value
    this.item;
this.headerName=this.item.config['report_heading']
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.IrHeatmapDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {

    this.config['value'] = {
      XaxisVar: 'period',
      YaxisVar: 'source_name',
      valueVar: "qty_difference"
    }
    
    this.config['percent'] = {
      XaxisVar: 'period',
      YaxisVar: 'source_name',
      valueVar: "pct_difference"
    }
  }

  loadApiData(reoprt_type?: string) {
    let api = this.item.config["list_of_api"] || {};

    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      let obj: any = { "api_key": 100134 }// api.api_key }

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = reoprt_type?reoprt_type:this.filterService.report_type;
      console.log('IR-Heatmap',query["report_typ"]);

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          this.isDataLoaded = true;
          this.isLoading=false
          this.data = (res == null || res == undefined) ? [] : res;
          if(this.data.length==0){this.noData=true}else {this.noData=false}
        }, (err: any) => {
          this.isLoading=false
          this.data = [];
          this.noData=true
          console.log(err)
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
      //   this.isDataLoaded = true;
      //   this.data = (res == null || res == undefined ) ? [] : res;
      // }, (error: any) => {
      //   this.data = [];
      //   console.log(error)
      // }));
    }
  }
  getgridData(value: any) {
    // debugger
    this.gridData = []
    this.gridConfig = value
   let dateFormat:any= this.kpitimecycle?.find(val=> val.value==this.filterService.report_type)?.['format']
    if (value.pivot) {
      this.columnDefs = [
        { headerName: 'Date ', field: "period", cellStyle: { "text-align": "center" }, sort: 'desc' , valueFormatter: (params: any) => moment(params.value).format(dateFormat), },
        { headerName: 'SP Name', field: "source_name", cellStyle: { "text-align": "center" },sort: 'asc' },
        { headerName: 'Dispense from Inventory', field: "inventory_qty", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value)  },
        { headerName: 'Dispense from Shipment', field: "dispense_qty", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value)  },
        { headerName: 'Difference', field: "qty_difference", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value)  },
        { headerName: 'Inventory on Hand', field: "inventory_on_hand", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value) }
      ];
    } else {  
      this.columnDefs = [
        { headerName: 'SP Name', field: "source_name", cellStyle: { "text-align": "center" },sort: 'asc' },
        { headerName: 'Dispense from Inventory', field: "inventory_qty", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value) },
        { headerName: 'Dispense from Shipment', field: "dispense_qty", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value) },
        { headerName: 'Difference', field: "qty_difference", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value) },
        { headerName: 'Inventory on Hand', field: "inventory_on_hand", cellStyle: { "text-align": "center" },valueFormatter: (params: any) =>this.numbedPipe(params.value) }
      ];
    }
    this.pivot = false
    this.gridData = value.data
    this.isDataLoaded = true

  }
  fullscreenChanges(fullscreen:any){
      this.isFullscreen = fullscreen;
      this.isActive = fullscreen;
 }
 changeDisplayType(value:any){
   this.selectedValue=value

 }
 numbedPipe(value: any) {
  return this.currency.transform(value, "", "", "1.0-2");
  //  return   this.numberpipe.transform(value)
}
exportXls(){
  this.pivotGrid.exportXls(this.headerName)
}
ngOnDestroy(): void {
  this.reqSubcription.forEach(res=> res.unsubscribe())
}
}
