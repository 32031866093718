import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injectable,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  SideBarDef,
} from "ag-grid-community";
import { Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";
import { NavigationService } from "src/app/services/navigation.service";
import { NewFilterService } from "src/app/services/new-filter.service";

@Component({
  selector: "app-status-codes",
  templateUrl: "./status-codes.component.html",
  styleUrls: ["./status-codes.component.scss"],
})
@Injectable({
  providedIn: 'root'
})
export class StatusCodesComponent implements OnInit {
  static key = 100800;

  @Input("item") item: any;
  @Input("heading") heading: string = "";
  @ViewChild("StatusCodeContainer", { read: ElementRef }) sample!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  @ViewChild("fs") fs!: ElementRef;
  isActive: boolean = false;
  @HostListener("fullscreenchange", ["$event"])
  @HostListener("webkitfullscreenchange", ["$event"])
  @HostListener("mozfullscreenchange", ["$event"])
  @HostListener("MSFullscreenChange", ["$event"])
  
  // widgets
  widgets: any = [];
  @Input("pageKey") pageKey: any;
  iconList: any;
  statusCode: any = [];
  employeeKey: any;
  clientKey: any;
  searchText: any = ''
  private gridColumnApi!: ColumnApi;
  public gridApi!: GridApi;
  public columnDefs: ColDef[] = []
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
    cellClass: "cell-wrap-text"
  };
  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';
  public autoGroupColumnDef: ColDef = {
    headerName: 'Status -> Status Reason Code',
    minWidth: 400,
    sortable: true,
    pinned: 'left',

    cellRendererParams: {
      suppressCount: true,
    },

  };
  public rowData: any = [];
  reqSubcription: Subscription[] = [];
  api: any;
  apiDataStatus: any = [];
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("StatusCodeDiv", { static: true }) StatusCodeDiv!: ElementRef;
  
  // Full Screen Functionality

  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false;
    }
  }

  closeFullscreen(): void {
    this.isFullscreen = false;

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.isActive = false;
    setTimeout(() => {
      //  this.plotChart()
    }, 100);
  }

  constructor(
    private filterServiceNew: NewFilterService,
    private router: ActivatedRoute,
    private http: HttpClient,
    private dataService: DataService,
    private filterServiceOld: FilterService,
    private navigationService: NavigationService

  ) {
    // this.router.params.subscribe((r: any) => {
    //   this.clientKey = r["clientKey"];
    // });

    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })

    this.filterServiceOld.onQuickFilter.subscribe((res: any) => {
      this.onSearchChange(res)
    })
  }
  @ViewChild('statusGrid') statusGrid!: AgGridAngular;

  ngOnChanges(changes: SimpleChanges): void {
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        this.isFullscreen = true
        var element = document.querySelector(".ag-root-wrapper")?.setAttribute('style', 'height: 85vh')
      } else {
        this.isFullscreen = false
        var element = document.querySelector(".ag-root-wrapper")?.setAttribute('style', 'height: 63vh')
      }
    })
  }

  ngOnInit(): void {
    
    // Auto Size from Element
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.StatusCodeDiv?.nativeElement);

    this.widgets = this.item ? this.item : "";
    this.iconList = this.widgets?.config?.icon;
    this.reqSubcription.push(this.filterServiceOld.filterQuery.subscribe((query: any) => {
      this.getStatusCodeData();
      setTimeout(() => {
        if (this.item.config.selectDefaultView.controls == 'yes') {
          this.dataService.expandCollapseChart.next(true)
          this.api?.expandAll()
        } else {
          this.dataService.expandCollapseChart.next(false)
          this.api?.collapseAll()
        }
      }, 2000)
    }))


    this.reqSubcription.push(this.dataService.expandCollapseChart.subscribe((resp: boolean) => {
      if (resp) {
        this.api?.expandAll()
      } else {
        this.api?.collapseAll()
      }
    }))

    this.filterServiceOld.onQuickFilter.subscribe((resp: any) => {
      this.onSearchChange(resp)
    })
  }

  getStatusCodeData() {
    const obj = {
      api_key: 100180,
      report_typ: "D",
      client_key: this.clientKey || "",
    };
    if(this.filterServiceOld.isEmptyObject(this.filterServiceOld.baseQuery) == false) {
      this.reqSubcription.push(this.filterServiceOld.executePatientQuery(obj).subscribe((resp: any) => {
        if (resp) {
            this.apiDataStatus = resp;
            this.statusCode = resp;
          }
          if (this.statusCode) {
            this.getMaxAndMinValues(this.statusCode)
          }
      }));
    }
  }

  getMaxAndMinValues(data: any[]) {
    const NewArray: any = []
    this.item.config.color_coading.controls.map((i: any) => {
      let item = this.statusCode.filter((j: any) => i.groupName == j.status_code)
      // let maxObject = item.reduce(function (prev: any, current: any) {
      //   return (prev?.status_code_usage_count > current?.status_code_usage_count) ? prev : current;
      // });
      // let minObject = item.reduce(function (prev: any, current: any) {
      //   return (prev?.status_code_usage_count < current?.status_code_usage_count) ? prev : current;
      // });
      // maxObject['maxValue'] = 'true';
      // minObject['minValue'] = 'true';
      // NewArray.push(maxObject, minObject)

      const max = item.reduce((acc: any, curr: any) => {
        if (acc === null || curr.status_code_usage_count > acc.status_code_usage_count) {
          return { status_code_usage_count: curr.status_code_usage_count, objs: [curr] };
        } else if (curr.status_code_usage_count === acc.status_code_usage_count) {
          acc.objs.push(curr);
        }
        return acc;
      }, null);
      const min = item.reduce((acc: any, curr: any) => {
        if (acc === null || curr.status_code_usage_count < acc.status_code_usage_count) {
          return { status_code_usage_count: curr.status_code_usage_count, objs: [curr] };
        } else if (curr.status_code_usage_count === acc.status_code_usage_count) {
          acc.objs.push(curr);
        }
        return acc;
      }, null);
      min?.objs?.map((i: any) => {
        i['minValue'] = 'true';
        NewArray.push(i)
      })
      max?.objs?.map((i: any) => {
        i['maxValue'] = 'true';
        NewArray.push(i)
      })
    })
    this.statusCode.map((i: any) => {
      const matchingObj = NewArray.find((newObj: any) => (newObj?.status_code_usage_count == i?.status_code_usage_count) && (newObj?.status_code == i?.status_code) && ((newObj?.source_name == i?.source_name)));
      return matchingObj ? matchingObj : i;
    })
    this.mergeObjectsInUnique(this.statusCode)
  }

  // Includ Total In Source Name and Map The Result
  mergeObjectsInUnique(data: any[]) {
    let result = []
    result = Object.values(data.reduce((c, { status_reason_code, status_code_usage_count, status_code }) => {
      c[status_reason_code] = c[status_reason_code] || { status_reason_code, count: [], status_code };
      c[status_reason_code].count = c[status_reason_code].count.concat(Array.isArray(status_code_usage_count) ? status_code_usage_count : [status_code_usage_count]);
      c[status_reason_code].status_code = c[status_reason_code].status_code;
      return c;
    }, {}));

    let array: any = []
    if (result) {
      result.forEach((i: any) => {
        if (i) {
          array.push({
            source_name: 'Total',
            status_code: i.status_code,
            status_reason_code: i.status_reason_code,
            status_code_usage_count: i.count.reduce(function (previousValue: any, currentValue: any) { return previousValue + currentValue })
          });
        }
      })
    }

    const NewArray: any = []
    this.item.config.color_coading.controls.map((i: any) => {
      let item = array.filter((j: any) => i.groupName == j.status_code)
      // let maxObject = item.reduce(function (prev: any, current: any) {
      //   return (prev?.status_code_usage_count > current?.status_code_usage_count) ? prev : current;
      // });
      // let minObject = item.reduce(function (prev: any, current: any) {
      //   return (prev?.status_code_usage_count < current?.status_code_usage_count) ? prev : current;
      // });
      // maxObject['maxValue'] = 'true';
      // minObject['minValue'] = 'true';
      // NewArray.push(maxObject, minObject)
      const max = item.reduce((acc: any, curr: any) => {
        if (acc === null || curr.status_code_usage_count > acc.status_code_usage_count) {
          return { status_code_usage_count: curr.status_code_usage_count, objs: [curr] };
        } else if (curr.status_code_usage_count === acc.status_code_usage_count) {
          acc.objs.push(curr);
        }
        return acc;
      }, null);
      const min = item.reduce((acc: any, curr: any) => {
        if (acc === null || curr.status_code_usage_count < acc.status_code_usage_count) {
          return { status_code_usage_count: curr.status_code_usage_count, objs: [curr] };
        } else if (curr.status_code_usage_count === acc.status_code_usage_count) {
          acc.objs.push(curr);
        }
        return acc;
      }, null);
      min?.objs?.map((i: any) => {
        i['minValue'] = 'true';
        NewArray.push(i)
      })
      max?.objs?.map((i: any) => {
        i['maxValue'] = 'true';
        NewArray.push(i)
      })
    })
    array.map((i: any) => {
      const matchingObj = NewArray.find((newObj: any) => (newObj?.status_code_usage_count == i?.status_code_usage_count) && (newObj?.status_code == i?.status_code) && ((newObj?.source_name == i?.source_name)));
      return matchingObj ? matchingObj : i;
    })

    array.forEach((j: any) => {
      this.statusCode.push(j)
    })


    this.onBtPivotMode();

  }

  exportXls() {
    this.gridApi.exportDataAsExcel({ fileName: 'Status Code Usage' });
  }

  onBtPivotMode() {
    this.gridColumnApi.setPivotMode(true);
    this.columnDefs = [
      { field: "status_code", headerName: 'Status Code', rowGroup: true, enableRowGroup: true },
      {
        field: "status_reason_code", headerName: 'Status Reason Code', rowGroup: true, enableRowGroup: true, enablePivot: true,
        cellClass: 'cell-wrap-text', width: 500,
        cellRenderer: function (params: any) {
          const sourceName = params.value;
          const description = params.node.allLeafChildren[0]?.data?.status_reason_description
          // return `<div style="position:relative;">${sourceName}<div class="tooltip" title="${description}">?</div></div>`;
          // return `<div>${sourceName}<span style="font-size:9px;font-weight:600;"> - </span><span style="font-size:10px;font-weight:500;">${description}</span></div>`;
          return `<div class="container-fluid">
            <div class="row">
              <div class="col-12 d-flex">
                <div class="" style="font-size:12px;">${sourceName}-</div>
                <div class="pl-1" style="font-size:9px;font-weight:500;padding-top:2px">${description}</div>
              </div>
            </div>
          </div>
        </div>`
        },
      },
      { field: "status_reason_description" },
      { field: "total" },
      { field: "source_name", headerName: 'Source Name', pivot: true, sortable: true, filter: false },
      {
        field: "status_code_usage_count",
        headerName: 'Count of Transactions',
        aggFunc: 'sum',
        suppressMovable: true,
        valueFormatter: (params: any) => {
          return params.value !== null && params.value !== undefined && params.value !== '' ? params.value : '-';
        },
        cellStyle: (params: any) => {
          let item = params.node.key
          let configItem = this.item.config.color_coading.controls.find((i: any) => i?.groupName == item);
          if (configItem) {
            if (this.item.config?.approvalType?.controls == 'yes') {
              return { display: 'block', color: 'black', borderRadius: 'none', background: 'none', height: 'inherit', alignItems: 'center', textAlign: 'center' }
            } else if (this.item.config.approvalType.controls == 'no') {
              return { display: 'none', color: 'black', borderRadius: 'none', background: 'none', height: 'inherit', alignItems: 'center', textAlign: 'center' }
            } else {
              return { display: 'block', color: 'black', borderRadius: 'none', background: 'none', height: 'inherit', alignItems: 'center', textAlign: 'center' }
            }
          } else {
            let configItem = this.item.config?.color_coading?.controls.find((i: any) => i?.groupName == params.node?.parent?.key);
            let item = this.statusCode?.find((i: any) => (params?.value == i?.status_code_usage_count) && (params?.node.key == i?.status_reason_code) && (params?.colDef?.pivotKeys[0] == i?.source_name))
            {
              // if(item?.status_code_usage_count > 300 && item?.status_code_usage_count <1000)
              // {
              //   return { color: 'green', display: 'flex', borderRadius: '10px', background: 'none', height: '24px', alignItems: 'center', textAlign: 'center', }
              // } else {
              //   return { color: 'black', display: 'flex', borderRadius: '10px', background: 'none', height: '24px', alignItems: 'center', textAlign: 'center' }
              // }
              if (item?.maxValue) {
                return { color: configItem?.max_color, display: 'flex', borderRadius: '10px', background: 'none', height: 'inherit', alignItems: 'center', textAlign: 'center', }
              }
              if (item?.minValue) {
                return { color: configItem?.min_color, display: 'flex', borderRadius: '10px', background: 'none', height: 'inherit', alignItems: 'center', textAlign: 'center' }
              }
            }

          }
        }
      },
    ];
    this.rowData = this.statusCode
  }

  onFirstDataRendered(params: any) {
    if (this.item.config.selectDefaultView.controls == 'yes') {
      params.api.forEachNode((node: any) => {
        if (node.group && !node.expanded) {
          node.setExpanded(true);
          this.onBtPivotMode()
        }
      });
      this.dataService.expandCollapseChart.next(true)
    } else {
      this.dataService.expandCollapseChart.next(false)
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.api = params.api
    if (this.item.config.selectDefaultView.controls == 'yes') {
      this.api?.expandAll()
      this.dataService.expandCollapseChart.next(true)
    } else {
      this.dataService.expandCollapseChart.next(false)
    }
  }

  onSearchChange(event: any) {
    this.searchText = event
    this.gridApi.setQuickFilter(this.searchText);
    this.gridApi.setFilterModel({
      status_code: { type: 'text', filter: this.searchText },
      status_reason_code: { type: 'text', filter: this.searchText },
      status_reason_description: { type: 'text', filter: this.searchText },
      total: { type: 'text', filter: this.searchText },
      status_code_usage_count: { type: 'text', filter: this.searchText }
    });
  }

  ngOnDestroy() {
    this.reqSubcription.forEach(res => res.unsubscribe())
    this.dataService.expandCollapseChart.next(false)
    this.filterServiceOld.onQuickFilter.next('')
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

}
