import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AlertSubscriptionService } from '../services/alert-subscription.service';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import * as _ from 'lodash';;
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-alert-subscription',
  templateUrl: './alert-subscription.component.html',
  styleUrls: ['./alert-subscription.component.scss']
})
export class AlertSubscriptionComponent implements OnInit, OnDestroy {

  static key = 100901;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();

  @ViewChild("alertSubscriptions", { static: true }) alertSubscriptions!: ElementRef;
  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;


  @HostBinding("class.is-fullscreen") isFullscreen = false;


  reqSubcription: Subscription[] = [];

  isActive = false;
  alertKeyValue: any;
  kpiLeftLists: any = [];
  kpiRightLists: any = [];

  currentlyOpenedItemIndex = -1;
  currentlyOpenedItemRightIndex = -1;

  inAppAlerts = false;
  inEmailAlerts = false;

  model: any;;
  fields: any;
  options: any;
  form: any;
  clientKey: any;

  currentSelection: any;
  alertDescription: any;

  loader = false;
  cancelSaveChanges = false;
  removeSubs = false;
  validationForCancel =false;

  constructor(
    private alertSubscriptionService: AlertSubscriptionService,
    private apiService: ApiService,
    private filterService: FilterService,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private snackBar: MatSnackBar,
  ) {


    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      // Load Data For ALerts
      this.loader = false;
      this.apiService.getDoc('kpi-alert-fields').subscribe((res: any) => {
        this.fields = res ? res : [];
        this.loadFields(this.fields);
      })

    }))

    this.router.params.subscribe((p: any) => {
      this.router.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })
  }

  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.alertSubscriptions?.nativeElement);

    this.alertKeyValue = this.item.config?.alert_name_key;
    // this.apiService.getDoc('kpi-alert-fields').subscribe((res: any) => {
    //   this.fields = res ? res : [];
    // })
  }

  async loadFields(fields: any) {
    for (let index = 0; index < fields?.length; index++) {
      const element = fields[index];
      let opt = element.templateOptions;
      if (opt.dataSourceUrl) {
        await this.getDataForAllFields(opt.method, opt.dataSourceUrl, opt.parameter, element.key);
      } else if (opt.localJson) {
        await this.getLocalDataForAllFields(opt.localJson, element.key);
      }
    }

    // Load Data For ALerts
    this.loadApiData();

    // console.log(this.alertSubscriptionService.allOnLoadDataForFields);
  }

  async getDataForAllFields(method: string, endPoint: string, parameter: string, key: string) {
    return new Promise((resolve, reject) => {
      this.alertSubscriptionService.getFilterFieldsData(method, endPoint, parameter, key).subscribe(
        (res: any) => {
          if (res) {
            this.alertSubscriptionService.allOnLoadDataForFields[key] = res;
          }
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async getLocalDataForAllFields(endPoint: string, key: string) {
    return new Promise((resolve, reject) => {
      this.alertSubscriptionService.getFilterFieldsLocalData(endPoint).subscribe(
        (res: any) => {
          if (res) {
            this.alertSubscriptionService.allOnLoadDataForFields[key] = res;
          }
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  setOpened(itemIndex: number) {
    // debugger
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex: number) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  setRightOpened(itemIndex: number) {
    this.currentlyOpenedItemRightIndex = itemIndex;
  }

  setRightClosed(itemIndex: number) {
    if (this.currentlyOpenedItemRightIndex === itemIndex) {
      this.currentlyOpenedItemRightIndex = -1;
    }
  }

  screenChanges(event: any) {
    this.isActive = event
    this.isFullscreen = event
  }

  onCheckboxChange($event:any) {
    this.validationForCancel = false;
    this.cancelSaveChanges = false;
  }

  loadApiData() {

    this.cancelSaveChanges = true;
    this.validationForCancel = true;

    const obj = {
      api_key: 100310,
      alert_type: this.alertKeyValue,
      report_typ: "D"
    }

    this.reqSubcription.push(this.alertSubscriptionService.executeQuery(obj).subscribe((res: any) => {
      const kpiLists = res ? res : [];
      this.getSavedSubscription(kpiLists);
    }, error => {
      this.loader = true;
    }))
  }

  groupObjectsBy(array: any[], keys: string[]): { [key: string]: any[] } {
    return array.reduce((result, currentValue) => {
      const groupKey = keys.map(key => currentValue[key]).join('-');
      (result[groupKey] = result[groupKey] || []).push(currentValue);
      return result;
    }, {});
  }

  getSavedSubscription(kpiLists: any) {

    this.reqSubcription.push(
      this.alertSubscriptionService.getSavedSubscription({ alert_type: this.alertKeyValue }).subscribe((res: any) => {

        let resData = res ? res : [];

        let groupedStateObjects = this.groupObjectsBy(resData, ['alert_key']);

        kpiLists.map((m: any) => {
          m['selected_fields'] = this.fields.filter((f: any) => m[f.templateOptions.flag_nm] != 'N');
          m['selected_data'] = [];
          m['selected_option'] = [];

          if (groupedStateObjects[m.alert_key]) {

            const data = groupedStateObjects[m.alert_key];

            let groupData = this.groupDataByTimeCycle(data, true);

            let keys: any = [];
            m['selected_fields'].forEach((m: any) => {
              let key = m.templateOptions.response_key ? m.templateOptions.response_key : m.key;
              keys.push(key);
            })

            let grouped: any;

            if (m.time_cycle_flg == 'Y') {
              groupData.map((m: any) => m.time_cycle_dim_key?.toString());
              grouped = this.groupBySpecificKeys(groupData, ['time_cycle_dim_key']);
            } else {
              groupData.map((m: any) => m.time_cycle_dim_key = null);
              grouped = this.groupBySpecificKeys(groupData, keys);
            }
            // console.log('grouped',grouped)

            // Reduce the Array of object
            let group = groupData.reduce((acc, item) => {
              acc[item.time_cycle_dim_key] = acc[item.time_cycle_dim_key] || [];
              acc[item.time_cycle_dim_key].push(item);
              return acc;
            }, {});

            let brakeObj = Object.entries(group).map(([key, value]) => ({ key, value }));

            for (let index = 0; index < brakeObj.length; index++) {
              const elValue: any = brakeObj[index];

              let selectedData:any = {};
              let objOption:any = {};

              let objF: any = {
                in_app_subscription_flg: elValue.value[0].in_app_subscription_flg == '1' ? true : false,
                email_subscription_flg: elValue.value[0].email_subscription_flg == '1' ? true : false
              }

              m['selected_fields'].forEach((res: any) => {
                
                elValue.value.map((map: any) => {
                  let key = res.templateOptions.response_key ? res.templateOptions.response_key : res.key;
                  if (map[key] != '') {
                    if(!selectedData[res.key]) {
                      selectedData[res.key] = [map[key]];
                    } else {
                      selectedData[res.key].push(map[key]);
                    }
                  }
                })

                if (res.key === 'time_cycle_key') {
                  let spltTime: any[] = [];
                
                  // Split the values by comma and concatenate them into spltTime
                  selectedData['time_cycle_key'].forEach((f: any) => {
                    let newSpl = f.split(',');
                    spltTime = spltTime.concat(newSpl);
                  });
                
                  // Remove duplicates from the split values
                  selectedData['time_cycle_key'] = [...new Set(spltTime)];
                } else {
                  // Remove duplicates from each array in selectedData
                  for (const key in selectedData) {
                    if (selectedData.hasOwnProperty(key)) {
                      selectedData[key] = [...new Set(selectedData[key])];
                    }
                  }
                }

                let option = this.alertSubscriptionService.allOnLoadDataForFields[res.key];
                let selectedOption = option.filter((f: any) => selectedData[res.key].find((o: any) => o == f[res.templateOptions.valueProp]));
                
                if ((res.type == 'alert-multi-select') || (res.type == 'alert-tree-select')) {
                  objF[res.key] = selectedOption;
                } else {
                  objF[res.key] = selectedOption[0];
                }

                if(res?.parentKey) {                  
                  let allOption = this.alertSubscriptionService.allOnLoadDataForFields[res.key];

                  let fndChild:any = this.fields.find((rs:any)=> rs.key == res.parentKey);
                  
                  let keyC = fndChild.templateOptions.response_key ? fndChild.templateOptions.response_key : fndChild.key;

                  if (selectedOption instanceof Object && !Array.isArray(selectedOption)) {
                    objOption[res.key] = allOption.filter((f:any)=> f[keyC] == selectedOption[keyC])
                  } else if (Array.isArray(selectedOption)) {
                    objOption[res.key] = allOption.filter((f:any)=> selectedOption.find((o:any)=> f[keyC] == o[keyC])) 
                  }
                } else {
                  objOption[res.key] = this.alertSubscriptionService.allOnLoadDataForFields[res.key]
                }

              });

              m.selected_data.push(objF);
              m['selected_option'].push(objOption);
            }

          }
        })

        this.wrapperFields(kpiLists);
        this.loader = true;
      }, error => {
        this.loader = true;
      }))
  }

  wrapperFields(kpiLists: any) {
    const middleIndex = Math.ceil(kpiLists.length / 2);
    this.kpiLeftLists = kpiLists.slice(0, middleIndex);
    this.kpiRightLists = kpiLists.slice(middleIndex);
    this.alertSubscriptionService.kpiLeftLists = this.kpiLeftLists;
    this.alertSubscriptionService.kpiRightLists = this.kpiRightLists;
  }

  addSubsription(kpi: any, idx: any) {
    let obj: any = {
      in_app_subscription_flg: true,
      email_subscription_flg: false
    }
    
    let objOption:any = {};

    kpi['selected_fields'].forEach((res: any) => {
      if (res.type == 'alert-multi-select') {
        obj[res.key] = [];
      } else {
        obj[res.key] = null
      }
      objOption[res.key] = this.alertSubscriptionService.allOnLoadDataForFields[res.key];
    })
    
    kpi.selected_data.push(obj);
    kpi['selected_option'].push(objOption);

    this.cancelSaveChanges = false;
    this.validationForCancel = false;
  }
  
  isButtonClicked = false;

  removeLeftSubscription(idx: any, di: any) {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;

      this.kpiLeftLists[idx].selected_data.splice(di, 1);
      this.alertSubscriptionService.kpiLeftLists[idx].selected_data.splice(di, 1);
      this.kpiLeftLists[idx].selected_option.splice(di, 1);
      this.removeSubs = true;
      this.cancelSaveChanges = false;
      this.validationForCancel = false;
      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }

  removeRightSubscription(idx: any, di: any) {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;

      this.kpiRightLists[idx].selected_data.splice(di, 1);
      this.alertSubscriptionService.kpiRightLists[idx].selected_data.splice(di, 1);
      this.removeSubs = true;
      this.cancelSaveChanges = false;
      this.validationForCancel = false;
      this.kpiRightLists[idx].selected_option.splice(di, 1);
  
      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }

  valueChanges:any;
  valueChangesConti = false;

  emitLeftSelectedValue(event: any, idx: any, di: any, fi:any) {
    // debugger
    this.kpiLeftLists[idx].selected_data[di][event.key] = event.value;
    this.alertSubscriptionService.kpiLeftLists[idx].selected_data[di][event.key] = event.value;
    this.cancelSaveChanges = false;

    let fndChild:any = this.fields.find((rs:any)=> rs.parentKey == event.key);

    if(fndChild) {
      let allOption = this.alertSubscriptionService.allOnLoadDataForFields[fndChild.key];

      let fndCd:any = this.fields.find((rs:any)=> rs.key == event.key);

      let keyC = fndCd.templateOptions.response_key ? fndCd.templateOptions.response_key : event.key;

      if (event.value instanceof Object && !Array.isArray(event.value)) {
        this.kpiLeftLists[idx].selected_option[di][fndChild.key] = allOption.filter((f:any)=> f[keyC] == event.value[event.key])
      } else if (Array.isArray(event.value)) {
        this.kpiLeftLists[idx].selected_option[di][fndChild.key] = allOption.filter((f:any)=> event.value.find((o:any)=> f[keyC] == o[event.key])) 
      }
      
      if (this.kpiLeftLists[idx].selected_data[di][fndChild.key] instanceof Object && !Array.isArray(event.value)) {
        this.kpiLeftLists[idx].selected_data[di][fndChild.key] = null;
        this.alertSubscriptionService.kpiLeftLists[idx].selected_data[di][fndChild.key] = null;
      } else if (Array.isArray(this.kpiLeftLists[idx].selected_data[di][fndChild.key])) {
        this.kpiLeftLists[idx].selected_data[di][fndChild.key] = [];
        this.alertSubscriptionService.kpiLeftLists[idx].selected_data[di][fndChild.key] = [];
      }

      
    } else {
      this.kpiLeftLists[idx].selected_option[di][event.key] = this.alertSubscriptionService.allOnLoadDataForFields[event.key];
    }
    
    this.valueChanges = {'position': 'left'+idx+di, 'key': event.key, 'valueChanges': this.valueChangesConti = !this.valueChangesConti};
  }

  emitRightSelectedValue(event: any, idx: any, di: any) {
    // debugger
    this.kpiRightLists[idx].selected_data[di][event.key] = event.value;
    this.alertSubscriptionService.kpiRightLists[idx].selected_data[di][event.key] = event.value;
    this.cancelSaveChanges = false;

    let fndChild:any = this.fields.find((rs:any)=> rs.parentKey == event.key);

    if(fndChild) {
      let allOption = this.alertSubscriptionService.allOnLoadDataForFields[fndChild.key];

      let fndCd:any = this.fields.find((rs:any)=> rs.key == event.key);

      let keyC = fndCd.templateOptions.response_key ? fndCd.templateOptions.response_key : event.key;

      if (event.value instanceof Object && !Array.isArray(event.value)) {
        this.kpiRightLists[idx].selected_option[di][fndChild.key] = allOption.filter((f:any)=> f[keyC] == event.value[event.key])
      } else if (Array.isArray(event.value)) {
        this.kpiRightLists[idx].selected_option[di][fndChild.key] = allOption.filter((f:any)=> event.value.find((o:any)=> f[keyC] == o[event.key])) 
      }
      
      if (this.kpiRightLists[idx].selected_data[di][fndChild.key] instanceof Object && !Array.isArray(event.value)) {
        this.kpiRightLists[idx].selected_data[di][fndChild.key] = null;
        this.alertSubscriptionService.kpiRightLists[idx].selected_data[di][fndChild.key] = null;
      } else if (Array.isArray(this.kpiRightLists[idx].selected_data[di][fndChild.key])) {
        this.kpiRightLists[idx].selected_data[di][fndChild.key] = [];
        this.alertSubscriptionService.kpiRightLists[idx].selected_data[di][fndChild.key] = [];
      }

      
    } else {
      this.kpiRightLists[idx].selected_option[di][event.key] = this.alertSubscriptionService.allOnLoadDataForFields[event.key];
    }
    
    this.valueChanges = {'position': 'right'+idx+di, 'key': event.key, 'valueChanges': this.valueChangesConti = !this.valueChangesConti};

    
  }

  duplicatesDetected(data:any) {
    if(data.length>1) {
      const duplicateIndices = this.findDuplicateIndices(data);
      if(duplicateIndices.length>0) {
        return true; 
      } 
    }
    return false; 
  }
  
  duplicatesDetectes() {
    let combineData = this.kpiLeftLists.concat(this.kpiRightLists);
    let allDuplicateIndices = [];

    for (let index = 0; index < combineData.length; index++) {
      const element = combineData[index];
      const duplicateIndices = this.findDuplicateIndices(element.selected_data);
      if (duplicateIndices && duplicateIndices.length > 0) {
        allDuplicateIndices.push({ index, duplicateIndices });
      }
    }
    
    return allDuplicateIndices.length>0 ? true : false;
  }

  cancelChanges() {
    // debugger
    this.currentlyOpenedItemRightIndex = -1;
    this.currentlyOpenedItemIndex = -1;
    this.loader = false;
    this.loadApiData();
  }

  saveChanges() {
    // debugger
    this.loader = false;
    let data: any = sessionStorage.getItem("employee_key")
    const employeeKeyObject = JSON.parse(data);
    const employeeValue = employeeKeyObject._value;

    let combineTwoArray = this.kpiLeftLists.concat(this.kpiRightLists);

    let finalData: any = [];

    for (let index = 0; index < combineTwoArray.length; index++) {
      const element = combineTwoArray[index];

      let obj: any = {
        "client_key": this.clientKey,
        "employee_key": employeeValue,
        "alert_key": element.alert_key,
        "alert_type_key": element.alert_type_key,
        "brand_key": this.filterService.baseQuery['brand_key'] ? this.filterService.baseQuery['brand_key'] : element.brand_key,
      }

      if (element.selected_data.length > 0) {
        element.selected_data.forEach((f: any) => {

          let brakeObj = Object.entries(f).map(([key, value]) => ({ key, value }));

          let flrData = brakeObj.filter((m: any) => element.selected_fields.find((o: any) => o.key == m.key));

          if (flrData.length > 0) {
            let data: any = {};

            flrData.forEach((d: any) => {
              let newObj = { [d.key]: d.value }
              let stringify = this.alertSubscriptionService.setFilterValue(newObj);
              data = Object.assign(data, stringify)
            });

            // Field Name changes
            let keyMapping: any = {};
            element.selected_fields.map((m: any) => {
              if (m.templateOptions.payload_key) {
                keyMapping[m.key] = m.templateOptions.payload_key;
              }
            })
            replaceKeys(data, keyMapping);

            let combinations = this.alertSubscriptionService.generateCombinations(data);

            combinations.map((m: any) => {
              m['in_app_subscription_flg'] = f.in_app_subscription_flg ? 1 : 0;
              m['email_subscription_flg'] = f.email_subscription_flg ? 1 : 0;
              Object.assign(m, obj);
            });
            finalData = finalData.concat(combinations);
          };
        })
      }
    }

    let finalGroupedData = this.groupDataByTimeCycle(finalData, false);

    combineTwoArray.forEach((o: any) => {
      finalGroupedData.map((m: any) => {
        if (m.alert_key && o.alert_key) {
          this.fields.forEach((f: any) => {
            if (o[f.templateOptions.flag_nm] == 'N' && !m[f.key]) {
              m[f.key] = null;
            }
          })
        }
      })
    })

    this.insertAlertData(finalGroupedData, combineTwoArray[0]);

    // replace key names
    function replaceKeys(data: any, keyMapping: any) {
      for (let oldKey in keyMapping) {
        if (data.hasOwnProperty(oldKey)) {
          const newKey = keyMapping[oldKey];
          data[newKey] = data[oldKey];
          delete data[oldKey];
        }
      }
    }
  }

  insertAlertData(obj: any, pylod: any) {
    let data: any = sessionStorage.getItem("employee_key")
    const employeeKeyObject = JSON.parse(data);
    const employeeValue = employeeKeyObject._value;

    let payload: any = {
      "configs": obj,
      "brand_key": this.filterService.baseQuery['brand_key'] ? this.filterService.baseQuery['brand_key'] : pylod.brand_key,
      "employee_key": employeeValue,
      "alert_type_key": pylod.alert_type_key
    }

    if (false) {
      this.snackBar.open("Please add a alert to subscribe!", '', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
        panelClass: ['warning-class']
      });
    } else {
      this.reqSubcription.push(this.apiService.post('kpi/kpi-subscription-update', payload).subscribe((resp: any) => {
        if (resp) {
          this.snackBar.open("Subscription updated successfully!", '', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success-class']
          });
          this.loader = true;
        } else {
          this.snackBar.open("Subscription failed!", '', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['error-class']
          });
          this.loader = false;
          this.loadApiData();
        }
        this.cancelSaveChanges = true;
      }, (error: any) => {
        if (obj.length == 0) {
          this.snackBar.open("Subscription updated successfully!", '', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success-class']
          });
          this.loader = true;
        } else {
          this.snackBar.open("Alerts subscription failed!", '', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['error-class']
          });
          this.loader = false;
          this.loadApiData();
        }
        this.cancelSaveChanges = true;
      }))
    }

  }

  groupDataByTimeCycle(originalData: any, varOfOnload: any) {
    if (originalData.length == 0) return [];

    // Extract keys from the first element of the array
    const firstItemKeys = Object.keys(originalData[0]);

    // Filter out the 'time_cycle_dim_key'
    // const groupingKeys = firstItemKeys.filter(key => key !== 'time_cycle_dim_key' && key !== 'time_cycle_key');
    const findKey: any = firstItemKeys.find(key => key == 'time_cycle_dim_key' || key == 'time_cycle_key');

    const groupedDataMap = new Map();

    // debugger

    originalData.forEach((item: any) => {
      // Extract keys from the first element of the array
      const firstItemKeys = Object.keys(item);

      // Filter out the 'time_cycle_dim_key'
      const groupingKeys = firstItemKeys.filter(key => key !== 'time_cycle_dim_key' && key !== 'time_cycle_key');
      const findKey: any = firstItemKeys.find(key => key == 'time_cycle_dim_key' || key == 'time_cycle_key');

      // Create a unique key for each item based on the specified properties
      const key = groupingKeys.map((key: any) => item[key]).join('-');

      if (groupedDataMap.has(key)) {
        if (findKey) {
          groupedDataMap.get(key)[findKey].push(item[findKey]);
        }
      } else {
        // Create a new group and add to the map
        const newGroup: any = { ...item };
        if (findKey) {
          newGroup[findKey] = [item[findKey]]
        }
        groupedDataMap.set(key, newGroup);
      }
    });

    // Convert the map back to an array of groups
    let groupedData = Array.from(groupedDataMap.values());

    if (varOfOnload) {
      groupedData.forEach((item: any) => {
        item[findKey].sort((a: any, b: any) => {
          return Number(a) - Number(b);
        });
        item[findKey] = item[findKey].toString();
      });

      // Sort the Grouped Data
      groupedData.sort((a: any, b: any) => a[findKey].localeCompare(b[findKey]));
    }

    return groupedData;
  }

  groupBySpecificKeys(data: any[], keys: string[]): any {
    const grouped: any = {};

    data.forEach(obj => {
      const groupKey = keys.map(key => obj[key]).join('-'); // Create a composite key

      if (!grouped[groupKey]) {
        grouped[groupKey] = [];
      }

      grouped[groupKey].push(obj);
    });

    return grouped;
  }

  getInfoDetails(item: any) {
    this.currentSelection = item.alert_nm;
    this.alertDescription = item.alert_desc;
    // debugger
  }

  deleteAlerts(kpi: any) {
    kpi.selected_data = [];
    kpi.selected_option = [];
    this.removeSubs = true;
    this.cancelSaveChanges = false;
    this.validationForCancel = false;
  }

  closeMenu() {
    this.trigger?.closeMenu();
  }

  validationForSaveChanges(): boolean {
    let combineData = this.kpiLeftLists.concat(this.kpiRightLists);
    let flrData = combineData.filter((f: any) => f.selected_data.length > 0);

    for (let index = 0; index < flrData.length; index++) {
      const element = flrData[index];
      const selectedEl = element.selected_data;
      for (let idx = 0; idx < selectedEl.length; idx++) {
        const element = selectedEl[idx];
        const flg = this.allValuesExist(element)
        if (flg) return flg;
      }
    }

    return false;
  }

  allValuesExist(obj: any): boolean {
    if (!obj.in_app_subscription_flg && !obj.email_subscription_flg) {
      return true;
    }

    for (const key in obj) {
      const value = obj[key];
      if (value === '') {
        return true;
      }
      if (value === null) {
        return true;
      }
      if (typeof value === 'object') {
        if (Object.keys(value).length === 0) {
          return true;
        }
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return true;
        }
      }
    }
    return false;
  }

  deepEqual(obj1: any, obj2: any): boolean {
    // Handle primitive types directly
    if (obj1 === obj2) return true;

    // Check if both are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have same number of keys
    if (keys1.length !== keys2.length) return false;

    // Check values for each key recursively
    return keys1.every(key => this.deepEqual(obj1[key], obj2[key]));
  }

  findDuplicateIndices(arr: any) {
    const indices: number[] = [];

    arr.forEach((obj: any, index: any) => {
      // Check if there is a duplicate object with the same values
      const isDuplicate = arr.some((otherObj: any, otherIndex: any) => {
        // Exclude self-comparison
        if (otherIndex !== index) {
          return this.deepEqual(obj, otherObj);
        }
        return false;
      });

      if (isDuplicate && !indices.includes(index)) {
        indices.push(index);
      }
    });

    return indices;
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
