<div #smallBar style="font-family: Poppins !important">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <!-- <button mat-raised-button color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
      <div style="background-color: #ffff;">
        <div class="m-1 mb-2">
        <icon-chart 
            [headerConfig]="headerConfig" 
            [iconList]="iconList" 
            [data]="data" 
            [pageKey]="pageKey" 
            [heading]="heading" 
            chartName="smallBar"
            [config]="smallBarContainer" 
            [expand]="fs" 
            [fullscreen]="isFullscreen" 
            [item]="item"
          ></icon-chart>
      </div>  </div>

<div *ngIf="item?.config?.list_of_api == 100609" ><hr></div>
      <div><loading *ngIf="isLoading"> </loading></div> 
      <div [style.display]="noData?'none':''" #smallBarContainer></div>
      <div style="height: 230px; width: auto; " *ngIf="noData">
       <span class="bg-text" >There is no data for this time period</span></div>
      <div #smallBarChart>
        <div id="d3SBarTooltip">
          <div *ngIf="isTooltip &&  myType=='groupFullName'" style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData}}</div>
          <div  *ngIf=" isTooltip && myType!='groupFullName'" class="container chart_tooltip">
          
            <div class="row chart_tooltip_header">
              <div class="col-12 d-flex flex-column mt-1 mb-1">
                <div class="chart_tooltip_header_heading">
               {{ item?.config?.list_of_api == 100609?  "Time to Therapy": "No of Defects"}} ({{item.config.chart_type=='onebar'?( item?.config?.list_of_api == 100609? (mytooltipData?.['name']|date:'MMM yyyy'):removeUnderScore(mytooltipData?.['name'])):mytooltipData?.[0]['name']}})
                </div>
              </div>
            </div>


            <div class="mt-2 mb-5" *ngIf="height > 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
              <div class="row" *ngIf="item.config.chart_type=='onebar'">
                <div class="col-12 d-flex m-auto  align-items-center">
                  <div>
                    <div class="tooltip_outlay" [style.background]="mytooltipData?.fill"></div>
                  </div>
                  <div class="chart_tooltip_body_data ml-2"> {{ item?.config?.list_of_api == 100609?  "Days": "Defects:"}} {{mytooltipData?.value ? numbedPipe(mytooltipData?.value ) : mytooltipData?.value }}  </div>
                </div>
              </div>

              <div *ngIf="item.config.chart_type=='twobar'">
                <div class="row" *ngFor="let data of mytooltipData;let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center pb-1">

                    <div>
                      <div class="tooltip_outlay" [style.background]="data?.fill"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2"> 
                      {{ errorType(data?.errorType) }}: {{data?.value ? numbedPipe(data?.value ) : data?.value }} </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="mt-2 mb-5" *ngIf="height < 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">


              <div class="row" *ngIf="item.config.chart_type=='onebar'">
                <div class="col-12 d-flex m-auto  align-items-center pb-1">

                  <div>
                    <div class="tooltip_outlay" [style.background]="mytooltipData?.fill"></div>
                  </div>
                  <div class="chart_tooltip_body_data ml-2 mb-2">{{ item?.config?.list_of_api == 100609? ( numbedPipe(mytooltipData?.value )  +" days"): ("Defects:" + numbedPipe(mytooltipData?.value ) )}}   </div>

                </div>
              </div>

              <div *ngIf="item.config.chart_type=='twobar'">
                <div class="row" *ngFor="let data of mytooltipData;let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center pb-1">

                    <div>
                      <div class="tooltip_outlay" [style.background]="data?.fill"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2"> 
                      {{ errorType(data?.errorType) }} : {{data?.value ? numbedPipe(data?.value ) : data?.value }} </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </article>
  </main>
</div>