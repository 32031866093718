<div style="width:100%;height: 100%;display: flex;">
  <!-- left menu -->
  <!-- <app-user-menu></app-user-menu> -->
  <div style="flex:1 0 auto;">
    <app-topnav [title]="workName"></app-topnav>
    <div *ngIf="pages" style="flex: 1 0 auto;height: 100%;padding: 5px;border-right:1px #ddd solid; margin-left: 94px !important;">
        <div style="flex-direction: column;width:auto; height: 100%;" *ngIf="pages.length > 1">
          <mat-tab-group style="height: 100%;">
            <mat-tab *ngFor="let page of pages">
              <ng-template mat-tab-label>
                <div>
                  {{page.page_nm}}
                </div>
              </ng-template>
              <ng-template matTabContent style="flex:1">           
                  <app-grid-page [pageInfo]="page" style="flex:1 0 auto;height:100%" ></app-grid-page>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div style="flex-direction: column;width:auto; height: 78vh;" *ngIf="pages.length == 1">
            <app-grid-page [pageInfo]="pages[0]" style="flex:1 0 auto;height:100%" ></app-grid-page>
        </div>
        <div style="height:20px;text-align: center;width:100%">
          Copyright ©2024 Data Unveil
        </div>
    </div>
  </div>
</div>
