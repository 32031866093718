<input type="hidden" [formControl]="dynamicSelect" [formlyAttributes]="field" />

<div *ngIf="this.showBy != 'no'">
    <label class="filterLabelStyle" style="margin: 0 !important; z-index: 9;">{{label||"NO Lable"}}</label>

    <!-- Select Dropdown -->
    <ng-select class="ngSelection" style="font-size:13px; font-family: 'Poppins'" #sc [multiple]="true"
        [clearable]="false" [items]="option" [(ngModel)]="selectedItems" [placeholder]="this.placeholder"
        [selectableGroup]="true" [closeOnSelect]="false" [dropdownPosition]="'bottom'" (remove)="selectOption()"
        (add)="selectOption()" (close)="onClose()" [bindLabel]="this.labelProp" [virtualScroll]="true">

        <!-- Search Of the Filter -->
        <ng-template ng-header-tmp>
            <input class="searchFiler" type="text" (input)="sc.filter(onFilterTextBoxChanged($event))"
                placeholder="Search" />

            <div class="form-check mr-2">
                <input class="form-check-input" type="checkbox" [ngModel]="isChecked" (click)="selectAllClick()">
                <label class="form-check-label" for="flexCheckDefault">
                    ({{selectAllLabel }} Search Results)
                </label>
            </div>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" [ngModel]="isAddChecked" (click)="selectAddClick()">
                <label class="form-check-label" for="flexCheckDefault">
                    {{'Add current selection to filter'}}
                </label>
            </div>
        </ng-template>

        <!-- Visible of the Filter -->
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" let-index="index">
            <div class="ng-value" *ngFor="let item of selectedItems  | slice: 0:itemsShowLimit">
                <span class="ng-value-label">{{ item[this.labelProp]}}</span>
            </div>
            <div class="ng-value" *ngIf="selectedItems.length > itemsShowLimit">
                <span class="ng-value-label">{{ selectedItems.length - itemsShowLimit }} more...</span>
            </div>
        </ng-template>

        <!-- Selected Check Box AND Lable name -->
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="form-check" style="margin: 0 0 -5px;">
                <input class="form-check-input" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    [value]="item">
                <label [matTooltip]="item[this.toolTipKey]" [matTooltipPosition]="'above'"
                    matTooltipClass="example-tooltip-multi" style="cursor: pointer" class="form-check-label"
                    for="flexCheckDefault">
                    {{item[this.labelProp] }}
                </label>
            </div>
        </ng-template>

        <!-- Apply Button -->
        <ng-template ng-footer-tmp>
            <button (click)="applyFilter(sc)" class="btn btn-sm btn-secondary buttonApply"
                [disabled]="selectedItems.length == 0">Apply</button>
        </ng-template>
    </ng-select>
    <hr>
</div>