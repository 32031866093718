<div>
    <label class="filterLabelStyle" style="margin-bottom: 5px;">{{label||"NO label"}}</label>
    <span *ngIf="validationForField" style="padding: 4px;color: #d11044;">*</span>
    <!-- Select Dropdown -->
    <ng-select 
        #sc
        [disabled]="validationOnParentChecks"
        appearance="outline"
        class="ngSelectionAlert" 
        [class.ng-select-validation]="validationForField"
        style="font-family: 'Poppins'" 
        [multiple]="true"
        [clearable]="false" 
        [items]="option" 
        [(ngModel)]="selectedOption" 
        [placeholder]="placeholder"
        [selectableGroup]="true" 
        [closeOnSelect]="false" 
        appendTo="body"
        [virtualScroll]="true"
        (remove)="selectOption()"
        (add)="selectOption()" 
        (close)="onClose()" 
        [bindLabel]="this.labelProp"
        >
        <!-- (change)="selectionChange()" -->

        <!-- Search Of the Filter -->
        <ng-template ng-header-tmp>
            <!-- <input class="searchFiler" type="text" (input)="sc.filter(onFilterTextBoxChanged($event))"
                placeholder="Search" /> -->

            <div class="form-check mr-2">
                <input class="form-check-input" style="cursor: pointer;" type="checkbox" [ngModel]="isChecked" (click)="selectAllClick()">
                <label class="form-check-label" style="cursor: pointer;" for="flexCheckDefault" (click)="selectAllClick()">
                    {{selectAllLabel }}
                </label>
            </div>
        </ng-template>

        <!-- Visible of the Filter -->
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" let-index="index">
            <div class="ng-value" *ngFor="let item of selectedOption | slice: 0: this.key == 'sp_location_key' ? 1 : itemsShowLimit">
                <span class="ng-value-label">{{ item[this.labelProp]}}</span>
            </div>
            <div class="ng-value" *ngIf="selectedOption.length > (this.key == 'sp_location_key' ? 1 : itemsShowLimit)">
                <span class="ng-value-label">{{ '+' + (selectedOption.length - (this.key == 'sp_location_key' ? 1 : (this.key == 'sp_location_key' ? 1 : itemsShowLimit))) }}</span>
            </div>
        </ng-template>

        <!-- Selected Check Box AND Lable name -->
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="form-check" style="margin: 0 0 -5px;">
                <input class="form-check-input" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    [value]="item">
                <label [matTooltip]="item[this.labelProp]" [matTooltipPosition]="'above'"
                    matTooltipClass="example-tooltip-multi" style="cursor: pointer" class="form-check-label"
                    for="flexCheckDefault">
                    {{item[this.labelProp] }}
                </label>
            </div>
        </ng-template>
    </ng-select>
    <div *ngIf="validationForField">
        <span class="my-2 du-req-text"> {{this.label + ' is required' }} </span>
    </div>
</div>