<input type="hidden" [formControl]="dynamicSelect" [formlyAttributes]="field" />
<div *ngIf="this.showBy != 'no'">
    <label class="filterLabelStyle" style="margin: 0 !important;">{{label}}</label>
    
    <mat-form-field class="example-full-width-time-date" style="width: 100% !important;" appearance="fill">
        <input 
            matInput 
            readonly 
            [min]="minDate" 
            [max]="maxDate" 
            [formControl]="selectedDate"
            [matDatepicker]="datePicker" 
            (dateInput)="changeDateSelection($event)"
        >
    
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    
    <hr>
</div>