<div *ngIf="!dataLoads && selectDataLoaded" style="min-width: 385px;">
  <div class="col-12 d-flex justify-content-between align-items-center" style="padding: 10px 15px;">
    <span class="followUpHeader" >
      {{ (paramsData.toBeShow ? 'Create' : 'Edit') + ' Follow-up' }}
    </span>
    <mat-icon [svgIcon]="cancel_x" style="cursor: pointer;width: 13px;color: #8A98AB !important;" mat-dialog-close></mat-icon>
  </div>

  <hr>

  <div class="p-3">
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label followUpHeader">Select follow-up type</label>
      <select class="form-select selectFollowUp" [(ngModel)]="followType" aria-label="Default select example" placeholder="followUpHeader" style="font-size: 13px; height: 40px;">
        <option *ngFor="let flow of followUpReasonAllData" [value]="flow.name">{{ flow.name }}</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label followUpHeader">{{(paramsData.toBeShow ? 'Select' : 'Due') + ' date'}} </label>
      <mat-form-field appearance="outline" style="width: 100%;" class="followUpPopDate">
        <input matInput [matDatepicker]="picker" readonly [min]="minDate" placeholder="Select date" [(ngModel)]="selectedFollowDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="mb-3" *ngIf="!paramsData.toBeShow">
      <label for="exampleFormControlInput1" class="form-label followUpHeader">Resolve</label>
      <select class="form-select selectFollowUp" [(ngModel)]="selectedFollowUpResolve" aria-label="Default select example" placeholder="followUpHeader" style="font-size: 13px; height: 40px;">
        <option *ngFor="let resl of followUpResolvedAllData" [value]="resl.value">{{ resl.name }}</option>
      </select>
    </div>

    <div *ngIf="!paramsData.toBeShow">
      <label for="exampleFormControlInput1" class="form-label followUpHeader followUpHeaderValue mr-1">Created by: </label>
      <label for="exampleFormControlInput1" class="form-label followUpHeader followUpHeaderValueWeight "> {{this.createdBy}}</label>
    </div>
    
    <div *ngIf="!paramsData.toBeShow">
      <label for="exampleFormControlInput1" class="form-label followUpHeader followUpHeaderValue mr-1">Created date: </label>
      <label for="exampleFormControlInput1" class="form-label followUpHeader followUpHeaderValueWeight">{{createdDate}}</label>
    </div>
  </div>

  <hr style="margin: 0 !important">

  <div class="d-flex align-items-center" style="padding: 10px 15px;">
    
    <!-- <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="followUpFlag" style="cursor: pointer;">
      <label class="form-check-label followUpHeaderFlag" for="flexCheckDefault" style="cursor: pointer;">
        Flag for a follow-up
      </label>
    </div> -->

    <div class="ms-auto d-flex align-items-center">
      <div>
        <button type="button" class="btn btncreate" style="color: #1363DF;" mat-dialog-close>Cancel</button>
      </div>
      <div>
          <button type="button" class="btn btn-primary btncreate" (click)="saveAndCreate()" [disabled]="!followType">{{ !paramsData.toBeShow ? 'Save' : 'Create'}}</button>
      </div>
    </div>
  </div>

</div>

<div *ngIf="dataLoads" style="width: 385px;position: relative;">
  <div class="col-12 d-flex justify-content-end align-items-center" style="padding: 10px 15px;">
    <mat-icon [svgIcon]="cancel_x" style="cursor: pointer;width: 13px;color: #8A98AB !important;" (click)="gotItforValue()"></mat-icon>
  </div>

  <div style="text-align: center;">
    <div *ngIf="this.loaderApi">
      <div class="circle-loader">
        <div class="checkmark draw"></div>
      </div>

      <div *ngIf="visibleData">
        <h2 class="successHeader">Success!</h2>
        <p class="followStatusParag" *ngIf="paramsData.toBeShow">Follow-up has been successfully created!</p>
        <p class="followStatusParag" *ngIf="!paramsData.toBeShow">Follow-up has been successfully updated!</p>
      </div>
    </div>

    <div *ngIf="!this.loaderApi">
      <div style="text-align: center;margin-bottom: 25px;">
        <svg style="width: 50px;height: 50px;color: red;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
        </svg>
      </div>
  
      <div *ngIf="visibleData">
        <h2 class="successHeader">Failed!</h2>
        <p class="followStatusParag">Something went wrong...!</p>
      </div>
    </div>
    <div style="margin-bottom: 25px;" *ngIf="this.visibleData">
      <button type="button" class="btn btn-primary btncreate" (click)="gotItforValue()">Got it</button>
    </div>
  </div>

</div>