export default {
  legend: {
    id: 'missing-info-legend',
    data: {
      source: 'service',
    },
    view: {
      width: 400,
      height: 164,
    },
  },
  tooltip: {
    id: 'missing-info-tooltip',
    data: {
      source: 'service',
    },
    view: {
      template: 'custom',
    },
  },
  vega: {
    id: 'missing-info-chart',
    data: {
      source: 'service',
    },
    view: {
      spec: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        width: 164,
        height: 164,
        autosize: 'none',
        signals: [{
          name: 'startAngle', value: 0,
        }, {
          name: 'endAngle', value: 6.29,
        }],

        data: [{
          name: 'series',
          values: [],
          transform: [{
            type: 'pie',
            field: 'value',
            startAngle: { signal: 'startAngle' },
            endAngle: { signal: 'endAngle' },
          }],
        }],

        scales: [{
          name: 'color',
          type: 'ordinal',
          domain: { data: 'series', field: 'type' },
        }],

        marks: [{
          type: 'arc',
          from: { data: 'series' },
          encode: {
            enter: {
              fill: { scale: 'color', field: 'type' },
              x: { signal: 'width / 2' },
              y: { signal: 'height / 2' }
            },
            update: {
              startAngle: { field: 'startAngle' },
              endAngle: { field: 'endAngle' },
              padAngle: { value: 0.01 },
              innerRadius: { signal: 'width / 2 * 0.7' },
              outerRadius: { signal: 'width / 2' },
              cornerRadius: { value: 0 }
            }
          }
        }]
      }
    }
  }
}