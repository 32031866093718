

  <div style="width:100%;">
    <main>
        <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
            <div style="background-color: #fff;">
                <icon-chart 
                    [headerConfig]="headerConfig" 
                    [iconList]="iconList" 
                    [data]="data" 
                    [pageKey]="pageKey" 
                    [heading]="heading"
                    [chartName]="divId" 
                    [config]="HeatMapContainer" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                ></icon-chart>
            </div>
            <div><loading *ngIf="isLoading"> </loading></div> 
            <div class="row">
                <div class="col-12">
                    <div id="BasicHeatMapChartDiv" [class.overWhole]="this.deviceDetectionService.isMobile"  style="font-family: Poppins !important;">
                        <div id="legendDiv"></div>
                        <div class="d-flex justify-content-start align-items-start" [style.overflow-y]="isFullscreen ? this.deviceDetectionService.isMobile ? '' : 'auto' : ''" [class.fulScrren]="isFullscreen" [style.height]="isFullscreen ? this.deviceDetectionService.isDesktop ? 'calc(100vh - 70px)' : this.deviceDetectionService.isMobile ? 'calc(100vh - 125px)' : 'calc(100vh - 100px)' : 'auto' ">
                           <div id="leftAxisDiv"></div>
                           <div id="chartDiv" #HeatMapContainer [class.overWholeInMobile]="!this.deviceDetectionService.isMobile"></div>
                        </div>
                    </div>


                    <div id="d3fullTooltip" style="display: none;">
                        <div style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize">
                            {{mytooltipData}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div id="BasicHeatMapChartDiv" style="font-family: Poppins !important; overflow: auto; height: calc(100vh - 83px);"  >
                <div id="legendDiv" ></div>
                <div id="leftAxisDiv" style="float: left;"></div>
                <div id="chartDiv" style="float: left; overflow-x: auto" #HeatMapContainer ></div>
            </div>
            <div id="d3fullTooltip">
                <div  style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData}}</div></div> -->
        </article>
    </main>
</div>