import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-bubble-chart-card",
  templateUrl: "./bubble-chart-card.component.html",
  styleUrls: ["./bubble-chart-card.component.scss"],
})
export class WidgetBubbleChartComponent implements OnInit, OnDestroy {
  // static key = 100506;
  
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  heading: any;
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('bubbleCharDiv', { static: true }) bubbleCharDiv!: ElementRef
  config: any
  data: any
  isDataLoaded = false
  reqSubcription: Subscription[] = []
  constructor(
    private filterService: FilterService,
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
    })
    )
  }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.bubbleCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
    this.config = {
      xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
      yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
      value_column: this.item.config["value_column"]["api_resp_column_nm"],
      secondary_value_column: this.item.config["secondary_value_column"]?.["api_resp_column_nm"] || null,
    }
  }

  loadApiData(reportType?: string) {
    let api = this.item.config["list_of_api"] || {};
    
    if((this.filterService.isEmptyObject(this.filterService.baseQuery) == false)){
    
      let obj:any = {"api_key" : '100115'}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.isDataLoaded = true;
          this.data = res == null ? [] : res;
        }, (err: any) =>{
          this.data = [];
          console.log(err)
        })
      )

      // this.reqSubcription.push(
      //   this.filterService.executeQuery(obj).subscribe(
      //     (res: any) => {
      //       this.isDataLoaded = true;
      //       this.data = res == null ? [] : res;
      //     },
      //     (error: any) => {
      //       this.data = [];
      //     }
      //   )
      // );
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
