
<div class="row page-title-header modal-header m-0">
  <div class="col-md-12 col-12 text-center">
    <span class="title">Select Client</span>
  </div>
</div>

<div style="padding: 20px 15px 0;">
  <mat-form-field style="width: 100%" appearance="fill" class="matFformFieldClient">
    <mat-label>Client Name</mat-label>
    <mat-select [(ngModel)]="selectedClient">
      <mat-option style="font-family: 'Poppins';" *ngFor="let client of clients" [value]="client">{{client.client_nm }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="row">
  <div class="col-12 text-center my-4">
    <button 
      type="button" 
      class="btn btn-primary btn-sm confirmBtnHomePopups" 
      matTooltip="Confirm" 
      matTooltipClass="expand-tooltip"
      [disabled]="!selectedClient" 
      (click)="clientSelection(selectedClient)" 
    >
      Confirm
    </button>
  </div>
</div>