import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../services/filter.service';

@Pipe({
  name: 'searchText',
  pure: false
})
export class searchTextPipe implements PipeTransform {
  constructor() {}

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      let propertKeys = Object.keys(item)
      let possibleValues = propertKeys.some((value:any)=> item[value]? item[value].toString().toLowerCase().includes(searchText) : '')
      return possibleValues ? item : ''
    });
  }
}
