import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number | any): string {
    
    if(value==0) return value
    
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + 'B';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + 'M';
    } else if (value >= 10000) {
      return (value / 1000).toFixed(2) + 'K';
    } else {
      return parsedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
}

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;

    if (parsedValue >= 1000000000) {
      return this.formatNumberWithCommas(parsedValue / 1000000000) + 'B';
    } else if (parsedValue >= 1000000) {
      return this.formatNumberWithCommas(parsedValue / 1000000) + 'M';
    } else if (parsedValue >= 10000) {
      return this.formatNumberWithCommas(parsedValue / 1000) + 'K';
    } else {
      return this.formatNumberWithCommas(parsedValue);
    }
  }

  private formatNumberWithCommas(parsedValue: any) {
    if(parsedValue==0) return parsedValue

    if (parsedValue % 1 === 0) {
      // If it's a whole number, round it and remove decimal places
      return parsedValue.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
    } else {
      // If it's not a whole number, preserve the decimal places
      return parsedValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
}