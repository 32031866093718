import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { AccordinColumnComponent } from '../../shared/accordin-column/accordin-column.component';
import { AlertIconComponent } from '../../shared/alert-icon/alert-icon.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as _ from 'lodash';;

@Component({
  selector: 'app-prescriber-accordin',
  templateUrl: './prescriber-accordin.component.html',
  styleUrls: ['./prescriber-accordin.component.scss'],
})
export class WidgetPrescriberAccordinComponent implements OnInit, OnDestroy {
    static key = 100601;

    @Input('config') config:any = {}
    @Input('item') item:any;
    @Input('pageKey') pageKey: any 

    @ViewChild("htmlcontent") htmlcontent!: ElementRef;
    searchText=""
    panelOpenState = false
    step!:number;
    heading:any;
    inputTypeBox:any;
    isDataLoaded=false;
    isDataLoading:any;
    rowData:any;
    reqSubcription: Subscription[] = []
    down = "down-arrow-collapse"
    up = "down-arrow-expand"
    
    async stop(ms: number): Promise<void> {
      return new Promise<void>(resolve => setTimeout(resolve, ms));
    }
    start() { this.isDataLoaded = false;}

    constructor(
        public filterService:FilterService, 
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private dashboardService:DashboardService) { 
        this.filterService.filterQuery.subscribe((query:any)=>{
            this.loadApiData()
        })
        this.filterService.dataRefreshinTooltip.subscribe(sdf=>{
            this.loadApiData()
        })

        this.reqSubcription.push(this.filterService.refreshAlertDelete.subscribe((res: any) => {
          if(res!=true) {
            this.updateSetting(res)
          } else {
              this.refreshAlertData()
            }
          })
        )

        this.filterService.onQuickFilter.subscribe((res:any)=> {
          this.searchText = res
        })
    }
    
    setStep(index: number, option:any) {
        this.step = index;
        this.panelOpenState = true
        this.filterService.hoverBackground = true
        this.filterService.locationPresId = option.prescriber_id
    }
    closeStep() {
        this.panelOpenState = false
    }

    nextStep() {
        this.step++;
    }
    prevStep() {
        this.step--;
    }

    locationId:any
    
    trackByFn(index: number, row: any): number {
        return row.id;
    }

    ngOnInit(){

        this.route.params.subscribe((p:any) => {
            let cardName = p["cardName"];

            if(cardName=='pwt') {
                this.route.queryParams.subscribe((params:any) => {
                    this.locationId = params.locName
                })
            }
        })
        
        // this.filterService.sliderValue = 1
        this.isDataLoading = "Loading..."
        this.loadConfigurationData()
        
    }

    expendedList:any

    loadConfigurationData() {
        let cfg = this.item.config
        this.heading = cfg['report_heading'] || '';
        this.inputTypeBox = cfg['input_type_search']['value']

        this.expendedList = cfg.total_patient_nav?.controls[0]
        if(this.expendedList) {
            this.expendedLoadData(this.expendedList)
        }

        this.filterService.selectedColumn = []

        cfg.list_of_api_col.sort(function(a:any, b:any) {
            return a.arrayIndex - b.arrayIndex;
        });

        cfg.list_of_api_col.forEach((res: any) => {
            res.property_CB.forEach((cb: any) => {
                if (cb.name == 'Add Column') {
                    if (cb.value) {
                        this.filterService.selectedColumn.push(res.api_resp_column_nm)
                    }
                }
            })
        })
    }

    loadApiData() {
        let api = this.item.config['list_of_api'] || {};
        sessionStorage.setItem('apiKey_from_grid', JSON.stringify(this.item.config['list_of_api'] || {}))
        this.isDataLoaded = false;
        this.rowData = []
        
        if (api.api_key && this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
            
          let obj = {
            activity_since_days: this.filterService.sliderValue || undefined,
            prescriber_office_location: this.locationId || undefined,
            report_typ : 'D',
            api_key: api.api_key
          }

          this.reqSubcription.push(this.filterService.frmExecutionQuery(obj).subscribe((res: any) => {
            this.isDataLoaded = true
            let resData = res == null ? [] : res
            this.rowData = resData.sort((a: any, b: any) => {
                if (a.prescriber_nm > b.prescriber_nm) {
                    return 1;
                }
                if (a.prescriber_nm < b.prescriber_nm) {
                    return -1;
                }
                return 0;
              })
            }, (err)=> {
                this.isDataLoading = "No Data" 
            })
          )
        }
    }

    ngOnDestroy(): void {
        this.reqSubcription.forEach(res => res.unsubscribe())
    }

    refreshAlertData() {
        let api = this.item.config['list_of_api'] || {};

          let query:any = {};
          query["api_key"] = api.api_key;
          query["report_typ"] = 'D'
          query["activity_since_days"] = this.filterService.sliderValue || undefined;
          query['prescriber_office_location'] = this.locationId || undefined

          this.reqSubcription.push(this.filterService.frmRemoveAlerts(query).subscribe((res: any) => {
            let cfg = this.item.config
            let resData:any = res == null ? [] : res
            this.rowData = resData.sort((a: any, b: any) => {
              if (a[cfg.list_of_api_col[0].api_resp_column_nm] > b[cfg.list_of_api_col[0].api_resp_column_nm]) {
                  return 1;
              }
              if (a[cfg.list_of_api_col[0].api_resp_column_nm] < b[cfg.list_of_api_col[0].api_resp_column_nm]) {
                  return -1;
              }
              return 0;
            })
    
          }, err => {
          })
          )
      }



    gridsize: any = 30;
    sliderValue = 1

    updateSetting(event:any) {
        this.gridsize = event;
        this.isDataLoaded = false
        if(this.gridsize ==  this.filterService.sliderValue) {
          let api = this.item.config['list_of_api'] || {};
          let carIds;
          
          let obj = {
            activity_since_days: this.filterService.sliderValue || undefined,
            prescriber_office_location: this.locationId || undefined,
            report_typ : 'D',
            api_key: api.api_key
          }

          this.reqSubcription.push(this.filterService.frmExecutionQuery(obj).subscribe((res: any) => {
             let resData = res == null ? [] : res
                this.isDataLoaded = true
                this.rowData = resData.sort((a: any, b: any) => {
                    if (a.prescriber_nm > b.prescriber_nm) {
                        return 1;
                    }
                
                    if (a.prescriber_nm < b.prescriber_nm) {
                        return -1;
                    }
                    return 0;
                  })
          },err => {
          })
          )
        }
      }

      pages:any
      dashboardPages = {}
    expendedLoadData(page:any) {
        // this.dashboardService.getExpandDetails(page).subscribe((response:any)=> {
        //     if (page.linkPoint == "DB") {
        //       response.forEach((data: any) => {
        //         this.pages = data.LeafElement || [];
        //         for (let idx in this.pages) {
        //           if (
        //             this.pages[idx].page_config_details == "[]" ||
        //             this.pages[idx].page_config_details == ""
        //           ) {
        //             this.pages[idx].page_config_details = [];
        //           } else {
        //             this.pages[idx].page_config_details = JSON.parse(
        //               this.pages[idx].page_config_details
      
        //             );
        //           }
        //         }
        //         this.dashboardPages = this.pages[0].page_config_details[0]
        //       });
        //     } else {
        //       this.pages = response[0].LeafElement || [];
        //       for (let idx in this.pages) {
        //         if (
        //           this.pages[idx].page_config_details == "[]" ||
        //           this.pages[idx].page_config_details == ""
        //         ) {
        //           this.pages[idx].page_config_details = [];
        //         } else {
        //           this.pages[idx].page_config_details = JSON.parse(
        //             this.pages[idx].page_config_details
      
        //           );
        //         }
        //       }
        //       this.dashboardPages = this.pages[0].page_config_details[0]
        //     }
        //   },(err) => {
        //   })

    }

    // dataaa={
    //     "x": 0,
    //     "y": 2,
    //     "cols": 50,
    //     "rows": 5,
    //     "key": 100502,
    //     "config": {
    //         "widgetKey": 100502,
    //         "report_heading": "",
    //         "input_type_search": {
    //             "name": "No",
    //             "value": "no"
    //         },
    //         "multi_icon": [
    //         ],
    //         "toggle-alignment": {
    //             "name": "No",
    //             "value": "no"
    //         },
    //         "list_of_api_col": [
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_id",
    //                 "api_resp_column_display_nm": "Patient ID",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "integer",
    //                 "isAlignment": "left",
    //                 "isActionIcon": true,
    //                 "isActionIconName": "greater_than",
    //                 "isActionPosition": "Right",
    //                 "isActionIconColor": "#1363df",
    //                 "isTextColorCondition": true,
    //                 "isTextColor": "#1260d8",
    //                 "isLinkCol": true,
    //                 "margingLinkColName": "Link Col S3",
    //                 "linkSelectionPossibleValues": [
    //                     {
    //                         "page_nm": "Patient List",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_patient_list_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Patient Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_patient_card_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Prescriber Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_prescriber_card_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Prescriber List",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_presciber_list_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Location Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_location_card_page_1.json"
    //                     }
    //                 ],
    //                 "selectNavigatePart": "patient_id",
    //                 "isLinkColCard": "Patient Card",
    //                 "arrayIndex": 1
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "current_status",
    //                 "api_resp_column_display_nm": "Current Status",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "isActionColumn": true,
    //                 "isSelectionTypeColumn": "Column Hover Over",
    //                 "columnSelectionPossibleValues": [
    //                     {
    //                         "api_key": 100201,
    //                         "api_display_nm": "Patient Status Journey"
    //                     },
    //                     {
    //                         "api_key": 100202,
    //                         "api_display_nm": "place holder"
    //                     }
    //                 ],
    //                 "isSelectionColumnAPI": 100201,
    //                 "isSelectionColumnParameter": [
    //                     "patient_id"
    //                 ],
    //                 "currentStatusPupups": [
    //                     {
    //                         "value": "P",
    //                         "popup": "pending"
    //                     },
    //                     {
    //                         "value": "C",
    //                         "popup": "other_than_pending"
    //                     }
    //                 ],
    //                 "conditional_format": true,
    //                 "condition_reference_column": "case_status",
    //                 "conditional_formatting_value": [
    //                     {
    //                         "col_value": "PENDING",
    //                         "icon": "status",
    //                         "color": "#ffcd4a"
    //                     },
    //                     {
    //                         "col_value": "CANCELLED",
    //                         "icon": "status",
    //                         "color": "#ff4949"
    //                     },
    //                     {
    //                         "col_value": "DISCONTINUED",
    //                         "icon": "status",
    //                         "color": "#ff3da6"
    //                     },
    //                     {
    //                         "col_value": "ACTIVE",
    //                         "icon": "status",
    //                         "color": "#3ac97c"
    //                     },
    //                     {
    //                         "icon": "status",
    //                         "color": "#2e14f0",
    //                         "col_value": "NURSING"
    //                     }
    //                 ],
    //                 "isTextColorCondition": false,
    //                 "isActionIcon": true,
    //                 "isActionPosition": "Right",
    //                 "isActionIconName": "plus",
    //                 "isActionIconColor": "#1363df",
    //                 "isEventsInIcon": true,
    //                 "isSelectionTypeIcon": "Icon Click",
    //                 "iconSelectionPossibleValues": [
    //                     {
    //                         "api_key": 100201,
    //                         "api_display_nm": "Patient Status Journey"
    //                     },
    //                     {
    //                         "api_key": 100202,
    //                         "api_display_nm": "place holder"
    //                     }
    //                 ],
    //                 "isSelectionIconAPI": 100202,
    //                 "isSelectionIconParameter": [
    //                     "patient_id"
    //                 ],
    //                 "arrayIndex": 2
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "alert",
    //                 "api_resp_column_display_nm": "Alerts",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "isActionIcon": false,
    //                 "conditional_format": false,
    //                 "isActionColumn": true,
    //                 "isSelectionTypeColumn": "Alerts",
    //                 "alertSelectionPosibleValues": [
    //                     {
    //                         "col_value": "Status Changes",
    //                         "icon": "status_change",
    //                         "color": "#ff9a3d"
    //                     },
    //                     {
    //                         "col_value": "New Patient",
    //                         "icon": "new_patient",
    //                         "color": "#e34071"
    //                     },
    //                     {
    //                         "col_value": "New Prescriber",
    //                         "icon": "new_prescriber",
    //                         "color": "#3ac97c"
    //                     },
    //                     {
    //                         "col_value": "Refill Too Soon",
    //                         "icon": "refill_alerts",
    //                         "color": "#1363df"
    //                     },
    //                     {
    //                         "col_value": "Shipment Delayed",
    //                         "icon": "delayed_alert",
    //                         "color": "#d11044"
    //                     }
    //                 ],
    //                 "arrayIndex": 3
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_nm",
    //                 "api_resp_column_display_nm": "Patient Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 4,
    //                 "isActionColumn": false,
    //                 "isSelectionTypeColumn": "Alerts",
    //                 "alertSelectionPosibleValues": [
    //                     {
    //                         "col_value": "Status Changes"
    //                     },
    //                     {
    //                         "col_value": "New Patient"
    //                     },
    //                     {
    //                         "col_value": "New Prescriber"
    //                     },
    //                     {
    //                         "col_value": "Refill Too Soon"
    //                     },
    //                     {
    //                         "col_value": "Delayed Shipment"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "gender",
    //                 "api_resp_column_display_nm": "Gender",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "cap",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 5
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_birth_date",
    //                 "api_resp_column_display_nm": "Patient DOB",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 6
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "prescriber_nm",
    //                 "api_resp_column_display_nm": "Prescriber Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 7
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "territory_nm",
    //                 "api_resp_column_display_nm": "Territory",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 8
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "data_source_name",
    //                 "api_resp_column_display_nm": "Source Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 9
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "payer_nm",
    //                 "api_resp_column_display_nm": "Payer Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 10
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "last_ship_date",
    //                 "api_resp_column_display_nm": "Last Ship Date",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 11
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "next_ship_date",
    //                 "api_resp_column_display_nm": "Next Ship Date",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 12
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "source_type",
    //                 "api_resp_column_display_nm": "Source Type",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "cap",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 13
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "ref_source",
    //                 "api_resp_column_display_nm": "Referral Source",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 14
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "case_status",
    //                 "api_resp_column_display_nm": "Case Status",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 15
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "payer_typ",
    //                 "api_resp_column_display_nm": "Payer Type",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 16
    //             }
    //         ],
    //         "status_column": {
    //             "name": "Patient Journey",
    //             "value": "patient journey"
    //         },
    //         "report_status_column": [
    //             {
    //                 "value": "P"
    //             },
    //             {
    //                 "value": "C"
    //             }
    //         ],
    //         "selected_column": [
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_id",
    //                 "api_resp_column_display_nm": "Patient ID",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "integer",
    //                 "isAlignment": "left",
    //                 "isActionIcon": true,
    //                 "isActionIconName": "greater_than",
    //                 "isActionPosition": "Right",
    //                 "isActionIconColor": "#1363df",
    //                 "isTextColorCondition": true,
    //                 "isTextColor": "#1260d8",
    //                 "isLinkCol": true,
    //                 "margingLinkColName": "Link Col S3",
    //                 "linkSelectionPossibleValues": [
    //                     {
    //                         "page_nm": "Patient List",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_patient_list_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Patient Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_patient_card_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Prescriber Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_prescriber_card_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Prescriber List",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_presciber_list_page_1.json"
    //                     },
    //                     {
    //                         "page_nm": "Location Card",
    //                         "s3_bucket_nm": "dudevtest-analytics-config",
    //                         "s3_file_path": "du/analytics/config/du_ana_frm_location_card_page_1.json"
    //                     }
    //                 ],
    //                 "selectNavigatePart": "patient_id",
    //                 "isLinkColCard": "Patient Card",
    //                 "arrayIndex": 1
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "current_status",
    //                 "api_resp_column_display_nm": "Current Status",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "isActionColumn": true,
    //                 "isSelectionTypeColumn": "Column Hover Over",
    //                 "columnSelectionPossibleValues": [
    //                     {
    //                         "api_key": 100201,
    //                         "api_display_nm": "Patient Status Journey"
    //                     },
    //                     {
    //                         "api_key": 100202,
    //                         "api_display_nm": "place holder"
    //                     }
    //                 ],
    //                 "isSelectionColumnAPI": 100201,
    //                 "isSelectionColumnParameter": [
    //                     "patient_id"
    //                 ],
    //                 "currentStatusPupups": [
    //                     {
    //                         "value": "P",
    //                         "popup": "pending"
    //                     },
    //                     {
    //                         "value": "C",
    //                         "popup": "other_than_pending"
    //                     }
    //                 ],
    //                 "conditional_format": true,
    //                 "condition_reference_column": "case_status",
    //                 "conditional_formatting_value": [
    //                     {
    //                         "col_value": "PENDING",
    //                         "icon": "status",
    //                         "color": "#ffcd4a"
    //                     },
    //                     {
    //                         "col_value": "CANCELLED",
    //                         "icon": "status",
    //                         "color": "#ff4949"
    //                     },
    //                     {
    //                         "col_value": "DISCONTINUED",
    //                         "icon": "status",
    //                         "color": "#ff3da6"
    //                     },
    //                     {
    //                         "col_value": "ACTIVE",
    //                         "icon": "status",
    //                         "color": "#3ac97c"
    //                     },
    //                     {
    //                         "icon": "status",
    //                         "color": "#2e14f0",
    //                         "col_value": "NURSING"
    //                     }
    //                 ],
    //                 "isTextColorCondition": false,
    //                 "isActionIcon": true,
    //                 "isActionPosition": "Right",
    //                 "isActionIconName": "plus",
    //                 "isActionIconColor": "#1363df",
    //                 "isEventsInIcon": true,
    //                 "isSelectionTypeIcon": "Icon Click",
    //                 "iconSelectionPossibleValues": [
    //                     {
    //                         "api_key": 100201,
    //                         "api_display_nm": "Patient Status Journey"
    //                     },
    //                     {
    //                         "api_key": 100202,
    //                         "api_display_nm": "place holder"
    //                     }
    //                 ],
    //                 "isSelectionIconAPI": 100202,
    //                 "isSelectionIconParameter": [
    //                     "patient_id"
    //                 ],
    //                 "arrayIndex": 2
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "alert",
    //                 "api_resp_column_display_nm": "Alerts",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "isActionIcon": false,
    //                 "conditional_format": false,
    //                 "isActionColumn": true,
    //                 "isSelectionTypeColumn": "Alerts",
    //                 "alertSelectionPosibleValues": [
    //                     {
    //                         "col_value": "Status Changes",
    //                         "icon": "status_change",
    //                         "color": "#ff9a3d"
    //                     },
    //                     {
    //                         "col_value": "New Patient",
    //                         "icon": "new_patient",
    //                         "color": "#e34071"
    //                     },
    //                     {
    //                         "col_value": "New Prescriber",
    //                         "icon": "new_prescriber",
    //                         "color": "#3ac97c"
    //                     },
    //                     {
    //                         "col_value": "Refill Too Soon",
    //                         "icon": "refill_alerts",
    //                         "color": "#1363df"
    //                     },
    //                     {
    //                         "col_value": "Delayed Shipment",
    //                         "icon": "delayed_alert",
    //                         "color": "#d11044"
    //                     }
    //                 ],
    //                 "arrayIndex": 3
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_nm",
    //                 "api_resp_column_display_nm": "Patient Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 4,
    //                 "isActionColumn": false,
    //                 "isSelectionTypeColumn": "Alerts",
    //                 "alertSelectionPosibleValues": [
    //                     {
    //                         "col_value": "Status Changes"
    //                     },
    //                     {
    //                         "col_value": "New Patient"
    //                     },
    //                     {
    //                         "col_value": "New Prescriber"
    //                     },
    //                     {
    //                         "col_value": "Refill Too Soon"
    //                     },
    //                     {
    //                         "col_value": "Delayed Shipment"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "gender",
    //                 "api_resp_column_display_nm": "Gender",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "cap",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 5
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "patient_birth_date",
    //                 "api_resp_column_display_nm": "Patient DOB",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": true
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 6
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "prescriber_nm",
    //                 "api_resp_column_display_nm": "Prescriber Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 7
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "territory_nm",
    //                 "api_resp_column_display_nm": "Territory",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 8
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "data_source_name",
    //                 "api_resp_column_display_nm": "Source Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 9
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "payer_nm",
    //                 "api_resp_column_display_nm": "Payer Name",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "init",
    //                 "isAlignment": "left",
    //                 "arrayIndex": 10
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "last_ship_date",
    //                 "api_resp_column_display_nm": "Last Ship Date",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 11
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "next_ship_date",
    //                 "api_resp_column_display_nm": "Next Ship Date",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "date",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 12
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "source_type",
    //                 "api_resp_column_display_nm": "Source Type",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": true
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "isDataType": "string",
    //                 "isTextCase": "cap",
    //                 "isAlignment": "center",
    //                 "arrayIndex": 13
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "ref_source",
    //                 "api_resp_column_display_nm": "Referral Source",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 14
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "case_status",
    //                 "api_resp_column_display_nm": "Case Status",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 15
    //             },
    //             {
    //                 "api_column_list_key": null,
    //                 "api_key": "6001",
    //                 "api_resp_column_nm": "payer_typ",
    //                 "api_resp_column_display_nm": "Payer Type",
    //                 "property_CB": [
    //                     {
    //                         "name": "Add Column",
    //                         "value": false
    //                     },
    //                     {
    //                         "name": " Hidden",
    //                         "value": false
    //                     }
    //                 ],
    //                 "dataType": [
    //                     {
    //                         "name": "String",
    //                         "value": "string"
    //                     },
    //                     {
    //                         "name": "Integer",
    //                         "value": "integer"
    //                     },
    //                     {
    //                         "name": "Date",
    //                         "value": "date"
    //                     }
    //                 ],
    //                 "conditional_Formatting": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = ?",
    //                 "link_col_S3": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     }
    //                 ],
    //                 "link_col_DB": [
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "actions_Column_Clicks": [
    //                     {
    //                         "name": "Column Click",
    //                         "value": "Column Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Column Hover Over",
    //                         "value": "Column Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Alerts",
    //                         "value": "Alerts",
    //                         "sql": "SELECT col_value FROM du_analytics_config.du_ana_col_value_lkp where col_value_typ = 'AL'"
    //                     }
    //                 ],
    //                 "actions_icon_Hovers": [
    //                     {
    //                         "name": "Icon Click",
    //                         "value": "Icon Click",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     },
    //                     {
    //                         "name": "Icon Hover Over",
    //                         "value": "Icon Hover Over",
    //                         "sql": "SELECT api_key, api_display_nm FROM du_analytics_config.du_ana_api_master where api_typ = 'click_col'"
    //                     }
    //                 ],
    //                 "margingLinkCol": [
    //                     {
    //                         "name": "Link Col S3",
    //                         "sql": "SELECT pm.page_nm, CONCAT(du.s3_bucket_prefix,s3_bucket_nm) s3_bucket_nm, CONCAT(fpm.path,'/',fm.file_nm) s3_file_path FROM du_config.du_ana_dashboard_page_master pm, du_config.cl_file_master fm, du_config.cl_s3_bucket_master sbm, du_config.cl_file_path_master fpm, du_config.cl_du_environment du WHERE pm.page_typ = 'link_page' and pm.page_file_key = fm.file_key and fm.path_key = fpm.path_key and pm.s3_bucket_key = sbm.s3_bucket_key"
    //                     },
    //                     {
    //                         "name": "Link Col DB",
    //                         "sql": "SELECT page_nm, work_key, page_key, MAX(version_number) version_number FROM du_analytics_config.du_ana_module_page_config WHERE page_typ = 'link_page' GROUP BY page_key"
    //                     }
    //                 ],
    //                 "bypassFirstTime": true,
    //                 "arrayIndex": 16
    //             }
    //         ],
    //         "list_of_api": {
    //             "api_key": 6001,
    //             "api_widget_typ": "Data Grid-2",
    //             "api_nm": "pending_patient_list",
    //             "api_display_nm": "Pending Patient List",
    //             "api_end_point": "kpi/get-list-of-value"
    //         },
    //         "activity_since": {
    //             "name": "No",
    //             "value": "no"
    //         },
    //         "pagination": {
    //             "name": "No",
    //             "value": "no"
    //         }
    //     }
    // }
}
