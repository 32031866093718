import detailConfig from './detail.config'
import overviewConfig from './overview.config'

export default {
  title: 'Dispense Trend',
  intervals: {
    D: { format: '%d %b %y', tickCount: 'day', labelAngle: -45, labelAlign: 'right' },
    W: { format: '%d %b %y', tickCount: 'week', labelAngle: -45, labelAlign: 'right' },
    M: { format: '%b %Y', tickCount: 'month', labelAngle: -45, labelAlign: 'right' },
    Q: { format: '%b %Y', tickCount: { interval: 'month', step: 3 }, labelAngle: 0, labelAlign: 'center' },
    Y: { format: '%Y', tickCount: 'year', labelAngle: 0, labelAlign: 'center' },
  },
  sourceLegend: {
    id: 'dispense-trend-source-legend',
    view: {
      editable: true,
      minEnabled: 1,
      maxEnabled: 1,
    },
  },
  locationLegend: {
    id: 'dispense-trend-location-legend',
    view: {
      editable: true,
      minEnabled: 1,
      maxEnabled: 1,
    },
  },
  vega: {
    view: {
      spec: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        autosize: 'fit',
        data: [{
          name: 'series',
          values: [],
        }, {
          name: 'tooltip',
          source: 'series',
          transform: [{
            type: 'filter',
            expr: 'toString(datum.date) == toString(indexDate)'
          }],
        }],

        signals: [{
          name: 'width',
          init: 'isFinite(containerSize()[0]) ? containerSize()[0] : 480',
        }, {
          name: 'height',
          init: 'isFinite(containerSize()[1]) ? containerSize()[1] : 310',
        }, {
          name: 'indexDate',
        }, {
          name: 'detailHeight',
          on: [{
            events: { signal: 'height' },
            update: 'height * 0.9 - 70',
          }],
        }, {
          name: 'overviewHeight',
          on: [{
            events: { signal: 'height' },
            update: 'height * 0.1',
          }],
        }, {
          name: 'detailDomain',
        }, {
          name: 'color',
          value: 'black',
        }],

        marks: [detailConfig, overviewConfig],
      }
    }
  }
}