<div class="widget-container" #bulletChartDiv>
     <app-bullet-chart
    [heading]="heading"
    [headerConfig]="this.item.config.widget_header_config"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [data]="data"
    [totalData]="totalData"
    >
    </app-bullet-chart> 
</div>
<!-- *ngIf="isDataLoaded"  -->