<div class="widget-container" #BasicHeatMapCharDiv>
    <app-basic-heatmap-chart
    [heading]="heading"
    [headerConfig]="this.item.config.widget_header_config"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [data]="data"
    [columnDetails]="columnDetails"
    >
    </app-basic-heatmap-chart>
</div>