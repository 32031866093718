import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  Query,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";
import { environment } from "src/environments/environment";
// import * as data from './data.json'

@Component({
  selector: "app-heatmap-chart",
  templateUrl: "./heatmap-chart.component.html",
  styleUrls: ["./heatmap-chart.component.scss"],
})
export class WidgetHeatmapChartComponent implements OnInit, OnChanges {
  static key = 100401;
  
  heading: string = "";
  chartData: any;
  x_axis_column: any;
  color_column: any;
  value_column: any;
  hierarchyLevels: any[] = [];
  colorCodeParam: any[] = [];
  isDataLoaded = false;
  x = false;
  reqSubcription: Subscription[] = [];
  data: any 
  config :any 
  @Input("item") item: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();

  loader:Boolean = true;

  constructor(private filterService: FilterService, private httpClient: HttpClient, private apiService: ApiService) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loader = true;
        this.loadApiData();
      })
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: any): void {
    if (changes.item.currentValue != changes.item.previousValue) {
      this.loadChartParam();
    }
  }

  loadChartParam() {
    this.heading = this.item.config["report_heading"];
    this.x_axis_column =
      this.item.config["x_axis_column"]["api_resp_column_nm"];
    this.color_column = this.item.config["color_column"]["api_resp_column_nm"];
    this.value_column = this.item.config["value_column"]["api_resp_column_nm"];
    this.hierarchyLevels = [
      this.item.config["l1_col"]["api_resp_column_nm"],
      this.item.config["l2_col"]["api_resp_column_nm"],
      this.item.config["l3_col"]["api_resp_column_nm"],
    ];
    this.colorCodeParam = [];
    this.item.config["expected_values"].map((e: any) => {
      if (e["isSelected"] == true)
        this.colorCodeParam.push({
          status: e.from_values,
          description: e.value_desc,
          Hex: e.color,
        });
    });
  }
  loadApiData() {
    let api = this.item.config["list_of_api"] || {};
    this.isDataLoaded = false;
    // For Testing
    // this.filterService.getSampleJsonData('data').subscribe(res=>{
    //   this.isDataLoaded = true
    //       this.chartData = []
    //       this.chartData['colors'] = this.colorCodeParam
    //       if (res.length>0) {
    //           this.chartData['data'] = res
    //       } else {
    //           this.chartData['data'] = []
    //       }
    // })
    // return

    if (api.api_key && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {
      
      // API Payload
      let obj:any = {"api_key" : api.api_key}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          
          if(res!=null) {
            this.onSizeChange.emit(800)
            // console.log('resize')
            this.isDataLoaded = true;
            this.chartData = [];
            this.chartData["colors"] = this.colorCodeParam;
            if (res.length > 0) {
              this.chartData["data"] = res;
            } else {
              this.chartData["data"] = [];
            }
          }
          this.loader = false;
          
        },(err:any)=>{
          console.log(err)
          this.loader = false;
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
      //   if(res!=null) {
      //     this.onSizeChange.emit(800)
      //     // console.log('resize')
      //     this.isDataLoaded = true;
      //     this.chartData = [];
      //     this.chartData["colors"] = this.colorCodeParam;
      //     if (res.length > 0) {
      //       this.chartData["data"] = res;
      //     } else {
      //       this.chartData["data"] = [];
      //     }
      //   }
      // }, (err) => {
      //   console.log(err)
      // }));
    }
  }

  onExpandCollape(val: any) {
    this.onSizeChange.emit(val);
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
