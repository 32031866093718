import { Component, OnInit, OnChanges, SimpleChanges, HostListener, AfterViewInit } from '@angular/core';
import { Subscription, take } from 'rxjs';
import Widget from '../widget';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-widget-card1kpi',
  templateUrl: './card1kpi.component.html',
  styleUrls: ['./card1kpi.component.scss']
})
export class Widget1kpiCardComponent extends Widget implements OnInit, OnChanges, AfterViewInit {
  
  static override key = 100101;
  
  // @Input('config') config: any = {}
  // @Input('item') item: any;
  // @Input('pageKey') pageKey: any;
  
  kpi_1_value: any;
  reqSubcription: Subscription[] = [];
  isOpen: boolean = false;
  infoData: any
  loader: boolean = true;
  cardName: any;
  cardId: any
  presId: any;
  isPortrait = false;

  obj: any = {
    header_alignment: {
      name: "BOTTOM",
      value: "bottom"
    }
  }

  constructor(
    private currency: CurrencyPipe,
  ) {
    super();

    this.subs.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
    // this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
    //   this.loadApiData();
    // }))
  }

  override ngOnInit(): void {
    // super.ngOnInit();

    if (!this.item.config['header_alignment']) {
      Object.assign(this.item.config, this.obj)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.item) {
      if (!this.item.config['icon'])
        this.item.config['icon'] = { name: 'question_circle' }
    }
  }

  getInfoDetails() {
    let data = {
      api_key: this.item.config['kpi_1_value_api']?.api_key || 0,
      widget_key: this.item.config['widgetKey'],
      page_key: this.pageKey,
      report_typ: 'D',
      brand_key: this.filterService.baseQuery?.brand_key
    }
    this.reqSubcription.push(this.dataService.getInfoDetail(data).subscribe((res: any) => {
      this.infoData = res && res[0] ? res[0] : { info_header: "No Info Data Found" }
    }))
    return this.isOpen = !this.isOpen
  }

  getStyle(): string {
    return 'background-color:#9900ff'
  }

  getValue(item: any, value: any) {
    return item ? item[value] : ''
  }

  numbedPipe(value: any) {
    if (value == 'NA') return value
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }

  
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortrait = window.innerWidth < window.innerHeight;
  }

  closeMenu() {
    this.trigger.closeMenu();
  }

  ngAfterViewInit() {
    this.checkOrientation()
  }

  cancel() {
    document.getElementById("setting")?.click()
  }

  loadApiData() {
    this.loader = true;
    let kpi1 = this.item.config['kpi_1_value_api']
    if (kpi1.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj: any = { "api_key": kpi1.api_key } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.subs.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          if (res && res.length > 0) {
            let selectedColumn = this.item.config['kpi_1_value_api_col']['api_resp_column_nm']
            this.kpi_1_value = res[0][selectedColumn]
          } else {
            this.kpi_1_value = ""
          }
          this.loader = false;
        }, (err: any) => {
          console.log('kpi1', err)
          this.loader = false
          this.kpi_1_value = ""
        })
      )
    }
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }
}
