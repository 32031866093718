<div class="widget-container timeCycle" [style.position]="deviceDetectionService.isDesktop ?  findKey ? 'relative' : 'absolute' : 'relative'" [ngClass]="{'hideWidget' : item.config.visible_prop.name == 'hide' || this.data.length==1}" >
    <div class=" d-flex justify-content-between" id='heatMapDiv'>
        <div *ngFor="let item of data" (click)="changeTimeCycle(item.value)" [matTooltipPosition]="'above'"
            [matTooltip]="item.label" [ngClass]="{'highlight': selectedType===item.value}" class="cycleHover">
            {{item.value}}
        </div>
    </div>
</div>
<!-- <div class="timeCycleResponsive sort_by_styles1 d-flex"
    [ngClass]="{ 'hideWidget' : item.config.visible_prop.name == 'hide' }">
    <label for="sortBy" class="sort_by_text mt-2">TC|</label>
    <mat-select class="sort_by_select text-center" style="width: 60% !important;" [(ngModel)]="selectedType"
        (ngModelChange)="changeTimeCycle(item.value)">
        <mat-option *ngFor="let item of data" [value]="item.value" class="dropdown_values">{{item.value}}</mat-option>
    </mat-select>
</div> -->