import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import { FieldType } from "@ngx-formly/core";
import moment from "moment";
import { FilterService } from "src/app/services/filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";
import { AppCustomDatepickerHeaderComponent } from "../timecycle-with-range/app-custom-datepicker-header";
import { Subscription, debounceTime } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { NavigationService } from "src/app/services/navigation.service";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-single-custom-datepicker",
  templateUrl: "./single-custom-datepicker.component.html",
  styleUrls: ["./single-custom-datepicker.component.scss"],
})

export class SingleCustomDatePickerComponent extends FieldType implements OnInit, OnDestroy {

  dynamicSelect= new FormControl();
  opt: any
  valueProp = "_id";
  labelProp = "selectedDate";
  labelPropForQ = "quarter,year";
  label:any;
  fromLabel:any;
  toLabel:any;
  currentField:any;
  parentKey:any="";
  placeholder:any
  dBDefaultData:any

  quarterFromDefault:any
  startFrom:any;
  isRestriction:any;

  quarterFromYear:any;
  quarterFromMonth:any;
  selectionMethod:any;

  onLoadDefault: any;
  onLoadDefaultYear: any;
  onLoadDefaultYearQuarter: any;

  yearFromDefault=new Date().getFullYear()
  currentYear=new Date().getFullYear()
  currentMonth = new Date().getMonth();

  yearFrom!:number;
  yearTo!:number;
  showQuarter:boolean=true;
  showQuarterTo:boolean=true;
  year10yearFrom!:number;
  year10yearTo!:number;
  totalYear:any = []


  selectedFromOption:any;
  selectedToOption:any;
  selectedRange:any
  
  dashboardKey:any

  // ----------------------------------

  selected!: Date | null;

  minFromDate!: Date;
  maxFromDate!: Date | null;
  minToDate!: Date | null;
  maxToDate!: Date;

  dbFromDate:any;
  dbEndDate!:Date | any;
  
  reqSubcription: Subscription[] = [];
  
  constructor(
    private filterService:FilterService, 
    private userActivityService:UserActivityService, 
    private route: ActivatedRoute, 
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private navigationService: NavigationService,
    private dashboardService: DashboardService
  ) {
    super()
  }

  exampleHeader = AppCustomDatepickerHeaderComponent
  dataSourceUrl:any
  defaultModel:any;
  selectedOption:any

  selectedType:any;

  selectedFromdate = new FormControl(new Date());
  selectedTodate = new FormControl(new Date());
  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;

  // From date selection Weekly and Daily
  fromDateChangeDay(type: string, event: MatDatepickerInputEvent<Date>) {
    
    this.minToDate = event.value;
    this.selectedFromdate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'D': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
    
  }
  // From date selection Weekly and Daily
  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    
    this.minToDate = event.value;
    this.selectedFromdate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'W': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
    
  }

  // To date selection Weekly and Daily
  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    
    this.maxFromDate = event.value;
    // this.selectedTodate.patchValue(event.value);

    this.selectedFromdate.patchValue(this.weekPrevious(event.value));
    this.selectedTodate.patchValue(event.value);


    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    sessionStorage.setItem('singleTimeCycleQuarter', JSON.stringify(ApplyDate))

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'W': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
  }
  // To date selection Weekly and Daily
  toDateChangeDay(type: string, event: MatDatepickerInputEvent<Date>) {
    
    this.maxFromDate = event.value;
    this.selectedTodate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'D': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
  }

  myFilter:any = (d: Date): boolean => {
    const day = d?.getDay();

    const dayForEnd = moment(this.dbEndDate).day(); 
    // Prevent Saturday and Sunday from being selected.
    return day == dayForEnd;
  }

  // Enable Only Friday Dates
  filterFridayDates(date: Date | null): boolean {
    if (!date) {
      return false;
    }
    // Check if the day of the week is Friday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    return date.getDay() === 2;
  }

  // Month selection From
  monthFromSelected(event:any, dp:any, input:any) {
    dp.close();
    this.minToDate = event;
    this.selectedFromdate.patchValue(moment(event).startOf('month').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)


    // User State
    let ab = { 'M': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

  }

  // Month Selection To
  monthToSelected(event:any, dp:any, input:any) {
    dp.close();
    this.maxFromDate = event;
    // this.selectedTodate.patchValue(moment(event).endOf('month').toDate());

    this.selectedFromdate.patchValue(moment(event).startOf('month').toDate());
    this.selectedTodate.patchValue(moment(event).endOf('month').toDate());


    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    sessionStorage.setItem('singleTimeCycleQuarter', JSON.stringify(ApplyDate))
    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'M': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

  }

  // From Year Selection
  yearFromSelected(event:any, dp:any, input:any) {
    dp.close();
    this.minToDate = event;
    this.selectedFromdate.patchValue(moment(event).startOf('year').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'Y': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
  }

  //  To Year Selection  
  yearToSelected(event:any, dp:any, input:any) {
    dp.close();
    this.maxFromDate = event;
    // this.selectedTodate.patchValue(moment(event).endOf('year').toDate());

    this.selectedFromdate.patchValue(moment(event).startOf('year').toDate());
    this.selectedTodate.patchValue(moment(event).endOf('year').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    sessionStorage.setItem('singleTimeCycleQuarter', JSON.stringify(ApplyDate))

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

    // User State
    let ab = { 'Y': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })
    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);
  }

  Option: any[] = [{selectedDate : ''}];
  tabId:any;

  // For Quarter Selection
  fromOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];

  toOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];
  brandDateLaunchDate:any;
  

  ngOnInit(): void {

    // dashboard key based on the router for brand start date 
    this.route.params.subscribe((p: any) => {
      
      this.route.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.dashboardKey = decryptedParams["ds_key"];
        this.tabId = params["tab_id"];
      });
    })


    // Get Brand based Date
    this.reqSubcription.push(this.filterService.lastRefreshedDate.subscribe((brand:any)=> {
      if(brand) {
        this.reqSubcription.push(this.dataService.getBrandStartDate(brand).subscribe((data:any)=> {
          if(data!=null) {
            this.minToDate = this.startFriday(data[0].launch_date);
            this.brandDateLaunchDate = data[0].launch_date
          }
        }));
      }
    }))

    this.reqSubcription.push(this.filterService.kpiBreakDownSelectedType.subscribe((res:any)=> {
      this.selectedType = res;
      
      if(this.defaultModel) {
        this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.defaultModel)
      }
    })) 

    // Filter Reset Method using Subject
    this.filterService.formReset.subscribe((res: any) => {

      if(this.selectedType!='Q') {

        if(this.selectedType=='W' || this.selectedType=='D') {
          if(this.selectedType=='D') {
            this.selectedFromdate.patchValue(this.dbFromDate);
            this.selectedTodate.patchValue(this.selectedendFriday(this.dbFromDate));
          } else {
            this.selectedFromdate.patchValue(this.weekPrevious(this.dbEndDate));
            this.selectedTodate.patchValue(this.selectedendFriday(this.dbEndDate));
          }
        } else if(this.selectedType=='M') {
          this.selectedFromdate.patchValue(moment(this.dbFromDate).startOf('month').toDate());
          this.selectedTodate.patchValue(moment().endOf('month').toDate());
        } else if(this.selectedType=='Y') {
          this.selectedFromdate.patchValue(moment(this.dbFromDate).startOf('year').toDate());
          this.selectedTodate.patchValue(moment().endOf('year').toDate());
        }

        let ApplyDate = {
          "from_date" : this.selectedFromdate.value,
          "to_date" : this.selectedTodate.value,
        }

        if(this.currentField.parentKey == "") {
          // Set Value for Formly form 
          this.field.formControl?.setValue(ApplyDate);
        }

        let ab = { [this.key as string]: ApplyDate }
        this.userActivityService.setData({ "selectedFilters": ab })
        this.filterService.selectedItem['timeCycle'][this.key as string] = ApplyDate

        // set Badges
        this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

        sessionStorage.removeItem('singleTimeWithoutQuarterFromAndToSelected');

      } else {

        // Quarter Reset 
        let ApplyDate = {
          "from_date" : moment(this.selectedFromOption.from_date).toDate(),
          "to_date" : moment(this.selectedToOption.to_date).toDate(),
        }

        if(this.currentField.parentKey == "") {
          // Set Value for Formly form 
          this.field.formControl?.setValue(ApplyDate);
        }

        let ab = { [this.key as string]: ApplyDate }
        this.userActivityService.setData({ "selectedFilters": ab })

        sessionStorage.removeItem('singleTimeQuarterFromAndToSelected');
        // set Badges
        this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)
      }
    })

    this.opt = this.field.templateOptions || {};
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.fromLabel = this.opt.fromLabel
    this.toLabel = this.opt.toLabel
    this.currentField = this.field
    this.dataSourceUrl = this.opt.dataSourceUrl

    this.quarterFromYear = this.opt.quarterYear
    this.quarterFromMonth = this.opt.quarterMonth
    this.selectionMethod = this.opt.selectionMethod

    this.onLoadDefault = this.opt.onLoadDefault
    this.onLoadDefaultYear = this.opt.onLoadDefaultYear
    this.onLoadDefaultYearQuarter = this.opt.onLoadDefaultYearQuarter

    this.isRestriction = this.opt.isRestriction

    if(this.opt.selectedPath=='C') {
      this.selectedType=this.opt.time_cycle
      this.filterService.report_type = this.opt.time_cycle
    }
    this.startFrom = this.opt.startFrom || 'brand'
    
    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
        
        if (this.parentControlSubscription) {
          this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
        }

        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];
          this.defaultModel = val
          
          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }

          if(this.fndParent()) {
            if (this.dataSourceUrl == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.defaultModel)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
    }
  }

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }
  
  // Load APi
  loadApiData(method:string, url:string,key:string,data:any) {
    
    // If SelectedType
    if(this.selectedType) {
      this.reqSubcription.push(this.filterService.loadData(method,url,key,data, this.dashboardService.dashboardKey, this.selectedType, this.dashboardService.getPageKey[Number(this.tabId)]).subscribe((data:any)=>{
        if(data!=null) {
          let dbFrom = moment(data[0]?.from_dt)
          
          if(this.startFrom!='db') {
            dbFrom = moment(this.brandDateLaunchDate)
          } else {
            dbFrom = moment(data[0]?.from_dt)
          }
          this.minToDate = this.startFriday(dbFrom)

          this.dbFromDate = moment(dbFrom).toDate();
          this.dbEndDate = moment(data[0]?.to_dt).toDate();

          // this.minFromDate = moment(dbFrom).toDate();
          // this.minToDate = moment(dbFrom).toDate();
          this.maxFromDate = moment().toDate();
          this.maxToDate = moment().toDate();
          
          // Selected Type Day and Week
          if(this.selectedType=='D') {

              let s = new FormControl()
              let y = new FormControl()              
              s.patchValue(this.dbFromDate);
              y.patchValue(moment().toDate())

              // defulat data
              let ApplyDates = {
                "from_date" : s.value,
                "to_date" : y.value,
              }
              // Defalut Load for reset default 
              sessionStorage.setItem('singleTimeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

              // UserState
              let userStateData: any = this.userActivityService.getDataTimeCycleSingle()
               
              let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
              let findData: any = updatesObj.find((o: any) => o.key == 'D')
              if(findData) {
                this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
                this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
              } else {
                this.selectedFromdate.patchValue(this.dbFromDate);
                this.selectedTodate.patchValue(moment().toDate());
              }

              let ApplyDate = {
                "from_date" : this.selectedFromdate.value,
                "to_date" : this.selectedTodate.value,
              }
              
              // Reset selected For Quarter Selection
              const serializedData:any = JSON.stringify(ApplyDate);
              sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

          }else if(this.selectedType=='W') {

              // this.minToDate = this.startFriday(dbFrom);
              this.maxToDate = this.dbEndDate;

              let s = new FormControl()
              let y = new FormControl()
              s.patchValue(this.weekPrevious(this.dbEndDate));
              y.patchValue(this.selectedendFriday(this.dbEndDate))

              // defulat data
              let ApplyDates = {
                "from_date" : s.value,
                "to_date" : y.value,
              }
              // Defalut Load for reset default 
              sessionStorage.setItem('singleTimeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

              // // UserState
              let userStateData: any = this.userActivityService.getDataTimeCycleSingle()
               
              let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
              let findData: any = updatesObj.find((o: any) => o.key == 'W')
              if(findData) {
                this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
                this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
              } else {
                this.selectedFromdate.patchValue(this.weekPrevious(this.dbEndDate));
                this.selectedTodate.patchValue(this.selectedendFriday(this.dbEndDate));
              }

              let ApplyDate = {
                "from_date" : this.selectedFromdate.value,
                "to_date" : this.selectedTodate.value,
              }

              // // Reset selected For Quarter Selection
              const serializedData:any = JSON.stringify(ApplyDate);
              sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

          // Selected Type Month
          } else if(this.selectedType=='M') {

            let s = new FormControl()
            let y = new FormControl()

            s.patchValue(moment(this.dbEndDate).startOf('month').toDate());
            y.patchValue(moment(this.dbEndDate).endOf('month').toDate())
            // defulat data
            let ApplyDates = {
              "from_date" : s.value,
              "to_date" : y.value,
            }
            // // Defalut Load for reset default 
            sessionStorage.setItem('singleTimeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

            // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycleSingle()
             
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'M')
            if(findData) {
              this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
              this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
            } else {
              this.selectedFromdate.patchValue(moment(this.dbEndDate).startOf('month').toDate());
              this.selectedTodate.patchValue(moment(this.dbEndDate).endOf('month').toDate());
            }

            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }

            // // Reset selected For Quarter Selection
            const serializedData:any = JSON.stringify(ApplyDate);
            sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

          // Selected Type Year
          } else if(this.selectedType=='Y') {
            
            let s = new FormControl()
            let y = new FormControl()
            s.patchValue(moment(this.dbEndDate).startOf('year').toDate());
            y.patchValue(moment(this.dbEndDate).endOf('year').toDate())
            // defulat data
            let ApplyDates = {
              "from_date" : s.value,
              "to_date" : y.value,
            }
            // Defalut Load for reset default 
            sessionStorage.setItem('singleTimeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

            // // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycleSingle()
             
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'Y')
            if(findData) {
              this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
              this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
            } else {
              this.selectedFromdate.patchValue(moment(this.dbEndDate).startOf('year').toDate());
              this.selectedTodate.patchValue(moment(this.dbEndDate).endOf('year').toDate());
            }

            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }

            // Reset selected For Quarter Selection
            const serializedData:any = JSON.stringify(ApplyDate);
            sessionStorage.setItem('singleTimeWithoutQuarterFromAndToSelected', serializedData);

          // Selected Type Quarter
          } else if (this.selectedType=='Q') {
            
            this.yearFromDefault = moment(this.dbFromDate).year();
            let quarterFromDefault = moment(this.dbFromDate).month()
            
            let yearDefaultLoad = moment(this.dbEndDate).year(); 
            let monthly = moment(this.dbEndDate).month();
            
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let monthDefaultLoad = monthNames[monthly];

            // 2 Quarter convertion based on the month
            if(quarterFromDefault<=2) {
              this.quarterFromDefault = 'Q1'
            } else if (quarterFromDefault<=5) {
              this.quarterFromDefault = 'Q2'
            } else if (quarterFromDefault<=8) {
              this.quarterFromDefault = 'Q3'
            } else if (quarterFromDefault<=11) {
              this.quarterFromDefault = 'Q4'
            }


            // From DropDown and To DropDown
            this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFromDefault)]
            this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFromDefault)]

            this.toOption= [...this.addingQuarterwithYear(this.toOption, yearDefaultLoad)]
            this.toOption= [...this.dateFormatQuarter(this.toOption, yearDefaultLoad)]

            // Selected From and To Quarter
            this.yearFrom = this.yearFromDefault
            this.filterService.yearDefault = this.yearFromDefault
          
            this.yearTo = this.currentYear
            this.filterService.yearToDefault = this.currentYear

            let selectedFromOption = this.fromOption.find((res: any) => res.value == this.quarterFromDefault);
            let selectedToOption = this.toOption.find((res:any)=> res.months?.toString().includes(monthDefaultLoad.toString()));
            
            // Reset Default For Quarter Selection
            let SetData = {"from_date" : selectedToOption['quarter,year'],"to_date" : selectedToOption['quarter,year']}
            const serializedData:any = JSON.stringify(SetData);
            sessionStorage.setItem('singleTimeQuarterFromAndToDefault', serializedData);


            let fieldKey:any = this.key as string

          //   // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycleSingle()
           
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'Q')

            if(findData) {
              // From
              if(findData.value?.from) {
                this.yearFrom = findData.value?.from.year
                this.filterService.yearDefault = findData.value?.from.year

                this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
                this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
                this.selectedFromOption = this.fromOption.find((res: any) => res.value == findData.value?.from.value);
              }

              // To
              if (findData.value?.to) {
                this.yearTo = findData.value?.to.year
                this.filterService.yearToDefault = this.yearTo

                this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
                this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
                this.selectedToOption = this.toOption.find((res:any)=> res.value == findData.value?.to.value);
              } 
            } else {
              this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.quarterFromDefault);
              this.selectedToOption = this.toOption.find((res:any)=> res.months?.toString().includes(monthDefaultLoad.toString())); 
            }            
            this.cdr.detectChanges()


            // When onclose the filter
            let selected:any = {
              from : this.selectedToOption,
              to : this.selectedToOption
            }
            sessionStorage.setItem('singleTimeCycleQuarter', JSON.stringify(selected))

            // From Quarter Default between gap should be disabled
            this.fromOption = this.defaultfromDisabled(this.fromOption)
            // to Quarter Default between gap should be disabled
            this.toOption = this.defaultToDisabled(this.toOption)

            // Reset selected For Quarter Selection
            let resetData = {
              "from_date" : this.selectedToOption['quarter,year'],
              "to_date" : this.selectedToOption['quarter,year'],
            }
            const serializedData1:any = JSON.stringify(resetData);
            sessionStorage.setItem('singleTimeQuarterFromAndToSelected', serializedData1);

          }
          this.cdr.detectChanges();

          // If Not in Quarter
          if(this.selectedType!='Q') {
            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }
            // Set Value for Formly form 
            this.field.formControl?.setValue(ApplyDate);
            // set Badges
            this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

          // If In Quarter
          } else {

            // Apply Date for Data base through the formly form
            let ApplyDate = {
              "to_date" : moment(this.selectedToOption.to_date).toDate(),
              "from_date" : moment(this.selectedToOption.from_date).toDate(),
            }
            // Set Value for Formly form 
            this.field.formControl?.setValue(ApplyDate);
            // set Badges
            this.filterService.setBadgeValue(this.key as string,moment(this.selectedToOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

          }
          this.cdr.detectChanges();
        }
  
      }))
    }
  }

  defaultfromDisabled(data:any) {
    let monFrom = false
    data.forEach((res:any)=> {
        if(res.value == this.quarterFromDefault) {
          monFrom = true
        } else {
          if(!monFrom) {
            Object.assign(res,
              {"disabled": true})
          }
        }
      })
      monFrom = false
      return data
  }

  defaultToDisabled(data:any) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonthIndex = moment(this.currentMonth).month();
    let month = monthNames[currentMonthIndex];

    let mon = false
    data.forEach((res:any)=> {
      if(res.months?.toString().includes(month.toString())) {
        mon = true
      } else {
        if(mon) {
          Object.assign(res,
            {"disabled": true})
        }
      }
    })
    mon = false
    return data
  }

  selectedendFriday(endDate: Date) {

    const currentDate = moment(endDate);
    // Define the target day (0 for Sunday, 1 for Monday, 2 for Tuesday, and so on)
    const targetDay = moment(this.dbEndDate).day(); // For example, 0 represents Sunday

    // Calculate the difference in days between the current day and the target day
    const daysUntilTargetDay = (targetDay - currentDate.day() + 7) % 7;

    // Add the calculated days to the current date to find the next occurrence of the target day
    // const nextOccurrence = currentDate.add(daysUntilTargetDay, 'days');
    let nextOccurrence:any = currentDate.clone().add(daysUntilTargetDay, 'days').toDate();

    return nextOccurrence

    // const currentDates = moment(endDate).toDate();
    // return currentDates;
  }

  // endFriday() {
  //   // current Week Selection To date
  //   const currentDates = moment();
  //   // const currentDates = moment(); // Get current date using moment
  //   let daysUntilFriday: any = currentDates.day(); // Calculate days until Friday

  //   if (daysUntilFriday <= 0) {
  //     daysUntilFriday += 7; // If today is Friday or later, add 7 days
  //   }
  //   let nextFriday:any = currentDates.clone().add(daysUntilFriday, 'days').toDate();
  //   // this.maxFromDate = nextFriday;
  //   // this.maxToDate = nextFriday;

  //   return nextFriday
  // }


  endFriday(end?:any) {
     
    // current Week Selection To date
    const currentDates = moment();
    // const currentDates = moment(); // Get current date using moment
    let daysUntilFriday: any = currentDates.day(); // Calculate days until Friday

    if (daysUntilFriday <= 0) {
      daysUntilFriday += 7; // If today is Friday or later, add 7 days
    }
    // let nextFriday:any = currentDates.clone().add(daysUntilFriday, 'days').toDate();
    // this.maxFromDate = nextFriday;
    // this.maxToDate = nextFriday;

    let nextOccurrence:any = currentDates.clone().add(daysUntilFriday, 'days').toDate();

    return nextOccurrence
  }

  startFriday(dbFrom:any) {
    
    const currentDates = moment(dbFrom);

    // current Week Selection From date
    let daysUntilFridayFrom: any = 7 + currentDates.day(); // Calculate days until Friday

    if (daysUntilFridayFrom <= 0) {
      daysUntilFridayFrom += 7; // If today is Friday or later, add 7 days
    }
    // let nextFridayFrom:any = currentDates.clone().subtract(daysUntilFridayFrom, 'days').toDate();
    let nextFridayFrom:any = currentDates.toDate();
    // this.minFromDate = nextFridayFrom;
    // this.minToDate = nextFridayFrom;

    return nextFridayFrom
  }

  // startFriday(dbFrom:any) {
  //   // current Week Selection From date
  //   let daysUntilFridayFrom: any = 5 - moment(dbFrom).day(); // Calculate days until Friday

  //   if (daysUntilFridayFrom <= 0) {
  //     daysUntilFridayFrom += 7; // If today is Friday or later, add 7 days
  //   }
  //   let nextFridayFrom:any = moment(dbFrom).clone().add(daysUntilFridayFrom, 'days').toDate();
  //   // this.minFromDate = nextFridayFrom;
  //   // this.minToDate = nextFridayFrom;

  //   return nextFridayFrom
  // }

  weekPrevious(dbFrom:any) {
    const date = this.selectedendFriday(dbFrom)
    return moment(date).subtract(6,'d').toDate();
  }

  OptionLoad() {
    let array:any = [{'selectedDate':''}]
    // array[0]['selectedDate'] = moment(this.selectedFromDate).format('MM/DD/YYYY') + ' - ' +  moment(this.selectedToDate).format('MM/DD/YYYY')
    return array
  }

  defaultSelectedFrom:any;
  defaultSelectedTo:any;

  // From Year Selection
  changeYear(year:any) {
     
    this.yearFrom=year || this.yearFromDefault;
    this.fromOption = [...this.changeYearWithQuarterFrom(this.fromOption)]
    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
    this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.selectedFromOption.value)
  }

  changeShowQuarter(){
    this.showQuarter=!this.showQuarter
    if (!this.showQuarter)
      this.year10yearFrom=this.yearFrom?12*Math.floor(this.yearFrom/12):12*Math.floor(this.yearFromDefault/12)
  }

  // From Year Selection
  changeYearTo(year:any) {
     
    this.yearTo=year || this.currentYear;
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
    this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
    this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
    this.selectedToOption = this.toOption.find((res:any)=> res.value==this.selectedToOption.value)
  }

  changeShowQuarterTo(){
    this.showQuarterTo=!this.showQuarterTo
    if (!this.showQuarterTo)
      this.year10yearTo=this.yearTo?12*Math.floor(this.yearTo/12):12*Math.floor(this.currentYear/12)
  }

  // applyFilter(ctrl:any) {

  //   let selected:any = {
  //     from : this.selectedFromOption,
  //     to : this.selectedToOption
  //   }
  //   sessionStorage.setItem('TimeCycleQuarter', JSON.stringify(selected))

  //   let ApplyDate = {
  //     "to_date" : moment(this.selectedToOption.to_date).toDate(),
  //     "from_date" : moment(this.selectedFromOption.from_date).toDate(),
  //   }

  //   // Set Value for Formly form 
  //   this.field.formControl?.setValue(ApplyDate);

  //   // User State
  //   let ab = { 'Q': {"from" : this.selectedFromOption, "to" : this.selectedToOption}}
  //   this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })


  //   // Reset selected For Quarter Selection
  //   let SetData = {
  //     "from_date" : this.selectedFromOption['quarter,year'],
  //     "to_date" : this.selectedToOption['quarter,year'],
  //   }
  //   const serializedData:any = JSON.stringify(SetData);
  //   sessionStorage.setItem('timeQuarterFromAndToSelected', serializedData);

  //   // set Badges
  //   this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeSingleSelection",'','',this.selectedType)

  //   ctrl.close()
  // }
  
  applyToFilter(ctrl:any) {

    let selected:any = {
      from : this.selectedToOption,
      to : this.selectedToOption
    }
    sessionStorage.setItem('singleTimeCycleQuarter', JSON.stringify(selected))

    let ApplyDate = {
      "from_date" : moment(this.selectedToOption.from_date).toDate(),
      "to_date" : moment(this.selectedToOption.to_date).toDate(),
    }

    // Set Value for Formly form 
    this.field.formControl?.setValue(ApplyDate);

    // User State
    let ab = { 'Q': {"from" : this.selectedToOption, "to" : this.selectedToOption}}
    this.userActivityService.setDataTimeCycleSingle({ "selectedFilters": ab })


    // Reset selected For Quarter Selection
    let SetData = {
      "from_date" : this.selectedToOption['quarter,year'],
      "to_date" : this.selectedToOption['quarter,year'],
    }
    const serializedData:any = JSON.stringify(SetData);
    sessionStorage.setItem('singleTimeQuarterFromAndToSelected', serializedData);

    // this.filterService.selectedItem['timeCycle'][this.key as string] = ApplyDate

    // set Badges
    this.filterService.setBadgeValue(
      this.key as string,
      moment(this.selectedToOption.from_date).format('MM/DD/YY'),
      moment(this.selectedToOption.to_date).format('MM/DD/YY'),
      ApplyDate,
      "timeSingleSelection",
      '',
      '',
      this.selectedType
    )

    ctrl.close()
  }



  changeYearWithQuarterFrom(data:any) {

    let yearDefault = new Date().getFullYear();
    let monthDefault = new Date().getMonth();

    // 2021 <= 2021 or 2022 or 2023
    if(this.yearFrom <= this.selectedToOption.year) {

      // Default Year
      if(this.yearFromDefault == this.yearFrom) {
        let mon = false
        data.forEach((res:any)=> {
          if(res.value == this.quarterFromDefault) {
            mon = true
          } else {
            if(!mon) {
              Object.assign(res,{"disabled": true})
            } else {
              Object.assign(res,{"disabled": false})
            }
          }
        })
        mon = false
        
        // From Year to Selected To Year
      } else if (this.yearFrom == this.selectedToOption.year) {
        let mon = false
        data.forEach((res:any)=> {
          // Q2 == Q3
          if(res.value == this.selectedToOption.value) {
            Object.assign(res,{"disabled": false})
            mon = true
          } else {
            if(!mon) {
              Object.assign(res,{"disabled": false})
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
        })
        mon = false
        
      } else if (this.yearFrom < this.selectedToOption.year) {
        data.forEach((res:any)=> {
          Object.assign(res, {"disabled": false})
        })
      }

     // 2023 > 2022 
    } else if(this.yearFrom > this.selectedToOption.year) {
      data.forEach((res:any)=> {
        Object.assign(res, {"disabled": true})
      })
    }

    return data
  }

  // year quarter change
  changeYearWithQuarterTo(data:any) {
    debugger
    if(this.yearFromDefault == this.yearTo) {
      let compWithFrom = false
      data.forEach((res:any)=> {
        if(res.value == this.quarterFromDefault) {
          Object.assign(res,{"disabled": false})
          compWithFrom = true
        } else {
          if(!compWithFrom) {
            Object.assign(res,{"disabled": true})
          } else {
            Object.assign(res,{"disabled": false})
          }
        }
      })
    } else {
      if(this.yearFromDefault < this.yearTo) {
        let yearToDefault = new Date().getFullYear();
        let quarterIndex = Math.floor(new Date().getMonth() / 3) + 1;
        let quarterToDefault = `Q${quarterIndex}`;
        
        if(this.yearTo == yearToDefault) {
          let compWithFrom = false;
          data.forEach((res:any)=> {
            if(res.value == quarterToDefault) {
              Object.assign(res,{"disabled": false})
              compWithFrom = true
            } else {
              if(!compWithFrom) {
                Object.assign(res,{"disabled": false})
              } else {
                Object.assign(res,{"disabled": true})
              }
            }
          })
        } else {
          data.forEach((res:any)=> {
            Object.assign(res, {"disabled": false})
          })
        }
        
      } else {
        data.forEach((res:any)=> {
          Object.assign(res, {"disabled": true})
        })
      }
    }

    
    return data
  }

  addingQuarterwithYear(myData:any, year:any) {
    myData.forEach((res:any) => Object.assign(res, 
      {"quarter,year": res.value + ' ' + year}
    ));
    return myData
  }

  dateFormatQuarter(arrData:any, year:any) {
    arrData.forEach((res:any)=> {
      if(res.value == 'Q1') {
        Object.assign(res,
          {"from_date": year+'-'+'01'+'-'+'01'},
          {"to_date": year+'-'+'03'+'-'+'31'},{"year": year})
      } else if (res.value == 'Q2') {
        Object.assign(res,
          {"from_date": year+'-'+'04'+'-'+'01'},
          {"to_date": year+'-'+'06'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q3') {
        Object.assign(res,
          {"from_date": year+'-'+'07'+'-'+'01'},
          {"to_date": year+'-'+'09'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q4') {
        Object.assign(res,
          {"from_date": year+'-'+'10'+'-'+'01'},
          {"to_date": year+'-'+'12'+'-'+'31'},{"year": year})
      }
    })

    return arrData
  }


  closeCondition = false;

  onClose() {

    let selected:any = sessionStorage.getItem('singleTimeCycleQuarter');
    let previousFrom = JSON.parse(selected)

    if(previousFrom.from['quarter,year'] != this.selectedFromOption['quarter,year']) {

      this.yearFrom = previousFrom.from.year

      this.fromOption = [...this.changeYearWithQuarterFrom(this.fromOption)]
      this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
      this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
      this.selectedFromOption = this.fromOption.find((res: any) => res.value == previousFrom.from.value)
    }
  }

  onCloseTo() {

    let selected:any = sessionStorage.getItem('singleTimeCycleQuarter');
    let previousFrom = JSON.parse(selected)

    if(previousFrom) {
      if(previousFrom.to['quarter,year'] != this.selectedToOption['quarter,year']) {
  
        this.yearTo = previousFrom?.to?.year
  
        this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
        this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
        this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
        this.selectedToOption = this.toOption.find((res: any) => res.value == previousFrom.to.value)
      }
    }
  }

  onOpenTo() {
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
  }
  
  onOpen() {
    this.fromOption= [...this.changeYearWithQuarterFrom(this.fromOption)]
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
    }
  }
}
