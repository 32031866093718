import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";
import { NewFilterService } from "src/app/services/new-filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";

@Component({
  selector: "app-line-chart-card",
  templateUrl: "./line-chart-card.component.html",
  styleUrls: ["./line-chart-card.component.scss"],
})
export class WidgetLineChartComponent implements OnInit, OnDestroy {
  static key = 100402;
  
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("lineCharDiv", { static: true }) lineCharDiv!: ElementRef;
  config: any;
  data: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  sourceName: any;
  constructor(
    private filterService: FilterService,
    private newFilterService: NewFilterService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private userActivityService: UserActivityService
  ) {
    this.route.params.subscribe((p: any) => {
      // this.sourceName =p["sourceName"];
      // if(this.sourceName){
      //   this.loadApiData(this.sourceName)
      // }else{
      // }
    });
    
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      }),
      this.newFilterService.showBy.subscribe((value: any) => {
        this.loadApiData(value);
        
      })
    );
  }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.lineCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
    this.config = {
      colours: {
        Biologics: "#8C23E6",
        Onco360: "#E223E6",
        Amber: "#FFCD4A",
        RxCrossroads: "#645DD7",
        TEST1: "gold",
        TEST2: "black",
        TEST3: "purple",
        TEST4: "orange",
      },
      period: "weekly",
      xAxisLabel: this.item.config["x_axis"]["api_resp_column_display_nm"],
      yAxisLabel: this.item.config["y_axis"]["api_resp_column_display_nm"],
      xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
      yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
      value_column: this.item.config["value_column"]["api_resp_column_nm"],
      secondary_value_column:
        this.item.config["secondary_value_column"]?.["api_resp_column_nm"] ||
        null,
        show_by_ndc:this.item.config?.["show_by_ndc"]?.["api_resp_column_nm"]|| undefined,
      displayType: "value",
    };
  }

  loadApiData(showby?: string) {
    let api = this.item.config["list_of_api"] || {};
    
    if(this.filterService.isEmptyObject(this.filterService.baseQuery)==false) {
      let obj:any = {"api_key" : api.api_key }

      let userState: any = this.userActivityService.getData();
      let showByVar =userState['showBy'+ this.item.config.showby_name]
      obj["report_typ"] = (this.filterService.showByKey==showByVar &&showByVar!=undefined)? this.filterService.showByKey : (showByVar ? showByVar:(this.filterService.defaultReport_type?this.filterService.defaultReport_type:this.filterService.report_type) );
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      console.log( 'line chrat',query["report_typ"])
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.isDataLoaded = true;
          this.data = (res == null || res == undefined ) ? [] : res;
        }, (err: any) =>{
          this.data = [];
          console.log(err)
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
      //   this.isDataLoaded = true;
      //   this.data = (res == null || res == undefined ) ? [] : res;
      // }, (error: any) => {
      //   this.data = [];
      //   console.log(error)
      // }));
    }
  }
  
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
