<div id="SmallBarChartDiv"></div>
<div style="display: flex;font-size: 17px;justify-content: center;font-weight: 600;"><span>{{config[0].period}}</span></div>

<div #smallBarChart>
  <div id="d3SBarTooltip">
    <div *ngIf="isTooltip &&  myType=='groupFullName'" style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData}}</div>
    <div  *ngIf=" isTooltip && myType!='groupFullName'" class="container chart_tooltip">
    
      <div class="row chart_tooltip_header">
        <div class="col-12 d-flex flex-column mt-1 mb-1">
          <div class="chart_tooltip_header_heading">
         {{ mytooltipData?.[0]['name']}}
          </div>
        </div>
      </div>


      <div class="mt-2 mb-5" *ngIf="height > 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
        <div class="row" >
          <div class="col-12 d-flex m-auto  align-items-center">
            <div>
              <div class="tooltip_outlay" [style.background]="mytooltipData?.[0].fill"></div>
            </div>
            <div class="chart_tooltip_body_data ml-2"> {{ "No of Patients:"}} {{ numbedPipe(mytooltipData?.[0].value )  }}  </div>
          </div>
        </div>

      

      </div>
      <div class="mt-2 mb-5" *ngIf="height < 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">


        <div class="row" >
          <div class="col-12 d-flex m-auto  align-items-center pb-1">

            <div>
              <div class="tooltip_outlay" [style.background]="mytooltipData?.[0].fill"></div>
            </div>
            <div class="chart_tooltip_body_data ml-2 mb-2">{{  ("No of Patients:" + numbedPipe(mytooltipData?.[0].value ) )}}   </div>

          </div>
        </div>

     

      </div>

    </div>
  </div>
</div>

