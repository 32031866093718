import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public apiBaseUrl: any
  executionUrl = "kpi/get-execution-details"

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiBaseUrl = environment.apiBaseUrl
  }

  login(data: any) {
    return this.httpClient.post(this.apiBaseUrl + '/api/user-validation', data);
  }
  getuserName(data: any) {
    return this.httpClient.post(this.apiBaseUrl + '/api/validate-pwd-key', data);
  }

  forgotPassowrd(data: any) {
    const obj = {
      user_id: data
    }
    return this.httpClient.post(this.apiBaseUrl + '/api/send-pwd-confirmation', obj);
  }

  getDoc(url: string): Observable<any> {
    return this.httpClient.get(`assets/json/${url}.json`)
  }

  getlogos(): Observable<any> {
    let endPoint: any = 'api/get-logos'
    return this.get(endPoint);
  }

  get(url: string): Observable<any> {
    return this.httpClient.get(`${this.apiBaseUrl}/${url}`)
  }

  post(url: string, data: any): Observable<any> {
    
    // Employee key and Client key
    if(this.getClientKey() && this.getEmpKey()) {
      let query: any = {
        "client_key": this.getClientKey(),
        "employee_key": this.getEmpKey()
      }
      Object.assign(data, query)
    }

    return this.httpClient.post(`${this.apiBaseUrl}/${url}`, data)
  }

  getEmpKey() {
    let emp:any = sessionStorage.getItem("employee_key");
    let employee_key = JSON.parse(emp)
    if(!employee_key) return false 
    return employee_key?._value;
  }

  getClientKey() {
    let client_key: any = sessionStorage.getItem("client");
    if(!client_key) return false
    let value = JSON.parse(client_key);
    return value?.[0]?.client?.client_key || null
  }

  alertKPIPost(url: string, data: any): Observable<any> {
    
    // Employee key and Client key
    if(this.getClientKey() && this.getEmpKey()) {
      let query: any = {
        "client_key": this.getClientKey(),
        "employee_key": this.getEmpKey()
      }
      Object.assign(data, query)
    }

    return this.httpClient.post(`${this.apiBaseUrl}/${url}`, data)
  }
  
  postBlob(url: string, data: any): Observable<any> {
    
    // Employee key and Client key
    if(this.getClientKey() && this.getEmpKey()) {
      let query: any = {
        "client_key": this.getClientKey(),
        "employee_key": this.getEmpKey()
      }
      Object.assign(data, query)
    }

    return this.httpClient.post(`${this.apiBaseUrl}/${url}`, data, {
      responseType: 'blob',
    })
  }

  alertKPIGet(url: string): Observable<any> {
    return this.httpClient.get(`${this.apiBaseUrl}/${url}`)
  }
}
