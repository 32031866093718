import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FilterService } from 'src/app/services/filter.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-basic-heatmap-chart',
  templateUrl: './basic-heatmap-chart.component.html',
  styleUrls: ['./basic-heatmap-chart.component.scss']
})
export class BasicHeatmapComponent implements OnInit, OnChanges {

  @ViewChild('lineChartContainer', { static: true }) lineChartContainer!: ElementRef
  @Input('item') item: any
  @Input('data') data: any
  @Input('columnDetails') columnDetails: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @Input('pageKey') pageKey: any
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  BasicHeatmapChartData: any;
  props: any;
  divId: any = "BasicHeatMapChartDiv";
  iconList: any
  renderLinePopup: boolean = false
  isActive = false;
  dataTurn: any
  tooltip = false
  initiateChart: boolean = false
  mytooltipData:any
  constructor(private filterService: FilterService, public deviceDetectionService: DeviceDetectionService) {
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }


//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  closeFullscreen(): void {
    this.props.chartHeight = 370
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  ngAfterViewInit(): void {
    document.getElementById('BasicHeatMapChartDiv')?.addEventListener("scroll", (event: any) => {
      // console.log(event,'ee')
      this.scrollTop = event?.target?.scrollTop
    })
  }

  scrollTop:any =0

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
    
    // console.log(myType,this.scrollTop,'type')
    this.mytooltipData = myData
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
 
    if (this.dataTurn < 250) {
      d3.select("#d3fullTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY + 30) - this.scrollTop + 'px')
        .style('right', (this.dataTurn + 20) + 'px')
        .style('left', 'unset')
        .style('bottom', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select("#d3fullTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY + 30) - this.scrollTop + 'px')
        .style('left', (myX+20) + 'px')
        .style('right', 'unset')
        .style('bottom', 'unset')
    }
  } 
  private hideTooltip(myType: any): void {

    d3.select("#d3fullTooltip")
      .style('visibility', 'hidden');
  }

  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue != changes['data']?.previousValue && this.initiateChart) {
      this.getBasicHeatmapChartData();
    }
  }

//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const legendSvg = d3.select('#' + myClass)
      .select("#legendDiv")
      .append('svg')
      .attr('id', 'svg_legendDiv' + myClass)
      .attr("width",1260)
      .style('background-color', 'white');

    const axisSvg = d3.select('#' + myClass)
      .select("#leftAxisDiv")
      .append('svg')
      .attr('id', 'svg_leftAxisDiv' + myClass)
      .style('background-color', 'white');

    const chartSvg = d3.select('#' + myClass)
      .select("#chartDiv")
      .append('svg')
      .attr('id', 'svg_chartDiv' + myClass)
      .style('background-color', 'white');

    const column1 = axisSvg.append("g").attr("id", "column1Group");
    column1.append("text").attr("id", "yNameTitle");
    column1.append("circle").attr("id", "arrowLeftCircle1");
    column1.append("text").attr("id", "arrowLeftText1");

    const column2 = axisSvg.append("g").attr("id", "column2Group");
    column2.append("text").attr("id", "yTypeTitle");
    column2.append("circle").attr("id", "arrowLeftCircle2");
    column2.append("text").attr("id", "arrowLeftText2");

    axisSvg.append("line").attr("id", "yColumnDivider");
    axisSvg.append("line").attr("id", "topAxisLine");
    axisSvg.append("line").attr("id", "topChartLine");

    const defs = axisSvg.append('defs');

    defs.append('clipPath').attr('id', 'column1Clip' + myClass)
      .append('rect').attr('id', 'column1ClipRect' + myClass);

    defs.append('clipPath').attr('id', 'column2Clip' + myClass)
      .append('rect').attr('id', 'column2ClipRect' + myClass);

    chartSvg.append("line").attr("id", "yColumnRight");
    chartSvg.append("line").attr("id", "topAxisLine");
    chartSvg.append("line").attr("id", "topChartLine");
    chartSvg.append("line").attr("id", "topAxisTopLine");
    chartSvg.append("g").attr("id", "sourceAxis").attr("class", "axis");
    this.initiateChart = true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const axisSvg: any = d3.select('#svg_leftAxisDiv' + myClass);
    const chartSvg: any = d3.select('#svg_chartDiv' + myClass);
    const legendSvg: any = d3.select('#svg_legendDiv' + myClass);
    const legendHeight = 20;
    let width: any = legendSvg.node().getBoundingClientRect().width - 35;
    let height = 0;
    const margins = { leftColumn: 180,  left: 0, right: 0, top: 61, bottom: 20};
    let sourceGroups: any = new Set();
    let columnDates: any = new Set();
    const dateColumnWidth = 90;
    const groupHeight = 32;
    let yVals: any = new Set();
    const yLabels: any = {};
    const heatData: any = [];
    const colorScale = d3.scaleThreshold().domain(myChart.props.thresholds).range(myChart.props.colors);
    const disableSourceYCombo: any = []
    margins.left = (margins.leftColumn * 2);
      // date Format
      const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b, %Y", "Q":"Q%q, %Y", "Y": "%Y"};
      const timePeriod = this.props.period;
      // const xTickFormat = d3.timeFormat(tickFormat[timePeriod]);
    myChart.BasicHeatmapChartData.forEach((d: any) => {
      yVals.add(d[myChart.props.columnFieldName]);
      yLabels[d[myChart.props.columnFieldName]] = [d[myChart.props.columnDisplayName],d[myChart.props.columnFieldType]];
      if(d[myChart.props.disableFieldName] === "N"){
        disableSourceYCombo.push({
          source: d[myChart.props.sourceVar],
          yVar: d[myChart.props.columnFieldName]
        })
      }
    })
    yVals = Array.from(yVals);
    const groupBy = Array.from(d3.group(myChart.BasicHeatmapChartData, (g: any) => g[myChart.props.dateVar].replaceAll("T00:00:00","")  + ":" + g[myChart.props.sourceVar]));
    groupBy.forEach((g: any) => {
      const splitIndex = g[0].split(":")
      sourceGroups.add(splitIndex[1]);
      columnDates.add(new Date(splitIndex[0]));
      g[1].forEach((y: any) => {
        heatData.push({
          source: y[myChart.props.sourceVar],
          date: new Date(y[myChart.props.dateVar].replaceAll("T00:00:00","")),
          yVar: y[myChart.props.columnFieldName],
          value: y[myChart.props.valueVar]
        })
      })
    })

    sourceGroups = Array.from(sourceGroups);
    columnDates = Array.from(columnDates);
    legendSvg.attr("height", legendHeight);
    d3.select('#' + myClass).select("#bHMChartDiv").style("width", (width - (margins.leftColumn * 2)) + "px")
    const requiredWidth = margins.left + margins.right + (dateColumnWidth * (sourceGroups.length * columnDates.length));
    const requiredHeight = margins.top + margins.bottom + (yVals.length * groupHeight);
    if(height < requiredWidth){
      height = requiredHeight;
    }
    axisSvg.attr("width",margins.leftColumn * 2).attr("height", requiredHeight);
    chartSvg.attr("width",requiredWidth - (margins.leftColumn * 2)).attr("height", requiredHeight);
    const xScaleSource: any = d3.scaleBand().domain(sourceGroups).range([0, requiredWidth - margins.left - margins.right]);
    const xScaleDate: any = d3.scaleBand().domain(columnDates).range([0, xScaleSource.bandwidth()]);
    const yScale: any = d3.scaleBand().domain(yVals).range([0, yVals.length * groupHeight]);

    let leftAxisVisible = ["field", "type"];

    axisSvg.select('#column1ClipRect' + myClass)
      .style('width', margins.leftColumn)
      .style('height', height);

    axisSvg.select('#column2ClipRect' + myClass)
      .style('width', margins.leftColumn)
      .style('height', height);

    axisSvg.select("#yNameTitle")
      .attr("x", margins.leftColumn/2)
      .attr("y", 25)
      .attr('fill', '#101D42')
      .attr('font-weight', '600')
      .attr('font-family', 'Poppins')
      .attr('font-size', '12')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .text(myChart.props.columnTitles["name"]);

    axisSvg.select("#yTypeTitle")
      .attr("x", margins.leftColumn/2)
      .attr("y", 25)
      .attr('fill', '#101D42')
      .attr('font-weight', '600')
      .attr('font-family', 'Poppins')
      .attr('font-size', '12')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .text(myChart.props.columnTitles["type"]);

    axisSvg.select("#yColumnDivider")
      .attr("x1", margins.leftColumn)
      .attr("x2", margins.leftColumn)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    axisSvg.select("#topAxisLine")
      .attr("x1", 0)
      .attr("x2", margins.leftColumn * 2)
      .attr("y1", margins.top - 20)
      .attr("y2", margins.top - 20)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    axisSvg.select("#topChartLine")
      .attr("x1", 0)
      .attr("x2", margins.leftColumn * 2)
      .attr("y1", 1)
      .attr("y2", 1)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    axisSvg.select("#arrowLeftCircle1")
      .attr("cursor", "pointer")
      .attr("r", 6)
      .attr("cx", margins.leftColumn - 10)
      .attr("cy", 21)
      .attr("stroke-width", 0)
      .attr("fill", "#EBEBEB")
      .on("click", (event: any) => {
        if(leftAxisVisible.includes("field")){
          leftAxisVisible = leftAxisVisible.filter((f: any) => f !== "field")
          d3.select("#arrowLeftText1")
            .text(">")
            .transition()
            .duration(500)
            .attr("x", 7);
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("cx", 7);
          axisSvg.select('#column1ClipRect' + myClass)
            .transition()
            .duration(500)
            .style("width",16 + "px")
          axisSvg.select("#column2Group")
            .transition()
            .duration(500)
            .attr("transform","translate(14,0)");
          axisSvg.select("#yColumnDivider")
            .transition()
            .duration(500)
            .attr("x1", 16)
            .attr("x2", 16)
        } else {
          leftAxisVisible.push("field");
          d3.select("#arrowLeftText1")
            .text("<")
            .transition()
            .duration(500)
            .attr("x", margins.leftColumn - 10);
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("cx", margins.leftColumn - 10);
          axisSvg.select("#column2Group")
            .transition()
            .duration(500)
            .attr("transform","translate(" + margins.leftColumn + ",0)");
          axisSvg.select('#column1ClipRect' + myClass)
            .transition()
            .duration(500)
            .style("width",margins.leftColumn + "px");
          axisSvg.select("#yColumnDivider")
            .transition()
            .duration(500)
            .attr("x1", margins.leftColumn)
            .attr("x2", margins.leftColumn)
        }
        const axisWidth = leftAxisVisible.length * margins.leftColumn + (2 - leftAxisVisible.length) * 16;
        axisSvg
          .transition()
          .duration(500)
          .attr("width",axisWidth);

        d3.select('#' + myClass).select("#bHMChartDiv")
          .transition()
          .duration(500)
          .style("width", width - axisWidth + "px");
      });

    axisSvg.select("#arrowLeftText1")
      .attr("pointer-events", "none")
      .attr("x", margins.leftColumn - 10)
      .attr("font-family", "Poppins")
      .attr("font-size", 12)
      .attr("text-anchor", "middle")
      .attr("y", 25.5)
      .attr("fill", "#1363DF")
      .text("<");

    axisSvg.select("#arrowLeftCircle2")
      .attr("cursor", "pointer")
      .attr("r", 6)
      .attr("cx",  margins.leftColumn - 10)
      .attr("cy", 21)
      .attr("stroke-width", 0)
      .attr("fill", "#EBEBEB")
      .on("click", (event: any) => {
        if(leftAxisVisible.includes("type")){
          leftAxisVisible = leftAxisVisible.filter((f: any) => f !== "type");
          d3.select("#arrowLeftText2")
            .text(">")
            .transition()
            .duration(500)
            .attr("x", 10);
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("cx", 10);
          axisSvg.select('#column2ClipRect' + myClass)
            .transition()
            .duration(500)
            .style("width",16 + "px")
        } else {
          leftAxisVisible.push("type");
          d3.select("#arrowLeftText2")
            .text("<")
            .transition()
            .duration(500)
            .attr("x", margins.leftColumn - 10);
          d3.select(event.currentTarget)
            .transition()
            .duration(500)
            .attr("cx", margins.leftColumn - 10);
          axisSvg.select('#column2ClipRect' + myClass)
            .transition()
            .duration(500)
            .style("width",margins.leftColumn + "px");
        }
        const axisWidth = leftAxisVisible.length * margins.leftColumn + (2 - leftAxisVisible.length) * 16;
        axisSvg
          .transition()
          .duration(500)
          .attr("width",axisWidth);

        d3.select('#' + myClass).select("#bHMChartDiv")
          .transition()
          .duration(500)
          .style("width", width - axisWidth + "px");
      });

    axisSvg.select("#arrowLeftText2")
      .attr("pointer-events", "none")
      .attr("x", margins.leftColumn - 10)
      .attr("font-family", "Poppins")
      .attr("font-size", 12)
      .attr("text-anchor", "middle")
      .attr("y", 25.5)
      .attr("fill", "#1363DF")
      .text("<");

    axisSvg.select("#column1Group")
      .attr('clip-path','url(#column1Clip' + myClass + ')');

    axisSvg.select("#column2Group")
      .attr("transform", "translate(" + margins.leftColumn + ",0)")
      .attr('clip-path','url(#column2Clip' + myClass + ')')

    chartSvg.select("#yColumnRight")
      .attr("x1", 1)
      .attr("x2", 1)
      .attr("y1", margins.top)
      .attr("y2", height)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    chartSvg.select("#topAxisTopLine")
      .attr("x1", 0)
      .attr("x2", requiredWidth - (margins.leftColumn * 2))
      .attr("y1", margins.top)
      .attr("y2", margins.top)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    chartSvg.select("#topAxisLine")
      .attr("x1", 0)
      .attr("x2", requiredWidth - (margins.leftColumn * 2))
      .attr("y1", margins.top - 20)
      .attr("y2", margins.top - 20)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    chartSvg.select("#topChartLine")
      .attr("x1", 0)
      .attr("x2", requiredWidth - (margins.leftColumn * 2))
      .attr("y1", 1)
      .attr("y2", 1)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

    chartSvg.select("#sourceAxis")
      .call(d3.axisTop(xScaleSource))
      .attr("transform", "translate(0,30)");

    chartSvg.selectAll("#sourceAxis text")
      .attr("y",-5)
      .attr('fill', '#101D42')
      .attr('font-weight', '600')
      .attr('font-family', 'Poppins')
      .attr('font-size', '12');

    chartSvg.selectAll("#sourceAxis line")
      .attr("x1", -(xScaleSource.bandwidth()/2) + 1)
      .attr("x2", -(xScaleSource.bandwidth()/2) + 1)
      .attr("y1", 10)
      .attr("y2", -(margins.top - 20))
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB");

      const column1Group = axisSvg
        .select("#column1Group")
        .selectAll('.column1Group' + myClass)
        .data(yVals)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'column1Group' + myClass);
          enter.append('text').attr('class', 'nameLabel');
          return enter;
        });

      function getLabelText  (myText: string) {
        const originalLength = myText.length;
        while(measureWidth(myText,10) > (margins.leftColumn - 35)){
          myText = myText.substring(0, myText.length - 1);
        }
        return myText.length === originalLength ? myText : myText.trim() + "..."
      }
      column1Group.select(".nameLabel")
        .attr("x", 5)
        .attr("y", (d: any) => margins.top + (yScale.bandwidth()/2) + yScale(d) + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-family', 'Poppins')
        .attr('font-size', '10')
        .text((d: any) => getLabelText(yLabels[d][0]))
        .on("mouseover", (event:any, d: any) => {
          if(getLabelText(yLabels[d][0]).includes("...")){
            myChart.showTooltip('nameFullName', yLabels[d][0], event.offsetX, event.offsetY, width);
          }
        })
        .on("mouseout", () => {
          myChart.hideTooltip("nameFullName");
        });;

      const column2Group = axisSvg
        .select("#column2Group")
        .selectAll('.column2Group' + myClass)
        .data(yVals)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'column2Group' + myClass);
          enter.append('text').attr('class', 'typeLabel');
          return enter;
        });

      column2Group.select(".typeLabel")
        .attr("x", 5)
        .attr("y", (d: any) =>  margins.top + (yScale.bandwidth()/2) + yScale(d) + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-family', 'Poppins')
        .attr('font-size', '10')
        .text((d: any) => getLabelText(yLabels[d][1]))
        .on("mouseover", (event:any, d: any) => {
          if(getLabelText(yLabels[d][1]).includes("...")){
            myChart.showTooltip('typeFullName', yLabels[d][1], event.offsetX, event.offsetY, width);
          }
        })
        .on("mouseout", () => {
          myChart.hideTooltip("nameFullName");
        });;
    const sourceGroup = chartSvg
      .selectAll('.sourceGroup' + myClass)
      .data(sourceGroups)
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'sourceGroup' + myClass);
        enter.append('g').attr('class', 'datesAxis axis');
        enter.append('g').attr('class', 'datesGroup');
        return enter;
      });

    sourceGroup
      .attr("transform", (d: any) => "translate(" + (xScaleSource(d) + 1) + "," + margins.top + ")");

    sourceGroup.select(".datesAxis").each((d:any, i: any, objects: any) => {
      objects.forEach((o: any) => {
        d3.select(o)
          .call(d3.axisTop(xScaleDate).tickFormat((t: any) => d3.timeFormat(tickFormat[timePeriod])(new Date(t))))
      })
    })

    chartSvg.selectAll(".datesAxis text")
      .attr("y", -7)
      .attr('fill', '#101D42')
      .attr('font-weight', '500')
      .attr('font-family', 'Poppins')
      .attr('font-size', '10');

    const datesGroup = sourceGroup.select(".datesAxis")
      .selectAll('.datesGroup')
      .data((d: any) => heatData.filter((f: any) => f.source === d))
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'datesGroup');
        enter.append('rect').attr('class', 'datesRect');
        enter.append('text').attr('class', 'datesRectLabel');
        return enter;
      });

    datesGroup.select(".datesRect")
      .attr("x", (d: any) => 3 + xScaleDate(d.date))
      .attr("width", dateColumnWidth - 6)
      .attr("height",groupHeight - 8)
      .attr("fill", (d: any) => isDisabled(d) ? "#D4D9E3" : colorScale(d.value))
      .attr("y", (d: any) => 4 + yScale(d.yVar))

    datesGroup.select(".datesRectLabel")
      .attr("x", (d: any) => 3 + xScaleDate(d.date) + (dateColumnWidth - 6)/2)
      .attr('fill', '#FFFFFF')
      .attr('font-weight', '600')
      .attr('font-family', 'Poppins')
      .attr('font-size', '10')
      .attr('text-anchor', 'middle')
      .attr("y", (d: any) => 8 + yScale(d.yVar) + (groupHeight - 8)/2)
      .text((d: any) => isDisabled(d) ? "" : d3.format(myChart.props.percentFormat)(d.value))

    chartSvg.selectAll(".datesAxis line")
      .attr("x1", -xScaleDate.bandwidth()/2)
      .attr("x2", -xScaleDate.bandwidth()/2)
      .attr("y1", 0)
      .attr("y2", -20)
      .attr("stroke-width",1)
      .attr("stroke", "#EBEBEB")

    chartSvg.selectAll(".axis path")
      .attr("display", "none");

    drawLegend(myChart.props.colors)

    function drawLegend (legendData: any) {

      const legendGroup = legendSvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('rect').attr('class', 'legendRect');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.select(".legendRect")
        .attr("y", 20 - 8)
        .attr("x", (d: any, i: any) => 3 + (i * dateColumnWidth) + ( ( myChart.deviceDetectionService.isTablet ?100: margins.leftColumn ) * 2))
        .attr("width", dateColumnWidth - 6)
        .attr("height",6)
        .attr("fill", (d: any) => d);

      legendGroup.select(".legendLabel")
        .attr('fill', '#8A98AB')
        .attr('font-weight', '500')
        .attr('font-family', 'Poppins')
        .attr('font-size', '10')
        .attr('text-anchor', 'middle')
        .attr("y", 20 - 10)
        .attr("x", (d: any, i: any) => 3 + (i * dateColumnWidth) + (margins.leftColumn * 2) + (dateColumnWidth/2))
        .text((d: any, i: any) => myChart.props.colorLabels[i])

    }
    function isDisabled(d: any) {
      return disableSourceYCombo.find((f: any) => f.source === d.source && f.yVar === d.yVar) !== undefined
    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px poppins';
      return context.measureText(myText).width;
    }
    this.stop(10).then(() => this.isLoading = false);
  }

// property & data for the chart
  getBasicHeatmapChartData() {
    this.BasicHeatmapChartData = this.data
    this.data = this.BasicHeatmapChartData
    this.props = {
      chartHeight: 370,
      noDataFlag: "field_applicable_flg",
      columnFieldName: "field_nm",
      columnDisplayName: "field_display_nm",
      columnFieldType: "field_typ",
      disableFieldName: "field_applicable_flg",
      sourceVar:"source_nm",
      valueVar: "percentage_populated",
      dateVar: "period",
       // dateFormat: "Q%q, %Y",
       period:this.filterService.report_type,
      percentFormat: ".0%",
      columnTitles: { "name": "Field Name", "type": "Requirement" },
      colors: ["#FB2E2E", "#FE6839", "#FCC332", "#94CF16", "#32CD70"],
      colorLabels: ["0-20%", "20-40%", "40-60%", "60-80%", "80-100%"],
      thresholds: [0.2, 0.4, 0.6, 0.8]
    }
    if (this.BasicHeatmapChartData !== undefined || this.BasicHeatmapChartData.length > 0) {
      setTimeout(() => {
        this.plotChart();

      }, 500);
    }
  }
}
