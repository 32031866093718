import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

import { WidgetConfig } from '../widget.model'
import  Widget from '../widget'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-alert-button',
  template: `
    <div class="mr-2">
      <div class="parallelogram-alert"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample">
        <img *ngIf="showAlerts" class="altert_orng" src="../../../../assets/alert_orange.png">
        <img class="matParallelogram-alert"
            (click)="showNotifications()"
            src="../../../../../assets/notiIcon.svg"/>
      </div>
    </div>
  `,
  styleUrls: ['./alert-button.scss'],
})
export class AlertButton extends Widget {
  static override key = 100106
  widgets: WidgetConfig[] = []
  showAlerts = true

  showNotifications () {
    this.showAlerts = false
  }
}