import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-tooltip-popup',
  templateUrl: './general-tooltip-popup.component.html',
  styleUrls: ['./general-tooltip-popup.component.scss']
})
export class GeneralTooltipPopupComponent implements OnInit {
  @Input('data') tooltipData: any;
  @Input('tooltipHead') tooltipHead: any;
  @Input('date') date: any = false;
  constructor(private currency: CurrencyPipe) { }

  ngOnInit(): void {
  }
  numbedPipe(value: any) {
    let data: any = ""
    if (typeof value == "string") {
      const myArray = value.split("/");

      for (let i = 0; i < myArray.length; i++) {
        data += this.currency.transform(myArray[i], '', '', '1.0-2') + "/"
      }
      var n = data.lastIndexOf("/");
      var val = data.substring(0, n)
      return val
    } else {
      return this.currency.transform(value, '', '', '1.0-2');
    }
  }

}
