<div class="row page-title-header m-0 modal-header">
  <div class="d-flex justify-content-between">
    <div></div>
    <div>
      <span class="title">Select Config</span>
    </div>
    <div>
      <a class="close link" (click)="close()">&times;</a>
    </div>
  </div>
</div>
<div style="padding: 20px 15px 0;">
  <mat-form-field style="width: 100%" appearance="fill" class="matFformFieldConfig">
    <mat-label>Config Type</mat-label>
    <mat-select [(ngModel)]="selectConfig">
      <mat-option style="font-family: 'Poppins';" *ngFor="let config of configs" [value]="config.config_typ" title="{{ config.config_typ }}">{{ config.config_typ }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="row">
  <div class="col-12 text-center my-4">
    <button 
      type="button" 
      class="btn btn-primary btn-sm confirmBtnHomePopups" 
      matTooltip="Confirm" 
      matTooltipClass="expand-tooltip"
      [disabled]="!selectConfig" 
      (click)="selectedConfig(selectConfig)"
    >
      Confirm
    </button>
  </div>
</div>