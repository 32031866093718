import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import * as d3 from "d3";
import * as topojson from 'topojson';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { FilterService } from 'src/app/services/filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-choropleth-map-chart',
  templateUrl: './choropleth-map-chart.component.html',
  styleUrls: ['./choropleth-map-chart.component.scss']
})
export class ChoroplethMapChartComponent implements OnInit, OnChanges {

  @Input('item') item: any = [];
  @Input('allData') allData: any = [];
  @Input('choroplethData') choroplethData: any;
  @Input('hideLinkTooltip') hideLinkTooltip: boolean = false;

  @HostBinding('class.is-fullscreen') isFullscreen = false;
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])

  @ViewChild('fs') fs!: ElementRef;

  props: any = {};
  divId: any = 'choroMapDiv';
  tooltipData: any;
  showChloropathPopup: boolean = false
  tooltipHead: any;
  isActive: boolean = false;
  tooltipValues: any = [];
  reqSubcription: Subscription[] = [];
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  choroplethApidata: any = [];
  showLocations: boolean = false
  locationNames: any = []
  hiddenParams: any

  constructor(
    private dataService: DataService,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    public numberPipe: NumberPipe,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    // Properties
    this.props = {
      colors: { "dark": "#1363DF", "mid": "#7BA9EF", "light": "#A1C1F2" },
      patientVar: 'pending_patient',
      stateVar: 'state_cd',
      zipVar: 'zip_code'
    }

    this.initiateCharts();


    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
  
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
      })
    })

    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        document.getElementById('choroplethCompleteChart')?.setAttribute('style', 'height:100vh;overflow-y:auto;overflow-x:hidden;background:#ffff;padding-top:3rem;width:100vw')
        this.hideLinkTooltip = false
        this.isFullscreen = true
        
        // Plot Chat
        setTimeout(() => {this.plotChart()}, 100);
      } else {
        document.getElementById('choroplethCompleteChart')?.setAttribute('style', 'height:auto;overflow-y:unset;background:#ffff;padding-top:auto;width:unset;overflow-x:unset')
        this.hideLinkTooltip = false
        this.isFullscreen = false;

        // Plot Chat
        setTimeout(() => {this.plotChart()}, 100);
      }
    })

    window.addEventListener('orientationchange', (event: any) => {
      // Plot Chat
      setTimeout(() => {this.plotChart()}, 100);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes['allData']?.currentValue!=changes['allData']?.previousValue) {

      this.allData = changes['allData'].currentValue || [];
      this.choroplethApidata = this.allData;
      this.hideLinkTooltip = false;
      
      // Plot Chat
      setTimeout(() => {this.plotChart()}, 0);
      this.dataService.choroplethData.next(this.choroplethApidata);
    }
  }

  mapClick(chartData: any, clickDirection: any): void {
    this.dataService.getState.next(chartData.state)
    sessionStorage.setItem('state', chartData.state)

  }

  InsideChart() {
    this.hideLinkTooltip = false
  }

  backButtonClick(): void {
    this.hideLinkTooltip = false
    this.showLocations = false
    this.dataService.backToDefault.next(true)
    this.plotChart()
  }

  resetButtonClick(): void {
    this.hideLinkTooltip = false
    this.showLocations = false;
    this.dataService.backToDefault.next(true)
    this.plotChart();
  }

  cancelStateHighlights(svg: any, darkColor: any): void {
    svg.selectAll(".statePath")
      .attr("stroke-width", 1)
      .attr("stroke", (d: any) => d.matchingState === undefined ? '#D9DDE4' : darkColor);
  }

  postcodeTagClick(myData: any) {
    // debugger
    this.dataService.showLocationLevelData.next(myData);
    // this.dataService.getState.next(myData.officeData[0].location_name ? myData.officeData[0].location_name : myData.officeData.location_name)
    sessionStorage.setItem('location', myData.officeData[0].location_name ? myData.officeData[0].location_name : myData.officeData.location_name)
  }

  private showTooltip(myX: any, myY: any, myData: any, chartWidth: any, chartHeight: any): void {
    this.hiddenParams = myData ? myData.state : null;
    
    if (!myData.officeData) {
      const fndState = this.allData.find((o:any)=> o['state_cd']?.toLowerCase()== myData?.state?.toLowerCase());
      
      this.tooltipData = ['State - ' + myData?.state, myData?.patientCount]
      this.tooltipValues = [
        {
          count: myData.patientCount,
          status: 'PATIENTS',
          change: '',
          color: 'yellow',
          myData: fndState
        }
      ];
      
      this.tooltipHead = 'Patient Count'
      if (this.isFullscreen == true) {
        d3.select('#d3ChoroplethTooltip')
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', myY + 60 + 'px')
          .style('left', myX + 15 + 'px')
          .style('z-index', 9999)
      }
      else {
        d3.select('#d3ChoroplethTooltip')
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', myY - 15 + 'px')
          .style('left', myX + 15 + 'px')
          .style('z-index', 9999)
      }
      this.hideLinkTooltip = true
    }

    else if (myData.officeData) {
      // debugger
      const data = this.filterService.allLocationData[myData.state] || [];
      this.locationNames = data.filter((f:any)=> f.zip_code == myData.postcode && f.state_cd == myData.state) 
      
      if (this.isFullscreen == true) {
        d3.select('#d3StateTooltip')
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', myY + 60 + 'px')
          .style('left', myX + 20 + 'px')
          .style('z-index', 9999)
      }
      else {
        d3.select('#d3StateTooltip')
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', myY - 15 + 'px')
          .style('left', myX + 20 + 'px')
          .style('z-index', 9999)
      }
      this.showLocations = true
    }
  }

  private hideTooltip(): void {
    this.showLocations = false
  }

  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const width = mySection.clientWidth;
    const height = 500; // 500 is random height, height is fixed to data later on
    const svg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .attr('height', height);

    svg.append('rect').attr('class', 'mapBackground' + myClass);
    svg.append('rect').attr('class', 'legendBackground' + myClass);
    svg.append('rect').attr('class', 'legendSizeRect' + myClass);
    svg.append('line').attr('class', 'legendSizeLine' + myClass);
    svg.append('text').attr('class', 'title' + myClass + ' sourcesTitle' + myClass);

    const defs = svg.append('defs');

    defs.append('clipPath').attr('id', 'legendClip')
      .append('rect').attr('id', 'legendClipRect' + myClass);

    defs.append('clipPath').attr('class', 'mapClipPath' + myClass)
      .attr('id', 'choroMapClipPath')
      .append('rect').attr('class', 'mapClipRect' + myClass);

    const baseSvg = svg.append('g').attr('class', 'baseSvg' + myClass);
    const mapGroup = baseSvg.append('g').attr('class', 'mapGroup' + myClass);
    const mapPathsGroup = mapGroup.append('g').attr('class', 'mapPathsGroup' + myClass);
    mapGroup.append('g').attr('class', 'mapCountiesGroup' + myClass);
    mapGroup.append('g').attr('class', 'mapTagsGroup' + myClass);
    mapGroup.append('g').attr('class', 'mapPathsOverGroup' + myClass);

    mapPathsGroup.append('path').attr('class', 'puertoRicoPath' + myClass);

    const zoomButtonGroup = svg.append('g').attr('id', 'zoomButtonGroup' + myClass);

    zoomButtonGroup.append('rect').attr('class', 'zoomRectWhite' + myClass + ' refreshRect' + myClass);
    zoomButtonGroup.append('rect').attr('class', 'zoomRectWhite' + myClass + ' buttonRect' + myClass);
    zoomButtonGroup.append('rect').attr('class', 'zoomRectBlue' + myClass + ' inButton' + myClass);
    zoomButtonGroup.append('rect').attr('class', 'zoomRectBlue' + myClass + ' outButton' + myClass);
    zoomButtonGroup.append('svg').attr('class', 'refreshIconSvg' + myClass)
      .append('path').attr('class', 'refreshIconPath' + myClass);
    zoomButtonGroup.append('svg').attr('class', 'inIconSvg' + myClass)
      .append('path').attr('class', 'inIconPath' + myClass);
    zoomButtonGroup.append('svg').attr('class', 'outIconSvg' + myClass)
      .append('path').attr('class', 'outIconPath' + myClass);
    zoomButtonGroup.append('text').attr('class', 'zoomValueLabel' + myClass);

    const backButtonGroup = svg.append('g').attr('id', 'backButtonGroup' + myClass);
    backButtonGroup.append('rect').attr('class', 'zoomRectWhite' + myClass + ' backButtonRect' + myClass);
    backButtonGroup.append('text').attr('class', 'backValueLabel' + myClass);
    backButtonGroup.append('svg').attr('class', 'backIconSvg' + myClass)
      .append('path').attr('class', 'backIconPath' + myClass);

  }
  
  // chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const svg: any = d3.select('#svg_' + myClass);
    const baseSvg = d3.select('.baseSvg' + myClass);
    const mapGroup = d3.select('.mapGroup' + myClass);
    if (svg) {
      var width = svg?.node()?.getBoundingClientRect()?.width;
    }
    const height = +svg.attr('height');
    const zoomButtonPanelWidth = 169;
    const zoomButtonPanelHeight = 36;

    const backIconPath = "M3.84662 6.03998L10 6.03997L10 3.96003L3.84662 3.96002L6.23759 1.4772L4.81504 0L0 5L4.81504 10L6.23759 8.5228L3.84662 6.03998Z";
    const plusIconPath = 'M18.6364 8.18184H11.8182V1.36364C11.8182 0.610908 11.2073 0 10.4545 0H9.54548C8.79275 0 8.18184 0.610908 8.18184 1.36364V8.18184H1.36364C0.610908 8.18184 0 8.79275 0 9.54548V10.4545C0 11.2073 0.610908 11.8182 1.36364 11.8182H8.18184V18.6364C8.18184 19.3891 8.79275 20 9.54548 20H10.4545C11.2073 20 11.8182 19.3891 11.8182 18.6364V11.8182H18.6364C19.3891 11.8182 20 11.2073 20 10.4545V9.54548C20 8.79275 19.3891 8.18184 18.6364 8.18184Z';
    const minusIconPath = 'M14.9091 1.85966e-05H9.45453C9 4.32323e-05 8.9658 0 8.36362 0L7.63638 1.85966e-05C7.0342 1.85966e-05 7 1.85967e-05 6.54547 1.85966e-05H1.09091C0.488726 1.85966e-05 0 0.488746 0 1.09093V1.81816C0 2.42035 0.488726 2.90907 1.09091 2.90907H6.54547C7 2.90907 7.0342 2.90929 7.63638 2.90929H8.36362C8.9658 2.90929 9 2.90907 9.45453 2.90907H14.9091C15.5113 2.90907 16 2.42035 16 1.81816V1.09093C16 0.488746 15.5113 1.85966e-05 14.9091 1.85966e-05Z';
    const refreshIconPath = 'M16.3662 3.63824L17.0735 2.93138V2.93138L16.3662 3.63824ZM20 8V9C20.5523 9 21 8.55228 21 8H20ZM19.609 12.7773C19.7622 12.2466 19.4561 11.6923 18.9255 11.5392C18.3948 11.3861 17.8406 11.6921 17.6874 12.2227L19.609 12.7773ZM21 2C21 1.44772 20.5523 1 20 1C19.4477 1 19 1.44772 19 2H21ZM14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9V7ZM0 10C0 15.5228 4.47715 20 10 20V18C5.58172 18 2 14.4183 2 10H0ZM10 0C4.47715 0 0 4.47715 0 10H2C2 5.58172 5.58172 2 10 2V0ZM17.0735 2.93138C15.2648 1.12139 12.7624 0 10 0V2C12.2104 2 14.2098 2.89514 15.6588 4.34511L17.0735 2.93138ZM15.6588 4.34511C16.4319 5.11872 17.3119 6.17306 18.0111 7.05263C18.3577 7.4887 18.6545 7.87487 18.8643 8.15174C18.9692 8.29008 19.0522 8.40091 19.1087 8.47676C19.1369 8.51468 19.1585 8.54384 19.1729 8.56331C19.1801 8.57304 19.1855 8.58035 19.189 8.58512C19.1908 8.58751 19.192 8.58925 19.1929 8.59035C19.1933 8.5909 19.1935 8.59129 19.1937 8.59151C19.1938 8.59162 19.1938 8.59169 19.1939 8.59172C19.1939 8.59174 19.1939 8.59173 19.1939 8.59173C19.1939 8.59172 19.1938 8.59169 20 8C20.8062 7.40831 20.8061 7.40826 20.8061 7.4082C20.8061 7.40817 20.806 7.4081 20.806 7.40803C20.8059 7.40789 20.8057 7.40771 20.8056 7.40749C20.8052 7.40705 20.8048 7.40644 20.8042 7.40567C20.8031 7.40413 20.8015 7.40194 20.7994 7.39911C20.7952 7.39344 20.7892 7.38521 20.7813 7.37454C20.7655 7.35319 20.7424 7.32207 20.7127 7.28211C20.6532 7.2022 20.5668 7.08687 20.4582 6.94364C20.2412 6.65737 19.9349 6.25871 19.5767 5.80809C18.8662 4.91428 17.9293 3.78774 17.0735 2.93138L15.6588 4.34511ZM10 20C14.5605 20 18.4055 16.9481 19.609 12.7773L17.6874 12.2227C16.7243 15.5605 13.6457 18 10 18V20ZM19 2V8H21V2H19ZM20 7H14V9H20V7Z';
    const mapIconPath = "M7.99959 0C4.00564 0 0.756348 3.2493 0.756348 7.2432C0.756348 12.1998 7.23834 19.4763 7.51432 19.7836C7.77354 20.0723 8.22611 20.0718 8.48486 19.7836C8.76084 19.4763 15.2428 12.1998 15.2428 7.2432C15.2428 3.2493 11.9935 0 7.99959 0ZM7.99959 10.8875C5.99014 10.8875 4.35537 9.25266 4.35537 7.2432C4.35537 5.23375 5.99018 3.59898 7.99959 3.59898C10.009 3.59898 11.6438 5.23379 11.6438 7.24324C11.6438 9.2527 10.009 10.8875 7.99959 10.8875Z";
    const zoomViewBox = '0 0 20 20';
    const refreshViewBox = '0 0 21 20';
    const mapViewBox = "0 0 16 20";
    const backViewBox = "0 0 10 10";

    let currentZoomLevel: any = 100;
    const chartData: any = this.choroplethApidata;
    let postcodeData: any = myChart.choroplethData.postcodeCoords;

    // debugger

    const stateData: any = [], postcodeTagData: any = [];

    const groupedByState = Array.from(d3.rollup(chartData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.patientVar]), (g: any) => g[myChart.props.stateVar]));
    groupedByState.forEach((d: any) => {
      stateData.push({
        state: d[0],
        patientCount: d[1]
      })
    })
    const groupedByPostcode = Array.from(d3.rollup(chartData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.patientVar]), (g: any) => g[myChart.props.zipVar]));
    groupedByPostcode.forEach((d: any) => {
      const matchingPostcode = postcodeData.find((f: any) => +f.ZIP === +d[0]);
      if (matchingPostcode === undefined) {
      } else {
        postcodeTagData.push({
          postcode: d[0],
          state: matchingPostcode.state,
          officeCount: d[1],
          coordinates: [matchingPostcode.LNG, matchingPostcode.LAT],
          officeData: chartData.filter((f: any) => +f.zip_code === +d[0])
        })
      }
    })
    svg.select('.mapBackground' + myClass)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#DAE1EC')
      .attr('rx', 12)
      .attr('ry', 12);

    const mapWidth = width;
    const mapHeight = height - zoomButtonPanelHeight - 40;

    const zoom = d3.zoom()
      .extent([[0, 0], [mapWidth, mapHeight]])
      .scaleExtent([1, 20])
      .translateExtent([[0, 0], [mapWidth, mapHeight]])
      .on('zoom', zoomed);

    svg.select('.mapClipRect' + myClass)
      .attr('width', mapWidth)
      .attr('height', mapHeight + 40);

    baseSvg.attr('clip-path', 'url(#choroMapClipPath)');

    // @ts-ignore
    baseSvg.call(zoom).on("dblclick.zoom", null);

    svg.select('#zoomButtonGroup' + myClass)
      .attr('transform', 'translate(' + (width - 16 - zoomButtonPanelWidth) +
        ',' + (height - zoomButtonPanelHeight - 16) + ')');

    svg.select('#backButtonGroup' + myClass)
      .attr('visibility', 'hidden')
      .attr('cursor', 'pointer')
      .attr('transform', 'translate(' + (width - 16 - 90) +
        ',' + (height - zoomButtonPanelHeight - 16) + ')')
      .on('click', () => {
        //@ts-ignore
        baseSvg.call(zoom.transform, d3.zoomIdentity);
        svg.selectAll('#backButtonGroup' + myClass).attr("visibility", "hidden");
        svg.selectAll('#zoomButtonGroup' + myClass).attr("visibility", "visible");
        drawChoropleth(allStates, [], []);
        //@ts-ignore
        baseSvg.call(zoom).on("dblclick.zoom", null);
        myChart.backButtonClick();
      });


    svg.selectAll('.zoomRectWhite' + myClass)
      .attr('width', zoomButtonPanelHeight)
      .attr('height', zoomButtonPanelHeight)
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('fill', 'white')
      .attr('cursor', 'pointer')
      .on('click', function () {
        myChart.resetButtonClick();
        // @ts-ignore
        baseSvg.call(zoom.transform, d3.zoomIdentity);
        currentZoomLevel = 100;
        svg.select('.zoomValueLabel' + myClass).text(currentZoomLevel + '%');
      });

    svg.selectAll('.buttonRect' + myClass)
      .attr('x', zoomButtonPanelHeight + 7)
      .attr('width', 126)

    svg.selectAll('.backButtonRect' + myClass)
      .attr('x', 0)
      .attr('width', 90)

    svg.selectAll('.inButton' + myClass)
      .attr('x', zoomButtonPanelHeight + 7 + zoomButtonPanelHeight + 58)
      .attr('y', 4)
      .attr('cursor', 'pointer')
      .on('click', function () {
        currentZoomLevel += 10;
        // @ts-ignore
        zoom.scaleBy(baseSvg, 1.1);
        svg.select('.zoomValueLabel' + myClass).text(currentZoomLevel + '%');
      });

    svg.selectAll('.outButton' + myClass)
      .attr('x', zoomButtonPanelHeight + 7 + 4)
      .attr('y', 4)
      .attr('cursor', 'pointer')
      .on('click', function () {
        if (currentZoomLevel > 100) {
          currentZoomLevel -= 10;
        }
        // @ts-ignore
        zoom.scaleBy(baseSvg, 0.9);
      });

    svg.selectAll('.zoomRectBlue' + myClass)
      .attr('width', 28)
      .attr('height', 28)
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('fill', '#1363DF');

    svg.select('.inIconPath' + myClass)
      .attr('pointer-events', 'none')
      .attr('fill', 'white')
      .attr('d', plusIconPath);

    svg.select('.inIconSvg' + myClass)
      .attr('x', zoomButtonPanelHeight + 7 + zoomButtonPanelHeight + 58 + 5)
      .attr('y', 4 + 5)
      .attr('pointer-events', 'none')
      .attr('viewBox', zoomViewBox)
      .attr('width', 18)
      .attr('height', 18);

    svg.select('.outIconPath' + myClass)
      .attr('pointer-events', 'none')
      .attr('fill', 'white')
      .attr('d', minusIconPath);

    svg.select('.outIconSvg' + myClass)
      .attr('pointer-events', 'none')
      .attr('x', zoomButtonPanelHeight + 7 + 6 + 5)
      .attr('y', 4 + 7 + 5)
      .attr('viewBox', zoomViewBox)
      .attr('width', 18)
      .attr('height', 18);

    svg.select('.refreshIconPath' + myClass)
      .attr('pointer-events', 'none')
      .attr('fill', '#1363DF')
      .attr('d', refreshIconPath);

    svg.select('.refreshIconSvg' + myClass)
      .attr('pointer-events', 'none')
      .attr('x', 4 + 5)
      .attr('y', 4 + 5)
      .attr('viewBox', refreshViewBox)
      .attr('width', 18)
      .attr('height', 18);

    svg.select('.backIconPath' + myClass)
      .attr('pointer-events', 'none')
      .attr('fill', myChart.props.colors.dark)
      .attr('d', backIconPath);

    svg.select('.backIconSvg' + myClass)
      .attr('x', 15)
      .attr('y', 4 + 8)
      .attr('pointer-events', 'none')
      .attr('viewBox', backViewBox)
      .attr('width', 12)
      .attr('height', 12);

    svg.select('.zoomValueLabel' + myClass)
      .attr('pointer-events', 'none')
      .attr('x', zoomButtonPanelHeight + 7 + 63)
      .attr('y', (zoomButtonPanelHeight / 2) + 4.5)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1363DF')
      .attr('font-size', '12px')
      .attr('font-weight', '700')
      .attr('font-family', 'Poppins')
      .text('100%');

    svg.select('.backValueLabel' + myClass)
      .attr('pointer-events', 'none')
      .attr('x', 60)
      .attr('y', (zoomButtonPanelHeight / 2) + 4.5)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1363DF')
      .attr('font-size', '12px')
      .attr('font-weight', '700')
      .attr('font-family', 'Poppins')
      .text('Back');

    const filterOut = ['American Samoa', 'United States Virgin Islands', 'Commonwealth of the Northern Mariana Islands', 'Guam'];
    const states = myChart.choroplethData.countyTopoJSON;
    const feature: any = topojson.feature(states, states.objects.states);
    const countiesFeature: any = topojson.feature(states, states.objects.counties);
    //@ts-ignore
    const projection: any = d3.geoAlbersUsa().fitSize([mapWidth - 40, mapHeight], feature);
    const path: any = d3.geoPath().projection(projection);


    const pRProjection = d3.geoConicEqualArea()
      .rotate([66, 0])
      .center([0, 18])
      .parallels([8, 18])
      .fitSize([mapWidth - 40, mapHeight], feature);

    const pRPath: any = d3.geoPath().projection(pRProjection);

    svg.select('.puertoRicoPath' + myClass)
      .attr('fill', 'white')
      .attr('d', pRPath(feature.features.find((f: any) => f.properties.stateCode === 'PR')))

    const puertoRicoCoordinates = pRPath.centroid(feature.features.find((f: any) => f.properties.stateCode === 'PR'));

    feature.features = feature.features.filter((f: any) => filterOut.indexOf(f.properties.name) === -1);
    feature.features.map((m: any) => m.matchingState = stateData.find((f: any) => f.state === m.properties.stateCode));
    const allStates = feature.features;
    drawChoropleth(allStates, [], [])
    function drawChoropleth(myStates: any, myTags: any, myCounties: any) {

      const mapStatesGroup = svg
        .select('.mapPathsGroup' + myClass)
        .selectAll('.mapStatesGroup' + myClass)
        .data(myStates)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'mapStatesGroup' + myClass);
          enter.append('path').attr('class', 'statePath');
          enter.append('text').attr('class', 'statePathLabel');
          enter.append('g').attr('class', 'countyPathGroup');
          enter.append('path').attr('class', 'statePathOutline');
          enter.append('text').attr('class', 'stateValueLabel');
          return enter;
        });

      mapStatesGroup.attr('id', (d: any) => d.properties.stateCode);

      mapStatesGroup.select('.statePath')
        .attr('id', (d: any) => "statePath" + d.properties.stateCode)
        .attr('fill', (d: any) => d.matchingState === undefined ? 'white' : myChart.props.colors.light)
        .attr('stroke', (d: any) => d.matchingState === undefined ? '#D9DDE4' : myChart.props.colors.dark)
        .attr('d', path)
        .on('mouseover', (event: any, d: any) => {
          if (d.matchingState !== undefined) {
            if (d.zoomedIn !== true) {
              svg.select("#statePath" + d.properties.stateCode).attr("fill", myChart.props.colors.dark);
              svg.select("#statePath" + d.properties.stateCode + '.stateValueLabel').attr("fill", "white")
              svg.select("#statePath" + d.properties.stateCode + '.statePathLabel').attr("fill", "white")
              myChart.showTooltip(event.offsetX, event.offsetY, d.matchingState, width, height);
            }
          }
        }).on('mouseout', (event: any, d: any) => {
          if (d.zoomedIn !== true) {
            svg.selectAll('.statePath').attr('fill', (d: any) => d.matchingState === undefined ? 'white' : myChart.props.colors.light)
            svg.selectAll('.stateValueLabel').attr('fill', myChart.props.colors.dark);
            svg.selectAll('.statePathLabel').attr('fill', (d: any) => d.matchingState === undefined ? '#8A98AB' : (d.properties.stateCode === "MA" ? myChart.props.colors.dark : 'white'))
            myChart.hideTooltip();
          }
        })
        .on("click", (event: any, d: any) => {
          if (d.zoomedIn !== true) {
            myChart.cancelStateHighlights(svg, myChart.props.colors.dark)
            svg.select("#" + d.properties.stateCode).raise();
            let clickDirection = "in";
            if (svg.select("#statePathOutline" + d.properties.stateCode + ".statePathOutline").attr("stroke") === "gold") {
              svg.select("#statePathOutline" + d.properties.stateCode + ".statePathOutline").attr("stroke-width", 1).attr("stroke", (d: any) => d.matchingState === undefined ? '#D9DDE4' : myChart.props.colors.dark);
              clickDirection = "out";
            } else {
              postcodeTagData.forEach((f: any) => f ? svg.select("#statePathOutline" + f.state + ".statePathOutline").attr("stroke-width", 1).attr("stroke", myChart.props.colors.dark) : "");
              svg.select("#statePathOutline" + d.properties.stateCode + ".statePathOutline").attr("stroke-width", 2).attr("stroke", (d: any) => d.matchingState === undefined ? '' : "gold");
            }
            myChart.mapClick(d.matchingState, clickDirection);
          }
        })
        .on("dblclick", function (event: any, d: any) {
          myChart.InsideChart();
          if (d.matchingState !== undefined) {
            myChart.cancelStateHighlights(svg, myChart.props.colors.dark)
            d.zoomedIn = true;
            svg.selectAll('#zoomButtonGroup' + myClass).attr("visibility", "hidden");
            svg.selectAll('#backButtonGroup' + myClass).attr("visibility", "visible");
            svg.select('.puertoRicoPath' + myClass).attr("visibility", "hidden")
            const filteredTags = postcodeTagData.filter((f: any) => f.state === d.properties.stateCode);
            const filteredCounties = countiesFeature.features.filter((f: any) => f.properties.stateCode === d.properties.stateCode)
            drawChoropleth([d], filteredTags, filteredCounties);
            event.stopPropagation();
            zoomToBounds(d, zoom);
          }
        });

      mapStatesGroup.select('.statePathLabel')
        .attr('id', (d: any, i: any) => "statePath" + i)
        .attr("x", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[0] : path.centroid(d)[0] + (d.properties.stateCode === "FL" ? 10 : 0))
        .attr("y", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[1] : path.centroid(d)[1])
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .attr('font-size', 10)
        .attr('font-weight', '300')
        .attr('font-family', 'Poppins')
        .attr('fill', (d: any) => d.matchingState === undefined ? '#8A98AB' : (d.properties.stateCode === "MA" ? myChart.props.colors.dark : 'white'))
        .text((d: any) => d.properties?.stateCode === "MA" ? myChart.numberPipe.transform(d.matchingState?.patientCount) : d.properties?.stateCode);

      mapStatesGroup.select('.stateValueLabel')
        .attr('id', (d: any, i: any) => "statePath" + i)
        .attr("x", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[0] : path.centroid(d)[0] + (d.properties.stateCode === "FL" ? 10 : 0))
        .attr("y", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[0] : path.centroid(d)[1] + 12)
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .attr('font-size', 10)
        .attr('font-weight', '300')
        .attr('font-family', 'Poppins')
        .attr('fill', myChart.props.colors.dark)
        .text((d: any) => d.matchingState === undefined ? '' : myChart.numberPipe.transform(d.matchingState?.patientCount));

      mapStatesGroup.select('.statePathOutline')
        .attr("pointer-events", "none")
        .attr('id', (d: any) => "statePathOutline" + d.properties.stateCode)
        .attr('fill', "transparent")
        .attr('stroke', (d: any) => d.matchingState === undefined ? '#D9DDE4' : myChart.props.colors.dark)
        .attr('d', path);

      mapStatesGroup.select('.stateValueLabel')
        .attr('id', (d: any, i: any) => "statePath" + i)
        .attr("x", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[0] : path.centroid(d)[0] + (d.properties.stateCode === "FL" ? 10 : 0))
        .attr("y", (d: any) => d.properties.stateCode === 'PR' ? puertoRicoCoordinates[0] : path.centroid(d)[1] + 12)
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .attr('font-size', 10)
        .attr('font-weight', '300')
        .attr('font-family', 'Poppins')
        .attr('fill', myChart.props.colors.dark)
        .text((d: any) => d.matchingState === undefined ? '' : myChart.numberPipe.transform(d.matchingState?.patientCount));


      const mapCountyGroup = mapStatesGroup
        .select('.countyPathGroup')
        .selectAll('.mapCountyGroup' + myClass)
        .data(myCounties)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'mapCountyGroup' + myClass);
          enter.append('path').attr('class', 'countyPath');
          return enter;
        });

      mapCountyGroup.select('.countyPath')
        .attr('fill', 'transparent')
        .attr('stroke', '#d5e3fa')
        .attr('stroke-width', '0.25')
        .attr('d', path)

      const mapTagsGroup = svg
        .select('.mapTagsGroup' + myClass)
        .selectAll('.mapTagGroup' + myClass)
        .data(myTags)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'mapTagGroup' + myClass);
          enter.append('svg').attr('class', 'mapIconSvg')
            .append('path').attr('class', 'mapIconPath');
          return enter;
        });

      mapTagsGroup.select('.mapIconPath')
        .attr('fill', 'white')
        .attr('d', mapIconPath)
        .on('mousemove', function (event: any, d: any) {
          svg.selectAll('.mapIconPath').attr('fill', 'white');
          // @ts-ignore
          d3.select(this).attr('fill', myChart.props.colors.dark);
          myChart.showTooltip(event.offsetX + 10, event.offsetY, d, width, height);
        }).on('mouseout', () => {
          svg.selectAll('.mapIconPath').attr('fill', 'white');
          myChart.hideTooltip();
        })
        .on("click", (event: any, d: any) => {
          myChart.hideTooltip();
          myChart.postcodeTagClick(d);
        });

      mapTagsGroup.select('.mapIconSvg')
        .attr('x', (d: any) => projection(d.coordinates) === null ? puertoRicoCoordinates[0] : projection(d.coordinates)[0])
        .attr('y', (d: any) => projection(d.coordinates) === null ? puertoRicoCoordinates[1] : projection(d.coordinates)[1])
        .attr('viewBox', mapViewBox)
        .attr('width', 8)
        .attr('height', 8);
    }

    function zoomToBounds(d: any, zoom: any) {
      baseSvg.call(zoom.transform, d3.zoomIdentity);
      const [[x0, y0], [x1, y1]] = path.bounds(d);
      baseSvg.transition().duration(750).call(
        zoom.transform,
        d3.zoomIdentity
          .translate(mapWidth / 2, mapHeight / 2)
          .scale(Math.min(8, 0.9 / Math.max((x1 - x0) / mapWidth, (y1 - y0) / mapHeight)))
          .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
        d3.pointer(event, svg.node()))

      //baseSvg.on('.zoom', null);
    }
    stateData.forEach((d: any) => {
      svg.select("#" + d.state).raise();
    })

    function zoomed(event: any) {
      const { transform } = event;
      mapGroup.attr('transform', transform);
      mapGroup.attr('stroke-width', 1 / transform.k);
      svg.select('.zoomValueLabel' + myClass).text(parseInt(String(transform.k * 100), 10) + '%');

    }


  }

  tooltipHide() {
    this.hideLinkTooltip = false
  }

  ngOnDestroy() {
    this.dataService.choroplethData.next('')
    this.choroplethApidata = []
    this.choroplethData = []
    this.showChloropathPopup = false
    this.reqSubcription.forEach((res: any) => res.unsubscribe())
  }
}
