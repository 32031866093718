import { Component, Input, OnDestroy } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Subscription } from 'rxjs'
import * as _ from 'lodash'
import * as d3 from 'd3'
import {  DataService, Tooltip } from '@dataunveil/ducharts'

@Component({
  standalone: true,
  imports: [CommonModule, Tooltip],
  selector: 'app-dispense-map-tooltip',
  template: `
    <duc-tooltip [config]="config">
      <ng-template #custom let-data>
        <div class="header">
          Quantity Shipped
        </div>
        <div class="main">
          <div class="location" *ngFor="let locations of data | keyvalue">
            {{ locations.key }}
            <div class="value" *ngFor="let location of $any(locations.value)">
          <span class="value-color"
            [style]="{ 'background-color': colorScale(location.label) }"></span>
              {{ location.label }}: {{ formatValue(location.value) }}
            </div>
          </div>
        </div>
      </ng-template>
    </duc-tooltip>
  `,
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnDestroy {
  subs: Subscription[] = []
  @Input() config: any
  colorScale: any

  constructor(protected dataService: DataService) {}

  ngOnInit () {
    this.subs.push(this.dataService.get('dispense-map-tooltip-wrapper').subscribe(this.onDataUpdate.bind(this)))
  }

  onDataUpdate (_data: any) {
    if (!_data) return this.dataService.set(this.config.id)

    const data = {
      ..._data,
      data: _.groupBy(_data.data.items, 'data.locationname')
    }
    this.colorScale = _data.data.colorScale
    this.dataService.set(this.config.id, data)
  }

  formatValue (value: number) {
    return d3.format(',')(value)
  }

  ngOnDestroy () {
    this.subs.forEach((sub) => sub.unsubscribe())
  }
}