// @ts-nocheck

import { CurrencyPipe } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  Injectable,
  OnDestroy,
  HostListener,
  HostBinding,
  ElementRef,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as d3 from "d3";
import { DialogService } from "src/app/services/dialog.service";
import { FilterService } from "src/app/services/filter.service";
import { ActivatedRoute } from "@angular/router";
import { NewFilterService } from "src/app/services/new-filter.service";
@Injectable({
  providedIn: "root",
})
@Component({
  selector: "line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("lineChartContainer", { static: true })
  lineChartContainer!: ElementRef;
  @ViewChild("lineChart") lineChart!: ElementRef;
  @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  @Input("data") data: any;
  @Input("pageKey") pageKey: any;
  @Input("item") item: any;
  @Input("config") config: any;
  @Input("sourceHeaderName") sourceHeaderName: any;
  @Input("headerConfig") headerConfig: any;
  @Input("heading") heading: string = "";
  @ViewChild("fs") fs: ElementRef;
  currentVisibleData: any = [];
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  @ViewChild("showMoreData", { static: true }) showMoreData!: ElementRef;
  isActive = false;
  iconList: any[] = [];
  Height: number = 500;
  lineData: any;
  dataTurn: number = 0;
  props: any = {};
  hei: number = 0;
  noData: Boolean = false
  showMore: Boolean = false
  divId: any = "lineChart";
  initiateChart: Boolean = false;
  // Tab for Percentage and Values
  selectedType: string = "percent";
  percentAndValue: any[] = [];
  renderLinePopup: boolean = false;
  isChart: boolean = false;
  lineChartPopupData: any = [];
  totalTooltipData: any = [];
  sourceName: any;
  showBy: any;
  constructor(
    public filterService: FilterService,
    private newFilterService: NewFilterService,
    private dialogService: DialogService,
    private currency: CurrencyPipe,
    private route: ActivatedRoute
  ) {
    // d3.selectAll("svg").remove();
    // d3.selectAll('.graph-container svg').remove();
    this.route.params.subscribe((p: any) => {
      this.sourceName = p["cardName"];
    });
    this.newFilterService.showBy.subscribe((value: any) => {
      this.start()
      this.showBy = value;
      this.getLineChartData();
    });

    this.filterService.changeDisplays.subscribe((res: any) => {
      this.changeDisplayType(res)
    })
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }

  @HostListener("fullscreenchange", ["$event"])
  @HostListener("webkitfullscreenchange", ["$event"])
  @HostListener("mozfullscreenchange", ["$event"])
  @HostListener("MSFullscreenChange", ["$event"])
  screenChange(event) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      // this.isFullscreen = false
    }
  }
//loade Method
isLoading = false;
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() { this.isLoading = true;}
 

  ngOnDestroy(): void {
    this.data = undefined;
    this.lineData = undefined;
  }
  isLoading = false;
  async wait(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() {
    this.isLoading = true;

  }
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    this.dataTurn = 0;
    this.hei = 0
    this.hei = chartHeight - myY
    this.dataTurn = chartWidth - myX;

    const copiedData = myData
    this.totalTooltipData = copiedData;
    this.lineChartPopupData = JSON.parse(JSON.stringify(copiedData));
    const tooltipData = copiedData;

    if (copiedData[1].length > 10) {
      // this.showMore = true;
      const data1 = copiedData[1].slice(0, 10);
      this.lineChartPopupData[1] = data1;
    } else {
      this.showMore = false;
    }


    // Full Screen
    if (this.isFullscreen == true) {
      // when No SourceName ex.Amber
      if (this.sourceName == undefined) {

        if (this.lineChartPopupData[1].length == 1) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 40 + 'px')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 40 + 'px')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 2) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 3) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 4 || this.lineChartPopupData[1].length > 4) {
          if (this.hei < 250) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 70) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 250) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 45 + 'px')
              .style('bottom', 'unset')
          }
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
          }
        }

        this.renderLinePopup = true;

      }
      // When SourceName is Active
      else {
        if (this.lineChartPopupData[1].length == 1) {

          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY + 40) + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
          }


        }

        else if (this.lineChartPopupData[1].length == 2) {
          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
          }

        }

        else if (this.lineChartPopupData[1].length == 3) {

          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
          }
        }
        else if (this.lineChartPopupData[1].length == 4 || this.lineChartPopupData[1].length > 4) {

          if (this.hei < 240) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 240) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 45 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 20) + 'px')
              .style('right', 'unset')
          }
        }
        this.renderLinePopup = true;


      }

    }

    // Without Full Screen
    else {
      // when No SourceName ex.Amber
      if (this.sourceName == undefined) {

        if (this.lineChartPopupData[1].length == 1) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 40 + 'px')
              .style('right', (this.dataTurn + 25) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 40 + 'px')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 2) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('right', (this.dataTurn + 25) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 3) {
          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('right', (this.dataTurn + 25) + 'px')
              .style('left', 'unset')
              .style('bottom', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
              .style('bottom', 'unset')
          }
        }

        else if (this.lineChartPopupData[1].length == 4 || this.lineChartPopupData[1].length > 4) {
          if (this.hei < 250) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 80) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 250) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 280) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 25) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 280) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
          }
        }
        this.renderLinePopup = true;

      }
      // When SourceName is Active
      else {
        if (this.lineChartPopupData[1].length == 1) {

          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', (myY + 40) + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
          }


        }

        else if (this.lineChartPopupData[1].length == 2) {
          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 30 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
          }

        }

        else if (this.lineChartPopupData[1].length == 3) {
          if (this.hei < 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 60) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 180) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
          }
        }
        else if (this.lineChartPopupData[1].length == 4 || this.lineChartPopupData[1].length > 4) {
          if (this.hei < 240) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('bottom', (this.hei - 80) + 'px')
              .style('top', 'unset')
          }
          else if (this.hei > 240) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('top', myY + 38 + 'px')
              .style('bottom', 'unset')
          }

          if (this.dataTurn < 560) {
            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('right', (this.dataTurn + 20) + 'px')
              .style('left', 'unset')
          }
          else if (this.dataTurn > 560) {

            d3.select("#d3LineTooltip")
              .style('visibility', 'visible')
              .style('position', 'absolute')
              .style('left', (myX + 25) + 'px')
              .style('right', 'unset')
          }
        }

        // else {
        //   if (this.hei < 180) {
        //     d3.select("#d3LineTooltip")
        //       .style('visibility', 'visible')
        //       .style('position', 'absolute')
        //       .style('bottom', (this.hei - 80) + 'px')
        //       .style('top', 'unset')
        //   }
        //   else if (this.hei > 180) {
        //     d3.select("#d3LineTooltip")
        //       .style('visibility', 'visible')
        //       .style('position', 'absolute')
        //       .style('top', myY + 38 + 'px')
        //       .style('bottom', 'unset')
        //   }

        //   if (this.dataTurn < 560) {
        //     d3.select("#d3LineTooltip")
        //       .style('visibility', 'visible')
        //       .style('position', 'absolute')
        //       .style('right', (this.dataTurn + 20) + 'px')
        //       .style('left', 'unset')
        //   }
        //   else if (this.dataTurn > 560) {

        //     d3.select("#d3LineTooltip")
        //       .style('visibility', 'visible')
        //       .style('position', 'absolute')
        //       .style('left', (myX + 25) + 'px')
        //       .style('right', 'unset')
        //   }
        // }
        this.renderLinePopup = true;


      }
    }

  }

  openDialog() {
    this.renderLinePopup = false
    if (this.sourceName) {

      this.dialogService.openDialog(this.showMoreData, '600px')
    } else {
      this.dialogService.openDialog(this.showMoreData, '300px')
    }
  }

  private hideTooltip(myType: any): void {
    if (this.showMore) {
      // this.renderLinePopup = false
    } else {
      this.renderLinePopup = false
    }
  }

  ngOnInit(): void {
   this.start()
    if (this.item.config["list_of_api"]?.api_key == 3005) {
      this.divId = "dispenceLineChart";
    }
    else {
      this.divId = "irLineChart";
    }
    this.iconList = this.item.config.icon
      ? this.item.config.icon
      : this.iconList;
    this.percentAndValue = this.item.config?.toggle_bar;
    this.initiateCharts();

    window.addEventListener("orientationchange", (event: any) => {
      this.getLineChartData();
    });

    document.addEventListener('fullscreenchange', (event) => {
      this.renderLinePopup = false
    })
  }

  changeDisplayType(selectedType: any) {
    this.selectedType = selectedType;
    this.getLineChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["data"].currentValue != changes["data"].previousValue &&
      this.initiateChart
    ) {
      this.getLineChartData();
    }
  }
  // openDialog() {
  //   this.dialogService.openDialog(this.prompt);
  // }

  openPDF(imgData) {
    // Generate PDF
    var doc = new jsPDF("p", "pt", "a4");
    doc.text(this.heading, 225, 30);
    doc.addImage(imgData, "PNG", 0, 50, 600, 500);
    doc.save(this.heading + ".pdf");
  }

  onSaveChartClicked(): void {
    const theElement = this.lineChartContainer.nativeElement;
    htmlToImage.toPng(theElement).then((dataUrl) => {
      this.openPDF(dataUrl);
    });
  }

  openFullscreen(): void {
    this.isFullscreen = true;
    const el = this.fs.nativeElement;
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    }
    setTimeout(() => {
      this.isActive = true;
      this.isFullscreen = true;
      this.plotChart();
    }, 500);
    setTimeout(() => { }, 500);
  }

  closeFullscreen(): void {
    this.isFullscreen = false;

    this.props.chartHeight = 500;
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      document.exitFullscreen();
    }
    this.isActive = false;
    setTimeout(() => {
      this.plotChart();
    }, 100);
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const mySvg = d3.select(this.lineChartContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);

    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'brushClip' + myClass)
      .append('rect').attr('id', 'brushClipRect' + myClass);

    defs.append('clipPath').attr('id', 'dotsClip' + myClass)
      .append('rect').attr('id', 'dotsClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1).attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000')
      .attr('flood-opacity', 0.4).attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');

    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('line').attr('class', 'dotLine' + myClass);
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
    mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg.append('g').attr('id', 'brushSelectionGroup' + myClass);
    mySvg.append('g').attr('id', 'chartGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass);
    mySvg.append('rect').attr('id', 'brushSelectionDateRect' + myClass);
    mySvg.append('text').attr('class', 'brushSelectionDateRectLabel' + myClass);
    mySvg.append('text').attr('id', 'brushSelectionDateRectCloseLabel' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    const tooltipGroup = mySvg.append('g').attr('id', 'tooltipGroup' + myClass);
    tooltipGroup.append('rect').attr('class', 'wrapTooltipRect' + myClass);
    tooltipGroup.append('text').attr('class', 'wrapTooltipLabel' + myClass);
    this.initiateChart = true;
  }
//  showby var changes
  showByChange(value: any) {
    if (this.item.config.list_of_api.api_key == 100196) {
      return value;
    } else if (this.config.secondary_value_column) {
      return value;
    } else if (this.showBy == undefined || this.showBy == "bottles") {
      return "number_of_bottles";
    } else {
      return value;
    }
    // (this.showBy == undefined || this.showBy == "tablets") ? this.config.yAxisVar : "number_of_bottles"  ,
  }
  //  d3 chart initial structure
  getLineChartData(): void {
   let extracolor:any=this.getRandomColor(500)
    this.renderLinePopup = false
    this.lineData = this.data;
    if (this.lineData != undefined && this.config) {
      this.props = {
        "darkColors": this.item.config.list_of_api?.api_key == 3005 ? ["#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC",
          "#e74c3c", "#3498db", "#2ecc71", "#f1c40f", "#9b59b6",
          "#1abc9c", "#34495e", "#e67e22", "#16a085", "#d35400",
          "#2980b9", "#8e44ad", "#c0392b", "#27ae60", "#f39c12",
          "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71", "#f1c40f",
          "#9b59b6", "#1abc9c", "#34495e", "#e67e22", "#16a085",
          "#d35400", "#2980b9", "#8e44ad", "#c0392b", "#27ae60",
          "#f39c12", "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71",
          "#f1c40f", "#9b59b6", "#1abc9c", "#34495e", "#e67e22",
          "#16a085", "#d35400", "#2980b9", "#8e44ad", "#c0392b",
          "#27ae60", "#f39c12", "#7f8c8d",
        ] : ["#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC",'#1F2B97','#2B6BCB','#2F3CAF','#3E85EF','#404CBB','#4832A1','#49ACA0','#58B3B9','#5EDE6B','#663DBC','#6651BE','#6BCFDD','#7458E7','#7559C5','#81B661','#B04747','#BB3847','#BBAB54','#BFC23D','#C45B0F','#C75FD0','#D0662A','#D25297','#DD6287','#DE7D48','#E29063','#FF5740','#FFC164',...extracolor],
        "lightColors": this.item.config.list_of_api?.api_key == 3005 ? ["#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC",
          "#e74c3c", "#3498db", "#2ecc71", "#f1c40f", "#9b59b6",
          "#1abc9c", "#34495e", "#e67e22", "#16a085", "#d35400",
          "#2980b9", "#8e44ad", "#c0392b", "#27ae60", "#f39c12",
          "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71", "#f1c40f",
          "#9b59b6", "#1abc9c", "#34495e", "#e67e22", "#16a085",
          "#d35400", "#2980b9", "#8e44ad", "#c0392b", "#27ae60",
          "#f39c12", "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71",
          "#f1c40f", "#9b59b6", "#1abc9c", "#34495e", "#e67e22",
          "#16a085", "#d35400", "#2980b9", "#8e44ad", "#c0392b",
          "#27ae60", "#f39c12", "#7f8c8d",
        ] : ["#F29F93", "#95D89C", "#92E9F5", "#FFD79C", "#8AB2EE", "#BBA4FF", "#BCE8A2", "#E581ED", "#FC8CAD", "#CF8080", "#F4E69B", "#EEB185", "#DDE89F", "#89DED4", "#C582F9", "#ECA3B5", "#96D6DA", "#F1B3E0", "#8CCDE9", "#8399E5",'#7181b4','#82a4e1','#8a9dd5','#9db5f4','#9f8dc8','#a47fa7','#a8d2c7','#b5d3d6','#c3e69b','#cda6e1','#cda4e3','#d7f4f8','#dcb9f1','#dcc9e3','#aadcbf','#e08685','#ec9a97','#ecd2ad','#f1f0c5','#f3c7af','#f4c9f0','#f8d4be','#f8c7e3','#fdd6d7','#fddbcb','#f7e2d8','#ffadab','#ffd3aa',...this.lightColor(extracolor)],
        period:          
          this.item.config.list_of_api.api_key == 100196
            ? "PCQ"
            : this.filterService.report_type || "D",
        xAxisLabel: this.config.xAxisLabel,
        yAxisLabel: this.config.yAxisLabel,
        xAxisVar: this.config.xAxisVar,
        yAxisVar: this.showByChange(this.config.yAxisVar),
        secondYVar: this.config.secondary_value_column || null,
        showBrush: true,
        d3AxisFormatting: false,
        yAxisFormatting: "-2~s",
        groupVar:   this.filterService.showByKey=='N'?this.config.show_by_ndc:this.config.value_column,
        displayType: this.selectedType,
        axisFontSize: 12,
        axisFontWeight: 700,
        axisRotation: -45,
        chartHeight: 500,
        partialPeriod:
          this.item.config.list_of_api.api_key == 100196 ? false : true,
        chartBrushAndPercents:
          this.config.secondary_value_column ||
            this.item.config.list_of_api.api_key == 100196
            ? false
            : true,
        showAreaLegend: false,
        areaLabels: {
          line: "Inventory Quantity",
          dottedLine: " Shipment Quantity",
        },
        "showGaps": true,
        "yBrush": this.item.config?.vertical_brush?.value == "true" ? true : false
      };
      if (this.isFullscreen) {
        this.props.chartHeight = window.outerHeight - 60;
      }
      if (this.lineData !== undefined && this.lineData.length > 0) {
        if (Object.keys(this.lineData[0]).indexOf("partial_period_flg") > -1) {
          if (
            this.lineData.find((f) => f["partial_period_flg"] === "Y") !==
            undefined
          ) {
            this.props.partialPeriod = true;
          }
        }
        this.noData = false
        setTimeout(() => {
          this.plotChart();
        }, 50);
      } else {
        this.lineData = [];
        this.plotChart();
        this.noData = true
      }
    }
    // else{
    //   this.lineData=[]
    //   this.plotChart();

    // }
  }
// chart svg  plotChart rendering 
  plotChart(): void {

    const myChart = this;
    const myClass = myChart.divId;
    const mySvg = d3.select('#svg_' + myClass);
    const width = mySvg.node().getBoundingClientRect().width;
    const height = myChart.props.chartHeight;
    let selectedSourceData = [];

    mySvg.attr("height", height);
    // various icons.
    // I export the icon in Figma as an SVG
    // Alecs knows we can only have one path - sometimes there are more than 1 in which case ask him to adjust it
    // you need to copy both the path AND the viewbox as each is a bit different
    // I am doing it this way as it is consistent and most of the icons are custom ones
    const iconViewBox = '0 0 10 7';
    const downIconPath = 'M0.313868 7H3.49922C3.67246 7 3.81309 6.85487 3.81309 6.67564V6.02045C3.81309 5.84142 3.67246 5.69609 3.49922 5.69609H2.15371L4.90684 2.8511L6.07402 4.05732C6.32051 4.31184 6.71992 4.31184 6.96641 4.05732L9.90801 1.01694C10.0307 0.890384 10.0307 0.684907 9.90801 0.558351L9.45977 0.0949173C9.3373 -0.0316386 9.13848 -0.0316386 9.01602 0.0949173L6.52012 2.67428L5.35313 1.46806C5.10666 1.21327 4.70719 1.21317 4.46074 1.46806L1.26172 4.77406V3.38356C1.26172 3.20453 1.12129 3.0592 0.947851 3.0592H0.313868C0.140429 3.0592 0 3.20453 0 3.38356V6.67564C0 6.85487 0.140429 7 0.313868 7Z';
    const upIconPath = 'M9.68613 0H6.50078C6.32754 0 6.18691 0.145126 6.18691 0.324363V0.979549C6.18691 1.15858 6.32754 1.30391 6.50078 1.30391H7.84629L5.09316 4.1489L3.92598 2.94268C3.67949 2.68816 3.28008 2.68816 3.03359 2.94268L0.0919922 5.98306C-0.0306641 6.10962 -0.0306641 6.31509 0.0919922 6.44165L0.540234 6.90508C0.662695 7.03164 0.861523 7.03164 0.983984 6.90508L3.47988 4.32572L4.64687 5.53194C4.89334 5.78673 5.29281 5.78683 5.53926 5.53194L8.73828 2.22594V3.61644C8.73828 3.79547 8.87871 3.9408 9.05215 3.9408H9.68613C9.85957 3.9408 10 3.79547 10 3.61644V0.324363C10 0.145126 9.85957 0 9.68613 0Z';
    // map date as always
    const allData = this.lineData;
    allData.map((m: any) => m.date = new Date(m[myChart.props.xAxisVar]));
    let sourceSet = new Set();
    // build source set from group var
    allData.forEach((d: any) => sourceSet.add(d[myChart.props.groupVar]));
    sourceSet = Array.from(sourceSet);
    const removeSpaceAndCharacters = (d: string) => d.replace(/-/g,'');
    const lineColors = {}, lineColorsLight = {}; //const fullNameMapper = {};
    // define colours now we have unique list of groups
    sourceSet.forEach((d: any, i: any) => {
      lineColors[removeSpaceAndCharacters(d)] = myChart.props.darkColors[i];
      lineColorsLight[removeSpaceAndCharacters(d)] = myChart.props.lightColors[i];
      // fullNameMapper[removeSpaceAndCharacters(d)] = d;
    })
    // tick format consistent - see bar chart for more details
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y", "PCQ": "Q%q %Y"};
    const timePeriod = this.props.period;
    const xTickFormat = d3.timeFormat(tickFormat[timePeriod]);
    let margins = { left: 75, right: 40, top: 70, bottom: 10, brush: 52, mid: 80, legend: 35};
    if(myChart.props.showBrush === false){
      // changes if no brush
      margins.brush = 0;
      margins.mid = 50;
      margins.bottom = 0;
    }
    // showAreaLegend is only true if there is a secondYVar defined
    // areaLegend is a secondary legend for the two lines (normal stroke (yAxisVar) and dash-array (secondYVar))
    myChart.props.showAreaLegend = myChart.props.secondYVar === null ? false : true;
    if(myChart.props.showAreaLegend === true){
      // change margin - slightly different if brush percents etc shown
      margins.top = myChart.props.chartBrushAndPercents === true ? 100 : 80;
    }
    const myDateSet = new Set();
    const lineSet = new Set();
    let dateGroup = [];
    allData.forEach((d: any) => {
      myDateSet.add(String(d.date));
      lineSet.add(d[myChart.props.groupVar])
    })
    Array.from(myDateSet).forEach((d: any) => {
      Array.from(lineSet).forEach((l: any) => {
        dateGroup.push([removeSpaceAndCharacters(l) + "|" + d, allData.filter((f: any)
          => f[myChart.props.groupVar] === l && String(f.date) === String(d))])
      })
    })
    // build date group by date AND group
  //let dateGroup = Array.from(d3.group(allData, (g: any) => g[myChart.props.groupVar] + '-' + g.date));
  // dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));
    let flatChartData = [];
    let dateSet = new Set();
    // building flatChartData
    dateGroup.forEach((d: any) => {
      // for each unique date AND group (see group above)
      // get the 1st entry and the total for yAxisVar
      const firstEntry = d[1][0];
      const firstVarTotal = firstEntry === undefined ? undefined : d3.sum(d[1], (s: any) => s[myChart.props.yAxisVar]);
      const secondVarTotal = firstEntry === undefined ? undefined :
        (myChart.props.secondYVar === null ? undefined : d3.sum(d[1], (s: any) => s[myChart.props.secondYVar]))
      // add the entry for this date with the TOTAL for all values for this data
      const myEntry = {
        xDate: d[0].split("|")[1],
        firstVar: firstVarTotal,
        secondVar: secondVarTotal,
        locations: d[1],
        date: new Date(d[0].split("|")[1])
      }
      myEntry[myChart.props.groupVar] = d[0].split("|")[0];
      flatChartData.push(myEntry);
      // also building date set since we are here
      dateSet.add(d[0].split("|")[1]);
    });
    if(!myChart.props.showGaps){
      flatChartData = flatChartData.filter((f: any) => f.firstVar !== undefined)
    }
    dateSet = Array.from(dateSet);
    // converting dateSet to date and sorting
    let dateGroupDates = dateSet.map(m => new Date(m));
    dateGroupDates = dateGroupDates.sort((a: any, b: any) => d3.ascending(a,b));
    // tick values same logic in all charts - see bar chart
    const tickValues = myChart.props.d3AxisFormatting === true ? null : dateGroupDates;
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    // group  flat data by date
    const dateRollup = Array.from(d3.group(flatChartData,  (g: any) => g.date));
    // within each group, roll up data so there is a total (yAxisVar) for each date, for each group (groupVar)
    dateRollup.forEach((d: any) => d[1] = Array.from(d3.rollup(d[1],
        (v: any) => d3.sum(v, (s: any) => s.firstVar), (r: any) => r[myChart.props.groupVar])));
    const dateIndexObject = {};
    // store date set indices (used later)
    dateSet.forEach((d: any, i: any) => dateIndexObject[d] = i);
    // get yMax from dateGroup and xExtent (from flatChartData)
    const yMax = d3.max(flatChartData, (d: any) => Math.max(d.firstVar, (myChart.props.secondYVar === null ? d.firstVar : d.secondVar)));
    const xExtent = d3.extent(flatChartData, (d: any) => d.date);
    // now group data
    const chartData = Array.from(d3.group(flatChartData, (d: any) => d[myChart.props.groupVar]));
    // setting brushStartX
    let startingVisibleRows: any = parseInt((width - margins.left - margins.right) / 25);
    const maxDataLength = d3.max(chartData, (d: any) => d[1].length);
    startingVisibleRows = startingVisibleRows > maxDataLength ? maxDataLength : startingVisibleRows;
    const brushStartX = allData.length === 0 ? 0 : (1-(startingVisibleRows/maxDataLength)) * (width - margins.left - margins.right);
    const chartHeight = height - margins.top - margins.bottom - margins.brush - margins.mid;
    // set selection brush (only used is myChart.props.chartBrushAndPercents is true
    const brushSelection = d3.brush()
        .extent([[0, 0], [width - margins.left - margins.right, chartHeight]])
        .on('start', brushSelectedStart)
        .on('end', brushedSelected);
    // set normal brush
    const brush = myChart.props.yBrush ?
      d3.brush()
        .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
        .on('start brush end',myChart.props.showBrush === false ? null : brushed)
    : d3.brushX()
        .handleSize(10)
        .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
        .on('start brush end',myChart.props.showBrush === false ? null : brushed);
    // no data message - soon to be redundant
    mySvg.select('#noDataMessage' + myClass)
      .attr("x", margins.left + (width - margins.left - margins.right)/2)
      .attr("y", margins.top + (height - margins.top - margins.mid - margins.brush - margins.bottom)/2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(allData.length === 0 ? "There is no data for this time period" : "");
    // partial period line + text - see bar for more details
    mySvg.select('.partialPeriodLine' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("x1", width - margins.right)
      .attr("x2", width - margins.right)
      .attr("y1", margins.top)
      .attr("y2", chartHeight + margins.top)
      .attr('stroke', '#1363DF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray','2,2');

    mySvg.select('.partialPeriodLineLabel' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("transform", "translate(" + (width - margins.right) + "," +
        (margins.top - 12) + ")")
      .attr("text-anchor", "middle")
      .attr("dy", 0)
      .attr('font-size', '8px')
      .attr('font-family', 'Poppins')
      .attr('font-weight', '400')
      .attr('fill', '#1363DF')
      .text('Partial period')
      .call(wrap, 35);

    // this is used by the brush selection, setting initial styling
    mySvg.select('.wrapTooltipRect' + myClass)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('height', 15)
        .attr('fill',  '#343435')
        .attr('background',  'none');
        

    mySvg.select('.wrapTooltipLabel' + myClass)
        .attr('font-family', 'Poppins')
        .attr('font-weight', '500')
        .attr('font-size', '10')
        .attr('fill',  'white')
        // .text('fill');

    // dot line - used in mouseover, same as combo + area line - initial styling
    mySvg.select('.dotLine' + myClass)
        .attr('visibility', 'hidden')
        .attr('pointer-events', 'none')
        .attr('stroke', '#A0A0A0')
        .attr('fill', '#A0A0A0')
        .attr('stroke-width', 3)
        .attr('y1', 0)
        .attr('y2', height - margins.top - margins.bottom - margins.brush - margins.mid)
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

    const brushDateRectHeight = 22;
    const brushDateRectMinWidth = 45;

    mySvg.select('#brushSelectionDateRect' + myClass)
        .attr('stroke-width', 0)
        .attr('height', brushDateRectHeight)
        .attr('fill', '#878f99')
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.select('.brushSelectionDateRectLabel' + myClass)
        .attr('fill', 'white')
        .attr('font-family', 'Poppins')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('cursor', function(): void {
          const myText = d3.select(this).text();
          if (myText.includes('..')){
            return 'pointer';
          } else {
            return 'default';
          }
        })
        .on('mousemove', function(event: any): void {
          const myText = d3.select(this).text();
          if (myText.includes('..')){
            mySvg.select('.wrapTooltipRect' + myClass)
                .attr('width', measureWidth(this.id, 10) + 15)
                .attr('x', (event.offsetX + 10) + 'px')
                .attr('y', event.offsetY + 'px');

            mySvg.select('.wrapTooltipLabel' + myClass)
                .attr('x', (event.offsetX + 15) + 'px')
                .attr('y', (event.offsetY + 10.5) + 'px')
                .text(this.id);
          }
        })
        .on('mouseout', () => {
          mySvg.select('.wrapTooltipRect' + myClass)
              .attr('width', 0);
          mySvg.select('.wrapTooltipLabel' + myClass).text('');
        });

    mySvg.select('#brushSelectionDateRectCloseLabel' + myClass)
        .attr('y', '13')
        .attr('fill', 'white')
        .attr('font-family', 'Poppins')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('cursor', 'pointer')
        .text(myChart.props.chartBrushAndPercents === true ? "x" : "")
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        .on('click', () => hideSelectBrush());

    // now I am going to explain re: legend in combo, area line + line
    //there is an arrow circle on the left + right with a label which defaults to not visible
    // the code might be a little different in the others but the logic is 100% the same
    // and they are all fully tested and working (which is why I am not changing the code)
    // in this case, max characters are 12, anything above that gets an ellipsis for the rest
    const maxCharacters = 10;
    const legendMargins = 120;
    //legend items are the lineColor keys - might be something different in other charts
    let legendItems = Object.keys(lineColors);
    // remapping them so that there is an index, value + label
    legendItems = legendItems.map((m: any, i: any) => m = {index: i, value: m, label: (m.length > maxCharacters ? m.substr(0, maxCharacters).trim() + '..' : m)});
    // using measure width to map the length of the label AND the value.
    legendItems.map((m: any) => m.length = measureWidth(m.label.toUpperCase(), 12));
    legendItems.map((m: any) => m.valueLength = measureWidth(m.value.toUpperCase(), 10));
    // so the width of the legend with ALL items is the length (set above) of each item + 40 (room for circle and spacing)
    const legendWidth = d3.sum(legendItems, (s: any) => s.length  + 40)
    let currentLegendItems = legendItems;
    // it starts by assuming all items fit
    let legendIndexLeft = 0;
    let legendIndexRight = legendItems.length - 1;
    // left arrow is always hidden initially (we are starting from the beginning)
    if (legendWidth >= (width - legendMargins)){
      // otherwise, the legend does not fit!!!
      currentLegendItems = [];
      let currentWidth = 0;
      // loop through all the legend items, aggregating the width UNTIL they don't fit anymore
      // legendIndexRight is the last one which fits.
      // anything after that is NOT stored in currentLegendItems and NOT visible
      legendItems.forEach((d: any, i: any) => {
        currentWidth += (d.length + 40);
        if (currentWidth < (width - legendMargins)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }

    mySvg.selectAll('.legendArrowLeftItem' + myClass)
      .attr('visibility', currentLegendItems.length === legendItems.length || legendIndexLeft === 0? "hidden" : "visible");
    mySvg.selectAll('.legendArrowRightItem' + myClass)
      .attr('visibility', currentLegendItems.length === legendItems.length ? "hidden" : "visible");

    // legend arrow circle left
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 16)
      .attr('cy', (margins.top / 2) - 5)
      .on('click', () => {
        if(myChart.props.chartBrushAndPercents === true){
          hideSelectBrush();
        }
        // on click, moves left and right behind one
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        // hard copy legend items
        const myFilteredLegendItems = JSON.parse(JSON.stringify(legendItems));
        // find new legend items using indices and draw legend
        currentLegendItems = myFilteredLegendItems.filter((f: any) => (f.index >= legendIndexLeft && f.index <= legendIndexRight));
        drawLegend(currentLegendItems, myChart.props.showAreaLegend);
        // right always visible (if the legend fits this will never be visible so will not be able to be clicked
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          // if left index is now 0, hide this circle so cannot click beyond range
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });

    // arrow, pointer event none
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 15)
      .attr('y', (margins.top / 2))
      .text('<');

    // right hand cirlce
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 16)
      .attr('cy', (margins.top / 2) - 5)
      .on('click', () => {
        if(myChart.props.chartBrushAndPercents === true){
          hideSelectBrush();
        }
        // move legend indices along one
        legendIndexLeft += 1;
        legendIndexRight += 1;
        // hard clone all legend items
        const myFilteredLegendItems = JSON.parse(JSON.stringify(legendItems));
        // filter to current selection and draw legend
        currentLegendItems = myFilteredLegendItems.filter((f: any) => (f.index >= legendIndexLeft && f.index <= legendIndexRight));
        drawLegend(currentLegendItems,myChart.props.showAreaLegend);
        // left hand circle always visible (see above re: logic)
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (legendItems.length - 1)){
          // if we have reached the last record, hide this circle so you cannot click out of range
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });

    // arrow label, pointer events none
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 15)
      .attr('y', (margins.top / 2))
      .text('>');

    // now draw legend!!!
    drawLegend(currentLegendItems,myChart.props.showAreaLegend);

    //styling for brush overlay and brush clip rects (dots have one as well so radius not cut in half)
    mySvg.selectAll('.overlay')
        .style('fill', 'transparent');

    mySvg.select('#brushClipRect' + myClass)
        .style('width', width - margins.left - margins.right)
        .style('height', chartHeight + 10)
        .attr('transform', 'translate(' + margins.left + ',' + (margins.top - 5) + ')');

    mySvg.select('#dotsClipRect' + myClass)
      .style('width', width - margins.left - margins.right + 12)
      .style('height', chartHeight + 12)
      .attr('transform', 'translate(' + (margins.left - 6) + ',' + (margins.top - 6) + ')');

    //set x and y scales
    const yScale = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom - margins.mid - margins.brush, 0]);
    const yScaleAll = d3.scaleLinear().domain([0, yMax]).range([margins.brush,0]);
    const yScaleBrush = d3.scaleLinear().domain([0, yMax]).range([margins.brush, 0]);
    const xScaleAll = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const xScale = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);

    // axis labels
    mySvg.select('#xAxisLabel' + myClass)
      .attr('visibility', allData.length === 0 ? 'hidden' : 'visible')
      .attr('x', margins.left + ((width - margins.left - margins.right)/2))
      .attr('y', height - margins.bottom - margins.brush - 10)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.xAxisLabel);

    mySvg.select('#yAxisLabel' + myClass)
      .attr('visibility', allData.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(13,' + (margins.top + ((height - margins.top - margins.bottom - margins.mid - margins.brush)/2)) + ') rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.yAxisLabel);

    // axes
    mySvg.select('#xAxis' + myClass)
        .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(tickValues).ticks(tickCount).tickFormat(xTickFormat))
        .attr('transform', 'translate(' + (margins.left || 0) + ',' + (margins.top + chartHeight) + ')');

    // chart group data - set up the same as bar.
    const chartGroupData = myChart.props.showBrush === false ? ['chart']: ['brush', 'chart'];
    // define lines
    const lineBrush = d3.line()
      .defined((d: any) => myChart.props.showGaps ? d.total !== undefined : true)
        .curve(d3.curveMonotoneX)
        .x((d: any) => xScaleAll(d.date))
        .y((d: any) =>  yScaleBrush(d.total));

    const lineFocus = d3.line()
      .defined((d: any) => myChart.props.showGaps ? d.total !== undefined : true)
      .curve(d3.curveMonotoneX)
        .x((d: any) => xScale(d.date))
        .y((d: any) =>  yScale(d.total));
    // including second line (when secondYVar !== null)
    const secondLine = d3.line()
      .curve(d3.curveMonotoneX)
      .defined((d: any) => myChart.props.showGaps ? d.total !== undefined : true)
      .x((d: any) => xScale(d.date))
      .y((d: any) =>  yScale(d.secondTotal));

    const lineArea = d3.area()
      .defined((d: any) => myChart.props.showGaps ? d.total !== undefined : true)
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d.date))
      .y0((d: any) =>  yScale(d.secondTotal))
      .y1((d: any) =>  yScale(d.total))
    // set brush
    mySvg.select('#brushGroup' + myClass)
      .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight + margins.mid) + ')')
      .call(brush)
      .call(brush.move,  !myChart.props.showBrush ? null :
        (myChart.props.yBrush ? [
            [xScale.range()[0], yScaleBrush.range()[1]],
            [xScale.range()[1], yScaleBrush.range()[0]]] :
          [brushStartX, width - margins.left - margins.right]));

    if(myChart.props.showBrush === false){
      // hide brush if not required
      mySvg.select('#brushGroup' + myClass)
        .call(brush.move,null)
        .selectAll("*").remove();
    }
    // if required set selection brush (or not if not)
    if(myChart.props.chartBrushAndPercents === true){
      mySvg.select('#brushSelectionGroup' + myClass)
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        .call(brushSelection)
        .call(brushSelection.move, [[0, 0],[0,0]]);
    } else {
      if(mySvg.select('#brushSelectionGroup' + myClass).selectAll(".overlay").node() !== null){
        mySvg.select('#brushSelectionGroup' + myClass)
          .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        .call(brushSelection.move, null);
      }
    }
    // handle line styles - see bar chart for explanation
    mySvg.selectAll('.handleLines' + myClass)
      .attr('visibility', allData.length === 0 || myChart.props.showBrush === false || myChart.props.yBrush ? 'hidden' : 'visible')
        .attr('y1', margins.top + chartHeight + margins.mid + (margins.brush - 12) / 2)
        .attr('y2', margins.top + chartHeight + margins.mid + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + margins.left + ',0)');
    // brush styling
    mySvg.selectAll('#brushGroup' + myClass).selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');

    mySvg.select('#brushSelectionGroup' + myClass)
      .select(".overlay")
      .on('mousemove', function(): any {
        myChart.hideTooltip('lineDot');
        selectedSourceData = [];
        mySvg.selectAll('.dataDot').attr('visibility', 'hidden');
        mySvg.selectAll('.dataDot2').attr('visibility', 'hidden');
        mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
      });

    drawChart();
    function drawChart(): void {
      // resetting axis styling
      mySvg.selectAll('.axis' + myClass + ' path')
        .style('stroke', '#E8EAEE');

      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', myChart.props.axisFontWeight)
        .style('font-size', myChart.props.axisFontSize);

      mySvg.select('#yAxis' + myClass)
        .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5).tickFormat((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormatting)(d)))
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

      mySvg.selectAll('#xAxis' + myClass + ' line')
        .style('pointer-events', 'none')
        .attr('y1', '0')
        .attr('y2', -(height - margins.top - margins.bottom - margins.brush - margins.mid))
        .style('stroke', '#F0F3F6');

      mySvg.selectAll('#yAxis' + myClass + ' line')
        .style('pointer-events', 'none')
        .attr('x1', '0')
        .attr('x2', width - margins.right - margins.left)
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);
      // xAxis - different behaviour of labels, same across all 4 charts (bar, line, arealine, combo)
      // see bar chart for explanation
      let ticksArray = tickValues === null ? xScale.ticks() : tickValues.filter((f: any) => f >= xScale.domain()[0] && f <= xScale.domain()[1]);
      ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
      const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
        ? width - margins.chartRight - margins.chartLeft : xScale(ticksArray[i+1]) - xScale(m) )

      const maxTextWidth = getMaxTextWidth();

      if (maxTextWidth > (xBandwidth - 5)) {
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;

        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style('font-family', 'Poppins')
          .style("text-anchor", "end")
          .style('font-size', myFontSize)
          //@ts-ignore
          .attr("dy",  3)
          //@ts-ignore
          .attr("transform", "rotate(-45)");
      } else {
        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style('font-family', 'Poppins')
          .style("text-anchor", "middle")
          //@ts-ignore
          .attr("dy", 3)
          //@ts-ignore
          .attr("transform", "rotate(0)");
      }

      function getMaxTextWidth() {
        let maxTextWidth = 0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function () {
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width)
        })
        return maxTextWidth
      }
      // set data for Angular team
      myChart.currentVisibleData = [chartData, xScale.domain()];
      // chartGroup
      const chartGroup = mySvg.select('#chartGroup' + myClass)
          .selectAll('.chartGroup' + myClass)
          .data(chartGroupData)
          .join((group): any => {
            const enter = group.append('g').attr('class', 'chartGroup' + myClass);
            enter.append('g').attr('class', 'linesGroup');
            enter.append('g').attr('class', 'dotsGroup');
            return enter;
          });

      // dots
      chartGroup.select('.dotsGroup')
        .attr('clip-path', (d: any) => d === 'brush' ? '' : 'url(#dotsClip' + myClass + ')')
        .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? (margins.mid + chartHeight) : 0) + ')');

      // lines
      chartGroup.select('.linesGroup')
        .attr('clip-path', (d: any) => d === 'brush' ? '' : 'url(#brushClip' + myClass + ')')
      .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? (margins.mid + chartHeight) : 0) + ')');

      // lines
      const lineGroup = chartGroup.select('.linesGroup')
          .selectAll('.linesGroupGroup')
          .data((d: any): void => {
            const myData = JSON.parse(JSON.stringify(chartData));
            // build data
            myData.forEach((m: any): void => {
              // convert to real dates
              m[1].map((n: any) => n.date = new Date(n.date));
              const allDates = Array.from(d3.group(m[1], (g: any) => g.date));
              // includesSecond (ie do we have a second var, is area legend shown)
              m.includesSecond =  m[1].length === 0 || m[1][0].secondVar === undefined ? false : true;
              m[1] = [];
              allDates.forEach((a: any): void => {
                a[1] = a[1].filter((f: any) => f.firstVar !== undefined);
                // for every date, push the data
                m[1].push({
                  dateIndex: dateIndexObject[String(a[0])],
                  date: a[0],
                  total: a[1].length === 0 ? undefined : d3.sum(a[1], (s: any) => s.firstVar),
                  secondTotal: a[1].length === 0 ? undefined :d3.sum(a[1], (s: any) => s.secondVar === undefined ? s.firstVar : s.secondVar),
                  data: a[1]
                });
              });
              // sort by date
              m[1] = m[1].sort((a: any, b: any) => d3.ascending(a.date, b.date));
            });
            // and map the line to use AND whether or not to show dots
            myData.map((m: any) => m.line = (d === 'brush' ? lineBrush : lineFocus));
            myData.map((m: any) => m.showDots = (d === 'brush' ? false : true));
            return myData;
          })
          .join((group): any => {
            const enter = group.append('g').attr('class', 'linesGroupGroup');
            enter.append('path').attr('class', 'linePath');
            enter.append('path').attr('class', 'linePathArea');
            enter.append('path').attr('class', 'linePath2');
            enter.append('path').attr('class', 'linePathMouseover');
            return enter;
          });

      // line path 2 - not visible if we are not using a second bar
      // lighter stroke as per colours in properties
      lineGroup.select('.linePath2')
        .attr("visibility", (d: any) => d.includesSecond ? "visible" : "hidden")
        .attr('pointer-events', 'none')
        .attr('fill', 'transparent')
        .attr('stroke', (d: any) => lineColorsLight[d[0]])
        .attr('stroke-width', (d: any) => d.showDots === false? 0 : 2)
        .attr('stroke-dasharray', "4,2")
        .attr('d', (d: any) => secondLine(d[1]))
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      // area - always red, only shows if we have a second var
      lineGroup.select('.linePathArea')
        .attr('pointer-events', 'none')
        .attr('fill', '#D11044')
        .attr('fill-opacity', (d: any) => d.showDots === true ? 0.2 : 0)
        .attr('stroke-width',  0)
        .attr('d', (d: any) => lineArea(d[1]))
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');
      // line path - always shows..
      lineGroup.select('.linePath')
          .attr('pointer-events', 'none')
          .attr('fill', 'transparent')
          .attr('stroke', (d: any) => lineColors[d[0]])
          .attr('stroke-width', 4)
          .attr('d', (d: any) => d.line(d[1]))
          .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      // mouseover
    lineGroup.select('.linePathMouseover')
      .attr('fill', myChart.props.showAreaLegend ? "transparent" : "none")
      .attr('stroke', 'transparent')
      .attr('stroke-width', 10)
      .attr('d', (d: any) => myChart.props.showAreaLegend ? lineArea(d[1]):d.line(d[1]))
      .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')')
      .on('mousemove', function(event: any, d: any): any {
        if(d.showDots){
          // same logic as ever, see bar data for full explanation
          // find date index closest to current mouse position
          const positionDate = xScale.invert(event.offsetX - margins.left);
          let closest: any = dateGroupDates.findIndex((f: any) => new Date(f) >= positionDate);
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(dateGroupDates[closest - 1], positionDate);
            const rightMinutes = d3.timeMinute.count(positionDate, dateGroupDates[closest]);
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
            if(closest > (dateGroupDates.length - 1)){
              closest = dateGroupDates.length - 1;
            }
          }
          // getting the data...
          const matchingDate = dateRollup.find(f => String(f[0]) === String(dateGroupDates[closest]));
          if(closest > 0){
            const previousDate = dateRollup.find(f => String(f[0]) === String(dateGroupDates[closest - 1]));
            matchingDate[1].forEach((m: any) => {
              const previousData = previousDate[1].find((f: any) => f[0] === m[0]);
              if(previousData !== undefined){
                m[2] = m[1] < previousData[1] ? "down" : (m[2] === previousData[1] ? "no change" : "up");
              }
              m[3] = lineColors[m[0]]
              const myRow = allData.filter((f) => String(f.date) === String(dateGroupDates[closest]) && f[myChart.props.groupVar] === m[0]);
              m[4] = myChart.props.secondYVar === null ? null : d3.sum(myRow, (s: any) => s[myChart.props.secondYVar])
              m[5] = lineColorsLight[m[0]];
              // m[0] = fullNameMapper[m[0]];
            });
          } else {
            matchingDate[1].forEach((m: any) => {
              m[2] = undefined; // value
              m[3] = lineColors[m[0]]; // line colour
              // 5 is second var value (if it exists)
              const myRow = allData.filter((f) => String(f.date) === String(dateGroupDates[closest]) && f[myChart.props.groupVar] === m[0]);
              m[4] = myChart.props.secondYVar === null ? null : d3.sum(myRow, (s: any) => s[myChart.props.secondYVar])
              m[5] = lineColorsLight[m[0]] // lighter line color
            })
          }
          // tooltip data format =
          //[groupVar, total for this group and date, the movement, color, second var (if using), lighter color]
          selectedSourceData = [];
          matchingDate[1].forEach((m: any) => {
            let myEntry = { dateValue: m[1]};
            myEntry[myChart.props.groupVar] = m[0]
            selectedSourceData.push(myEntry);
          });
          // show dot line and hide all dots
          mySvg.select('.dotLine' + myClass)
            .attr('visibility', 'visible')
            .attr('x1', xScale(dateGroupDates[closest]))
            .attr('x2', xScale(dateGroupDates[closest]));
          mySvg.selectAll('.dataDot').attr('visibility', 'hidden');
          mySvg.selectAll('.dataDot2').attr('visibility', 'hidden');
          if(myChart.props.showAreaLegend === true){
            // if second var, show all dots - first and second for this group
            mySvg.selectAll('#dot' + dateIndexObject[dateGroupDates[closest]] + "-" + d[0].toLowerCase().replace(/ /g,'').replace(/\//g, '_'))
              .selectAll("circle").attr('visibility', 'visible');
          } else {
            // if not second var, show all dots for this date
            mySvg.selectAll('.dataDot#date' + dateIndexObject[dateGroupDates[closest]]).attr('visibility', 'visible');
          }
          // if there is data, show tooltip
          if(matchingDate[1].length > 0){
            matchingDate[1] = matchingDate[1].sort((a: any,b: any) =>  a[0] == d[0] ? -1 : b[0] == d[0] ? 1 : 0);
            myChart.showTooltip('lineDot', matchingDate, margins.left + xScale(dateGroupDates[closest]), event.offsetY, width,height);
          }
        }
        })
        .on('mouseout', function(): any {
          // hide tooltip and reset
          myChart.hideTooltip('lineDot');
          selectedSourceData = [];
          mySvg.selectAll('.dataDot').attr('visibility', 'hidden');
          mySvg.selectAll('.dataDot2').attr('visibility', 'hidden');
          mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
        });

      const dotData = getDotData(chartData);
      const dataDotsGroup = mySvg.select('#chartGroup' + myClass)
        .selectAll('.dataDotsGroup')
        .data( dotData)
        .join((group): any => {
          const enter = group.append('g').attr('class', 'dataDotsGroup').attr('clip-path', (d: any) => d === 'brush' ? '' : 'url(#dotsClip' + myClass + ')');
          enter.append('circle').attr('class', 'secondSingleDot');
          enter.append('circle').attr('class', 'singleDot');
          enter.append('circle').attr('class', 'brushDot');

          return enter;
        });

      dataDotsGroup.select('.brushDot')
        .attr('r', 2)
        .attr('stroke-width', 0)
        .attr('fill', (d: any) => lineColors[d[0]])
        .attr('cx', (d: any) => xScale(d[1].date) + margins.left)
        .attr('cy', (d: any) => yScaleBrush(d[1].firstVar) + margins.top + chartHeight + margins.mid);

      dataDotsGroup.select('.singleDot')
        .attr('r', 4)
        .attr('stroke-width', 0)
        .attr('fill', (d: any) => lineColors[d[0]])
        .attr('cx', (d: any) => xScale(d[1].date) + margins.left)
        .attr('cy', (d: any) => yScale(d[1].firstVar) + margins.top)
        .on("mouseover", dataDotMouseover)
        .on("mouseout", () => {
          mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
          myChart.hideTooltip("dataDot");
        })

      dataDotsGroup.select('.secondSingleDot')
        .attr('r', myChart.props.secondYVar === null ? 0 :4)
        .attr('stroke-width', 0)
        .attr('fill', (d: any) => lineColorsLight[d[0]])
        .attr('cx', (d: any) => xScale(d[1].date) + margins.left)
        .attr('cy', (d: any) => myChart.props.secondYVar === null ? 0 :yScale(d[1].secondVar) + margins.top)
        .on("mouseover", dataDotMouseover)
        .on("mouseout", (event: any) => {
          mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
          mySvg.select(event.currentTarget).attr('visibility', 'visible');
          myChart.hideTooltip("dataDot");
        });

      function dataDotMouseover(event: any, d: any) {
        // getting the data...
        const closest = dateGroupDates.findIndex((f) => String(f) === String(d[1].date))
        const matchingDate = dateRollup.find(f => String(f[0]) === String(dateGroupDates[closest]));
        if(closest > 0){
          const previousDate = dateRollup.find(f => String(f[0]) === String(dateGroupDates[closest - 1]));
          matchingDate[1].forEach((m: any) => {
            const previousData = previousDate[1].find((f: any) => f[0] === m[0]);
            if(previousData !== undefined){
              m[2] = m[1] < previousData[1] ? "down" : (m[2] === previousData[1] ? "no change" : "up");
            }
            m[3] = lineColors[m[0]]
            const myRow = allData.filter((f) => String(f.date) === String(dateGroupDates[closest]) && f[myChart.props.groupVar] === m[0]);
            m[4] = myChart.props.secondYVar === null ? null : d3.sum(myRow, (s: any) => s[myChart.props.secondYVar])
            m[5] = lineColorsLight[m[0]]
          });
        } else {
          matchingDate[1].forEach((m: any) => {
            m[2] = undefined; // value
            m[3] = lineColors[m[0]]; // line colour
            // 5 is second var value (if it exists)
            const myRow = allData.filter((f) => String(f.date) === String(dateGroupDates[closest]) && f[myChart.props.groupVar] === m[0]);
            m[4] = myChart.props.secondYVar === null ? null : d3.sum(myRow, (s: any) => s[myChart.props.secondYVar])
            m[5] = lineColorsLight[m[0]] // lighter line color
            // m[0] = fullNameMapper[0];
          })
        }
        // tooltip data format =
        //[groupVar, total for this group and date, the movement, color, second var (if using), lighter color]
        selectedSourceData = [];
        matchingDate[1].forEach((m: any) => {
          let myEntry = { dateValue: m[1]};
          myEntry[myChart.props.groupVar] = m[0]
          selectedSourceData.push(myEntry);
        });
        // show dot line and hide all dots
        mySvg.select('.dotLine' + myClass)
          .attr('visibility', 'visible')
          .attr('x1', xScale(dateGroupDates[closest]))
          .attr('x2', xScale(dateGroupDates[closest]));
        mySvg.selectAll('.dataDot').attr('visibility', 'hidden');
        mySvg.selectAll('.dataDot2').attr('visibility', 'hidden');
        if(myChart.props.showAreaLegend === true){
          // if second var, show all dots - first and second for this group
          mySvg.selectAll('#dot' + dateIndexObject[dateGroupDates[closest]] + "-" + d[0].toLowerCase().replace(/ /g,'').replace(/\//g, '_'))
            .selectAll("circle").attr('visibility', 'visible');
        } else {
          // if not second var, show all dots for this date
          mySvg.selectAll('.dataDot#date' + dateIndexObject[dateGroupDates[closest]]).attr('visibility', 'visible');
        }
        // if there is data, show tooltip
        if(matchingDate[1].length > 0){
          matchingDate[1] = matchingDate[1].sort((a: any,b: any) =>  a[0] == d[0] ? -1 : b[0] == d[0] ? 1 : 0);
          myChart.showTooltip('lineDot', matchingDate, margins.left + xScale(dateGroupDates[closest]), event.offsetY, width, height);
        }

      }
      // now dots group (all hidden initially)
      const dotsGroup = chartGroup.select('.dotsGroup')
        .selectAll('.dotsGroupGroup')
        .data((d: any): void => {
          // duplicate build to line data
          const myData = JSON.parse(JSON.stringify(chartData));
          myData.forEach((m: any): void => {
            m[1].map((n: any) => n.date = new Date(n.date));
            const allDates = Array.from(d3.group(m[1], (g: any) => g.date));
            m.includesSecond =  m[1].length === 0 || m[1][0].secondVar === undefined ? false : true;
            m[1] = [];
            allDates.forEach((a: any): void => {
              m[1].push({
                dateIndex: dateIndexObject[String(a[0])],
                date: a[0],
                total: d3.sum(a[1], (s: any) => s.firstVar),
                secondTotal: d3.sum(a[1], (s: any) => s.secondVar === undefined ? s.firstVar : s.secondVar),
                data: a[1]
              });
            });
            m[1] = m[1].sort((a: any, b: any) => d3.ascending(a.date, b.date));
          });
          myData.map((m: any) => m.line = (d === 'brush' ? lineBrush : lineFocus));
        // myData.map((m: any) => m.showDots = (d === 'brush' ? false : true));
          return d === "brush" ? [] : myData;
        })
        .join((group): any => {
          const enter = group.append('g').attr('class', 'dotsGroupGroup');
          enter.append('g').attr('class', 'dotGroup');
          enter.append('g').attr('class', 'dotGroup2');
          return enter;
        });

      // two dots (yAxisVar and secondYVar) - second only shown/rendered if needed
      dotsGroup.select('.dotGroup')
          .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      dotsGroup.select('.dotGroup2')
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      const dotGroup =  dotsGroup.select('.dotGroup')
          .selectAll('.dotGroup' + myClass)
          .data((d: any) =>  d[1])
          .join((group): any => {
            const enter = group.append('g').attr('class', 'dotGroup' + myClass);
            enter.append('circle').attr('class', 'dataDot');
            return enter;
          });

      dotGroup
        .attr('id', (d: any) => 'dot' + d.dateIndex + "-" + d.data[0][myChart.props.groupVar].toLowerCase().replace(/ /g,'').replace(/\//g, '_'))

      dotGroup.select('.dataDot')
          .attr('id', (d: any) => 'date' + d.dateIndex)
          .attr('r', 4)
          .attr('stroke-width', 2)
          .attr('stroke', 'white')
          .attr('pointer-events', 'none')
          .attr('visibility', 'hidden')
          .attr('fill', (d: any) => lineColors[d.data[0][myChart.props.groupVar]])
          .attr('cx', (d: any) => xScale(d.date))
          .attr('cy', (d: any) => yScale(d.total));

      const dotGroup2 =  dotsGroup.select('.dotGroup2')
        .selectAll('.dotGroup2' + myClass)
        .data(  (d: any)  =>  myChart.props.showAreaLegend ? d[1] : [])
        .join((group): any => {
          const enter = group.append('g').attr('class', 'dotGroup2' + myClass);
          enter.append('circle').attr('class', 'dataDot2');
          return enter;
        });

      dotGroup2.attr('id', (d: any) => 'dot' + d.dateIndex + "-" + d.data[0][myChart.props.groupVar].toLowerCase().replace(/ /g,'').replace(/\//g, '_'))

      dotGroup2.select('.dataDot2')
        .attr('id', (d: any) => 'date' + d.dateIndex)
        .attr('r', 4)
        .attr('stroke-width', 2)
        .attr('stroke', 'white')
        .attr('pointer-events', 'none')
        .attr('visibility', 'hidden')
        .attr('fill', (d: any) => lineColors[d.data[0][myChart.props.groupVar]])
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScale(d.secondTotal));
    }

    function brushSelectedStart(): void {
      // clears everything from brush selection to get started
      mySvg.select('#selectionLeft' + myClass)
          .text('');

      mySvg.select('#selectionRight' + myClass)
          .text('');

      mySvg.select('#arrowLeft' + myClass)
          .attr('d', '');

      mySvg.select('#arrowRight' + myClass)
          .attr('d', '');

      mySvg.select('#brushSelectionDateRect' + myClass)
          .attr('width', 0);

      mySvg.select('#brushSelectionDateRectCloseLabel' + myClass)
          .text('');

      mySvg.select('.brushSelectionDateRectLabel' + myClass)
          .text('');
    }
    function brushedSelected(event: any): void {

      const selection = event.selection;
      if (selection !== null  && event.mode !== undefined) {
        // re-style selection box for the brush selection
        mySvg.selectAll('#brushSelectionGroup' + myClass)
          .selectAll('.selection').attr('class', 'selection lineSelectionBox');

        mySvg.selectAll('.lineSelectionBox')
          .style('fill', '#1363DF')
          .style('fill-opacity', '0.1')
          .style('stroke', '#1363DF')
          .style('stroke-width', '1')
          .style('stroke-dasharray', '15,15');

        //hide handles and tooltip, dotline and data dots
        mySvg.selectAll('#brushSelectionGroup' + myClass)
          .selectAll('.handle')
          .style('width', 0)
          .style('height', 0);
        myChart.hideTooltip('lineDot');
        mySvg.selectAll('.dataDot').attr('visibility', 'hidden');
        mySvg.selectAll('.dataDot2').attr('visibility', 'hidden');
        mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
        // invert scales to get x and y domain
        const xDomain = [selection[0][0], selection[1][0]].map(xScale.invert);
        const yDomain = [selection[0][1], selection[1][1]].map(yScale.invert);
        // hard clone data and find re-format date (as string when cloned)
        let filteredData = JSON.parse(JSON.stringify(flatChartData));
        filteredData.map(m => m.date = new Date(m.date));
        // filter to current selection data
        filteredData = filteredData.filter(f => f.date >= xDomain[0] && f.date <= xDomain[1]);
        let selectedLineSet = new Set();
        // build selected Line Set
        filteredData.filter(f => f.firstVar >= yDomain[1] && f.firstVar <= yDomain[0]).forEach(d => selectedLineSet.add(d[myChart.props.groupVar]));
        selectedLineSet = Array.from(selectedLineSet);
        // filter so only selected line set data
        filteredData = filteredData.filter(f => selectedLineSet.indexOf(f[myChart.props.groupVar]) > -1);
        if (filteredData.length > 1){
          // if there are still values
          let sources = new Set();
          selectedSourceData = [];
          // build the source set and get extent
          filteredData.forEach(f => sources.add(f[myChart.props.groupVar]));
          sources = Array.from(sources);
          const extent = d3.extent(filteredData, (e: any) => e.date);
          const first = extent[0];
          const last = extent[1];
          sources.forEach((s: any) => {
            // for each sorce, get the start + end value and build the source data for this group
            let matchingData = filteredData.filter((f: any) => f[myChart.props.groupVar] === s);
            matchingData = matchingData.sort((a: any, b: any) => d3.ascending(a.date, b.date));
            const mySourceData = {
              startValue: matchingData[0].firstVar,
              endValue: matchingData[matchingData.length - 1].firstVar,
            };
            // value, average, change, percent change - all used in tooltip
            mySourceData[myChart.props.groupVar] = s
            mySourceData.average = d3.mean(matchingData, (m: any) => m.firstVar);
            mySourceData.change = mySourceData.endValue - mySourceData.startValue;
            mySourceData.percentChange = mySourceData.change / mySourceData.startValue;
            selectedSourceData.push(mySourceData);
          });
          // redrawing legend so the extra things are shown
          drawLegend(currentLegendItems,myChart.props.showAreaLegend);
          // work out position last (and whether it is in range with other brush selection)
          let positionLast = xScale(last);
          if ((xScale(last) - xScale(first)) < brushDateRectMinWidth){
            positionLast = xScale(first) + brushDateRectMinWidth;
          }
          // calculate new extent and move brush selection
          // this snaps the users selection to the  actual bounds of the data
          // ie the user is unlikely to have got the x postion of an exact date in the data
          const dateFormat = d3.timeFormat('%d %b %Y');
          const newExtent = [[xScale(first), selection[0][1]], [positionLast, selection[1][1]]];
          d3.select(this).call(brushSelection.move, newExtent);
          // set the parameters and properties for the date rect and labels.
              mySvg.select('.brushSelectionDateRectLabel' + myClass)
              .attr('transform', 'translate(' + (xScale(first) + margins.left + 5) + ',' + (margins.top + 15 + selection[0][1]) + ')')
              .text(dateFormat(first) + ' - ' + dateFormat(last))
              .call(wrap, positionLast - xScale(first) - 20);

          const noOfTspans =  d3.select('.brushSelectionDateRectLabel' + myClass).selectAll("tspan")._groups[0].length;

          mySvg.select('#brushSelectionDateRect' + myClass)
            .attr('x', xScale(first))
            .attr('y', selection[0][1])
            .attr('width', positionLast - xScale(first))
            .attr("height", noOfTspans > 1 ? 35 : 22);

          mySvg.select('#brushSelectionDateRectCloseLabel' + myClass)
              .text('x')
              .attr('transform', 'translate(' + (xScale(first) + margins.left
                  + positionLast - xScale(first) - 10) + ',' + (margins.top + selection[0][1]) + ')');
        }  else {
          // if no data in selection, hide the brush
          hideSelectBrush();
        }
      }
    }
    function hideSelectBrush(): void {
      // reset all properties so selection brush is hidden
      mySvg.select('#brushSelectionDateRect' + myClass)
          .attr('width', 0);

      mySvg.select('#brushSelectionDateRectCloseLabel' + myClass)
          .text('');

      mySvg.select('.brushSelectionDateRectLabel' + myClass)
          .text('');

      mySvg.select('#brushSelectionGroup' + myClass)
          .call(brushSelection.move, [[0, 0], [0, 0]]);
      mySvg.select('#selectionLeft' + myClass)
          .text('');

      mySvg.select('#selectionRight' + myClass)
          .text('');

      mySvg.select('#arrowLeft' + myClass)
          .attr('d', '');

      mySvg.select('#arrowRight' + myClass)
          .attr('d', '');

    }
    function getDotData(chartData: any)  {
      const dotData = [];
      chartData.forEach((d: any) => {
        d[1].forEach((c: any, i: any) => {
          if(c.firstVar !== undefined){
            if(i === 0){
              if (!d[1][1] || d[1][1].firstVar === undefined){
                dotData.push([d[0],c])
              }
            } else if (i === d[1].length - 1){
              if (d[1][i - 1].firstVar === undefined){
                dotData.push([d[0],c])
              }
            } else if(d[1][i - 1].firstVar === undefined &&
              d[1][i+1].firstVar === undefined){
              dotData.push([d[0], c])
            }
          }
        })
      })
      return dotData;
    }
    function brushed(event: any): void {
      // this is the normal brush!!!
      const selection = event.selection;
      if(myChart.lineData.length === 0){
        return // which does not render if no data (soon to be not needed)
      }
      if (selection !== null) {
        if(myChart.props.yBrush){
          yScale.domain([
            yScaleAll.invert(selection[1][1]),
            yScaleAll.invert(selection[0][1]),
          ]);
        }
        // always hide select brush first and tooltip
        if (event.sourceEvent !== undefined  && myChart.props.chartBrushAndPercents === true){
          hideSelectBrush();
        }
        myChart.hideTooltip('lineDot');
        // reset xdomain to current selection
        const xDomain = myChart.props.yBrush ? [
          xScaleAll.invert(selection[0][0]),
          xScaleAll.invert(selection[1][0]),
        ] : selection.map(xScaleAll.invert);
        const dataRemaining: any = dateSet.filter((f: any) => new Date(f) >= xDomain[0] && new Date(f) <= xDomain[1]).length;
        // filter data to new selection
        if(dataRemaining < 1) {
          // if less than one data point left, reset to previous selection
          const previousStart = xScaleAll(xScale.domain()[0]);
          const previousEnd = xScaleAll(xScale.domain()[1]);
          let brushMove = [previousStart, previousEnd];
          if(myChart.props.yBrush){
            const previousStartY = yScaleAll(yScale.domain()[0]);
            const previousEndY = yScaleAll(yScale.domain()[1]);
            brushMove = [[previousStart, previousEndY],
              [previousEnd, previousStartY]];
          }
            mySvg.select('#brushGroup' + myClass)
              //@ts-ignore
              .call(brush.move, myChart.props.showBrush === false ? null
                : brushMove);
        } else {
          // otherwise...
          // set partial period visibility (only if the last data point is visible)
          if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          } else {
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility",  "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility","hidden")
          }
          // reset xScale, xAsis and some styling
          xScale.domain(xDomain);
          mySvg.select('#xAxis' + myClass)
            .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(tickValues).ticks(tickCount).tickFormat(xTickFormat))
            .attr('transform', 'translate(' + (margins.left || 0) + ',' + (margins.top + chartHeight) + ')');

          mySvg.selectAll('#xAxis' + myClass + ' line')
            .style('stroke-width', (d: any) => (d >= xDomain[0] && d <= xDomain[1]) ? 1 : 0);

          mySvg.selectAll('#xAxis' + myClass + ' text')
            .attr('visibility', (d: any) => d >= xDomain[0] && d <= xDomain[1] ? 'visible' : 'hidden');

          drawChart();
          if(!myChart.props.yBrush){
            // reset handle position - see bar chart for further explanation
            const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
            const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');

            mySvg.select('.handleLeftLine1' + myClass)
              .attr('x1', handleEastX + 3)
              .attr('x2', handleEastX + 3);

            mySvg.select('.handleLeftLine2' + myClass)
              .attr('x1', handleEastX + 7)
              .attr('x2', handleEastX + 7);

            mySvg.select('.handleRightLine1' + myClass)
              .attr('x1', handleWestX + 3)
              .attr('x2', handleWestX + 3);

            mySvg.select('.handleRightLine2' + myClass)
              .attr('x1', handleWestX + 7)
              .attr('x2', handleWestX + 7);

            mySvg.select('#brushGroup' + myClass)
              .selectAll('.handle')
              .attr('visibility', allData.length === 0 ? 'hidden' : 'visible')
              .attr('fill', 'white')
              .attr('rx', 4)
              .attr('ry', 4)
              .attr('y', (margins.brush - 24) / 2)
              .attr('height', 24)
              .style('filter', 'url(#drop-shadow)');
          }
        }
      }
    }

    function measureWidth(myText: any, myFontSize: any): any {
      const context = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }


    function drawLegend(myLegendItems: any, drawArea: any): void {

      // legend the same as other charts
      // EXCEPT additional icon (legendDirectionIcon) and label which is used after a brush selection and explained above
      const legendGroup = mySvg
          .select('#legendGroup' + myClass)
          .selectAll('.legendGroup' + myClass)
          .data(myLegendItems)
          .join((group): any => {
            const enter = group.append('g').attr('class', 'legendGroup' + myClass);
            enter.append('circle').attr('class', 'legendCircle');
            enter.append('text').attr('class', 'legendLabel' + myClass);
            // cannot remember if you have seen the way I am using icons before or not
            // it is an svg (which gets positioned/sized) with a path (which gets icon properties and the path)
            enter.append('svg').attr('class', 'legendDirectionIconSvg').append('path').attr('class', 'legendDirectionIcon');
            enter.append('text').attr('class', 'legendChangeLabel');
            return enter;
          });

      legendGroup.select('.legendDirectionIcon')
          .attr( 'd', function(d: any): any {
            const isSelected = selectedSourceData.find(f => f[myChart.props.groupVar] === d.value);
            if (isSelected === undefined  || myChart.props.displayType === 'value'){
              return '';
            } else {
              if (isSelected.dateValue === undefined){
                d3.select(this).style('fill', isSelected.change >= 0 ? '#3AC97C' : '#D11044');
                return isSelected.change >= 0 ? upIconPath : downIconPath;
              } else {
                return '';
              }
            }
          });

      legendGroup.select('.legendChangeLabel')
          .attr('id', (d: any) => 'changeLabel_legendItem' + d.index + myClass)
          .attr('y', margins.legend + 18)
          .attr('font-weight', '700')
          .attr('font-size', '10')
          .attr('font-family', 'Poppins')
          .attr('pointer-events', 'none')
          .text(function(d: any): any{
            const isSelected = selectedSourceData.find(f => f[myChart.props.groupVar] === d.value);
            if (isSelected === undefined){
              return '';
            } else if (myChart.props.displayType === 'percent'){
              d3.select(this)
                  .attr('dx', 0)
                  .style('fill', isSelected.change >= 0 ? '#3AC97C' : '#D11044');
              return  d3.format('.2~%')(isSelected.percentChange);
            } else {
              d3.select(this)
                  .attr('dx', -14)
                  .style('fill',  '#101D42');
              return  'Avg: ' + d3.format('.2s')(isSelected.average);

            }
          });

      legendGroup.select('.legendDirectionIconSvg')
          .attr('id', (d: any) => 'icon_legendItem' + d.index + myClass)
          .attr( 'pointer-events', 'none')
          .attr('y',  margins.legend + 10)
          .attr('viewBox',  iconViewBox)
          .attr( 'width',  10)
          .attr( 'height', 10);

      legendGroup.select('.legendCircle')
          .attr('id', (d: any) => 'circle_legendItem' + d.index + myClass)
          .attr('cy', margins.legend - 4)
          .attr('fill', d => lineColors[d.value])
          .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
          .attr('id', (d: any) => 'legendItem' + d.index + myClass)
          .attr('y', margins.legend + 1)
          .attr('fill', '#101D42')
          .attr('font-weight', '500')
          .attr('font-size', '12')
          .attr('font-family', 'Poppins')
          .attr('cursor', d => d.label.includes('..') ? 'pointer' : 'default')
          .text(d => d.label)
          .on('mousemove', (event: any, d: any) => {
            // mySvg.select('.wrapTooltipRect' + myClass)
            //   .attr('width', 0);
            mySvg.select('.wrapTooltipLabel' + myClass).text('');
            if (d.label.includes('..')){
              mySvg.select('.wrapTooltipRect' + myClass)
                  .attr('width', d.valueLength + 18)
                  .attr('x', (event.offsetX + 10) + 'px')
                  .attr('y', event.offsetY + 'px');

              mySvg.select('.wrapTooltipLabel' + myClass)
                  .attr('x', (event.offsetX + 15) + 'px')
                  .attr('y', (event.offsetY + 11) + 'px')
                  .text(d.value);
            }
          })
          .on('mouseout', () => {
            mySvg.select('.wrapTooltipRect' + myClass)
                .attr('width', 0);
            mySvg.select('.wrapTooltipLabel' + myClass).text('');
          });

      let legendX = 20;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        const myId = this.id;
        d3.select(this).attr('x', legendX);
        mySvg.select('#circle_' + myId).attr('cx', legendX - 8 - 6);
        mySvg.select('#icon_' + myId).attr('x', legendX);
        mySvg.select('#changeLabel_' + myId).attr('x', legendX + 14);
        legendX += (35 + d.length);
      });
      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');

      // this one is the optional area legend, only shown if secondYVar !== null
      // same format as all other legends - although it is a line (with fill or dasharray) instead of a circle
        const areaLegendData = drawArea === true ? ["line", "dottedLine"] : [];

        const areaLegendGroup = mySvg
          .select('#legendGroup' + myClass)
          .selectAll('.areaLegendGroup' + myClass)
          .data(areaLegendData)
          .join((group): any => {
            const enter = group.append('g').attr('class', 'areaLegendGroup' + myClass);
            enter.append('line').attr('class', 'areaLegendLine');
            enter.append('text').attr('class', 'areaLegendLabel');
            return enter;
          });

        areaLegendGroup.attr("transform", (d: any, i: any) => "translate(" + ((i * 170) + ((width - (170 * areaLegendData.length))/2)) + ",0)")

        areaLegendGroup.select(".areaLegendLine")
          .attr("x1",0)
          .attr("x2", 20)
          .attr("y1", margins.top - 20)
          .attr("y2", margins.top - 20)
          .attr("stroke", "#8A98AB")
          .attr("stroke-width", 3)
          .attr("stroke-dasharray", (d: any) => d === "line" ? "" : "2,2")

        areaLegendGroup.select(".areaLegendLabel")
          .attr('font-weight', '500')
          .attr('font-size', '12')
          .attr('font-family', 'Poppins')
          .attr("x",23)
          .attr("y", margins.top - 16)
          .text((d: any) => myChart.props.areaLabels[d])

    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function(): any {
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'em');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0){
              tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);

            }
          }
        }
      });
      if (lineNumber > 1){
        text.selectAll('tspan').remove();
        text.attr('id', originalValue)
        text.text(originalValue.substr(0, 5) + '..');
      }
    }
    this.stop(10).then(() => this.isLoading = false);
  }
// numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, "", "", "1.0-2");
    //  return   this.numberpipe.transform(value)
  }
  // color generator method
  getRandomColor(count: any) {
    let shades: any = [];
    for (let i = 0; i < count; i++) {
      var length = 6;
      var chars = '0123456789ABCDEF';
      var hex = '#';
      while (length--) hex += chars[(Math.random() * 16) | 0];
      const shade = hex;
      shades.push(shade);
    }
    return shades;
  }
  lightenHexColor(hexColor, percent) {
    // Parse the hex color to RGB values
    const rgbColor = this.hexToRgb(hexColor);
  
    // Calculate the lightened RGB values
    const lightenedColor = rgbColor.map(value => Math.min(Math.round(value + value * percent / 100), 255));
  
    // Convert the lightened RGB values back to hex
    const lightenedHexColor = this.rgbToHex(lightenedColor[0], lightenedColor[1], lightenedColor[2]);
  
    return lightenedHexColor;
  }
  
   hexToRgb(hex) {
    // Remove the hash if it exists
    hex = hex.replace(/^#/, '');
  
    // Parse the hex color to RGB values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    return [r, g, b];
  }
  
  rgbToHex(r, g, b) {
    const toHex = value => {
      const hex = value.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
  
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }
  
  lightColor(array:any) {
    let lightColor:any=[]
    array.forEach((darkColor)=>{
    const val = this.lightenHexColor(darkColor, 60);
    lightColor.push(val)
    })
   return lightColor
  }
}