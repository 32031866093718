import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescriber-change-popup',
  templateUrl: './prescriber-change-popup.component.html',
  styleUrls: ['./prescriber-change-popup.component.scss']
})
export class PrescriberChangePopupComponent implements OnInit {
@Input('prescriberData') prescriberData : any = [ {
  heading:'Prescriber Change',
  date:'18 Jan 2022',
  from:'Hyderabad',
  to:'Chennai'
}]
  constructor() { }

  ngOnInit(): void {
  }

}
