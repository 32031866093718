import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, HostListener, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-widget-card2kpi',
  templateUrl: './card2kpi.component.html',
  styleUrls: ['./card2kpi.component.scss']
})
export class Widget2kpiCardComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  static key = 100102
  @Input('config') config: any = {}
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  infoData: any
  kpi_1_value: any
  kpi_2_value: any

  // showBy Variable
  kpi_2_heading: any
  unit_1_label: any
  kpi_2_heading_value: any
  unit_1_label_value: any
  showBy: any;

  loaderKpi1: boolean = true;
  loaderKpi2: boolean = true;

  reqSubcription: Subscription[] = []
  isOpen: boolean = false;
  cardName: any;
  cardId: any
  presId: any;
  obj: any = {
    header_alignment: {
      name: "BOTTOM",
      value: "bottom"
    }
  }

  constructor(
    private filterService: FilterService,
    private dataService: DataService,
    private apiService: ApiService,
    private currency: CurrencyPipe,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private httpClient: HttpClient,
    private newFilterService: NewFilterService) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
    }))

    this.route.params.subscribe((p: any) => {
      let clientKey = p["clientKey"];
      let moduleKey = p["moduleKey"];
      let dashboardKey = p["dashboardKey"];
      this.cardName = p["cardName"];
      this.cardId = p["cardId"];


      // if(this.cardName == 'prCard') {
      //   this.presId = this.cardId 
      // } else if (this.cardName=='pwt') {
      //   this.route.queryParams.subscribe((params:any) => {
      //     if(this.cardId == 'prCard') {
      //       this.presId = params.presId 
      //     }
      //   })
      // }
    })

    this.newFilterService.showBy.subscribe((value: any) => {
      this.showBy = value
      if (value == "bottles") {
        this.unit_1_label = this.item.config.unit_1_label
        this.kpi_2_heading = this.item.config.kpi_2_heading
        this.kpi_1_value = this.unit_1_label_value
        this.kpi_2_value = this.kpi_2_heading_value
      } else if (value == "tablets") {
        this.unit_1_label = this.item.config.kpi_2_heading
        this.kpi_2_heading = this.item.config.unit_1_label
        this.kpi_1_value = this.kpi_2_heading_value
        this.kpi_2_value = this.unit_1_label_value
      }
    })
  }

  ngOnInit(): void {
    if (!this.item.config.header_alignment) {
      Object.assign(this.item.config, this.obj)
    }
  }

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger.closeMenu();
  }

  getInfoDetail() {
    let data = {
      api_key: this.item.config.kpi_2_value_api?.api_key || 0,
      widget_key: this.item.config.widgetKey,
      page_key: this.pageKey,
      report_typ: 'D',
      brand_key: this.filterService.baseQuery?.brand_key
    }
    this.reqSubcription.push(this.dataService.getInfoDetail(data).subscribe((res: any) => {
      this.infoData = res && res[0] ? res[0] : { info_header: "No Info Data Found" }
    }))
    return this.isOpen = !this.isOpen
  }

  //call API to get the data
  loadApiData() {
    let kpi1 = this.item.config['kpi_1_value_api']
    let kpi2 = this.item.config['kpi_2_value_api']
    this.loaderKpi1 = true;
    this.loaderKpi2 = true;


    if (kpi1 && kpi1.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj: any = { "api_key": kpi1.api_key } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          if (res && res.length > 0) {
            let selectedColumn = this.item.config['kpi_1_value_api_col']?.['api_resp_column_nm']
            this.kpi_1_value = res[0][selectedColumn]
            this.unit_1_label_value = res[0][selectedColumn]
            // this.loaderKpi1 = false;
          } else {
            this.kpi_1_value = ""
            // this.loaderKpi1 = false;
          }
          this.loaderKpi1 = false;
        }, (err: any) => {
          if (err.status == 200) {
            // this.loaderKpi1 = false;
          }
          this.loaderKpi1 = false;
          console.log('Kpi 1 error', err)
          this.kpi_1_value = "";
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res:any)=>{
      //   if (res && res.length>0) {
      //     let selectedColumn = this.item.config['kpi_1_value_api_col']?.['api_resp_column_nm']
      //     this.kpi_1_value = res[0][selectedColumn]
      //     this.unit_1_label_value =  res[0][selectedColumn]
      //     // this.loaderKpi1 = false;
      //   } else {
      //     this.kpi_1_value = ""
      //     // this.loaderKpi1 = false;
      //   }

      // },(err:any)=>{
      //   if(err.status==200) {
      //     // this.loaderKpi1 = false;
      //   }
      //   console.log('Kpi 1 error', err)
      //   this.kpi_1_value = "";
      // }))
    }

    if (kpi2 && kpi2.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj2: any = { "api_key": kpi2.api_key } // 'prescriber_id' : this.presId

      let query2 = Object.assign({}, this.filterService.baseQuery, obj2)
      query2["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res: any) => {
          if (res && res.length > 0) {
            this.kpi_2_value = res[0][this.item.config['kpi_2_value_api_col']?.['api_resp_column_nm']]
            this.kpi_2_heading_value = res[0][this.item.config['kpi_2_value_api_col']?.['api_resp_column_nm']]
          } else {
            this.kpi_2_value = ""
          }
          this.loaderKpi2 = false;
          this.cdr.detectChanges()

          if (this.showBy == "bottles") {
            this.unit_1_label = this.item.config.unit_1_label
            this.kpi_2_heading = this.item.config.kpi_2_heading
            this.kpi_1_value = this.unit_1_label_value
            this.kpi_2_value = this.kpi_2_heading_value
          } else if (this.showBy == "tablets") {
            this.unit_1_label = this.item.config.kpi_2_heading
            this.kpi_2_heading = this.item.config.unit_1_label
            this.kpi_1_value = this.kpi_2_heading_value
            this.kpi_2_value = this.unit_1_label_value
          }

          this.cdr.detectChanges()
        }, err => {
          if (err.status == 200) {
          }
          this.loaderKpi2 = false;
          console.log('Kpi 2 error', err)
          this.kpi_2_value = ""
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj2).subscribe((res:any)=>{
      //   if (res && res.length>0) {
      //     this.kpi_2_value = res[0][this.item.config['kpi_2_value_api_col']?.['api_resp_column_nm']]
      //     this.kpi_2_heading_value = res[0][this.item.config['kpi_2_value_api_col']?.['api_resp_column_nm']]
      //     this.loaderKpi2 = false;
      //   } else {
      //     this.kpi_2_value = ""
      //     this.loaderKpi2 = false;
      //   }
      //   this.cdr.detectChanges()

      //   if (this.showBy == "bottles") {
      //     this.unit_1_label = this.item.config.unit_1_label
      //     this.kpi_2_heading = this.item.config.kpi_2_heading
      //     this.kpi_1_value = this.unit_1_label_value 
      //     this.kpi_2_value =this.kpi_2_heading_value 
      //   }else if (this.showBy == "tablets") {
      //     this.unit_1_label = this.item.config.kpi_2_heading 
      //     this.kpi_2_heading = this.item.config.unit_1_label
      //     this.kpi_1_value = this.kpi_2_heading_value 
      //     this.kpi_2_value =  this.unit_1_label_value
      //   }

      //   this.cdr.detectChanges()
      // },(err:any)=>{
      //   if(err.status==200) {
      //     this.loaderKpi2 = false;
      //   }
      //   console.log('Kpi 2 error', err)
      //   this.kpi_2_value = ""
      // }))
    }

    // show by config
    setTimeout(() => {
      if (this.showBy == "bottles") {
        this.unit_1_label = this.item.config.unit_1_label
        this.kpi_2_heading = this.item.config.kpi_2_heading
        this.kpi_1_value = this.unit_1_label_value
        this.kpi_2_value = this.kpi_2_heading_value
      } else if (this.showBy == "tablets") {
        this.unit_1_label = this.item.config.kpi_2_heading
        this.kpi_2_heading = this.item.config.unit_1_label
        this.kpi_1_value = this.kpi_2_heading_value
        this.kpi_2_value = this.unit_1_label_value
      }
    }, 1500);

  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.item) {
      if (!this.item.config['icon'])
        this.item.config['icon'] = { name: 'question_circle' }
    }
    if (changes['item'].currentValue != changes['item'].previousValue) {
      // this.loadApiData()
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  getStyle(): string {
    return 'background-color:#9900ff'
  }
  getValue(item: any, value: any) {
    return item ? item[value] : ''
  }
  numbedPipe(value: any) {
    if (value == 'NA') return value
    return this.currency.transform(value, '', '', '1.0-2');
    // return   this.numberPipe.transform(value)
  }

  isPortrait = false
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortrait = window.innerWidth < window.innerHeight;
    if (this.isPortrait) {
    } else {
    }
  }

  ngAfterViewInit() {
    this.checkOrientation();
  }
  cancel() {
    document.getElementById("setting")?.click()
  }
}
