import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-area-line-chart',
  templateUrl: './area-line-chart.component.html',
  styleUrls: ['./area-line-chart.component.scss']
})
export class AreaLineChartComponent implements OnInit, OnDestroy {
  static key = 100604;
  
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('areaLineChart', { static: true }) areaLineChart!: ElementRef
  config: any
  data: any
  heading: any
  isDataLoaded = false
  reqSubcription: Subscription[] = []
  constructor(
    private filterService: FilterService,
    private httpClient: HttpClient, 
    private apiService: ApiService
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
    })
    )
  }
  ngOnInit(): void {
    this.item

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.areaLineChart.nativeElement);
    this.loadlineConfig()
  }

  loadlineConfig() {
    this.heading = this.item.config['report_heading']
  }


  loadApiData(reportType?:string) {
    
    this.item.config['list_of_api']="100171"
    let api = this.item.config['list_of_api'] || undefined
    if((this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {
      
      // API Payloads
      let obj:any = {"api_key": api || "100171", "time_cycle": this.item.config?.default_timeCycle, "report_typ": this.filterService.report_type}
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          
          this.isDataLoaded = true
          this.data = res == null ? [] : res
          
        },(err:any)=>{
          console.log(err)
          this.data = []
          this.isDataLoaded = true;
        })
      )

      // this.reqSubcription.push(this.filterService.selfTimeCycleExecuteQuery(obj).subscribe((res:any)=>{
      //   this.isDataLoaded = true
      //   this.data = res == null ? [] : res
      // }, (error: any) => {
      //   this.data = []
      // }))
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

}