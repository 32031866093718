<div  class="widget-container"  #comboBarChart >
    <app-comboBar-chart 
    [headerConfig]="this.item?.config?.widget_header_config"
    [heading]="heading"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [data]="data"
    >
    </app-comboBar-chart>
</div>
