import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class DataService {
  patientIdArray = new BehaviorSubject<any>('')
  chartData = new BehaviorSubject<any>([])
  radarData = new BehaviorSubject<any>([])
  alertSettingToggle = new BehaviorSubject<any>(false)
  expandCollapseChart = new BehaviorSubject<boolean>(false)
  choroplethData = new BehaviorSubject<any>([])
  
  getState = new Subject<any>();
  backToDefault = new Subject<any>();
  showLocationLevelData = new Subject<any>();
  
  
  showStateLevelData = new Subject<any>();




  getListOfWork(arg0: string) {
    throw new Error("Method not implemented.");
  }
  constructor(
    private apiService: ApiService,
    private helperService: HelperService
  ) {}

  getData(url:any): Observable<any> {
    return this.apiService.getDoc(url);
  }

  getWorkList() {
    return this.apiService.get(environment.getListOfWork);
  }
  insertWork(data: any) {
    return this.apiService.post(environment.addNewWorkList, data);
  }
  getWorkDetail(workKey: any, version: any) {
    let data = {
      work_key: workKey,
      version_number: version,
    };
    return this.apiService.post(environment.getWorkDetails, data);
  }
  getInfoDetail(data: any) {   
    return this.apiService.post(environment.infoIconText, data);
  }

  getWidgetList() {
    return this.apiService.get(environment.apiListEndPoint);
  }

  getWidgetConfig(widget: any) {
    return this.apiService.post(environment.widgetConfigEndPoint, {
      widgetKey: widget.key,
    });
  }

  getPagesByWorkId(workKey: any, version: any) {
    let data = {
      work_key: workKey,
      version_number: version,
    };
    return this.apiService.post(environment.pageListEndPoint, data);
  }

  getApiColumns(widgetConfigKey: string, key: string, value: string) {
    let postData = {
      widgetConfigKey: widgetConfigKey,
      keyValuePairs: {
        [key]: value,
      },
    };
    return this.apiService.post(environment.apiColumnListEndPoint, postData);
  }

  getFilter(data: any[], paramName: any) {
    if (data.length == 0) return null;
    let filter = "";
    data.map((e) => {
      filter += e[paramName] + ",";
    });
    return filter.slice(0, filter.lastIndexOf(","));
  }

  getDashboardList() {
    return this.getJson("dashboard-list");
  }

  getUserMenu() {
    return this.getJson("userMenu");
  }

  getJson(url: string) {
    return this.apiService.getDoc(url);
  }
  getConfigType() {
    return this.apiService.get(environment.ConfigTypeEndPoint);
  }
  getEnvironmentType() {
    return this.apiService.get(environment.EnvironmentEndPoint);
  }
  getPatientList() {
    return this.apiService.get(environment.patientListFRM)
  }
  
  getTimeCycleData(query:any){
    let data = {} 
    data = query
    return this.apiService.post(environment.getCycleData, data)
  }
  
  getExportExcel(query:any){
    return this.apiService.postBlob(environment.serverSideExcelExport, query);
  }

  getUserInfo(query:any){
    let data = {
      "user_id" : query
    }
    return this.apiService.post(environment.getUserInfo, data)
  }

  getBrandStartDate(data:any) {
    var client_key: any = sessionStorage.getItem("client");
    let value = JSON.parse(client_key);
   
    var empkey: any = sessionStorage.getItem("employee_key");
    let employee_key = JSON.parse(empkey);

    let query: any = {
      "brand_key": data.brand_key,
      "client_key": value[0].client.client_key || 0,
      "employee_key": employee_key._value,
    }

    if (!query.brand_key) return new Observable((ob) => ob.next(undefined));
    return this.apiService.post(environment.getBrandDate, query);

  }
}
