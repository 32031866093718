<div class="widget-container" #linkCardDiv>
    <div>
    <h3>{{item.config['link_card_title']||''}}</h3>
    </div>
    <div>
    <img [src]="item.config['link_card_img_url']" class="img-fluid" alt="Place image title" style="display: block;margin: 0 auto;">
    </div>
    <div style="padding: 5px 0;">
        <a [href]="item.config['link_card_btn_link']">
            <button type="button" style="border-radius: 10px;" [style.background]="item.config['link_card_btn_bgcolor']" [style.color]="item.config['link_card_btn_color']" class="btn btn-block">{{item.config['link_card_btn_name']||''}}</button>
        </a>    
    </div>
</div>
