import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { Subscription } from "rxjs/internal/Subscription";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";
import { NewFilterService } from "src/app/services/new-filter.service";
import { BarChartTempComponent } from "../../shared/charts/barchart/barchart.component";
import { CustomHeader } from "../../shared/charts/sparklines-Grid/custom-header.component";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { UserActivityService } from "src/app/services/useractivity.service";

@Component({
  selector: "app-sparklineBar-chart-card",
  templateUrl: "./sparklineBar-chart-card.component.html",
  styleUrls: ["./sparklineBar-chart-card.component.scss"],
})
export class WidgetSparklineBarChartComponent implements OnInit, OnDestroy {
  static key = 100507;
  
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  commentsData: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("SparklinesgridCharDiv", { static: true })
  SparklinesgridCharDiv!: ElementRef;
  config: any;
  data: any;
  fieldData: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  sourceName: any;
  ColumnData: any
  DataValue: any
  rolling_Days: any
  rawData:any=[]

  constructor(
    private filterService: FilterService,
    private newFilterService: NewFilterService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private deviceDetectionService: DeviceDetectionService,
    private userActivityService: UserActivityService
  ) {
    this.route.params.subscribe((p: any) => {
      this.sourceName = p["cardName"] || '';
    });
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData(this.filterService.showByKey);
        let start_Date: any = this.filterService.baseQuery['from_date'];
        let end_Date: any = this.filterService.baseQuery['to_date'];
        let intervalType = this.filterService.report_type; // Possible values: 'D', 'W', 'M', 'Q', 'Y'

        switch (intervalType) {
            case 'D':
                this.rolling_Days = moment(end_Date).diff(start_Date, 'days');
                break;
            case 'W':
                this.rolling_Days = moment(end_Date).diff(start_Date, 'weeks');
                break;
            case 'M':
                this.rolling_Days = moment(end_Date).diff(start_Date, 'months');
                break;
            case 'Q':
                this.rolling_Days = moment(end_Date).diff(start_Date, 'quarters');
                break;
            case 'Y':
                this.rolling_Days = moment(end_Date).diff(start_Date, 'years');
                break;
            default:
                this.rolling_Days = 0; // or handle default case
        }
                // this.rolling_Days = moment(end_Date).diff(start_Date, 'weeks');
              })
    );
    this.reqSubcription.push(
    this.newFilterService.showBy.subscribe((value: any) => {
      this.loadApiData(value);

    }))
  }

  ngOnInit(): void {
    // console.log(this.item)
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.SparklinesgridCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
    this.config = {
      xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
      yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
      valuecolumn: 'dispense_quantity',
      valuecolumn2: 'inventory_quantity',
      difference:'difference',
      cumulativecolumn:'dispense_cumulative_qty',
      cumulativecolumn2:'inventory_cumulative_qty',
      cumulativeDifference:'cumulative_qty_difference'
    }
  }

  loadApiData(showby?: any) {
    let getField: any
    let locationRT: any
    let sourceRT: any

debugger
    getField = this.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "READ FIELDS")
    locationRT = this.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "SELECT LOCATION")
    sourceRT = this.item.config?.['report_type_selection'].find((val: any) => val.action_typ == "SELECT SOURCE")

    let api = this.item.config['list_of_api'] || 'APIKeyNotFound'
    let commentapi = this.item.config['comments_api'].comments_api_key || 'APIKeyNotFound'


    // ir Source 100130
    // irlocation 100131
    if (api.api_key && this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payloads
      let obj2: any = { "api_key": api.api_key, "report_typ": "R", "show_by_type": showby }
      let query1 = Object.assign({}, this.filterService.baseQuery, obj2)
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res: any) => {
          this.isDataLoaded = true;
          this.ColumnData =  res;
          if (this.ColumnData.length==0) {
            this.fieldData = [];
            console.log( 'IR field no Data')
          }
            //  child API Payloads
            let userState: any = this.userActivityService.getData();

            let obj1: any = { "api_key": api.api_key, "report_typ":(this.filterService.showByKey==userState['showBy'+ this.item.config.showby_name] )?this.filterService.showByKey : (userState['showBy'+ this.item.config.showby_name] ? userState['showBy'+ this.item.config.showby_name]:this.filterService.defaultReport_type )}
            let query = Object.assign({}, this.filterService.baseQuery, obj1)
            console.log( 'sparkline line chrat',query["report_typ"])
            this.reqSubcription.push(
              this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
                this.rawData=res
                this.DataValue =  res;
                this.passData(showby)
              }, (err: any) => {
                this.data = [];
                console.log( 'IR Data Api Error', err)
              })
            )
           //end
          // this.passData(showby)
        }, (err: any) => {
          console.log( 'IR field Api Error', err)
          this.fieldData = [];
        })
      )
    


    }

    //  comment Api Config
    let reportType = ''
    let showByValue:any
   let ndc_key:any
    let userState: any = this.userActivityService.getData();
    let ir_calc_mode =(this.filterService.showByKey==userState['showBy'+ this.item.config.showby_name] )?this.filterService.showByKey : (userState['showBy'+ this.item.config.showby_name] ? userState['showBy'+ this.item.config.showby_name]:this.filterService.defaultReport_type )
    if (this.sourceName) {
      reportType =  locationRT?.report_typ || 'S'
      showByValue = showby
      
    } else {
      reportType = sourceRT?.report_typ || 'S'
      showByValue = null // undefined
      ndc_key=null
    }

    let postdata:any = { report_typ: reportType, comment_typ: 'IR', api_key: commentapi ,show_by_type:showByValue,"ir_calc_mode":ir_calc_mode, };
    if (this.sourceName) {
    } else {
      postdata['ndc_key']=ndc_key
    }
    this.reqSubcription.push(this.filterService.commentsQuery(postdata).subscribe((res: any) => {
      this.commentsData = res;
    }, err => {
      console.log( 'IR comment api ',err)
    }));
  }

  
  passData(showby: any) {
    // debugger
    if(this.DataValue?.length==0){this.data=[]}
    if(this.ColumnData?.length==0){this.fieldData=[];this.data=[]}
    if (this.ColumnData?.length > 0 && this.DataValue?.length > 0) {
      setTimeout(() => {
        this.fieldData = this.loadDataGridConfig(this.ColumnData)
        this.DataValue = this.groupByData(this.DataValue, this.config.yAxisVar, showby == 'N' ? 'ndc_key' : this.config.xAxisVar)
        this.data = this.pivotData(this.DataValue, this.config.yAxisVar, showby == 'N' ? 'ndc_key' : this.config.xAxisVar)
        this.DataValue = []
        this.ColumnData = []
      }, 50);

    }
  }
  loadDataGridConfig(FieldData: any) {
    let sourceName = FieldData
    let timeCycleLable:any={
      D:' days',
      W:' weeks',
      M:' months',
      Q:' quarters',
      Y:' years'
    }
    let timeCycleFormat:any={
      D:'DD MMMM yyyy',
      W:'DD MMMM yyyy',
      M:'MMMM yyyy',
      Q:'MMMM yyyy',
      Y:'yyyy'
    }
let timeCycleVar:any = timeCycleLable[this.filterService.report_type]
    let def: any = [
      {
        field: this.config.yAxisVar, minWidth: 200, valueFormatter: (params: any) => {
          if (moment(params.value).format('DD MMMM yyyy') == '01 January 1900') {
            return "Rolling " + (this.rolling_Days + 1) + ((this.rolling_Days + 1)==1 ? timeCycleVar.replace('s','') : timeCycleVar)
            // return "Overall (" + moment(new Date()).format('yyyy') +')'
          } else {
            return moment(params.value).format(timeCycleFormat[this.filterService.report_type])
          }
        },
        suppressMenu: true, pinned: this.deviceDetectionService.isMobile ? 'none' : 'left', cellStyle: { "text-align": "left", "line-height":`${ ((this.item.config?.cumulative?.value)=='true') ?'160px' : '80px'  }`, "height": "100% ", "vertical-align": "middle" }
      },
      // { field: 'Amber',  minWidth: 215 , cellRendererFramework : BarChartTempComponent ,
      //  cellRendererParams  :((params: any) => { params.commentsData = this.commentsData 
      // return  { params} }),suppressMenu: true,  headerComponent: CustomHeader, cellStyle: { "text-align": "left" },},
    ];
    let field: any
    for (let i = 0; i < sourceName.length; i++) {
      field = this.config.xAxisVar
      def.push({
        headerName: sourceName[i]['header_nm'],
        field: sourceName[i][field], minWidth: 215, cellRenderer: BarChartTempComponent,
        cellRendererParams: ((params: any) => {
          params.commentsData = this.commentsData
          params.sourceName = this.sourceName
          params.item = this.item
          return { params }
        }), suppressMenu: true, headerComponent: CustomHeader, headerComponentParams: {
          item: this.item
        }, cellStyle: { "text-align": "left" },
      })

    }
    return def
    // this.columnApi.setRowGroupColumns([]);
  }
  groupByData(data: any, rowKey: string, columnKey: string) {
    let sumObject: any = {};

    // Iterate through the array and sum up the values based on sp_location_name and date
    data.forEach((item: any) => {
      const key = item[columnKey] + '|' + item[rowKey];
      if (sumObject[key]) {
        sumObject[key][this.config.valuecolumn] += item[this.config.valuecolumn]; // Add dispense_qty to existing sum if key already exists
        sumObject[key][this.config.valuecolumn2] += item[this.config.valuecolumn2]; // Add inventory_qty to existing sum if key already exists
        sumObject[key][this.config.difference] += item[this.config.difference];
        sumObject[key][this.config.cumulativecolumn] += item[this.config.cumulativecolumn]; // Add cumulative dispense_qty to existing sum if key already exists
        sumObject[key][this.config.cumulativecolumn2] += item[this.config.cumulativecolumn2]; // Add cumulative inventory_qty to existing sum if key already exists
        sumObject[key][this.config.cumulativeDifference] += item[this.config.cumulativeDifference];
        // Add other properties to sum up as needed
      } else {
        sumObject[key] = { ...item }; // Initialize sum if key doesn't exist
      }
    });

    // Convert the sumObject back to an array of objects
    let GroupedData = Object.values(sumObject);
    // console.log(GroupedData);
    return GroupedData
  }
  pivotData(originalData: any, rowKey: string, columnKey: string) {
    const pivotedData: any = {};
    originalData.forEach(function (item: any, index: any) {
      const rowValue = item[rowKey];
      const columnValue = item[columnKey];
      if (!pivotedData[rowValue]) { pivotedData[rowValue] = {} }
      pivotedData[rowValue][columnValue.toLowerCase()] = item;
      pivotedData[rowValue][rowKey] = item[rowKey];
      delete item[rowKey];
      // delete item[columnKey];
    });
    return Object.values(pivotedData);
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
