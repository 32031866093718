<main>
  <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff">
    <loading *ngIf="loader"></loading>
    <div class="widget-container" #paymentHGridDiv>
      
      <div class="mb-1" [style.padding]="this.isIcon ? '' : '10px 0'">
        <icon-chart 
          [headerConfig]="headerConfig" 
          [iconList]="iconList" 
          [data]="rowData" 
          [pageKey]="pageKey"
          [heading]="heading" 
          [chartName]="'PaymentGrid'" 
          [config]="paymentHGridDiv" 
          [expand]="fs"
          [apiKey]="this.item.config['list_of_api_parent'].api_key"
          [fullscreen]="isFullscreen" 
          [item]="item" 
          (typeToSearch)="onQuickFilterChanged($event)"
          (screenChanges)="screenChanges($event)"
          (expendCol)="expendCol($event)">
        </icon-chart>
      </div>
      <!-- <button style="font-size: 12px" (click)="onBtStartEditing()">
        Start Editing Line 2
      </button> -->
      <div id="pdfAgTable" #pdfTable>
        <ag-grid-angular 
          #paymentGrid 
          style="width: 100%; height: 100%;margin-bottom: 15px;"
          class="ag-theme-alpine test recordErrorPaymentHira" 
          [class.recordErrorSideBarNot]="isPivotGrid"
          [class.recordErrorSideBar]="!isPivotGrid"
          [class]="rowGroupingDisplayIcon"
          [class.gridRowColor]="gridRowColor"
          [sideBar]="isPivotGrid ? sideBar : false"
          [class.paginationBottom]="pagination"
          [suppressDragLeaveHidesColumns]="true"
          [columnDefs]="columnDefs" 
          [defaultColDef]="defaultColDef"
          [animateRows]="true"
          [masterDetail]="isDetailGrid" 
          [detailRowAutoHeight]="detailRowAutoHeight" 
          [suppressAggFuncInHeader]="true"
          [groupRowRendererParams]="groupRowRendererParams"
          [rowHeight]="minRowHeight" 
          [autoGroupColumnDef]="autoGroupColumnDef"
          [detailCellRendererParams]="detailCellRendererParams" 
          refreshStrategy="everything" 
          [pagination]="pagination"
          [paginationPageSize]="pageSizeCount"
          (sortChanged)="onSortChanged($event)"
          [rowData]="rowData" 
          [groupDisplayType]="groupDisplayType"
          [gridOptions]="gridOptions"
          [isGroupOpenByDefault]="isGroupOpenByDefault"
          [aggFuncs]="aggFuncs"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </article>
</main>

<!-- For Pdf -->
<ng-template #pdfSettingGrid>
  <app-pdf-export-popup 
    *ngIf="!loader"
    [paymentGridData]="paymentGrid" 
    [columnDefs]="pdfColumnDefs"
    [columnDefs2]="childColumnDefs" 
    [rowData]="rowData" 
    [groupData]="GroupedData"
    [table]="pdfTable">
  </app-pdf-export-popup>
</ng-template>