import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { UserActivityService } from 'src/app/services/useractivity.service';

@Component({
  selector: 'widget-dataRange',
  template: `
    <style>
      .borderRemove {
          border: none;
      }
      .rangeColor {
        color: #8f8f8f;
        font-size: 16px;
      }
      ::ng-deep .mat-date-range-input {
        font-size: 14px !important;
      }
      ::ng-deep .mat-error {
        margin-top: 10px;
      }
      ::ng-deep .borderRemove .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
        margin-right: -20px;
        font-size: 14px !important;
      }
      ::ng-deep .mat-date-range-input-mirror {
        width:4.5rem;
      }
      /* ::ng-deep .mat-datepicker-popup {
        right: 0 !important;
      } */
      ::ng-deep.mat-button-wrapper{
        font-size: 12px !important;
      }
      @media screen and (orientation: portrait) and (max-width: 768px) {
        ::ng-deep .mat-datepicker-popup {
          right: 10px !important;
        }
      }
    </style>
      <div>
      <label class="filterLabelStyle" style="position: absolute;z-index: 9;font-family:'Poppins'">{{label}}</label>
      <mat-form-field appearance="fill" class="form-control borderRemove">
        <mat-date-range-input [formGroup]="filterService.dateRange" [rangePicker]="picker" [min]="filterService.startDate" [max]="filterService.endDate">
          <input matStartDate readonly formControlName="start" placeholder="Start date" style="font-family:'Poppins'">
          <input matEndDate readonly formControlName="end" (dateChange)="dateChange()" placeholder="End date" style="font-family:'Poppins'">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="filterService.dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="filterService.dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      </div>
      <hr>

    
  `,
})
export class DynamicDateRange extends FieldType implements OnInit, OnDestroy {

  constructor(public filterService: FilterService, private dataService: DataService,
    private userActivityService: UserActivityService
  ) {
    super()
  }

  label: any
  override field: any
  opt: any

  //default prop setting
  valueProp = "_id";
  labelProp = "name";
  currentField: any;
  parentKey: any = "";

  reqSubcription: Subscription[] = []
  cardName: any;
  fieldKey:any;
  defaultDate:any;

  ngOnInit() {

    this.filterService.dateRangeFilterCheck = true;
    
    // Filter Reset Method using Subject
    this.reqSubcription.push(this.filterService.formReset.subscribe((res: any) => {

      this.filterService.dateRange.patchValue(this.filterService.selectedDate)
      this.filterService.selectedItem['date'][this.key as string] = this.filterService.selectedDate
      
      if(this.currentField.parentKey == "") {
        // Set Values
        this.field.formControl?.setValue(this.filterService.selectedDate);
      }
      
      let startDate = moment(this.filterService.selectedDate.start).format('MM/DD/YY');
      let endDate = moment(this.filterService.selectedDate.end).format('MM/DD/YY')
      this.filterService.setBadgeValue(this.key as string, startDate, endDate, this.filterService.selectedDate, "dateSelection")

    }))

    // Formly Form Field Load
    this.opt = this.field.templateOptions || {};
    this.labelProp = this.opt.labelProp
    this.valueProp = this.opt.valueProp
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl
    this.fieldKey = this.model[this.key as string]

    // Parent Key checking
    if (this.currentField.parentKey! != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)
        parentControl?.valueChanges.subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];
           
          
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> this.key as string == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }

          if(this.fndParent()) {
            if (ds == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.opt.howLong, val, this.opt.valueProp)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      // when Time Cyclye is not available
      if(!this.filterService.dateRangeCheck) {
         this.filterService.report_type =  this.opt.time_cycle||"D"
        this.loadApiData(this.opt.method||"POST", ds,this.opt.parameter,this.opt.howLong, this.model, this.opt.valueProp)
      }
      // when Time Cyclye is available
      this.reqSubcription.push(this.filterService.timeCycleDate.subscribe((res: any) => {
        if (this.filterService.timeCycleCount) {
           
          this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.filterService.timeCycleCount, this.model, this.opt.valueProp)
        }
      })
      )
    }
  }

  loadDate: any;
  badgeDate: any;

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }

  loadApiData(method: any, url: any, parameter: any, howLong: any, model: any, valueProp: any): void {
    this.filterService.getCycleDate(method, url, parameter, howLong, model, valueProp).then((date: any) => {
      if (date != null) {
        this.defaultDate = date
        let userStateData: any = this.userActivityService.getData()
        // Object into Array of Object
        let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
        let findData: any = updatesObj.find((o: any) => o.key == this.key as string)
         

        if (findData) {
          if (findData.value.length) {
            this.loadDate = findData.value[0];
            this.badgeDate = findData.value[0];
            this.filterService.selectedItem['date'][this.key as string] = findData.value[0];
          } else {
            findData.value.start = new Date(findData.value.start)
            findData.value.end = new Date(findData.value.end)

            this.loadDate = findData.value;
            this.badgeDate = findData.value;
            this.filterService.selectedItem['date'][this.key as string] = findData.value;
          }
        } else {
          this.loadDate = date;
          this.badgeDate = date;  
          this.filterService.selectedItem['date'][this.key as string] = date;
        }

        this.filterService.dateRange.patchValue(this.loadDate)

        // Set Values
        this.field.formControl?.setValue(this.loadDate);

        // this.filterService.selectedItem['date'][this.key as string] = date
        this.filterService.allOptionData['date'][this.key as string] = date

        // Set Badges
        this.filterService.setBadgeValue(
          this.key as string,
          moment(this.filterService.dateRange.value.start).format('MM/DD/YY'),
          moment(this.filterService.dateRange.value.end).format('MM/DD/YY'),
          date,
          "dateSelection"
        )
      }

    })
  }

  // load previous date
  setPreviousDate() {
    this.filterService.previousSelectedDate = this.loadDate
  }

  // Unsubscriober
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }


  // Date Change
  dateChange() {
    this.loadDate = this.filterService.dateRange.value
    if (this.loadDate.start == null || this.loadDate.end == null) return

    this.filterService.dateRange.patchValue(this.loadDate)

    this.setPreviousDate()

    // formly Model
    this.field.formControl?.setValue(this.loadDate)

    let ab = { [this.key as string]: this.loadDate }
    this.userActivityService.setData({ "selectedFilters": ab })
    this.filterService.selectedItem['date'][this.key as string] = this.loadDate

    // set Badges
    this.filterService.setBadgeValue(
      this.key as string,
      moment(this.filterService.dateRange.value.start).format('MM/DD/YY'),
      moment(this.filterService.dateRange.value.end).format('MM/DD/YY'),
      this.loadDate,
      "dateSelection"
    )
    
  }
}

