<div class="widget-container" #KPIHeatMapCharDiv>
    <app-kpi-breakdown-heatmap-chart
    [type]="'widget'"
    [heading]="heading"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [data]="data"
    
    >
    </app-kpi-breakdown-heatmap-chart>
</div>
<!-- *ngIf="isDataLoaded"  -->