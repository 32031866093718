import { Component, Input } from '@angular/core'
import { CommonModule, CurrencyPipe, KeyValue } from '@angular/common'
import * as d3 from 'd3'

const timeFormatter = d3.timeFormat('%a, %d %b %Y')

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-status-cell-info-dpm',
  template: `
    <ng-container *ngIf="Object.keys(data.details).length">
      <div class="header" *ngIf="header">
        <div class="date">{{ timeFormatter(data.xKey) }}</div>
      </div>
      <div class="main">
        <div class="file" *ngFor="let file of data.details | keyvalue: byIndex">
          <div class="file-details">
            <div class="status-block"
                [style]="{ 'background-color': data.colorScale($any(file.value).status) }"></div>
            <div class="number">{{ $any(file.value).index }}</div>
            <div class="status">{{ $any(file.value).status }}</div>
          </div>
          <div class="name">{{ file.key }}</div>
          <div class="count">
            <div class="received"><span></span>{{ numbedPipe($any(file.value).count.received) }}
              RECEIVED
            </div>
            <div class="status-count">
              <div>
                <div class="tree-line"></div>
                <div class="success"></div>
                {{ numbedPipe($any(file.value).count.success) }} SUCCESS
              </div>
              <div>
                <div class="tree-line"></div>
                <div class="failed"></div>
                {{ numbedPipe($any(file.value).count.failed) }} FAILURES
              </div>
              <div>
                <div class="tree-line"></div>
                <div class="errors"></div>
                {{ numbedPipe($any(file.value).count.errors) }} ERRORS
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./cell-info.scss']
})
export class CellInfo {
  @Input() data: any
  @Input() header: any

  byIndex (a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    const f1 = Number(a.value.index)
    const f2 = Number(b.value.index)
    return f1 > f2 ? -1 : f2 > f1 ? 1 : 0
  }

  constructor (private currency: CurrencyPipe) {
  }

  protected readonly Object = Object
  protected readonly timeFormatter = timeFormatter

  numbedPipe (value: any) {
    return this.currency.transform(value, '', '', '1.0-2')
  }
}