import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: 'app-simple-change-popup',
  templateUrl: './simple-change-popup.component.html',
  styleUrls: ['./simple-change-popup.component.scss']
})

export class SimpleChangePopupComponent implements OnInit, OnChanges {
  @Input("transactionName") transactionName: any = "Brand";
  @Input("date") date: any = '2023-01-30';
  @Input("transactionData") transactionData: any = [{ key: "No Data Found", Value: [] }];
  @Output() showChangeTooltip = new EventEmitter();
  obj = [
    {
      name: "From",
      Value: [
        { Key: "Diagnosis", Value: "Galaxy" },
        { Key: "Period", Value: "02.02.2022 - 01.30.2023" },
        { Key: "Duration", Value: "362 days" },
      ],
    },
    {
      name: "To",
      Value: [
        { Key: "Diagnosis", Value: "Jupiterol" },
        { Key: "Period", Value: "01.30.2023 - 02.24.2023" },
        { Key: "Duration", Value: "25 days (ongoing)" },
      ],
    },

  ];

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    // if (this.transactionName == 'status') {

    //   this.transactionName = "Active " + this.transactionName.charAt(0).toUpperCase() + this.transactionName.slice(1)
    // } else {
    //   this.transactionName = this.transactionName.charAt(0).toUpperCase() + this.transactionName.slice(1)
    // }
    // if (this.transactionName == "unknown" || this.transactionName == null || this.transactionName == undefined || this.transactionName == '') {
    //   this.hideTooltip();
    // }
  }
  ngOnInit(): void { }

  hideTooltip() {
    this.showChangeTooltip.next(false);
  }

  ngOnDestroy() {

  }
}
