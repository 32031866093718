import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-popup-delete',
  templateUrl: './popup-delete.component.html',
  styleUrls: ['./popup-delete.component.scss']
})
export class PopupDeleteComponent implements OnInit {
  paramsData:any
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private dialogRef: MatDialogRef<PopupDeleteComponent>,
    public filterService: FilterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private newFilterService: NewFilterService
  ) {
    this.paramsData = passedData
  }

  ngOnInit(): void {

  }

  deleteCommentToClose(event:any) {
    this.dialogRef.close(event);
  }
}
