<div class="card_journey">
  <div class="container">
    <div class="row mt-3 ml-1">
      <div class="col-10 text-start"><img class="mr-2" src="../../../../assets/Transaction.png" /><span
          class="accordian_heading">Transaction Details</span></div>
      <div class="col-2 text-end"><img src="../../../../assets/Vector.png" (click)="hideTooltip()" /></div>
      <hr class="heading_divider mt-2 mr-3" />
    </div>
    <div class="row accordian_inside_heading ml-1 mt-3">
      {{transactionName}}
    </div>
    <div class="panel-group mt-4 mb-5" id="accordion" *ngIf="transactionData">
      <div class="panel panel-default panel_outlay mt-2" *ngFor="let data of transactionData;let i=index">
        <div class="panel-heading">
          <h4 class="panel-title mt-2">
            <a data-toggle="collapse" class="accordion-toggle collapsed inner_heading" data-parent="#accordion"
              [attr.href]="'#collapse'+i">
              {{data.Key}}
            </a>
          </h4>

        </div>
        <div [attr.id]="'collapse'+i" class="panel-collapse collapse in ml-2 mr-2" data-parent="#accordion">
          <hr class="heading_divider mt-2 mr-3" />
          <div class="panel-body">
            <div class="row mt-2" *ngFor="let obj of data?.Value">
              <div class="col-12 d-flex">
                <div class="col-6 trans_data_key text-end">{{obj.Key}}</div>
                <span class="pl-0">:</span>
                <div class="col-6 trans_data_value text-start">{{obj.Value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ng-template #elseBlock>
        <div class="text-center">
          <h3>No Data Found</h3>
        </div>
      </ng-template> -->
  </div>
</div>