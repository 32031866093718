import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { ClientModelService } from '../client-model/client-model.service';

@Component({
  selector: 'app-client-roles',
  templateUrl: './client-roles.component.html',
  styleUrls: ['./client-roles.component.scss']
})
export class ClientRolesComponent implements OnInit {
  roles:any;
  selectedRole:any;
  clients:any;
  userName:any;
  isButtonClicked=false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  private dialogRef: MatDialogRef<ClientRolesComponent>,
  private sessionService: SessionService,
  public clientModelService: ClientModelService) {
  
    this.clients = passedData.client;
    this.roles = passedData.roles;
    this.userName = this.sessionService.getUsername();
  }


  ngOnInit(): void {}

  closeUsingCross() {
    this.dialogRef.close('closeFromClientRole');
  }

  selectRole(selectRole:any) {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;
      
      if(selectRole) {
        this.clientModelService.newSetRoleDetails(this.selectedRole);
        this.dialogRef.close(this.selectedRole);
      }
      
      // Reset the button state after a certain delay (e.g., 2 seconds)
      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }

}
