<div class="widget-container" #lineCharDiv>
    <line-chart
    [headerConfig]="item.config?.widget_header_config"
    [heading]="heading"
    [config]="config"
    [data]="data"
    [item]="item"
    [pageKey]="pageKey"
    >
    </line-chart>
</div>