
import { Component, Input, OnDestroy } from '@angular/core';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';

@Component({
	selector: 'loading',
	template: `
		<div *ngIf="this.deviceDetectionService.isDesktop" id="pause" [style.height]="type=='kpi'? '100%' : 'calc(100% - 55px)'" class="align-items-center justify-content-center">
			<div class="loader" [style.height.px]="type=='kpi'?30:130" [style.width.px]="type=='kpi'?30:130"></div>
			<!-- <div id="spinner"></div> -->
			<!-- <div id="spinnertext" >Loading...</div> -->
		</div>
	`,
	styleUrls: ['loading.scss']
})

export class LoadingComponent {
	@Input() type!: any

	constructor(public deviceDetectionService: DeviceDetectionService) {}
}
