import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }
  totalAverage(numbers:any):number {
    if (numbers.length === 0) {
      return 0; // Handle the case where the array is empty to avoid division by zero.
    }
    const sum = numbers.reduce((acc:any, current:any) => acc + current['kpi_value'], 0);
    const average = sum / numbers.length;

    return average;
  }
  calculateAverage(array: any, look_back_period: any) {
    var startIndex = 0;
    if (array.length <= look_back_period + 1) {
      startIndex = 0;
    }
    else {
      startIndex = array.length - (look_back_period + 1);
    }
    const valuesToLookCalculate = array.slice(startIndex, array.length - 1)
    const sum = valuesToLookCalculate.reduce((acc: any, val: any) => acc + val, 0);
    return sum / valuesToLookCalculate.length;

  }
  standardDevation(array: any, look_back_period: any, stdev?: any, buffer?: any) {
    
    // var startIndex=0;
    // var lcl=0;
    // var ucl=0;
    // var avg=0;
    // array.forEach((data:any,i:any)=>{

    // if(i>1){
    //   if(array.length<=look_back_period+1)
    //           {
    //             startIndex=0;
    //           }
    //           else{startIndex+1}
    //           const valuesToLookCalculate =array.slice(startIndex,array.length-1)
    //           const sum = valuesToLookCalculate.reduce((acc:any, val:any) => acc + val, 0);
    //           avg= sum / valuesToLookCalculate.length;
    //           const squaredDifferences = valuesToLookCalculateSD.map((val:any) => Math.pow(val - average, 2));
    //           const sumOfSquaredDifferences = squaredDifferences.reduce((acc:any, val:any) => acc + val, 0);
    //           const variance = sumOfSquaredDifferences / valuesToLookCalculateSD.length;
    //           var dev = Math.sqrt(variance)
    //           lcl=average-(stdev*dev);
    //           ucl=average+(stdev*dev);
    // }


    // })
    const average = this.calculateAverage(array, look_back_period);
    var startIndex = 0;
    if (array.length <= look_back_period + 1) {
      startIndex = 0;
    }
    else {
      startIndex = array.length - (look_back_period + 1);
    }
    const valuesToLookCalculateSD = array.slice(startIndex, array.length - 1)
//  console.log(startIndex, array.length - 1)
    const squaredDifferences = valuesToLookCalculateSD.map((val: any) => Math.pow(val - average, 2));
    const sumOfSquaredDifferences = squaredDifferences.reduce((acc: any, val: any) => acc + val, 0);
    const variance = sumOfSquaredDifferences / valuesToLookCalculateSD.length;
    // var dev = Math.sqrt(variance)

    // var Lcl=average-(stdev*dev);
    // var UCL=average+(stdev*dev);
    return Math.sqrt(variance);


  }
  calculationControlChart(array: any, properties:any) {

    var startIndex = 0;
    var lcl = 0;
    var ucl = 0;
    var avg = 0;
    var stdev = properties.no_of_std_deviations
    var look_back_period = properties.look_back_period
    var buffer =properties.buffer_range
    var myThresholdRange = [properties["lower_threshold"],properties["upper_threshold"]]
    let limitBuffer:any = undefined;
    let valuevar:string ='kpi_value'
    let valuesSoFar: any = [];

    array?.forEach( (data:any, i:any) =>  {
      //valuesSoFar.push(data['kpi_value']);
      //const average = this.calculateAverage(valuesSoFar, look_back_period);
      if (i > 0) {
        if (i <= look_back_period ) {
          startIndex = 0;
        }
        else { startIndex =  i - look_back_period ; }

        const valuesToLookCalculate = array.slice(startIndex, i)
        const sum = valuesToLookCalculate.reduce((acc: any, val: any) => acc + val[valuevar], 0);
        avg = sum / valuesToLookCalculate.length;
        const squaredDifferences = valuesToLookCalculate.map((val: any) => Math.pow(val[valuevar] - avg, 2));
        const sumOfSquaredDifferences = squaredDifferences.reduce((acc: any, val: any) => acc + val, 0);
        const variance = sumOfSquaredDifferences / valuesToLookCalculate.length;
        var dev = Math.sqrt(variance)
        lcl = avg - (stdev * dev);
        ucl = avg + (stdev * dev);

        let myLimitRange = [lcl < myThresholdRange[0] ? myThresholdRange[0] : lcl, ucl > myThresholdRange[1] ? myThresholdRange[1] : ucl];
        if (myThresholdRange[0] === null) {
          myLimitRange = [lcl, ucl];
        }
        limitBuffer = [myLimitRange[0] +(myLimitRange[0] * (buffer)), myLimitRange[1] - (myLimitRange[1] * (buffer))];
        // if ((limitBuffer[1] < myLimitRange[0]) && buffer > 0) {
        //   limitBuffer[1] = myLimitRange[0];
        // }
        // if ((limitBuffer[0] > myLimitRange[1]) && buffer > 0) {
        //   limitBuffer[0] = myLimitRange[1];
        // }
        const myValue = data[valuevar];
        if(myValue < myLimitRange[0] || myValue > myLimitRange[1]){
          data.status =  "anomaly";
        } else if (myValue < limitBuffer[0] || myValue > limitBuffer[1]){
          data.status =  "buffer values"
        } else {
          data.status = "normal";
        }
        data['lowerThreshold'] = myThresholdRange[0] || undefined
        data["upperThreshold"] = myThresholdRange[1] || undefined
        data["lowerLimit"] = lcl>0 ? lcl : 0 || 0
        data["upperLimit"] = ucl || 0
        data["lowerBuffer"] = limitBuffer[0]>0?limitBuffer[0] : 0 || undefined
        data["upperBuffer"] = limitBuffer[1] || undefined
        data["average"] = this.totalAverage(array)

      }else{
        data['lowerThreshold'] = myThresholdRange[0] || 0
        data["upperThreshold"] = myThresholdRange[1] || 0
        data["lowerLimit"] = lcl || undefined
        data["upperLimit"] = ucl || undefined
        data["lowerBuffer"] = limitBuffer?.[0] || undefined
        data["upperBuffer"] = limitBuffer?.[1] || undefined
        data["status"] ="normal",
        data["average"] =this.totalAverage(array)
      }


    })
    // console.log(array)
    return array
  }



  multiControlchartCalculation(properties:any,data:any){
    let value :any
    properties.forEach((prop:any)=>{
      value = data.filter((d:any)=>{return prop.source_name == d.source_name && prop.kpi_nm == d.kpi_nm})
      value = this.calculationControlChart(value, prop)
      let result:any = []
   data.forEach((item1:any) => {
     return value.some((item2:any) => {
      if( item1.source_name == item2.source_name && item1.kpi_nm == item2.kpi_nm ){
       item1=item2
      }
     result.push(item1);
     });
   })
   result = [...new Set(result)];
   data=result
  })

}
}