import { CompactType, DisplayGrid, GridType } from 'angular-gridster2'
import {
  Draggable,
  GridsterConfig as GConfig,
  PushDirections,
  Resizable
} from 'angular-gridster2/lib/gridsterConfig.interface'

export interface GridsterConfig extends GConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}

const config: GridsterConfig = {
  gridType: GridType.ScrollVertical,
  DisplayGrid: DisplayGrid.Always,
  compactType: CompactType.None,
  pushItems: true,
  outerMargin: true,
  minCols: 50,
  maxCols: 200,
  minRows: 25,
  maxRows: 2000,
  minItemCols: 1,
  minItemRows: 1,
  maxItemCols: 200,
  maxItemRows: 2000,
  maxItemArea: 400000,
  minItemArea: 1,
  defaultItemCols: 1,
  defaultItemRows: 1,
  enableEmptyCellClick: false,
  enableEmptyCellContextMenu: false,
  enableEmptyCellDrop: false,
  enableEmptyCellDrag: false,
  enableOccupiedCellDrop: false,
  emptyCellDragMaxCols: 10,
  emptyCellDragMaxRows: 5,
  ignoreMarginInRow: false,
  draggable: {
    enabled: false,
  },
  resizable: {
    enabled: false,
  },
  pushDirections: { north: true, east: true, west: true, south: true },
}

export default config