import { Component, OnInit} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-under-score-details',
  template: `
    {{ label ? label.replaceAll('_', ' ') : ''}}
  `
})
export class UnderScoreDetailsComponent implements OnInit {
  label:any;
  constructor(public filterService: FilterService) {}
  
  ngOnInit(): void {}

  agInit(params: ICellRendererParams): void {
   
   this.label = params.value
  }

}