import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";
import { FilterService } from "src/app/services/filter.service";
import moment from "moment";
import { a } from "aws-amplify";
import _ from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DeviceDetectionService } from "src/app/services/detectipad.service";

@Component({
  selector: "app-hover-over-click",
  templateUrl: "./hover-over-click.component.html",
  styleUrls: ["./hover-over-click.component.scss"],
})
export class HoverOverClickComponent implements OnInit {
  today: string = moment().format("DD.MM.YY");

  public params: any;
  public valueToDisplay: any;
  status_tick = "status_tick";
  status_na = "status_na";
  status_pending = "status";
  status_circle = "status_circle";
  status_ns = "status_ns";
  api_key: any;
  dynamicParameter: any = {};
  valueFormatColors: any;
  report_type: any;
  newPosition: any;
  inputType: any;

  constructor(public filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private dialogRef: MatDialogRef<HoverOverClickComponent>,
    public deviceDetectionService: DeviceDetectionService
    ) {
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {

    this.inputType = this.passedData.params.data[this.passedData.selectedValue.parameter[0]];

    // Conditional Formatter Colors and Value
    this.valueFormatColors = this.passedData.formatValue;

    // Selected API Key
    this.api_key = parseInt(this.passedData.selectedValue.api_key);
    let component = this.passedData.params.data;
    let check = this.passedData.component.find(
      (res: any) =>
        res.popup.toLowerCase() == component.case_status.toLowerCase()
    );
    if (this.passedData.component.length > 0) {
      if (check) {
        this.report_type = check.value;
      } else {
        this.report_type = "C";
      }
    }

    // To Create Dynamic PARAMETER Based on Selection
    this.dynamicParameter = {};

    // call API
    this.loadApiData();
  }

  loadApiData() {
    let valueGetter = this.passedData.params.data;
    let pend: any = sessionStorage.getItem("pending");
    let pending = JSON.parse(pend);
    let nonPend: any = sessionStorage.getItem("non-pending");
    let nonPending = JSON.parse(nonPend);
    if (this.report_type == "P") {
      let P = pending.find(
        (res: any) => res.patient_id == valueGetter.patient_id
      );
      if (P) {
        this.valueToDisplay = P.patient_status_history;
      } else {
        this.valueToDisplay = "";
      }
    } else {
      let nP = nonPending.find(
        (res: any) => res.patient_id == valueGetter.patient_id
      );

      if (nP) {
        nP.patient_status_history.forEach((data: any) => {
          this.valueFormatColors.forEach((color: any) => {
            if (
              data.curr_status.toLowerCase() == color.col_value.toLowerCase()
            ) {
              data = Object.assign(data, color);
            }
          });
        });
        let a = nP.patient_status_history.slice(-4);
        this.valueToDisplay = a;
      } else {
        this.valueToDisplay = "";
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
