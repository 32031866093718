import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

// Node Configuration Model
export class TodoItemNode {
  children!: TodoItemNode[];
  label!: string;
  email_subscription_flg: string = "N";
  app_subscription_flg: string = "N";
  isSelectedEmail!: boolean;
  isSelectedApp!: boolean;
  user_subscription_key!: string;
  report_typ!: string
}

// Flat to-do item node with expandable and level information
export class TodoItemFlatNode {
  label!: string;
  level!: number;
  expandable!: boolean;
  email_subscription_flg: string = "N";
  app_subscription_flg: string = "N";
  isSelectedEmail!: boolean;
  isSelectedApp!: boolean;
  user_subscription_key!: string;
  report_typ!: string

}

@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss'],

})

// Alert Class Component
export class AlertSettingsComponent implements OnInit {
  selectedValuesArray: any = []
  totalTreeValues: any = []
  toggle: boolean = false
  toggleHide: boolean = false
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  clientKey: any;
  toggleHide1: boolean = false;

  // Icons Object
  IconObjects = [
    {
      alert_key: "100101",
      icon: 'new_alert.svg',
      description: 'Indicates new patients that comes to patient flow',
      checkboxColor: "primary",
      iconColor: "#E34071",
      opacity: 0.2,
    },
    {
      alert_key: "100106",
      icon: 'refill_alert.svg',
      description: 'Indicates refill patients that are in a patient flow',
      checkboxColor: "primary",
      iconColor: "#40B2E3",
      opacity: 0.2,
    },
    {
      alert_key: "100102",
      icon: 'prescriber_alert.svg',
      description: 'Indicates new presctibers that are in a patient flow',
      checkboxColor: "primary",
      iconColor: "#3AC97C",
      opacity: 0.2,
    },
    {
      alert_key: "100104",
      icon: 'refill_to_soon_alert.svg',
      description: 'Indicates dispenses happened before due date',
      checkboxColor: "primary",
      iconColor: "#1363DF",
      opacity: 0.2,
    },
    {
      alert_key: "100105",
      icon: 'shipment_delayed_alert.svg',
      description: 'Indicates delayed shipment',
      checkboxColor: "primary",
      iconColor: "#D11044",
      opacity: 0.2,
    },
    {
      alert_key: "100103",
      icon: 'refresh_alert.svg',
      description: 'Shows status changes for a patient for selected period',
      checkboxColor: "primary",
      iconColor: "#FF9A3D",
      opacity: 0.2,
    },
    {
      alert_key: "100107",
      icon: 'custom_alert.svg',
      description: 'Shows custom alerts for selected period',
      checkboxColor: "primary",
      iconColor: "#844AFF",
      opacity: 0.2,
    },

  ]
  employeeKey: any;
  alertSettingsObj: any;
  defaultSelectedValues: any = [];


  // Get Data for Defining Nodes Changes
  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  // Constructor
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private newFilterService: NewFilterService, private router: ActivatedRoute, private navigationService: NavigationService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    
    this.router.params.subscribe((r: any) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })


  }

  ngOnInit(): void {
    this.employeeKey = sessionStorage?.getItem("employee_key");
    if (this.employeeKey) {
      let key = JSON.parse(this.employeeKey)
      this.employeeKey = key["_value"]
      // this.buildTreeView()
    }
  }

  buildTreeView() {
    this.defaultSelectedValues = []
    this.selectedValuesArray = []
    // API To Call Alert Data Object
    const obj = {
      api_key: 100150,
      report_typ: "D",
      client_key: this.clientKey || "",
      employee_key: this.employeeKey ? this.employeeKey : '',
    }
    this.newFilterService.executealertDataQuery(obj).subscribe((resp: any) => {
      this.alertSettingsObj = resp;

      if (this.alertSettingsObj) {
        // Push Icons and Colors In The Alert Object which will Come from API

        this.alertSettingsObj.forEach((j: any) => {
          this.IconObjects.find((i: any) => {
            if (j.alert_key == i.alert_key) {
              Object.assign(j, {
                icon: i.icon,
                checkboxColor: i.checkboxColor,
                iconColor: i.iconColor,
                opacity: i.opacity,
                description: i.description

              })
            }
          })
        })
        // Register Icons
        this.alertSettingsObj.forEach((element: any) => {
          this.matIconRegistry.addSvgIcon(
            element.icon,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../../assets/svgIcons/${element.icon}`)
          );
        });

        // Mapping of Data of Tree Nodes Which are Selected By Default To 'Yes' i.e 'Y'
        this.alertSettingsObj.forEach((element: any) => {
          if (element.Status_Data) {
            const newData: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode> = new MatTreeFlatDataSource(
              this.treeControl,
              this.treeFlattener
            );
            newData.data = this.buildFileTree(element.Status_Data, 0);
            element.dataSource = newData

            // Notify the change.
            this.dataChange.next(newData.data);
            if (element.Status_Data) {
              //  Mapping Selected Values from Data Object
              for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
                for (let j = 0; j < this.selectedValuesArray?.length; j++) {
                  if (this.treeControl.dataNodes[i].user_subscription_key == this.selectedValuesArray[j].user_subscription_key) {
                    if (this.selectedValuesArray[j].app_subscription_flg == 'Y' && this.selectedValuesArray[j].email_subscription_flg == 'Y') {
                      // this.getParentNode(this.treeControl.dataNodes[i])
                      Object.assign(this.treeControl.dataNodes[i], {
                        email_subscription_flg: this.selectedValuesArray[j].email_subscription_flg,
                        app_subscription_flg: this.selectedValuesArray[j].app_subscription_flg,
                        user_subscription_key: this.selectedValuesArray[j].user_subscription_key,
                        report_typ: this.selectedValuesArray[j].report_typ,
                      })
                      if (!this.checklistSelectionApp.isSelected(this.treeControl.dataNodes[i])) {
                        this.todoItemSelectionToggleGET(this.treeControl.dataNodes[i], 'app')
                      }
                      if (!this.checklistSelectionEmail.isSelected(this.treeControl.dataNodes[i])) {
                        this.todoItemSelectionToggleGET(this.treeControl.dataNodes[i], 'email')
                      }
                    }
                    else if (this.selectedValuesArray[j].app_subscription_flg == 'N' && this.selectedValuesArray[j].email_subscription_flg == 'Y') {
                      Object.assign(this.treeControl.dataNodes[i], {
                        email_subscription_flg: this.selectedValuesArray[j].email_subscription_flg,
                        app_subscription_flg: this.selectedValuesArray[j].app_subscription_flg,
                        user_subscription_key: this.selectedValuesArray[j].user_subscription_key,
                        report_typ: this.selectedValuesArray[j].report_typ,

                      })
                      if (!this.checklistSelectionApp.isSelected(this.treeControl.dataNodes[i])) {
                        this.todoItemSelectionToggleGET(this.treeControl.dataNodes[i], 'email')
                      }
                    }
                    else if (this.selectedValuesArray[j].app_subscription_flg == 'Y' && this.selectedValuesArray[j].email_subscription_flg == 'N') {
                      Object.assign(this.treeControl.dataNodes[i], {
                        email_subscription_flg: this.selectedValuesArray[j].email_subscription_flg,
                        app_subscription_flg: this.selectedValuesArray[j].app_subscription_flg,
                        user_subscription_key: this.selectedValuesArray[j].user_subscription_key,
                        report_typ: this.selectedValuesArray[j].report_typ,

                      })
                      if (!this.checklistSelectionApp.isSelected(this.treeControl.dataNodes[i])) {
                        this.todoItemSelectionToggleGET(this.treeControl.dataNodes[i], 'app')
                      }

                    }

                  }
                }
              }
            }
          }
        });
      }
    })


  }


  // Building Tree
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const item = obj[key];
      const node = new TodoItemNode();
      node.label = obj[key].alert_name ? obj[key].alert_name : obj[key].Key;
      node.email_subscription_flg = obj[key].email_subscription_flg;
      node.app_subscription_flg = obj[key].app_subscription_flg;
      node.user_subscription_key = obj[key].user_subscription_key;
      node.report_typ = obj[key].report_typ;
      node.isSelectedApp = obj[key].isSelectedApp;
      node.isSelectedEmail = obj[key].isSelectedEmail;
      this.totalTreeValues.push(node)
      if (item != null) {
        if (typeof item === 'object' && item.Value != undefined) {
          node.children = this.buildFileTree(item.Value, level + 1);
        } else {
          node.label = obj[key].alert_name ? obj[key].alert_name : obj[key].Key;
          if (node.app_subscription_flg == 'Y' || node.email_subscription_flg == 'Y') {
            this.selectedValuesArray.push(node)
            this.defaultSelectedValues.push(node)
          }
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ label: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.label = name;
    this.dataChange.next(this.data);
  }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelectionApp = new SelectionModel<TodoItemFlatNode>(
    true /* multiple */
  );
  checklistSelectionEmail = new SelectionModel<TodoItemFlatNode>(
    true /* multiple */
  );


  checkAll(type: any) {
    if (type == 'email') {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        if (this.treeControl.dataNodes[i].email_subscription_flg)
          this.checklistSelectionEmail.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }

    else if (type == 'app') {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        if (this.treeControl.dataNodes[i].email_subscription_flg)
          this.checklistSelectionApp.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }

    this.toggle = true



  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.label === '';

  // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.label === node.label ? existingNode : new TodoItemFlatNode();
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.user_subscription_key = node.user_subscription_key;
    flatNode.report_typ = node.report_typ;
    node.email_subscription_flg = node.email_subscription_flg;
    node.app_subscription_flg = node.app_subscription_flg;
    node.isSelectedApp = node.isSelectedApp;
    node.isSelectedEmail = node.isSelectedEmail;
    node.user_subscription_key = node.user_subscription_key;
    node.report_typ = node.report_typ;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelectedEmail(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelectionEmail.isSelected(child)
    );
    return descAllSelected;
  }
  descendantsAllSelectedApp(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelectionApp.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelectedEmail(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelectionEmail.isSelected(child)
    );
    return result && !this.descendantsAllSelectedEmail(node);
  }
  descendantsPartiallySelectedApp(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelectionApp.isSelected(child)
    );
    return result && !this.descendantsAllSelectedApp(node);
  }


  // Only When Tree Loads
  todoItemSelectionToggleGET(node: TodoItemFlatNode, type: any): void {
    if (type == 'email') {
      this.toggleHide = false
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelectionEmail.toggle(node);
      descendants.forEach((j: any) => {
        if (this.checklistSelectionApp.selected.length > 0) {

          this.checklistSelectionApp?.selected?.find((i: any) => {
            if (j.user_subscription_key == i.user_subscription_key && i.user_subscription_key != undefined) {
              // this.treeControl.expand(node)
              descendants.forEach((k: any) => {
                // this.treeControl.expand(k)
              })
              this.toggleHide = true
            }
            else {
              if (!this.toggleHide) {
                // this.treeControl.collapse(node)
                descendants.forEach((k: any) => {
                  // this.treeControl.collapse(k)
                })
              }

            }
          })
        } else {
          // this.treeControl.collapse(j)
          // this.treeControl.collapse(node)
        }


      })

      this.checklistSelectionEmail.isSelected(node) ? this.checklistSelectionEmail.select(...descendants) : this.checklistSelectionEmail.deselect(...descendants);
      // this.checklistSelectionEmail.isSelected(node) ? (this.checklistSelectionEmail.select(...descendants), (node.expandable == true && node.level == 0 ? this.treeControl.expand(node) : node.expandable == true && node.level != 0 ? this.treeControl.expand(node) : "")) : (this.checklistSelectionEmail.deselect(...descendants), node.expandable == true && node.level == 0 ? this.treeControl.collapse(node) : node.expandable == true && node.level != 0 ? this.treeControl.collapse(node) : '')
      descendants.every((child) => this.checklistSelectionEmail.isSelected(child));
      this.checklistSelectionEmail.selected.forEach((i: any) => {
        if (i.expandable) {
          // this.treeControl.expand(i)
        }
        else {
          if (i.email_subscription_flg == 'N') {
            return i.email_subscription_flg = 'Y'
          }
        }
      })
    }

    else if (type == 'app') {
      this.toggleHide1 = false
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelectionApp.toggle(node);
      descendants.forEach((j: any) => {
        if (this.checklistSelectionEmail.selected.length > 0) {
          this.checklistSelectionEmail?.selected?.find((i: any) => {
            if (j.user_subscription_key == i.user_subscription_key && i.user_subscription_key != undefined) {
              // this.treeControl.expand(node)
              descendants.forEach((k: any) => {
                // this.treeControl.expand(k)
              })
              this.toggleHide1 = true
            }
            else {
              if (!this.toggleHide1) {
                // this.treeControl.collapse(node)
                descendants.forEach((k: any) => {
                  // this.treeControl.collapse(k)
                })
              }

            }
          })
        }
        else {
          // this.treeControl.collapse(j)
          // this.treeControl.collapse(node)
        }


      })
      this.checklistSelectionApp.isSelected(node) ? this.checklistSelectionApp.select(...descendants) : this.checklistSelectionApp.deselect(...descendants);
      // this.checklistSelectionApp.isSelected(node) ? (this.checklistSelectionApp.select(...descendants), (node.expandable == true && node.level == 0 ? this.treeControl.expandAll() : node.expandable == true && node.level != 0 ? this.treeControl.expand(node) : "")) : (this.checklistSelectionApp.deselect(...descendants), node.expandable == true && node.level == 0 ? this.treeControl.collapseAll() : node.expandable == true && node.level != 0 ? this.treeControl.collapse(node) : '')
      descendants.every((child) => this.checklistSelectionApp.isSelected(child));
      this.checklistSelectionApp.selected.forEach((i: any) => {
        if (i.expandable) {
          // this.treeControl.expand(i)
        }
        else {
          if (i.app_subscription_flg == 'N') {
            return i.app_subscription_flg = 'Y'
          }
        }
      })
    }
    this.checkAllParentsSelection(node, type);

  }


  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode, type: any): void {
    if (type == 'email') {
      this.toggleHide = false
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelectionEmail.toggle(node);
      descendants.forEach((j: any) => {
        if (this.checklistSelectionApp.selected.length > 0) {

          this.checklistSelectionApp?.selected?.find((i: any) => {
            if (j.user_subscription_key == i.user_subscription_key && i.user_subscription_key != undefined) {
              this.treeControl.expand(node)
              descendants.forEach((k: any) => {
                this.treeControl.expand(k)
              })
              this.toggleHide = true
            }
            else {
              if (!this.toggleHide) {
                this.treeControl.collapse(node)
                descendants.forEach((k: any) => {
                  this.treeControl.collapse(k)
                })
              }

            }
          })
        } else {
          this.treeControl.collapse(j)
          this.treeControl.collapse(node)
        }


      })
      this.checklistSelectionEmail.isSelected(node) ? this.checklistSelectionEmail.select(...descendants) : this.checklistSelectionEmail.deselect(...descendants);
      // this.checklistSelectionEmail.isSelected(node) ? (this.checklistSelectionEmail.select(...descendants), (node.expandable == true && node.level == 0 ? this.treeControl.expand(node) : node.expandable == true && node.level != 0 ? this.treeControl.expand(node) : "")) : (this.checklistSelectionEmail.deselect(...descendants), node.expandable == true && node.level == 0 ? this.treeControl.collapse(node) : node.expandable == true && node.level != 0 ? this.treeControl.collapse(node) : '')
      descendants.every((child) => this.checklistSelectionEmail.isSelected(child));
      this.checklistSelectionEmail.selected.forEach((i: any) => {
        if (i.expandable) {
          this.treeControl.expand(node)
        }
        else {
          if (i.user_subscription_key) {
            if (i.email_subscription_flg == 'N') {
              return i.email_subscription_flg = 'Y'
            }
          }
        }
        if (i.user_subscription_key) {
          descendants.forEach((j: any) => {
            if (j.user_subscription_key == i.user_subscription_key) {
              this.treeControl.expand(node)
              descendants.map((k: any) => {
                if (k.expandable) {
                  this.treeControl.expand(k)
                }
              })
            } else {
            }
          })
        }
        else {

        }

      })
    }

    else if (type == 'app') {
      this.toggleHide1 = false
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelectionApp.toggle(node);
      descendants.forEach((j: any) => {
        if (this.checklistSelectionEmail.selected.length > 0) {
          this.checklistSelectionEmail?.selected?.find((i: any) => {
            if (j.user_subscription_key == i.user_subscription_key && i.user_subscription_key != undefined) {
              this.treeControl.expand(node)
              descendants.forEach((k: any) => {
                this.treeControl.expand(k)
              })
              this.toggleHide1 = true
            }
            else {
              if (!this.toggleHide1) {
                this.treeControl.collapse(node)
                descendants.forEach((k: any) => {
                  this.treeControl.collapse(k)
                })
              }

            }
          })
        }
        else {
          this.treeControl.collapse(j)
          this.treeControl.collapse(node)
        }


      })
      this.checklistSelectionApp.isSelected(node) ? this.checklistSelectionApp.select(...descendants) : this.checklistSelectionApp.deselect(...descendants);
      // this.checklistSelectionApp.isSelected(node) ? (this.checklistSelectionApp.select(...descendants), (node.expandable == true && node.level == 0 ? this.treeControl.expandAll() : node.expandable == true && node.level != 0 ? this.treeControl.expand(node) : "")) : (this.checklistSelectionApp.deselect(...descendants), node.expandable == true && node.level == 0 ? this.treeControl.collapseAll() : node.expandable == true && node.level != 0 ? this.treeControl.collapse(node) : '')
      descendants.every((child) => this.checklistSelectionApp.isSelected(child));
      this.checklistSelectionApp.selected.forEach((i: any) => {
        if (i.expandable) {
          this.treeControl.expand(node)

        }
        else {
          if (i.user_subscription_key) {
            if (i.app_subscription_flg == 'N') {
              return i.app_subscription_flg = 'Y'
            }
          }
        }
        if (i.user_subscription_key) {
          descendants.forEach((j: any) => {
            if (j.user_subscription_key == i.user_subscription_key) {
              this.treeControl.expand(node)
              descendants.map((k: any) => {
                if (k.expandable) {
                  this.treeControl.expand(k)
                }
              })
            } else {
            }
          })
        }
        else {

        }

      })
    }
    this.checkAllParentsSelection(node, type);

  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode, type: any): void {
    if (type == 'email') {
      this.checklistSelectionEmail.toggle(node);
      if (node.email_subscription_flg == 'Y') {
        node.email_subscription_flg = "N"
      } else if (node.email_subscription_flg == "N") {
        node.email_subscription_flg = "Y"
      }
    }

    else if (type == 'app') {
      this.checklistSelectionApp.toggle(node);
      if (node.app_subscription_flg == 'Y') {
        node.app_subscription_flg = "N"
      } else if (node.app_subscription_flg == "N") {
        node.app_subscription_flg = "Y"
      }
    }
    this.checkAllParentsSelection(node, type);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode, type: any): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent, type);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode, type: any): void {
    if (type == 'email') {
      const nodeSelected = this.checklistSelectionEmail.isSelected(node);
      const descendants = this.treeControl.getDescendants(node);
      // this.treeControl.expand(node)                  // Future Use to Show Tree in Expand Form with Selected Nodes
      const descAllSelected = descendants.every((child) => this.checklistSelectionEmail.isSelected(child));
      if (nodeSelected && !descAllSelected) {
        this.checklistSelectionEmail.deselect(node);
      }
      else if (!nodeSelected && descAllSelected) {
        this.checklistSelectionEmail.select(node);
      }
    }
    else if (type == 'app') {
      const nodeSelected = this.checklistSelectionApp.isSelected(node);
      const descendants = this.treeControl.getDescendants(node);
      // this.treeControl.expand(node)                // Future Use to Show Tree in Expand Form with Selected Nodes
      const descAllSelected = descendants.every((child) => this.checklistSelectionApp.isSelected(child));
      if (nodeSelected && !descAllSelected) {
        this.checklistSelectionApp.deselect(node);
      }
      else if (!nodeSelected && descAllSelected) {
        this.checklistSelectionApp.select(node);
      }

    }

  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  // Save Alert Data
  saveSettings() {
    let data: any = []
    let data1: any = []
    let data2: any = []
    data1 = this.checklistSelectionApp.selected.filter((i: any) => !i.expandable)
    data2 = this.checklistSelectionEmail.selected.filter((i: any) => !i.expandable)
    if (data1.length == 0) {
      data2.forEach((i: any) => {
        return i.app_subscription_flg = "N"
      })
    } else if (data2.length == 0) {
      data1.forEach((j: any) => {
        return j.email_subscription_flg = "N"
      })
    }

    data1.map((i: any) => {
      data.push(i)
    })
    data2.map((i: any) => {
      data.push(i)
    })

    data.forEach((i: any) => {
      return i.app_subscription_flg = "N", i.email_subscription_flg = "N"
    })


    data.forEach((i: any) => {
      data1.find((j: any) => {
        if (j.user_subscription_key == i.user_subscription_key) {
          return i.app_subscription_flg = "Y"
        }
      })

      data2.find((k: any) => {
        if (k.user_subscription_key == i.user_subscription_key) {
          return i.email_subscription_flg = "Y"
        }
      })
    })


    // Declare a new array
    let newArray: any = [];

    // Declare an empty object
    let uniqueObject: any = {};

    // Loop for the array elements
    for (let i in data) {

      // Extract the title
      let objTitle: any = data[i]['user_subscription_key'];

      //  Use the title as the index
      uniqueObject[objTitle] = data[i];
    }

    // Loop to push unique object into array
    for (const i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }

    this.treeControl.dataNodes.forEach((i: any) => {
      if (i.user_subscription_key) {
        const val = newArray.find((j: any) => i.user_subscription_key == j.user_subscription_key)
      }
    })

    newArray.forEach((i: any, m: any) => {
      this.defaultSelectedValues.forEach((j: any, k: any) => {
        if (j.user_subscription_key == i.user_subscription_key) {
          this.defaultSelectedValues.splice(k, 1)

        }
      })
    })

    this.defaultSelectedValues.map((i: any) => {
      i.app_subscription_flg = "N"
      i.email_subscription_flg = "N"
    })

    this.defaultSelectedValues.forEach((i: any) => {
      if (i) {
        newArray.push(i)
      }
    })

    // Update API
    const data3 = this.alertSettingsObj.filter((i: any) => !i.Status_Data)
    if (data) {
      const obj = {
        api_key: 100150,
        report_typ: "U",
        client_key: this.clientKey || "",
        employee_key: this.employeeKey ? this.employeeKey : '',
        alert_data: [{
          alerts: data3,
          status_Data: newArray ? newArray : []
        }]
      }
      this.newFilterService.executealertDataQuery(obj).subscribe((resp: any) => {
        if (resp) {
          // this.alertSettingsObj = resp;
          this.alertSettingsObj = []
          this.checklistSelectionApp = new SelectionModel<TodoItemFlatNode>(
            true /* multiple */
          );
          this.checklistSelectionEmail = new SelectionModel<TodoItemFlatNode>(
            true /* multiple */
          );
          this.buildTreeView()
        }
      })
    }

  }

  // Reset Alert Data
  resetSettings() {

    this.alertSettingsObj.forEach((i: any) => {
      i.email_subscription_flg = 'N'
      i.app_subscription_flg = "N"
    })

    this.DecheckAll()

  }

  // DeCheck All Tree Nodes Data
  DecheckAll() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.checklistSelectionApp.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelectionApp.toggle(this.treeControl.dataNodes[i]);
      }
      this.treeControl.collapse(this.treeControl.dataNodes[i]);
      if (this.checklistSelectionEmail.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelectionEmail.toggle(this.treeControl.dataNodes[i]);
      }
      this.treeControl.collapse(this.treeControl.dataNodes[i]);
    }
    this.toggle = false
  }

  // For Alerts without Tree Data checkbox Functionality
  handleChange(event: any, item: any, type: any) {
    this.alertSettingsObj.find((i: any) => {
      if (i.alert_name == item.alert_name) {
        if (type == 'Email') {
          return i.email_subscription_flg = event.checked ? 'Y' : 'N'
        } else if (type == 'App') {
          return i.app_subscription_flg = event.checked ? 'Y' : 'N'
        }

      }
    })

  }
}
