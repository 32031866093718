<div class="widget-container" #detailCard>
    <!-- Headings -->
    <main >
        <article #fs fullscreen-able [class.is-active]="isActive" [style.padding]="isActive ? '15px' : '5px'">

            <div class="m-1 mb-2">
                <!-- Header Configuration -->
                <icon-chart 
                    [headerConfig]="item.config?.widget_header_configuration"
                    [heading]="heading" 
                    [config]="gridList" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                ></icon-chart>
            </div>
            <hr>

            <div  style="overflow-y: auto;" class="desktopMode p-2">
                <div class="d-flex justify-content-between">
                    <div *ngFor="let r of gridArr">
                        <span style="color: #8A98AB">
                            {{ r.key.toUpperCase() }}
                        </span>
                        <br>
                        <span style="color: #101D42">
                            {{r.value}}
                        </span>
                    </div>
                </div>
            </div>

            <div  style="overflow-y: auto;" class="mobileMode p-2">
                <div *ngFor="let r of gridArr">
                    <span style="color: #8A98AB">
                        {{ r.key.toUpperCase() }}
                    </span>
                    <br>
                    <span style="color: #101D42">
                        {{r.value}}
                    </span>
                    <br>
                </div>
            </div>
            
            <div class="tabletMode p-2">
                <div class="row">
                    <div class="col-4 mb-2" *ngFor="let r of gridArr">
                        <span style="color: #8A98AB">
                            {{ r.key.toUpperCase() }}
                        </span>
                        <br>
                        <span style="color: #101D42">
                            {{r.value}}
                        </span>
                    </div>
                </div>
            </div>
            
        </article>
    </main>
</div>