import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  ElementRef,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularResizeElementDirection, AngularResizeElementEvent } from "angular-resize-element";
import moment from "moment";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/services/filter.service";
import { SpinnerService } from "src/app/services/spinner.service";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-dynamic-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {
  static key = 222222;
  
  moment = moment
  @Input("config") config: any = {};
  @Input("item") item: any;
  cardName: any;
  cardId: any;
  model: any = {};
  loadingForm = false;
  showAll: boolean = false
  fields: any[] = [];
  options: any = {};
  form = new FormGroup({});
  isConfigLoading = true;
  isReset = false;
  apiRequest: any;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  patientId: any;
  messages: any;
  employee_key: any;
  reqSubcription: Subscription[] = [];
  alert_notification:any;
  searchAlertText:any;
  searchAnnotationText:any;
  queryParams:any;

  constructor(
    public filterService: FilterService,
    public spinnerService: SpinnerService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public deviceDetectionService: DeviceDetectionService,
  ) {

    this.route.params.subscribe((p: any) => {
      this.cardName = p['cardName'];
      this.queryParams = p
    });

    this.isConfigLoading = true;
    var empkey: any = sessionStorage.getItem("employee_key");
    this.employee_key = JSON.parse(empkey);

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((res:any)=> {
      
      if(this.newFilterDesign) {
        if(this.filterService.filterQueryForAnnotation!=this.filterService.baseQuery?.['brand_key']) {
          this.filterService.filterQueryForAnnotation = this.filterService.baseQuery?.['brand_key']

          // this.loadAnnotation = true;
          // this.showAll = true;
          // debugger
          // let data = this.showAll ? '' : this.selectedTab.isPayload
          // this.getcommentsData(data);

          this.getAlertsNotificationData()
        }
      }
    }))
  }

  @ViewChild("offcanvasRight", { static: true }) offcanvasRight!: ElementRef;
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["item"].currentValue != changes["item"].previousValue) {
      this.loadConfiguration(this.item);
    }
  }

  trackByFnAlert(index:any) {
    return index;
  }

  trackByFnAnnotation(index:any) {
    return index;
  }

  ngOnInit(): void {
    this.filterService.filterQueryForAnnotation='';
    this.checkOrientation()
    this.showAll = true;
    // this.getcommentsData()
  }

  isPortrait = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortrait = window.innerWidth < window.innerHeight;
    this.data.width = 320
    this.filterService.model = {};
    this.filterService.widthForSideBar = this.data.width;
  }

  onModelChange(model: any) {
    let splitDate: any = {};

    let backup = ['quarter_select', 'date_range', 'quarter_range_select', 'time_date', 'time_single_date', 'from_date', 'to_date']

    let queryFilter = Object.entries(model).map(([key, value]) => ({ key, value }));
    let ifQuery: any = queryFilter.find((fnd: any) => backup.some((res: any) => res == fnd.key));

    let frmDate = 'from_date'
    let toDate = 'to_date'

    if (ifQuery) {
      splitDate = {}
      if (ifQuery.key == 'date_range') {
        splitDate[frmDate] = model[ifQuery.key]?.start
        splitDate[toDate] = model[ifQuery.key]?.end
        delete model.date_range;
      } else if (ifQuery.key == 'quarter_select' || ifQuery.key == 'quarter_range_select') {
        splitDate[frmDate] = moment(model[ifQuery.key]?.[frmDate]).toDate()
        splitDate[toDate] = moment(model[ifQuery.key]?.[toDate]).toDate()
        delete model?.quarter_select;
        delete model?.quarter_range_select;
      } else if (ifQuery.key == 'time_date' || ifQuery.key == 'time_single_date') {

        splitDate[frmDate] = model[ifQuery.key] ? model[ifQuery.key].from_date : '' 
        splitDate[toDate] = model[ifQuery.key] ? model[ifQuery.key]?.to_date : ''

        delete model?.time_date;
        delete model?.time_single_date;
      } else if (ifQuery.key == 'from_date') {
        splitDate[frmDate] = moment(model[ifQuery.key]).toDate()
      } else if (ifQuery.key == 'to_date') {
        splitDate[toDate] = moment(model[ifQuery.key]).toDate()
      } else {
        splitDate[frmDate] = this.filterService.startDate
        splitDate[toDate] = this.filterService.endDate
      }
    }

    if (!splitDate[frmDate]) {
      splitDate[frmDate] = ''
    }
    if (!splitDate[toDate]) {
      splitDate[toDate] = ''
    }
    
    if(ifQuery) {
      Object.assign(model, splitDate);
    }
     
    const modelLength = Object.keys(model).length;
    const filters: any = Object.fromEntries(
      Object.entries(model).filter(
        ([key, value]) => {
          return value != "";
        }
      )
    );

    let filtersLength = Object.keys(filters).length;
    // debugger
    // Call Api if length is true
    if (modelLength == filtersLength) {

      this.filterService.previousModel = model;

      // make it as Null if all value has been selected

      // debugger
      for (let key of this.filterService.selectAllAsNull) {
        const element = this.filterService.allOptionData.multi;
        if(model[key]?.length == element[key]?.length) {
          model[key] = null;
        }
      }

      this.filterService.setFilterValue(model , this.cardName ? this .queryParams : undefined);
    }
  }

  reset() {
    this.filterService.resetFilter();
  }

  globalData:any[]=[];
  dataFilter = false;
  newFilterDesign = false;
  
  trackByFn(index:any) {
    return index; // Replace 'id' with the unique identifier property of your item
  }

  loadConfiguration(item: any) {
     
    if (!item.config) return;
    if(item.key!=100700) {
      let data = item.config.find((f:any)=> f.name == 'Filter')
      this.globalData = item.config.filter((f:any)=> f.selected && !(f.name=='Information'));
      this.selectedTab = this.globalData[0];
      
      if(data) {
        let newData = {'all_source_type' : data.allSourceType, 'is_Selected': data.isSourceType}
        this.filterService.selectedFilterValue = newData;
        this.filterService.globalFilterFields = data.filterTemplate["template_config_details"];
      }
      this.newFilterDesign = true;
    } else {
      this.selectedTab.name = 'Filter'
      this.selectedTab.selected = true;
      this.filterService.selectedFilterValue = item.config.selected_filter;
      this.filterService.globalFilterFields = item.config.filter_name["template_config_details"];
      this.newFilterDesign = false;
    }

    this.dataFilter = true;
    
    this.setBadges();
    this.isConfigLoading = false;
  }

  setBadges() {
    this.filterService.badges = [];
    this.filterService.globalFilterFields.forEach((f: any) => {
      const exist = this.filterService.badges.find((b: any) => b.key == f.key);
      if (!exist) {
        this.filterService.badges.push({
          key: f.key,
          label: f.templateOptions.label,
          value: "",
          length: "",
          path: "",
        });
      }
    });
  }

  editedChanges(updatedDate: any) {
    if (updatedDate == null) { return false } else {
      let start_Date: any = moment(updatedDate);
      let end_Date: any = moment(new Date());
      let totalDays: any = end_Date.diff(start_Date, 'days');
      if (totalDays <= 2) {

        return true
      } else {
        return false
      }
    }
  }
  getCurrentDate(date: any) {
    if (date) {
      return moment(date).format('MM/DD/YYYY HH:mm')
    } else {
      return moment().format('MM/DD/YYYY HH:mm')
    }
  }
  
  loadAnnotation = false;
  loadAnnotationApi = false;
  getcommentsData(data?:any) {
    this.loadAnnotationApi = false;
    let postdata: any = { report_typ: "A", comment_typ: data ? data : null, api_key: '100132'};
    this.reqSubcription.push(this.filterService.commentsQuery(postdata).subscribe((res: any) => {
      this.loadAnnotation = false;
      this.loadAnnotationApi = true;
      let messages = res ? res : [];
      if(messages.length>0) {
        this.messages = [...messages].sort((a: any, b: any) => {
          const momentA = moment(a.insert_timestamp);
          const momentB = moment(b.insert_timestamp);
          return momentB.diff(momentA);
        });
      } else {
        this.messages = [];
      }
    }, err => {
      this.messages = [];
      this.loadAnnotationApi = false;
      this.loadAnnotation = false;
    }));
  }

  getAlertsNotificationData() {
  
    let postdata: any = {
      "brand_key": this.filterService.baseQuery?.['brand_key'],
      "client_key": this.filterService.baseQuery?.['client_key'],
      "employee_key": this.filterService.baseQuery?.['employee_key'],
      "api_key": "100410",
      "report_typ": "D"
    }

    this.reqSubcription.push(this.filterService.alertNotificationFilter(postdata).subscribe((res: any) => {
      this.alert_notification = res ? res : [];
    }, err => {
      this.alert_notification = [];
    }));
  }

  selectedTab:any = {
    name : '',
    selected: false
  };

  
  globalFilter(a:any) {
    // debugger
    this.selectedTab = a

    if(a.name == "Annotation") {
      this.loadAnnotation = true;
      let data = this.showAll ? this.selectedTab.isPayload : ''
      this.getcommentsData(data);
    }
  }

  showAllToggle(showAll:any) {
    // debugger
    this.loadAnnotation = true;
    let data = showAll ? '' : this.selectedTab.isPayload
    this.getcommentsData(data)
  }

  rightExpended = false;
  expendedRight() {
    this.rightExpended=!this.rightExpended
  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

  public readonly AngularResizeElementDirection = AngularResizeElementDirection;
  public data: any = {};

  public onResizeSide(evt: AngularResizeElementEvent): void {
    this.data.width = evt.currentWidthValue;
    this.data.height = evt.currentHeightValue;
    this.data.top = evt.currentTopValue;
    this.data.left = evt.currentLeftValue;
    this.filterService.widthForSideBar = this.data.width
  }

  // trackByFnAnnotation(index: number): number {
  //   return index;
  // }
  trackByFnMessage(index: number): number {
    return index;
  }
}
