
<div  class="widget-container" #GridDiv  >
  <main >
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
      <div>
        <icon-chart 
        [headerConfig]="headerConfig" 
        [iconList]="iconList" 
        [data]="rawData" 
        [pageKey]="pageKey" 
        [heading]="heading" 
        [chartName]="divId" 
        [config]="pivotGridDiv" 
        [expand]="fs" 
        [fullscreen]="isFullscreen" 
        [item]="item"
        ></icon-chart>  
      </div>
      <hr>  
      
      <!-- <div  style="display: flex;">
        <div   style=" text-align: center;width: 225px;">
          <h1 style="font-weight: 500; font-size: 20px; margin: 3px 0px 4px 0px !important ">{{'Dispense difference'}}</h1> 
        </div>
        <div  class="diffcard" style="background-color:#F6CFDA ; border: 2px solid #D11044 ; margin: 3px 0px 4px 0px !important">>0</div> &nbsp; &nbsp;
        <div  class="diffcard" style="background-color:#D8F4E5 ; border: 2px solid #3AC97C ; margin: 3px 0px 4px 0px !important">0</div>
      </div>
      <hr>  -->
      <!-- #pivotGridDiv -->
      <loading *ngIf="loader"></loading>
      <div style="height: 500px; width: auto; " *ngIf="noData">
           <span   *ngIf="noData" class="bg-text">There is no data for this time period</span>
         </div>
            <!-- <button  mat-raised-button  color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
            <div  [style.display]="noData?'none':'' "style=" width: 100%; overflow:auto;"  [ngStyle]="{ height : gridHeight } " #pivotGridDiv (click)="$event.preventDefault()" >
            <ag-grid-angular  id="barGridDiv" 
            style="width: 100%;"
            class="ag-theme-alpine testing settingHeight"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [rowHeight]="((this.item.config?.cumulative?.value)=='true') ?  160: 80"
            [headerHeight]="35"
            [suppressDragLeaveHidesColumns]="true"
            [suppressContextMenu]="true" 
            (gridReady)="onGridReady($event)"
            ></ag-grid-angular>
            <!-- [overlayNoRowsTemplate]="overlayNoRowsTemplate" -->
        </div>
      </article>
     </main>
</div>





  <!-- <div class="widget-container"> -->
    <!-- <div style="display:flex;flex-direction:row">
      <span style="flex:1;"><h2 style="font-weight: 500;">{{ ' No Header'}}</h2></span>
      <span>
        <mat-icon  class="MenuStyle" svgIcon="dotmenu_circle"   [matMenuTriggerFor]="menu"></mat-icon>
        <mat-icon [svgIcon]="expandCollapseIcon" (click)="expandCollapse()" [matTooltip]="expandOrCollapse" matTooltipClass="expand-tooltip" class="matExpandCollapseBtn"></mat-icon>
      </span>
     
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item  (click)="exportXls()"><mat-icon svgIcon="xls" ></mat-icon>Export XLS</button>
   </mat-menu> -->
   <!-- <ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [rowHeight]="90"
  ></ag-grid-angular> -->
    <!-- <ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine-dark"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [pivotMode]="true"
    [animateRows]="true"
    [rowHeight]="100"
    [rowData]="rowData"
    [pivotHeaderHeight]='0'
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular> -->
    <!-- </div> -->
    