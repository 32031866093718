<div class="linePopupTooltip" style="width:100%;">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <loading *ngIf="isLoading"> </loading>
      <div>
        <icon-chart [headerConfig]="headerConfig" [sourceHeaderName]="sourceName" [pageKey]="pageKey"
          [heading]="heading" [chartName]="'lineChart'" [config]="lineChartContainer" [expand]="fs"
          [fullscreen]="isFullscreen" [item]="item" [data]="lineData"></icon-chart>
      </div>
      <div><loading *ngIf="isLoading"> </loading></div>
      <div [style.display]="noData?'none':''" #lineChartContainer></div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
        <span class="bg-text">There is no data for this time period</span>
      </div>

      <!-- Line Chart Tooltip -->
      <div id="d3LineTooltip">
        <div class="container chart_tooltip" *ngIf="renderLinePopup">
          <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column">

              <div class="row mt-1 mb-1 chart_tooltip_header_heading">
                <div class="col-12 d-flex justify-content-between">
                  <div *ngIf="lineChartPopupData[1] && !config?.secondary_value_column && !sourceName" class="chart_tooltip_header_data">
                    Quantity Shipped ({{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }})
                  </div>
                  <div *ngIf="lineChartPopupData[1] && config?.secondary_value_column && !sourceName" class="chart_tooltip_header_data">
                    {{lineChartPopupData[1][0][0]}} ({{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }})
                  </div>
                  <!-- <div *ngIf="!config?.secondary_value_column && !sourceName" class="chart_tooltip_header_heading"  style="padding-left: 5px !important;"></div> -->
                  <!-- <div *ngIf="config?.secondary_value_column && !sourceName" class="chart_tooltip_header_heading1">{{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }}</div> -->
                 
                  <!-- <div *ngIf="lineChartPopupData[1] && config?.secondary_value_column && sourceName" class="col-6 mt-2 d-flex justify-content-center chart_tooltip_header_heading1">QUANTITY</div> -->
                  <!-- <div *ngIf="showMore"> <mat-icon class="matIcon" style=" height: auto !important; width: auto !important;" (click)="renderLinePopup = false">close</mat-icon></div> -->
                </div>
              </div>

              <div class="row mb-1" *ngIf="lineChartPopupData[1] && config?.secondary_value_column && sourceName">
                <div class="col-12 d-flex flex-row align-items-end">
                  <div class="col-6 d-flex chart_tooltip_header_data text-start" style="padding-left: 5px;">{{this.filterService.baseQuery.source_nm}} ({{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }})
                  </div>
                  <div class="col-2 chart_tooltip_header_data text-end" style="width: auto">Shipment</div>
                  <div class="col-2 chart_tooltip_header_data text-end" style="width: auto">Inventory</div>
                  <div class="col-2 chart_tooltip_header_data text-end" style="width: auto;padding-right: 30px;">Difference</div>
                </div>
              </div>
            </div>
          </div>


          <!-- When Height is Greater than 140 && !sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 140  && !sourceName && lineChartPopupData[1].length == 1 && !config?.secondary_value_column" [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after1' :'chart_tooltip_body1'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>
            </div>

          </div>
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 140  && !sourceName && lineChartPopupData[1].length == 1 && config?.secondary_value_column" [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-afterConfig' :'chart_tooltip_bodyConfig'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 140 && !sourceName && lineChartPopupData[1].length == 2 " [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 140 && !sourceName && lineChartPopupData[1].length == 3 " [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>

          </div>

          <!-- When height is Less than 140 && !sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 140 && !sourceName && lineChartPopupData[1].length == 1" [ngClass]="(dataTurn < 280) ?  'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>

          </div>
<!-- 100  -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 140 && !sourceName && lineChartPopupData[1].length == 2 " [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 140 && !sourceName && lineChartPopupData[1].length == 3 " [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
            </div>
          </div>

          <!-- When height is Greater than 250 && !sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 250 && !sourceName && lineChartPopupData[1].length >= 4 && lineChartPopupData[1].length < 6" [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>

            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 250 && !sourceName && lineChartPopupData[1].length >= 6" [ngClass]="(dataTurn < 280) ? 'chart_tooltip_body-afterSN' :'chart_tooltip_bodySN'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>

            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>

          <!-- When height is Less than 250 && !sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 250 && !sourceName && lineChartPopupData[1].length >= 4 && lineChartPopupData[1].length < 6" [ngClass]="(dataTurn < 280) ?  'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
              
            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 250 && !sourceName && lineChartPopupData[1].length >= 6" [ngClass]="(dataTurn < 280) ?  'chart_tooltip_body-after100SN' :'chart_tooltip_body100SN'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!sourceName">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <!-- <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  }"  svgIcon="line_circle"></mat-icon> -->
                  <div class="tooltip_outlay" [style.background]="'#ffff'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">
                  {{"Inventory Quantity:"}} {{data[1] ? numbedPipe(data[1]) : data[1]  }}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'grey'"></div>
                  <!-- <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } "  svgIcon="dashed_circle"></mat-icon> -->
                </div>
                <div class="chart_tooltip_body_data ml-2">{{"Shipment Quantity:"}} {{data[4] ? numbedPipe(data[4]) : data[4]}}</div><br>
              </div>
              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[4] - data[1]) }}</div>
              </div>
              
            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>

          <!-- When Height is Greater than 180 && sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 180 && lineChartPopupData[1].length == 1 && sourceName" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after1' :'chart_tooltip_body1'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
                style="padding-left: 3px;">
                <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                  <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
                </div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
                </div>
              </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 180 && lineChartPopupData[1].length == 2 && sourceName" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after2' :'chart_tooltip_body2'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
                style="padding-left: 3px;">
                <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                  <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
                </div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
                </div>
              </div>

              <!-- <div *ngIf="lineChartPopupData[1] && sourceName" class="chart_tooltip_header_data ml-2 mb-2"
                style="background-color:grey;">
                {{ data?.[0]}}
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <mat-icon [ngStyle]="{'color':data[3],'width': '8px'  } "  svgIcon="line_circle"></mat-icon>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2">{{data[4] ?
                  numbedPipe(data[4]) : data[4] }} {{ " Inventory Quantity"}}</div><br>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <mat-icon [ngStyle]="{'color': data[3] ,'width': '8px' } " style="margin-top:'3px'" svgIcon="dashed_circle"></mat-icon>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[1] ? numbedPipe(data[1]) : data[1] }} {{ " SHIPMENT
                  DISPENESES"}} </div><br>
              </div>

              <div *ngIf="config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#e4829e'"></div>
                </div>
                <div *ngIf="config?.secondary_value_column" class="chart_tooltip_body_data ml-2 mb-0"> {{"Quantity Difference:
                  "}} {{ numbedPipe( data[1] - data[4]) }}</div>
              </div> -->
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 180 && sourceName && (lineChartPopupData[1].length == 3)" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>
            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->

          </div>

          <!-- When Height is Less than 180 && sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 180 && lineChartPopupData[1].length == 1 && sourceName" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-afterbottom1' :'chart_tooltip_bodybottom1'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 180 && lineChartPopupData[1].length == 2 && sourceName" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>
            </div>

          </div>

          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 180 && lineChartPopupData[1].length == 3 && sourceName" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
                style="padding-left: 3px;">
                <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                  <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
                </div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
                <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
                </div>
              </div>
            </div>
          </div>

          <!-- When Height is Less than 240 && sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 240 && sourceName && (lineChartPopupData[1].length >= 4 && lineChartPopupData[1].length < 6)" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>
            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>
          <div class="mt-2 mb-5 pb-2" *ngIf="hei < 240 && sourceName && (lineChartPopupData[1].length >= 6)" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-afterSourceName' :'chart_tooltip_bodySourceName'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>
            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->
          </div>

          <!-- When Height is Greater than 240 && sourceName -->
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 240 && sourceName && (lineChartPopupData[1].length >= 4 && lineChartPopupData[1].length < 6)" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>

            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->

          </div>
          <div class="mt-2 mb-5 pb-2" *ngIf="hei >= 240 && sourceName && (lineChartPopupData[1].length >= 6)" [ngClass]="(dataTurn < 560) ? 'chart_tooltip_body-afterSourceName' :'chart_tooltip_bodySourceName'">
            <div class=" " *ngFor="let data of lineChartPopupData[1];let i=index">

              <div *ngIf="!config?.secondary_value_column" class="col-12 d-flex m-auto align-items-center pl-1">
                <div>
                  <div class="tooltip_outlay" [style.background]="data[3]"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                </div>
                <div class="ml-2" *ngIf="!config?.secondary_value_column">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="data[2] == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="data[2] == 'down'" />
                </div>
              </div>

              <div *ngIf="config?.secondary_value_column && sourceName" class="col-12 d-flex align-items-center"
              style="padding-left: 3px;">
              <div class="col-6 d-flex ml-0 pl-0 align-items-center">
                <div class="tooltip_outlay" [style.background]="data[3]"></div>
                <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
              </div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end"> {{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
              <div class="col-2 chart_tooltip_body_data ml-2 text-end">{{ numbedPipe( data[4] - data[1]) }}
              </div>
            </div>

            </div>
            <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
              more</div> -->

          </div>
        </div>
      </div>

    </article>
  </main>
</div>



<!-- Line chart Tooltip Popup -->
<ng-template #showMoreData>
  <div class="">
    <div class="row tooltip_popup_header">
      <div class="col-12 d-flex flex-column" *ngIf="sourceName">

        <div class="row chart_tooltip_header_heading">
          <div class="col-12 d-flex justify-content-between">
            <div class="mt-1" *ngIf="!sourceName">{{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }}</div>
            <div class="mt-1" *ngIf="sourceName">{{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }}</div>
            <!-- <div *ngIf="showMore"> <mat-icon class="matIcon" [mat-dialog-close]="true" style=" height: auto !important; width: auto !important;">close</mat-icon></div> -->
          </div>
          
        </div>

        <div class="row mt-1 mb-2">
          <div class="col-12 d-flex flex-row align-items-end">
            <div class="col-6 chart_tooltip_header_data text-start pl-5">DISPENSE QUANTITY </div>
            <div class="col-2 text-end chart_tooltip_header_dchart_tooltip_header_heading1 ata_in">Shipment Quantity</div>
            <div class="col-2 text-end chart_tooltip_header_data_in">Inventory Quantity</div>
            <div class="col-2 text-end chart_tooltip_header_data_in">Quantity Difference</div>
          </div>
        </div>
      </div>

      <div class="col-12 d-flex flex-column" *ngIf="!sourceName">
        <div class="row chart_tooltip_header_heading">
          <div class="col-12 d-flex justify-content-between">
            <div class="mt-1 pl-3">{{lineChartPopupData[0] | date: 'dd-MMM-yyyy' }}</div>
          </div>
          <div *ngIf="lineChartPopupData[1] && !sourceName" class="col-12 pl-3 chart_tooltip_header_data mt-1 mb-2">
            Quantity Shipped
          </div>
        </div>
      </div>
    </div>
    <div class="row details-body"  *ngIf="sourceName"  style="max-height: 400px;overflow-x: hidden;overflow-y: auto;">
      <div class="col-12 " *ngFor="let data of totalTooltipData[1];let i=index">
        <div class="col-12 d-flex align-items-center" style="padding-left: 3px;">
          <div class="col-6 d-flex pl-5">
            <div class="tooltip_outlay" [style.background]="data[3]"></div>
            <div class="chart_tooltip_body_data ml-2">{{data[0]}} : </div>
          </div>
          <div class="col-2 text-end chart_tooltip_body_data">{{data[4] ? numbedPipe(data[4]) : data[4] }}</div>
          <div class="col-2 text-end chart_tooltip_body_data"> {{data[1] ? numbedPipe(data[1]) : data[1] }}</div>
          <div class="col-2 text-end chart_tooltip_body_data">{{ numbedPipe( data[4] - data[1]) }}
          </div>
        </div>
      </div>
    </div>
    <div class="row details-body"  *ngIf="!sourceName"  style="max-height: 300px;overflow-x: hidden;overflow-y: auto;">
      <div class="col-12 d-flex mt-1 ml-2" *ngFor="let data of totalTooltipData[1];let i=index">
        <div>
          <div class="tooltip_outlay" [style.background]="data[3]"></div>
        </div>
        <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
        </div>
        <div class="ml-2">
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
            *ngIf="data[2] == 'up'" />
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
            *ngIf="data[2] == 'down'" />
        </div>
        <div>
        </div>
       
      </div>
    </div>
    <div [mat-dialog-close]="true" class="row details-body justify-content-center showLess_txt" >
      less</div>
  </div>

</ng-template>