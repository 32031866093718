<div style="width:100%;">
    <!-- *ngIf="type=='widget'" -->
    <main>
        <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
            <!-- <div style="display: flex; background-color: #fff;">
                <div class="col-md-3 ">
                    <h2
                        style="font-size: 22px; font-weight: 400; margin:0px !important; font-family: poppins !important;">
                        {{heading}}</h2>
                </div>
                <div *ngIf="iconList" style="margin: auto;margin-right: 1px;">
                    <icon-chart [headerConfig]="item.config?.widget_header_config" [iconList]="iconList" [data]="data" [pageKey]="pageKey" [heading]="heading"
                        [chartName]="divId" [config]="kpiBreakdownContainer" [expand]="fs" [fullscreen]="isFullscreen"
                        [item]="item"></icon-chart>
                </div>
            </div> -->
            <icon-chart *ngIf="iconList" [headerConfig]="item?.config?.widget_header_config" [iconList]="iconList" [data]="data" [pageKey]="pageKey" [heading]="heading"
            [chartName]="divId" [config]="kpiBreakdownContainer" [expand]="fs" [fullscreen]="isFullscreen"
            [item]="item"></icon-chart>

            <loading *ngIf="isLoading&&type=='widget'"> </loading> 
            <div id="kpiBreakdownChartDiv" [style.display]="noData?'none':''" #kpiBreakdownContainer  class="scrollcss" [ngStyle]="{ 'height':type=='chart'? '300px':( isFullscreen ? 'calc(100vh - 60px)': 'auto') ,'overflow-y': 'auto' ,'margin': '0px 10px' }" ></div>
            
            <div style="height: 300px; width: auto; " *ngIf="noData">
             <span  class="bg-text" >There is no data for this time period</span></div>
            <div id="d3HBarTooltip">
                <div class="container chart_tooltip" *ngIf="isTooltip ">
                    <div class="row chart_tooltip_header">
                        <div class="  d-flex flex-column">
                            <div class="chart_tooltip_header_heading mt-1">
                                {{  getPeriodtext()}}
                            </div>
                            <div style="color: white;">{{mytooltipData?.data?.name}}</div>

                        </div>
                    </div>
                    <div class="mt-2 mb-5"
                        [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                        <div class=" mt-1 ">
                            <div style="color: white;">{{mytooltipData?.data?.parent }}</div>
                            <div class="  d-flex m-auto  align-items-center">
                                <div>
                                    <div class="tooltip_outlay" [style.background]="mytooltipData?.data?.fill"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2"> {{  numbedPipe(mytooltipData?.data?.value)}} </div>

                            </div>
                        </div>
                        <div class=" mt-1 ">
                            <div style="color: white; font-size: 9px; margin-left: 24px;">{{"THRESHOLDS" }}</div>
                            <div class="  d-flex m-auto  align-items-center">
                                <div>
                                    <div class="tooltip_outlay" [style.background]="'#FFF'"></div>
                                </div>
                                <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(mytooltipData?.data?.dataLowerThreshold)}} - {{ numbedPipe(mytooltipData?.data?.dataUpperThreshold)}} </div>

                            </div>            
                           </div>
                        <div class=" mt-1 pb-2">
                            <div style="color: white; font-size: 9px; margin-left: 24px;">{{"CONTROL LIMITS" }}</div>
                            <div class="  d-flex m-auto  align-items-center">
                                <div>
                                    <div class="tooltip_outlay" [style.background]="'#FFF'"></div>
                                    <!-- <mat-icon [ngStyle]="{'color': 'gray' ,'width': '16px' } " style="margin-top: 3px !important;" svgIcon="dashed_circle"></mat-icon> -->
                                </div>
                                <div class="chart_tooltip_body_data ml-2"> {{ numbedPipe(mytooltipData?.data?.dataLowerLimit)}} - {{ numbedPipe(mytooltipData?.data?.dataUpperLimit)}} </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div  *ngIf="type=='chart'" class="d-flex " style="float: right;">
                <div style="margin: 5px;">
                    <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close (click)="filterService.isDataLoaded = false;">Cancel</button>
                </div>
                <div style="margin: 5px;">
                    <button type="button" class="btn btn-primary btnDelete" mat-dialog-close (click)="compareClick('route')">Apply</button>
                </div>
            </div>
        </article>
    </main>
</div>
<!-- <div *ngIf="type=='chart'" ></div>
<div id="kpiBreakdownChartDiv" #kpiBreakdownContainer style="overflow-y: auto"></div> -->
<!-- {
    "data": {
        "name": "Patients",
        "value": 157,
        "status": "anomaly",
        "selectedIndex": "",
        "y": 180,
        "parent": "RXCROSSROADS"
    },
    "ranges": {
        "threshold": [
            300,
            2000
        ],
        "limit": [
            500,
            3000
        ]
    }
} -->