import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { EnhancementService } from 'src/app/services/enhancements-service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-bullet-chart-card',
  templateUrl: './bullet-chart-card.component.html',
  styleUrls: ['./bullet-chart-card.component.scss']
})
export class BulletChartCardComponent implements OnInit, OnDestroy {
  static key = 100607;

  @Input('item') item: any
  @Input('pageKey') pageKey: any
  heading: any;
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('bulletChartDiv', { static: true }) bulletChartDiv!: ElementRef
  config: any
  data: any
  isDataLoaded = false
  totalData: any
  reqSubcription: Subscription[] = []
  constructor(
    private filterService: FilterService, private apiService: ApiService, private httpClient: HttpClient,private enhancementService:EnhancementService
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
    })
    )
  }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.bulletChartDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData(reportType?: string) {
    let api = this.item.config["list_of_api"] || {};

    if ((this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {

      // API Payload
      let obj1: any = { "api_key": api }
      let obj2: any = { "api_key": '100197' }

      // Parent 
      let query1 = Object.assign({}, this.filterService.baseQuery, obj1)
      query1["report_typ"] = this.filterService.report_type;
        if(this.enhancementService.currentQuarterFlag ) {
          query1["table_nm_version"] = 'curr'
        } else {
          query1["table_nm_version"] = 'hist'
        }

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res: any) => {
          this.isDataLoaded = true;
          this.data = res == null ? [] : res;
        }, (err: any) => {
          this.data = [];
          this.isDataLoaded = true
        })
      )


      if (this.item.config["chart_type"]=='bullettable') {
        // Child
        let query2 = Object.assign({}, this.filterService.baseQuery, obj2)
        query2["report_typ"] = this.filterService.report_type;
          if(this.enhancementService.currentQuarterFlag ) {
            query2["table_nm_version"] = 'curr'
          } else {
            query2["table_nm_version"] = 'hist'
          }
        this.reqSubcription.push(
          this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res: any) => {
            this.isDataLoaded = true;
            this.totalData = res == null ? [] : res;
          }, (err: any) => {
            console.log(err)
            this.totalData = []
          })
        )
      }
      // this.reqSubcription.push(
      //   this.filterService.executeQuery(obj1).subscribe(
      //     (res: any) => {
      //       this.isDataLoaded = true;
      //       this.data = res == null ? [] : res;
      //     },
      //     (error: any) => {
      //       this.data = [];
      //     }
      //   ),
      //   this.filterService.executeQuery(obj2).subscribe(
      //     (res: any) => {
      //       this.isDataLoaded = true;
      //       this.totalData  = res == null ? [] : res;
      //     },
      //     (error: any) => {
      //       this.data = [];
      //     }
      //   )
      // );
    }
    // let postdata = {

    //   "client_key": 2001,
    //   "report_typ": "D",
    //   "api_key": 100197
    // }
    // this.apiService.post("kpi/get-execution-details", postdata).subscribe(
    //   (res: any) => {
    //     this.isDataLoaded = true;
    //     this.totalData = res == null ? [] : res;
    //   },
    //   (error: any) => {
    //     this.totalData = []
    //   }
    // )
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}



