<div>
  <div class="title">Contents</div>
  <div  style=" height: 93vh;width: 300px; overflow-y:auto;">
    <mat-tree style="margin-left:10px;" [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div class="cardtree" (click)="onClick(node)">
          <!-- <mat-icon>credit_card</mat-icon>&nbsp; -->
          <span class="child" >{{node.widget_nm}}</span>
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" style="text-align: left;" matTreeNodeToggle [attr.aria-label]="node.widget_nm"  (click)="onClick(node)">
         <div  class="parent" [style.color]="treeControl.isExpanded(node)?'#1363DF':'#101D42'">{{node.widget_nm}}</div> 
         <div> <button  mat-icon-button >            
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button></div>
        </div>
        <!-- There is inline padding applied to this div using styles.
              This padding value depends on the mat-icon-button width.  -->
        <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>  