<div [style.display]="this.clientKey ? 'block': 'none'">
  <hr class="line" [ngStyle]="{ background: newColor }" />

  <app-topnav [clientLogo]="clientLogo"></app-topnav>
  
  <div *ngIf="pages && pages.length && selectConfig == 'S3'" style="overflow-y: auto;height: calc(100vh - 65px);">
    <div class="row mx-1 reasonScrolls mt-3">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3" *ngFor="let page of pages" [style.display]="page.hide_flg == 'Y' ? 'none':'block'">
        <mat-card class="card-container du-mat-card-dashboard" *ngIf="page.hide_flg == 'N'">
          <mat-card-header>
            <!-- <div mat-card-avatar class="example-header-image"></div> -->
            <mat-card-title style="white-space: nowrap;" class="du-dashboard-name-header">{{ page.dashboard_nm }}</mat-card-title>
          </mat-card-header>
          <div class="du-dashboard-image mt-2">
            <img mat-card-image class="du-mat-card-image" [src]="page.active_image" />
          </div>
          <mat-card-actions>
            <div class="row">
              <div [class]="page.hist_access_flg == 'H' ? 'col-md-12' : 'col-md-6'" class="p-2">
                <button mat-raised-button style="font-family: poppins !important;" [style.width.%]="100" color="primary" [disabled]="page.access_flg == 'N'"
                  [hidden]="page.access_flg == 'H'" (click)="openPage(page)">
                 <span style="font-size: 18px;"> {{moduleKey==100101 ? 'Live' : 'View'}}</span>
                </button>
              </div>
              <div class="col-md-6 p-2" *ngIf="page.hist_access_flg != 'H'">
                <button mat-raised-button style="font-family: poppins !important;" [style.width.%]="100" color="primary" [disabled]="page.hist_access_flg == 'N'">
                  History
                </button>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <!-- <div class="text-center p-3" style="position: absolute; bottom: 0;">
        Copyright ©2024 Data Unveil
      </div> -->
  
    </div>
  </div>
  
  <div class="p-5 m" *ngIf="selectConfig == 'DB' && checkFlag" style="position: relative;">
  
    <div class="d-flex justify-content-center">
      <div style="width: 100%">
        <span class="title" style="font-weight: 600; font-size: 15px;">Select Workname</span>
        <br>
        <div>
          <ng-select #sc style="font-size:13px;" (change)="openPage(selectedWork)" [(ngModel)]="selectedWork"
            [clearable]="false" placeholder="Type to Search">
            <ng-option *ngFor="let option of DbPages" [value]="option">
              {{ option.work_nm }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #brandkeySelection>
  <!-- <div> -->
  <!-- <mat-icon class="close-icon" style="float: right;margin: 5px;" [mat-dialog-close]="true">close</mat-icon> -->
  <!-- </div> -->
  <style>
    ::ng-deep .mat-select-value-text {
      font-size: 14px;
      font-family: "Poppins";
    }
  </style>
  <div style="font-family: 'Poppins';">
    <div class="pt-2 px-2 text-center text-white" style="background-color: #1363DF;">
      <h3 style="margin: 0 !important;font-family: 'Poppins';"> Select Brand</h3>
      <hr style="padding: 0 !important;margin: 0 !important">
    </div>

    
    <div style="min-height: auto !important;font-family: 'Poppins';padding: 10px;">
      <div class="pb-3">
        <select style="font-size: 12px;font-family: 'Poppins';" class="form-select p-2" [(ngModel)]="test" aria-label="Default select example">
          <option value="no">Select Option</option>
          <option *ngFor="let a of brandKeyData" [value]="a.brand_key" style="font-size: 12px;font-family: 'Poppins';padding: 10px;">
            {{a.brand_name}}
          </option>
        </select>
      </div>
      <!-- <mat-form-field appearance="fill" style="width: 100%;font-family: 'Poppins';">
          <mat-label style="font-size: 12px;font-family: 'Poppins';">Select Brand</mat-label>
          <mat-select [(ngModel)]="test" style="width: 100%">
            <mat-option *ngFor="let a of brandKeyData" [value]="a.brand_key"
              style="font-size: 12px;font-family: 'Poppins';">
              {{a.brand_name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="d-flex justify-content-end ml-3">
          <!-- <button [mat-dialog-close]="true" type="button" class="btn btn-light">Cancel</button> &nbsp; &nbsp; -->
          <button type="button" style="font-size: 12px;font-family: 'Poppins';" (click)="setBrandKey(test)"
            class="btn btn-primary">Apply</button>
        </div>
      </div>
  </div>

</ng-template>