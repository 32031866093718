<div class="widget-container" #pendingPipelineDiv>
  <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">

    <div class="outlay_pipeline card" id="outlay_pipeline_full">
      <div class="card-body">
        <div class="mb-3">
          <icon-chart 
            [headerConfig]="item.config?.widget_header_configurations" 
            [selectedFilter]="selectedFilter"
            [pageKey]="pageKey" 
            [heading]="heading" 
            [chartName]="'NetworkChart'" 
            [config]="NetworkChartContainer"
            [expand]="fs" 
            [fullscreen]="isFullscreen" 
            [item]="item" 
            (screenChanges)="screenChanges($event)"
            [sliderValue]="this.filterService.sliderValue" 
            [apiKey]="100126"></icon-chart>

        </div>

        <loading *ngIf="loader"></loading>

        <div id="completeNetworkChart" #NetworkChartContainer class="mt-2 mb-3 overflow-auto">
          <app-network-chart [pieColorObject]="pieColorObject" [dropdownArray]="dropdownArray"
            [sliderValue]="this.filterService.sliderValue" [sortBySelectedData]="this.sortBySelectedData"
            [listStyle]="listStyle" [item]="item" (emitLoader)="emitLoader($event)"></app-network-chart>
        </div>
      </div>
    </div>
  </article>
</div>