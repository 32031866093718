<div class="widget-container" #smallBarChart>
    <app-small-bar-chart 
        style="width: 100%;" 
        [config]="config" 
        [headerConfig]="this.item.config.widget_header_config"
        [heading]="heading" 
        [data]="data" 
        [item]="item" 
        [pageKey]="pageKey">
    </app-small-bar-chart>
</div>