import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash';

import { WidgetConfig } from '../components/widget/widget.model'

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  static getApiKey (config: any): number {
    return parseInt(config.config.list_of_api?.api_key || config.config.list_of_api || config.apiKey) || 999999
  }
  static getId (config: WidgetConfig) {
    const apiKey = WidgetService.getApiKey(config)
    return config.key * Math.pow(10, Math.floor(Math.log10(apiKey)) + 1) + apiKey
  }
  action$: Record<string, Subject<any>> = {}
  pageHeaderWidgets: WidgetConfig[] = []
  filterIcon = false
  alertIcon = false
  showLessAndMore = new Subject<boolean>()

  #init (id: number) {
    this.action$[id] = this.action$[id] || new Subject()
  }

  set (_id: number | WidgetConfig, action?: Action): void {
    const id = _.isNumber(_id) ? _id : WidgetService.getId(_id)
    this.#init(id)
    this.action$[id].next(action)
  }

  get (_id: number | WidgetConfig, prop?: string) {
    const id = _.isNumber(_id) ? _id : WidgetService.getId(_id)
    this.#init(id)
    const pipes = []
    if (prop) pipes.push(filter((action: Action) => !prop || action.name === prop))

    // @ts-ignore
    return this.action$[id].asObservable().pipe(...pipes)
  }
}

export type Action = {
  name: string
  state?: any
}