<!-- <div class="login-wrapper">
  <div class="w-100 globalNav">
      <div class="navbar-area sticky-top">
          <div class="main-nav">
              <div class="w-100 header_home_main">
                  <nav class="navbar navbar-expand-md navbar-light">
                      <a class="navbar-brand" href="index.html">
                          <img src="assets/SCRAII_Logo.png" alt="Logo" />
                      </a>
                  </nav>
              </div>
          </div>
      </div>
  </div>

  <div class="login-container">
      <div class="row mx-0">
          <div class="col-xl-6 col-lg-8 col-md-9 col-sm-12 card flex-row mx-auto px-0">
              <div class="img-left d-none d-md-flex"></div>
                <div class="card-body">
                    <div style="text-align: center;font-family: Poppins;">
                        <div class="col-lg-12 col-sm-12 col-12 p-0">
                            <h4 class="title mt-3 mb-4">
                                Session Timeout
                            </h4>
                            <p class="font12 mb-4">Your session has expired. <br> Please log in again to continue accessing our site.</p>
                        </div>

                        <button type="submit" class="btn btnBtn btn-block" (click)="logout()">Login</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->

<div class="container-fluid">
    <div class="row">
        <div class="du_login_box col-lg-6 col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-between"
        style="height:100vh">
            <div class="row mt-2 pl-4">
                <img class="pt-2" src="../../../../assets/SCRAII_LoginLogo.svg" style="width: 25%" />
            </div>
            <div class="row d-flex justify-content-center"
                style="width: -webkit-fill-available;top: 25% !important;position: absolute;">
                <div class="card shadow-lg w-75">
                    <div class="card-body">
                        <div class="row get_started">
                            Session Expired!
                        </div>
                        <div class="row mt-2 get_started_subheading">
                            Your session has expired. Please log in again to continue accessing the site.
                        </div>
                        <div class="row mt-5 mb-4 d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary login_button w-50" (click)="logout()">
                                Back To Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center footer">
                <div class="mb-2">Copyright © 2024 All Rights Reserved by <b>Data Unveil</b></div>
            </div>
        </div>
        <!-- <div class="col-6 pr-0 mr-0">
            <img src="../../../../assets/Signup.png" style="height: 100vh; width: -webkit-fill-available" />
        </div> -->
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6">
            <img class="du_login_newIMG"
            *ngFor="let image of images; let i = index"
            [src]="image"
            alt="Slide {{ i }}"
            [@fadeAnimation]="currentImageIndex === i"
            [style.z-index]="currentImageIndex === i ? 1 : 0"
          />
    </div>
</div>