import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { DataService } from 'src/app/services/data.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-hbar-chart',
  templateUrl: './hbar-chart.component.html',
  styleUrls: ['./hbar-chart.component.scss']
})
export class HbarChartComponent implements OnInit,OnChanges {

  // @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  @Input('data') data: any
  
  @Input('pageKey') pageKey: any
  @Input('height') dynamicHeight: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  noData:boolean=false
  isActive = false;
  currentVisibleData: any = [];
  comboBarPopupData:any
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  HbarChartData:any=[];
  props: any;
  divId: any = "HBarChartDiv";
  dataTurn: number = 0
  renderLinePopup:any = false
  initiateChart:boolean =false;tooltip:boolean =false;
  showBy:any;mytooltipData:any
    constructor( public filterService: FilterService,private dataService :DataService,private container: ElementRef,private currency:CurrencyPipe,private newFilterService: NewFilterService, public deviceDetectionService:DeviceDetectionService ) { 
      this.filterService.filterQuery.subscribe((query: any) => {
        this.start()
      })
    }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event:any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }
  closeFullscreen(): void {
    this.isFullscreen = false;
    this.props.chartHeight= this.item.config.chart_type=='small'?230:500
    this.isActive = false;
    
    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } 
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }


//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

 
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
      // console.log(myData)  
      this.mytooltipData = myData
      this.dataTurn = 0
      this.dataTurn = chartWidth - myX
      this.tooltip=true
      if (this.dataTurn < 250) {
        d3.select("#d3hbarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', myY + 80 + 'px')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
          .style('bottom', 'unset')
      }
      else
       if (this.dataTurn > 250) {
  
        d3.select("#d3hbarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (  myY + 80) + 'px')
          .style('left', (myX+20) + 'px')
          .style('right', 'unset')
          .style('bottom', 'unset')
      }
  }

  private hideTooltip(myType: any): void {
    this.tooltip=false
    d3.select("#d3hbarTooltip")
      .style('visibility', 'hidden');
  }

  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
    

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue != changes['data']?.previousValue && this.initiateChart) {
      this.getHbarChartData();

    }
  }
  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("class", "xAxis" + myClass);
    mySvg.append("g").attr("class", "yAxis" + myClass);
    this.initiateChart=true
    }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    let height =  myChart.props.chartHeight;
    const margins = { left: 80, right: 20, top: 20, bottom: 30, group: 4, brush: 52, mid: 24, range: 52} ;


    mySvg.attr("height", height);

    let xMax: any = undefined;
    let xScale: any = undefined;
    let xScaleWidth: any = undefined;

    myChart.HbarChartData.map((m: any) => m[myChart.props.xVar] = isNaN(m[myChart.props.xVar]) ? 0 : m[myChart.props.xVar])
    let barData: any = [];
    if (myChart.props.stackVar === null) {
      barData = d3.rollup(myChart.HbarChartData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.xVar]), (d: any) => d[myChart.props.barVar])
      barData = Array.from(barData);
      if(myChart.props.xVar2){
        barData.forEach((element:any) => {
          element[2]= parseInt( myChart.HbarChartData.find((d:any)=> element[0] ==d[myChart.props.barVar])[myChart.props.xVar2])
          
        });}
      barData = barData.map((m: any) => m = {
        name: m[0],
        value: m[1],
        value2: m[2]||0,
        start: 0,
        fill: myChart.props.barFill[myChart.props.xVar],
        borderRadius: 4
      });
      xMax = d3.max(barData, (d: any) => Math.max( d.value,d.value2));
      xScale = d3.scaleLinear().domain([0, xMax]).range([0,width - margins.left - margins.right]);
      xScaleWidth = d3.scaleLinear().domain([0, xMax]).range([0,width - margins.left - margins.right]);
      barData.map((m: any) => m.width = xScaleWidth(m.value))
      barData.map((m: any) => m.borderRadius = 2);
      barData.map((m: any) => m.totalValue = m.value);
      barData.map((m: any) => m.extraX = 0);
      barData.map((m: any) => m.width2 = xScaleWidth(m.value2))
    } else {
      let barVars: any = new Set();
      let stackVars: any = new Set()
      myChart.HbarChartData.forEach((d: any) => {
        barVars.add(d[myChart.props.barVar]);
        stackVars.add(d[myChart.props.stackVar]);
      })
      let stackData: any = [];
      Array.from(barVars).forEach((d: any) => {
        const myData: any = {name: d}
        Array.from(stackVars).forEach((v: any) => {
          const myValue = d3.sum(myChart.HbarChartData, (s: any) =>
            s[myChart.props.barVar] === d && s[myChart.props.stackVar] === v
              ? s[myChart.props.xVar] : 0);
          myData[v] = myValue;
        })
        stackData.push(myData);
      })
      stackData = d3.stack()
        .keys(Array.from(stackVars))
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)
        (stackData);


      xMax = d3.max(stackData, (d: any) => d3.max(d, (m: any) => m[1]));
      xScale = d3.scaleLinear().domain([0, xMax]).range([0, width - margins.left - margins.right]);
      xScaleWidth = d3.scaleLinear().domain([0, xMax]).range([0, width - margins.left - margins.right]);
      const extraXs: any = {};
      stackData.forEach((d: any, i: any) => {
        const myKey = d.key;
        const isLast = (d.index === (stackData.length - 1));
        d.map((m: any) => m.value = (m[1] - m[0]))
        d.map((m: any) => m.width = xScaleWidth(m.value));
        d.forEach((s: any, index: any) => {
          let myExtraX = 0;
          if (s.height < 2 && s.value > 0) {
            myExtraX = (2 - s.width);
            s.height = 2;
            extraXs[index] = (extraXs[index] === undefined ? myExtraX : extraXs[index] + myExtraX)

          }
          barData.push({
            name: s.data.name,
            start: s[0],
            value: s.value,
            width: s.width,
            fill: myChart.props.barFill[myKey],
            extraX: extraXs[index] === undefined ? 0 : -extraXs[index],
            totalValue: s[1],
            borderRadius: isLast ? 2 : 0,
            groupIndex: index,
            stackIndex: i,
            errorType: myKey
          })
        })
      })
    }

    const emptyLastBars = barData.filter((f: any) => f.borderRadius > 0 && f.value === 0);
    emptyLastBars.forEach((d: any) => {
      d.borderRadius = 0;
      let filledBars = barData.filter((f: any) => f.groupIndex === d.groupIndex && f.value > 0);
      filledBars = filledBars.sort((a: any, b: any) => d3.descending(a.stackIndex, b.stackIndex));
      if (filledBars.length > 0) {
        filledBars[0].borderRadius = 2;
      }
    })
    const yTicks: any = [];
    barData.forEach((d: any) => yTicks.push(d.name));
    const minBarHeight = 24;
    const heightNeeded = margins.top + margins.bottom + (yTicks.length * minBarHeight);
    if(height < heightNeeded){
      height = heightNeeded;
      mySvg.attr("height", heightNeeded);
    }

    const yScale: any = d3.scaleBand().domain(yTicks).range([0, height - margins.top - margins.bottom]);

    mySvg.select('.yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).tickValues(yTicks))
      .attr('transform', 'translate(' + margins.left + ',' + (margins.top-5) + ')');
    mySvg.select('.xAxis' + myClass)
      // @ts-ignore
      .call(  this.item.config.chart_type=='small'?d3.axisBottom(xScale).tickSizeOuter(0).ticks(5).tickFormat((d) => `${this.currency.transform(d, "", "", "1.0-2") }  days`):d3.axisBottom(xScale).tickSizeOuter(0).ticks(5,myChart.props.xFormat))
      // .call(d3.axisBottom(xScale).tickSizeOuter(0).ticks(5,myChart.props.xFormat))
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

    mySvg.selectAll('.yAxis' + myClass + ' text')
      .style('font-weight', 700)
      .style('font-family', "Poppins")
      .style('fill', "#101D42")
      .style('font-size', 12)
      .text((d: any) => getYLabel(d.replace(/_/g, ''), margins.left - 15))
      .on("mouseover", (event:any, d: any) => {
        if(getYLabel(d.replace(/_/g, ''), margins.left - 15).includes("..")){
          myChart.showTooltip('groupFullName', d.replace(/_/g, ''), event.offsetX, event.offsetY, width);
        }
      })
      .on("mouseout", () => {
        myChart.hideTooltip("groupFullName");
      });

    mySvg.selectAll('.xAxis' + myClass + ' text')
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#8A98AB")
      .style('font-size', 8)

    mySvg.selectAll('.xAxis' + myClass + ' line')
      .attr('y1', 0)
      .attr('y2', -(height - margins.top - margins.bottom))
      .style('stroke', "#E8EAEE")
      .style('stroke-width', 1);

    mySvg.selectAll('.xAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.yAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.yAxis' + myClass + ' line')
      .style('display', "none")

    if(myChart.props.barLabels !== undefined){
      drawLegend(Object.keys(myChart.props.barFill));
    }

    const barGroup = mySvg
      .selectAll('.chartGroup' + myClass)
      .data(barData)
      .join((group: any) => {
        const enter = group.append('g').attr('class', 'chartGroup' + myClass);
        enter.append('path').attr('class', ' bars barRect');
        enter.append('path').attr('class', ' bars barRect1');
        return enter;
      });

      const barHeight:any = (yScale.bandwidth() / 2)-15;

    barGroup.select('.barRect')
      .attr('d', (d: any) => 'M0,0 L' + (d.width - d.borderRadius) + ',0 '
        + 'Q' + d.width + ',0 ' + d.width + ',' + d.borderRadius
        + 'L' + d.width + ',' + (barHeight - d.borderRadius)
        + 'Q' + d.width + ', ' + barHeight+ ',' + (d.width - d.borderRadius)
        + ',' + barHeight + ' L0,' + barHeight + 'Z')
      .attr('fill', (d: any) => d.fill)
      .attr('stroke-width', 0)
      .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.start) + d.extraX)
        + ',' + (margins.top + yScale(d.name) + (barHeight/2)) + ')')
        barGroup.select('.barRect1')
        .attr('d', (d: any) => 'M0,0 L' + (d.width2 - d.borderRadius) + ',0 '
          + 'Q' + d.width2 + ',0 ' + d.width2 + ',' + d.borderRadius
          + 'L' + d.width2 + ',' + (barHeight - d.borderRadius)
          + 'Q' + d.width2 + ', ' + barHeight+ ',' + (d.width2 - d.borderRadius)
          + ',' + barHeight + ' L0,' + barHeight + 'Z')
        .attr('fill','#FFB039')  // (d: any) => d.fill)
        .attr('stroke-width', 0)
        .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.start) + d.extraX)
          + ',' + (margins.top + yScale(d.name) + (barHeight) +10) + ')')

          barGroup.selectAll('.bars').on("mouseover", (event: any, d: any) => {
        let tooltipData = "";
        if (myChart.props.stackVar === null) {
          tooltipData = d;
        } else {
          tooltipData = barData.filter((f: any) => f.name === d.name);
        }
        myChart.showTooltip('bar', tooltipData, event.offsetX, event.offsetY, width);
      })
      .on("mouseout", () => {
        myChart.hideTooltip('bar');
      });

    function getYLabel(myLabel: any, bandwidth: any){
      while(!checkWidth(myLabel) || myLabel === ".."){
        if(myLabel.includes("..")){
          myLabel = myLabel.substr(0, myLabel.length-2);
        }
        myLabel = myLabel.substr(0, myLabel.length - 1) + "..";
      }
      return myLabel;
      function checkWidth(myText:any){
        if((measureWidth(myText, 12) + 4) > bandwidth){
          return false
        } else {
          return true
        }
      }
    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function drawLegend(legendData: any) {

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(" + (margins.left) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d)
        .attr('fill', (d: any) => myChart.props.barFill[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any) => 'legendItem' + d)
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => myChart.props.barLabels[d].toUpperCase())
      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function (): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + 12);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (40 + myWidth)
      });

    }
    this.stop(10).then(() => this.isLoading = false);
  }
// property & data for the chart
  getHbarChartData(){
    //works with stackVar null - defect-by-column-name
      this.HbarChartData= this.item.config.chart_type=='small'?this.aggerationData(this.data):this.data
      this.props = this.item.config.chart_type=='small'? {
        chartHeight: 220,//this.deviceDetectionService.isDesktop ? (this.dynamicHeight+30 ): 200,
        barVar: 'field_name',
        xVar: 'this_account',
        xVar2: 'network',
        stackVar: null, //single bar if stackVar === null
        barFill:  {'this_account':'#1868E3','network':'#FFB039'},
        barLabels:  {'this_account':'This account','network':'Network'},
        xFormat:"0.0f",// '$,.0f',
        topAxis: false,
        sortDescending: false,
        showTotal: false,
        topValues: 10 // null if not needed
      }:{
        chartHeight:  500,
        barVar: 'brand_name',
        xVar: 'amount_per_record',
        stackVar: null, //single bar if stackVar === null
        barFill: {'amount_per_record':'#4E90F8'},
        barLabels: undefined ,
        xFormat: this.item.config.chart_type=='small'?  "0.0f" :'$,.0f'
      }
      // @ts-ignore
      if ( this.HbarChartData.length > 0){
          this.noData=false
          this.plotChart();
      }else{
        this.isLoading=false
        this.noData=true
      }
  }
  // numberFormat
  numberFormating(value:any){
    return this.item.config.chart_type=='small'? this.currency.transform(value, "", "", "1.0-2") +" days":d3.format("$.2s")(value)
  }
aggerationData(data:any){
  const transformedArray:any = data.reduce((acc:any, curr:any) => {
    const fieldName = curr.type.charAt(0).toUpperCase() + curr.type.slice(1).toLowerCase();
    const categoryKey = curr.category.toLowerCase().replace(/\s+/g, '_');
    
    const existingItem = acc.find((item:any) => item.field_name === fieldName);
    if (existingItem) {
        existingItem[categoryKey] = (existingItem[categoryKey] || 0) + curr.value;
    } else {
        const newItem = {
            field_name: fieldName,
            [categoryKey]: curr.value
        };
        acc.push(newItem);
    }
    return acc;
}, []);
return transformedArray
}
}