<div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
        
        <mat-icon *ngIf="this.data.action=='error'" style="color: #E74028;overflow: visible;">error_outline</mat-icon>
        <mat-icon *ngIf="this.data.action=='success'" style="color: #2CB239;overflow: visible;">check_circle_outline</mat-icon>
        <mat-icon *ngIf="this.data.action=='warning'" style="color: #FFB039;overflow: visible;">warning_outline</mat-icon>
        <mat-icon *ngIf="this.data.action=='info'" style="color: #25D3EB;overflow: visible;">info_outline</mat-icon>
        
        <span style="color: #d32007;font-family: 'Poppins';margin-left: 10px;width: 100%;" *ngIf="this.data.action=='error'">
            There was an issue while retrieving data. Please try again. 
            <br>
            Reach out to support 
            <a href="mailto:sanofi-scraii-support@dataunveil.com">{{'sanofi-scraii-support@dataunveil.com'}}</a> 
            if the issue persists.
        </span> 
        
        <span style="color: #099a12;font-family: 'Poppins';margin-left: 10px;width: 100%;" *ngIf="this.data.action!='error'">
            {{ this.data.message || '' }}
        </span> 
    </div>
    <!-- <div>
        <button mat-button [style.color]="this.data.action=='error' ? '#E74028' : '#2CB239' " style="color: #fff;line-height: 25px;font-family: 'Poppins';"  aria-hidden="false" class="du-snack-mat-btn" (click)="snackBarRef.dismiss()">Ok</button>
    </div> -->
</div>