import {
  Component,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import _ from "lodash";
import { Subject, Subscription } from "rxjs";
import { FilterService } from "src/app/services/filter.service";
import { NewFilterService } from "src/app/services/new-filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";
@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-show-by-card",
  templateUrl: "./show-by-card.component.html",
  styleUrls: ["./show-by-card.component.scss"],
})
export class ShowByCardComponent implements OnInit, OnChanges, OnDestroy {
  static key = 100605;
  @Input("item") item: any = {};
  @Input("pageKey") pageKey: any;
  showByData: any = [{ label: "No Data" ,value:"no data" }];
  reqSubcription: Subscription[] = [];
  selectedValueByUserActivity: any;
  selectedValue: any;
  queryParams:any;
  matSelectWidth: number = 160; // Default width
  constructor(
    private newFilterService: NewFilterService,
    private filterService : FilterService,
    private userActivityService: UserActivityService,
    private route: ActivatedRoute,
  ) {
    this.reqSubcription.push(
    this.filterService.showbyfilterQuery.subscribe((data:any)=> {
      setTimeout(() => {
        this.loadshowByApiData(data);
      }, 100);
    }));
    
    this.route.params.subscribe((p: any) => {
      this.queryParams = p;
    });

    this.filterService.showByUOM.subscribe((query: any) => {
      // this.loadApiData(query);
    })
  }
 
  
  
  ngOnInit(): void {
    let cfg = this.item.config;
    if(cfg?.showBy_api?.value=='true' && cfg?.showBy_static.value=='true') return;

    // this.filterService.showByAPI =  (cfg?.showBy_api?.value=='true')||false;
    // if(this.filterService.showByAPI) return;


    if(cfg?.showBy_static.value=='true'){
      this.showByData=cfg?.show_by;
      this.filterService.showByHeader=cfg?.showby_headername.value;

      // User Activity
      let userState: any = this.userActivityService.getData();
      const stateValue = userState['showBy'+ cfg?.showby_name]
      const defaultValue = cfg?.show_by[0].value
      if (userState && stateValue &&  defaultValue!=stateValue) {
        this.filterService.showByKey =  stateValue; 
        this.selectedValue = this.filterService.showByKey 
      } else {
        this.selectedValue = defaultValue
        this.filterService.showByKey = this.selectedValue;
        this.userActivityService.setData({ ['showBy'+cfg?.showby_name]: this.selectedValue });
      }
    }
    // User Activity
    // let userState: any = this.userActivityService.getData();
    // if (userState) {
    //   if (userState.showBy) {
    //     this.selectedValueByUserActivity = userState.showBy == undefined ? this.selectedValue : userState.showBy;
    //   }
    // }

    // this.showByData = this.item?.config?.show_by ? this.item.config.show_by : this.showByData;

    // let findData = this.showByData.find(
    //   (fnd: any) => fnd.value == this.selectedValueByUserActivity
    // );

    // if (findData) {
    //   this.selectedValue = findData.value;
    // } else {
    //   this.selectedValue = this.showByData[0].value;
    // }
  }

  showByChange(value: any) {
    let cfg = this.item.config;

    if(cfg?.showBy_static.value=='true'){
      
      
      this.userActivityService.setData({['showBy'+cfg?.showby_name]: value });
      this.filterService.showByKey = value;

      if(cfg?.showBy_api?.value=='true' && cfg?.showBy_static.value=='true') {
        let showByData = _.map(this.showByData, val => ({ir_calc_mode: val['label'],report_typ: val['value'],ir_calc_default_flg:val['default']}));
        let fndSelectedValue = showByData.find((f:any)=> f.report_typ == value);
        let ab = { 'report_typ': fndSelectedValue }
        this.userActivityService.setData({ "selectedFilters": ab });
        this.filterService.showByChangeFilter.next(fndSelectedValue);
      } else {
        this.newFilterService.showBy.next(value);
      }

      return
    }

    let fndData = this.showByData.find((fnd:any)=> fnd['brand_uom_xref_key'] == value)

    if(fndData) {
      let selectedDesc = fndData['uom_desc'].toLowerCase();
      this.newFilterService.showBy.next(selectedDesc);
      this.userActivityService.setData({ showBy: fndData });
      this.filterService.showByKey = this.selectedValue;
      this.filterService.setFilterValue(this.filterService.previousModel, this.queryParams);
    }
    // debugger
  }

  // dynamic api for the Showby options
  loadshowByApiData(res:any){
    //ir showby
    // debugger
    if(res?.['opt'].length==0) return;
    
    this.showByData = _.map(res.opt, val => ({
      label: val['ir_calc_mode'],  
      value: val['report_typ'],     
      default:val['ir_calc_default_flg']
    }));

    this.filterService.showByKey = res?.['select'].report_typ;
    this.selectedValue =  this.filterService.showByKey;
    if(this.item.config?.showby_name) {
      this.userActivityService.setData({ ['showBy'+this.item.config.showby_name]: this.selectedValue });
    }


    // let apidefaultValue=_.find(this.showByData,val =>val['default']=='Y' )['value']

    // if (userState && userState['showBy'+ this.item.config.showby_name] && apidefaultValue==userState['showBy'+ this.item.config.showby_name]) {
    //   this.filterService.showByKey =  userState['showBy'+ this.item.config.showby_name];
    //   this.selectedValue=this.filterService.showByKey 
    // }else{
    //   this.selectedValue=_.find(this.showByData,val =>val['default']=='Y' )['value']
    //   this.filterService.showByKey = this.selectedValue;
    //   this.userActivityService.setData({ ['showBy'+this.item.config.showby_name]: this.selectedValue });
    // }
  }
  
  ngOnChanges(changes?: SimpleChanges): void {
    // let userStatedata = this.userActivityService.getData();
    // if (userStatedata?.showBy) {
      // this.newFilterService.showBy.next(userStatedata.showBy);
    // }
  }

  loadApiData(query:any) {
    if(this.item.config?.showBy_static.value=='true')return
    // Api Payload
    let brand = {"brand_key": query['brand_key']}

    this.reqSubcription.push(this.filterService.executeQueryForShowBy(brand).subscribe((res: any) => {
       
      if(res!=null) {
        let selectedDesc:any;
        this.showByData = res ? res : [];

        let userState: any = this.userActivityService.getData();
        if (userState?.showBy) {
          this.selectedValue = userState.showBy['brand_uom_xref_key'];
          selectedDesc = userState.showBy['uom_desc'].toLowerCase();
        } else {
          this.selectedValue = this.showByData[0]['brand_uom_xref_key'];
          selectedDesc = this.showByData[0]['uom_desc'].toLowerCase();
        }

        this.filterService.showByKey = this.selectedValue;
        this.newFilterService.showBy.next(selectedDesc);
      }
    }, (err=> {
      console.log('ShoBy',err)
    })));
  }
  //to get tooltip Label
  tooltipForShowBy(data:any,value:any):string{
    let label:string = data.find((fnd: any) => fnd.value == value)?data.find((fnd: any) => fnd.value == value).label:undefined
     return this.initCap(label)
 
   }

  ngOnDestroy() {
    // this.item = {};
    this.filterService.showByAPI=false
    this.filterService.showByKey=undefined
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  } 
   initCap(value: string): string {
    if (!value) return value;
    if (value==value.toUpperCase()) return value;
    return value.replace(/\w\S*/g, (txt:any) =>
      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  calculateDynamicWidth(): string {
    const tooltipText:string = this.tooltipForShowBy(this.showByData, this.selectedValue);
    const dynamicWidth = tooltipText ? tooltipText.length * 10 + 50 :this.matSelectWidth; // Adjust the multiplier as needed
    this.matSelectWidth = Math.max(dynamicWidth, 160); // Set a minimum width
    return `${this.matSelectWidth}px`;
  } 

}
