import { Injectable } from "@angular/core";
import moment from "moment";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";

@Injectable({
  providedIn: "root",
})
export class AlertSubscriptionService {
  
  previousSingleSelectedItems: any = [];
  previousMultiSelectedItems: any = [];
  
  selectedAllFilters: any = {};
  selectedFilters: any = {};

  kpiRightLists:any = [];
  kpiLeftLists:any = [];

  constructor(
    private apiService: ApiService,
    private filterService: FilterService
  ) {}

  executeQuery(obj: any) {
    let query = Object.assign({}, this.filterService.baseQuery, obj)
    query["report_typ"] = 'D';
    return this.apiService.post("kpi/get-execution-details", query);
  }

  getSavedSubscription(obj:any){
    let query = Object.assign({}, this.filterService.baseQuery, obj)
    return this.apiService.post("kpi/kpi-subscription-get", query);
  }

  loadApiData(method: string, url: string, key: string, data: any) {
    if (method.toUpperCase() == "POST") {
      let request: any = {};

      let keys = key.split(",");

      for (let k of keys) {
        let keyName = k;

        if (data[keyName] instanceof Array) {
          request[keyName] = data[keyName] ? this.getFilter(data[keyName], keyName) : "";
        } else {
          request[keyName] = data[keyName] ? data[keyName][keyName] : "";
        }
      }

      request = Object.assign({}, request, this.filterService.baseQuery) 

      return this.apiService.post(url, request);
    } else {
      return this.apiService.get(url);
    }
  }

  setFilterValue(values: any) {
    // debugger
    // auto query stored based on formly form data
    const query: any = {};
    for (let [key, value] of Object.entries(values)) {
      if (value instanceof String) {
        query[key] = value;
      } else if (value instanceof Date) {
        query[key] = moment(value).format("yyyy-MM-DD");
      } else if (value instanceof Object) {
        const v: any = value;
        if (v[key] == undefined) {
          let a: string = v?.map((a: any) => a[key]);
          query[key] = a.toString();
        } else {
          query[key] = v[key];
        }
      } else if (value instanceof Array) {
        query[key] = this.getFilter(value, key);
      }
    }

    return query;
    console.log('HHH',query)
    // Main
    // this.baseQuery = query;
    // this.filterQuery.next(this.baseQuery);
  }

  getFilter(data: any[], paramName: any) {
    if (data.length == 0) return null;
    let filter = "";
    data.map((e) => {
      filter += e[paramName] + ",";
    });
    return filter.slice(0, filter.lastIndexOf(","));
  }

  allOnLoadDataForFields:any = {};

  getFilterFieldsData(method: string, url: string, parameter: string, key:any) {
    // debugger

    if (method.toUpperCase() == "POST") {
      let request: any = {};

      let keys = parameter.split(",");

      for (let k of keys) {
        let keyName = k;

        if(keyName == 'brand_key') {
          request[keyName] = this.filterService.baseQuery[keyName];
        } else if (keyName=='source_nm') {
          request[keyName] = null;
          // request[keyName] = this.allOnLoadDataForFields['source_key'] ? this.getFilter(this.allOnLoadDataForFields['source_key'], keyName) : "";
        } else if (keyName=='patient_status') {
          request[keyName] = null;
        } else {
          request[keyName] = null;
          // if(this.allOnLoadDataForFields[keyName] instanceof Array) {
          //   request[keyName] = this.allOnLoadDataForFields[keyName] ? this.getFilter(this.allOnLoadDataForFields[keyName], keyName) : "";
          // } else {
          //   request[keyName] = this.allOnLoadDataForFields[keyName] ? this.allOnLoadDataForFields[keyName][keyName] : "";
          // }
        }
      }

      request = Object.assign({}, request);

      return this.apiService.post(url, request);
    } else {
      return this.apiService.get(url);
    }

  }

  getFilterFieldsLocalData(url: string) {
    return this.apiService.getDoc(url);
  }

  generateCombinations(data:any) {
    const keys:any = Object.keys(data);
    const values:any = Object.values(data);
  
    let combinations = [{}];
  
    keys.forEach((key:any, index:any) => {
      const currentValues = values[index].split(',');
  
      const newCombinations:any = [];
      combinations.forEach((combination) => {
        currentValues.forEach((value:any) => {
          const newCombination = { ...combination, [key]: value };
          newCombinations.push(newCombination);
        });
      });
      combinations = newCombinations;
    });
    return combinations;
  }

  revertCombinations(combinations:any) {
    const revertedData:any = {};
  
    combinations.forEach((combination:any) => {
      for (const key in combination) {
        if (revertedData.hasOwnProperty(key)) {
          if (!revertedData[key].includes(combination[key])) {
            revertedData[key].push(combination[key]);
          }
        } else {
          revertedData[key] = [combination[key]];
        }
      }
    });
  
    for (const key in revertedData) {
      revertedData[key] = revertedData[key].join(',');
    }
  
    return revertedData;
  }
}
