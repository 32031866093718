<div *ngIf="loading">
    <label class="filterLabelStyle" style="margin-bottom: 5px;">{{label||"NO label"}}</label>
    <span *ngIf="validationForField" style="padding: 4px;color: #d11044;">*</span>
    <ng-select 
        #sc appearance="outline" 
        [class.ng-select-validation]="validationForField"
        class="ngSelectionAlertTree" 
        style="font-family: 'Poppins'" 
        [multiple]="true"
        [clearable]="false" 
        [placeholder]="placeholder" 
        [selectableGroup]="true" 
        [closeOnSelect]="false" 
        appendTo="body"
        [virtualScroll]="true" 
        [searchable]="false" 
        [items]="this.option" 
        [bindLabel]="this.labelProp"
        [items]="option" 
        [(ngModel)]="selectedOption"
        (close)="onClose()">

        <ng-template ng-header-tmp>

            <input class="searchFiler" type="text" [(ngModel)]="searchString" (input)="filter($event)" placeholder="Search" />
              
            <br>
            
            <mat-checkbox
                style="margin-left: 10px;"
                [checked]="isAllSelected()"
                (change)="selectAll($event.checked)">
                Select All
            </mat-checkbox>
            <hr>

            <div class="ngSelectionAlertTree-du">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree-du">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <li class="mat-tree-node">
                            <mat-checkbox [checked]="!!node.checked" [indeterminate]="node.indeterminate"
                                (change)="toggleSelection(node)">
                                {{node.name}}
                            </mat-checkbox>
                        </li>
                    </mat-tree-node>

                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <mat-checkbox [checked]="!!node.checked" [indeterminate]="node.indeterminate"
                                    (change)="toggleSelection(node)">
                                    {{node.name}}
                                </mat-checkbox>
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </div>

        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" let-index="index">
            <div class="ng-value"
                *ngFor="let item of selectedOption | slice: 0: this.key == 'sp_location_key' ? 1 : itemsShowLimit">
                <span class="ng-value-label">{{ item[this.labelProp]}}</span>
            </div>
            <div class="ng-value" *ngIf="selectedOption.length > (this.key == 'sp_location_key' ? 1 : itemsShowLimit)">
                <span class="ng-value-label">{{ '+' + (selectedOption.length - (this.key == 'sp_location_key' ? 1 :
                    (this.key == 'sp_location_key' ? 1 : itemsShowLimit))) }}</span>
            </div>
        </ng-template>


    </ng-select>
    <div *ngIf="validationForField">
        <span class="my-2 du-req-text"> {{this.label + ' is required' }} </span>
    </div>
</div>