<div style="width:100%;">
    <main>
        <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
            <div class="row" style="background-color: #fff;">
                <icon-chart 
                        [headerConfig]="headerConfig" 
                        [iconList]="iconList" 
                        [data]="data" 
                        [pageKey]="pageKey" 
                        [heading]="heading"
                        [chartName]="divId" 
                        [config]="bubbleChartContainer" 
                        [expand]="fs" 
                        [fullscreen]="isFullscreen"
                        [item]="item"
                    ></icon-chart>
            </div>
            <div><loading *ngIf="isLoading"> </loading></div> 
            <div class="row">
                <div class="col-12">
                    <div id="bubbleMapDiv" style="font-family: Poppins !important;" #bubbleChartContainer></div>
                </div>
            </div>
            <div id="d3BarTooltip">
                <div *ngIf="renderLinePopup" class="square">
                    <div style="margin: auto;margin-right: 1px; ">
                        <div class="iconStyle">
                            <mat-icon svgIcon="cross" class="icon" (click)="close()"></mat-icon>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="renderLinePopup" class="error-label-arrow"></div> -->
                <div class="popupcontainer " *ngIf="renderLinePopup">
                    <div style="width:100%;">
                        <div style="display: flex;">
                            <div style="padding: 5px;">
                                <h2 style="margin:0 !important; font-size: 16px;">{{"Trending - " + sourceName}}</h2>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 10px;">
                        <app-bubblemap-line-chart *ngIf="renderLinePopup" [mapType]="'oldMap'" [item]="item" [sourceName]="sourceName"
                            [data]="data" [locationName]="locationName"
                            [lineColour]="lineColour" [showBy]="showBy"></app-bubblemap-line-chart>
                    </div>
                </div>
            </div>
            <div id="d3BubbleTooltip">
                <app-general-tooltip-popup [data]="bubbleTooltipData"
                    *ngIf="showBubblePopup"></app-general-tooltip-popup>
            </div>
        </article>
    </main>
</div>