import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-kpi-heat-map-card",
  templateUrl: "./kpi-heat-map-card.component.html",
  styleUrls: ["./kpi-heat-map-card.component.scss"],
})
export class KpiHeatMapCardComponent implements OnInit {
  static key = 100703;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("KPIHeatMapCharDiv", { static: true })
  KPIHeatMapCharDiv!: ElementRef;
  config: any;
  data: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  constructor(private filterService: FilterService) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
  }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.KPIHeatMapCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
  }

  loadApiData(reportType?: string) {
    let api = this.item.config["list_of_api"] || {};
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {

      // API Payloads
      let obj:any = {"api_key": '100203',"report_typ" : 'D', "time_cycle" : this.filterService.report_type}

      this.reqSubcription.push(
        this.filterService.kpiBreakdownQuery(obj).subscribe(
          (res: any) => {
            this.isDataLoaded = true;
            this.data = res == null ? [] : res;
          },
          (error: any) => {
            this.data = [];
          }
        )
      );
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
