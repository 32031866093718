import { Component, OnInit } from '@angular/core';
import * as d3 from "d3";
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {
  PJRadarData: any;
  props: any;
  divId: any = "PJRadarChartDiv";
  constructor(private dataService: DataService) { }


  ngOnInit(): void {
    this.dataService.radarData.subscribe((resp: any) => {
      this.PJRadarData = resp
      if (this.PJRadarData) {
        this.initiateCharts();
        this.getPJRadarChartData();
      }
    })
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("class", "axisGroup" + myClass);
    const radarGroup = mySvg.append("g").attr("class", "radarGroup" + myClass);
    radarGroup.append("path").attr("class", "radarPath" + myClass);
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    const outerMargin = 60;

    mySvg.attr("width", width)
      .attr("height", height);

    myChart.PJRadarData.map((m: any) => m[myChart.props.valueVar] = isNaN(m[myChart.props.valueVar]) ? 0 : m[myChart.props.valueVar])
    let radarGroup: any = d3.rollup(myChart.PJRadarData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.valueVar]), (d: any) => d[myChart.props.radarVar])
    radarGroup = Array.from(radarGroup);
    radarGroup = radarGroup.map((m: any) => m = { name: m[0], value: m[1] });
    const radarRadius = Math.min(width - outerMargin, height - outerMargin) / 2;
    const totalGroups = radarGroup.length;
    const maxValue: any = d3.max(radarGroup, (d: any) => d.value);
    const angleSlice = Math.PI * 2 / totalGroups;

    const rScale = d3.scaleLinear().domain([0, maxValue]).range([0, radarRadius]);
    let ticks = rScale.ticks();

    if (ticks.length > 1) {
      const finalTick = ticks[ticks.length - 1];
      if (finalTick !== maxValue) {
        let tickGap = ticks[1] - 0;
        ticks.push(finalTick + tickGap)
      }
    }
    const maxTick: any = d3.max(ticks);
    let axisData: any = [];

    ticks.forEach((t: any) => {
      let myAxisData = [];
      for (let g: any = 0; g < totalGroups; g++) {
        myAxisData.push(t)
      }
      axisData.push(myAxisData);
    })

    const radarLine = d3.lineRadial()
      .curve(d3['curveLinearClosed'])
      .radius((d: any) => rScale(d))
      .angle((d, i) => i * angleSlice)

    const axisGrid = mySvg.select(".axisGroup" + myClass)
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');

    mySvg.select(".radarGroup" + myClass)
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');

    const axisGroup = axisGrid
      .selectAll('.axisGroup' + myClass)
      .data(axisData)
      .join(function (group: any): any {
        const enter = group.append('g').attr('class', 'axisGroup' + myClass);
        enter.append('path').attr('class', 'axisPath');
        enter.append('text').attr('class', 'axisLabel');
        return enter;
      });

    axisGroup.select('.axisLabel')
      .attr('font-family', 'DM Sans')
      .attr('fill', '#000000')
      .attr('font-size', '8px')
      .attr('font-weight', '500')
      .attr("text-anchor", "middle")
      .attr("y", (d: any) => -rScale(d[0]) + 4)
      .text((d: any, i: any) => i > 0 && i < (axisData.length - 1) ? d[0] : "")

    axisGroup.select('.axisPath')
      .attr("fill", "transparent")
      .attr("stroke", "#D9D9D9")
      .attr("d", (d: any) => radarLine(d))
      .attr("stroke-width", 1);

    const axisLineGroup = axisGrid
      .selectAll('.axisLineGroup' + myClass)
      .data(axisData)
      .join(function (group: any): any {
        const enter = group.append('g').attr('class', 'axisLineGroup' + myClass);
        enter.append('line').attr('class', 'axisLine');
        enter.append('circle').attr('class', 'axisDot');
        enter.append('text').attr('class', 'axisLabel');
        return enter;
      });

    axisLineGroup.select('.axisDot')
      .attr("cx", (d: any, i: any) => rScale(maxTick) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("cy", (d: any, i: any) => rScale(maxTick) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr("r", 5)
      .attr("stroke", "#8A98AB")
      .attr("fill", "#D0D7E1")
      .attr("stroke-width", 2);

    axisLineGroup.select('.axisLine')
      .attr("x1", (d: any, i: any) => rScale(0) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("y1", (d: any, i: any) => rScale(0) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr("x2", (d: any, i: any) => rScale(maxTick) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("y2", (d: any, i: any) => rScale(maxTick) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr("stroke", "#D9D9D9")
      .attr("stroke-width", 1);

    radarGroup.map((m: any, i: any) => m.x = rScale(maxTick) * Math.cos(angleSlice * i - Math.PI / 2));

    const radarGroupGroup = mySvg.select(".radarGroup" + myClass)
      .selectAll('.radarGroupItems' + myClass)
      .data(radarGroup)
      .join(function (group: any): any {
        const enter = group.append('g').attr('class', 'radarGroupItems' + myClass);
        enter.append('text').attr('class', 'axisLabel');
        enter.append('circle').attr('class', 'radarDot');
        return enter;
      });

    const marginLeftRight = (width / 2) - radarRadius - outerMargin;

    radarGroupGroup.select('.axisLabel')
      .attr("transform", (d: any, i: any) => "translate(" + (d.x + (d.x < -15 ? - 15 : (d.x < 15 ? 0 : 15)))
        + "," + (rScale(maxTick) * Math.sin(angleSlice * i - Math.PI / 2) + 2.5 + (d.x < -15 ? 0 : (d.x < 15 ? -15 : 0))) + ")")
      .attr('fill', '#101D42')
      .attr('font-size', '10px')
      .attr('font-weight', '400')
      .attr("text-anchor", (d: any) => d.x < -15 ? "end" : (d.x < 15 ? "middle" : "start"))
      .attr("dy", 0)
      .text((d: any) => d.name)
      .each(function (d: any) {
        if (d.x < -15 || d.x > 15) {
          //@ts-ignore
          wrap(d3.select(this), marginLeftRight - 20)
        }
      })

    radarGroupGroup.select('.radarDot')
      .attr("cx", (d: any, i: any) => rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("cy", (d: any, i: any) => rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr("r", 5)
      .attr("fill", myChart.props.chartColor)
      .attr("stroke", "white")
      .attr("stroke-width", 1);

    mySvg.select(".radarPath" + myClass)
      .attr("d", radarLine(radarGroup.map((v: any) => v.value)))
      .attr("fill", myChart.props.chartColor)
      .attr("fill-opacity", 0.2)
      .attr("stroke", myChart.props.chartColor);



    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function (): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 12;
        const dy = 0;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0) {
              lineNumber += 1;
              tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

            }
          }
        }
      });
    }

  }
// property & data for the chart
  getPJRadarChartData() {
    this.dataService.getJson('PJ-Radar').subscribe((res: any) => {
      // this.pJRadarService.getPJRadarChartData().subscribe((res:any)=>{
      // this.PJRadarData=res;
      this.props = {
        chartHeight: 300,
        chartColor: '#8C23E6',
        radarVar: 'program_nm',
        valueVar: 'no_of_days'
      }
      // @ts-ignore
      if (this.PJRadarData !== undefined || this.PJRadarData.length > 0) {
        this.plotChart();
      }
    });
  }

}
