import { ViewportScroller } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';;
import { Subscription } from 'rxjs';
import { ScrollableItemDirective } from 'src/app/directives/scrollable-item.directive';
import { DialogService } from 'src/app/services/dialog.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { PopupStatusComponent } from '../popup-status/popup-status.component';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { HoverOverClickComponent } from '../hover-over-click/hover-over-click.component';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-patient-value-getter',
  templateUrl: './patient-value-getter.component.html',
  styleUrls: ['./patient-value-getter.component.scss']
})
export class PatientValueGetterComponent implements ICellRendererAngularComp, OnDestroy {
  params!: ICellRendererParams;
  data:any
  reqSubcription: Subscription[] = []
  api_key:any

  constructor(
    private dialog: MatDialog,
    public filterService: FilterService,
    private newFilterService: NewFilterService,
    private deviceDetectionService: DeviceDetectionService,
    private constantsService: ConstantsService
  ) {}

  agInit(params: ICellRendererParams<any>): void {
    this.data = params
    this.params = params.value
  }

  refresh(): boolean {
    return false;
  }

  statusChanges() {
    if(this.data.selectedStyles) {
      let actionData = this.data.selectedStyles;
      if(actionData.iconsClickEvent) {

        this.api_key = actionData.iconsClickEvent.iconApi
        let report = "D"
        let patient_status = null

        let resData = {
          report_typ : report,
          api_key : this.api_key,
          patient_status : patient_status,
          patient_case_status : this.data.data.case_status,
          patient_reason_status : this.data.data.status_reason,
          patient_id : this.data.data.patient_id
        }
        this.newFilterService.sendData(resData);
        this.filterService.patientStatusCondition = false
        this.dialog.open(PopupStatusComponent, {disableClose: true,
          width:'400px',
          height: '75'
        });
      }
    }
  }
  
  popupDataLoad(event:any) {
    event.stopPropagation();
    if(!this.deviceDetectionService.isDesktop) {
      this.filterService.getDataPatientJourneyDataHold.next(this.data)
    }
  }

  noDataLoad() {

  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

  onLongPress(): void {
    if(this.data.column?.colId == 'current_status') {
      this.filterService.getDataPatientJourneyDataHold.next(this.data)
    }
  }
}