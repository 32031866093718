<div class="widget-container" #paymentRecordDetail>
    <loading *ngIf="loader">
    </loading>
    <main >
        <article #fs fullscreen-able [class.is-active]="isActive" [style.padding]="isActive ? '15px' : '0px'" style="background-color: #fff;">
            <!-- header -->
            <div class="mb-1" style="padding: 0 0 10px;">
                <icon-chart 
                    [headerConfig]="item.config?.widget_header_configuration"
                    [rowDataLength]="this.rowData" 
                    [data]="this.rowData" 
                    [pageKey]="pageKey" 
                    [heading]="heading"
                    [chartName]="'GridListdetails'" 
                    [config]="gridList" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                    (typeToSearch)="onQuickFilterChanged($event)"
                    (screenChanges)="screenChanges($event)"
                    (exportXls)="exportXls($event)"
                ></icon-chart>
            </div>
            
            <!-- body -->
            <div style="height: 500px; width: auto; " *ngIf="noData">
              <span class="bg-text">There is no data for this time period</span>
            </div>
            <div [style.display]="noData?'none':''">
                <ag-grid-angular
                    style="width: 100%; height: 100%;margin: 0 0 10px;"
                    class="ag-theme-alpine recordErrorPayment"
                    [suppressDragLeaveHidesColumns]="true"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [tooltipHideDelay]="tooltipHideDelay"
                    [animateRows]="true"
                    [suppressLoadingOverlay]="true"
                    [rowHeight]="50"
                    [paginationPageSize]="10"
                    [pagination]="true"
                    [masterDetail]="true"
                    [suppressAggFuncInHeader]="true"
                    [detailRowHeight]="detailRowHeight"
                    [detailRowAutoHeight]="true"
                    [detailCellRendererParams]="detailCellRendererParams"
                    [rowData]="rowData"
                    [gridOptions]="gridOptions"
                    (gridReady)="onGridReady($event)"
                    ></ag-grid-angular> 
                    <!-- [overlayNoRowsTemplate]="overlayNoRowsTemplate" -->
            </div>

        </article>
    </main>
</div>