<div class="widget-container" #dataGridDiv>
  <main>
    <article #fs fullscreen-able [class.is-active]="isFullscreen" style="background-color: white;">
  
      <div class="header">
  <div>
    <icon-chart 
      [headerConfig]="item.config?.widget_header_configuration"
      [rowDataLength]="data" 
      [data]="data" 
      [chartName]="'DataGrid'" 
      [config]="datagridList"
      [item]="item"
      [expand]="fs" [fullscreen]="isFullscreen"
      (expendCol)="expendCol($event)"
      (screenChanges)="fullscreenChanges($event)"
    ></icon-chart>
  </div>
  <div  *ngIf="loader" style="width: 100%;height:200px"><loading></loading></div>
  <!-- <div style="display:flex;flex-direction:row">
    <span style="flex:1;"><h2 style="font-weight: 600;font-family: 'Poppins';">{{heading}}</h2></span>
    <span>
      <mat-icon [svgIcon]="expandCollapseIcon" (click)="expandCollapse()" [matTooltip]="expandOrCollapse" matTooltipClass="expand-tooltip" class="matExpandCollapseBtn"></mat-icon>
    </span>
  </div> -->
  <div #datagridList >
    <!-- [ngStyle]="{'height': isFullscreen ? window.innerHeight + 'px' : 'auto','overflow':'auto'}" -->
    <div style="height: 200px; width: auto; " *ngIf="noData">
      <span class="bg-texts">There is no data for this time period</span>
    </div>
    <ag-grid-angular
        style="width:100%; padding-bottom: 10px;font-family: 'Poppins';"
        [ngClass]="{'ag-theme-balham':true, 'DataGrid':isFullscreen}"
        [suppressMovableColumns]="true"
        [rowStyle]="rowStyle"
        [rowHeight]="rowHeight"
        [suppressCellFocus]="true"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [suppressAggFuncInHeader]="true"
        [groupIncludeFooter]="false"
        [groupIncludeTotalFooter]="false"
        [animateRows]="true"
        [rowData]="data"
        (gridReady)="onGridReady($event)"
        *ngIf="columnDefs.length>0 && isDataLoaded==true && data.length>0"
        >
      </ag-grid-angular>
  </div>
</div>
</article>
</main>
</div>