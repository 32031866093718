<div class="widget-container" #nestedwidget>
  <main>
    <article #fs fullscreen-able [ngClass]="{ 'is-active': isActive, 'fullscreen-padding': isActive}"
      style="background-color: white; overflow: auto;">
      <!-- <loading *ngIf="isLoading"> </loading> -->
      
      <div style="margin: 15px;height: 12px;">
        <icon-chart [headerConfig]="item.config?.widget_header_config" [pageKey]="pageKey"
          [heading]="item.config['report_heading']" [chartName]="'IRSummery'" [config]="heatMapContainer" [expand]="fs"
          [fullscreen]="isFullscreen" [item]="item" [data]="data"
          (screenChanges)="fullscreenChanges($event)"></icon-chart>
      </div>
      
      <div [style.display]="noData?'none':''" #heatMapContainer></div>

      <div *ngIf="item">
        
        <app-small-bar-widget-chart *ngIf="items['key'] == '100606'" style="height: 100%; width: 100%" [item]="items"
          [dynamic]="true" [pageKey]="pageKey">
        </app-small-bar-widget-chart>
        
        <app-pie-chart-card *ngIf="items['key'] == '100708'"
          style="height: 100%; width: 100% " [item]="items" [pageKey]="pageKey" [dynamic]="true">
        </app-pie-chart-card>

        <!-- <app-time-to-therapy
          *ngIf="items['key'] == '100708'  && this.items['config']['chart_type'].name=='DonutpieChart'"
          style="height: 100%; width: 100%" [item]="items" [pageKey]="pageKey" [dynamic]="true">
        </app-time-to-therapy> -->

        <app-h-bar-chart *ngIf="items['key'] == '100701'" [height]="height" style="height: 100%; width: 100%"
          [item]="items" [dynamic]="true" [pageKey]="pageKey">
        </app-h-bar-chart>

      </div>

    </article>
  </main>
</div>