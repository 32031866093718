<div class="pdf_export_block" style="height:540px;overflow: hidden;border-radius: 10px;">
    <div class="header">
        <div class="d-flex align-items-center justify-content-between p-2">
            <span class="expoPdfname pl-2">Export PDF</span>
            <mat-icon class="mr-3" style="width: 20px;height: 20px;" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-4 scrollcss card card-body shadow-lg custom_border" style="padding-left: 2rem;padding-top: 1rem;height:500px;overflow: auto;">
            <div class="row">
                <div class="col-12 ">
                    <form (ngSubmit)="submitFormHandler($event)">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="labelPDF">
                                    Grid Layout
                                </div>
                                <div class="">
                                    <mat-button-toggle-group class="toggleButton" (change)="toggleOrientation($event)"
                                        name="pageOrientation" aria-label="page_orientation"
                                        [value]="PDF_PAGE_ORITENTATION">
                                        <mat-button-toggle name="orientation" id="landscape"
                                            value="landscape">Landscape</mat-button-toggle>
                                        <mat-button-toggle name="orientation" id="portrait"
                                            value="portrait">Portrait</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>

                        <!-- <div class="row mt-2">
                            <div class="col-12 d-flex">
                                <div class="col-6 text-start labelPDF">Header Height</div>
                                <div class="col-6 text-start labelPDF">Cell Height</div>
                            </div>
                            <div class="col-12 d-flex">
                                <div class="col-6 text-start">
                                    <input class="cellProp" type="number" id="headerRowHeight"
                                        [value]="PDF_HEADER_HEIGHT"
                                        (change)="PDF_HEADER_HEIGHT = parseInt(Value($event))" />
                                </div>
                                <div class="col-6 text-start">
                                    <input class="cellProp" type="number" id="cellRowHeight" [value]="PDF_ROW_HEIGHT"
                                        (change)="PDF_ROW_HEIGHT = parseInt(Value($event))" />
                                </div>
                            </div>
                        </div> -->

                        <div class="row mt-2">
                            <div class="col-12 d-flex">
                                <div class="col-4 ">
                                    <input class="form-check-input" type="checkbox" id="exportWithFormatting"
                                        [checked]="PDF_HEADER_NAME" (change)="PDF_HEADER_NAME = Checked($event)" />
                                    <label class="labelPDF ml-1" for="exportWithFormatting">
                                        Brand Name
                                    </label>
                                </div>
                                <div class="col-4 ">
                                    <input class="form-check-input" type="checkbox" id="exportWithFormatting"
                                        [checked]="PDF_HEADER_LOGO" (change)="PDF_HEADER_LOGO = Checked($event)" />
                                    <label class="labelPDF ml-1" for="exportWithFormatting">
                                        Header Logo
                                    </label>
                                </div>
                                <div class="col-4 ">
                                    <input class="form-check-input" type="checkbox" id="exportColumnsAsLink"
                                        [checked]="PDF_FOOTER" (change)="PDF_FOOTER = Checked($event)" />
                                    <label class="labelPDF ml-1" for="exportColumnsAsLink">
                                        Footer (page count)
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="labelPDF mt-2">PDF Title</div>
                                <div class="d-flex">
                                    <input type="text" id="pdfHeading" name="pdfheading"
                                        style="width: 250px;border: 1px solid #E8EAEE;border-radius: 8px;height: 36px;"
                                        [value]="PDF_HEADING" [disabled]="editName" [(ngModel)]="PDF_HEADING"
                                        (change)="PDF_HEADING = (Value($event))" />
                                    <i class="fa fa-edit" (click)="editPdfTitle()"
                                        style="font-size: 20px;position: absolute;right: 16px;top:7px;color: red;"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="labelPDF">PDF Title Position</div>
                                <div class="">
                                    <input class="form-check-input" type="radio" name="alignment" id="left" value="left"
                                        [checked]="PDF_HEADING_ALIGNMENT === 'left'"
                                        (change)="PDF_HEADING_ALIGNMENT = AlignmentValue($event)" />
                                    <label class="form-check-label" for="Left">
                                        Left
                                    </label>
                                    <input class="form-check-input" type="radio" name="alignment" id="center"
                                        value="center" [checked]="PDF_HEADING_ALIGNMENT === 'center'"
                                        (change)="PDF_HEADING_ALIGNMENT = AlignmentValue($event)" />
                                    <label class="form-check-label" for="center">
                                        Center
                                    </label>
                                    <input class="form-check-input" type="radio" name="alignment" id="right"
                                        value="right" [checked]="PDF_HEADING_ALIGNMENT === 'right'"
                                        (change)="PDF_HEADING_ALIGNMENT = AlignmentValue($event)" />
                                    <label class="form-check-label" for="right">
                                        Right
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="labelPDF">PDF Title Color</div>
                                <div class="">
                                    <color-picker id="headingColor" [(ngModel)]="PDF_HEADINGCOLOR"
                                        (ngModelChange)="onPDFColorChange($event)" name="pdf-color"></color-picker>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="color-picker-container">
                                <color-picker [(ngModel)]="PDF_ODD_BKG_COLOR" (ngModelChange)="onOddColorChange($event)"
                                    name="odd-bkg-color"></color-picker>
                                <div>Odd row background color</div>
                            </div>
                            <div class="color-picker-container">
                                <color-picker [(ngModel)]="PDF_EVEN_BKG_COLOR" (ngModelChange)="onEvenColorChange($event)"
                                    name="even-bkg-color"></color-picker>
                                <div>Even row background color</div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="exportWithFormatting"
                                    [checked]="PDF_WITH_CELL_FORMATTING" (change)="PDF_WITH_CELL_FORMATTING = Checked($event)" />
                                <label class="form-check-label" for="exportWithFormatting">
                                    Cell styles
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="exportColumnsAsLink"
                                    [checked]="PDF_WITH_COLUMNS_AS_LINKS" (change)="PDF_WITH_COLUMNS_AS_LINKS = Checked($event)" />
                                <label class="form-check-label" for="exportColumnsAsLink">
                                    Hyperlinks
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="selectedRowsOnly" [checked]="PDF_SELECTED_ROWS_ONLY"
                                    (change)="PDF_SELECTED_ROWS_ONLY = Checked($event)" />
                                <label class="form-check-label" for="selectedRowsOnly">
                                    Selected rows only
                                </label>
                            </div>  -->

                        <div class="row mt-2 popup_tree">
                            <div class="col-12 labelPDF">
                                Columns
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 d-flex">
                                    <div class="col-6 ">
                                        <input class="form-check-input" type="radio" name="alignmentdata" id="Detail" value="Detail"
                                            [checked]="DETAIL_DATA=='Detail'"  (click)="dataChange('Detail')" (change)="DETAIL_DATA =  AlignmentValue($event)" />
                                        <label class="labelPDF ml-1" for="Detail">
                                            Detail 
                                        </label>
                                    </div>
                                    <div class="col-6 " *ngIf="groupData?.length>0 && columnDefs2.length==0">
                                        <input class="form-check-input" type="radio" name="alignmentdata" id="SUMMARY" value="SUMMARY"
                                            [checked]="DETAIL_DATA=='SUMMARY'" (click)="dataChange('SUMMARY')" (change)="DETAIL_DATA =  AlignmentValue($event)" />
                                        <label class="labelPDF ml-1" for="Detail">
                                          Summary
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 mt-1">
                                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                                        <button style="margin-bottom: 8px;" mat-icon-button disabled></button>
                                        <mat-checkbox class="checklist-leaf-node"
                                            [checked]="checklistSelection.isSelected(node)"
                                            (change)="todoLeafItemSelectionToggle(node)"
                                            color="primary" >{{node.item}}</mat-checkbox>
                                    </mat-tree-node>

                                    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent">
                                        <button style="margin-left: 0px !important;" mat-icon-button disabled></button>
                                    </mat-tree-node>

                                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild">
                                        <button style="margin-bottom: 8px;" mat-icon-button matTreeNodeToggle
                                            [attr.aria-label]="'Toggle ' + node.item">
                                            <mat-icon class="mat-icon-rtl-mirror">
                                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                        <mat-checkbox [checked]="descendantsAllSelected(node)"
                                            [indeterminate]="descendantsPartiallySelected(node)"
                                            (change)="todoItemSelectionToggle(node)"
                                            color="primary" style="color: '#101D42';font-weight: 600;">{{node.item}}</mat-checkbox>
                                    </mat-tree-node>
                                </mat-tree>

                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-around align-items-center">
                                <button [disabled]="this.checklistSelection.selected.length == 0" type="submit"
                                    (click)="getPreview()" class="btn btn-success">
                                    Preview
                                </button>
                                <button [disabled]="this.checklistSelection.selected.length == 0" type="submit"
                                    (click)="exportPDf()" class="btn btn-primary">
                                    Export
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </div>
        <div class="col-8" *ngIf="pdfPreviewData" style="background-color: #F4F5F9;">
            <iframe [src]="pdfPreviewData" width="100%" height="100%"></iframe>
        </div>
        <div class="col-8" *ngIf="!pdfPreviewData || checklistSelection.selected.length == 0" style="background-color: #F4F5F9;display: flex;
            justify-content: center;
            margin: auto;
            align-items: center;
            vertical-align: middle;height: 570px;
           ">Preview PDF here</div>
    </div>


</div>