import { Component, Input } from '@angular/core'
import {  Tooltip } from '@dataunveil/ducharts'

import { CellInfo } from './cell-info'

@Component({
  standalone: true,
  imports: [Tooltip, CellInfo],
  selector: 'app-status-tooltip-dem',
  template: `
    <duc-tooltip [config]="config">
      <ng-template #custom let-data>
        <app-status-cell-info-dem [header]='true' [data]="data"></app-status-cell-info-dem>
      </ng-template>
    </duc-tooltip>
  `,
  styles: []
})
export class TooltipDEM {
  @Input() config: any
}