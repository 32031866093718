

<div class="widget-container" #vennChartDiv >
  <!-- Headings -->
  <main >
      <article #fs fullscreen-able [class.is-active]="isActive" style="background: white;">

          <div class=" ">

              <icon-chart 
                  [headerConfig]="item.config?.widget_header_configuration"
                  [data]="this.vennData" 
                  [pageKey]="pageKey" 
                  [heading]="heading"
                  [chartName]="'vennChartDiv'" 
                  [config]="Vennchart" 
                  [expand]="fs" 
                  [fullscreen]="isFullscreen"
                  [item]="item"
                  (screenChanges)="screenChanges($event)"
              ></icon-chart>

          </div>

          <hr>
          <!-- Grid -->
          <br>
          <div><loading *ngIf="isLoading"> </loading></div> 
          <div style="height: 550px; width: auto; " *ngIf="noData">
           <span class="bg-text" >There is no data for this time period</span></div>
          <div class="main" #Vennchart  [style.display]="noData?'none':''" >
            <duc-legend [config]="$any(config.components[0])"></duc-legend>
            <duc-venn [config]="$any(config.components[1])" [data]="vennData">
              <duc-tooltip [config]="$any(config.components[2])">
                <ng-template #custom let-data>
                  <div></div>
                  <div *ngFor="let set of data.sets">{{ set}}</div>
                </ng-template>
              </duc-tooltip> </duc-venn>
          </div >
      </article>
  </main>
</div>

