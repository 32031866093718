<div class="song" [ngStyle]="{'width.px': data.column?.colId == 'current_status' ? data.column?.actualWidth - 10 : ''}">
    <div class="album-cover">
        <mat-icon *ngIf="data.selectedStyles?.icon && data.selectedStyles?.position == 'Left'" [svgIcon]="data.selectedStyles?.icon" [style.color]="data.selectedStyles?.color" style="margin-right:5px;cursor: pointer;" class="matIconsIn"></mat-icon>
        <mat-icon class="ms-auto" *ngIf="data.icon" (click)="data.column?.colId == 'current_status' ? popupDataLoad($event) : noDataLoad() " [svgIcon]="data.icon" [style.color]="data.color" style="margin-right:5px;" class="matIconsIn"></mat-icon>
        <!-- <mat-icon class="ms-auto" *ngIf="!data.icon"  style="padding: 3px;margin-right:5px;"></mat-icon> -->
    </div>
    <div class="song-name">
        <span style="align-self: center;" appLongPress (longPress)="onLongPress()">{{data.selectedStyles?.extraText.prefix ? data.selectedStyles?.extraText.prefix : ''}} {{ params ? (params.toString() | titlecase) : '' }} {{data.selectedStyles?.extraText.suffix ? data.selectedStyles?.extraText.suffix : ''}}</span>
    </div>
    <div class="song-action">
        <mat-icon (click)="statusChanges()" *ngIf="data.selectedStyles?.icon && data.selectedStyles?.position == 'Right'" [svgIcon]="data.selectedStyles?.icon" [style.color]="data.selectedStyles?.color" style="margin-right:5px;cursor: pointer;" class="matIconsIn"></mat-icon>
    </div>
</div>