export default {
  type: 'group',
  name: 'detail',
  encode: {
    update: {
      height: { signal: 'detailHeight' },
      width: { signal: 'width' },
      fill: { value: 'transparent' },
    }
  },

  signals: [{
    name: 'indexDate',
    push: 'outer',
    on: [{
      events: '@detail:pointermove',
      update: 'findNearest(func("d => _.uniqBy(d, _d => _d.toString())", pluck(data("series"), "date")), invert("x", clamp(x(), 0, width)))'
    }, {
      events: { signal: 'detailDomain' },
      update: 'null',
      force: true,
    }],
  }, {
    name: 'lineHighlight',
    on: [{
      events: 'line:pointerover',
      update: 'datum.source',
    }, {
      events: 'line:mouseout',
      update: 'null',
    }]
  }],

  scales: [{
    name: 'x',
    type: 'time',
    range: 'width',
    domain: { data: 'series', field: 'date' },
    domainRaw: { signal: 'detailDomain' },
  }, {
    name: 'y',
    type: 'linear',
    range: { signal: '[detailHeight - 2, 2]' },
    nice: true,
    zero: true,
    domain: { data: 'series', field: 'value' },
  }],

  axes: [{
    orient: 'bottom',
    scale: 'x',
    domainColor: '#E8EAEE',
    tickCount: 'month',
    tickColor: '#E8EAEE',
    labelFont: 'Poppins',
    labelFontSize: 12,
    labelAngle: -45,
    labelAlign: 'right',
    labelBaseline: 'middle',
    labelColor: '#101D42',
    labelPadding: 8,
    format: '%d %b %y',
    encode: {
      ticks: {
        update: {
          y: { value: -2 },
        }
      },
      labels: {
        update: {
          stroke: { value: 'black' },
          strokeWidth: { signal: '+indexDate == +datum.value ? 1 : 0' },
          // custom date format
          // text: { 
          //   signal: "timeFormat(datetime(year(datum.value), month(datum.value), date(datum.value) + (5 - day(datum.value) + 7) % 7), ' %d %b %y')"
          // },
        },
      },
      domain: {
        update: {
          y: { value: -2 },
        }
      },
    }
  }, {
    orient: 'left',
    scale: 'y',
    domainColor: '#E8EAEE',
    ticks: false,
    tickCount: 4,
    grid: true,
    gridColor: '#E8EAEE',
    labelFont: 'Poppins',
    labelColor: '#101D42',
    labelFontSize: 12,
    labelSeparation: 16,
    labelPadding: 16,
    format: ',.0f',
  }],

  marks: [{
    type: 'group',
    encode: {
      enter: {
        clip: { value: true },
      },
      update: {
        width: { signal: 'width' },
        height: { signal: 'detailHeight + 4' },
      }
    },
    marks: [{
      type: 'area',
      from: { data: 'series' },
      interactive: false,
      encode: {
        update: {
          x: { scale: 'x', field: 'date' },
          y: { scale: 'y', field: 'value' },
          y2: { scale: 'y', value: 0 },
          opacity: { value: 0.5 },
          fill: {
            value: {
              x1: 1,
              y1: 1,
              x2: 1,
              y2: 0,
              gradient: 'linear',
              stops: [
                { offset: 0, color: 'white' },
                { offset: 1, color: 'blue' },
              ]
            }
          },
          interpolate: { value: 'monotone' },
        },
      },
    }, {
      type: 'line',
      from: { data: 'series' },
      encode: {
        update: {
          x: { scale: 'x', field: 'date' },
          y: { scale: 'y', field: 'value' },
          strokeWidth: { value: 4 },
          interpolate: { value: 'monotone' },
          stroke: { signal: 'color' },
        },
      },
    }]
  }, {
    type: 'group',
    name: 'horizontalCrosshair',
    encode: {
      opacity: { signal: 'indexDate ? 1 : 0' },
    },
    marks: [{
    type: 'rule',
    from: { data: 'tooltip' },
    interactive: false,
    encode: {
      enter: {
        x: { value: 0 },
        stroke: { value: '#000' },
        strokeWidth: { value: 1 },
        strokeDash: { value: [2, 2] },
      },
      update: {
        x2: { signal: 'width' },
        y: { scale: 'y', field: 'value' },
      }
    }
  }, {
    type: 'rect',
    from: { data: 'tooltip' },
    encode: {
      enter: {
        x: { value: -60 },
        width: { value: 45 },
        height: { value: 16 },
      },
      update: {
        y: { signal: 'scale("y", datum.value) - 8' },
        fill: { value: 'white' },
      }
    }
  }, {
      type: 'text',
      from: { data: 'tooltip' },
      encode: {
        update: {
          x: { value: -15 },
          y: { scale: 'y', field: 'value' },
          align: { value: 'right' },
          baseline: { value: 'middle' },
          text: { signal: 'format(datum.value, ",")' },
          font: { value: 'Poppins' },
          fill: { value: 'black' },
          stroke: { value: 'black' },
          fontSize: { value: 12 },
        }
      }
    }]
  }, {
    type: 'group',
    name: 'verticalCrosshair',
    encode: {
      opacity: { signal: 'indexDate ? 1 : 0' },
    },
    marks: [{
      type: 'symbol',
      from: { data: 'tooltip' },
      interactive: false,
      encode: {
        update: {
          x: { scale: 'x', field: 'date' },
          y: { scale: 'y', field: 'value' },
          fill: { signal: 'color' },
          stroke: { value: 'white' },
        }
      },
      zindex: 1,
    }, {
      type: 'rule',
      interactive: false,
      encode: {
        enter: {
          y: { value: 0 },
          stroke: { value: '#000' },
          strokeWidth: { value: 1 },
          strokeDash: { value: [2, 2] },
        },
        update: {
          x: { scale: 'x', signal: 'indexDate' },
          y2: { signal: 'detailHeight' },
          strokeOpacity: { signal: 'indexDate ? 1 : 0'},
        }
      }
    }]
  }]
}