<!-- <div class="showByContainer align-items-center w-auto" style="margin: 5px auto;text-align: center;">
  <div class="sort_by_styles d-flex">
    <label for="sortBy" class="sort_by_text mt-2">Show By</label>
    <mat-select class="sort_by_select text-center" style="width: 60% !important;" [matTooltip]="tooltipForShowBy(showByData,selectedValue)" [(ngModel)]="selectedValue"
      (ngModelChange)="showByChange($event)">
      <mat-option *ngFor="let showBy of showByData" [value]="item.config?.showBy_static ? showBy.value : showBy.brand_uom_xref_key"
        class="dropdown_values">{{ (item.config?.showBy_static ? showBy.label : showBy.uom_desc) | initcap }}</mat-option>
    </mat-select>
  </div>
</div> -->
<div style="min-height: 70px; height: 100%;">
  <div class="widget-container">
    <div class="d-flex align-items-center sort_by_select">
      <label for="sortBy" class="sort_by_text">Show By</label>
      <mat-select class="sort_by_select" [matTooltip]="tooltipForShowBy(showByData,selectedValue)" [(ngModel)]="selectedValue" (ngModelChange)="showByChange($event)">
        <mat-option *ngFor="let showBy of showByData" [value]="item.config?.showBy_static ? showBy.value : showBy.brand_uom_xref_key"
          class="dropdown_values">{{ (item.config?.showBy_static ? showBy.label : showBy.uom_desc) | initcap }}</mat-option>
      </mat-select>
    </div>
  </div>
</div>
