import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponentComponent } from './loader-component/loader-component.component';
import { ToastComponentComponent } from './toast-component/toast-component.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    LoaderComponentComponent,
    ToastComponentComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports:[
    LoaderComponentComponent,
    ToastComponentComponent
  ]
})
export class LoaderToastModuleModule { }
