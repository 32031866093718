import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, Inject} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { MatCalendar, MatDatepicker } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { Subject, takeUntil } from "rxjs";

/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  styles: [
    `
    .example-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
    }

    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      /* text-align: center; */
    }
  `,
  ],
  template: `
    <div class="example-header">
      <div>
        <button mat-icon-button (click)="previousClicked('year')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <!-- <button mat-icon-button (click)="previousClicked('month')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button> -->
        <span class="example-header-label">{{periodLabel}}</span>
        <!-- <button mat-icon-button (click)="nextClicked('month')">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button> -->
        <button mat-icon-button (click)="nextClicked('year')">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>

    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class AppCustomDatepickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  customYearFormat = {
    year: 'numeric', // 'numeric' is equivalent to 'yyyy'
  };

  get periodLabel() {
    
    return this._dateAdapter
      .format(this._calendar.activeDate, this.customYearFormat)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
