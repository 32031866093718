import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'; // Import CryptoJS library for encryption/decryption
import { Base64 } from 'js-base64'; // Import Base64 for URL-safe encoding/decoding

@Injectable({
  providedIn: 'root'
})

export class CryptoService {

  private key = CryptoJS.enc.Utf8.parse('70928693879789324645');
  private iv = CryptoJS.enc.Utf8.parse('97893246457092869387');

  encrypt(value: string): string {
    let atob = this.encodeToBase64(value)
    return Base64.encodeURI(atob);
  }

  decrypt(value: string): string {
    const decodValue = Base64.decode(value);
    let btoa = this.decodeFromBase64(decodValue)
    return btoa;
  }

  encryptUsingCrypto(value: any) {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    
    return Base64.encodeURI(encrypted);
  }

  decryptUsingCrypto(value:any) {
    // Decode the Base64 encoded string
    const decoded = Base64.decode(value);

    const decrypted = CryptoJS.AES.decrypt(decoded, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // return decryptValue
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }

  encodeToBase64(str:any) {
    return btoa(str);
  }

  decodeFromBase64(base64:any) {
    return atob(base64);
  }
}
