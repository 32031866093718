import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-ediiting-grid-popup',
  templateUrl: './ediiting-grid-popup.component.html',
  styleUrls: ['./ediiting-grid-popup.component.scss']
})
export class EdiitingGridPopupComponent {
  
  config:any;
  newData: any;
  fileData: any;
  selectedValue: any;
  selectedColumn: any;
  operatorData: any = [
    // {
    //   "name": "Less than",
    //   "bucket_range_operator": "<",
    //   "selected": false
    // },
    // {
    //   "name": "More than",
    //   "bucket_range_operator": ">",
    //   "selected": false
    // },
    {
      "name": "Between",
      "bucket_range_operator": "-",
      "selected": true
    }
  ]

  selectedBucketRange = this.operatorData[0].selected;

  constructor(
    public dialogRef: MatDialogRef<EdiitingGridPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filterService: FilterService, 
    private apiService: ApiService,
    private httpClient: HttpClient,
  ) {
    // debugger
    this.newData = JSON.parse(JSON.stringify(data['data']));
    this.fileData = JSON.parse(JSON.stringify(data['file']));
    this.selectedColumn = JSON.parse(JSON.stringify(data['value']));
    this.config = JSON.parse(JSON.stringify(data['configuration']));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addBucketData() {
    let newObj = {
      "brand_key": this.fileData['brand_key'],
      "source_key": this.fileData['source_key'],
      "file_type_key": this.fileData['file_type_key'],
      "from_days": 0,
      "to_days": 10,
      "bucket_range_operator": "-",
      "pymt_pct": 0
    }

    this.newData.push(newObj)
  }

  clearAll(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.newData = []
  }

  deleteData(flr: any, idx: any) {
    this.newData.splice(idx, 1)
  }

  saveChanges() {
    let request:any = {};
    if(this.selectedColumn!='2') {
      request['api_key'] = this.config['list_of_api_file_level'].api_key;
      request['report_typ'] = "U"
      request['brand_key'] = this.fileData['brand_key']
      request['source_key'] = this.fileData['source_key']
      request['ir_days'] = Number(this.fileData['ir_days'])
      request['file_type_dim_key'] = this.fileData['file_type_key']
      request['data_service_fee'] = Number(this.fileData['data_service_fee'])
      this.updateFileTypeLevel(request);
    }
  }

  updateFileTypeLevel(payload:any) {
    
    let query2 = Object.assign({}, this.filterService.baseQuery, payload);

    this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
      
      if(res.length==0){
        this.dialogRef.close(payload);
        return console.log('success');
      } 
    },(err:any)=>{
      console.log('UPDATE - File Level Err '+ payload['api_key'], err)
    });
  }
}
