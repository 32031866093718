import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Inject, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, DialogPosition } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FilterService } from 'src/app/services/filter.service';
import { PopupDeleteComponent } from '../popup-delete/popup-delete.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DialogService } from 'src/app/services/dialog.service';
import { UserActivityService } from 'src/app/services/useractivity.service';

@Component({
  selector: 'app-ir-comment-popup',
  templateUrl: './ir-comment-popup.component.html',
  styleUrls: ['./ir-comment-popup.component.scss']
})
export class IrCommectPopupComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  reqSubcription: Subscription[] = [];
 
  paramsData:any
  data:any
  searchValue = ''
  maxValue: any = 500
  show: any
  style: any
  isChecked: boolean = false
  edit: any = false
  editIndex: any
  employee_key: any
  CommentData: any
  commentIndex: any
  messages: any = []
  editedValue: string = '';
  pluscolor = '#D8F4E5'
  plusbordercolor: any = '#3AC97C'
  minuscolor = '#F6CFDA'
  minusbordercolor: any = '#D11044'
  discrepancycolor = '#1363DF1A'
  discrepancybordercolor = '#1363DF'
  sparkLineBarChart: any;
  selectedTabIndex = 0;
  Cumulative:boolean=false
  dashboardKey:any
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private dialogRef: MatDialogRef<IrCommectPopupComponent>,
    public filterService: FilterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private currency: CurrencyPipe,
    public deviceDetectionService: DeviceDetectionService,
    private el: ElementRef,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    private userActivityService: UserActivityService
  ) {
    
    this.paramsData = passedData.params
    this.messages = passedData.messages

    var empkey: any = sessionStorage.getItem("employee_key");
    this.employee_key = JSON.parse(empkey);
 
      this.router.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.dashboardKey = decryptedParams["ds_key"];
      });
  }


 
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit() {
    this.myFunction();
  }

  ngOnInit(): void {
    this.Cumulative = (this.paramsData.params.item.config?.cumulative?.value)=='true'
    function strToObj(e: any) { var obj = new Function("return" + e); return obj() };
    this.data = this.paramsData?.value ? this.paramsData?.value : []
   
    let maxData = Math.max(this.data['dispense_quantity'], this.data['inventory_quantity'])
    if (this.maxValue < maxData) {
      this.maxValue = maxData
    }
    if (this.data['discrepancy'] == 'Y' && this.data['difference']== 0) {
      this.style = { 'height': '75px', 'width': '200px', 'background-color': this.discrepancycolor, 'border': '2px solid' + this.discrepancybordercolor, 'border-radius': '7px' }
    } else if (this.data['difference'] == 0) {
      this.style = { 'height': '75px', 'width': '200px', 'background-color': this.pluscolor, 'border': '2px solid' + this.plusbordercolor, 'border-radius': '7px' }
    } else {
      this.style = { 'height': '75px', 'width': '200px', 'background-color': this.minuscolor, 'border': '2px solid' + this.minusbordercolor, 'border-radius': '7px' }

    }
    document.getElementById('comment')?.focus()
  }
  getMSGHtml(data:any){
    data= data.replaceAll(' ','&nbsp;')
    data=data.replaceAll('\n','<br>')
    return data
  }
  messageData(value: any, event: any) {
    event.preventDefault();
    event.stopPropagation()
    if (event.shiftKey && event.key === 'Enter'){return}
    if (event.shiftKey) {return}
    if(!value.trim()) { this.searchValue = ''; return  } 
    // value = value.trim()
    if (value == '') return
    let insert:any
    insert = this.paramsData.params.item.config?.['report_type_selection'].find((val:any)=>val.action_typ ==  "INSERT")
 
    let postdata: any = {
      "report_typ": insert.report_typ||  "I",
      "comments": value,
      "ndc_key" : null,
      "data_source_key": this.data.source_key || undefined,
      "period": moment(this.paramsData.data.period).format("yyyy-MM-DD"),
    }
    if (this.paramsData?.params?.sourceName) {
     if( this.filterService.showByKey=="N"){
      postdata['ndc_key'] =  this.data.ndc_key
       postdata["location_key"] = null 
     } else if(this.filterService.showByKey=="L"){
      postdata["location_key"] =  this.data.location_key
      postdata['ndc_key'] = null 

     }
    }
    this.loadCommentsData(postdata)
    this.searchValue = ''
    document.getElementById('comment')?.focus()
  }

  getCurrentDate(date: any) {
    if (date) {
      return moment(date).format('MM/DD/YYYY HH:mm')
    } else {
      return moment().format('MM/DD/YYYY HH:mm')
    }
  }

  deleteComment() {
    let deletert :any
    deletert = this.paramsData.params.item.config?.['report_type_selection'].find((val:any)=>val.action_typ ==  "DELETE")

    let postdata = {
      "report_typ":  deletert.report_typ || "D",
      "user_comments_key": this.CommentData.user_comments_key
    }
    this.loadCommentsData(postdata)
    this.messages.splice(this.commentIndex, 1);
  }

  editComment(message: any, event: any, i: any) {
    // this.stop(event);
    this.editedValue = this.CommentData.comments
    this.editIndex = this.commentIndex
    this.edit = true
  }
  SaveEdit(message: any, index: any) {
    if(!this.editedValue.trim()) { this.editedValue = ''; return  }
    if (this.editedValue == '') return
    let update:any
    update= this.paramsData.params.item.config?.['report_type_selection'].find((val:any)=>val.action_typ ==  "UPDATE")

    let postdata = {
      "user_comments_key": message.user_comments_key,
      "report_typ":  update.report_typ || "U",
      "comments": this.editedValue,
    }
    this.loadCommentsData(postdata)
    this.messages[index].comments = this.editedValue
    this.messages[index].update_timestamp = moment().format('MM/DD/YYYY HH:mm')
    this.edit = false

  }

  openDialog(event: any) {
    // let po: DialogPosition = { left: event.clientX + 'px', top: event.clientY + 'px' };
    // this.dialog.closeAll();
    // this.dialog.open(this.customTemplate, { hasBackdrop: true, position: po, });
  }
  @ViewChild('DeleteDialog') DeleteDialog: any;

  DelDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    // this.dialog.open(this.DeleteDialog, {
    //   width: '250px',
    //   enterAnimationDuration,
    //   exitAnimationDuration,
    // });

    let dialogRef = this.dialog.open(PopupDeleteComponent, {
      width: this.sizeOftheDeletePopup(),
      maxWidth: '90vw',
      disableClose: true,
      panelClass: "du-custom-modalbox",
      data:'comment'
    });

    dialogRef.afterClosed().subscribe((value) => {
      
      if(value == '1') {
        this.deleteComment()
      }
    });

  }
  
  sizeOftheDeletePopup() {
    let width = ''
    return width
  }

  loadCommentsData(data: any) {
     
    data['comment_typ'] = 'IR' 
    data['api_key'] = this.paramsData.params.item.config['comments_api'].comments_api_key
    // data['dashboard_key']= this.dashboardKey
    data['dashboard_key']= this.dashboardService.dashboardKey
    let userState: any = this.userActivityService.getData();
    let ir_calc_mode:any =(this.filterService.showByKey==userState['showBy'+ this.paramsData.params.item.config.showby_name] )?this.filterService.showByKey : (userState['showBy'+ this.paramsData.params.item.config.showby_name] ? userState['showBy'+ this.paramsData.params.item.config.showby_name]:this.filterService.defaultReport_type )
    
    data['ir_calc_mode'] = ir_calc_mode
    if (this.paramsData?.params?.sourceName) {
    data['show_by_type']= this.filterService.showByKey}

    this.reqSubcription.push(this.filterService.commentsQuery(data).subscribe((res: any) => {
      if (data.report_typ == 'I') {
        let commentsvalue = res ? res : []
        this.messages = commentsvalue
        
        this.myFunction();

        this.dialogService.customSnackBar('success', 'green', 'Message Added Successfully');
      }

      // Delete Snackbar
      if(data.report_typ == 'D') {
        this.dialogService.customSnackBar('success', 'green', 'Message Deleted Successfully');
      }

      // Update Snackbar
      if(data.report_typ == 'U') {
        this.dialogService.customSnackBar('success', 'green', 'Message Updated Successfully');
      }

    }))
  }

  closeInst() {
    
   this.dialogRef.close(this.messages) 
  }

  myFunction():void {
    setTimeout(() => {
        const element: HTMLElement | null = this.el.nativeElement.querySelector('#content');
      if(element)
        element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 200);
  }

  getcomentData( message :any,i:any){
    this.CommentData  =message
    this.commentIndex =i
   }
   numbedPipe(value: any) {
     return this.currency.transform(value, '', '', '1.0-2');
   }
   NegnumbedPipe(value: any) {
     
    if(value<0){
      value = value * -1
      return this.currency.transform(value, '', '', '1.0-2');

    }else{
      return this.currency.transform(value, '', '', '1.0-2');
    }
  }

  getStyles() {
    return { width: "100%", height: this.paramsData?.params?.sourceName ? "75%" : "60%", 'overflow-y': "auto" }
  }

  editedChanges(updatedDate: any) {
    if (updatedDate == null) { return false } else {
      let start_Date: any = moment(updatedDate);
      let end_Date: any = moment(new Date());
      let totalDays: any = end_Date.diff(start_Date, 'days');
      if (totalDays <= this.paramsData.params.item.config.no_of_days.value) {

        return true
      } else {
        return false
      }
    }
  }

  seletedTab(event: any) {
    
    if(event == 1) {
      this.myFunction()
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }


  overAllDate(){
    if(this.filterService.baseQuery){
    let start_Date: any = this.filterService.baseQuery['from_date'];
    let end_Date: any =  this.filterService.baseQuery['to_date'];
    let  rolling_Days:any = moment(end_Date).diff(start_Date, 'weeks');
    return  "Rolling "+(rolling_Days+1) +" weeks"}
  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }
}
