<div id="choroMapDiv" class="mt-2">
</div>
<div id="d3ChoroplethTooltip">
    <app-pipeline-tooltip-link *ngIf="hideLinkTooltip" [SelectedNavigation]="true" [item]="item"
        [popupHeading]="tooltipData[0]" [toolTipData]="tooltipValues" [hiddenParams]="hiddenParams"
        (hideLinkTooltip)="tooltipHide()"></app-pipeline-tooltip-link>

</div>
<div id="d3StateTooltip">
    <div class="location_tooltip" *ngIf="showLocations">
        <div class="row location_values mt-2 mb-1 text-center" *ngFor="let d of locationNames;let i=index">
            <div class="col-12 d-flex">
                <div calss="col-1" style="margin: auto;" *ngIf="locationNames.length >1">{{i+1}}.</div>
                <div class="col-10 mt-2 mb-1" style="margin: auto;">
                    {{d?.prescriber_address}}
                </div>
            </div>
        </div>
    </div>
</div>