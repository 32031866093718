import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DeviceDetectionService } from 'src/app/services/detectipad.service'
import Widget from '../widget'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-text-notes',
  template: `
  <span *ngIf="noteValue" class="widgetContainer" [style.margin.px]="!this.deviceDetectionService.isDesktop ? '10' : '0'">
    <pre class="preTag" > {{ noteValue || '' }}</pre>
  </span>
  `,
  styleUrls: ['./text-notes.component.scss']
})
export class WidgetTextNotesComponent extends Widget {
  static override key = 100201

  noteValue:any;

  constructor (
    public deviceDetectionService: DeviceDetectionService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.subs.push(
      this.filterService.filterQuery.subscribe((res:any)=> {
        this.loadConfig();
      })
    );    
  }

  loadConfig() {
    let item = this.item.config?.['text_note_config']
    if(item?.['selectedValue'] == "payment calculator") {
      if(this.enhancementService.currentQuarterFlag) {
        this.noteValue = item?.currentNotes;
      } else {
        this.noteValue = item?.previousNotes;
      }
    } else {
      this.noteValue = item?.defaultNotes || this.item.config?.['notes'] || '';
    }
  }
}