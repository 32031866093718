<div class="card_journey">
  <div class="container">
    <div class="row mt-2 ml-1">
      <div class="col-10 text-start"><img class="mr-2" src="../../../../assets/brand_change.svg" /><span
          class="accordian_heading">{{transactionName.replaceAll('_', ' ')}}</span></div>
      <div class="col-2 text-end"><img src="../../../../assets/Vector.png" (click)="hideTooltip()" /></div>
      <hr class="heading_divider mt-2 mr-3" />
    </div>
    <div class="row accordian_inside_heading ml-1 mt-2">
      {{date | date: 'dd-MMM-yyyy'}}
    </div>

    <div class="inner_box mt-2 mb-2" *ngFor="let data of obj">
      <div class="inner_heading d-flex text-start pt-1 pl-3">
        {{data.name}}
      </div>
      <hr class="heading_divider mt-1 mr-3" />
      <div class="row mt-2" *ngFor="let y of data?.Value">
        <div class="col-12 d-flex">
          <div class="col-5 trans_data_key text-end">{{y.Key}}</div>
          <span class="pl-0">:</span>
          <div class="col-7 trans_data_value text-start">{{y.Value}}</div>
        </div>
      </div>
    </div>

  </div>
</div>