import { ThisReceiver } from '@angular/compiler';
import { Component, Injectable, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from "d3";
import { FilterService } from 'src/app/services/filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-bubblemap-line-chart',
  templateUrl: './bubblemap-line-chart.component.html',
  styleUrls: ['./bubblemap-line-chart.component.scss']
})
export class BubblemapLineChartComponent implements OnInit, OnChanges {
  bubblemaplinechartdata: any = []
  divId: any = 'bubbleMaplinechartDiv';
  @Input('lineColour') lineColour: any
  @Input('sourceName') sourceName: any
  @Input('showBy') showBy: any
  @Input('locationName') locationName: any
  @Input('item') item: any
  @Input('mapType') mapType: any
  @Input('data') data: any
  selectedType: any
  props: any = {}
  showBubbleLineTooltip: boolean = false;
  bubbleLineTooltipData: any;
  // sourceName: any = 'BIOLOGICS SP';
  // locationName: any = 'CARY,NC';

  constructor(private filterService: FilterService) { }

  public showTooltip(myType: any, myData: any, myX: any, myY: any): void {

      myData.push(this.lineColour)
    this.bubbleLineTooltipData = myData

    d3.select("#d3BubbleLineTooltip")
      .style('visibility', 'visible')
      .style('position', 'absolute')
      .style('top', (myY) + 'px')
      .style('left', (myX+15) + 'px')
      .style('z-index', '99999')

      this.showBubbleLineTooltip = true
  }

  public hideTooltip(myType: any): void {
    this.showBubbleLineTooltip = false

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getBubblemapLineChartData();

  }

  ngOnInit(): void {

    this.initiateCharts();
    this.getBubblemapLineChartData();
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const width = mySection.clientWidth;
    const height = 200; // 300 is random height, height is fixed to data later on
    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', 'white');

    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'bmlBrushClip' + myClass)
      .append('rect').attr('id', 'bmlBrushClipRect' + myClass);

    const filter: any = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    mySvg.append('circle').attr('id', 'legendCircle' + myClass);
    mySvg.append('text').attr('id', 'legendLabel' + myClass)


    mySvg.append('path').attr('id', 'brushLinePath' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass)
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('path').attr('id', 'areaPath' + myClass);
    mySvg.append('path').attr('id', 'linePath' + myClass);
    mySvg.append('line').attr('id', 'mouseoverLine' + myClass);
    mySvg.append('circle').attr('id', 'mouseoverDot' + myClass);
    mySvg.append('rect').attr('id', 'mouseoverRect' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);


    mySvg.append("linearGradient")
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("x2", 0)
      .selectAll("stop")
      .data([
        {offset: "0%", color: "black"},
        {offset: "100%", color: "white"}
      ])
      .enter().append("stop")
      .attr("id", (d,i) => i === 0 ? "zeroStop" : "")
      .attr("offset", (d: any) => d.offset)
      .attr("stop-color", (d: any) => d.color);
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    // define core elements
    const myChart: any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width = mySvg.node().getBoundingClientRect().width;
    const height = myChart.props.chartHeight;
    const allData = myChart.bubblemaplinechartdata;
    let margins = { left: 50, right: 30, top: 20, bottom: 70, brush: 20};
    // different margins if no brush
    if(myChart.props.showBrush === false){
      margins.brush = 0;
      margins.bottom = 50;
    }
    // map dates to full dates
    allData.map((m: any) => m.date = new Date(m[myChart.props.xAxisVar]));
    // roll up by date + yAxisVar
    let dateGroup: any = Array.from(d3.rollup(allData, (r: any) => d3.sum(r, (s: any) => s[myChart.props.yAxisVar]), (g: any) =>  g.date));
    // define date group
    let dateGroupDates = dateGroup.map((m: any) => m[0]);
    dateGroupDates = dateGroupDates.sort((a: any, b: any) => d3.ascending(a,b));
    // define x tick format (used in line, bar, combo, gantt, area charts as well)
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    const timePeriod = this.props.period;
    const xTickFormat = d3.timeFormat(tickFormat[timePeriod]);
    // define extents and scales
    const yMax: any = d3.max(dateGroup, (d: any) => d[1]);
    let xExtent: any = d3.extent(dateGroup, (d: any) => d[0]);
    const lastDay1Or2 = +d3.timeFormat("%d")(xExtent[1]) <= 2 ? true : false;
    // as with all brush charts there is an All X scale (the brush, does not change)
    // and a standard X scale - which changes as user interacts with the brush
    // and is used to draw the chart elements
    const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const yScale: any = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);
    // brush scales same as All but have smaller range
    const xScaleBrush: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const yScaleBrush: any = d3.scaleLinear().domain([0, yMax]).range([margins.brush, 0]);
    // line and area definitions
    const line: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d[0]))
      .y((d: any) =>  yScale(d[1]));

    const lineBrush: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScaleBrush(d[0]))
      .y((d: any) =>  yScaleBrush(d[1]));

    const area: any = d3.area()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d[0]))
      .y0((d: any) =>  yScale(d[1]))
      .y1( yScale(0));
    // x brush definition
    const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
      .on('start brush end', brushed);
    // legend circle and label - only 1 so no data appending
    const circleRadius = 4;
    mySvg.select('#legendCircle' + myClass)
      .attr('cy', 7)
      .attr('r', circleRadius)
      .attr('fill',myChart.props.colour);

    mySvg.select('#legendLabel' + myClass)
      .attr('y', 6 + 5)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '11px')
      .attr('font-weight', '500')
      .text(myChart.props.locationName);
    // positioning legend items
    const legendWidth = (circleRadius * 3) + measureWidth(myChart.props.locationName, 12) + 10;

    mySvg.select('#legendCircle' + myClass)
      .attr('cx', circleRadius + (width - legendWidth)/2);

    mySvg.select('#legendLabel' + myClass)
      .attr('x', (circleRadius * 3) + (width - legendWidth)/2);
    // axis labels
    d3.select('#xAxisLabel' + myClass)
      .attr('x', margins.left + ((width - margins.left - margins.right)/2))
      .attr('y', height  - margins.brush - 17)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.xAxisLabel);

    d3.select('#yAxisLabel' + myClass)
    .attr('transform','translate(13,90) rotate(-90)')
      // .attr('transform','translate(13,' + (margins.top + ((height - margins.top - margins.bottom - margins.brush)/2)) + ') rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.yAxisLabel);
    // clip rect for brush
    mySvg.select('#bmlBrushClipRect' + myClass)
      .style('width', width -  margins.right - margins.left)
      .style('height', height)
      .attr('transform', 'translate(0,-20)');

    if(myChart.props.showBrush){
      // call brush if needed
      mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + margins.left + ',' + (height - margins.brush - 10) + ')')
        .call(brush)
        .call(brush.move, [0, width - margins.left - margins.right]);
      // set handle line basic props
      // same format in all brush charts
      mySvg.selectAll('.handleLines' + myClass)
        .attr('y1', height - margins.brush - 10 + (margins.brush - 12) / 2)
        .attr('y2', height - margins.brush - 10 + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + margins.left + ',0)');
      // set brush selection styles
      mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');
      // draw the brush line
      mySvg.select('#brushLinePath' + myClass)
        .attr('d', lineBrush(dateGroup))
        .attr('fill', 'transparent')
        .attr('stroke', myChart.props.colour)
        .attr('stroke-width', '4px')
        .attr('transform', 'translate(' + margins.left + ',' + (height - margins.brush - 10) + ')');
    } else {
      // no brush so make sure nothing is visible
      mySvg.select('#brushGroup' + myClass).html("")

      mySvg.selectAll('.handleLines' + myClass)
        .style('stroke-width', '0')

      mySvg.select('#brushLinePath' + myClass)
        .attr('d', "")
    }
    // set chart height and width
    mySvg.attr("width", width)
      .attr("height", height);
    // mouseover rect for interaction
    mySvg.select("#mouseoverRect" + myClass)
      .attr("fill","transparent")
      .attr("width", width - margins.left - margins.right)
      .attr("height", height - margins.bottom - margins.top)
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
      .on("mousemove", (event: any) => {
        // get date of current position
        const positionDate = xScale.invert(event.offsetX - margins.left);
        // look in data for closest date to position date
        let closest: any = dateGroupDates.findIndex((f: any) => new Date(f) >= positionDate);
        // if not 1st entry
        if(closest > 0){
          // look left and right and decide if at the right spot
          const leftMinutes = d3.timeMinute.count(dateGroupDates[closest - 1], positionDate);
          const rightMinutes = Math.abs(d3.timeMinute.count(dateGroupDates[closest], positionDate));
          if(leftMinutes < rightMinutes){
            closest -= 1;
          }
        } else if(closest === -1){
          // out of range so go for the last entry
          closest = dateGroupDates.length - 1;
        }
        // position the mouseover line and dot
        d3.select('#mouseoverLine' + myClass)
          .attr('visibility', 'visible')
          .attr('x1', xScale(dateGroup[closest][0]))
          .attr('x2', xScale(dateGroup[closest][0]));

        d3.select('#mouseoverDot' + myClass)
          .attr('visibility', 'visible')
          .attr('cx', xScale(dateGroup[closest][0]))
          .attr('cy', yScale(dateGroup[closest][1]));
        // show tooltip
        myChart.showTooltip('lineDot', dateGroup[closest],event.offsetX, event.offsetY);
      })
      .on('mouseout', () => {
        d3.select('#mouseoverLine' + myClass).attr('visibility', 'hidden');
        d3.select('#mouseoverDot' + myClass).attr('visibility', 'hidden');
        myChart.hideTooltip('lineDot');
      });
    // set mouseover line style
    mySvg.select('#mouseoverLine' + myClass)
      .attr('visibility', 'hidden')
      .attr('pointer-events', 'none')
      .attr('stroke', '#A0A0A0')
      .attr('stroke-width', 3)
      .attr('y1', 0)
      .attr('y2', height - margins.top - margins.bottom)
      .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');
    // set mouseover dot style
    mySvg.select('#mouseoverDot' + myClass)
      .attr('visibility', 'hidden')
      .attr('pointer-events', 'none')
      .attr('r', 4)
      .attr('fill', myChart.props.colour)
      .attr('stroke', 'white')
      .attr('stroke-width', 1)
      .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');
    //set gradient y positions
    mySvg.select("#area-gradient")
      .attr("y1", yScale(yMax))
      .attr("y2", yScale(0));
    // and stop color
    mySvg.select("#zeroStop")
      .attr("stop-color", myChart.props.colour);
    // y axis - definition and styles
    mySvg.select('#yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(parseInt(yMax) < 3 ? parseInt(yMax) : 3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.selectAll('#yAxis' + myClass + ' line')
      .attr('x1', '0')
      .attr('x2', width - margins.right - margins.left)
      .style('stroke', '#F0F3F6')
      .style('stroke-width', 1);

    mySvg.selectAll('#yAxis' + myClass + ' text')
      .text((d: any) => +d > 0 ?  d3.format('.0s')(d): "")

    // draw chart
    drawChart();

    function drawChart(){
      dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0],b[0]));
      // draw chart function
      // everything in here changes in response to the brush
      // line path (note clip path)
      mySvg.select('#linePath' + myClass)
        .attr('clip-path','url(#bmlBrushClip' + myClass + ')')
        .attr('d', line(dateGroup))
        .attr('fill', 'transparent')
        .attr('stroke', myChart.props.colour)
        .attr('stroke-width', '4px')
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
      // area path (note clip path)
      mySvg.select('#areaPath' + myClass)
        .attr('clip-path','url(#bmlBrushClip' + myClass + ')')
        .attr('d', area(dateGroup))
        .attr('stroke', 'transparent')
        .attr('fill', 'url(#area-gradient)')
        .attr('fill-opacity', 0.3)
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
      // x axis - define and style
      mySvg.select('#xAxis' + myClass)
        // @ts-ignore
        .call(d3.axisBottom(xScale).tickSizeOuter(0).ticks(3))
        .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

      mySvg.selectAll('#xAxis' + myClass + ' line')
        .attr('display', 'none');

      mySvg.selectAll('.axis' + myClass + ' path')
        .style('stroke', '#E8EAEE');

      const xTicks = xScale.ticks(3);
      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', 500)
        .style('font-size', 10)
        .attr("y", 3);

      mySvg.selectAll('#xAxis' + myClass + ' text')
        .text((d: any, i: any) => {
          if(i < (xTicks.length - 0) || !lastDay1Or2){
            return xTickFormat(d);
          }
          return ""
        });
    }
    function brushed(event: any): void {
      const selection = event.selection;
      if (selection !== null) {
        d3.select('#mouseoverLine' + myClass).attr('visibility', 'hidden');
        d3.select('#mouseoverDot' + myClass).attr('visibility', 'hidden');
        myChart.hideTooltip('lineDot');
        // if selection exists, map to xDomain and change scale
        const xDomain: any = selection.map(xScaleAll.invert);
        xScale.domain(xDomain);
        // draw chart
        drawChart();
        // get the handle positions
        const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
        const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');
        // reset the handle line positions relative to handle positions
        mySvg.select('.handleLeftLine1' + myClass)
            .attr('x1', handleEastX + 3)
            .attr('x2', handleEastX + 3);

        mySvg.select('.handleLeftLine2' + myClass)
            .attr('x1', handleEastX + 7)
            .attr('x2', handleEastX + 7);

          mySvg.select('.handleRightLine1' + myClass)
            .attr('x1', handleWestX + 3)
            .attr('x2', handleWestX + 3);

          mySvg.select('.handleRightLine2' + myClass)
            .attr('x1', handleWestX + 7)
            .attr('x2', handleWestX + 7);
          // style and position the handles (y, x is user driven)
          mySvg.select('#brushGroup' + myClass)
            .selectAll('.handle')
            .attr('fill', 'white')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('y', (margins.brush - 24) / 2)
            .attr('height', 24)
            .style('filter',  'url(#drop-shadow)');
        }
    }
    // used multiple times
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
  }
  // property & data for the chart
  getBubblemapLineChartData() {
    this.filterService.getSampleJsonData('DispenseTrendDataWeeklyBubble').subscribe((res: any) => {
      if (res) {
        this.bubblemaplinechartdata = this.data
        this.props = {
          sourceName: this.sourceName,
          locationName: this.locationName,
          // sourceName:  'Amber',
          // locationName: 'SAN BERNARDINO',
          chartHeight: 200,
          xAxisVar: 'period',
          yAxisVar: this.mapType=='dispenceMap'? 'value':  (this.showBy == "tablets"&& this.showBy != undefined) ?  'quantityshipped': "number_of_bottles",
          colour: this.lineColour || '#645CF3',
          period: this.filterService.report_type || 'D',
          sourceVar: "sourcename",
          locationVar: "locationname",
          xAxisLabel: "Period",
          yAxisLabel: "Quantity Shipped", //+ (this.showBy == undefined ?'Bottles': this.showBy?.charAt(0).toUpperCase() + this.showBy?.slice(1))+")",
          yAxisFormat: '.0',
          xTickFormat: "%d %b %y %H:%M",
          showBrush: true
        }

        this.bubblemaplinechartdata = this.mapType=='dispenceMap' ? this.bubblemaplinechartdata : this.bubblemaplinechartdata.filter((f: any) => f[this.props.sourceVar] === this.props.sourceName  && f[this.props.locationVar] === this.props.locationName);
        this.plotChart();
      }
    });
  }

}



