import { Component, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent, SideBarDef } from 'ag-grid-community';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { LicenseManager } from 'ag-grid-enterprise'
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043433 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data Unveil )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SCRAII )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SCRAII )_need_to_be_licensed___( SCRAII )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 June 2024 )____[v2]_MTcxNzcxNDgwMDAwMA==8211f96f946c59b6a3dfa3ea03a04f25")
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-pivot-grid',
  templateUrl: './pivot-grid.component.html',
  styleUrls: ['./pivot-grid.component.scss']
})
export class WidgetPivotGridComponent implements OnInit, OnDestroy {
  static key = 100505;
  
  @Input('item') item: any = {}
  @Input('pageKey') pageKey: any
  iconList: any
  reqSubcription: Subscription[] = []
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('pivotGridDiv', { static: true }) pivotGridDiv!: ElementRef
  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';
  public overlayNoRowsTemplate = '<div style="display: block;background:  rgba(255, 255, 255) ;width: 100%;height:calc(100% - 60px);position: fixed;bottom: 0;left: 0;z-index: 1000;" class="d-flex align-items-center justify-content-center"><div style="-webkit-animation: frames 1s infinite linear;animation: frames 1s infinite linear;background: transparent;border: 0.5vw solid #FFF;border-radius: 100%;border-color:#984898 #984898 #984898 #2549CA;width: 8vw;height: 8vw;opacity: 1.6;padding: 0;position: absolute;z-index: 999;border-style: solid solid none dotted;animation: rotation 1s linear infinite;"></div><div id="spinnertext" >Loading...</div></div>';
  public   columnDefs: ColDef[] = []
  rowData            : any     = []
  isDataLoaded       :Boolean  = false
  expandCollapseIcon :string   = "expand"
  expandOrCollapse   :string   = "Expand All"
  data               : any     = []
  ndcValue           : string  = ''
  loader             : Boolean = true
  levels             : any     = []
  public autoGroupColumnDef: ColDef = {
    headerName        : 'Group',
    minWidth          : 300,
    pinned            : this.deviceDetectionService.isMobile ? null: 'left',
    cellRendererParams: {
      suppressCount: true,

    }
  }
  public gridApi!: GridApi;
  showBy: any
  constructor(
    public deviceDetectionService: DeviceDetectionService,
    private filterService: FilterService,
    private newFilterService: NewFilterService,
    private numberPipe: NumberPipe,
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
      this.onBtShowLoading()
    }))

    this.newFilterService.showBy.subscribe((value: any) => {
      this.showBy = value
      // this.loadDataGridConfig()

    })
  }

  public processSecondaryColGroupDef: (colGroupDef: ColGroupDef) => void = (colGroupDef: ColGroupDef) => {
    let val = this.item.config.time_cycle.find((f: any) => f.value == (this.filterService.report_type || 'D'))
    colGroupDef.headerName = moment(colGroupDef.headerName).format(val.format || 'DD MMM yyyy')
  };

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('rowGroupOpened', this.onRowGroupOpened.bind(this));
  }

  onRowGroupOpened(event: any): void {
    // Check if all autoGroupColumnDef groups are closed
    let allGroupsClosed = false;
    
    this.gridApi.forEachNode((node) => {
      if (node.group && node.expanded) {
        allGroupsClosed = true;
      }
    });

    if(allGroupsClosed) {
      this.expandCollapseIcon = "collapse";
      this.expandOrCollapse = "Collapse All";
    } else {
      this.expandCollapseIcon = "expand";
      this.expandOrCollapse = "Expand All";
    }

    this.filterService.expendTree.next(allGroupsClosed)
  }
  onBtShowLoading() {
    this.loader = true
    // this.gridApi?.showNoRowsOverlay();
  }
  onBtHide() {
    this.loader = false
    // this.gridApi?.hideOverlay();
  }
  exportXls() {
    this.gridApi.exportDataAsExcel({ fileName: this.item.config['report_heading'] });
  }

  public defaultColDef: ColDef<any> = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: false,

  };

  expendCol(event: any) {
     
    if (event) {
      this.gridApi.expandAll();
    } else {
      this.gridApi.collapseAll()
    }
  }

  expandCollapse() {
    if (this.expandCollapseIcon == "collapse") {
      this.expandCollapseIcon = "expand"
      this.expandOrCollapse = "Expand All"
      this.gridApi.collapseAll()

    } else {
      this.expandCollapseIcon = "collapse"
      this.expandOrCollapse = "Collapse All"
      this.gridApi.expandAll();
    }
  }

  ngOnInit(): void {

    this.onBtShowLoading()
    this.iconList = this.item.config.icon ? this.item.config.icon :  this.iconList;
    this.sideBar  = {
      toolPanels: [
        {
          id             : 'columns',
          labelDefault   : 'Columns',
          labelKey       : 'columns',
          iconKey        : 'columns',
          toolPanel      : 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues   : false,
            suppressPivots   : false,
            suppressPivotMode: true,
            suppressRowGroups: false,
          // suppressSideButtons: false,
          // suppressColumnFilter: false,
          // suppressColumnExpandAll: false,
          // suppressColumnSelectAll: false,
          // suppressSyncLayoutWithGrid: true,
          },
        },
      ],
      position: 'right',
    };
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.pivotGridDiv.nativeElement);
    this.loadDataGridConfig()
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  ngOnChanges(changes: any): void {
  }

  loadDataGridConfig() {
    if (this.item == undefined) return

    for (let i = 1; i < 10; i++) {
      const headerName = this.item.config?.['l' + i + '_col']?.['api_resp_column_display_nm']
      const element    = this.item.config?.['l' + i + '_col']?.['api_resp_column_nm']
      if (element != undefined && headerName != undefined) {
        this.levels.push({
          headerName    : headerName,
          field         : element,
          rowGroup      : true,
          resizable     : true,
          enableRowGroup: true
        },)
      }
    }
    this.columnDefs = [...this.levels,
    {
      headerName : this.item.config.x_axis_column["api_resp_column_display_nm"],
      field      : this.item.config.x_axis_column['api_resp_column_nm'],
      pivot      : true,
      enablePivot: true,
        // Set the pivotComparator to sort in descending order
      pivotComparator: (valueA: any, valueB: any) => valueB.localeCompare(valueA)
    },
    {
      headerName    : this.item.config.value_column["api_resp_column_display_nm"],
      field         : this.getfield(),
      aggFunc       : 'sum',
      valueFormatter: (params: any) => this.numberPipe.transform(params.value),
      cellStyle     : { textAlign: 'center' },
      enableValue   : true
    },
    ];

  }

  getfield() {
    if (this.item.config['list_of_api'].api_key == "100142") {
      return this.item.config.value_column['api_resp_column_nm'].toLowerCase()
    } else {
      if (this.showBy == "tablets") {
        return this.item.config.value_column['api_resp_column_nm'].toLowerCase()
      } else {
        return "number_of_bottles"
      }

    }
  }

  showByValue(value: any) {
    this.ndcValue = value
    this.rowData = this.data.filter((val: any) => val.group_type == value)

    if (value == 'NDC') {
      this.columnDefs = [
        { headerName: this.item.config.l1_col["api_resp_column_display_nm"], field: this.item.config.l1_col['api_resp_column_nm'], rowGroup: false, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.l2_col["api_resp_column_display_nm"], field: this.item.config.l2_col['api_resp_column_nm'], rowGroup: false, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.l3_col["api_resp_column_display_nm"], field: this.item.config.l3_col['api_resp_column_nm'], rowGroup: true, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.x_axis_column["api_resp_column_display_nm"], field: this.item.config.x_axis_column['api_resp_column_nm'], pivot: true, enablePivot: true, pivotComparator: (valueA: any, valueB: any) => valueB.localeCompare(valueA) },
        { headerName: this.item.config.value_column["api_resp_column_display_nm"], field: this.getfield(), aggFunc: 'sum', valueFormatter: (params: any) => this.numberPipe.transform(params.value), cellStyle: { textAlign: 'center' }, enableValue: true },
      ];
    } else {
      this.columnDefs = [
        { headerName: this.item.config.l1_col["api_resp_column_display_nm"], field: this.item.config.l1_col['api_resp_column_nm'], rowGroup: true, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.l2_col["api_resp_column_display_nm"], field: this.item.config.l2_col['api_resp_column_nm'], rowGroup: true, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.l3_col["api_resp_column_display_nm"], field: this.item.config.l3_col['api_resp_column_nm'], rowGroup: true, resizable: true, enableRowGroup: true },
        { headerName: this.item.config.x_axis_column["api_resp_column_display_nm"], field: this.item.config.x_axis_column['api_resp_column_nm'], pivot: true, enablePivot: true, pivotComparator: (valueA: any, valueB: any) => valueB.localeCompare(valueA) },
        { headerName: this.item.config.value_column["api_resp_column_display_nm"], field: this.getfield(), aggFunc: 'sum', valueFormatter: (params: any) => this.numberPipe.transform(params.value), cellStyle: { textAlign: 'center' }, enableValue: true },
      ];
    }
  }

  loadApiData() {
    this.expandCollapseIcon = "expand"
    this.expandOrCollapse   = "Expand All"
    let api = this.item?.config ? this.item?.config['list_of_api'] : {};

    if (api?.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {
      // Api Payload
      let obj: any = { "api_key": api.api_key }

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          this.data = res ? res : [];

          if (api.api_key == 100142) {
            this.onBtHide()
            this.rowData = this.data  //.filter((val:any )=>val.group_type == "source" ) 
            if (this.ndcValue) { this.showByValue(this.ndcValue) }
          } else {
            this.rowData = this.data
            this.onBtHide()
          }
          this.isDataLoaded = true
        }, (err: any) => {
          this.data = []
          this.rowData = []
          this.onBtHide()
        })
      )
    }
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

}
