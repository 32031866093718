import { CommonModule, CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ICellRendererParams } from "ag-grid-community";
import { EnhancementService } from "src/app/services/enhancements-service";
import { FilterService } from "src/app/services/filter.service";

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    selector: 'app-currency-grid-format',
    template: `
    <span [title]="tooltipValue">{{ value }}</span>
    `,
})

export class CurrencyFormatGridComponent {
    value: any;
    tooltipValue: any;
    params: any;

    currencySymbol: string = '$';
    decimalSeparator: string = '.';
    thousandsSeparator: string = ',';

    constructor(private enhancementService: EnhancementService, private filterService: FilterService, private currency: CurrencyPipe) { }

    agInit(params: ICellRendererParams): void {
        this.params = params
        let currentValue = params.value;

        if(this.params?.['api_key']==100190) {
        
            if(this.enhancementService.currentQuarterFlag && this.params['maskCurrentQuarter']) {
                this.value = '-'
                this.tooltipValue = '-'
            } else {
                this.configuration(currentValue);
            }
        } else {
            this.configuration(currentValue);
        }
    }

    configuration(currentValue: any) {
        if (!currentValue || currentValue == 0) {
            this.tooltipValue = '$0.00';
            this.value = '$0.00';
        } else {


            // Ensure the value is a string to handle further manipulations
            let valueString = currentValue.toString();

            // Split the value into whole and decimal parts
            const [wholePart, decimalPart] = valueString.split('.');

            // Format the whole part with thousands separators
            const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);


            // Tooltip
            let tooltipValue = decimalPart ? this.decimalSeparator + (decimalPart.length === 1 ? decimalPart + '0' : decimalPart) : '.00';
            this.tooltipValue = this.currencySymbol + formattedWholePart + tooltipValue;

            // Format the decimal part
            const formattedDecimalPart = decimalPart ? this.decimalSeparator + (decimalPart.length === 1 ? decimalPart + '0' : decimalPart.slice(0, 2)) : '.00';

            // Return the formatted currency string
            this.value = this.currencySymbol + formattedWholePart + formattedDecimalPart;

            if(this.params.node.group) {
                this.value = this.currency.transform(currentValue)
            }
        }
    }
}