import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Subscription, debounceTime } from 'rxjs';
import { AlertSubscriptionService } from '../../services/alert-subscription.service';

@Component({
  selector: 'app-alert-single-select',
  templateUrl: './alert-single-select.component.html',
  styleUrls: ['./alert-single-select.component.scss']
})
export class AlertSingleSelectComponent implements OnInit {

  @Input('field') field:any;
  @Input('allFields') allFields:any;
  @Input('data') data:any;
  @Input('selectAll') selectAll:any;
  @Input('valueChanges') valueChanges:any;

  @Output('emitSelectedValue') emitSelectedValue = new EventEmitter<any>();
  
  valueProp = "";
  labelProp = "";
  label: any;
  currentField: any;
  parentKey: any = "";
  placeholder: any;
  selectedOption: any = null;
  option:any;
  opt:any;
  fieldKey:any;
  oncloseCondition = false;
  reqSubcription: Subscription[] = [];
  key:any;
  validationOnParentChecks = false;

  // Define a variable to hold the subscription
  public parentControlSubscription: Subscription | undefined;
  
  constructor(
    private alertSubscriptionService: AlertSubscriptionService,
  ) {
  }

  ngOnInit(): void {
    // debugger
    // Get Value from configuration
    this.opt = this.field.templateOptions || {};
    this.labelProp = this.opt.labelProp
    this.valueProp = this.opt.valueProp
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl
    this.fieldKey = this.field.key;
    this.key = this.field.key;

    this.option = this.selectAll ? this.selectAll[this.key] : this.alertSubscriptionService.allOnLoadDataForFields[this.key];
    this.selectedOption = this.data[this.key];

    if (this.currentField.parentKey) {
      const parentData = this.data[this.currentField.parentKey];
      if (
        !parentData ||
        (typeof parentData === 'object' && Object.keys(parentData).length === 0) || // Check for empty object
        (Array.isArray(parentData) && parentData.length === 0) // Check for empty array
      ) {
        this.validationOnParentChecks = true;
      }
    } else {
      this.validationOnParentChecks = false;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['valueChanges'] && changes['valueChanges'].currentValue !== changes['valueChanges'].previousValue) {

      let curVal = changes['valueChanges'].currentValue;
      let selectVal = curVal.valueChange;

      let fndKey = this.allFields.find((o:any)=> o.parentKey == selectVal?.key)

      if(fndKey && this.valueChanges.position == selectVal?.position && fndKey.key == this.key) {
        this.option = this.selectAll ? this.selectAll[this.key] : this.alertSubscriptionService.allOnLoadDataForFields[this.key];
        this.selectedOption = null;
      }
    }
  }
  
  validation() {
    if(!this.selectedOption) return true;
    if(Object.keys(this.selectedOption).length==0) return true;
    return false;
  }

  // Load API Data
  loadApiData(method: string, url: string, key: string, data: any) {

    this.reqSubcription.push(this.alertSubscriptionService.loadApiData(method, url, key, data).subscribe((data: any) => {
      
      if(!data || data.length==0) {
        this.setValueWhenNoData();
        return
      } 
      this.option = data;
      this.selectedOption = null;
      this.field.formControl?.setValue(this.selectedOption)
    }, (err:any)=> {
      // when there is no data in filter
      this.setValueWhenNoData();
    }))
  }

  setValueWhenNoData() {
    this.option=[];
    this.selectedOption = null;
    this.field.formControl?.setValue({ [this.key as string]: null})
  }

  onFilterTextBoxChanged($event: any) {
    return ($event.target as HTMLInputElement).value
  }  

  validationForField = false;

  // when on close the dropdown
  onClose() {
    // debugger
    if(!this.selectedOption || (typeof this.selectedOption === 'object' && Object.keys(this.selectedOption).length === 0)) {
      this.validationForField = true;
    } else {
      this.validationForField = false;
    }
    // this.field.formControl?.setValue(this.selectedOption)
  }

  selectionChange() {
    // debugger
    let obj = {
      key : this.key,
      value : this.selectedOption
    }
    this.emitSelectedValue.emit(obj);
  }
}
