import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {


  isBrowserClosed : boolean =true;
  constructor() {
    //this.mixpanelToken = environment.mixpanel_token;
let userdata :any=localStorage.getItem('roles');
     
    this.init();
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(): void {
    mixpanel.init(environment.MixPanelToken);
   // mixpanel.identify(userToken);
  }
  setIdentity(userToken:string): void {
    //mixpanel.init("3e49ec3030837692545f75e53392f748");
   mixpanel.identify(userToken);
  }



  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if(environment.MixPanelTrack)
    {
    mixpanel.track(id, action);
    }
  }
  register(action: any = {}): void {
    
    mixpanel.register(action);
    
  }
  time_event(action: any = {}): void {
    
    mixpanel.time_event(action);
    
  }
}