<div class="widget-container" #barCharDiv>
    <bar-chart 
    [headerConfig]="this.item.config.widget_header_configer"
    style="width: 100%;"
    [config]="config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
  
    >
    </bar-chart>
</div>
