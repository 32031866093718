import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {
  messages: any = [
    {name:"Darshan",message:"Hi, Asif. How can I help you?",time:" • 13:30"},
    {name:"you",message:"Hey. I need some help with setting a new password to my account. How to do it?",time:" • 13:32"},
    {name:"Darshan",message:"typing...",time:" "}
    ]
  HelpIcon:boolean=false
  commentIndex:any
  CommentData:any
  searchValue:any
  @ViewChild('chatWindow') chatWindow: any;
  constructor(  public dialog: MatDialog,private currency: CurrencyPipe) { }
 svg:any = '<svg width="64" height="64" viewBox="0 0 64 64" fill="none"  xmlns="http://www.w3.org/2000/svg"><rect width="64" height="64" rx="12" fill="#1363DF" fill-opacity="0.1" /><path d="M31.9999 35.9997L27.9999 31.9997M31.9999 35.9997C33.8624 35.2913 35.6491 34.398 37.3333 33.333M31.9999 35.9997V42.6663C31.9999 42.6663 36.0399 41.933 37.3333 39.9997C38.7733 37.8397 37.3333 33.333 37.3333 33.333M27.9999 31.9997C28.7094 30.1589 29.6029 28.3944 30.6666 26.733C32.2202 24.249 34.3834 22.2037 36.9506 20.7918C39.5178 19.3799 42.4035 18.6482 45.3333 18.6663C45.3333 22.293 44.2933 28.6663 37.3333 33.333M27.9999 31.9997H21.3333C21.3333 31.9997 22.0666 27.9597 23.9999 26.6663C26.1599 25.2263 30.6666 26.6663 30.6666 26.6663M21.9999 37.9997C19.9999 39.6797 19.3333 44.6663 19.3333 44.6663C19.3333 44.6663 24.3199 43.9997 25.9999 41.9997C26.9466 40.8797 26.9333 39.1597 25.8799 38.1197C25.3617 37.625 24.679 37.3392 23.9629 37.317C23.2468 37.2949 22.5478 37.538 21.9999 37.9997Z" stroke="#1363DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>'
  ngOnInit(): void {
  }
  openDialog(event: any) {
    let po: DialogPosition = { left: event.clientX-285 + 'px', top: event.clientY-400 + 'px' };
    this.dialog.closeAll();
    this.dialog.open(this.chatWindow, { hasBackdrop: false, position: po, });
    setTimeout(() => {
      document.getElementById('header')?.click()
    }, 200);

  }
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
}
matDialogClose(){
  this.HelpIcon=!this.HelpIcon
  this.dialog.closeAll();
}

getCurrentDate(date: any) {
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  } else {
    return moment(new Date()).format('DD/MM/YYYY')
  }
}
getcomentData( message :any,i:any){
  this.CommentData  =message
  this.commentIndex =i
 }
 messageData(value: any, event: any) {
  event.stopPropagation()
  if (value == '') return
  this.messages.push({message:value,name:'you',time: '•'+ moment(new Date()).format("hh:ss")})
  this.searchValue = ''
  document.getElementById('comment')?.focus()
}
 
}
