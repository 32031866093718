<div  class="widget-container">

    <div>
        <icon-chart 
            [headerConfig]="item.config?.widget_header_configuration"
            [rowDataLength]="rowData" 
            [data]="rowData" 
            [pageKey]="pageKey" 
            [heading]="heading"
            [chartName]="'locationCard'" 
            [item]="item"
        ></icon-chart>
    </div>

    <div *ngIf="!isDataLoaded"><loading >{{ !isDataLoaded }}</loading></div>

    <mat-accordion #accorion class="example-headers-align" *ngIf="rowData && isDataLoaded">
        <mat-expansion-panel style="border: 1px solid #E8EAEE;" *ngFor="let option of rowData | filterText: searchText; let index = index;trackBy: trackByFn" [expanded]="step === index" (opened)="setStep(index, option)" >


            <mat-expansion-panel-header class="expansionGivenStyle" *ngIf="this.filterService.selectedColumn">
                <mat-panel-title *ngFor="let a of this.filterService.selectedColumn;trackBy: trackByFn" (click)="$event.stopPropagation();">
                    <app-accordin-column inView [option]="option" [selectedColumn]="a" [item]="this.item.config"></app-accordin-column>
                </mat-panel-title>
                <!-- <div style="display: flex;align-items: center;">
                    <mat-icon *ngIf="!panelOpenState">add</mat-icon>
                    <mat-icon *ngIf="panelOpenState">remove</mat-icon>
                </div> -->
            </mat-expansion-panel-header>

            <hr>
            <div *ngIf="dashboardPages">
                <app-patient-grid-list style="height: 100%; width: 100%" *ngIf="step === index" [item]="dashboardPages" [paginates]="false">
                </app-patient-grid-list>
            </div>
        </mat-expansion-panel>
      
    </mat-accordion>
</div>