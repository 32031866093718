<span *ngIf="data">
    <span *ngFor="let a of data" style="padding: 6px;">
        <mat-icon (click)="matIconClick($event,a)" [matBadge]="a.Count == 1 ? '' : a.Count" matBadgeColor="warn" style="width: 24px; height: 24px;cursor: pointer;" [svgIcon]="a.icon" [style.color]="a.color"></mat-icon>
    </span>
</span>
<span *ngIf="!data" style="color: #8A98AB">
    No alerts
</span>

<ng-template #patientJourney>
    <div class="example-dialog-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <app-pipeline-update-popup [NetworkChartTooltip]="false" (valueChange)='displayCounter($event)'></app-pipeline-update-popup>
    </div>
</ng-template>