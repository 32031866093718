import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatComponentsModule } from './../../mat-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedServicesModule } from 'src/app/services/shared.services.module';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth.routing.module';
import { QRCodeModule } from 'angularx-qrcode';
import { LogoutComponent } from './logout/logout.component';
import { ImpersonationComponent } from './impersonation/impersonation.component';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    LogoutComponent,
    ImpersonationComponent
  ],
  imports: [
    CommonModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    SharedServicesModule,
    AuthRoutingModule,
    FormsModule,
    NgSelectModule,
  ],
  exports:[
    LoginComponent,
    ResetPasswordComponent,
    LogoutComponent,
    ImpersonationComponent
  ]
})
export class AuthModule { }
