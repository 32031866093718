import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-basic-heatmap-card',
  templateUrl: './basic-heatmap-card.component.html',
  styleUrls: ['./basic-heatmap-card.component.scss']
})
export class BasicHeatmapCardComponent implements OnInit, OnDestroy {
  static key = 100702;

  @Input('item') item:any
  @Input('pageKey') pageKey:any
  heading:any;
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('BasicHeatMapCharDiv',{static:true}) BasicHeatMapCharDiv!:ElementRef
  config:any
  data:any
  isDataLoaded=false
  reqSubcription: Subscription[] =[]
  columnDetails:any
  constructor(
    private filterService: FilterService,
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query:any)=>{
      this.loadApiData()
    })
    )

    this.reqSubcription.push(this.filterService.kpiBreakDownSelectedType.subscribe((rept:any)=> {
      
      this.loadApiData()
    }))
   }
  ngOnInit(): void {
    this.item;

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.BasicHeatMapCharDiv.nativeElement);
    this.loadlineConfig();
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
   
  }
  
  isEmptyObject(obj:any) {
    return Object.keys(obj).length === 0;
  }

  loadApiData(reportType?: string) {
    let api = this.item.config["list_of_api"] || {};

    // When basequery is loaded 
    if((this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api Payload
      let obj:any = {"api_key" : '100300', "report_typ" : this.filterService.report_type, "time_cycle" : this.filterService.report_type}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.isDataLoaded = true;
          this.data = res == null ? [] : res;
        },(err:any)=>{
          this.data = [];
          console.log(err)
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
      //     this.isDataLoaded = true;
      //     this.data = res == null ? [] : res;
      //   },(error: any) => {
      //     this.data = [];
      //     console.log(error)
      //   })
      // );
    }

      // this.filterService.executeQuery('100301').subscribe(
      //   (res: any) => {
      //     this.columnDetails = res == null ? [] : res;
          // this.filterService.fprDataQuery('100300').subscribe(
          //   (res: any) => {
          //     this.isDataLoaded = true;
          //     this.data = res == null ? [] : res;
          //   },
          //   (error: any) => {
          //     this.data = [];
          //   }
          // )
         
        // },
      //   (error: any) => {
      //     this.data = [];
      //     // console.error(error);
      //   }
      // )
     
   
  }
  // columnDetails
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
