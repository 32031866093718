<!-- Widget-Container -->
<loading *ngIf="loaderKpi1" type="kpi"></loading>
<div class="container-fluid widget-container" *ngIf="item.config">
    <div class="d-flex">
        <div class="pr-2 NotCenterDiv">
            <mat-icon class="small-icon" [svgIcon]="item.config['icon']['name']"
                [style.color]="item.config['icon_color']">
            </mat-icon>
        </div>
        <div class="topContainer" style="text-align: left;" *ngIf="item.config['header_alignment']['value'] == 'bottom'">
            <div class="kpi2Values mb-1">
                <span  *ngIf="!loaderKpi1" [style.color]="item.config['icon_color']">
                    {{(this.item.config.integer_format?.value =='number') ?  (kpi_1_value || 0 | numberFormat) : kpi_1_value || 0 |customCurrency}}
                </span>
                <!-- <span><div  *ngIf="loaderKpi1" [style.border-top]="  '5px solid '+item.config['icon_color']" class="loader"></div></span> -->
                 <span class="ml-2 label_kpi2" style="font-weight:400">{{item.config['unit_1_label']}}
                </span>
            </div>
            <div class="kpi2fontlabel mb-1">{{item.config['kpi_1_heading']||'KPI Heading'}}</div>
            <div class="kpi2FontStyle">
                <span>{{item.config['kpi_2_heading']||'KPI-2 Heading'}}:</span>
                <span class="fontlowerStyle" *ngIf="!loaderKpi1">  {{(this.item.config.integer_format?.value =='number') ?  (kpi_2_value || 0 | numberFormat)  : kpi_2_value || 0 |customCurrency}}</span>
                <span class="ml-2">{{item.config['unit_2_label']}}</span>
            </div>
        </div>
        <div class="topContainer" style="text-align: left;align-self: center;" *ngIf="item.config['header_alignment']['value'] == 'top'">
            <div class="kpi2fontlabel mb-1">{{item.config['kpi_1_heading']||'KPI Heading'}}</div>
            <div class="kpi2Values mb-1  mt-2">
                <span *ngIf="!loaderKpi2"  [style.color]="item.config['icon_color']">
                    {{(this.item.config.integer_format?.value =='number') ?  (kpi_1_value || 0 | numberFormat)  : kpi_1_value || 0 |customCurrency}}
                </span>
                <!-- <span><div  *ngIf="loaderKpi2" [style.border-top]="  '5px solid '+item.config['icon_color']" class="loader"></div></span> -->
                 <span class="ml-2 label_kpi2" style="font-weight:400">{{ unit_1_label || item.config['unit_1_label']}}
                </span>
            </div>
            <div class="kpi2FontStyle">
                <span>{{kpi_2_heading||item.config['kpi_2_heading']||'KPI-2 Heading'}}:</span>
                <span class="fontlowerStyle" *ngIf="!loaderKpi2">  {{(this.item.config.integer_format?.value =='number') ?  (kpi_2_value || 0 | numberFormat)  : kpi_2_value || 0 |customCurrency}}</span>
                <span class="ml-2">{{item.config['unit_2_label']}}</span>
            </div>
        </div>
    </div>
</div>
<!-- Top Right Opacity Icon -->
<div class="large-icon-containter" *ngIf="item.config && !loaderKpi1" id="setting">
    <img class="info_img"  matTooltip="Help" (click)="getInfoDetail()"  [matMenuTriggerFor]="info"   type="button"  src="./../../../../assets/svgIcons/info_circle.svg"/>
    <!-- <span (mouseover)="isOpen = !isOpen" (mouseout)="isOpen = !isOpen"  type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="stroke_text">i</span> -->
    
    <mat-menu  #info="matMenu">
        <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
            <div class="card-header d-flex justify-content-between align-items-center" style="border-bottom: none;background: #ddd;">
            <span style="font-weight: 600;">{{infoData?.info_header}}</span>
        
            <span (click)="closeMenu()" mat-dialog-close> 
                <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z" fill="#1363DF" />
                </svg>
            </span>
            </div>
        
            <div class="card-body">
            <div [innerHtml]="(infoData?.info_text) || '' "></div>
            </div>
        </div> 
    </mat-menu>
</div>

<div class="bottom-icon-container" *ngIf="item.config && !loaderKpi1">
    <mat-icon class="large-icon" [svgIcon]="item.config['icon']['name']"></mat-icon>
</div>