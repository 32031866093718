<div class="row page-title-header m-0 modal-header">
    <div class="d-flex justify-content-between">
      <div></div>
      <div>
        <span class="title">Select Role</span>
      </div>
      <div>
        <a class="close link" (click)="closeUsingCross()">&times;</a>
      </div>
    </div>
  </div>
  <div style="padding: 20px 15px 0;">
    <mat-form-field style="width: 100%" appearance="fill" class="matFformFieldConfig">
      <mat-label>Role name</mat-label>
      <mat-select [(ngModel)]="selectedRole">
        <mat-option style="font-family: 'Poppins';" *ngFor="let role of roles" [value]="role" title="{{role.user_role_nm}}">{{role.user_role_nm}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div class="row">
    <div class="col-12 text-center my-4">
      <button 
        type="button" 
        class="btn btn-primary btn-sm confirmBtnHomePopups" 
        matTooltip="Confirm" 
        matTooltipClass="expand-tooltip"
        [disabled]="!selectedRole" 
        (click)="selectRole(selectedRole)" 
      >
        Confirm
      </button>
    </div>
  </div>