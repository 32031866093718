import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSidenav } from "@angular/material/sidenav";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { FilterService } from "src/app/services/filter.service";
import { IconService } from "src/app/services/icon.service";
import { NavigationService } from "src/app/services/navigation.service";
import { SessionService } from "src/app/services/session.service";
import { UserActivityService } from "src/app/services/useractivity.service";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  
  @Input("menuItems") menuItems: any[] = [];
  @Output() onSelect = new EventEmitter<any>();
  @Output("sidebarSize") sidebarSize = new EventEmitter<any>();
  
  @ViewChild("sidenav") sidenav: MatSidenav | undefined;
  
  clientKey: any;
  clientName: any;
  userName: string = "";
  logo: boolean = false;
  starts = "search_icon";
  modeOver = "over";
  toggleClicked = false;  
  
  isExpanded = false;
  showSubmenu: boolean = false;
  showSubSubMenu: boolean = false;
  reqSubcription: Subscription[] = [];
  hovered: any;
  highlightedIcon: any;
  moduleKey: any;
  iconsforUserMenu: any = [];
  selectedName: any;
  isIpad = false;
  brandKeyData: any[] = [];
  test: any;
  menuState: string = "out";
  clickWaitsUntilYes = false;

  constructor(
    private iconService: IconService,
    private sessionService: SessionService,
    public dashboardService: DashboardService,
    private router: ActivatedRoute,
    private route: Router,
    private navigationService: NavigationService,
    public filterService: FilterService,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    private userActivityService: UserActivityService,
    public deviceDetectionService: DeviceDetectionService
  ) {
    this.reqSubcription.push(this.filterService.userMenuSideBar.subscribe((res:any)=> {
      if(res) this.isExpanded = true;
    }));
  }

  public highlightRow(emp: any, access_flg: any) {
    if (access_flg != "N") {
      this.selectedName = emp.module_key;
    }
  }
  
  ngOnInit(): void {
    this.userName = this.sessionService.getUsername();
    let clientObj: any;
    let client: any;

    if (sessionStorage.getItem("client")) {
      let clientData = sessionStorage.getItem("client");
      clientObj = JSON.parse(clientData as any);

      if (clientObj && clientObj.length && clientObj[0].client) {
        client = clientObj[0].client;
        this.clientKey = client.client_key;
        this.clientName = client.client_nm;
      }
    } else {
      this.route.navigate([`home/`]);
    }

    if (this.clientKey) {
      let body = {
        username: this.userName,
        client_key: this.clientKey,
      };

      this.reqSubcription.push(
        this.dashboardService.getModuleAccess(body).subscribe(
          (modules) => {
            this.saveDataInSession(modules)
          },
          () => {}
        )
      );
    }
  }

  saveDataInSession(modules:any) {
    this.sessionService.setEmployeeId(modules[0].employee_key);
    this.filterService.employKey = modules[0].employee_key
    this.menuItems = modules;
    this.highlightedIcon = modules[0].module_key;
    this.filterService.firstModuleKey = modules[0].module_key;
    this.router.firstChild?.params.subscribe((p) => {
      if (Object.keys(p).length == 0) {
        let flg = this.menuItems.find((res:any)=>res.access_flg=='Y')
        this.onClick(flg);
      }
    });
  }

  selectedMenu(menu: any) {
    if (menu.isSelected) return "icon-hover";
    return "icon-normal";
  }

  onClick(item: any) {
    if (item.access_flg != "Y") {
      return false;
    }

    this.filterService.refreshData = false;
    this.filterService.brandCarry = false;

    // Filter reset in Nav track
    this.filterService.resetFilterAndPayloadInNavDetails();
    
    let userStateData: any = this.userActivityService.getData();
    let key = Object.keys(userStateData.selectedFilters).length
    if(key > 0) {
      this.filterService.resetFilter();
    }
    // if(userStateData.pageTab != 0) {
    //   this.userActivityService.setData({ pageTab: 0 });
    // }
    if(userStateData.selectedType != '') {
      this.userActivityService.setData({ selectedType: '' });
    }
    this.highlightedIcon = item.module_key
    this.dashboardService.moduleKey = item.module_key
    
    this.userActivityService.hierarchicalGridState = [];
    // window.colState = ''
    // sessionStorage.removeItem('window.colState');
    
    if(item.module_key!='100110'){
      sessionStorage.removeItem("brandKeyForFilters");
    }

    // Select module
    this.dashboardService.setModuleDetails(item);
    sessionStorage.setItem("module", JSON.stringify(item));
    
    let query = {
      cl_key: item.client_key,
      md_key: item.module_key
    }
    this.navigationService.routerNavigate(`dashboard/${item.module_nm}`, query)

    // this.route.navigate([`dashboard/${item.client_key}/${item.module_key}`]);
    
    // if(!this.deviceDetectionService.isDesktop) {
    //   this.filterService.userMenuSideBar.next(true);
    // }
    this.filterService.brandSelectWithBackNavigation = false;
  }

  styleObject(menu: any) {
    return { "background-image": "url(" + menu.active_image + ")" };
  }

  handleSidebarToggle() {
    if(!this.clickWaitsUntilYes) {
      this.clickWaitsUntilYes = true;
      
      if(this.toggleClicked) {
        this.toggleClicked = false;
        this.isExpanded = false;
      } else {
        this.toggleClicked = true;
      }
      this.sidebarSize.next(this.toggleClicked);

      setTimeout(() => {
        this.clickWaitsUntilYes = false;
      }, 2000);
    }
  }

  mouseenter() {
    if(!this.toggleClicked) {
      this.isExpanded = true;
    }
  }

  mouseleave() {
    if(!this.toggleClicked) {
      this.isExpanded = false;
    }
    this.filterService.userMenuSideBar.next(false);
  }

  toggleMenu() {
    this.menuState = this.menuState === "out" ? "in" : "out";
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
