<!--  OLD LOGIN SCREEN -->

<!-- <div class="login-wrapper">
  <div class="w-100 globalNav">
      <div class="navbar-area sticky-top">
          <div class="main-nav">
              <div class="w-100 header_home_main">
                  <nav class="navbar navbar-expand-md navbar-light">
                      <a class="navbar-brand" href="index.html">
                          <img src="assets/SCRAII_Logo.png" alt="Logo" />
                      </a>
                  </nav>
              </div>
          </div>
      </div>
  </div>

  <div class="login-container">
      <div class="row mx-0">
          <div class="col-xl-6 col-lg-8 col-md-9 col-sm-12 card flex-row mx-auto px-0">
              <div class="img-left d-none d-md-flex"></div>
              <div class="card-body">
                  <div [hidden]='!loginMode'>
                      <div class="col-lg-12 col-sm-12 col-12 p-0">
                          <h4 class="title mt-4">
                              Login
                          </h4>
                          <p class="font12">Welcome to SCRAII. Please enter your login
                              credentials.</p>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 form-box p-0">
                          <form #f="ngForm">
                              <div class="form-input">
                                  <label>Username</label>
                                  <input type="email" [(ngModel)]='username' placeholder="Enter your email address"
                                      name="username" required>
                              </div>
                              <div class="form-input">
                                  <label>Password</label>
                                  <input type="password" [(ngModel)]='password' placeholder="Enter your password"
                                      name="password" required>
                              </div>
                              <div class="col-lg-12 col-sm-12 col-12 my-2 p-0">
                                  <div class="text-right">
                                      <a href="javascript:void(0)" class="forget-link"
                                          (click)="showResetPassword()">Forgot Password?</a>
                                  </div>
                              </div>
                              <button type="submit" class="btn btn-block" (click)="login(f)">Login</button>
                          </form>

                          <div>
                              {{message}}
                          </div>
                      </div>
                  </div>

                  <div [hidden]='!firstTimeReset'>
                      <div class="col-lg-12 col-sm-12 col-12">
                          <h4 class="title text-center mt-4">
                              Reset Password
                          </h4>
                          <br />
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 form-box">
                          <form>
                              <div class="form-input">
                                  <label>New password</label>
                                  <input type="password" [(ngModel)]='new_password' placeholder="Enter new password"
                                      name="newPassword" required>
                              </div>
                              <div class="form-input">
                                  <label>Confirm password</label>
                                  <input type="password" [(ngModel)]='confirm_password'
                                      placeholder="Enter confirm password" name="confirmPassword" required>
                              </div>
                              <button type="submit" class="btn btn-block" (click)="resetPassword()">Reset</button>
                              <br />{{messageResetPassword}}
                          </form>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 my-3 p-0 m-0">
                          <div class="text-right">
                              <a href="javascript:void(0)" class="forget-link nextIcon" (click)="showLogin()">
                                  <span class="mr-2">Go to login form</span> <span class="arrow"></span>
                              </a>
                          </div>
                      </div>
                  </div>

                  <div [hidden]='!forgotMode'>
                      <div class="col-lg-12 col-sm-12 col-12">
                          <h4 class="title mt-4 mb-3">
                              Set new password
                          </h4>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 form-box">
                          <form>
                              <div class="form-input">
                                  <label>Email</label>
                                  <input type="email" [(ngModel)]='forgot_email' placeholder="Enter email"
                                      name="forgotEmail" required>
                              </div>
                              <button type="submit" class="btn btn-block" (click)="sendVerificationCode()">Send
                                  verification code</button>
                          </form>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 my-3">
                          <div class="text-left">
                              <a href="javascript:void(0)" class="forget-link backIcon" (click)="showLogin()">
                                  <span class="arrow"></span>
                                  <span class="ml-4">Back to login</span>
                              </a>
                          </div>
                      </div>
                  </div>

                  <div [hidden]='!resetMode'>
                      <div class="col-lg-12 col-sm-12 col-12">
                          <h4 class="title mt-4 mb-3">
                              Reset password
                          </h4>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 form-box">
                          <form>
                              <div class="form-input">
                                  <label>Verification code</label>
                                  <input type="text" [(ngModel)]='verification_code'
                                      placeholder="Enter verification code" name="verificationCode" required>
                              </div>
                              <div class="form-input">
                                  <label>New password</label>
                                  <input type="password" [(ngModel)]='new_update_password'
                                      placeholder="Enter new password" name="newPassword" required>
                              </div>
                              <button type="submit" class="btn btn-block" (click)="updatePassword()">Update
                                  password</button>
                              <div>
                                  {{updatePasswordMessage}}
                              </div>
                          </form>
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 my-3 p-0 m-0">
                          <div class="text-right">
                              <a href="javascript:void(0)" class="forget-link nextIcon" (click)="showLogin()">
                                  <span class="mr-2">Go to login form</span> <span class="arrow"></span>
                              </a>
                          </div>
                      </div>
                  </div>

                  <div [hidden]='!mfaMode'>
                      <div class="col-lg-12 col-sm-12 col-12 user-name">
                          <strong>MFA Verification</strong>
                      </div>
                      <br />
                      <div class="col-lg-12 col-sm-12 col-12 form-input">
                          <form>
                              <div *ngIf="showQR">
                                  <div>Please scan this code to setup MFA</div>
                                  <qrcode [qrdata]="qrValue" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                              </div>
                              <div class="form-group">
                                  <mat-form-field appearance="standard">
                                      <label>Enter verification code</label>
                                      <input type="text" [(ngModel)]="mfa_answer" name="verificationCode" matInput
                                          required>
                                  </mat-form-field>
                              </div>
                              <button type="submit" class="btn btn-primary" (click)="verifyMFA()">Verify MFA</button>
                              <div class="text-danger">
                                  <br />{{mfaMessage}}
                              </div>
                          </form>
                          <br />
                      </div>

                      <div class="col-lg-12 col-sm-12 col-12 link-part">
                          <a href="javascript:void(0)" (click)="showLogin()">Login form</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->





<!--  NEW LOGIN SCREEN -->
<div class="container-fluid">
    <div class="row">
        <!-- Login Form Block -->
        <div class="du_login_box col-lg-6 col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-between"
            style="height:100vh">
            <!-- SCRAII LOGO -->
            <div class="row mt-2 pl-4 laptopview">
                <img class="pt-2" src="../../../../assets/SCRAII_LoginLogo.svg" style="width: 25%" />
            </div>
            <div class="row for_mobile_tab mt-5">
                <div class="col-12 d-flex" style="width: -webkit-fill-available;justify-content: center;align-items: center;">
                    <img class="pt-2 scraii_mob_logo" src="../../../../assets/scraii_mobile_logo.svg" />
                    <div class=" ml-1 scraii_txt_mobile" style="font-size: large;margin-top: 13px;">SCRAII</div>
                </div>
                <div class="col-12 mt-3 d-flex flex-column" style="width: -webkit-fill-available;justify-content: center;align-items: center;">
                    <div class=" ml-1 power_txt_mobile">POWERING PATIENT ENGAGEMENT</div>
                    <div class="ml-1 mt-2 show_subContent" style="font-size: smaller;">Provide powerful insights to brand and patient services leaders</div>
                </div>
            </div>
            <!-- LOGIN FORM -->
            <div class="row d-flex justify-content-center form_mobile mr-0 mb-0"
                style="width: -webkit-fill-available;top: 25%;position: absolute;">
                <div class="card shadow-lg w-75">
                    <!-- Login -->
                    <div class="card-body" [hidden]="!loginMode">
                        <div class="row d-flex justify-content-center get_started text-center">
                            <!-- </div> -->
                            <!-- <div class="row mt-2 get_started_subheading"> -->
                            Sign in
                        </div>
                        <div class="row mt-3">
                            <form #f="ngForm">
                                <div class="form-input">
                                    <label class="login_label">Email</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/email_prefix.svg" />
                                        <input class="login_input pl-5" type="email" [(ngModel)]='username'
                                            placeholder="Enter your email address" name="username" required />
                                    </div>
                                </div>

                                <div class="form-input mt-3">
                                    <label class="login_label">Password</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5" type="{{ showPassword ? 'text' : 'password' }}"
                                            type="password" [(ngModel)]='password' placeholder="Enter new password"
                                            name="newPassword" required />
                                        <img draggable="false" (click)="togglePasswordVisibility()" class="iconLogin"
                                            [src]="!showPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'"
                                            style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class="form-input row error_msg mt-2 mb-2">
                                    {{message}}
                                </div>

                                <div class="row align-items-center">
                                    <div class="col-6 text-left d-flex">
                                        <input type="checkbox" [checked]="rememberMe"
                                            (change)="onRememberMeChange($event)" />
                                        <span class="remember_me_login ml-2">Remember Me</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <a class="forgot_password_link" href="javascript:void(0)"
                                            (click)="showResetPassword()">Forgot Password</a>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button type="submit" class="btn btn-primary login_button" (click)="login(f)">
                                            Login
                                        </button>
                                    </div>
                                </div>

                                <!-- <div class="row mt-4">
                                    <div class="col-12 d-flex justify-content-center">
                                        <span class="not_member">Not a member?</span>
                                        <a class="sign_up_link pl-1" href="javascript:void(0)">Sign Up</a>
                                    </div>
                                </div> -->
                            </form>
                        </div>
                    </div>
                    <!-- MFA Verification -->
                    <div class="card-body" [hidden]="!mfaMode">
                        <!-- <div class="row get_started">
                            Get started now
                        </div> -->
                        <!-- <div class="row mt-2 get_started_subheading">
                            Enter credentials to login into your account
                        </div> -->
                        <div class="row mt-3">
                            <form>
                                <div *ngIf="showQR">
                                    <div>Please scan this code to setup MFA</div>
                                    <qrcode [qrdata]="qrValue" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                                </div>
                                <div class="form-input">
                                    <div class="row login_label mb-4">Enter Verification Code</div>
                                    <div class="row">
                                        <input class="login_input pl-2 w-50" type="text" [(ngModel)]="mfa_answer"
                                            name="verificationCode" autocomplete="off" required />
                                    </div>
                                    <div class="form-input row error_msg mt-2 mb-2">{{ mfaMessage }}</div>
                                </div>
                                <div class="row mt-3">
                                    <button type="submit" class="btn btn-primary verify_mfa_button w-25"
                                        (click)="verifyMFA()">
                                        Verify MFA
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Forgot Password -->
                    <div class="card-body" [hidden]='!forgotMode'>
                        <div class="row get_started">
                            Forgot your password?
                        </div>
                        <div class="row mt-2 get_started_subheading">
                            Please provide your registered email to get the instructions to reset your password
                        </div>
                        <div class="row mt-3">
                            <form #f="ngForm">
                                <div class="form-input">
                                    <label class="login_label">Email</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/email_prefix.svg" />
                                        <input class="login_input pl-5" type="email" [(ngModel)]='forgot_email'
                                            placeholder="Enter your email" name="forgotEmail" required />
                                    </div>
                                </div>

                                <div class="form-input row error_msg mt-2 mb-2">
                                    {{forgotErrorMessage}}
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button type="submit" class="login_button btn btn-primary"
                                            (click)="sendVerificationCode()">
                                            Send Verification Link
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-12 d-flex justify-content-center">
                                        <a class="back_to_login pl-1" href="javascript:void(0)"
                                            (click)="showLogin()">Back to login</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Reset Password -->
                    <div class="card-body" [hidden]='!resetMode'>
                        <div class="row get_started">
                            Email Sent successfully!!
                        </div>
                        <div class="row mt-2 get_started_subheading">
                            Two emails have been sent to you- one includes a verification code and the other contains a
                            reset link.
                        </div>
                        <div class="row mt-3">
                            <!-- <form #f="ngForm">
                                <div class="form-input">
                                    <label class="login_label">Enter Verification Code</label>
                                    <div class="input-icons d-flex">
                                        <input class="login_input" type="text" [(ngModel)]='verification_code'
                                            placeholder="Enter verification code" name="verificationCode" required />
                                    </div>
                                </div>

                                <div class="form-input mt-3">
                                    <label class="login_label">New Password</label>
                                    <div class="input-icons d-flex">
                                        <img class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5" type="{{ showPassword ? 'text' : 'password' }}"
                                            [(ngModel)]='new_update_password' placeholder="Enter new password"
                                            name="newPassword" required />
                                        <img (click)="togglePasswordVisibility()" class="iconLogin"
                                            [src]="!showPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'"
                                            style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class="form-input row error_msg mt-2 mb-2">
                                    {{updatePasswordMessage}}
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button type="submit" class="login_button btn btn-primary"
                                            (click)="updatePassword()">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-12 d-flex justify-content-center">
                                        <a class="back_to_login pl-1" href="javascript:void(0)"
                                            (click)="showLogin()">Back to login</a>
                                    </div>
                                </div>
                            </form> -->
                        </div>
                    </div>
                    <!-- Reset Password -->
                    <div class="card-body" [hidden]='!isResetPasswordSuccess'>
                        <div class="row get_started">
                            Success!!
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">Your Password has been reset successfully.</div>
                            <div class="col-12 mt-3" style="color: blue;">Redirecting back to Login Screen...</div>
                        </div>
                    </div>
                    <!-- First Time Reset Password -->
                    <div class="card-body" [hidden]='!firstTimeReset'>
                        <div class="row get_started">
                            Reset password
                        </div>
                        <div class="row mt-2 get_started_subheading">
                            Create new password to your account.
                        </div>
                        <div class="row mt-3">
                            <form #f="ngForm">
                                <div class="form-input mt-3">
                                    <label class="login_label">New Password</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5" type="{{ showPassword ? 'text' : 'password' }}"
                                            [(ngModel)]='new_password' placeholder="Enter new password"
                                            name="newPassword" required />
                                        <img draggable="false" (click)="togglePasswordVisibility()" class="iconLogin"
                                            [src]="!showPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'"
                                            style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class="form-input mt-3">
                                    <label class="login_label">Confirm New Password</label>
                                    <div class="input-icons d-flex">
                                        <img draggable="false" class="iconLogin" src="../../../../assets/password_login.svg" />
                                        <input class="login_input pl-5"
                                            type="{{ showConfirmPassword ? 'text' : 'password' }}"
                                            [(ngModel)]='confirm_password' placeholder="Enter confirm password"
                                            name="confirmPassword" required />
                                        <img draggable="false" (click)="toggleConfirmPasswordVisibility()" class="iconLogin"
                                            [src]="!showPassword ? '../../../../assets/eye.svg' : '../../../../assets/eyeoff_login.svg'"
                                            style="position: absolute; right: 0" />
                                    </div>
                                </div>

                                <div class=" form-input row error_msg mt-2 mb-2">
                                    {{messageResetPassword}}
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button type="submit" class="login_button btn btn-primary"
                                            (click)="resetPassword()">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-12 d-flex justify-content-center">
                                        <a class="back_to_login pl-1" href="javascript:void(0)"
                                            (click)="showLogin()">Back to login</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FOOTER -->
            <div class="row d-flex justify-content-center footer">
                <div class="mb-2">Copyright © 2024 All Rights Reserved by <b>Data Unveil</b></div>
            </div>
        </div>
        <!-- Login Side Image -->
        <!-- <div class="du_login_img col-lg-6 col-xs-12 col-sm-12 col-md-6"> -->
        <!-- <img src="../../../../assets/loginPageBg.svg" style="height: 100vh;" /> -->
        <!-- </div> -->
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6">
            <img draggable="false" class="du_login_newIMG" *ngFor="let image of images; let i = index" [src]="image" alt="Slide {{ i }}"
                [@fadeAnimation]="currentImageIndex === i" [style.z-index]="currentImageIndex === i ? 1 : 0" />
            <!-- <img src="../../../../assets/loginPageBg.svg" style="height: 100vh;" /> -->
        </div>
    </div>
</div>