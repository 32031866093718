import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ConstantsService } from './constants.service';
import { Auth } from 'aws-amplify';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  timedOut: boolean = false;
  idleState = 'Not started.';
  
  constructor(
    private idle: Idle,
    private constantsService: ConstantsService,
    private sessionService: SessionService
  ) {}

  checkForInactivity() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(this.constantsService.USER_INACTIVITY_TIMEOUT * 60);
    this.idle.setTimeout(this.constantsService.USER_INACTIVITY_WARNING_TIMEOUT_IN_SECOND);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
        // console.log(this.idleState)
        this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        // console.log(this.idleState)
        this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
        this.idleState = "You've gone idle!";
        // console.log(this.idleState)
    });

    this.idle.onTimeoutWarning.subscribe((countdown:any) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
        // console.log(this.idleState)
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    // console.log(this.idleState)
    this.timedOut = false;
  }

  logout() {
    Auth.signOut();

    this.logSession();

    sessionStorage.clear();
    this.sessionService.setUser(null);
    this.sessionService.setUsername(null);
    this.sessionService.setLoginTime(null);

    // window.location.href = '/';
    window.location.href = "/timedout";
  }

  logSession() {
    return new Promise((resolve, reject) => {
        if (this.sessionService.getLogSession() && this.sessionService.getLogSession().session_id) {
            let username = this.sessionService.getUsername();
            let logData = {
                user_id: username,
                log_typ: this.constantsService.ACTION_TYPE_LOGOUT,
                session_id: this.sessionService.getLogSession().session_id,
            };
            // this.logService.log(logData).subscribe(
            //     (response:any) => {
            //         resolve(null);
            //     },
            //     () => {
            //         // ignore log errors
            //         resolve(null);
            //     }
            // );
        }
    });
  }
}
