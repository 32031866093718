import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import moment from "moment";
import { Subscription, debounceTime } from "rxjs";
import { EnhancementService } from "src/app/services/enhancements-service";
import { FilterService } from "src/app/services/filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";

@Component({
  selector: "app-quarter-selection",
  templateUrl: "./quarter-selection.component.html",
  styleUrls: ["./quarter-selection.component.scss"],
})
export class QuarterSelectionComponent extends FieldType implements OnInit, OnDestroy {

  dynamicSelect= new FormControl();
  opt: any
  valueProp = "_id";
  labelProp = "quarter,year";
  label:any;
  currentField:any;
  parentKey:any="";
  placeholder:any
  dBDefaultData:any

  reqSubcription: Subscription[] = [];
  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;

  yearFromDefault=new Date().getFullYear()
  currentYear=new Date().getFullYear()
  currentMonth:any;

  quarterFromYear:any;
  quarterFromMonth:any;
  selectionMethod:any;

  onLoadDefault: any;
  onLoadDefaultYear: any;
  onLoadDefaultYearQuarter: any;

  selectedFromOption:any;

  constructor(private enhancementService: EnhancementService, private filterService:FilterService, private userActivityService:UserActivityService, private cdr: ChangeDetectorRef) {
    super()
  }

  fromOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];
  
  ngOnInit(): void {
    // reset function
    this.filterService.formReset.subscribe((res: any) => {
      this.selectedFromOption = this.model[this.key as string]
      this.filterService.selectedItem['quarter'][this.key as string] = this.selectedFromOption
      // this.filterService.selectedItem['quarterTo'][this.key as string] = this.selectedOption
      if(this.currentField.parentKey == "") { 
        this.field.formControl?.setValue(this.selectedFromOption);
      }
      if(!this.selectedFromOption) this.selectedFromOption = {};this.selectedFromOption['quarter,year'] = 'Q0 0000' 
      // this.filterService.setBadgeValue(this.key as string, 1, this.opt.options.length, this.selectedFromOption, 'quarterSelection')
    })

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    this.currentMonth = monthNames[currentMonthIndex];

    this.opt = this.field.templateOptions || {};
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl

    this.quarterFromYear = this.opt.quarterYear
    this.quarterFromMonth = this.opt.quarterMonth
    this.selectionMethod = this.opt.selectionMethod

    this.onLoadDefault = this.opt.onLoadDefault
    this.onLoadDefaultYear = this.opt.onLoadDefaultYear
    this.onLoadDefaultYearQuarter = this.opt.onLoadDefaultYearQuarter

    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
        
        if (this.parentControlSubscription) {
          this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
        }

        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];

          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }
          
          if(this.fndParent()) {
            if (ds == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, val)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model)
    }

    this.selectedFromOption = this.fromOption[1]
    
  }

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }

  yearFrom!:number;
  showQuarter:boolean=true;
  year10yearFrom!:number;
  totalYear:any = [];
  quarterFromDefault:any
  
  // From Year Selection
  changeYear(year:any) {
    let fieldKey:any = this.key as string;

    this.yearFrom=year || this.yearFromDefault;
    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
    this.fromOption= [...this.disabledQuarter(this.fromOption)]
    this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.selectedFromOption.value)

    const data:any = sessionStorage.getItem('quarterFromForOnclose');
    const serializedData:any = data ? JSON.parse(data) : {};
    this.filterService.setBadgeValue(this.key as string, serializedData?.quarterFrom?.[fieldKey]?.length,serializedData?.quarterFrom?.[fieldKey].length, serializedData?.quarterFrom?.[fieldKey],'quarterSelection', '', this.labelProp)
  }

  changeShowQuarter(){
    this.showQuarter=!this.showQuarter
    if (!this.showQuarter)
      this.year10yearFrom=this.yearFrom?12*Math.floor(this.yearFrom/12):12*Math.floor(this.yearFromDefault/12)
  }

  onloadCurrentQuarter:any; 

  loadApiData(method:string, url:string,key:string,data:any) {
    // debugger
    if(this.opt.onLoadDefault=='api') {
      let api = {api_key: 10019709};

      this.filterService.executeQuery(Object.assign({}, api, data)).subscribe((res:any)=> {
        if(res && res[0]) {
          this.onloadCurrentQuarter = res[0];
        } else {
          this.onloadCurrentQuarter = {qtr_start_dt: new Date(), qtr_end_dt: new Date()}
        }
  
        this.loadData(method,url,key,data)
      }, err => {
        this.onloadCurrentQuarter = {qtr_start_dt: new Date(), qtr_end_dt: new Date()}
        this.loadData(method,url,key,data);
      })
    } else {
      this.onloadCurrentQuarter = {qtr_start_dt: new Date(), qtr_end_dt: new Date()};
      this.loadData(method,url,key,data);
    }
  }

  loadData(method:string, url:string,key:string,data:any) {
    this.reqSubcription.push(this.filterService.loadData(method,url,key,data).subscribe((data:any)=>{
      if(data!=null) {

        let selectOption:any;
  
        this.dBDefaultData = data ? data[0] ? data[0] : {}  : {}
        
        // database date or designer configuration date
        if(this.quarterFromYear == "db_start_year" || this.quarterFromMonth == "db_start_year") {
          this.yearFromDefault = new Date(data[0].launch_date).getFullYear();
          this.quarterFromDefault = data[0].quarter
        } else {
          this.yearFromDefault = new Date(parseInt(this.quarterFromYear), 0, 1).getFullYear();
          this.quarterFromDefault = this.quarterFromMonth;
        }
  
        // Page load or current date
        if(this.onLoadDefault == 'yes') {
          this.yearFrom = this.onLoadDefaultYear
          this.filterService.yearDefault = this.onLoadDefaultYear
          this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.onLoadDefaultYear)]
          this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.onLoadDefaultYear)]
          this.fromOption= [...this.disabledQuarter(this.fromOption)]
          selectOption = this.fromOption.find((res: any) => res.value == this.onLoadDefaultYearQuarter) 
        } else {
          
          if(this.onLoadDefault == 'api') {
            const dateLength = (new Date(this.onloadCurrentQuarter?.qtr_end_dt).getMonth()+1)
            let mathCeil = Math.ceil(dateLength);
            let quarter = 'Q'+mathCeil/3;

            this.yearFrom = new Date(this.onloadCurrentQuarter?.qtr_start_dt).getFullYear();
            this.filterService.yearDefault = new Date(this.onloadCurrentQuarter?.qtr_start_dt).getFullYear();

            this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
            this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.yearFrom)]
            this.fromOption= [...this.disabledQuarter(this.fromOption)]
            selectOption = this.fromOption.find((res: any) => res.value == quarter);

          } else {
            this.yearFrom = this.currentYear
            this.filterService.yearDefault = this.currentYear
            this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.currentYear)]
            this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.currentYear)]
            this.fromOption= [...this.disabledQuarter(this.fromOption)]
            selectOption = this.fromOption.find((res: any) => res.months?.toString().includes(this.currentMonth.toString()))
          }
        }
  
        // User state
        let userStateData: any = this.userActivityService.getData()
        let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
        let findData: any = updatesObj.find((o: any) => o.key == this.key as string)

        let fieldKey:any = this.key as string
        if(findData) {
          this.yearFrom = findData.value?.year
          this.filterService.yearDefault = this.yearFrom
          this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
          this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.yearFrom)]
          this.fromOption= [...this.disabledQuarter(this.fromOption)]
          this.selectedFromOption = this.fromOption.find((res: any) => res.value == findData.value?.value)

        } else {
          this.selectedFromOption = selectOption;
        }
        this.cdr.detectChanges();

        
        // Default Selected Data From and To date - reset to default purpose.  
        // this.filterService.allOptionData['quarter'][fieldKey] = selectOption
        this.filterService.selectedItem['quarter'][fieldKey] = this.selectedFromOption
        const defaultSelectedData:any = {'quarterFrom' : { [fieldKey] : selectOption }}
        const serializedData:any = JSON.stringify(defaultSelectedData);        
        sessionStorage.setItem('quarterFromAll', serializedData);


        // onClose for popup
        const defaultSelectedData1:any = {'quarterFrom' : { [fieldKey] : this.selectedFromOption }}
        const serializedData1:any = JSON.stringify(defaultSelectedData1);
        sessionStorage.setItem('quarterFromForOnclose', serializedData1);
        
        const isDbStartDateInRange = this.enhancementService.findCurrentQuarter(this.onloadCurrentQuarter, this.selectedFromOption);
        if(isDbStartDateInRange) {
          this.enhancementService.currentQuarterFlag = true;
        } else {
          this.enhancementService.currentQuarterFlag = false;
        }

        this.field.formControl?.setValue(this.selectedFromOption)
        this.filterService.setBadgeValue(this.key as string, this.selectedFromOption?.length,this.selectedFromOption?.length, this.selectedFromOption,'quarterSelection', '', this.labelProp)
  
        this.filterService.previousQuarterFromSelectedItems[fieldKey] = this.selectedFromOption

      }
    }))
  }

  applyFilter(ctrl:any) {
    let fieldKey:any = this.key as string

    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.yearFrom)]

    const isDbStartDateInRange = this.enhancementService.findCurrentQuarter(this.onloadCurrentQuarter, this.selectedFromOption);
    if(isDbStartDateInRange) {
      this.enhancementService.currentQuarterFlag = true;
    } else {
      this.enhancementService.currentQuarterFlag = false;
    }

    this.field.formControl?.setValue(this.selectedFromOption)
    this.filterService.setBadgeValue(this.key as string, this.selectedFromOption?.length,this.selectedFromOption?.length, this.selectedFromOption,'quarterSelection', '', this.labelProp)
    
      
    // onClose for popup
    const defaultSelectedData:any = {'quarterFrom' : { [fieldKey] : this.selectedFromOption }}
    const serializedData:any = JSON.stringify(defaultSelectedData);
    sessionStorage.setItem('quarterFromForOnclose', serializedData);


    this.filterService.previousQuarterFromSelectedItems[fieldKey] = this.selectedFromOption
    
    // User State
    let ab = { [this.key as string]: this.selectedFromOption }
    this.userActivityService.setData({ "selectedFilters": ab })

    sessionStorage.removeItem('userStateTime')
    // this.userActivityService.setDataTimeCycle({ "selectedFilters": {}})

    this.filterService.yearDefault = this.yearFrom
    this.closeCondition = true;
    this.filterService.selectedItem['quarter'][fieldKey] = this.selectedFromOption

    ctrl.close()
  }

  selectFromOption() {}

  closeCondition = false;

  onClose() {

    const data:any = sessionStorage.getItem('quarterFromForOnclose');
    let fieldKey:any = this.key as string;
    
    if(data) {
      const serializedData:any = JSON.parse(data);
      if(serializedData?.quarterFrom?.[fieldKey]?.['quarter,year'] != this.selectedFromOption['quarter,year']) {

        this.yearFrom = serializedData.quarterFrom?.[fieldKey]?.year
        this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
        this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
        this.fromOption= [...this.disabledQuarter(this.fromOption)]
        this.selectedFromOption = this.fromOption.find((res: any) => res.value == serializedData.quarterFrom?.[fieldKey]?.value)
      }
    }
  }

  addingQuarterwithYear(myData:any, year:any) {
    myData.forEach((res:any) => Object.assign(res, 
      {"quarter,year": res.value + ' ' + year}
    ));
    return myData
  }

  dateFormatQuarter(arrData:any, year:any) {
    arrData.forEach((res:any)=> {
      if(res.value == 'Q1') {
        Object.assign(res,
          {"from_date": year+'-'+'01'+'-'+'01'},
          {"to_date": year+'-'+'03'+'-'+'31'},{"year": year})
      } else if (res.value == 'Q2') {
        Object.assign(res,
          {"from_date": year+'-'+'04'+'-'+'01'},
          {"to_date": year+'-'+'06'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q3') {
        Object.assign(res,
          {"from_date": year+'-'+'07'+'-'+'01'},
          {"to_date": year+'-'+'09'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q4') {
        Object.assign(res,
          {"from_date": year+'-'+'10'+'-'+'01'},
          {"to_date": year+'-'+'12'+'-'+'31'},{"year": year})
      }
    })

    return arrData
  }

  disabledQuarter(myData:any) {

    let currentYearDefault = new Date().getFullYear();
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let currentMonthDefault = 'Jan';

    if(this.onloadCurrentQuarter) {
      const dateLength = new Date(this.onloadCurrentQuarter?.qtr_end_dt).getMonth()
      currentMonthDefault = monthNames[dateLength];
    } else {
      let currentDate = new Date();
      let currentMonthIndex = currentDate.getMonth();
      currentMonthDefault = monthNames[currentMonthIndex];
    }

    // 2023 > 2021 
    if(currentYearDefault>this.yearFrom) {
      //2021 < 2023 Or 2021 == 2021
      if(this.yearFromDefault < this.yearFrom) {
        myData.forEach((res:any)=> {
          Object.assign(res, {"disabled": false})
        })
      } else {
        if(this.yearFromDefault == this.yearFrom) {

          let onLoad = false;
          // If Q2
          myData.forEach((res:any)=> {
            if(res.value == this.quarterFromDefault) {
              onLoad = true
              Object.assign(res, {"disabled": false})
            } else {
              if(!onLoad) {
                Object.assign(res, {"disabled": true})
              } else {
                Object.assign(res, {"disabled": false})
              }
            }
          })

        }
        
      }

    // 2023 > 2023 OR Above
    } else {
      if(currentYearDefault==this.yearFrom) {
        let onLoad = false;
        // If Q2
        myData.forEach((res:any)=> {
          if(res.months?.toString().includes(currentMonthDefault.toString())) {
            onLoad = true
            Object.assign(res, {"disabled": false})
          } else {
            if(!onLoad) {
              Object.assign(res, {"disabled": false})
            } else {
              Object.assign(res, {"disabled": true})
            }
          }
        })

      } else {
        myData.forEach((res:any)=> {
          Object.assign(res, {"disabled": false})
        })
      }
    }

    return myData
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }
}
