import {Component, OnDestroy, OnInit} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { SessionStorage } from "angular-web-storage";
import { Subscription, debounceTime } from "rxjs";
import { FilterService } from "src/app/services/filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";

@Component({
  selector: "app-quarter-range-selection",
  templateUrl: "./quarter-range-selection.component.html",
  styleUrls: ["./quarter-range-selection.component.scss"],
})
export class QuarterRangeSelectionComponent extends FieldType implements OnInit , OnDestroy{

  dynamicSelect= new FormControl();
  opt: any
  valueProp = "_id";
  labelProp = "quarter,year";
  label:any;
  fromLabel:any;
  toLabel:any;
  currentField:any;
  parentKey:any="";
  placeholder:any
  dBDefaultData:any

  quarterFromDefault:any
  yearFromDefault=new Date().getFullYear()
  currentYear=new Date().getFullYear()
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  currentDate = new Date();
  currentMonth:any;

  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;

  quarterFromYear:any;
  quarterFromMonth:any;
  selectionMethod:any;

  onLoadDefault: any;
  onLoadDefaultYear: any;
  onLoadDefaultYearQuarter: any;

  selectedFromOption:any;
  selectedToOption:any;

  reqSubcription: Subscription[] = [];
  
  selectedRange:any

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());

    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }

  constructor(private filterService:FilterService, private userActivityService:UserActivityService) {
    super()
  }

  fromOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];

  toOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];
  
  ngOnInit(): void {
    // reset function
    this.filterService.formReset.subscribe((res: any) => {

      
        let model = this.model[this.key as string]
  
        this.selectedFromOption = Object.assign({}, model, {"quarter,year": model?.resetFrom})
        this.selectedToOption = Object.assign({}, model, {"quarter,year": model?.resetTo})
  
        let rangeSelection:any = {
          "quarter,year" : this.selectedFromOption['quarter,year'] + ' - ' + this.selectedToOption['quarter,year'],
          "to_date" : this.selectedToOption.to_date,
          "from_date" : this.selectedFromOption.from_date,
          "resetFrom" : this.selectedFromOption['quarter,year'],
          "resetTo" : this.selectedToOption['quarter,year'],
        }
        this.selectedRange = Object.assign({}, this.selectedFromOption, rangeSelection)
        
        this.filterService.selectedItem['quarterFrom'][this.key as string] = this.selectedFromOption
        this.filterService.selectedItem['quarterTo'][this.key as string] = this.selectedToOption
        
        if(this.currentField.parentKey == "") { 
          this.field.formControl?.setValue(this.selectedRange);
        }
        if(this.selectedRange['quarter,year'].includes('undefined')) this.selectedRange['quarter,year'] = 'Q0 0000 - Q0 0000'
        // this.filterService.setBadgeValue(this.key as string, this.selectedRange?.length,this.toOption.length, this.selectedRange,'quarterRangeSelection', '', this.labelProp)
    })

    this.opt = this.field.templateOptions || {};
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.fromLabel = this.opt.fromLabel
    this.toLabel = this.opt.toLabel
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl

    this.quarterFromYear = this.opt.quarterYear
    this.quarterFromMonth = this.opt.quarterMonth
    this.selectionMethod = this.opt.selectionMethod

    this.onLoadDefault = this.opt.onLoadDefault
    this.onLoadDefaultYear = this.opt.onLoadDefaultYear
    this.onLoadDefaultYearQuarter = this.opt.onLoadDefaultYearQuarter

    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);
        
        if (this.parentControlSubscription) {
          this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
        }

        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];

          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }
          
          if(this.fndParent()) {
            if (ds == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, val)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", ds, this.opt.parameter, this.model)
    }
    // this.selectedFromOption = this.fromOption[1]
    
  }

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }

  yearFrom!:number;
  yearTo!:number;
  showQuarter:boolean=true;
  showQuarterTo:boolean=true;
  year10yearFrom!:number;
  year10yearTo!:number;
  totalYear:any = []

  defaultSelectedFrom:any
  defaultSelectedTo:any
  // From Year Selection
  changeYear(year:any) {
    
    this.yearFrom=year || this.yearFromDefault;
    this.fromOption = [...this.changeYearWithQuarterFrom(this.fromOption)]
    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
    this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.selectedFromOption.value)
  }

  changeShowQuarter(){
    this.showQuarter=!this.showQuarter
    if (!this.showQuarter)
      this.year10yearFrom=this.yearFrom?12*Math.floor(this.yearFrom/12):12*Math.floor(this.yearFromDefault/12)
  }

  // From Year Selection
  changeYearTo(year:any) {
    
    this.yearTo=year || this.currentYear;
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
    this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
    this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
    this.selectedToOption = this.toOption.find((res:any)=> res.value==this.selectedToOption.value)
  }

  changeShowQuarterTo(){
    this.showQuarterTo=!this.showQuarterTo
    if (!this.showQuarterTo)
      this.year10yearTo=this.yearTo?12*Math.floor(this.yearTo/12):12*Math.floor(this.currentYear/12)
  }

  loadApiData(method:string, url:string,key:string,data:any) {
    this.reqSubcription.push(this.filterService.loadData(method,url,key,data).subscribe((data:any)=>{
      let selectFrom:any
      let selectTo:any

      this.dBDefaultData = data ? data[0] ? data[0] : {}  : {}
      if(this.quarterFromYear == "db_start_year" || this.quarterFromMonth == "db_start_year") {
        this.yearFromDefault = new Date(data[0].launch_date).getFullYear();
        this.quarterFromDefault = data[0].quarter
        
        this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFromDefault)]
        this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFromDefault)]
        // this.fromOption= [...this.disabledQuarter(this.fromOption,false,data[0].quarter)]
        selectFrom = this.fromOption.find((res: any) => res.value == this.dBDefaultData.quarter)
        this.defaultSelectedFrom = this.fromOption.find((res: any) => res.value == this.dBDefaultData.quarter)
      } else {
        this.yearFromDefault = new Date(parseInt(this.quarterFromYear), 0, 1).getFullYear();
        this.quarterFromDefault = this.quarterFromMonth
        
        this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFromDefault)]
        this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFromDefault)]
        // this.fromOption= [...this.disabledQuarter(this.fromOption,false,this.quarterFromMonth)]
        selectFrom = this.fromOption.find((res: any) => res.value == this.quarterFromMonth)
        this.defaultSelectedFrom = this.fromOption.find((res: any) => res.value == this.quarterFromMonth)
      }

      this.yearFrom = this.yearFromDefault
      this.filterService.yearDefault = this.yearFromDefault

      this.yearTo = this.currentYear
      this.filterService.yearToDefault = this.currentYear

      let currentMonthIndex = this.currentDate.getMonth();
      this.currentMonth = this.monthNames[currentMonthIndex];

      this.toOption= [...this.addingQuarterwithYear(this.toOption, this.currentYear)]
      this.toOption= [...this.dateFormatQuarter(this.toOption,this.currentYear)]
      selectTo = this.toOption.find((res:any)=> res.months?.toString().includes(this.currentMonth.toString()))
      this.defaultSelectedTo = this.toOption.find((res:any)=> res.months?.toString().includes(this.currentMonth.toString()))

      // From Quarter Default between gap should be disabled
      this.fromOption = this.defaultfromDisabled(this.fromOption)
      // to Quarter Default between gap should be disabled
      this.toOption = this.defaultToDisabled(this.toOption)

      let fieldKey:any = this.key as string

      // UserState
      let userStateData: any = this.userActivityService.getData()

      let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
      let findData: any = updatesObj.find((o: any) => o.key == this.key as string)

      if(findData) {
        // From
        if(findData.value?.from) {
          this.selectedFromOption = findData.value?.from
        } else {
          this.selectedFromOption = selectFrom
        } 

        // To
        if (findData.value?.to) {
          this.selectedToOption = findData.value?.to
        } else {
          this.selectedToOption = selectTo
        }
      } else {
        this.selectedFromOption = selectFrom
        this.selectedToOption = selectTo
      }

      let rangeSelection:any = {
        "quarter,year" : this.selectedFromOption['quarter,year'] + ' - ' + this.selectedToOption['quarter,year'],
        "to_date" : this.selectedToOption.to_date,
        "from_date" : this.selectedFromOption.from_date,
        "resetFrom" : this.selectedFromOption['quarter,year'],
        "resetTo" : this.selectedToOption['quarter,year'],
      }
      this.selectedRange = Object.assign({}, this.selectedFromOption, rangeSelection)
      
      this.field.formControl?.setValue(this.selectedRange)
      this.filterService.setBadgeValue(this.key as string, this.selectedRange?.length,this.toOption.length, this.selectedRange,'quarterRangeSelection', '', this.labelProp)
      
      this.filterService.previousQuarterFromSelectedItems[fieldKey] = this.selectedFromOption
      this.filterService.previousQuarterToSelectedItems[fieldKey] = this.selectedToOption

      // Default Selected Data From and To date - reset to default purpose.  
      const defaultSelectedData:any = [ 
        {'quarterFrom' : { [fieldKey] : this.defaultSelectedFrom }},
        {'quarterTo' : { [fieldKey] : this.defaultSelectedTo }}
      ]

      const serializedData:any = JSON.stringify(defaultSelectedData);
      sessionStorage.setItem('quarterFromAndTo', serializedData);
      sessionStorage.setItem('quarterFromAndToForOnclose', serializedData);

      // this.filterService.allOptionData['quarterFrom'][fieldKey] = this.defaultSelectedFrom
      // this.filterService.allOptionData['quarterTo'][fieldKey] = this.defaultSelectedTo

      this.filterService.selectedItem['quarterFrom'][fieldKey] = this.selectedFromOption
      this.filterService.selectedItem['quarterTo'][fieldKey] = this.selectedToOption

    }))
  }

  defaultfromDisabled(data:any) {
    let monFrom = false
    data.forEach((res:any)=> {
        if(res.value == this.quarterFromDefault) {
          monFrom = true
        } else {
          if(!monFrom) {
            Object.assign(res,
              {"disabled": true})
          }
        }
      })
      monFrom = false
      return data
  }
  defaultToDisabled(data:any) {
    let mon = false
    let currentMonthIndex = this.currentDate.getMonth();
    this.currentMonth = this.monthNames[currentMonthIndex];

    data.forEach((res:any)=> {
      if(res.months?.toString().includes(this.currentMonth.toString())) {
        mon = true
      } else {
        if(mon) {
          Object.assign(res,
            {"disabled": true})
        }
      }
    })
    mon = false
    return data
  }

  changeYearWithQuarterFrom(data:any) {

    let yearDefault = new Date().getFullYear();
    let monthDefault = new Date().getMonth();

    // 2021 <= 2021 or 2022 or 2023
    if(this.yearFrom <= this.selectedToOption.year) {

      // Default Year
      if(this.yearFromDefault == this.yearFrom) {
        let mon = false
        data.forEach((res:any)=> {
          if(res.value == this.quarterFromDefault) {
            Object.assign(res,{"disabled": false})
            mon = true
          } else {
            if(!mon) {
              Object.assign(res,{"disabled": true})
            } else {
              Object.assign(res,{"disabled": false})
            }
          }
        })
        mon = false
        
        // From Year to Selected To Year
      } else if (this.yearFrom == this.selectedToOption.year) {
        let mon = false
        data.forEach((res:any)=> {
          // Q2 == Q3
          if(res.value == this.selectedToOption.value) {
            Object.assign(res,{"disabled": false})
            mon = true
          } else {
            if(!mon) {
              Object.assign(res,{"disabled": false})
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
        })
        mon = false
        
      } else if (this.yearFrom < this.selectedToOption.year) {
        data.forEach((res:any)=> {
          Object.assign(res, {"disabled": false})
        })
      }

     // 2023 > 2022 
    } else if(this.yearFrom > this.selectedToOption.year) {
      data.forEach((res:any)=> {
        Object.assign(res, {"disabled": true})
      })
    }

    return data
  }

  // year quarter change
  changeYearWithQuarterTo(data:any) {
    
    let yearToDefault = new Date().getFullYear();
    // let monthToDefault = new Date().getMonth();
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    let monthToDefault = monthNames[currentMonthIndex];

    //2023 >= 2023
    if(this.yearTo >= this.selectedFromOption.year) {

      // 2022 == 2022
      // when all year is same
      if(this.yearTo == this.selectedFromOption.year && this.yearTo == yearToDefault) {
        let date = false;
        let findCurMonth = false;

        data.forEach((res:any) => {
          // Q1 Q2 Q3 Q4
          if(res.months?.toString().includes(monthToDefault.toString())) {
            findCurMonth = true
            Object.assign(res,{"disabled": false})
          } else {
            if(!findCurMonth) {
              // != Q2
              if(res.value != this.selectedFromOption.value) {
                if(!date) {
                  Object.assign(res,{"disabled": true})
                } else {
                  Object.assign(res,{"disabled": false})
                }
              } else {
                Object.assign(res,{"disabled": false})
                date = true
              }
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
        });
        date = false;
        findCurMonth = false;

      // when Toyear and selected from year both are same 
      } else if(this.yearTo == this.selectedFromOption.year) {
        let dat = false
        data.forEach((res:any) => {
          if(res.value == this.selectedFromOption.value) {
            dat = true
            Object.assign(res,{"disabled": false})
          } else {
            if(!dat) {
              Object.assign(res,{"disabled": true})
            } else {
              Object.assign(res,{"disabled": false})
            }
          }
        });
        dat = false
        // greater than of selected year
      } else if (this.yearTo > this.selectedFromOption.year) {
        
        if(this.yearTo == yearToDefault) {
          let dat = false
          data.forEach((res:any) => {
            if(res.months?.toString().includes(monthToDefault.toString())) {
              dat = true
              Object.assign(res,{"disabled": false})
            } else {
              Object.assign(res,{"disabled": false})
              if(!dat) {
              } else {
                Object.assign(res,{"disabled": true})
              }
            }
          });
          dat = false
        } else {
          data.forEach((res:any)=> {
            Object.assign(res, {"disabled": false})
          })
        }

      }

    } else if(this.yearTo < this.selectedFromOption.year) {
      data.forEach((res:any)=> {
        Object.assign(res, {"disabled": true})
      })
    }

    return data
  }

  applyFilter(ctrl:any) {
    
    let fieldKey:any = this.key as string

    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption, this.yearFrom)]

    const defaultSelectedData:any = [ 
      {'quarterFrom' : { [fieldKey] : this.selectedFromOption }},
      {'quarterTo' : { [fieldKey] : this.selectedToOption }}
    ]
    const serializedData:any = JSON.stringify(defaultSelectedData);
    sessionStorage.setItem('quarterFromAndToForOnclose', serializedData);

    let rangeSelection:any = {
      "quarter,year" : this.selectedFromOption['quarter,year'] + ' - ' + this.selectedToOption['quarter,year'],
      "to_date" : this.selectedToOption.to_date,
      "from_date" : this.selectedFromOption.from_date,
      "resetFrom" : this.selectedFromOption['quarter,year'],
      "resetTo" : this.selectedToOption['quarter,year'],
    }
    this.selectedRange = Object.assign({}, this.selectedFromOption, rangeSelection)
    
    this.field.formControl?.setValue(this.selectedRange)
    this.filterService.setBadgeValue(this.key as string, this.selectedRange?.length,this.toOption.length, this.selectedRange,'quarterRangeSelection', '', this.labelProp)

    
    this.filterService.previousQuarterFromSelectedItems[fieldKey] = this.selectedFromOption
    // this.filterService.previousQuarterToSelectedItems[fieldKey] = this.selectedToOption

    let ab = { [this.key as string]: { 'from' : this.selectedFromOption, 'to' : this.selectedToOption }}
    this.userActivityService.setData({ "selectedFilters": ab })

    this.filterService.yearDefault = this.yearFrom
    this.filterService.yearToDefault = this.yearTo

    this.filterService.selectedItem['quarterFrom'][fieldKey] = this.selectedFromOption
    this.filterService.selectedItem['quarterTo'][fieldKey] = this.selectedToOption

    
    ctrl.close()
  }
  applyToFilter(ctrl:any) {
    
    let fieldKey:any = this.key as string

    this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
    this.toOption= [...this.dateFormatQuarter(this.toOption, this.yearTo)]

    const defaultSelectedData:any = [ 
      {'quarterFrom' : { [fieldKey] : this.selectedFromOption }},
      {'quarterTo' : { [fieldKey] : this.selectedToOption }}
    ]
    const serializedData:any = JSON.stringify(defaultSelectedData);
    sessionStorage.setItem('quarterFromAndToForOnclose', serializedData);

    let rangeSelection:any = {
      "quarter,year" : this.selectedFromOption['quarter,year'] + ' - ' + this.selectedToOption['quarter,year'],
      "to_date" : this.selectedToOption.to_date,
      "from_date" : this.selectedFromOption.from_date,
      "resetFrom" : this.selectedFromOption['quarter,year'],
      "resetTo" : this.selectedToOption['quarter,year'],
    }
    this.selectedRange = Object.assign({}, this.selectedFromOption, rangeSelection)
    
    this.field.formControl?.setValue(this.selectedRange)
    this.filterService.setBadgeValue(this.key as string, this.selectedRange?.length,this.toOption.length, this.selectedRange,'quarterRangeSelection', '', this.labelProp)

    this.filterService.previousQuarterToSelectedItems[fieldKey] = this.selectedToOption

    let ab = { [this.key as string]: { 'from' : this.selectedFromOption, 'to' : this.selectedToOption }}
    this.userActivityService.setData({ "selectedFilters": ab })

    this.filterService.yearDefault = this.yearFrom
    this.filterService.yearToDefault = this.yearTo

    this.filterService.selectedItem['quarterTo'][fieldKey] = this.selectedToOption
    this.filterService.selectedItem['quarterFrom'][fieldKey] = this.selectedFromOption

    ctrl.close()
  }


  selectFromOption() {}

  closeCondition = false;

  onClose() {
    
    const data:any = sessionStorage.getItem('quarterFromAndToForOnclose');
    let fieldKey:any = this.key as string;
    
    if(data) {
      const serializedData:any = JSON.parse(data);
      if(serializedData[0].quarterFrom?.[fieldKey]?.['quarter,year'] != this.selectedFromOption['quarter,year']) {

        this.yearFrom = serializedData[0].quarterFrom?.[fieldKey]?.year
        this.fromOption= [...this.changeYearWithQuarterFrom(this.fromOption)]
        this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
        this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
        this.selectedFromOption = this.fromOption.find((res: any) => res.value == serializedData[0].quarterFrom?.[fieldKey]?.value)
      }
    }
  }

  onCloseTo() {

    const data:any = sessionStorage.getItem('quarterFromAndToForOnclose');
    let fieldKey:any = this.key as string;
    
    if(data) {
      const serializedData:any = JSON.parse(data);
      if(serializedData[1].quarterTo?.[fieldKey]?.['quarter,year'] != this.selectedToOption['quarter,year']) {
      
        this.yearTo = serializedData[1].quarterTo?.[fieldKey]?.year
        this.toOption= [...this.changeYearWithQuarterTo(this.toOption)]
        this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
        this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
        this.selectedToOption = this.toOption.find((res: any) => res.value == serializedData[1].quarterTo?.[fieldKey]?.value)
      }
    }
  }

  onOpenTo() {
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
  }
  
  onOpen() {
    this.fromOption= [...this.changeYearWithQuarterFrom(this.fromOption)]
  }
 
  addingQuarterwithYear(myData:any, year:any) {
    myData.forEach((res:any) => Object.assign(res, 
      {"quarter,year": res.value + ' ' + year}
    ));
    return myData
  }

  dateFormatQuarter(arrData:any, year:any) {
    arrData.forEach((res:any)=> {
      if(res.value == 'Q1') {
        Object.assign(res,
          {"from_date": year+'-'+'01'+'-'+'01'},
          {"to_date": year+'-'+'03'+'-'+'31'},{"year": year})
      } else if (res.value == 'Q2') {
        Object.assign(res,
          {"from_date": year+'-'+'04'+'-'+'01'},
          {"to_date": year+'-'+'06'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q3') {
        Object.assign(res,
          {"from_date": year+'-'+'07'+'-'+'01'},
          {"to_date": year+'-'+'09'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q4') {
        Object.assign(res,
          {"from_date": year+'-'+'10'+'-'+'01'},
          {"to_date": year+'-'+'12'+'-'+'31'},{"year": year})
      }
    })

    return arrData
  }

  disabledQuarter(myData:any, onLoad:any, quarter:any) {
    let currentYearDefault = new Date().getFullYear();
    // let currentMonthDefault = new Date().getMonth();

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    let currentMonthDefault = monthNames[currentMonthIndex];

    
    if(this.yearFromDefault<this.yearFrom && this.yearFrom<currentYearDefault) {
      myData.forEach((res:any)=> {
        Object.assign(res, {"disabled": false})
      })
    } else {
        if(this.yearFromDefault>=this.yearFrom) {
          myData.forEach((res:any)=> {
            if(res.value != quarter) {
              if(!onLoad) {
                Object.assign(res, {"disabled": true})
              }
            } else {
              onLoad = true
              Object.assign(res, {"disabled": false})
            }
          })
        } else if(this.yearFrom>=currentYearDefault) {
          myData.forEach((res:any)=> {
            if(res.months?.toString().includes(currentMonthDefault.toString())) {
              onLoad = true
              Object.assign(res, {"disabled": false})
            } else {
              if(onLoad) {
                Object.assign(res, {"disabled": true})
              }
            }
          })
        } else {
          myData.forEach((res:any)=> {
            Object.assign(res, {"disabled": false})
          })
        }
    }
    return myData
  }
}
