import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import icons from '../../assets/json/icons.json';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import newicons from '../../assets/json/newicons.json';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  icon:any = {
    name:'settings'
  }
  iconList: any[] = icons
   newIconList: any[] =newicons
  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    this.getIcon();
    
  }

  getIcon() {
    this.iconList.forEach((icon: any) => {
      this.matIconRegistry.addSvgIcon(icon.name, this.setPath(`assets/svgIcons/${icon.value}`));
    });

    this.newIconList.forEach((icon: any) => {
      this.matIconRegistry.addSvgIcon(icon.name, this.setPath(`assets/svgIcons/${icon.value}`));
    });
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
