import * as _ from 'lodash'
import * as d3 from 'd3'

const timeParser = d3.timeParse('%d-%m-%y')
const timeFormatter = d3.timeFormat('%d %b')
const timeFormatter2 = d3.timeFormat('%a')

export default {
  title: 'Dupixent 2w',
  data: {
    type: 'dupixent',
    url: 'data/status-overview/dupixent/data.json',
  },
  legend: {
    id: 'data-load-status-legend',
    data: {
      source: 'service',
    },
    view: {
      editable: true,
    },
  },
  statusOverview: {
    id: 'data-load-status-overview',
    type: 'StatusOverview',
    data: {
      type: 'series'
    },
    view: {
      aggregate4Ancestors: true,
      colorScheme: ['#D11044', '#FFC000', '#E8EAEE', '#3AC97C', '#537CCB'],
      itemAccessors: ['source_type', 'source_name', 'file_type'],
      itemsOrder: {
        source_type : [],
        file_type: []
      },
      xAccessor: (record: any) => +timeParser(record.cal_day)!,
      xFormatter: (value: any) => timeFormatter(value),
      xFormatter2: (value: any) => timeFormatter2(value),
      statusAccessor: 'file_status',
      cellValueAccessor: (record: any, acc: number = 0) => (
        record.file_status === 'Not Expected' || record.file_status === 'Missing'? '' : acc + record.no_of_records
      ),
      cellValueFormatter: (v: any) => _.isString(v) ? '' : d3.format(',')(v),
      detailsAccessor: (record: any, obj: any) => {
        if (!record.file_nm) return {}
        const index = (_.keys(obj).length + 1).toString()
        const file = obj[record.file_nm] || {
          index: `File ${_.padStart(index, 3, '0')}`,
          status: record.file_status,
          file_type_key:record.file_type_key,
          source_key:record.source_key,
          period:record.cal_day
        }
        obj[record.file_nm] = file
        file.count = {
          received: (file.no_of_records || 0) + Number(record.no_of_records),
          success: (file.success_record_count || 0) + Number(record.success_record_count),
          failed: (file.failure_record_count || 0) + Number(record.failure_record_count),
          errors: (file.error_record_count || 0) + Number(record.error_record_count),
          ignored: (file.records_ignored_count || 0) + Number(record.records_ignored_count),
        }
        return obj
      },
      badgeAccessor: (serie: any) => {
        return !!(serie.failure_record_count || serie.error_record_count|| serie.comment_flg == 'Y')
      },
      selectable: true,
      legend: 'data-load-status-legend',
      tree: 'data-load-status-tree',
      tooltip: 'data-load-status-tooltip',
    }
  },
  tree: {
    id: 'data-load-status-tree',
    type: 'Tree',
    data: {
      source: 'service',
    },
    view: {
      collapsedAll: true,
    }
  },
  tooltip: {
    id: 'data-load-status-tooltip',
    data: {
      source: 'service',
    },
    view: {
      template: 'custom',
    }
  }
}