
<div class="widget-container" #HBarCharDiv>
    <app-hbar-chart
    [heading]="heading"
    [headerConfig]="this.item.config?.widget_header_config"
    [config]="config"
    [data]="data"
    [height]="height.height"
    [item]="item"
    [pageKey]="pageKey"
    >
    </app-hbar-chart>
</div>
