import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-tooltip',
  template: `<div style=" font-size:12px;color:#fff ;padding:6px;background-color: #00000080;border-radius:8px;font-weight: 500;font-family:poppins;" [innerHTML]="tooltipHtml"></div>`,
})

export class CustomTooltipComponent {
  tooltipHtml: any;

  constructor(private sanitizer: DomSanitizer) {}

  agInit(params: any): void {
    this.tooltipHtml = this.sanitizer.bypassSecurityTrustHtml(params.value);
  }
}
