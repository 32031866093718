import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserActivityService } from '../../../services/useractivity.service';

@Component({
  selector: 'app-ag-record-details',
  templateUrl: './ag-record-details.component.html',
  styleUrls: ['./ag-record-details.component.scss']
})
export class AgRecordDetailsComponent implements OnInit {

  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardName: any;
  cardId: any;
  parentgroup: any = [];
  params: any
  defaultValue: any;
  autoGroup = false;
  moment = moment;
  paramValue:any;
  moduleName:any;
  tab_id:any;

  constructor(
    public filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private userState: UserActivityService
  ) {

    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      
      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        // this.clientKey = decryptedParams["tab_id"];
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
      });
    });
  }

  ngOnInit(): void {}

  agInit(params: ICellRendererParams): void {
    // debugger
    this.params = params
    this.defaultValue = this.params.value ? this.params.type == 'date' ? moment(this.params.value).format("MM/DD/YYYY") : this.params.value : ''
    this.autoGroup = this.params['from'] == 'parent' ? true : false;

    if (!this.params?.node?.['field']) {
      this.params.node['field'] = this.params.column.colId;
    }
    if (!this.params?.node?.['key']) {
      this.params.node['key'] = this.params.data[this.params.column.colId];
    }
  }

  errRecord() {

    // Set Navigations
    let item = this.params?.item;
    let arrEmpty:any = {};

    if(item?.['config']?.nav_configuration) {
      const config = item?.['config']?.nav_configuration?.['controls'][0];
      let nav = {
        dbJSON: config?.dbJSON,
        s3JSON: config?.s3JSON,
        pageName: config?.pageName,
        isDashboardLevel: config?.isDashboardLevel
      }
      sessionStorage.setItem("navDetailInfo", JSON.stringify(nav));

      if(config?.customPayloadKey) {
        arrEmpty[config?.customPayloadKey] = config?.customPayloadValue;
      }
      
      // Payload Passing Data
      if(config?.payloadData) {
        let pyldDta = config?.payloadData.split(',');
        pyldDta.forEach((m:any)=> {
          arrEmpty[m] = this.params?.data[m];
        });
      }
    }

    // Data Mapping
    this.parentgroup = [];
   
    if (this.autoGroup) {
      this.parentgroup = [...this.getGroupedParentData(this.params.node, this.params.node.level)];
    } else {
      this.parentgroup = this.params['filter'];
      this.parentgroup.push(...this.params['detailFilterKey']);
      this.parentgroup.forEach((res: any) => {
        Object.assign(res, { [res.key]: this.params?.data[res.key] });
      });
    }   
    
    let fndBucket = this.parentgroup.find((o:any)=> o.key=='bucket');
    if(fndBucket) arrEmpty['bucket_desc'] = fndBucket[fndBucket.key];
    
    // IF Error and Delay Bucket Key
    if(this.params['data']?.delay_bucket_key) arrEmpty['delay_bucket_key'] = this.params['data']?.delay_bucket_key;
    if(this.params['data']?.error_bucket_key) arrEmpty['error_bucket_key'] = this.params['data']?.error_bucket_key;
    
    // Outstanding Defect Navigations
    let data:any = this.parentgroup.find((f:any)=>f.key=="file_type_name")
    let configData: any = {
      report_key: ((data && data.key=='file_type_name') ||(this.params.node.field=='file_type_name'))? true:false,
      api_key:  ((data && data.key=='file_type_name') ||(this.params.node.field=='file_type_name'))? 100250:100253
    }
    // console.log(configData)
    this.userState.setData({'detailConfig':configData})

    // User State for Hirarical Grid
    sessionStorage.setItem('treeStateHierarical', JSON.stringify(this.parentgroup));
    
    if(this.parentgroup.length>0) sessionStorage.setItem("filterData", JSON.stringify(this.parentgroup));
    else sessionStorage.removeItem("filterData");
    
    if(Object.keys(arrEmpty).length>0) sessionStorage.setItem('payloadData', JSON.stringify(arrEmpty));
    else sessionStorage.removeItem('payloadData');

    // this.filterService.columnClickedOnGrid.next(this.parentgroup);
    
    let query:any = {
      tab_id: 0,
      cl_key: this.clientKey,
      md_key: this.moduleKey,
      ds_key: this.dashboardKey,
      record: 'yes'
    }
  
    setTimeout(() => {
      if(this.params?.linkingPageSelection && this.params?.linkingPageSelection?.toString().toLowerCase()=='tab') {
        sessionStorage.setItem("expandGridState", JSON.stringify(this.parentgroup));
        query['tab_id'] = (Number(this.params.tabCount) - 1);
        this.navigationService.routerNavigate(`dashboard/${this.moduleName}/page`, query);
      } else {
        if(this.params['type']=='date') {
          const newdate = this.params.value.replace(/\s/g, '');
          this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${moment(newdate).format("MM-DD-YYYY")}`, query);
        } else {
          this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'record'}`, query);
        }
      }
    }, 200);
  }

  getGroupedParentData(node: any, level: number) {
    let newGroup = [];
    for (let index = 0; index <= level; index++) {

      let fnd = this.params.columnList.find((o: any) => o['api_resp_column_nm'] == node.field)

      if (fnd) {
        let value = {
          key: node.field,
          [node.field]: node.key,
          filterKey: fnd.filterKey
        }
        newGroup.push(value);
      }
      node = node.parent;
    }
    return newGroup;
  }
}
