<div class="widget-container" #IrHeatmapDiv>
  <main>
    <article #fs fullscreen-able [ngClass]="{ 'is-active': isActive, 'fullscreen-padding': isActive}"
      style="background-color: white; overflow: auto;">
      <loading *ngIf="isLoading"> </loading>
      <div>
        <icon-chart [headerConfig]="item.config?.widget_header_config" [pageKey]="pageKey"
          [heading]="item.config['report_heading']" [chartName]="'IRSummery'" [config]="heatMapContainer" [expand]="fs"
          [fullscreen]="isFullscreen" [item]="item" [data]="data"
          (screenChanges)="fullscreenChanges($event)"></icon-chart>
      </div>
      <div [style.display]="noData?'none':''" #heatMapContainer>
        <div>
          <app-new-heatmap-chart (gridData)="getgridData($event)" [data]="data" [config]="config"
            [selectedValue]="selectedValue"></app-new-heatmap-chart>
        </div>
        <div class="mb-3 mt-2"><hr></div>
        <div style="display: flex;justify-content: space-between;">
        <div>
          <span style="font-family: Poppins;font-size: 20px;font-weight: 600;line-height: 36px;letter-spacing: 0em;text-align: left; margin-left: 7px;
                ">{{ gridConfig.pivot? gridConfig.selectedValue+' - All Weeks' :'Summary - '+
            (gridConfig.selectedValue | date: 'dd MMM yyyy') }}</span>
          
        </div>
        <mat-icon matTooltip="Export List"  class="exportic" svgIcon="dotmenu_circle" [matMenuTriggerFor]="menu"></mat-icon>
      </div>
      <pivot-grid #PivotGrid *ngIf="isDataLoaded" [dateFormat]="dateFormat" [data]="gridData" [item]="item"
            [columnDef]="columnDefs" pagination="false" [pivot]="pivot" ></pivot-grid>
      </div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
        <span class="bg-text">There is no data for this time period</span>
      </div>
      <mat-menu #menu="matMenu" >
        <button mat-menu-item  (click)="exportXls()"><mat-icon svgIcon="xls" ></mat-icon>Export to Excel</button>
      </mat-menu>

    </article>
  </main>
</div>