import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { FilterService } from '../../../../services/filter.service';

@Component({
  selector: 'app-new-heatmap-chart',
  templateUrl: './new-heatmap-chart.component.html',
  styleUrls: ['./new-heatmap-chart.component.scss']
})
export class NewHeatmapChartComponent implements OnInit, OnChanges {
  // @ViewChild('heatmapContainer', { static: true }) heatmapContainer: ElementRef;
  divId: string = 'heatmapContainer'
  constructor(private currency: CurrencyPipe,private filterService :FilterService) { }
  list: any = []
  @Input('data') data: any;
  @Input('config') config: any;
  @Input('selectedValue') selectedValue: any;
  initiateChart: boolean = false;
  tooltipVisible: any = false;
  mytooltipData: any;
  dataTurn: any;
  scrollLeft: any = 0;
  scrollWidth: any = 0
  scrollRight: any = 0
  Right: boolean = false
  Left: boolean = false
  myX: any;
  myY: any;
  @Output() gridData = new EventEmitter();
  selectedRows: Set<number> = new Set();
numberRange:any
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.["data"]?.currentValue != changes?.["data"]?.previousValue && this.initiateChart) {
      this.selectedRows.clear()
      this.plotChart()
    }
    if (changes?.["selectedValue"]?.currentValue != changes?.["selectedValue"]?.previousValue && this.initiateChart) {
      this.selectedRows.clear()
      this.plotChart()
    }
  }
  ngOnInit() {
    this.initiateCharts()
    // this.plotChart()
  }
  ngAfterViewInit(): void {
    this.scrollLeft = 0
    this.scrollWidth = 0
    this.scrollRight = 0
    document.getElementById('heatmapContainer')?.addEventListener("scroll", (event: any) => {
      this.scrollLeft = event?.target?.scrollLeft
      this.scrollWidth = event?.target?.scrollWidth
      this.scrollRight = this.scrollWidth - this.scrollLeft
      if (this.scrollLeft == 0) {
        this.scrollRight = 0
        this.scrollWidth = 0
      }
    })
  }



  showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {

    // console.log(myType,this.scrollTop,'type')
    this.mytooltipData = myData
    this.dataTurn = 0
    const chartWidths: any = document.getElementById('heatmapContainer')?.clientWidth
    this.dataTurn = chartWidths - myX
    this.tooltipVisible = true
    this.myX = myX
    this.myY = myY
    if (this.dataTurn < 250) {
      this.Right = true
      this.Left = false
      d3.select("#ItSummeryTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY + 60) + 'px')
        .style('right', (this.dataTurn + 30) + this.scrollLeft + 'px')
        .style('left', 'unset')
        .style('bottom', 'unset')
    }
    else if (this.dataTurn > 250) {
      this.Right = false
      this.Left = true
      d3.select("#ItSummeryTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY + 60) + 'px')
        .style('left', (myX + 30) - this.scrollLeft + 'px')
        .style('right', 'unset')
        .style('bottom', 'unset')
    }
  }
  hideTooltip(visible: any): void {
    setTimeout(() => {
      this.tooltipVisible = false
      d3.select("#ItSummeryTooltip")
        .style('visibility', 'hidden');
    }, 100)
  }
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;
    //legend
    const legendSvg = d3.select("#legendDiv")
      .append('svg')
      .attr('id', 'svg_legendDiv' + myClass)
      .attr("width", '400px')
      .style('background-color', 'white');

    d3.select("#Yaxis")
      .append('svg')
      .attr('id', 'svg_Yaxis' + myClass)
      .attr("width", '100%')
      .style('background-color', 'white');

    const Svg = d3.select("#" + myClass)
      .append("svg")
      .attr('id', 'svg_' + myClass)
      .attr("width", '100%')
      .style('background-color', 'white');

    this.initiateChart = true;
  }

  // legendValue:any = [{ name: "0-20%", color: "#3DC77B" }, { name: "20-40%", color: "#9CE4BD" },{ name: "40-60%", color: "#E3E3E3" }, { name:"60-80%", color: "#ECA1B5" }, { name: "80-100%", color: "#D01345" }]
  async plotChart() {

    const myChart = this;
    const myClass = myChart.divId;
    const legendSvg = d3.select('#svg_legendDiv' + myClass);
    const svgcontainer = d3.select('#svg_' + myClass);
    const Yaxisxcontainer = d3.select('#svg_Yaxis' + myClass);
    const MaxValue: any = d3.max(this.data, (m: any) => Math.max(m['dispense_qty'], m['inventory_qty']));
    const MinValue: any = d3.min(this.data, (m: any) => Math.min(m['dispense_qty'], m['inventory_qty'], m['qty_difference']));
    // console.log(MinValue);

    const result = splitNumberIntoRanges(MaxValue, 3);
    const XaxisVar = myChart.config[myChart.selectedValue].XaxisVar; //"period"
    const YaxisVar = myChart.config[myChart.selectedValue].YaxisVar; //"source_name" 
    const valueVar = myChart.config[myChart.selectedValue].valueVar; //"qty_difference"
    const data = this.data //await fetch('assets/json/heatmap.json').then(a => a.json())
    const tickFormat: any = { "D": "%d %b %Y", "W": "%d %b %Y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y" };
    const xTickFormat: any = d3.timeFormat(tickFormat[this.filterService.report_type]);
    const colorStops: any = [0.00001, 0.2, 0.4, 0.6, 0.8, 1];
    const colorRange: any =myChart.selectedValue == 'percent'?["#3DC77B","#F6CFDA" ,"#ED9FB4","#E3708F","#DA4069","#D11044",]:["#1F10D1B2", "#1F10D180", "#1F10D14D", "#3DC77B", "#D0134526", "#ECA1B5", "#D01345"];
      let colorLabels: any = ["0%", "1 to 20%", "20 to 40%", "40 to 60%", "60 to 80%", "80 to 100%"];
    const dateColumnWidth = 90;
    if (myChart.selectedValue == 'value') { colorLabels = result }

    // Labels of row and columns -> unique identifier of the column called 'group' and 'variable'
    var myGroups: any = _.sortBy(d3.map(data, function (d: any) { return d[XaxisVar]; }));
    var myVars: any = d3.map(data, function (d: any) { return d[YaxisVar]; });
    // myVars = _.sortBy(myVars, (myVars: string) => myVars.toLowerCase());
    myVars = myVars.sort().reverse();
    myGroups = myGroups.reverse();


    // set the dimensions and margins of the graph
    var margin = { leftColumn: 0, top: 10, right: 25, bottom: 60, left: 40 };
    const mySvg: any = d3.select('#svg_' + myClass);
    legendSvg.attr("height", 20).attr("width", (dateColumnWidth * (myChart.selectedValue == 'percent'? 6: 7)));
    const periodlength: any = new Set(myGroups)//x-axis
    const sourcelength: any = new Set(myVars)//y-axis
    const width: any = mySvg.node().getBoundingClientRect().width;
    const maxLength: any = d3.max(data, (str: any) => str[YaxisVar].length) || 1;
    margin.left = margin.left + (maxLength * 6)
    const widths = (25 * periodlength.size)
    const height = (20 * sourcelength.size)
    // const widths = width - margin.left - margin.right;
    // const height = 500 - margin.top - margin.bottom;
    // margin.leftColumn = (width - margin.left - margin.right) / 5.1

    drawLegend(colorRange)


    // Legend 
    function drawLegend(legendData: any) {

      const legendGroup = legendSvg
        .selectAll('.legendGroup')
        .data(legendData)
        .join(function (group: any): any {
          const enter = group.append('g').attr('class', 'legendGroup');
          enter.append('rect').attr('class', 'legendRect');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.select(".legendRect")
        .attr("y", 20 - 8)
        .attr("x", (d: any, i: any) => 3 + (i * dateColumnWidth) + (margin.leftColumn * 2))
        .attr("width", dateColumnWidth - 6)
        .attr("height", 6)
        .attr("fill", (d: any) => d);

      legendGroup.select(".legendLabel")
        .attr('fill', '#8A98AB')
        .attr('font-weight', '500')
        .attr('font-family', 'Poppins')
        .attr('font-size', '10')
        .attr('text-anchor', 'middle')
        .attr("y", 20 - 10)
        .attr("x", (d: any, i: any) => (i * dateColumnWidth) + (margin.leftColumn * 2) + (dateColumnWidth / 2))
        .text((d: any, i: any) => colorLabels[i])

    }

    // append the svg object to the body of the page
    svgcontainer.selectAll('g').remove()
    const svg = svgcontainer
      .attr("width", widths + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");


    // Build X scales and axis:
    var x: any = d3.scaleBand()
      .range([0, widths])
      .domain(myGroups)
      .padding(0.1);
    svg.append("g").classed('x-axis', true)
      .style("font-size", 10)
      .style("font-family", "Poppins")
      .style("font-weight", 500)
      .style("color", "#8A98AB")
      .style("cursor", "pointer")
      // .attr("transform", "translate(0," + (height) + ")")
      .attr("transform", "translate(-21," + (height + 25) + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .select(".domain").remove()
    svg.selectAll('.x-axis .tick text')
      .attr("transform", "rotate(-45)")
      .text((d: any) => xTickFormat(new Date(d)))
      // .call(wrap, 40)
      .on('mouseover', function (event: any, d: any) {
        // Increase font size on mouseover
        d3.select(this).style("font-weight", 600); // Adjust the font size as needed
      })
      .on('mouseout', function (event: any, d: any) {
        // Reset font size on mouseout
        d3.select(this).style("font-weight", 500); // Reset to the original font size
      })
      .on('click', function (event: any, d: any) {
        svg.selectAll('.x-axis .tick text').style("font-size", "10px").style("color", "#8A98AB");
        d3.select(this).style("font-size", "11px").style("color", "#1363DF");
        svg.selectAll('.x-axis .tick ')
          .each(function (d1, i) {
            const tickGroup = d3.select(this);
            if (periodlength.size == 1) { svg.selectAll('.y-axis .tick  rect').remove(), tickGroup.selectAll('rect').remove(); }
            if (d1 == d) {

              const selected: any = d1
              if (myChart.selectedRows.has(selected)) {
                tickGroup.selectAll('rect').remove();
                return
              }
              // Append a rectangle behind the text
              tickGroup.append('rect')
                .attr('x', (x.bandwidth() - 6) / 2)  // Adjust the x-coordinate based on your requirements
                .attr('y', -height - 25)  // Adjust the y-coordinate based on your requirements
                .attr('width', x.bandwidth() + 3)  // Adjust the width based on your requirements
                .attr('height', height)  // Adjust the height based on your requirements
                .attr("rx", 2.5) //radius
                .attr("ry", 2.5) //radius
                .style('fill', 'none')  // Set fill to none to make the rectangle transparent
                .style('stroke', '#1363DF')  // Set the border color
                .style('stroke-width', 2) // Set the border width 
                .style('stroke-dasharray', 4); // Set the border line style  


            } else {
              svg.selectAll('.y-axis .tick  rect').remove()
              tickGroup.selectAll('rect').remove();
            }
          }), handleCellClick(XaxisVar, d, false)
      })

    // Build Y scales and axis:
    var y: any = d3.scaleBand()
      .range([height, 0])
      .domain(myVars)
      .padding(0.1);
    svg.append("g").classed('y-axis', true)
      .style("font-size", 12)
      .style("font-family", "Poppins")
      .style("font-weight", 500)
      .style("color", "#101D42")
      .style("cursor", "pointer")
      .call(d3.axisLeft(y).tickSize(0))
      .select(".domain").remove()
    svg.selectAll('.y-axis .tick text')
      .on('mouseover', function (event: any, d: any) {
        // Increase font size on mouseover
        d3.select(this).style("font-size", "14px").style("font-weight", 600); // Adjust the font size as needed
      })
      .on('mouseout', function (event: any, d: any) {
        // Reset font size on mouseout
        d3.select(this).style("font-size", "12px").style("font-weight", 500); // Reset to the original font size
      })
      .on('click', function (event: any, d: any) {  // Select all tick elements and append text and a rectangle to each one
        svg.selectAll('.x-axis .tick text').style("font-size", "10px").style("color", "#8A98AB");
        svg.selectAll('.y-axis .tick ')
          .each(function (d1, i) {
            const tickGroup = d3.select(this);
            if (sourcelength.size == 1) { svg.selectAll('.x-axis .tick  rect').remove(), tickGroup.selectAll('rect').remove(); }
            if (d1 == d) {
              const selected: any = d1
              if (myChart.selectedRows.has(selected)) {
                tickGroup.selectAll('rect').remove();
                return
              }
              // Append a rectangle behind the text
              tickGroup.append('rect')
                .attr('x', -(margin.left - 2))  // Adjust the x-coordinate based on your requirements
                .attr('y', -(y.bandwidth() + 2) / 2)  // Adjust the y-coordinate based on your requirements
                .attr('width', widths + margin.left)  // Adjust the width based on your requirements
                .attr('height', y.bandwidth() + 2)  // Adjust the height based on your requirements
                .attr("rx", 2.5) //radius
                .attr("ry", 2.5) //radius
                .style('fill', 'none')  // Set fill to none to make the rectangle transparent
                .style('stroke', '#1363DF')  // Set the border color
                .style('stroke-width', 2) // Set the border width 
                .style('stroke-dasharray', 4); // Set the border line style  

              // Append the text element
              // tickGroup.append('text')
              //   .attr('x', -3)
              //   .attr('dy', '0.32em')
              //   .style('font-size', '14px')
              //   .style('font-weight', '500')
              //   .text('AltScripts');  // Set the text content dynamically if needed
            } else {
              svg.selectAll('.x-axis .tick  rect').remove()
              tickGroup.selectAll('rect').remove();
            }
          })

          , handleCellClick(YaxisVar, d, true)
      });

    // Build color scale
    var myColor = d3.scaleThreshold().domain(colorStops).range(colorRange);


    // create a tooltip
    var tooltip = d3.select("#" + this.divId)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    var mouseover = function (event: any, d: any) {
      // tooltip
      //   .style("opacity", 1)

      // d3.select(local)
      //   .style("stroke", "black")
      //   .style("opacity", 1)
    }
    function mousemove(d: any) {
      tooltip
        .html("The exact value of<br>this cell is: " + d.currentTarget.__data__[valueVar])
        .style("position", "absolute")
        .style("font-family", "Poppins")
        .style("left", (d.offsetX + 20) + "px")
        .style("top", (d.offsetY + 20) + "px")
    }
    var mouseleave = function (d: any) {

      // tooltip
      //   .style("opacity", 0)
      // d3.select(local)
      // .style("stroke", "none")
      // .style("opacity", 0.8)
    }
    // add the squares
    svg.selectAll()
      .data(data, function (d: any) { return d[XaxisVar] + ':' + d[YaxisVar]; })
      .enter()
      .append("rect")
      .attr("x", function (d: any) { return x(d[XaxisVar]) })
      .attr("y", function (d: any) { return y(d[YaxisVar]) })
      .attr("rx", 2.5)
      .attr("ry", 2.5)
      .attr("width", 0)//x.bandwidth() )
      .attr("height", 0)//y.bandwidth() )
      .attr('transform', `translate(${x.bandwidth() / 2}, ${y.bandwidth() / 2})`)
      .style("fill", function (d: any) { return (myChart.selectedValue == 'percent') ? myColor( (d[valueVar] / 100)) : myChart.valueColourRange(myChart.numberRange,d[valueVar])})
      .style("cursor", "default")
      // .style("stroke-width", 4)
      // .style("stroke", "none") 
      .on("mouseover", function (event, d) {
        d3.select(this)
          .style('stroke', '#1363DF') // Set the border color
          .style('stroke-width', 2) // Set the border width 
          .style('stroke-dasharray', 4);
        mouseover(event, d);
      })
      .on("mousemove", function (event, d) { myChart.showTooltip('myType', d, event.offsetX, event.offsetY, width) })
      .on("mouseleave", function (event, d) {
        d3.select(this)
          .style('stroke', 'none') // Remove the border on mouseleave
          .style('stroke-width', 0)
          , myChart.hideTooltip(false)
      })
      .style('opacity', .2)
      .transition()
      .duration(1200)
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .attr('transform', 'translate(0, 0)')
      .style('opacity', 1)



    // Function to handle cell click
    const handleCellClick = (axisVar: any, selectedRowData: any, pivot: boolean) => {
      const selectedRowIndex = selectedRowData;

      // Toggle selection of the row
      if (this.selectedRows.has(selectedRowIndex)) {
        this.selectedRows.delete(selectedRowIndex);
      } else {
        this.selectedRows.clear()
        this.selectedRows.add(selectedRowIndex);
      }

      // Update the styling based on selected rows
      updateRowSelection(axisVar);

      // Display the selected row data
      displaySelectedRowData(axisVar, pivot, selectedRowData);
    };

    // Function to update row selection styling
    const updateRowSelection = (axisVar: any) => {
      svg.selectAll('rect') //.attr("width", (d: any) => this.selectedRows.has(d[axisVar]) ?42: x.bandwidth()  )
        .classed('selected-row', (d: any) => this.selectedRows.has(d[axisVar]));

    };

    // Function to display selected row data
    const displaySelectedRowData = (axisVar: any, pivot: boolean, selectedValue: any) => {
      const selectedRowData = data.filter((d: any) => this.selectedRows.has(d[axisVar]));
      // console.log('Selected Row Data:', selectedRowData);
      // debugger
      let value = {
        selectedValue: selectedValue,
        data: selectedRowData,
        pivot: pivot
      }
      setTimeout(() => {

        this.gridData.emit(value);
      }, 100);
    };


    // // Add title to graph
    // svg.append("text")
    //       .attr("x", 0)
    //       .attr("y", -50)
    //       .attr("text-anchor", "left")
    //       .style("font-size", "22px")
    //       .text("A d3.js heatmap");

    // // Add subtitle to graph
    // svg.append("text")
    //       .attr("x", 0)
    //       .attr("y", -20)
    //       .attr("text-anchor", "left")
    //       .style("font-size", "14px")
    //       .style("fill", "grey")
    //       .style("max-width", 400)
    //       .text("A short description of the take-away message of this chart.");


    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function (): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 10;
        const dy = 22;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            if (line.length > 0) {
              tspan.text(line.join(' '));
              line = [word];
              if (word.trim().length > 0) {
                lineNumber += 1;
                tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

              }
            } else {
              line = [word]
            }
          }
        }
      });
    }

    function splitNumberIntoRanges(number: any, numberOfRanges: any) {
      let ranges = [];
      const Negranges = [];
      let rangesnumb = [];
      const negrangesnumb = [];
      const rangeSize = Math.ceil(number / numberOfRanges);

      for (let i = 0; i < numberOfRanges; i++) {
        const start = i * rangeSize + 1;
        const end = (i + 1) * rangeSize;
        if(i==0){
          negrangesnumb.push("-"+ end + ' /' + " -" + 0.1);
          rangesnumb.push(0.1 + ' / ' + end);
          ranges.push((0.1) + ' to ' + numberFormating(end));
          Negranges.push(("(-" + numberFormating(end) + ') to (' + "-" + (0.1)+")"));

        }else{
          negrangesnumb.push("-"+ end + ' /' + " -" + start);
          rangesnumb.push(start + ' / ' + end);
          ranges.push((numberFormating(start) + ' to ' + numberFormating(end)));
          Negranges.push(("(-" + numberFormating(end) + ') to (' + "-" + numberFormating(start)+")"));
        }
      }
      ranges.unshift("0");
      rangesnumb.unshift("0/0");
      ranges = [...Negranges.reverse(), ...ranges];
      myChart.numberRange=[...negrangesnumb.reverse(), ...rangesnumb];
      // ranges.unshift(Negranges.reverse()); 
      // console.log(myChart.numberRange)
      return ranges;
    }

    function numberFormating(value: any) {
      return d3.format(".2~s")(value)
    }
    handleCellClick(XaxisVar, myGroups[0], false)
    svg.selectAll('.x-axis .tick ').each(function (d1, i) {
      const tickGroup = d3.select(this);
      if (d1 == myGroups[0]) {

        // Append a rectangle behind the text
        tickGroup.append('rect')
          .attr('x', (x.bandwidth() - 6) / 2)  // Adjust the x-coordinate based on your requirements
          .attr('y', -height - 25)  // Adjust the y-coordinate based on your requirements
          .attr('width', x.bandwidth() + 3)  // Adjust the width based on your requirements
          .attr('height', height)  // Adjust the height based on your requirements
          .attr("rx", 2.5) //radius
          .attr("ry", 2.5) //radius
          .style('fill', 'none')  // Set fill to none to make the rectangle transparent
          .style('stroke', '#1363DF')  // Set the border color
          .style('stroke-width', 2) // Set the border width 
          .style('stroke-dasharray', 4) // Set the border line style  

        d3.select(this).style("font-size", "11px").style("color", "#1363DF");

      }
    })
  }

  valueColourRange(ranges: any, value: any) {
    const colorRange = ["#1F10D1B2", "#1F10D180", "#1F10D14D", "#3DC77B", "#D0134526", "#ECA1B5", "#D01345"];
   let colorIndex = 0;
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i].split('/');
      const start = parseFloat(range[0]);
      const end = parseFloat(range[1]);

      if (start <= value && value <= end) {
        colorIndex = i;
        break;
      }
    }
    const color = colorRange[colorIndex];
    return color
  }
  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, "", "", "1.0-2");
    //  return   this.numberpipe.transform(value)
  }

}