import {
  Overlay,
  OverlayContainer,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-prescriber-card",
  templateUrl: "./prescriber-card.component.html",
  styleUrls: ["./prescriber-card.component.scss"],
})
export class PrescriberCardComponent implements OnInit, AfterViewInit {
  static key = 100107;
  
  constructor(
    public filterService: FilterService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    public deviceDetectionService: DeviceDetectionService
  ) {
    // this.route.params.subscribe((p: any) => {
    //   let clientKey = p["clientKey"];
    //   let moduleKey = p["moduleKey"];
    //   let dashboardKey = p["dashboardKey"];
    //   this.cardName = p["cardName"];
    //   this.cardId = p["cardId"];

    //   if (this.cardName == "pwt") {
    //     this.route.queryParams.subscribe((params: any) => {
    //       this.cardId = params.presId;
    //     });
    //   }
    // });

    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
  }

  @ViewChild("myElement", { static: true }) myElement!: ElementRef;
  @ViewChild("myDiv", { static: true }) myDiv!: ElementRef;

  @Input("item") item: any = {};
  @Input("height") height: any = {};

  dynamicHeight: any;
  show = false;
  hiddenToggle = false;
  clientKey: any;
  cardName: any;
  cardId: any;
  presData: any;

  check = false;
  isPortrait: any;
  cardDisplay = false;

  reqSubcription: Subscription[] = [];

  ngOnInit(): void {}

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.cardDisplay = false;
    this.tryTest()
  }

  ngAfterViewInit() {
    this.tryTest()
  }

  tryTest() {
    this.cardDisplay = false;
    // if(!this.isIPad) {
      const styleTransform = window.getComputedStyle(this.height.el);
      setTimeout(() => {
        const particularStyle: any = styleTransform.getPropertyValue("transform");
        const parent: any = this.height.gridster?.el as HTMLElement;
        const element: any = this.myElement.nativeElement as HTMLElement;
  
        let extractedSubstring: string = "";
  
        const startIndex = particularStyle.indexOf("(") + 1; // Find the index of the opening parenthesis
        const endIndex = particularStyle.indexOf(")"); // Find the index of the closing parenthesis
  
        if (startIndex !== -1 && endIndex !== -1) {
          extractedSubstring = particularStyle.substring(startIndex, endIndex); // Extract the substring
        }
        let ab = extractedSubstring.replace(/\s/g, "");
        let split: any = ab.split(",").slice(-2);
        element.style.zIndex = "99";
        element.style.position = "absolute";
        element.style.padding = "1px";
        element.style.top = parseInt(split[1]) + 15 + "px";
        element.style.left = parseInt(split[0]) + 10 + "px";
        element.style.height = this.height.height + "px";
        element.style.width = this.height.width + "px";
        element.style.display = 'block';
  
        if (parent.firstElementChild !== element) {
          this.renderer.appendChild(parent, element);
        }     

        if(this.deviceDetectionService.isDesktop) {
          this.cardDisplay = true;
        } else {
          this.cardDisplay = false;
        }
        this.cdr.detectChanges();

        

      }, 1500);
    // } else {
    //   this.cardDisplay = false;
    //   this.cdr.detectChanges();

    // }
  }

  dataLoaded = false;

  loadApiData() {
    this.dataLoaded = false;
    
    let obj = {
      activity_since_days: this.filterService.sliderValue,
      // prescriber_id: this.cardId,
      report_typ: "D",
      api_key: "100116"
    }; 

    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      this.reqSubcription.push(
        this.filterService.frmExecutionQuery(obj).subscribe((res: any) => {
          let data: any = res ? res[0] : [];
          this.presData = Object.entries(data).map(([key, value]) => ({
            key,
            value,
          }));
          this.dataLoaded = true;
        })
      );
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
  showBtnForLes() {
    this.show = !this.show
    this.filterService.showLessAndMore.next(this.show)
  }
}
