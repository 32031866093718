import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SessionService } from 'src/app/services/session.service';
import { ClientModelService } from '../../shared/client-model/client-model.service';
import { FilterService } from 'src/app/services/filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { FormControl } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-body',
  templateUrl: './app-body.component.html',
  styleUrls: ['./app-body.component.scss'],
})
export class AppBodyComponent implements OnInit {
  
  userName: string = '';
  client: any;
  isPortrait!: boolean;
  isIpad: boolean = false;
  sideBarStacked:boolean = false;
  reqSubcription: Subscription[] = [];
  isExpanded = false;

  constructor(private dashboardService: DashboardService, 
    private sessionService: SessionService, 
    public clientModelService: ClientModelService,
    private filterService: FilterService,
    public deviceDetectionService: DeviceDetectionService,
    private route:Router) {
      this.reqSubcription.push(this.filterService.userMenuSideBar.subscribe((res:any)=> {
        this.isExpanded = res;
      }));
    }

  ngOnInit(): void {
    if(this.deviceDetectionService.isDesktop) {
      this.isIpad = false;
    } else {
      this.isIpad = true;
    }
    this.userName = this.sessionService.getUsername();
    this.client = this.clientModelService.getClientDetails();
  }

  selectModule(module:any){
    this.dashboardService.setModuleDetails(module);
    sessionStorage.setItem('module', JSON.stringify(module));
    this.route.navigate([`dashboard/${module.client_key}/${module.module_key}`])
    // return false
  }

  sidebarSizeEvent($event:any) {
    this.sideBarStacked = $event;
  }
}