<div class="widget-container" #clusterbarChartDiv>
    <app-cluster-bar-chart 
    [headerConfig]="this.item.config.widget_header_config"
    style="width: 100%;"
    [config]="config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
  
    >
    </app-cluster-bar-chart>
</div>
