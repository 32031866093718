<loading *ngIf="loader" type="kpi"></loading>
<div class="container widget-container">
  <div class="d-flex justify-content-center">
    <div class="donut-container" *ngIf="chartData">
      <app-donut-chart [data]="chartData"
          [donutWidth]="donutWidth"
          [dataColumn]="'data'"
          [colorColumn]="'color'"></app-donut-chart>
    </div>
    <div style="margin-block: auto; position: relative;text-align: left;"
        class="align-items-center mx-1 mx-md-2 mx-lg-4">
      <div style="display: inline-flex;font-family: 'Poppins';">
        <span class="Percentage">{{ headPercentage || '0%' }}</span>
        <span class="ratio">{{ headRatio || '(0/0)' }}</span>
      </div>
      <div class="headHead">
        <span class="heading"
            *ngIf="this.item.config"> {{ this.item.config['kpi_heading'] || 'KPI Heading' }}</span><br>
      </div>
      <div class="headHead">
        <span class="subheading">({{ subHeading }})</span>
      </div>
    </div>
    <div style="white-space: nowrap;text-align: left;font-family: 'Poppins';">
      <div class="" *ngFor="let i of data">
        <span [style.background-color]="i.color" class="dot"></span>
        <span style="font-family: 'Poppins';font-size: 12px;" class="pl-1">{{ i.name }}</span>:
        <span style="font-weight: bold;font-family: 'Poppins';font-size: 12px;">{{ i.value }}</span>
      </div>
    </div>
  </div>
</div>