import { Directive, Input, TemplateRef, OnInit, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { ComponentType, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { FilterService } from '../services/filter.service';

@Directive({
  selector: '[customTooltip]',
  
})
export class CustomTooltipDirective implements OnInit {
  // ToolTip Input Method
  @Input('customTooltip') tooltipContent!: TemplateRef<any> | ComponentType<any>;

  // Overlay method
  private _overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private filterService: FilterService
  ) {
    this.filterService.hideTools.subscribe((res:any) => {
        this._overlayRef.detach();  
    })
   }

  ngOnInit(): void {
    if (this.tooltipContent) {
      const position = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 8,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -8,
        }
      ]);

      
      this._overlayRef = this.overlay.create({
        positionStrategy: position,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'custom-tooltip',
      });
    }
    else {
      console.error('[ERROR] something is Custom tooltip...');
    }
  }

  checkArray:any[]=[]
  @HostListener('click')
  private _show(): void {

    if (this._overlayRef) {

      this.filterService.hideTools.next(true)
      
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;

      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      }
      else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }

      this._overlayRef.attach(containerPortal);
    }
  }

  // @HostListener('document:click', ['$event.target'])
  // private _hide(targetElement:any): void {
    
  //   const clickedInside = this.elementRef.nativeElement.contains(targetElement);
  //   if (!clickedInside) {
  //     if (this._overlayRef) {
  //       // this._overlayRef.detach();
  //     }
  //   }

  // }


}
