import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss']
})
export class WidgetLinkCardComponent implements OnInit {
  static key = 100104;
  @Input('config') config:any = {}
  @Input('item') item:any
  
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('linkCardDiv',{static:true}) linkCardDiv!:ElementRef
  constructor() { }

  ngOnInit(): void {
    const observer = new ResizeObserver((e:any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.linkCardDiv.nativeElement);
  }
}
