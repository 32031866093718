import { Component, HostBinding, Inject } from '@angular/core'
import { Subscription } from 'rxjs'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import * as _ from 'lodash'
import * as d3 from 'd3'
import {  Legend, StateService, Tooltip, Vega } from '@dataunveil/ducharts'
import { FilterService } from 'src/app/services/filter.service'

import config from './config'

@Component({
  standalone: true,
  imports: [CommonModule, Vega,Legend, Tooltip ,MatIconModule, MatDialogModule],
  selector: 'app-dispense-map-modal',
  template: `
    <div class="header"><h2 style="font-family: poppins;">Dispense Trend - Locations</h2>
      <div class="controls">
        <button class="expand" (click)="toggleFullscreen()">
          <mat-icon class="matIcons" [svgIcon]="fullscreen ? 'collapse_arrow' : 'expand_circle'">
          </mat-icon>
          <mat-icon *ngIf="fullscreen" fontIcon="close_fullscreen"></mat-icon>
        </button>
        <button class="close" mat-dialog-close>
          <mat-icon fontIcon="close"></mat-icon>
        </button>
      </div>
    </div>
    <div class="content">
      <div class="locations">
        <div class="legend-name">Locations</div>
        <duc-legend [config]="config.locationLegend" [data]="locationLegendData"></duc-legend>
      </div>
      <div class="divider"></div>
      <div class="sources">
        <duc-legend [config]="config.sourceLegend" [data]="sourceLegendData"></duc-legend>
        <duc-vega [config]="vegaConfig" [data]="chartData"></duc-vega>
      </div>
    </div>
  `,
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  config: any = config
  data: any
  subs: Subscription[] = []
  vegaConfig: any = config.vega
  locationFilters: any = []
  sourceFilters: any = []
  filteredData: any = []
  chartData: any = []
  locationLegendData: any
  sourceLegendData: any
  @HostBinding('class.fullscreen') get isFullscreen () { return this.fullscreen }
  fullscreen = false
  colorScale: any

  constructor(@Inject(MAT_DIALOG_DATA) public _data: any,
    protected stateService: StateService,
    public filterService: FilterService,
  ) {
    this.colorScale = _data.colorScale
    const data = _.reduce(_data.items, (all: any[], item) => {
      const series = _.map(item.series, s => ({
        date: new Date(s.date),
        value: s.value,
        location: item.data.locationname,
        source: item.label,
      }))
      return _.concat(all, series)
    }, [])
    this.data = data
    _.assign(this.vegaConfig.view.spec.marks[0].axes[0], this.config.intervals[this.filterService.report_type])
    if(this.filterService.report_type=='W'){
      const date = new Date(this.data[0].date);
      const dateCount= date.getDay();
   this.vegaConfig.view.spec.marks[0].axes[0].encode.labels.update['text']= {signal : `timeFormat(datetime(year(datum.value), month(datum.value), date(datum.value) + (${dateCount} - day(datum.value) + 7) % 7), ' %d %b %y')`}
    }   else{
      let value =this.vegaConfig.view.spec.marks[0].axes[0].encode.labels.update
      delete value['text']
    } 
  }

  async ngOnInit () {
    this.#filterData()
    this.locationLegendData = _.map(_.groupBy(this.data, 'location'), (v, location) => ({
      label: location,
      color: 'grey',
    }))
    this.#updateSourceLegend()

    this.subs.push(this.stateService.get(this.config.locationLegend.id, 'disabled')
      .subscribe((state: any) => this.#onLocationUpdate(state.disabled)))

    this.subs.push(this.stateService.get(this.config.sourceLegend.id, 'disabled')
      .subscribe((state: any) => this.#onSourceUpdate(state.disabled)))
  }

  #filterData () {
    this.filteredData = _.filter(this.data, r => !this.locationFilters.includes(r.location))
    this.chartData = _.filter(this.filteredData, r => !this.sourceFilters.includes(r.source))
    const intervalFormatters = {
      D: d3.timeDay,
      W: d3.timeWeek,
      M: d3.timeMonth,
      Q: d3.timeMonth,
      Y: d3.timeYear,
    } as any
    if (this.filterService.report_type) {
      _.each(this.chartData, d => {
        d.date = intervalFormatters[this.filterService.report_type](d.date)
        // d.date = this.filterService.report_type == 'W' ? new Date((d.date)) :intervalFormatters[this.filterService.report_type](d.date)
      })
    }

    const color = this.colorScale(this.chartData?.[0]?.source) as string
    const colorSignal = _.find(this.vegaConfig.view.spec.signals, { name: 'color' })!
    colorSignal.value = color
    this.vegaConfig.view.spec.marks[0].marks[0].marks[0].encode.update.fill.value.stops[1].color = color
    this.vegaConfig = _.cloneDeep(this.vegaConfig)
  }

  #onLocationUpdate (state: any) {
    this.locationFilters = state
    this.#filterData()
    this.#updateSourceLegend()
  }

  #updateSourceLegend () {
    this.sourceLegendData = _.map(_.groupBy(this.filteredData, 'source'), (v, source) => ({
      label: source,
      color: this.colorScale(source),
    }))
  }

  #onSourceUpdate (state: any) {
    this.sourceFilters = state
    this.#filterData()
  }

  toggleFullscreen () {
    this.fullscreen = !this.fullscreen
  }
}