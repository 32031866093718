import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertSubscriptionService } from '../../services/alert-subscription.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  indeterminate?: boolean;
  item?: any;
  checked?: boolean;
}

// const selectedNodes: any = [
//   {
//     "patient_status": "ACTIVE",
//     "patient_reason_code_desc": "INSURANCE ISSUE",
//     "patient_status_reason_code": "APS49",
//     "patient_stage_status_code": "ON HOLD"
// },
// {
//   "patient_status": "ACTIVE",
//   "patient_reason_code_desc": "REVERIFYING BENEFITS",
//   "patient_status_reason_code": "APS41",
//   "patient_stage_status_code": "ON HOLD"
// },
// {
//   "patient_status": "ACTIVE",
//   "patient_reason_code_desc": "WAITING ON PATIENT SHIP DATE DECISION",
//   "patient_status_reason_code": "APS27",
//   "patient_stage_status_code": "ON HOLD"
// },
// {
//   "patient_status": "CANCELLED",
//   "patient_reason_code_desc": "PATIENT TRANSFERRED BACK TO HUB",
//   "patient_status_reason_code": "CS10",
//   "patient_stage_status_code": "TRANSFER"
// },
// {
//   "patient_status": "CT",
//   "patient_reason_code_desc": "CLINICAL TRIAL AUTHORIZATION PERIOD HAS EXPIRED.  PATIENT BEING TRANSITIONED TO PAP.",
//   "patient_status_reason_code": "CTH08",
//   "patient_stage_status_code": "DISCONTINUED"
// }
// ];

@Component({
  selector: 'app-alert-tree-select',
  templateUrl: './alert-tree-select.component.html',
  styleUrls: ['./alert-tree-select.component.scss']
})
export class AlertTreeSelectComponent implements OnInit {

  @Input('field') field: any;
  @Input('allFields') allFields: any;
  @Input('data') data: any;

  @Output('emitSelectedValue') emitSelectedValue = new EventEmitter<any>();

  valueProp = "";
  labelProp = "";
  label: any;
  currentField: any;
  parentKey: any = "";
  placeholder: any;
  selectedOption: any[] = [];
  // option:any;
  opt: any;
  fieldKey: any;
  oncloseCondition = false;
  isChecked = false;
  selectAllLabel = 'Select All';
  enteredText: any;
  firstTime = true;
  isAddChecked = false;
  itemsShowLimit = 2;
  reqSubcription: Subscription[] = [];
  key: any;

  level1 = 'patient_status';
  level2 = 'patient_stage_status_code';
  level3 = 'patient_reason_code_desc';
  option: any;
  selectedLeafNodes: TreeNode[] = [];
  searchString:any;

  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  originalData: any;
  optionJson:any;
  loading = false;
  selectedNodes:any;
  validationForField = false;

  constructor(private alertSubscriptionService: AlertSubscriptionService) {}
  
  ngOnInit(): void {
    // Get Value from configuration
    this.key = this.field.key
    this.opt = this.field.templateOptions || {};
    this.labelProp = this.opt.labelProp
    this.valueProp = this.opt.valueProp
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.currentField = this.field
    let ds = this.opt.dataSourceUrl

    // debugger
    if(this.alertSubscriptionService.allOnLoadDataForFields[this.key]) {
      this.optionJson = this.alertSubscriptionService.allOnLoadDataForFields[this.key];
      this.originalData = this.transformToTreeData(this.optionJson);
      this.option = this.optionJson;

      this.selectedNodes = this.data[this.key];
      this.dataSource.data = this.originalData;
      
      if(this.selectedNodes?.length>0) {
        this.initializeSelection(this.selectedNodes);
        this.getSelectedValues();

        this.selectedOption = [];
        this.selectedLeafNodes.forEach((f: any) => {
          this.selectedOption.push(f.item);
        })
      } else {
        this.selectedOption = [];
      }

      setTimeout(() => {
        this.loading = true;
      }, 200);
    }
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  transformToTreeData(optionJson: any[]) {
    const treeData: any[] = [];

    optionJson.forEach(option => {
      let statusNode = treeData.find(node => node.name === option[this.level1]);
      if (!statusNode) {
        statusNode = { name: option[this.level1], children: [] };
        treeData.push(statusNode);
      }

      let stageNode = statusNode.children!.find((node: any) => node.name === option[this.level2]);
      if (!stageNode) {
        stageNode = { name: option[this.level2], children: [] };
        statusNode.children!.push(stageNode);
      }

      stageNode.children!.push({
        name: option[this.level3],
        item: option,
        quickSearch: option[this.level1] + ' ' + option[this.level2] + ' ' + option[this.level3],
      });
    });

    return treeData;
  }

  initializeSelection(selectedNodes: any[]): void {
    selectedNodes.forEach(selectedNode => {
      this.setNodeChecked(this.originalData, selectedNode);
    });
  }

  setNodeChecked(nodes: TreeNode[], selectedNode: any): boolean {
    let found = false;
    nodes.forEach(node => {
      // debugger
      if (node.children) {
        if (this.setNodeChecked(node.children, selectedNode)) {
          node.checked = node.children.every(child => child.checked);
          node.indeterminate = node.children.some(child => child.checked || child.indeterminate) && !node.checked;
          found = true;
        }
      } else if (node.item[this.valueProp] === selectedNode[this.valueProp]) {
        node.checked = true;
        found = true;
      }
    });
    return found;
  }

  filter($event: any): void {
    let filterText = ($event.target as HTMLInputElement).value;

    if (!filterText) {
      this.dataSource.data = this.originalData;
    } else {
      this.dataSource.data = this.filterTreeData(this.originalData, filterText);
    }

    this.treeControl.dataNodes = this.dataSource.data;

    if (!filterText) {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.expandAll();
    }

    this.updateFilteredParentNodes();
  }

  filterTreeData(data: TreeNode[], filterText: string): TreeNode[] {
    const filteredData = data.map(node => ({
      ...node,
      children: node.children ? this.filterStageNodes(node.children, filterText) : []
    })).filter(node => node.children!.length > 0 || node.name.toLowerCase().includes(filterText.toLowerCase()));

    // Update the parent nodes based on filtered children
    filteredData.forEach(node => {
      if (node.children) {
        node.checked = node.children.every(child => child.checked);
        node.indeterminate = node.children.some(child => child.checked || child.indeterminate) && !node.checked;
      }
    });

    return filteredData;
  }

  filterStageNodes(nodes: TreeNode[], filterText: string): TreeNode[] {
    const filteredNodes = nodes.map(node => ({
      ...node,
      children: node.children ? node.children.filter((child: any) => child.quickSearch.toLowerCase().includes(filterText.toLowerCase())) : []
    })).filter(node => node.children!.length > 0);

    // Update the parent nodes based on filtered children
    filteredNodes.forEach(node => {
      if (node.children) {
        node.checked = node.children.every(child => child.checked);
        node.indeterminate = node.children.some(child => child.checked || child.indeterminate) && !node.checked;
      }
    });

    return filteredNodes;
  }

  toggleSelection(node: TreeNode): void {
    node.checked = !node.checked;
    if (node.children) {
      node.children.forEach(child => {
        this.toggleChildSelection(child, node.checked!);
      });
    }
    this.updateParentNodes(node);
    this.updateFilteredParentNodes();
    this.getSelectedValues();
  }

  selectAll(checked: boolean): void {
    
    this.selectAllNodes(this.dataSource.data, checked);

    if (this.isAllSelected()) {
      this.getSelectedValues();
    } else {
      this.selectedLeafNodes = [];
      this.selectedOption = [];
    }
  }

  selectAllNodes(nodes: TreeNode[], checked: boolean): void {
    nodes.forEach(node => {
      node.checked = checked;
      if (node.children) {
        this.selectAllNodes(node.children, checked);
      }
    });

    // If you need to update parent nodes based on their children's state
    this.updateParentNodesRecursively(this.dataSource.data);
  }

  isAllSelected(): boolean {
    // Check if all visible nodes (including filtered nodes) are selected
    return this.areAllNodesSelected(this.dataSource.data);
  }

  areAllNodesSelected(nodes: TreeNode[]): boolean {
    return nodes.every(node => {
      if (node.children) {
        return this.areAllNodesSelected(node.children);
      }
      return !!node.checked;
    });
  }

  updateParentNodesRecursively(nodes: TreeNode[]): void {
    nodes.forEach(node => {
      if (node.children) {
        this.updateParentNodesRecursively(node.children);
        node.checked = node.children.every(child => child.checked);
        node.indeterminate = node.children.some(child => child.checked || child.indeterminate) && !node.checked;
      }
    });
  }

  getSelectedValues(): void {
    this.selectedOption = [];
    
    this.collectSelectedLeafNodes(this.dataSource.data);
    
    this.selectedLeafNodes.forEach((f: any) => {
      this.selectedOption.push(f.item);
    })
  }

  collectSelectedLeafNodes(nodes: TreeNode[]): void {
    nodes.forEach((node: any) => {
      if (node.checked && (!node.children || node.children.length === 0)) {
        this.selectedLeafNodes = this.selectedLeafNodes.filter((f:any) => f.quickSearch.toLowerCase() != node.quickSearch.toLowerCase());
        this.selectedLeafNodes.push(node);
      }

      if (!node.checked && (!node.children || node.children.length === 0)) {
        const existingIndex = this.selectedLeafNodes.findIndex((f:any) => f.quickSearch.toLowerCase() == node.quickSearch.toLowerCase());
        if (existingIndex !== -1) {
          // Remove the existing node
          this.selectedLeafNodes.splice(existingIndex, 1);
        }
      }

      if (node.children) {
        this.collectSelectedLeafNodes(node.children);
      }
    });
  }

  updateFilteredParentNodes(): void {
    this.dataSource.data.forEach(node => this.updateParentNodeState(node));
  }

  updateParentNodeState(node: TreeNode): void {
    if (node.children) {
      node.children.forEach(child => this.updateParentNodeState(child));
      node.checked = node.children.every(child => child.checked);
      node.indeterminate = node.children.some(child => child.checked || child.indeterminate) && !node.checked;
    }
  }

  toggleChildSelection(node: TreeNode, checked: boolean): void {
    node.checked = checked;
    if (node.children) {
      node.children.forEach(child => {
        this.toggleChildSelection(child, checked);
      });
    }
  }

  updateParentNodes(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    while (parent) {
      if (parent.children) {
        parent.checked = parent.children.every(child => child.checked);
        parent.indeterminate = parent.children.some(child => child.checked || child.indeterminate) && !parent.checked;
      }
      parent = this.getParentNode(parent);
    }
  }

  getParentNode(node: TreeNode): TreeNode | null {
    const findParent = (currentNode: TreeNode, targetNode: TreeNode): TreeNode | null => {
      if (!currentNode.children) {
        return null;
      }
      for (const child of currentNode.children) {
        if (child === targetNode) {
          return currentNode;
        }
        const parent = findParent(child, targetNode);
        if (parent) {
          return parent;
        }
      }
      return null;
    };

    for (const rootNode of this.originalData) {
      const parent = findParent(rootNode, node);
      if (parent) {
        return parent;
      }
    }
    return null;
  }

  onClose() {
    // debugger
    
    this.selectedOption = [];
    this.selectedLeafNodes.forEach((f: any) => {
      this.selectedOption.push(f.item);
    })
    
    if(!this.selectedOption || (typeof this.selectedOption === 'object' && Object.keys(this.selectedOption).length === 0)) {
      this.validationForField = true;
    } else {
      this.validationForField = false;
    }

    let obj = {
      key: this.key,
      value: this.selectedOption
    }
    this.emitSelectedValue.emit(obj);
  }

  selectionChange() {
    this.onClose();
  }

  validation() {
    if (!this.selectedOption) return true;
    if (Object.keys(this.selectedOption).length == 0) return true;
    return false;
  }
}
