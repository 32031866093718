<div style="width:100%;">

  <main>

    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">

      <!-- <button  mat-raised-button  color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->

      <div>
        <icon-chart [headerConfig]="headerConfig" [iconList]="iconList" [data]="barData" [pageKey]="pageKey"
          [heading]="heading" [chartName]="divId" [config]="clusterBarChartContainer" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item"  (screenChanges)="fullscreenChanges($event)">
        </icon-chart>
      </div>
      <div><loading *ngIf="isLoading"> </loading></div>
      <div class="col-12 d-flex m-auto   align-items-center"   style="justify-content: center;">
        <div class="legendcircle" [style.display]="noData?'none':''" ></div>
      <div class="legendName ml-2">{{barData?.[0]?.source_name}}</div>      
    </div>
      <div [style.display]="noData?'none':''" id="clusterBarChart" #clusterBarChartContainer style="width:100%;">
      </div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
       <span class="bg-text" >There is no data for this time period</span></div>

      <div id="d3BarTooltip">
        <div class="container chart_tooltip" *ngIf="this.tooltipVisible">
          <div class="row chart_tooltip_header ">
            <div class="col-12 chart_tooltip_header_data mt-1 mb-1">
                Quantity Shipped ({{mytooltipData?.date | date: 'dd-MMM-yyyy'}})
            </div>
          </div>
          <div class="mt-1 mb-5 pb-2" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData.colours.quantityShipped">
                  </div>
                </div>

                <div class="chart_tooltip_body_data ml-2"> DISPENSE FROM INVENTORY : {{mytooltipData?.quantityShipped ?
                  numbedPipe(mytooltipData?.quantityShipped) : 0 }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousMA == 'up'" />

                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousMA == 'down'" />
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData.colours.quantityShipped2">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> DISPENSE FROM SHIPMENT : {{mytooltipData?.quantityShipped2 ?
                  numbedPipe(mytooltipData?.quantityShipped2) : 0 }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="'#fd6565'">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> DIFFERENCE : {{mytooltipData?.difference ?
                  numbedPipe(mytooltipData?.difference) : 0 }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData.colours.movingAverage">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> INVENTORY ON HAND : {{mytooltipData?.movingAverage ?
                  numbedPipe(mytooltipData?.movingAverage) : 00 }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="'red'">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> INVENTORY FAILURES : {{mytooltipData?.disp_failed_qty ?
                  numbedPipe(mytooltipData?.disp_failed_qty) : 0}}
                </div>

                
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="'red'">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> SHIPMENT FAILURES : {{mytooltipData?.inv_failed_qty ?
                  numbedPipe(mytooltipData?.inv_failed_qty) : 0 }}
                </div>

                
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </article>
  </main>
</div>