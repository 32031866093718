<div class="widget-container">
  <loading *ngIf="loader" style="height: 300px !important;"></loading>
  <heatmap-chart
  *ngIf="isDataLoaded"
  [heading]="heading"
  [x_axis_column]="x_axis_column"
  [color_column]="color_column"
  [value_column]="value_column"
  [hierarchyLevels]="hierarchyLevels"
  [chartData]="chartData"
  (onSizeChange)="onExpandCollape($event)"
  >
</heatmap-chart>
</div>

<!-- <div class="widget-container" #HeatMapCharDiv>
    <div class="legend-container">
      <duc-legend-component [config]="$any(config[0])"></duc-legend-component>
    </div>
    <div class="main-chart">
      <duc-tree [config]="$any(config[2])"></duc-tree>
      <duc-status-table [config]="$any(config[1])" [data]="data"></duc-status-table>
    </div>
    <duc-tooltip [config]="$any(config[3])">
      <ng-template #custom let-data>
        <div class="header">
          <div class="date">{{ data.timestamp }}</div>
          <div class="title">Transaction status</div>
        </div>
        <div class="main">
          <div class="statuses">
            <div class="status"><span>&check;</span>SENT: {{ data.details.sent_records }}</div>
            <div class="problems">
              <div class="status">
                <div><span>&#x274C;</span>FAILURES: {{ data.details.failures }}</div>
                <a href="#">See details &gt;</a>
              </div>
              <div class="status">
                <div><span>&#x274C;</span>ERRORS: {{ data.details.errors }}</div>
                <a href="#">See details &gt;</a>
              </div>
              <div class="status">
                <div><span>&#x274C;</span>ON HOLD: {{ data.details.on_hold }}</div>
                <a href="#">See details &gt;</a>
              </div>
            </div>
          </div>
          <div class="comments">
            <span><input placeholder="Add comment here"/></span>
            <button>Send</button>
          </div>
        </div>
      </ng-template>
    </duc-tooltip>
</div> -->