import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { GridApi } from "ag-grid-community";
import moment from "moment";
import { BehaviorSubject, EMPTY, observable, Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { HelperService } from "./helper.service";

@Injectable({
    providedIn: "root",
})
export class AlertService {
    employKey: any
    client_key: any
    annotations: any = false
    getDataPatientJourneyDataHold = new Subject<any>();
    thresholdoldData: any
    constructor(private apiService: ApiService, private helperService: HelperService) {
        // Client Key Get from Local
        var client_key: any = sessionStorage.getItem("client");
        let value: any = JSON.parse(client_key);
        if (value) {
            this.client_key = value[0]?.client.client_key || ''
        }

    }


    getBrandData() {
        const obj = {
            client_key: this.client_key
        }

        return this.apiService.alertKPIPost("kpi/get-brand-filter", obj);
    }

    getSoruceList(item: any) {
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        const obj = {
            client_key: this.client_key,
            employee_key: employeeValue,
            brand_key: item
        }
        return this.apiService.alertKPIPost("kpi/get-sp-source-type-filter", obj);
        // return this.apiService.post("kpi/get-source-type-filter", obj);
    }

    getAllSourceNames(item:any,sourcetype:any) {
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        const obj = {
            client_key: this.client_key,
            employee_key: employeeValue,
            brand_key: item,
            source_type:sourcetype

        }
        return this.apiService.alertKPIPost("kpi/get-source-filter", obj);
    }


    getLocationsDataList(item:any,sourcename:any) {
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        const obj = {
            client_key: this.client_key,
            employee_key: employeeValue,
            brand_key: item,
            source_nm:sourcename
        }
        return this.apiService.alertKPIPost("kpi/dispense-trend-location-filter", obj);
    }

    getKPIDataList (){
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        const obj = {
            client_key: this.client_key,
            employee_key: employeeValue,
        }
        return this.apiService.alertKPIGet("kpi/get-kpi-type");
        
    }

    getSavedSubscription (obj:any){
        return this.apiService.alertKPIPost("kpi/kpi-subscription-get",obj);
        
    }

    insertAlerts(obj:any){
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        
        return this.apiService.alertKPIPost("kpi/kpi-subscription-update",obj);
        
    }

    getEventsAPI(key:any,type:any) {
        let data: any = sessionStorage.getItem("employee_key")
        const employeeKeyObject = JSON.parse(data);
        const employeeValue = employeeKeyObject._value;
        const obj = {
            client_key: this.client_key,
            employee_key: employeeValue,
            api_key:100310,
            brand_key: key,
            report_typ: "D",
            alert_type:type
        }
        return this.apiService.alertKPIPost("kpi/get-execution-details", obj)
      
      }

    


}
