
<div style="font-family: Poppins !important">
    <main>
      <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
        <div>
          <icon-chart [headerConfig]="headerConfig" [pageKey]="pageKey"
            [heading]="heading" [chartName]="'StackAreaBar'" [config]="StackAreaBarContainer" [expand]="fs"
            [fullscreen]="isFullscreen" [item]="item" [data]="chartData"></icon-chart>
        </div>
  
        
  
        <div  [style.display]="noData?'none':''"  #StackAreaBarContainer ></div>
   <div style="height: 500px; width: auto; border: 1px solid #c7c7c7; border-radius: 20px; " *ngIf="noData">
          <span class="bg-text">There is no data for this time period</span>
        </div>
      </article>
    </main>
  </div>