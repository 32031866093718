<div class="page">
  <!-- <div class="page-header">
      <app-header></app-header>
  </div> -->
  <div class="page-content">
      <app-body></app-body>
  </div>
  <!-- <div class="page-footer">
      <app-footer></app-footer>
  </div> -->
</div>

