<div class="stepper-bg" *ngIf="valueToDisplay && report_type == 'P'" [style.position]="this.filterService.hoverBackground ? 'fixed' : 'relative'" >
    <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
        Status change history - {{inputType || ''}} 
        
    </div>
    <hr>
    <div class="row">
        <div class="col">
            <div class="timeline-steps aos-init aos-animate p-3">
                <div class="timeline-step"  *ngFor="let a of valueToDisplay; ">
                    <div class="timeline-content" style="z-index: 999;font-family: 'Poppins'" >
                        <span class="no_of_days" >{{a.no_of_days == null ? '' : a.no_of_days + ' days' }}</span>
                        <!-- <div class="inner-circle"></div> -->
                        <mat-icon
                            [ngStyle]="{'color': a.stage_status == 'Success' ? '#3AC97C' : a.stage_status == 'NA' ? '#E8EAEE' : '#FFCD4A' } "
                            style="width: 30px;height: 30px;"
                            [svgIcon]="a.stage_status == 'Success' ? status_tick : a.stage_status == 'NA' ? status_na: a.stage_status == 'In Progress' ? status_pending : status_ns ">
                        </mat-icon>

                        <p class="mt-2 mb-1" [ngStyle]="{'color' : a.stage_status=='NA' ? '#8A98AB' : '#101D42'}" style="font-size: 12px;line-height: 12px;">{{a.du_status_group}}</p>
                        <p style="font-size: 12px;line-height: 10px;color: #E8EAEE" class="text-muted mb-0 mb-lg-0">{{a.status_date? '(':''}}{{a.status_date ? ( a.status_date | date: 'MM/dd/YY' ) : ''}}{{a.status_date? ')':''}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="stepper-bg" *ngIf="valueToDisplay && report_type == 'C'">
    <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
        Status change history - {{inputType || ''}}
        <span>(Last 4 Statuses)</span>
    </div>
    <hr>
    <div class="row">
        <div class="col">
            <div class="timeline-steps aos-init aos-animate p-3">
                <div class="timeline-step"  *ngFor="let a of valueToDisplay;">
                    <div class="timeline-content" style="z-index: 999;font-family: 'Poppins'" >
                        <!-- <span *ngIf="!firstItem" class="no_of_days">{{a.no_of_days == null ? '' : a.no_of_days + ' days' }}</span> -->
                        <!-- <div class="inner-circle"></div> -->
                        <mat-icon
                            [ngStyle]="{'color': a.color}"
                            style="width: 30px;height: 30px;"
                            [svgIcon]="status_circle">
                        </mat-icon>

                        <p class="mt-2 mb-1" [ngStyle]="{'color' : a.stage_status=='NA' ? '#8A98AB' : '#101D42'}" style="font-size: 12px;line-height: 12px;">{{a.curr_status}}</p>
                        <p style="font-size: 12px;line-height: 10px;color: #E8EAEE" class="text-muted mb-0 mb-lg-0">{{a.curr_du_status_date? '(':''}}{{a.curr_du_status_date ? ( a.curr_du_status_date | date: 'MM/dd/YY' ) : ''}}{{a.curr_du_status_date? ')':''}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="stepper-bg" *ngIf="valueToDisplay ==''">
    <div class="p-3" style="font-weight: 600;font-family: 'Poppins';font-size: 14px; line-height: 16px;">
        Status change history - {{inputType || ''}}
    </div>
    <hr>
    <div class="px-3" style="display: flex;">
        <!-- <hr> -->
        <div style="display: table; margin: 0 auto;">
            <div class="text-center pt-2 px-2 pb-4" style="position: relative;">
                No History
            </div>
        </div>
    </div>
</div>