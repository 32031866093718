<div class="widget-container" #bubbleCharDiv>
    <app-dispense-map
    [heading]="heading"
    [headerConfig]="this.item.config.widget_header_configuration"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [data]="data"
    
    >
    </app-dispense-map>
</div>
<!-- *ngIf="isDataLoaded"  -->