<div class="row page-title-header modal-header m-0">
  <div class="col-md-12 col-12 text-center">
    <span class="title">Select Brand</span>
  </div>
</div>

<div style="padding: 20px 15px 0;">
  <mat-form-field style="width: 100%" appearance="fill" class="matFformFieldClient">
    <mat-label>Select Brand</mat-label>
    <mat-select [(ngModel)]="selectedBrand" class="brandPopups">
      <mat-option style="font-family: 'Poppins';font-size: 12px;" *ngFor="let client of brandKeyData" [value]="client">{{client.brand_name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="row">
  <div class="col-12 text-center my-4">
    <button 
      type="button" 
      class="btn btn-primary btn-sm confirmBtnHomePopups" 
      [disabled]="!selectedBrand" 
      (click)="brandSelection(selectedBrand)" 
    >
      Confirm
    </button>
  </div>
</div>
