import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { join } from 'path';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-alerts-tab',
  templateUrl: './alerts-tab.component.html',
  styleUrls: ['./alerts-tab.component.scss']
})
export class AlertsTabComponent implements OnInit {
  isOpen: boolean = false
  showAlterts: boolean = true;
  showGanttTooltip: boolean = true;
  dataObj = [
    {

      heading: 'New Patient',
      color: '#E34071',
      values: [
        {
          id: "ID592847201232",
          prescriber: "John Reed",
          date: "03.05.2022"
        },
        {
          id: "ID836191848701",
          prescriber: "Dan Stoltenberg",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
      ]
    },
    {

      heading: 'Refill Too Soon',
      color: '#1363DF',
      values: [
        {
          id: "ID592847201232",
          prescriber: "John Reed",
          date: "03.05.2022"
        },
        {
          id: "ID836191848701",
          prescriber: "Dan Stoltenberg",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
      ]
    },
    {

      heading: 'New Prescriber',
      color: '#3AC97C',
      values: [
        {
          id: "ID592847201232",
          prescriber: "John Reed",
          date: "03.05.2022"
        },
        {
          id: "ID836191848701",
          prescriber: "Dan Stoltenberg",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
      ]
    },
    {

      heading: 'Shipment Delayed',
      color: '#D11044',
      values: [
        {
          id: "ID592847201232",
          prescriber: "John Reed",
          date: "03.05.2022"
        },
        {
          id: "ID836191848701",
          prescriber: "Dan Stoltenberg",
          date: "02.05.2022"
        },
        {
          id: "ID725525123421",
          prescriber: "David Robinson",
          date: "02.05.2022"
        },
      ]
    },
    {

      heading: 'Status Change',
      color: '#FF9A3D',
      statusValues: [{
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },
      {
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },
      {
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },
      {
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },
      {
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },
      {
        id: "ID725525123421",
        from: "Waiting in new RX on HCP",
        to: "Refer to HUB for bridge",
        date: "02/05/2022"
      },

      ]
    },

  ]
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  employeeKey: any;
  alertData: any = [];
  status_tick = "status_tick"
  status_pending = "status"
  trash = "trash_delete"
  scrollTop: any;
  randomColor = this.getRandomColor()
  deleteObj: any;
  @ViewChild('DeleteDialogAlert') DeleteDialogAlert: any;
  @ViewChild('DeleteDialogAlert1') DeleteDialogAlert1: any;
  totalData: any
  update: any

  getRandomColor() {
    return {
      borderLeft: '6px solid #' + Math.floor(Math.random() * 16777215).toString(16)
    }
  }

  DelDialog(enterAnimationDuration: string, exitAnimationDuration: string, obj: any, data: any): void {
    this.totalData = data
    this.update = obj
    this.dialog.open(this.DeleteDialogAlert, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
  DelDialog1(enterAnimationDuration: string, exitAnimationDuration: string, obj: any, data: any): void {
    this.totalData = data
    this.update = obj
    this.dialog.open(this.DeleteDialogAlert1, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
  constructor(
    private router: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    public filterServiceNew: NewFilterService,
    public filterService: FilterService,
    public dialog: MatDialog,
    private navigationService: NavigationService
  ) {

    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
  
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
      })
    })
  }

  handleClick1() {
    document.getElementById('alert-body')?.scrollTo(0, this.scrollTop)

  }

  ngOnInit(): void {
    // this.getAlertNotificationData()
    document.getElementById('alert-body')?.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop == 0) {
        document.getElementById('alert-body')?.scrollTo(0, this.scrollTop)

      } else {
        this.scrollTop = (event?.target.scrollTop)
      }
    })
  }

  handleClick() {
    this.isOpen = !this.isOpen
  }

  handleImgClick() {
  }
  toolTipHide() {
    this.showGanttTooltip = false
  }

  // getRandomColor() {
  //   return {
  //     borderLeft: '6px solid #' + Math.floor(Math.random() * 16777215).toString(16)
  //   }
  // }

  showNotifications() {
    this.showAlterts = false
  }

  getAlertNotificationData() {
    let array: any = []
    let prescriberArray: any = []
    let patientArray: any = []
    let refillArray: any = []
    let shipmentArray: any = []
    this.employeeKey = sessionStorage?.getItem("employee_key");
    if (this.employeeKey) {
      let key = JSON.parse(this.employeeKey)
      this.employeeKey = key["_value"]
    }
    const obj = {
      api_key: 100135,
      report_typ: "G",
      client_key: this.clientKey || "",
      employee_key: this.employeeKey ? this.employeeKey : '',
    }
    this.filterServiceNew.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        resp.forEach((i: any) => {
          const obj = {
            patient_key: i.patient_key,
            alert_col_value: JSON.parse(i.alert_col_value)
          }
          this.alertData.push(obj)
        })
        this.alertData?.map((i: any) => {
          i.alert_col_value.forEach((k: any) => {
            if (k.Alert_key == 100103) {
              k.Data.forEach((j: any) => {
                const obj = {
                  alert_key: k.Alert_key,
                  id: i?.patient_key,
                  from: j?.From,
                  to: j?.To,
                  date: j?.Date
                }
                array.push(obj)
              })
            }
            else if (k.Alert_key == 100101) {
              k.Data.forEach((j: any) => {
                const obj = {
                  alert_key: k.Alert_key,
                  id: i?.patient_key,
                  prescriber: j?.Prescriber_Name,
                  date: j?.alert_dt
                }
                patientArray.push(obj)
              })

            }
            else if (k.Alert_key == 100102) {
              k.Data.forEach((j: any) => {
                const obj = {
                  alert_key: k.Alert_key,
                  id: i?.patient_key,
                  prescriber: j?.Prescriber_Name,
                  date: j?.alert_dt
                }
                prescriberArray.push(obj)
              })

            }
            else if (k.Alert_key == 100104) {
              k.Data.forEach((j: any) => {
                const obj = {
                  alert_key: k.Alert_key,
                  id: i?.patient_key,
                  prescriber: j?.Prescriber_Name,
                  date: j?.alert_dt
                }
                refillArray.push(obj)
              })
            }
            else if (k.Alert_key == 100105) {
              k.Data.forEach((j: any) => {
                const obj = {
                  alert_key: k.Alert_key,
                  id: i?.patient_key,
                  prescriber: j?.Prescriber_Name,
                  date: j?.alert_dt
                }
                shipmentArray.push(obj)
              })
            }
          });
        })
      }
    })
    this.dataObj.map((i: any) => {
      if (i.heading == 'Status Change') {
        i.statusValues = array
      }
      else if (i.heading == 'New Prescriber') {
        i.values = prescriberArray

      }
      else if (i.heading == 'New Patient') {
        i.values = patientArray
      }
      else if (i.heading == 'Refill Too Soon') {
        i.values = refillArray
      }
      else if (i.heading == 'Shipment Delayed') {
        i.values = shipmentArray
      }
    })
  }

  updateAlertsNetwork() {
    if (this.totalData.heading != 'Status Change') {
      this.dataObj.find((i: any) => {
        if (i.heading == this.totalData.heading) {
          i.values.forEach((j: any, k: any) => {
            if (j.id == this.update.id && j.prescriber == this.update.prescriber && j.date == this.update.date) {
              this.deleteObj = j
              i.values.splice(k, 1)
            }
          })
        }
      })
    }

    else if (this.totalData.heading == 'Status Change') {
      this.dataObj.find((i: any) => {
        if (i.heading == this.totalData.heading) {
          i.statusValues.forEach((j: any, k: any) => {
            if (j.id == this.update.id && j.from == this.update.from && j.to == this.update.to && j.date == this.update.date) {
              this.deleteObj = j
              i.statusValues.splice(k, 1)
            }
          })
        }
      })
    }

    let query:any = {};
    query["api_key"] = '100135';
    query["report_typ"] = 'D';
    query["Alert_key"] = this.update.alert_key || undefined
    query["alert_dt"] = this.update?.date || undefined
    query["patient_id"] = this.update?.id || undefined


    this.filterService.frmRemoveAlerts(query).subscribe(
      (res: any) => {
        if (res) {

        }
      },
      (error: any) => {

      })
  }

  deleteAll() {
    this.dataObj = []

  }
}
