import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { ApiService } from "src/app/services/api.service";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "app-comboBar-chart-card",
  templateUrl: "./comboBar-chart-card.component.html",
  styleUrls: ["./comboBar-chart-card.component.scss"],
})
export class WidgetComboBarChartComponent implements OnInit, OnDestroy {
  static key = 100600;
  
  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("comboBarChart", { static: true }) comboBarChart!: ElementRef;
  config: any;
  configm: any;
  data: any 
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  constructor(
    private filterService: FilterService,
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
      })
    );
  }
  ngOnInit(): void {
    this.loadlineConfig();
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0]?.contentRect.height);
    });
    if (this.comboBarChart?.nativeElement) {
      observer?.observe(this.comboBarChart?.nativeElement);
    }
   
  }

  loadlineConfig() {
     
    this.heading = this.item.config["report_heading"];
    this.configm={
      xAxisLabel: this.item.config["x_axis"]["api_resp_column_display_nm"],
      yAxisLabel: this.item.config["y_axis"]["api_resp_column_display_nm"],
      xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
      yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
      value_column: this.item.config["value_column"]["api_resp_column_nm"],
      secondary_value_column: this.item.config["secondary_value_column"]?.["api_resp_column_nm"] || null,
      group_value_column :  "data_source_name" || this.item.config["group_value_column"]["api_resp_column_nm"]

    }
    this.config = {
      xAxisLabel: this.item.config["x_axis"]["api_resp_column_display_nm"],
      yAxisLabel: this.item.config["y_axis"]["api_resp_column_display_nm"],
      xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
      yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
      value_column: this.item.config["value_column"]["api_resp_column_nm"],
      secondary_value_column: this.item.config["secondary_value_column"]?.["api_resp_column_nm"] || null,
      group_value_column :  "data_source_name" || this.item.config["group_value_column"]["api_resp_column_nm"]
    }
  }

  loadApiData(reportType?: string) {
    let lineData: any = [];
    let barData: any = [];
    let val: any = {};
    let api = this.item.config["list_of_api"] || {};
    if(api.api_key && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {
      
      // API Payload
      let obj:any = {"api_key" : api.api_key}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          if(res == null || res.length == 0) {return (this.data = []);}
          this.isDataLoaded = true;
          for (let i = 0; i < res.length; i++) {
            lineData[i] = res[i];
            barData[i] = res[i];
          }
          val["lineData"] = lineData;
          val["barData"] = barData;
          this.data = val;
        }, (err: any) =>{
          this.data = [];
          console.log(err)
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
      //   if(res == null || res.length == 0) {return (this.data = []);}
      //   this.isDataLoaded = true;
      //   for (let i = 0; i < res.length; i++) {
      //     lineData[i] = res[i];
      //     barData[i] = res[i];
      //   }
      //   val["lineData"] = lineData;
      //   val["barData"] = barData;
      //   this.data = val;
      // }, (error: any) => {
      //   this.data = [];
      // }));
    }
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
