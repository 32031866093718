<div class="pdf_export_block widget-container" #alertSettingNew>
    <div class="header" style="height: 40px;background-color: #ffffff">
        <div class="row mt-2">
            <div class="col-9 text-start pl-2">
                <h2 class="pl-4 mt-1" style="font-family: Poppins;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: left;
                ">
                    {{item.config?.widget_heading}}
                </h2>
            </div>
            <div class="col-3 text-center">
                <!-- <mat-form-field class="autocomplete-field">
                    <mat-label class="mb-2 panel_label">Select Brand</mat-label>
                    <mat-select (selectionChange)="onBrandChange($event)" [(value)]="selectedBrand">
                        <mat-option *ngFor="let option of options6" [value]="option">{{option.brand_name}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
        </div>
    </div>

    <div class="body mt-3" *ngIf="dataControls">
        <div>
            <loading *ngIf="isLoading"> </loading>
        </div>
        <mat-accordion>
            <!-- FORM GROUP -->
            <form [formGroup]="formRange">
                <!-- FORM ARRAY -->
                <div formArrayName="data">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-3"
                            *ngFor="let item of dataControls?.controls; let idx = index">
                            <!-- INDEX MAPPING OF ARRAY -->
                            <mat-expansion-panel class="ml-2 mr-2" style="width: -webkit-fill-available"
                                (click)="getCurrentKPIName(item,idx)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title
                                        class="panel_header d-flex align-items-center justify-content-between">
                                        <div class="row" style="width: -webkit-fill-available;">
                                            <div
                                                class="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center justify-content-between">
                                                <div class="pl-1">
                                                    {{
                                                    formRange.get('data')?.get(idx.toString())?.get('dropdownName')?.value
                                                    ??
                                                    'Unknown KPI' }}
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <!-- <span *ngIf="item.value.sections.length > 0" class="subscribed"
                                                        style="background: green;color: #ffffff;">Subscribed</span> -->
                                                    <mat-icon style="cursor: pointer;margin-right: 10px;"
                                                        class="matIcon" (click)="$event.stopPropagation()"
                                                        matTooltip="Alert Info" svgIcon="info_circle"
                                                        [matMenuTriggerFor]="info"
                                                        (click)="getInfoDetails(item,idx)"></mat-icon>
                                                    <mat-icon *ngIf="item.value.sections.length > 0"
                                                        style="cursor: pointer;margin-right: 10px;color: #3AC97C;"
                                                        (click)="$event.stopPropagation()"
                                                        matTooltip="Subscribed">notifications_active</mat-icon>
                                                    <mat-icon *ngIf="item.value.sections.length <= 0"
                                                        style="cursor: pointer;margin-right: 10px;color:#345D7D;"
                                                        (click)="$event.stopPropagation()"
                                                        matTooltip="Not Subscribed">notifications_off</mat-icon>
                                                    <!-- <span *ngIf="item.value.sections.length <= 0" class="not_subscribed"
                                                        style="background: grey;color: #ffffff;">Not Subscribed</span> -->
                                                    <mat-icon *ngIf="item.value.sections.length > 0"
                                                        style="cursor: pointer;margin-right: 10px;color: #E85C3D;"
                                                        matTooltip="Delete alerts"
                                                        (click)="openDialog('delete',item,idx);$event.stopPropagation()">delete</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                    <!-- <mat-panel-description style="text-align: end;justify-content: end;">
                                        
                                    </mat-panel-description> -->
                                </mat-expansion-panel-header>
                                <div class="mt-3 mb-3 grid_outline" [formGroupName]="idx">
                                    <div class="row mt-3 mb-2 d-none">
                                        <div class="col-10">
                                            <select class="form-select" style="border-radius: 8px"
                                                (change)="onShowByNameChange($event)">
                                                <option>Show By Name</option>
                                                <option *ngFor="let option of showByFilterObj" [value]="option">
                                                    {{ option }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="" formArrayName="sections">
                                        <div
                                            *ngFor="let pointer of getSectionControls(idx).controls; let j = index;let last = last">
                                            <div [formGroupName]="j" class="row mt-3" style="border: 1px solid grey;
                                background: linear-gradient(0deg, #E8EAEE, #E8EAEE), linear-gradient(0deg, #F9FAFB, #F9FAFB);
                                border-radius: 8px;min-height: 208px;">
                                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mt-4 astrix_map">
                                                    <mat-form-field>
                                                        <mat-label class="mb-2 panel_label" >Source Type</mat-label>
                                                        <mat-select formControlName="item1"
                                                            (selectionChange)="onSourceTypeChange($event, idx,j)">
                                                            <mat-option style="font-family: 'Poppins' !important;"
                                                                *ngFor="let option of formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item8')?.value"
                                                                [value]="option">{{option?.source_type}}</mat-option>
                                                        </mat-select>
                                                        <mat-error class="mt-3" style="font-family: 'Poppins' !important;color:#E85C3D !important"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item1')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item1')!.hasError('required')">
                                                            Source Type is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mt-4 astrix_map">
                                                    <!-- <mat-form-field>
                                                        <mat-label class="mb-2 panel_label ">Source Name</mat-label>
                                                        <mat-select
                                                            [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0)"
                                                            formControlName="item2" multiple
                                                            (selectionChange)="onSourceNameChange($event, idx, j)">
                                                            <mat-option
                                                                *ngFor="let option of formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value"
                                                                [value]="option">{{option.source_nm}}</mat-option>
                                                        </mat-select>
                                                        <mat-error class="mt-3"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item1')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')!.hasError('required')">
                                                            Source Name is required
                                                        </mat-error>
                                                    </mat-form-field> -->
                                                    <mat-form-field>
                                                        <mat-label class="mb-2 panel_label ">Source Name</mat-label>
                                                        <mat-select
                                                            (openedChange)="openedChangeSource($event,idx, j, 'source')"
                                                            (closed)="onSelectClosed(idx, j, 'source')"
                                                            [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0)"
                                                            (selectionChange)="onSourceNameChange($event, idx, j)"
                                                            placeholder="Select" formControlName="item2" multiple>
                                                            <mat-select-trigger style="font-family: 'Poppins' !important;">
                                                                {{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value
                                                                ?
                                                                (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value[0]
                                                                == 'all' ? "All Selected" :
                                                                formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value[0]?.source_nm
                                                                ) :""}}
                                                                <span style="font-family: 'Poppins' !important;"
                                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value?.length > 1  && formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value[0] !== 'all'"
                                                                    class="additional-selection">
                                                                    (+{{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value.length
                                                                    - 1}}
                                                                    {{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value?.length
                                                                    === 2 ? 'other' :
                                                                    'others'}})
                                                                </span>
                                                            </mat-select-trigger>
                                                            <div class="select-container">
                                                                <mat-optgroup>
                                                                    <mat-form-field style="width: 100%">
                                                                        <input #search autocomplete="off"
                                                                            placeholder="Search" aria-label="Search"
                                                                            matInput [formControl]="searchControl"
                                                                            (input)="handleSearch(idx, j, 'source')"
                                                                            (keyup)="handleKeydown($event, idx, j,'source')" />
                                                                        <button *ngIf="search.value"
                                                                            [disableRipple]="true" matSuffix
                                                                            mat-icon-button aria-label="Clear"
                                                                            (click)="clearSearch(idx, j,'source')">
                                                                            <mat-icon>close</mat-icon>
                                                                        </button>
                                                                    </mat-form-field>
                                                                </mat-optgroup>
                                                                <mat-option style="font-weight: 600;color: #1363DF;"
                                                                    value="all" style="font-family: 'Poppins' !important;"
                                                                    [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0) || (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value.length != formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('sourceNameSearchList')?.value.length)"
                                                                    (onSelectionChange)="getSelectAllChange($event, idx, j,'sourceName')">Select
                                                                    all</mat-option>
                                                                <hr />
                                                                <mat-option
                                                                    (onSelectionChange)="getSelectAllChange($event, idx, j,'sourceName')" style="font-family: 'Poppins' !important;"
                                                                    *ngFor="let option of formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value"
                                                                    [value]="option">
                                                                    {{option.source_nm}}
                                                                </mat-option>
                                                            </div>
                                                        </mat-select>
                                                        <mat-error class="mt-3" style="font-family: 'Poppins' !important;color:#E85C3D !important"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')!.hasError('required')">
                                                            Source Name is required
                                                        </mat-error>
                                                        <!-- <mat-checkbox
                                                            [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0)"
                                                            class="mt-1"
                                                            [checked]="(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0) && (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value.length == formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value.length)"
                                                            color="primary"
                                                            (change)="getSelectAllChange($event, idx, j,'sourceName')">Select
                                                            all</mat-checkbox> -->
                                                    </mat-form-field>

                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mt-4 astrix_map"
                                                    *ngIf="(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'Y') && (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('locationSearchList')?.value?.length > 0)">
                                                    <!-- <mat-form-field>
                                                        <mat-label class="mb-2 panel_label">Location</mat-label>
                                                        <mat-select formControlName="item3" multiple
                                                            [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0)"
                                                            (selectionChange)="onLocationchange($event,idx,j)">
                                                            <mat-option
                                                                *ngFor="let option of formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value"
                                                                [value]="option">{{option?.sp_location_name}}</mat-option>
                                                        </mat-select>
                                                        <mat-error class="mt-3"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item1')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item3')!.hasError('required')">
                                                            Location is required
                                                        </mat-error>
                                                    </mat-form-field> -->
                                                    <!-- <mat-checkbox
                                                        [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0)"
                                                        class="mt-1" color="primary"
                                                        [checked]="(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value.length > 0) && (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value.length == formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value.length)"
                                                        (change)="getSelectAllChange($event, idx, j,'location')">Select
                                                        all</mat-checkbox> -->
                                                    <mat-form-field class="">
                                                        <mat-label class="mb-2 panel_label ">Location Name</mat-label>
                                                        <mat-select
                                                            (openedChange)="openedChangeLocation($event,idx, j, 'location')"
                                                            (closed)="onSelectClosed(idx, j, 'location')"
                                                            [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0)"
                                                            (selectionChange)="onLocationchange($event,idx,j)"
                                                            placeholder="Select" formControlName="item3" multiple>
                                                            <mat-select-trigger style="font-family: 'Poppins' !important;">
                                                                {{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value
                                                                ?
                                                                (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value[0]
                                                                == 'all' ? "All Selected" :
                                                                formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value[0]?.sp_location_name
                                                                ) :""}}

                                                                <span style="font-family: 'Poppins' !important;"
                                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value?.length > 1  && formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value[0] !== 'all'" 
                                                                    class="additional-selection">
                                                                    (+{{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value.length
                                                                    - 1}}
                                                                    {{formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.value?.length
                                                                    === 2 ? 'other' :
                                                                    'others'}})
                                                                </span>
                                                            </mat-select-trigger>
                                                            <div class="select-container">
                                                                <mat-optgroup>
                                                                    <mat-form-field style="width: 100%">
                                                                        <input #search autocomplete="off"
                                                                            placeholder="Search" aria-label="Search"
                                                                            matInput [formControl]="searchControl"
                                                                            (input)="handleSearch(idx, j, 'location')"
                                                                            (keyup)="handleKeydown($event, idx, j,'location')" />
                                                                        <button *ngIf="search.value"
                                                                            [disableRipple]="true" matSuffix
                                                                            mat-icon-button aria-label="Clear"
                                                                            (click)="clearSearch(idx, j,'location')">
                                                                            <mat-icon>close</mat-icon>
                                                                        </button>
                                                                    </mat-form-field>
                                                                </mat-optgroup>
                                                                <mat-option style="font-weight: 600;color: #1363DF;"
                                                                    value="all" style="font-family: 'Poppins' !important;"
                                                                    [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0) || (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value != formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('locationSearchList')?.value)"
                                                                    (onSelectionChange)="getSelectAllChange($event, idx, j,'location')">Select
                                                                    all</mat-option>
                                                                <hr />
                                                                <mat-option
                                                                    (onSelectionChange)="getSelectAllChange($event, idx, j,'location')" style="font-family: 'Poppins' !important;"
                                                                    *ngFor="let option of formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value"
                                                                    [value]="option">
                                                                    {{option?.sp_location_name}}
                                                                </mat-option>
                                                            </div>
                                                        </mat-select>
                                                        <!-- <mat-error class="mt-3" style="font-family: 'Poppins' !important;"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item1')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item3')!.hasError('required')">
                                                            Location is required
                                                        </mat-error> -->
                                                        <!-- <mat-checkbox
                                                                [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0)"
                                                                class="mt-1"
                                                                [checked]="(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0) && (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.value.length == formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value.length)"
                                                                color="primary"
                                                                (change)="getSelectAllChange($event, idx, j,'sourceName')">Select
                                                                all</mat-checkbox> -->
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mt-4 astrix_map">
                                                    <mat-form-field>
                                                        <mat-label class="mb-2 panel_label">Alert Type</mat-label>
                                                        <mat-select formControlName="item5" multiple
                                                            (selectionChange)="onTypeChange()"
                                                            [disabled]="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item1')?.value == ''">
                                                            <mat-option *ngFor="let option of options5" style="font-family: 'Poppins' !important;"
                                                                [value]="option">{{
                                                                option
                                                                }}</mat-option>
                                                        </mat-select>
                                                        <mat-error class="mt-3" style="font-family: 'Poppins' !important;color:#E85C3D !important"
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item5')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item5')!.hasError('required')">
                                                            Alert type is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <!-- <mat-checkbox
                                                        [disabled]="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item1')?.value == ''"
                                                        [checked]="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item5')?.value.length == options5.length"
                                                        class="mt-1" color="primary"
                                                        (change)="getSelectAllChange($event, idx, j,'alert')">Select
                                                        all</mat-checkbox> -->
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mt-4 astrix_map"
                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'Y'">
                                                    <mat-form-field>
                                                        <mat-label class="mb-2 panel_label">Time Cycle</mat-label>
                                                        <mat-select formControlName="item7" multiple
                                                            (selectionChange)="onTypeChange()"
                                                            [disabled]="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item1')?.value == ''">
                                                            <mat-option *ngFor="let option of options7" style="font-family: 'Poppins' !important;"
                                                                [value]="option">{{
                                                                option
                                                                }}</mat-option>
                                                        </mat-select>
                                                        <mat-error class="mt-3" style="font-family: 'Poppins' !important;color:#E85C3D !important "
                                                            *ngIf="formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item7')!.touched && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item7')!.hasError('required')">
                                                            Time Cycle is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <!-- <mat-checkbox
                                                        [disabled]="!(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item6')?.value?.length > 0)"
                                                        [checked]="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item7')?.value.length == options7.length"
                                                        class="mt-1" color="primary"
                                                        (change)="getSelectAllChange($event, idx, j,'timeCycle')">Select
                                                        all</mat-checkbox> -->
                                                </div>

                                                <!-- <div class="col-lg-12 col-md-12 col-sm-12 mt-2"
                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'N' && formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'Y'">
                                                    <mat-error class="mt-3">
                                                        Locations not applicable for this alert
                                                    </mat-error>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-1"
                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'N' && formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'Y'">
                                                    <mat-error class="mt-3">
                                                        Time cycle not applicable for this alert
                                                    </mat-error>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-1"
                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'N' && formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'N'">
                                                    <mat-error class="mt-3">
                                                        Locations & time cycle not applicable for this alert
                                                    </mat-error>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-1"
                                                    *ngIf="formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'Y' && !(formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0) && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')?.value?.length >0">
                                                    <mat-error class="mt-3">
                                                        Locations not present for this source name
                                                    </mat-error>
                                                </div> -->

                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-2" style="font-family: 'Poppins' !important;">
                                                    <button type="button"
                                                        (click)="deleteSection(idx,j)" style="font-family: 'Poppins' !important;color:#E85C3D !important"
                                                        mat-icon-button><mat-icon style="color: #E85C3D;">delete</mat-icon>
                                                        Remove
                                                        subscription</button>
                                                </div>
                                            </div>
                                            <div class="row mt-3" *ngIf="last">
                                                <button class="addButton" type="button" style="font-family: 'Poppins' !important;"
                                                    (click)="addSection(pointer,idx,j)"
                                                    [disabled]="
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item1')?.hasError('required') ||
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.hasError('required') ||
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item5')?.hasError('required') ||

  (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'Y' ? 
  (((formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0) 
  && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')?.value?.length >0) ? 
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.hasError('required'): false ) : false ) ||

  (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'Y' ?
   formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item7')?.hasError('required') : false )"
                                                    mat-icon-button><mat-icon>add</mat-icon> Add additional
                                                    subscription</button>
                                                <mat-error style="font-family: 'Poppins' !important;" style="color:#E85C3D !important"
                                                    *ngIf="
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item1')?.hasError('required') ||
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item2')?.hasError('required') ||
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item5')?.hasError('required') ||

  (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('location_flag')?.value == 'Y' ? 
  (((formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item4')?.value?.length > 0) 
  && formRange.get('data')!.get(idx.toString())!.get('sections')!.get(j.toString())!.get('item2')?.value?.length >0) ? 
  formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item3')?.hasError('required'): false ) : false ) ||
  
  (formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('time_cycle_flag')?.value == 'Y' ?
   formRange.get('data')?.get(idx.toString())?.get('sections')?.get(j.toString())?.get('item7')?.hasError('required') : false )">
                                                    *Please complete the above section to add new one
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="addButton row mt-3" style="font-family: 'Poppins' !important;"
                                        *ngIf="isArrayAndEmpty(formRange.get('data')?.get(idx.toString())?.get('sections'))">
                                        <button type="button" (click)="getAddSubsSection(item,idx)" style="font-family: 'Poppins' !important;"
                                            mat-icon-button><mat-icon>add</mat-icon> Add
                                            subscription</button>
                                    </div>

                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>
                    <div class="row m-4">
                        <div class="col-lg-7 col-md-7 col-sm-12" style="font-family: 'Poppins' !important;"><mat-error style="font-family: 'Poppins' !important;color:#E85C3D !important">{{nullSourceType || nullSourceName ||
                                nullAlerts || nullTimeCycle
                                || nulllocationValues ? '*Please fill mandatory fields to save changes' :
                                ''}}</mat-error></div>
                        <div class="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-end">
                            <button class="" 
                                [ngClass]="{'disabled_button' :disableOnLoad, 'reset_button': !disableOnLoad }"
                                mat-raised-button color="basic" [disabled]="disableOnLoad"
                                (click)="openDialog('cancel')">Cancel</button>
                            <button class="save_button ml-2" [ngClass]="{
                            'saveButton': !nullSourceType || !nullSourceName || !nullAlerts || !nullTimeCycle || !nulllocationValues || !disableOnLoad,
                            'disabled_button' : nullSourceType || nullSourceName || nullAlerts || nullTimeCycle || nulllocationValues || disableOnLoad
                         }" mat-raised-button color="primary"
                                [disabled]="nullSourceType || nullSourceName || nullAlerts || nullTimeCycle || nulllocationValues || disableOnLoad"
                                (click)="openDialog('save')">Save Changes</button>
                        </div>

                    </div>
                </div>
            </form>

        </mat-accordion>

    </div>
</div>

<ng-template #deleteDialog style="height: 200px;">
    <div class="row" style="background-color: #F6F8FB;">
        <div class="col-12 d-flex justify-content-between align-items-center alert_popup_header">
            <span style="font-weight: 500;font-family: 'Poppins';font-size:medium">Remove Alerts</span>
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);">close</mat-icon>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 d-flex justify-content-center">
            <div style="font-family:'Poppins'">Are you sure you want to remove all subscription for this alert?</div>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 mt-1 d-flex justify-content-between">
            <button class="" style="color:#E85C3D;background:none;border:none" (click)="cancelDialogPopup()">No</button>
            <button class="" style="color:#3AC97C;background:none;border:none" (click)="removeDelete()">Yes</button>
        </div>
    </div>

</ng-template>

<ng-template #saveDialog style="height: 200px;">
    <div class="row " style="background-color: #F6F8FB;">
        <div class="col-12 d-flex justify-content-between align-items-center alert_popup_header">
            <span style="font-weight: 500;font-family: 'Poppins';font-size:medium">Alerts Subscription</span>
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);">close</mat-icon>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 d-flex justify-content-center">
            <div style="font-family:'Poppins'">{{dataSet.length > 0 ? "Are you sure you want update alert subscription?"
                : "Are you sure you want to
                subscribe to alerts?" }}</div>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 mt-1 d-flex justify-content-between">
            <button class="" style="color:#E85C3D;background:none;border:none" (click)="cancelDialogPopup()">No</button>
            <button class="" style="color:#3AC97C;background:none;border:none"
                (click)="saveSettings();cancelDialogPopup()">Yes</button>
        </div>
    </div>

</ng-template>

<ng-template #cancelDialog style="height: 200px;">
    <div class="row " style="background-color: #F6F8FB;">
        <div class="col-12 d-flex justify-content-between align-items-center alert_popup_header">
            <span style="font-weight: 500;font-family: 'Poppins';font-size:medium">Discard Changes</span>
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);">close</mat-icon>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 d-flex justify-content-center">
            <div style="font-family:'Poppins'">Are you sure you want to discard all unsaved changes?</div>
        </div>
    </div>
    <div class="row m-2">
        <div class="col-12 mt-1 d-flex justify-content-between">
            <button class="" style="color:#E85C3D;background:none;border:none" (click)="cancelDialogPopup()">No</button>
            <button class="" style="color:#3AC97C;background:none;border:none"
                (click)="resetSettings();cancelDialogPopup()">Yes</button>
        </div>
    </div>

</ng-template>

<mat-menu #info="matMenu">
    <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
        <div class="card-header d-flex justify-content-between align-items-center"
            style="border-bottom: none;background: #ddd;">
            <span style="font-weight: 600;">{{currentSelection || ''}}</span>

            <span (click)="closeMenu()" mat-dialog-close>
                <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z"
                        fill="#1363DF" />
                </svg>
            </span>
        </div>

        <div class="card-body">
            <div>{{alertDescription ? alertDescription : ' No Data Found'}}</div>
        </div>
    </div>
</mat-menu>