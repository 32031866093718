<div>
    <label class="filterLabelStyle" style="margin-bottom: 5px;">{{label||"No Label"}}</label>
    <span *ngIf="validationForField" style="padding: 4px;color: #d11044;">*</span>
    <!-- Selcted Dropdown -->
    <ng-select 
        #sc 
        [disabled]="validationOnParentChecks"
        appearance="outline"
        [class.ng-select-validation]="validationForField"
        class="ngSelectionAlert" 
        [items]="option" 
        [multiple]="false" 
        [clearable]="false" 
        appendTo="body"
        [virtualScroll]="true"
        [placeholder]="placeholder" 
        [(ngModel)]="selectedOption" 
        [bindLabel]="labelProp" 
        (close)="onClose()"
        (change)="selectionChange()"
    >

        <!-- Search Header -->
        <!-- <ng-template ng-header-tmp>
            <input class="searchFiler" type="text" (input)="sc.filter(onFilterTextBoxChanged($event))" placeholder="Search" />
        </ng-template> -->

        <!-- Dropdown lable -->
        <ng-option *ngFor="let opt of option" [value]="opt">
            {{ opt[this.labelProp] }}
        </ng-option>
        
    </ng-select>
    <div *ngIf="validationForField">
        <span class="my-2 du-req-text">{{this.label + ' is required' }}</span> 
    </div>
</div>