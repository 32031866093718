import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-information',
  templateUrl: './help-information.component.html',
  styleUrls: ['./help-information.component.scss']
})
export class HelpInformationComponent implements OnInit {

  constructor() { }
  menuItems:any = [
    {
        "widget_nm": "Overview",
        "children": [
            {
                "widget_nm": "Introduction"
            },
            {
                "widget_nm": "How to Access the Dashboard"
            },
            {
                "widget_nm": "Landing Page"
            },
            {
                "widget_nm": "Common Icons for Dashboards"
            },
            {
                "widget_nm": "Pre-selected filters for Dashboards"
            }
        ]
    },
    {
        "widget_nm": "Operations Modules",
        "children": [
            {
                "widget_nm": "Data Processing Monitor",
                "children": [
                  {
                    "widget_nm": "Two Types of Dashboards"
                  },
                  {
                  "widget_nm": "Dashboard - Processed Date"
                 },
                 {
                "widget_nm": "Dashboard-Expected Date"
                 }
                ]
            },
            {
                "widget_nm": "Data Grid",
                "children": [
                  {
                    "widget_nm": "KPI (Key Performance Indicators) Cards"
                  },
                ]
            },
            {
                "widget_nm": "Dispense Trend",
                "children": [
                  {
                    "widget_nm": "Dispense Trend"
                  },
                  {
                   "widget_nm": "Dispense Trend by Location"
                 },
                 {
                   "widget_nm": "Shipped Patients"
                 },
                 {
                   "widget_nm": "KPI (Key Performance Indicators) Card"
                 }
                ]
            }
        ]
    },
    {
        "widget_nm": "Inventory Module",
        "children": [
            {
                "widget_nm": "Inventory Reconciliation"
            }
        ]
    },
    {
        "widget_nm": "Data Quality Monitor Module",
        "children": [
          {
            "widget_nm": "Dispense Trend",
            "children": [
                      {
                        "widget_nm": " Dispense Trend"
                      },
                      {
                       "widget_nm": "Dispense Trend by Location"
                     },
                     {
                       "widget_nm": "Shipped Patients"
                     },
                     {
                       "widget_nm": "KPI (Key Performance Indicators) Card"
                     }
                    ]
           },
            {
                "widget_nm": "Status Code Usage",
            },
            {
                "widget_nm": "Outstanding Defects",
                "children": [
                  {
                    "widget_nm": "Defects"
                  },
                  {
                   "widget_nm": "Outstanding Defects"
                 }
                ]
            },
            {
                "widget_nm": "SP Validation Rules",
            },
            {
                "widget_nm": "SP Scorecard",
            },
            {
                "widget_nm": "Data Quality Insights (Field Population Rate)",
            }
        ]
    }
]
  ngOnInit(): void {
  }
  addItem(data:any){
      // document.getElementById("point_2").scrollIntoView({behavior: "smooth"});
    let scroll:any =  document.getElementById(data.widget_nm)
    scroll.scrollIntoView({behavior: "smooth"});
  }

}
