import {
    Directive,
    EventEmitter,
    Input,
    OnInit,
    Output,
    reflectComponentType,
    ViewContainerRef
  } from '@angular/core'
  import * as _ from 'lodash';
  
  import Widgets from './widget.model'
  
  @Directive({
    standalone: true,
    selector: '[app-widget]',
  })
  export class WidgetDirective implements OnInit {
    @Input('app-widget') inputs: any
    @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  
    constructor (public viewContainerRef: ViewContainerRef) {
    }
  
    ngOnInit () {
      this.viewContainerRef.clear()
      const component = _.find(Widgets, { key: this.inputs.item.key })
      if (!component) return
      // @ts-ignore
      const inputs = _.map(reflectComponentType(component).inputs, 'propName')
      const componentRef = this.viewContainerRef.createComponent(component as any)
      // @ts-ignore
      componentRef.instance.onSizeChange?.subscribe(
        (v:any) => this.onSizeChange.emit(v)
      )
      _.each(this.inputs, (c, name) => {
        // @ts-ignore
        if (_.includes(inputs, name)) componentRef.instance[name] = c
      })
    }
  }