import { LoginComponent } from './components/auth/login/login.component';
import { DefaultLayoutComponent } from './components/layout/default-layout/default-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClientModelComponent } from './components/shared/client-model/client-model.component';
import { WorkDisplayComponent } from './components/dashboard/work-display-component/work-display.component';
import { SparklinesGridComponent } from './components/shared/charts/sparklines-Grid/sparklines-grid.component';
import { HelpCenterComponent } from './components/widget/help-center/help-center.component';
import { HierarchicalGridChartComponent } from './components/shared/charts/hierarchical-grid-chart/hierarchical-grid-chart.component';
import { ConfigTypeSelectionComponent } from './components/shared/config-type-selection/config-type-selection.component';
import { MultiLinechartComponent } from './components/widget/multi-linechart/multi-linechart.component';
import { AuthGuardGuard } from './services/auth-guard.guard';
import { HelpInformationComponent } from './components/widget/help-information/help-information.component';
import { StackedAreabarComponent } from './components/shared/charts/stacked-areabar/stacked-areabar.component';
import { WidgetPaymentSummaryComponent } from './components/widget/payment-summary/payment-summary.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'client',
    component: ClientModelComponent,
  },
  {
    path: 'config',
    component: ConfigTypeSelectionComponent,
  },

  // {
  //   path: "test",
  //   component: HelpCenterComponent,
  // },
  // {
  //   path: "help",
  //   component: HelpInformationComponent,
  // },
  // {
  //   path: "payment",
  //   component: HierarchicalGridChartComponent,
  // },
  {
    path: "work/:workKey/:version",
    component: WorkDisplayComponent,
  },
  {
    path: 'auth',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./components/dashboard/dashbaord.module').then(m => m.DashboardModule),
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [AuthGuardGuard]
})
export class AppRoutingModule { }
