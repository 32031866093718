import { Injectable } from '@angular/core';
// import Swal from 'sweetalert2';
const Swal = require('sweetalert2') 

@Injectable({
  providedIn: 'root'
})
export class AlertDialogService {

  constructor() { }

  success(message:any) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            position: 'center',
            title: 'Success!',
            text: message,
            customClass: {
                container: 'du-modal-container',
                popup: 'du-modal-popup',
                header: 'du-modal-header du-modal-alert',
                confirmButton: 'du-btn du-confirm-btn create-align float-none du-mt-2',
                cancelButton: 'du-btn du-cancel-btn create-align float-none du-mt-2'
            },
            showConfirmButton: true,
            allowOutsideClick: false,
            confirmButtonText: '<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Ok</span>',
        }).then((result:any) => {
            if (result.isConfirmed) {
                resolve(null);
            }
        });
    });
}

error(message:any) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            position: 'center',
            title: 'Error !',
            text: message,
            customClass: {
                container: 'du-modal-container',
                popup: 'du-modal-popup',
                header: 'du-modal-header du-modal-error',
                confirmButton: 'du-btn du-cancel-btn create-align float-none du-mt-2',
                cancelButton: 'du-btn du-cancel-btn create-align float-none du-mt-2'
            },
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Ok</span>',
        }).then((result:any) => {
            if (result.isConfirmed) {
                resolve(null);
            }
        });
    });
}

warning(message:any) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            position: 'center',
            title:  ' ',
            text:'🛈️ '+ message,
            customClass: {
                container: 'du-modal-container',
                popup: 'du-modal-popup',
                header: 'du-modal-header du-modal-alert',
                confirmButton: 'du-btn du-confirm-btn create-align float-none du-mt-2',
                cancelButton: 'du-btn du-cancel-btn create-align float-none du-mt-2'
            },
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Ok</span>',
        }).then((result:any) => {
            if (result.isConfirmed) {
                resolve(null);
            }
        });
    });
}

confirmClose(message='') {
    let defaultMessage='You have unsaved changes. Are you sure you want to close?';
    let defaultConfirmText='<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Confirm</span>';
    let defaultCancelText='Cancel';
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: 'Alert !',
            text:message ? message : defaultMessage,
            allowOutsideClick: false,
            customClass: {
                container: 'du-modal-container',
                popup: 'du-modal-popup',
                header: 'du-modal-header du-modal-alert',
                confirmButton: 'du-btn du-confirm-btn create-align float-none du-mt-2',
                cancelButton: 'du-btn du-cancel-btn create-align float-none du-mt-2'
            },
            showCancelButton: true,

            confirmButtonText:message ?'<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Yes</span>' :defaultConfirmText,
            cancelButtonText:message ?'<i class="fa fa-times-circle fa-icon du-mt-2"></i><span class="du-btn-text">No</span>' :'<i class="fa fa-times-circle fa-icon du-mt-2"></i><span class="du-btn-text">Cancel</span>',

        }).then((result:any) => {
            if (result.isConfirmed) {
                resolve(null);
            }
        });
    });
  }

  confirmationWindow(message:any) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: 'Warning',
            text: message,
            allowOutsideClick: false,
            customClass: {
                container: 'du-modal-container',
                popup: 'du-modal-popup',
                header: 'du-modal-header du-modal-alert',
                confirmButton: 'du-btn du-confirm-btn create-align float-none du-mt-2',
                cancelButton: 'du-btn du-cancel-btn create-align float-none du-mt-2'
            },
            showCancelButton: true,
            confirmButtonText: '<i class="fa fa-check-circle fa-icon du-mt-2"></i><span class="du-btn-text">Confirm</span>',
            cancelButtonText: '<i class="fa fa-times-circle fa-icon du-mt-2"></i><span class="du-btn-text">Cancel</span>',

        }).then((result:any) => {
            if (result.isConfirmed) {
                resolve(null);
            }
        });
    });
  }
}
