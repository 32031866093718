import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit {
  static key = 100105;
  
  showAlerts: boolean = true;
  tableData: any;
  record_value: any = 0;
  response: any = [];
  spliceRecord: any;
  showRecords: boolean = false;
  tabStyle = "Form"
  tabStyleData: any;
  showButtons: boolean = true;
  tabHeadingArray: any;
  @Input('item') item: any
  apiList: any;
  currentTab: any;
  cardNameArray: any = [];
  defaultCardName: any;
  formRange!: FormGroup<any>;
  widgets: any = [];
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  patientId: any;
  stateCtrl = new FormControl('');
  reqSubcription: Subscription[] = [];
  cardName: any;
  cardId: any;

  @Output('onSizeChange') onSizeChange = new EventEmitter<any>();
  @ViewChild('patientCardDiv', { static: true }) patientCardDiv!: ElementRef;
  
  dynamicHeight: any;
  showLoader: boolean = false
  employeeKey: any;
  error: boolean = false;
  loader = true;
  
  constructor(
    private filterService: NewFilterService,
    private navigationService: NavigationService,
    private router: ActivatedRoute,
  ) {
  }


  ngOnInit(): void {
    this.employeeKey = sessionStorage?.getItem("employee_key");
    if (this.employeeKey) {
      let key = JSON.parse(this.employeeKey);
      this.employeeKey = key["_value"];
    }
    this.showLoader = true;

    this.router.params.subscribe((p: any) => {
      this.cardName = p['cardName'] || '';

      this.router.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.patientId = decryptedParams["cardId"];
        this.cardId = decryptedParams["cardId"];
      });
    });


    this.widgets = this.item;
    this.getTotalTabNamesData()

    this.dynamicHeight = (this.item.rows * 20) - 20;
    
    this.onChange();

    setTimeout(() => {
      const observer = new ResizeObserver((e: any) => {
        this.onSizeChange.emit(e[0].contentRect.height);
      });
      observer.observe(this.patientCardDiv.nativeElement);
    }, 3000);

  }
  dynamicHeights() {
    return { height: this.dynamicHeight + 'px', overflow: 'hidden' }
  }

  getVisble() {
    return { height: 'auto', overflow: 'visible' }
  }

  //  To Get the Total Data of Headings of Each Tab
  getTotalTabNamesData() {
    this.showLoader = true
    this.tabHeadingArray = this.widgets.config.information_card_details[0].card_details
    this.getSplicedRecords()
  }

  
  //  On Tab Change Click Event
  onChange(event?: MatTabChangeEvent | any) {
    this.currentTab = event?.tab.textLabel || 'General'
    // debugger
    this.tabHeadingArray.find((i: any) => {
      if (i.startValue == this.currentTab) {
        const obj = {
          api_key: i.selectionDropdown,
          report_typ: "D",
          client_key: this.clientKey || "",
          patient_id: this.patientId,
          employee_key: this.employeeKey ? this.employeeKey : "",

        }
        this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe(
          (resp: any) => {
            this.error = false
            if (resp) {
              const data = resp[0]
              let result = Object.entries(data).map(([k, v]) => ({ id: k, value: v }));
              if (i.rowValue != '') {
                this.spliceRecord = (i.rowValue * 4)
              } else {
                this.spliceRecord = 2
              }

              if (this.spliceRecord < result.length) {
                this.showButtons = true
              } else this.showButtons = false

              if (i.selectStyle == 'Form') {
                this.response = result.splice(0, this.spliceRecord)
                this.tableData = this.response
                this.showRecords = false
              } else if (i.selectStyle == 'Tab') {
                this.tabStyleData = result
                this.showRecords = false
              } else {
                this.response = result.splice(0, this.spliceRecord)
                this.tableData = this.response
                this.showRecords = false
              }

            }
          }, (error: any) => {
            this.tableData = []
            this.tabStyleData = []
            this.error = true
          }
        ))
      }
    })


  }


  // Get Less Data on Basis of Row For 1st Tab on Initial Load
  getSplicedRecords() {
    this.showLoader = true
    // for (let i = 0; i < this.tabHeadingArray.length; i++) {
    const record = this.tabHeadingArray[0].selectionDropdown
    const style = this.tabHeadingArray[0].selectStyle
    this.currentTab = this.tabHeadingArray[0].startValue
    this.record_value = this.tabHeadingArray[0].rowValue

    // this.apiList?.find((j: any) => {
    // if (j.api_display_nm == record) {
    const obj = {
      api_key: record,
      report_typ: "D",
      client_key: this.clientKey || "",
      patient_id: this.patientId,
      employee_key: this.employeeKey ? this.employeeKey : "",

    }
    this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
      if (resp) {
        const data = resp[0]
        let result = Object.entries(data).map(([k, v]) => ({ id: k, value: v }));
        this.spliceRecord = (this.record_value * 4)
        this.showLoader = true
        if (this.spliceRecord < result.length) {
          this.showButtons = true
        } else this.showButtons = false

        if (style == 'Form') {
          this.response = result.splice(0, this.spliceRecord)
          this.tableData = this.response
          this.showRecords = false
        } else if (style == 'Tab') {
          this.tabStyleData = result
          this.showRecords = false
        }
      }
    }
    ))

    // }
    // })

    // }

    // this.dataService.getJson("patient-card").subscribe((resp:any) => {
    //   this.spliceRecord = (this.record_value * 4)
    //   if(this.spliceRecord < resp.card_information.length)
    //   {
    //     this.showButtons = true
    //   } else this.showButtons = false
    //   this.response = resp.card_information
    //   this.response = this.response.splice(0,this.spliceRecord)
    //   this.tableData = this.response
    //   this.showRecords = false
    // })
  }

  // Show More Functionality
  getTotalRecords(event: any) {

    this.tabHeadingArray.find((i: any) => {
      if (i.startValue == this.currentTab) {
        // this.apiList.find((j: any) => {
        // if (j.api_display_nm == i.selectionDropdown) {
        const obj = {
          api_key: i.selectionDropdown,
          report_typ: "D",
          client_key: this.clientKey || "",
          patient_id: this.patientId,
          employee_key: this.employeeKey ? this.employeeKey : "",


        }
        this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
          if (resp) {
            const data = resp[0]
            let result = Object.entries(data).map(([k, v]) => ({ id: k, value: v }));
            this.response = result
            this.tableData = this.response
            this.showRecords = true

          }
        }))

        // }
        // })
      }
    })


    // this.dataService.getJson("patient-card").subscribe((resp:any) => {
    //   this.response = resp.card_information
    //   this.tableData = this.response
    //   this.showRecords = true
    // })


  }

  // Show Less Functionality
  getTrimmedRecords() {
    this.tabHeadingArray.find((i: any) => {
      if (i.startValue == this.currentTab) {
        // this.apiList.find((j: any) => {
        // if (j.api_display_nm == i.selectionDropdown) {
        const obj = {
          api_key: i.selectionDropdown,
          report_typ: "D",
          client_key: this.clientKey || "",
          patient_id: this.patientId,
          employee_key: this.employeeKey ? this.employeeKey : "",

        }
        this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((resp: any) => {
          if (resp) {
            const data = resp[0]
            let result = Object.entries(data).map(([k, v]) => ({ id: k, value: v }));
            this.spliceRecord = (i.rowValue * 4)

            if (this.spliceRecord < result.length) {
              this.showButtons = true
            } else this.showButtons = false

            this.response = result.splice(0, this.spliceRecord)
            this.tableData = this.response
            this.showRecords = false

          }
        }))

        // }
        // })
      }
    })


  }


  // Alerts Notification
  showNotifications() {
    this.showAlerts = false
  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

}
