<div class="widget-container" #TerritoryDiv>
  <loading *ngIf="loader"></loading>
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <div>
        <icon-chart [headerConfig]="headerConfig"
                [iconList]="iconList" [data]="data"
                [pageKey]="pageKey" [heading]="item.config['report_heading']"
                [chartName]="'TerritoryDiv'" [expand]="fs"
                [fullscreen]="isFullscreen" [config]="TerritoryDiv" [item]="item" [apiKey]="100710"></icon-chart>
      </div>
      <div style="display: flex; gap: 10px;" #TerritoryDiv>
        <!-- background-color: #eaeaf4;border-radius: 20px;margin: 10px; -->
        <div [style.width]="isFullscreen ? '70%' : '60%'" style="width: 60%;">
          <duc-map *ngIf="mapConfig" [config]="mapConfig" [data]="mapData">
            <duc-tooltip [config]="$any(config.components[1])">
              <ng-template #custom let-data>
                {{ data.items[0].data.employee_first_name }} {{ data.items[0].data.employee_last_name }}
              </ng-template>
            </duc-tooltip>
          </duc-map>
        </div>


        <div  class="scrollttm"  [style.width]="isFullscreen ? '30%' : '40%'" style="overflow: auto;" [style.height.px]="isFullscreen ? window.outerHeight-35 : 530">
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngFor="let userd of users"  style="margin: 10px 3px;border-radius: 15px;border: 1px solid #E8EAEE;color:#101D42;">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="headertext">{{ userd.name }}</span>
                  <span class="badges">{{ userd.count }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let user of userd.data">
                <hr/>
                <!-- followUp template -->
                <div style="display:flex" *ngIf="userd.type == 'followUp'">
                  <div style="width:90%">
                    <div class="maindetail">{{ user.username }}</div>
                    <div class="address">
                      {{user.address.replaceAll(",,",',')}}
                      <!-- {{user.address}} -->
                      <!-- {{ user.address.street }},{{ user.address.city }},{{
                        user.address.zipcode
                      }} -->
                    </div>
                  </div>
                  <div style="width: 10%;margin: 10px auto;text-align: center;">
                    <!-- <mat-checkbox class="example-margin" matTooltip="Mark for follow up"
                            matTooltipPosition="above" [(ngModel)]="user.follow"
                            color="primary"></mat-checkbox> -->
                  </div>
                </div>
                <!-- progressBar template -->
                <div style="display:flex" *ngIf="userd.type == 'progressBar'">
                  <div style="width:90%">
                    <div class="maindetail">{{ user.username }}</div>
                    <div style="display:flex">
                      <mat-progress-bar mode="determinate" style="margin:auto" [ngClass]="'custom-progress-bar'"
                      [value]="calculatePercentage(user.volume ,userd.data)"></mat-progress-bar>
                              <!-- [value]="(userd.volume / lodash.maxBy(userd.data, 'volume')) *100"></mat-progress-bar> -->

                      <div style="width:10%;margin-left:10px">
                        <span>{{ transform(user.volume) }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 10%;margin: 10px auto;text-align: center;">
                    <!-- <mat-checkbox class="example-margin" matTooltip="Mark for follow up"
                            matTooltipPosition="above" [(ngModel)]="user.follow"
                            color="primary"></mat-checkbox> -->
                  </div>
                </div>
                <!-- progressBar template -->
                <div style="display:flex" *ngIf="userd.type == 'progressBarnumber'">
                  <div style="width:90%">
                    <div class="maindetail">{{ user.username }}</div>
                    <div style="display:flex">
                      <mat-progress-bar mode="determinate" style="margin:auto" color="warn" [ngClass]="'warn-progress-bar'"
                              [value]="calculatePercentage(user.volume ,userd.data)"></mat-progress-bar>

                      <div style="width:10%;margin-left:10px">
                        <span>{{ user.volume }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 10%;margin: 10px auto;text-align: center;">
                    <!-- <mat-checkbox class="example-margin" matTooltip="Mark for follow up"
                            matTooltipPosition="above" [(ngModel)]="user.follow"
                            color="primary"></mat-checkbox> -->
                  </div>
                </div>
                <!-- progressBar template -->
                <div style="display:flex" *ngIf="userd.type == 'progressBardays'">
                  <div style="width:90%">
                    <div class="maindetail">{{ user.username }}</div>
                    <div style="display:flex">
                      <mat-progress-bar mode="determinate"  [ngClass]="'custom-progress-bar'" style="margin:auto"  
                              [value]="calculatePercentage(user.volume ,userd.data)"></mat-progress-bar>

                      <div style="width:20%;margin-left:10px">
                        <span>{{ user.volume + ' ' + 'days' }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 10%;margin: 10px auto;text-align: center;">
                    <!-- <mat-checkbox class="example-margin" matTooltip="Mark for follow up"
                            matTooltipPosition="above" [(ngModel)]="user.follow"
                            color="primary"></mat-checkbox> -->
                  </div>
                </div>
                <!-- progressBar template -->
                <div style="display:flex" *ngIf="userd.type == 'profilelist'">
                  <div>
                    <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png"
                            alt="Profile">
                    <!-- <mat-icon style="vertical-align: bottom;">account_circle</mat-icon> -->
                  </div>
                  <div style="width:80%; margin-left:5px">
                    <div>
                      <span class="profiletext">{{ user.name }}</span>
                    </div>
                    <div class="maindetail">{{ user.username }}</div>
                    <div class="date">{{ user.date }}</div>
                  </div>
                  <div style="width: 10%;margin: 10px auto;text-align: center;">
                    <mat-icon color="primary" style="font-size:30px">keyboard_arrow_right</mat-icon>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </article>
  </main>
</div>