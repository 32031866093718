<div class="widget-container" #pivotGridDiv>
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
  <loading *ngIf="loader"></loading>
  <div>
    <icon-chart 
        [headerConfig]="this.item.config.widget_header_config" 
        [iconList]="iconList" 
        [data]="rowData" 
        [pageKey]="pageKey" 
        [heading]="item.config['report_heading']"
        [chartName]="'paymentSummary'" 
        [config]="aggrid" 
        [item]="item"
        [expand]="fs"   
        (expendCol)="expendCol($event)"
        (screenChanges)="screenChanges($event)"
      ></icon-chart>
  </div>
  
  
  <div style="height: 400px; width: auto; " *ngIf="noData">
    <span class="bg-texts">There is no data for this time period</span>
  </div>
  <div class="example-wrapper" #aggrid [style.display]="noData?'none':''">
    <ag-grid-angular
      [ngStyle]="{ 'width':'100%','padding': '10px',  'height': isFullscreen ? 'auto':'460px'}"
      [ngClass]="{'ag-theme-balham':true,'du-ps-grid':true, 'du-ps-grid-fullscreen':isFullscreen}"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [animateRows]="true"
      [pivotMode]="true"
      [rowHeight]="40"
      [rowData]="rowData"
      [headerHeight]="30"
      [pivotTotals]="true"
      [suppressDragLeaveHidesColumns]="true"
      [suppressAggFuncInHeader]="true"
      [groupDefaultExpanded]="1"
      (gridReady)="onGridReady($event)"
      [groupHideOpenParents]="true"  
      [groupDisplayType]="groupDisplayType"
      [removePivotHeaderRowWhenSingleValueColumn]="true"
      [getRowStyle]="getRowStyle"
      [suppressContextMenu]="true" 
      ></ag-grid-angular>
      <!-- [sideBar]="sideBar" -->
      <!-- [pivotHeaderHeight]="0" -->
      <!-- [processPivotResultColGroupDef]="processSecondaryColGroupDef" -->
      <!-- [overlayNoRowsTemplate]="overlayNoRowsTemplate" -->
  </div>

</article>
</main>
</div>
