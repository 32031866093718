import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from 'src/app/services/filter.service';
import { EdiitingGridPopupComponent } from '../ediiting-grid-popup/ediiting-grid-popup.component';

@Component({
  selector: 'app-detail-grid-editing',
  templateUrl: './detail-grid-editing.component.html',
  styleUrls: ['./detail-grid-editing.component.scss']
})
export class DetailGridEditingComponent {

  editIcon = 'edit_grid_icon';
  addIcon = 'plus_grid_icon';
  params:any;
  record_detail:any = [];

  constructor(private filterService: FilterService, public dialog: MatDialog, private cdr:ChangeDetectorRef) {}

  agInit(params: any): void {
    // debugger
    this.params = params
    this.record_detail = this.params.data.record_detail;
   }

  refresh(params: any): boolean {
    return false;
  }

  editBuckets(data:any, file:any, idx:any, value:any): void {
    // debugger
    let configuration = this.params.data.configuration
    const dialogRef = this.dialog.open(EdiitingGridPopupComponent, {
      data: {data, file, value, configuration},
      hasBackdrop: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // debugger
      if(result) {
        let change = this.params.data.record_detail.find((o:any)=> o.file_type_key==result.file_type_dim_key&&o.source_key==result.source_key)
        if(change) {
          change.ir_days = result.ir_days;
          change.data_service_fee = result.data_service_fee
        }
        const newValue = this.params.data.record_detail.reduce((acc: any, name: any) => acc + name['data_service_fee'], 0);
        this.params.data['data_service_fee'] = newValue;
        this.filterService.dataPartnerRefreshingGrid.next(true)
        this.cdr.detectChanges()
      }
    });
  }

}
