import { Platform } from '@angular/cdk/platform';
import { Injectable, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.detectDevice();

    // Listen for window resize event
    // fromEvent(window, 'resize').subscribe(() => {
    //    
    //   this.detectDevice();
    // });
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.detectDevice();
  }

  private detectDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }
}