

<div #WordCloud style="font-family: Poppins !important">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <!-- <button mat-raised-button color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
      <div style="background-color: #ffff;">
          <icon-chart 
            [headerConfig]="headerConfig" 
            [iconList]="iconList" 
            [data]="data" 
            [pageKey]="pageKey" 
            [heading]="heading"
            chartName="wordCloud" 
            [config]="wordCloudContainer" 
            [expand]="fs" 
            [fullscreen]="isFullscreen"
            [item]="item"
          ></icon-chart>
      </div>
     
      <div><loading *ngIf="isLoading"> </loading></div> 
      <div #WordCloudChart>
          <div [style.display]="noData?'none':''" #wordCloudContainer></div>
    <div style="height: 500px; width: auto; " *ngIf="noData">
     <span class="bg-text" >There is no data for this time period</span></div>
     <!-- {
      "text": "Primary ICD10 code",
      "value": 1476,
      "fill": "#8C23E6",
      "size": 30,
      "font": "Poppins",
      "style": "normal",
      "weight": 700,
      "rotate": 90,
      "padding": 2,
      "x": -34,
      "y": 27,
      "width": 64,
      "height": 329,
      "xoff": 0,
      "yoff": 0,
      "x1": 32,
      "y1": 163,
      "x0": -32,
      "y0": -149,
      "hasText": true
  } -->
     <div id="d3WordCloudTooltip">
      <!-- <div  style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData?.text}}</div> -->
        <div class="container chart_tooltip" *ngIf="tooltip" >
          <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column">
              <div class="chart_tooltip_header_heading mt-1 mb-1">
                {{heading }}
              </div>
             
            </div>
          </div>
          <div class="mt-2 mb-5" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class="row mt-1 pb-3" >
              <div class="col-12 d-flex m-auto  align-items-center">
               
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData?.fill" ></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{mytooltipData?.text }} : {{mytooltipData?.value}}</div>
                
              </div>
            </div>
          </div>

        </div>
      </div> 
     </div>
    </article>
  </main>
</div>