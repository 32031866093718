import { Injectable } from '@angular/core';

import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';
import * as htmlToImage from 'html-to-image';

@Injectable({
    providedIn: 'root'
})
export class ExportPPTService {

    constructor() { }

    captureElementToBase64(element: HTMLElement): Promise<string> {
        return new Promise((resolve, reject) => {
          html2canvas(element).then((canvas: HTMLCanvasElement) => {
            canvas.toBlob((blob: Blob | any) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data);
              };
            });
          }).catch((error: any) => {
            reject(error);
          });
        });
      }
      
    private captureElement(element: HTMLElement): Promise<HTMLCanvasElement> {
        return new Promise((resolve, reject) => {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setTimeout(() => {

                // htmlToImage.toCanvas(element)
                // .then(function (dataUrl) {
                //     resolve(dataUrl);
                // })
                // .catch(function (error) {
                //     console.error('oops, something went wrong!', error);
                // });

                html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
                    resolve(canvas);
                }).catch((error) => {
                    reject(error);
                });
            }, 2000); // Increased timeout to ensure element is rendered
        });
    }

    private sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private async captureAllElements(elements: HTMLElement[]): Promise<{ canvas: HTMLCanvasElement, rect: DOMRect }[]> {
        const results: { canvas: HTMLCanvasElement, rect: DOMRect }[] = [];
        for (const element of elements) {
            try {
                const canvas = await this.captureElement(element);
                const rect = element.getBoundingClientRect();
                results.push({ canvas, rect });
            } catch (error) {
                console.error('Error capturing element:', error);
            }
            await this.sleep(2000); // Wait for 2 seconds between captures
        }
        return results;
    }

    async exportToPpt(containers: HTMLElement[], headerName: string): Promise<void> {
        const pptx = new pptxgen();
        pptx.layout = 'LAYOUT_WIDE';

        pptx.defineSlideMaster({
            title: 'MASTER_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                {
                    image: {
                        x: 11.3,
                        y: 6.4,
                        w: 1.67,
                        h: 0.65,
                        path: 'assets/SCRAII_Logo.png',
                    },
                },
            ],
            slideNumber: { x: 0.3, y: '90%' },
        });

        const results = await this.captureAllElements(containers);

        let slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
        const margin = 0.2;
        const slideWidth = 13.33 - (2 * margin);
        const maxY = 7.0 - margin; // Maximum Y position minus margin

        const addHeader = (slide: pptxgen.Slide) => {
            slide.addText(
                [{ text: headerName, options: { color: '#212529', fontSize: 14, fontFace: 'Poppins Black' } }],
                { x: margin, y: margin }
            );
        };

        addHeader(slide);
        let currentY = 1.0 + margin; // Initial Y position after header

        results.forEach(({ canvas, rect }, index) => {
            const itemWidth = rect.width / 96;
            const itemHeight = rect.height / 96;

            let adjustedWidth = itemWidth;
            let adjustedHeight = itemHeight;

            if (itemWidth > slideWidth) {
                const scaleFactor = slideWidth / itemWidth;
                adjustedWidth = slideWidth;
                adjustedHeight = itemHeight * scaleFactor;
            }

            // Check if the item fits on the current slide
            if (currentY + adjustedHeight > maxY) {
                slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
                currentY = 1.0 + margin; // Reset Y position for new slide
                addHeader(slide);
            }

            slide.addImage({
                data: canvas.toDataURL(),
                x: margin,
                y: currentY,
                w: adjustedWidth,
                h: adjustedHeight
            });

            currentY += adjustedHeight + margin; // Update current Y position
        });

        pptx.writeFile({ fileName: headerName + '.pptx' });
    }
}
