<div class="pending_patient_tooltip">
  
  <div class="pending_patient_header container py-2">
    <div class="d-flex justify-content-between align-items-center">
      <span class="pending_patient_header_heading">{{popupHeading}}</span>
      <mat-icon style="font-size: 15px;padding: 5px 5px;color: #fff;cursor: pointer;" (click)="hidePendingLinkTooltip()">close</mat-icon>
    </div>
    <div>
      <span class="pending_patient_header_data">{{PendingPatients}}</span>
    </div>
  </div>
  
  <div class="mt-2 pending_patient_body mb-3 container">
    <div class="row mt-2 pb-2 align-items-center" *ngFor="let item of toolTipData">

      <div class="col-1">
        <div class="tooltip_outlay" [style.background]="item.color ? item.color : 'red'"></div>
      </div>

      <div class="col-5 pr-0 ml-0">
        <span class="pending_patient_body_data">{{ item?.count ? isNumber(item?.count) ? this.numberPipe.transform(item.count) : item.count : 0 }} {{item?.status}}
        </span>
      </div>

      <div class="w-auto text-start" *ngFor="let data of popupConfig">
        <div class="pending_patient_body_data_text" >
          <a (click)="routeHandler(data, item)" name="Patients" style="cursor:pointer; color: #1363df;">{{data.disp_name}}</a>
        </div>
      </div>

    </div>
  </div>
</div>