import { Component, ElementRef, EventEmitter, HostBinding, Injectable, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, RowGroupingDisplayType, SideBarDef } from 'ag-grid-community';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { LicenseManager } from 'ag-grid-enterprise'
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { color } from 'd3';
import * as _ from 'lodash'
import { QuarterSelectionComponent } from '../../shared/custom-input-type/quarter-selection/quarter-selection.type';
import { ValueBtnComponent } from '../basic-grid-list/value-btn.component';
import { EnhancementService } from 'src/app/services/enhancements-service';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043433 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data Unveil )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SCRAII )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SCRAII )_need_to_be_licensed___( SCRAII )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 June 2024 )____[v2]_MTcxNzcxNDgwMDAwMA==8211f96f946c59b6a3dfa3ea03a04f25")
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class WidgetPaymentSummaryComponent implements OnInit, OnDestroy {
  static key = 100812;
  
  @Input('item') item: any = {}
  @Input('pageKey') pageKey: any
  iconList: any
  isActive:boolean=false
  @ViewChild("fs") fs!: ElementRef;
  noData:boolean=false
  currentVisibleData: any = [];
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  reqSubcription: Subscription[] = []
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>()
  @ViewChild('pivotGridDiv', { static: true }) pivotGridDiv!: ElementRef
  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';
  public   columnDefs: ColDef[] = []
  public groupDisplayType: RowGroupingDisplayType = "multipleColumns"
  rowData            : any     = []
  isDataLoaded       :Boolean  = false
  expandCollapseIcon :string   = "expand"
  expandOrCollapse   :string   = "Expand All"
  data               : any     = []
  uniquesourceNames  : any
  loader             : boolean = true
  levels             : any     = []
  public autoGroupColumnDef: ColDef = {
    // headerName        : 'SPs',
    // headerClass:'text-left',
    minWidth          : 200,
    // pinned            : this.deviceDetectionService.isMobile ? null: 'left',
    cellRendererParams: {
      suppressDoubleClickExpand: true,
      suppressCount: true,
      cellStyle: {  'min-height':'40px' , 'line-height' : '40px'},
    }
  }
 
  public defaultColDef: ColDef<any> = {
    // flex: 1,
    // minWidth: 50,
    resizable: true,
    sortable: true,
    cellStyle: { textAlign: 'center' ,'line-height' : '40px' ,color:'#101D42' ,'font-weight': 600 },
    suppressMenu:true,
    

  };
  
  public gridApi!: any;
  public gridColumnApi: any;
  showBy: any
  constructor(
    public deviceDetectionService: DeviceDetectionService,
    private filterService: FilterService,
    private newFilterService: NewFilterService,
    private numberPipe: NumberPipe,
    private httpClient: HttpClient,
    private apiService: ApiService,
    public enhancementService:EnhancementService
  ) {

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData()
      this.onBtShowLoading()
    }))

    // this.newFilterService.showBy.subscribe((value: any) => {
    //   this.showBy = value
    //   // this.loadDataGridConfig()

    // })
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.addEventListener('rowGroupOpened', this.onRowGroupOpened.bind(this));
  }
  onRowGroupOpened(event: any): void {
    // Check if all autoGroupColumnDef groups are closed
    let allGroupsClosed = false;
    
    this.gridApi.forEachNode((node:any) => {
      if (node.group && node.expanded) {
        allGroupsClosed = true;
      }
    });

    if(allGroupsClosed) {
      this.expandCollapseIcon = "collapse";
      this.expandOrCollapse = "Collapse All";
    } else {
      this.expandCollapseIcon = "expand";
      this.expandOrCollapse = "Expand All";
    }

    this.filterService.expendTree.next(allGroupsClosed)
  }
  onBtShowLoading() {
    this.loader = true
  }
  onBtHide() {
    this.loader = false
  }
  exportXls() {
    this.gridApi.exportDataAsExcel({ fileName: this.item.config['report_heading'] });
  }

  
  expendCol(event: any) {
     
    if (event) {
      this.gridApi.expandAll();
    } else {
      this.gridApi.collapseAll()
    }
  }


  ngOnInit(): void {

    this.onBtShowLoading()
    this.sideBar  = {
      toolPanels: [
        {
          id             : 'columns',
          labelDefault   : 'Columns',
          labelKey       : 'columns',
          iconKey        : 'columns',
          toolPanel      : 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues   : false,
            suppressPivots   : false,
            suppressPivotMode: true,
            suppressRowGroups: false,
          // suppressSideButtons: false,
          // suppressColumnFilter: false,
          // suppressColumnExpandAll: false,
          // suppressColumnSelectAll: false,
          // suppressSyncLayoutWithGrid: true,
          },
        },
      ],
      position: 'right',
    };
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    });
    observer.observe(this.pivotGridDiv.nativeElement);
    this.loadDataGridConfig()
  }
  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  ngOnChanges(changes: any): void {
  }

  loadDataGridConfig() {
    if (this.item == undefined) return

    for (let i = 1; i < 10; i++) {
      const headerName = this.item.config?.['l' + i + '_col']?.['api_resp_column_display_nm']
      const element    = this.item.config?.['l' + i + '_col']?.['api_resp_column_nm']
      if (element != undefined && headerName != undefined) {
        this.levels.push({
          headerName    : headerName,
          field         : element,
          rowGroup      : true,
          resizable     : true,
          // sort:'asc',
          // cellStyle     : { textAlign: 'center' },
          enableRowGroup: true,
          valueFormatter: (params: any) =>{ if(params.value=='report'){return "Report"}; return params.value}
        },)
      }
    }
    this.columnDefs = [...this.levels,
    {
      headerName : this.item.config.x_axis_column["api_resp_column_display_nm"],
      field      : this.item.config.x_axis_column['api_resp_column_nm'],
      pivot      : true,
      enablePivot: true,
      
        // Set the pivotComparator to sort in descending order
        pivotComparator:(a: string, b: string) => {
              let displayedRows: any = [];
          // Iterate over each node after filter and sort
          this.gridApi.forEachNodeAfterFilterAndSort((node:any) => {
            if (node.data != undefined) {
              displayedRows.push(node.data);
            }
          });
          const order:any = _.uniq(_.map(displayedRows, 'file_type_name'));
              return order.indexOf(a) - order.indexOf(b);
            }
      // pivotComparator: (valueA: any, valueB: any) => valueB.localeCompare(valueA)
    },
    {
      headerName: this.item.config.value_column["api_resp_column_display_nm"],
      field: this.item.config.value_column['api_resp_column_nm'].toLowerCase(),
      valueGetter: p => `${p.data?.[this.item.config.value_column['api_resp_column_nm']]}|${p.data?.penalty_flg}`,
      aggFunc: params => {
        const total = params.values.reduce((sum, value) => sum + parseFloat(typeof value === 'number' ? value : value.split("|")[0] || 0), 0);
        const penaltyFlag = params.values.length > 0 ? params.values[0].split("|")[1] : ' ';
        return `${total === 0 ? '' : total}|${penaltyFlag}`;
      },
      comparator: (valueA, valueB) => {
        const numA = parseFloat(valueA.split("|")[0]) || 0;
        const numB = parseFloat(valueB.split("|")[0]) || 0;
        return numA - numB;
      },
      cellRenderer: (params:any) => {
        const [value, penaltyFlag] = params.value.split("|");
        const formattedValue =  this.numberPipe.transform(value )||'';
        const className = penaltyFlag === 'Y' ? 'DU-Ps-Batch' : '';
        return `<span class="${className}">${formattedValue!==''?'$'+formattedValue:formattedValue}</span>`;
      },
      cellStyle: { textAlign: 'right' },
      enableValue: true,
      // autoHeight:true,
      // wrapHeaderText:true,
      width: 120
    }
    ];
 
  }



  loadApiData() {
    this.expandCollapseIcon = "expand"
    this.expandOrCollapse   = "Expand All"
    let api:any = {}//this.item?.config ? this.item?.config['list_of_api'] : {};
    api['api_key'] =100199
    if (api?.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {
      // Api Payload
      let obj: any = { "api_key":   100199 ||api.api_key }  

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      if(this.enhancementService.currentQuarterFlag ) {
        query["table_nm_version"] = 'curr'
      } else {
        query["table_nm_version"] = 'hist'
      }
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          this.data = res ? res : [];
          if(this.data.length==0){this.noData=true}else {this.noData=false}
             this.rowData = [ ...this.calculateTotal(),...this.data  ]; 
            //  this.uniquesourceNames = _.uniq(_.map(this.rowData, 'source_name'));
            this.onBtHide()
            this.gridApi.expandAll();
            setTimeout(() => {
              this.gridColumnApi.autoSizeAllColumns();
            }, 200);
          this.isDataLoaded = true
        }, (err: any) => {
          this.data = []
          this.rowData = []
          this.noData=true
          this.onBtHide()
        })
      )
    }
  }
  
  calculateTotal() {
    const totals = _.map(_.groupBy(this.data, item => `${item.source_name}/${item.quarter}`), (items, key) => {
      const [source_name, quarter] = key.split('/');
      return {
        source_name,
        file_type_name: 'Total',
        quarter,
        amount_payable: _.sumBy(items, 'amount_payable'),
        penalty_flg: _.find(items,flg=>flg.penalty_flg=='Y')?.['penalty_flg']||'N'
      };
    });
    //  console.log(totals);
  return totals
  }
  
  
  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }
  screenChanges(event:any) {
    this.isActive = event
    this.isFullscreen = event
  }
   public getRowStyle:any =(params:any)=> {
    const sourceName = params.node.parent.key; 
    let displayedRows: any = [];
    // Iterate over each node after filter and sort
    this.gridApi.forEachNodeAfterFilterAndSort((node:any) => {
      if (node.data != undefined) {
        displayedRows.push(node.data);
      }
    });
    this.uniquesourceNames = _.uniq(_.map(displayedRows, 'source_name'));

    // Find the corresponding row in rowData for the current source_name
    const rowEntry = this.uniquesourceNames.find((row: any) => row === sourceName );

    // Check if the rowEntry is found and if its index is even
    if (rowEntry && this.uniquesourceNames.indexOf(rowEntry) % 2 === 0) {
      // console.log(sourceName , this.rowData.indexOf(rowEntry) )
      return { background: '#f2f2f2' }; // Gray background for even indexed source_name
    }
    // console.log(sourceName ,this.rowData.indexOf(rowEntry) )

    return { background: '#fff' };; // Default styling for odd indexed source_name or if rowEntry is not found
  }

}
