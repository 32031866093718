import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardGuard implements CanActivate, CanDeactivate<boolean> {

  constructor(
    public authService: AuthService,
    public router: Router) {

  }

  canActivate(): boolean {
    if (!this.authService.isLoggedIn()) {
      this.authService.logoutAndRedirect();
    }
    return this.authService.isLoggedIn();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return confirm('Discard unsaved changes?');
  }

}
