
     
      <!-- <div><loading *ngIf="isLoading"> </loading></div> -->

      <div #WordCloudChart>

        <div [style.display]="noData?'none':''" > 
          <div  #wordCloudContainer  style="margin: 0; padding: 0;"></div>
          <div id="HbarChartDiv" style="margin: 0; padding: 0; overflow-y: auto"></div>
        </div>
        
          <!-- <div [style.display]="noData?'none':''" #wordCloudContainer></div> -->
    <div style="height: 500px; width: auto; " *ngIf="noData">
     <span class="bg-text" >There is no data for this time period</span></div>


     <div id="d3WordCloudTooltip">
      <div *ngIf="tooltipType=='groupFullName'"  style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize ;font-size: 12px;"> {{mytooltipData}}</div>
        <div class="container chart_tooltip" *ngIf="tooltipType=='groupHBar' && tooltip"  >
          <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column mt-1">
                <div class="chart_tooltip_header_heading" style="font-size: 10px !important;">
                    {{config[0].period}} 
                </div>
            </div>
            <div class="col-12 d-flex flex-column mb-1">
                <div class="chart_tooltip_header_heading" style="font-size: 12px;">
                    {{config[0].name}}
                </div>
            </div>
        </div>
          <!-- <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column">
              <span>{{config[0].period}}</span>
              <div class="chart_tooltip_header_heading mt-1 mb-1">
                {{config[0].name }}
              </div>
             
            </div>
          </div> -->
          <div class="mt-2 mb-5" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class="row mt-1 pb-2" >
              <div class="col-12 d-flex m-auto  align-items-center">
               
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData?.fill" ></div>
                </div>
                <div class="chart_tooltip_body_data ml-2">{{mytooltipData?.name }} : {{mytooltipData?.value}}</div>
                
              </div>
            </div>
          </div>

        </div>
      </div> 
      <div style="display: flex;font-size: 17px;justify-content: center;font-weight: 600;"><span>{{config[0].period}}</span></div>
     </div>
 