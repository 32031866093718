import { Component, Input } from '@angular/core'
import {  Tooltip } from '@dataunveil/ducharts'

import { CellInfo } from './cell-info'

@Component({
  standalone: true,
  imports: [Tooltip, CellInfo],
  selector: 'app-status-tooltip-dpm',
  template: `
    <duc-tooltip [config]="config">
      <ng-template #custom let-data>
        <app-status-cell-info-dpm [data]="data" [header]='true' ></app-status-cell-info-dpm>
      </ng-template>
    </duc-tooltip>
  `,
  styles: [':host ::ng-deep duc-tooltip .custom { max-width: 225px; }'],
})
export class TooltipDPM {
  @Input() config: any
}