<div class="widget-container" #slopeCharDiv>
    <slope-chart
    [heading]="heading"
    [headerConfig]="this.item.config.widget_header_config"
    [config]="config"
    [data]="data"
    [item]="item"
    [pageKey]="pageKey"
    >
    </slope-chart>
</div>
