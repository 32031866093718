import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {
  static key = 100509;
  
  @Input('item') item:any
  @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  constructor(private dialogService :DialogService) { }

  ngOnInit(): void {
  }
  openDialog(){
    this.dialogService.openDialog(this.prompt)
  }

}
