<input type="hidden" [formControl]="dynamicSelect" [formlyAttributes]="field" />

<div *ngIf="this.selectedType=='D'">

  <label class="filterLabelStyle" style="margin: 0 !important;">{{toLabel}}</label>
  <mat-form-field class="example-full-width" style="width: 100% !important;" appearance="fill">
    <!-- <mat-label>Choose a to date</mat-label> -->
    <input matInput readonly [min]="minToDate" [max]="maxToDate" [formControl]="selectedTodate" [matDatepicker]="topickerDay"
    (dateInput)="toDateChangeDay('input', $event)">
    <mat-datepicker-toggle matSuffix [for]="topickerDay"></mat-datepicker-toggle>
    <mat-datepicker #topickerDay></mat-datepicker>
  </mat-form-field>
  
  <hr>
</div>


<div *ngIf="this.selectedType=='W'">

  <label class="filterLabelStyle" style="margin: 0 !important;">{{label}}</label>
  <mat-form-field class="example-full-width" style="width: 100% !important;" appearance="fill">
    <!-- <mat-label>Choose a to date</mat-label> -->
    <input matInput readonly [min]="minToDate" [max]="maxToDate" [formControl]="selectedTodate" [matDatepickerFilter]="myFilter" [matDatepicker]="topicker"
    (dateInput)="toDateChange('input', $event)">
    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
    <mat-datepicker #topicker></mat-datepicker>
  </mat-form-field>
  
  <hr>
</div>

<div *ngIf="this.selectedType=='M'" class="monthCaledar">

  <label class="filterLabelStyle" style="margin: 0 !important;">{{label}}</label>
  <mat-form-field class="example-full-width" style="width: 100% !important;" appearance="fill">
    <!-- <mat-label>Choose a from date</mat-label> -->
    <input matInput readonly #toMonthDpInput [min]="minToDate" [max]="maxToDate" [formControl]="selectedTodate" [matDatepicker]="toMonthDp">
    <mat-datepicker-toggle matSuffix [for]="toMonthDp"></mat-datepicker-toggle>
    <mat-datepicker [calendarHeaderComponent]="exampleHeader" #toMonthDp (monthSelected)="monthToSelected($event, toMonthDp, toMonthDpInput)" startView="year"></mat-datepicker>
  </mat-form-field>

  <hr>
</div>

<div *ngIf="this.selectedType=='Y'">

  <label class="filterLabelStyle" style="margin: 0 !important;">{{label}}</label>
  <mat-form-field class="example-full-width" style="width: 100% !important;" appearance="fill">
    <!-- <mat-label>Choose a from date</mat-label> -->
    <input matInput readonly #toYearDpInput [min]="minToDate" [max]="maxToDate" [formControl]="selectedTodate" [matDatepicker]="toYearDp">
    <mat-datepicker-toggle matSuffix [for]="toYearDp"></mat-datepicker-toggle>
    <mat-datepicker [calendarHeaderComponent]="exampleHeader" #toYearDp (yearSelected)="yearToSelected($event, toYearDp, toYearDpInput)" startView="multi-year"></mat-datepicker>
  </mat-form-field>
  
  <hr>
</div>


<div *ngIf="this.selectedType=='Q'">
  
  <div>
    <label class="filterLabelStyle" style="margin: 0 !important;">{{label}}</label>
    
    <ng-select #sct class="ngSelection" style="font-size:13px" [multiple]="false" [clearable]="false" [items]="toOption"
      [placeholder]="this.placeholder" [(ngModel)]="this.selectedToOption" [dropdownPosition]="'bottom'"
      [clearable]="false" [closeOnSelect]="false" [bindLabel]="this.labelPropForQ" (close)="onCloseTo()" (open)="onOpenTo()">
  
  
      <ng-template ng-header-tmp>
  
        <div class="selectYear">
          <div class="ngb-dp-arrow">
            <button  class="btn btn-link ngb-dp-arrow-btn" type="button" ><span class="ngb-dp-navigation-chevron" (click)="showQuarterTo?changeYearTo(yearTo > yearFromDefault ? yearTo-1 : yearFromDefault):year10yearTo=year10yearTo-12"></span></button>
          </div>
  
          <button type="button" class="btn btn-link" (click)="changeShowQuarterTo()">{{showQuarterTo?yearTo?yearTo:yearFromDefault:(year10yearTo+' - '+(year10yearTo+11))}}</button>
          <div class="ngb-dp-arrow right">
            <button  class="btn btn-link ngb-dp-arrow-btn" type="button" (click)="showQuarterTo?changeYearTo(yearTo<currentYear ?yearTo + 1 : currentYear):year10yearTo=year10yearTo+12"><span class="ngb-dp-navigation-chevron"></span></button>
          </div>
        </div>
  
        
        <ng-container *ngIf="!showQuarterTo">
  
          <div class="row">
            <div class="col-3" style="margin: 0px 0 5px;text-align: center;" *ngFor="let item of [0,1,2,3,4,5,6,7,8,9,10,11]">
              <!-- Your content here -->
              <div class="grid-item">
                <button class="btnYearCalender" [disabled]="currentYear<year10yearTo+item || yearFromDefault>year10yearTo+item" [ngClass]="{'bg-primary':(year10yearTo+item)==yearTo}"  (click)="changeYearTo(year10yearTo+item);showQuarterTo=true">
                  <span>{{year10yearTo+item}}</span>
                </button>
              </div>
            </div>
          </div>
  
  
          
        </ng-container>
    </ng-template>
  
  
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div style="display: flex;justify-content: space-around;">
          <input id="item-{{ index }}" style="margin: 0 10px;" [disabled]="item.disabled" type="checkbox"
            [ngModel]="item$.selected" [value]="item" (click)="item$.selected ? $event.preventDefault() : ''"/>
          <span class="col" *ngFor="let month of item.months" [class.disabledQ]="item.disabled">{{month}}</span>
        </div>
      </ng-template>
  
  
      <ng-template ng-footer-tmp>
        <button (click)="applyToFilter(sct)" [disabled]="selectedToOption.disabled"
          class="btn btn-sm btn-secondary buttonApply">Apply</button>
      </ng-template>
    </ng-select>
    <hr>
  </div>
 

</div>