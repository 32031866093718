<div class="widget-container" #therapyDiv>
  <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white">
    
    <div class="outlay_pipeline card">
      <div class="card-body">
        
        <div class="row mt-1">
          <icon-chart
            [headerConfig]="item.config?.widget_header"
            [pageKey]="pageKey"
            [heading]="heading"
            [chartName]="'ChoroplethChart'"
            [config]="ChoroplethChartContainer"
            [expand]="fs"
            [fullscreen]="isFullscreen"
            [item]="item"
            [apiKey]="100125"
          ></icon-chart>
        </div>

        <div *ngIf="loader" style="height: 500px;"><loading></loading></div>

        <div #ChoroplethChartContainer>
          <div class="row mt-2" *ngIf="!loader && this.allData?.length>0">
            <div class="col-6 choro_map_resp">
              <app-choropleth-map-chart
                [item]="item"
                [allData]="allData"
                [choroplethData]="choroplethData"
              ></app-choropleth-map-chart>
            </div>
  
            <div class="col-6 list_resp">
              <app-prescriber-offices-list
                [item]="item"
                [allData]="allData"
                [statusArray]="statusArray"
              ></app-prescriber-offices-list>
            </div>
          </div>

          <div *ngIf="!loader && this.allData?.length==0" style="position: relative;height: 400px;width: 100%;">
            <div style="position: absolute;top: 50%; left: 50%; text-align: center;font-size: 20px;transform: translate(-50%, -50%);">
              No data to show
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
