import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatComponentsModule } from "src/app/mat-components.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { AlertSingleSelectComponent } from './selection/alert-single-select/alert-single-select.component';
import { AlertMultiSelectComponent } from "./selection/alert-multi-select/alert-multi-select.component";
import { AlertSubscriptionComponent } from "./alert-subscription/alert-subscription.component";
import { AlertSubscriptionService } from "./services/alert-subscription.service";
import { ChartsModule } from "../../shared/charts/charts.module";
import { AlertTreeSelectComponent } from './selection/alert-tree-select/alert-tree-select.component';

const widgetConfig:any = {
  extras: { lazyRender: true, resetFieldOnHide: true },

  types: [
    {
      name: "alert-single-select",
      component: AlertSingleSelectComponent
    },
    {
      name: "alert-multi-select",
      component: AlertMultiSelectComponent
    },
    {
      name: "alert-tree-select",
      component: AlertTreeSelectComponent
    }
  ]
};

@NgModule({
  declarations: [
    AlertSingleSelectComponent,
    AlertMultiSelectComponent,
    AlertSubscriptionComponent,
    AlertTreeSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule,
    NgMultiSelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormlyModule.forRoot(widgetConfig),
    ReactiveFormsModule,
    FormlyBootstrapModule,
    MatComponentsModule,
    NgSelectModule,
    ChartsModule,
  ],
  exports: [
    AlertSingleSelectComponent,
    AlertMultiSelectComponent,
    AlertSubscriptionComponent,
    AlertTreeSelectComponent
  ],
  providers: [
    AlertSubscriptionService
  ]
})
export class AlertsSubscriptionModule {}

