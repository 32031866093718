   
<div class="iconDiv">
    <mat-icon  class="matIcons" svgIcon="info_circle" (click)="openDialog()" ></mat-icon>
</div>
<!-- [matMenuTriggerFor]="info"
    <mat-menu  #info="matMenu" yPosition="below"  >
<div  class="popoverCustom" >
    <div class="heading text-left " style="font-family: 'Poppins'!important;"> {{"infoData.info_header"}} </div>
    <div class="p-2 text-left" style="font-family: 'Poppins'!important;">
        <p> {{"infoData.info_text"}}</p>
    </div>
</div>    
</mat-menu> -->
<ng-template #prompt style="height: 200px; width: auto;">
    <div class="header">
         <h2 style="margin:11px auto 11px 8px !important;  font-family: 'Poppins'!important;" >{{item.config['report_heading']+" Heading Data"}}</h2> 
         <button mat-icon-button class="close-button" [mat-dialog-close]="true">
               <mat-icon class="close-icon">close</mat-icon>
         </button>
    </div>
    <pre>
        Dispense Trend by Source

        Quantity Shipped: Number of units dispensed represented here in bottles and tablets. Each bottle can hold 30 tablets.
        
        Shipments: Number of shipments of Rezurock sent to the patients
        
        Shipped Patients: Number of patients that have received shipments
        
        Dispense by Source: Trend showing the number of units dispensed by each pharmacy. Quantity shipped is shown in tablets.
        
        Clicking on the line chart and dragging the mouse to the right on the lines will show the % age increase or decrease in quantity shipped for the selected period for each of the selected pharmacy. In case the option Value is selected the average Quantity Shipped during the selected period is displayed.
        
        Selecting the time-cycle between Weekly (W), Monthly (M), Quarterly (Q) and Yearly (Y) will show the trend line of Quantity Shipped by pharmacies in the respective time periods
        
        Moving Average: Moving average combo chart shows the sum of Quantity Shipped by all pharmacies as bars and the moving average trend line of quantity shipped. By default, a moving average 2 periods is shown. The moving average period can be adjusted and a number between 2 and 9 can be selected. For example, while seeing the Quantity Shipped in a monthly (M) granularity, adjusting the moving average to 3 will show the 3 months moving average for Quantity Shipped.</pre>
      
  </ng-template>