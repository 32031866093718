import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SelectionPopupComponent } from "../../shared/selection-popup/selection-popup.component";
import { ConstantsService } from "src/app/services/constants.service";
import { FilterService } from "src/app/services/filter.service";
import { FollowupPopupComponent } from "../../shared/follow-up-popup/follow-up-popup.component";
import { DeviceDetectionService } from "src/app/services/detectipad.service";

@Component({
  selector: "app-summary-grid",
  templateUrl: "./summary-grid.component.html",
  styleUrls: ["./summary-grid.component.scss"],
})
export class WidgetSummaryGridComponent implements OnInit {
  static key = 100802;
  
  @Input("item") item: any;
  @Input("heading") heading: string = "";
  @Input("height") height: any = {};

  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  isActive = false;
  
  check_icon = "check_icon"
  constructor(private dialog: MatDialog, private constantsService: ConstantsService, private filterService: FilterService, public deviceDetectionService: DeviceDetectionService) {
    this.filterService.followUP.subscribe((r:any)=> {
      this.followUp()
    })
  }

  ngOnInit(): void {}


  followUp() {
    let dialogRef = this.dialog.open(FollowupPopupComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
      panelClass: "du-custom-modalbox",
    });

    dialogRef.afterClosed().subscribe((value) => {
       
      // this.setBrandKey(value.brand_key)
    });
  }


  gridArr = [
    {
      pt_status : 'Missing patient info',
      pt_name : 'Eleanor Pena',
      pt_date : 'today, 9:51pm',
      pt_curr : 'Resolve'
    },
    {
      pt_status : 'Missing insurance details',
      pt_name : 'Jacob Jones',
      pt_date : 'Jul 24, 8:59pm',
      pt_curr : 'Resolve'
    },
    {
      pt_status : 'Most lagging patients',
      pt_name : 'Cody Fisher',
      pt_date : 'Jul 24, 8:59pm',
      pt_curr : 'Resolved'
    },
    {
      pt_status : 'Missing patient signature',
      pt_name : 'Jenny Wilson',
      pt_date : 'Jul 24, 8:59pm',
      pt_curr : 'Resolve'
    },
    {
      pt_status : 'Most lagging patients',
      pt_name : 'Cody Fisher',
      pt_date : 'Jul 24, 8:59pm',
      pt_curr : 'Resolved'
    },
    {
      pt_status : 'Missing patient signature',
      pt_name : 'Jenny Wilson',
      pt_date : 'Jul 24, 8:59pm',
      pt_curr : 'Resolve'
    }
  ]

}
