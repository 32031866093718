<!-- Pending Information Popup  -->

<div class="pending_info_popup container">
    <div class="row">
        <div class="col-12 d-flex m-auto align-items-center">
            <div class="col-10 pending_info_heading pl-2 mt-3 mb-2">{{pendingInfo[0].heading}}</div>
            <div class="col-2 text-end"><img src="../../../../assets/Vector.png" (click)="hidePendingTooltip()" /></div>
        </div>

    </div>
    <hr class="heading_divider mt-2" />
    <div class="row mt-2">
        <div class="col-12 d-flex" style="justify-content:space-between;">
            <div class="data_key">Start Date:</div>
            <div class="data_value">{{pendingInfo[0].startDate | date: 'MM/dd/yyyy'}}</div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 d-flex" style="justify-content:space-between;">
            <div class="data_key">End Date:</div>
            <div class="data_value">{{pendingInfo[0].endDate | date: 'MM/dd/yyyy'}}</div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 d-flex" style="justify-content:space-between;">
            <div class="data_key">Duration:</div>
            <div class="data_value">{{pendingInfo[0].duration}}</div>
        </div>
    </div>
    <hr class="heading_divider mt-2" />
    <div class=" row">
        <div class="col-12 data_key">Status Codes:</div>
    </div>
    <div class="row mt-2 mb-2 pl-2">
            <div class="col-1 d-flex w-auto status_codes" *ngFor=" let item of pendingInfo[0]?.codes;let i=index">
                {{item}}<span *ngIf="i != pendingInfo[0].codes.length-1 && pendingInfo[0].codes.length > 1" class='pl-0'>,</span>
            </div>
    </div>
</div>