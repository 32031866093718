import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HiddenField } from "./hidden.type";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DynamicDateRange } from "./date-range.type";
import { MatComponentsModule } from "src/app/mat-components.module";

import { NgSelectModule } from "@ng-select/ng-select";
import {CdkMenuModule} from '@angular/cdk/menu';
import { SingleCustomDatePickerComponent } from "./single-custom-datepicker/single-custom-datepicker.component";
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from "@angular/material/core";
import { QuarterSelectionComponent } from "./quarter-selection/quarter-selection.type";
import { QuarterRangeSelectionComponent } from "./quarter-range-selection/quarter-range-selection.type";
import { TimecycleWithRangeComponent } from "./timecycle-with-range/timecycle-with-range.type";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { SingleDateComponent } from "./single-date/single-date.component";
import { DynamicMultiSelect } from "./multi-select/multi-select.component";
import { DynamicSelect } from "./single-select/single-select.component";
import { DynamicNewMultiSelect } from "./new-multi-select/new-multi-select.component";
import {OverlayModule} from '@angular/cdk/overlay';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
const widgetConfig:any = {
  extras: { lazyRender: true, resetFieldOnHide: true },

  types: [
    {
      name: "dynamic-select",
      component: DynamicSelect,
    },
    {
      name: "hidden",
      component: HiddenField,
    },
    {
      name: "multi-select",
      component:DynamicMultiSelect,
      wrappers: []
    },
    {
      name: "new-multi-select",
      component: DynamicNewMultiSelect
    },
    {
      name: "date-range",
      component: DynamicDateRange,
    },
    {
      name: "quarter-select",
      component: QuarterSelectionComponent,
    },
    {
      name: "quarter-range-select",
      component: QuarterRangeSelectionComponent,
    },
    {
      name: "single-datepicker",
      component: SingleCustomDatePickerComponent,
    },
    {
      name: 'timecycle-with-date-range',
      component: TimecycleWithRangeComponent
    },
    {
      name: 'date-single',
      component: SingleDateComponent
    }
  ],
};

@NgModule({
  declarations: [
    DynamicSelect, 
    DynamicMultiSelect, 
    HiddenField,
    DynamicDateRange,
    QuarterSelectionComponent,
    QuarterRangeSelectionComponent,
    SingleCustomDatePickerComponent,
    TimecycleWithRangeComponent,
    SingleDateComponent,
    DynamicNewMultiSelect
    // AppCustomDatepickerHeaderComponent
  ],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormlyModule.forRoot(widgetConfig),
    FormlyBootstrapModule,
    MatComponentsModule,
    FormsModule,
    NgSelectModule,
    CdkMenuModule,
    MatCardModule, 
    MatDatepickerModule, 
    MatNativeDateModule,
    MatFormFieldModule, 
    MatInputModule, 
    MatNativeDateModule, 
    MatDatepickerModule,
    OverlayModule
  ],
  exports: [
    DynamicSelect, 
    DynamicMultiSelect, 
    HiddenField,
    DynamicDateRange,
    QuarterSelectionComponent,
    QuarterRangeSelectionComponent,
    SingleCustomDatePickerComponent,
    TimecycleWithRangeComponent,
    SingleDateComponent,
    DynamicNewMultiSelect
    // AppCustomDatepickerHeaderComponent
  ]
})
export class CustomInputTypeModule {}

