<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" [style.position]="this.toggleClicked ? 'relative' : 'fixed'">
  <div>
    <div style="text-align: center">
      <img src="assets/scraii-logo.png" class="pt-1 "  alt="SCRAII Logo" style="width: 60px; height:60px ; margin: 5px" />
      <div style="color:  #093868; margin: auto; font-family: 'Poppins'; font-size: 8px; font-weight: 400;"> Powered By Data Unveil</div>
    </div>
    <div style="text-align: center;justify-content: center;position: relative;" class="text-center logg du-side-menu-bar-talet">
      <mat-icon style="cursor: pointer;" *ngIf="this.toggleClicked" (click)="handleSidebarToggle()">keyboard_arrow_left</mat-icon>
      <mat-icon style="cursor: pointer;" *ngIf="!this.toggleClicked" (click)="handleSidebarToggle()">menu</mat-icon>
    </div>
    
    <div style="text-align: center;justify-content: center;position: relative;" class="text-center logg du-side-menu-bar-talet-close">
      <mat-icon style="cursor: pointer;" (click)="mouseleave()">keyboard_arrow_left</mat-icon>
    </div>

  </div>

  <div class="content contentForScroll" style="margin: 20px 0 0 0;overflow-y: auto;height: calc(100% - 142px);overflow-x: hidden;">
    <div class="link" *ngFor="let folder of menuItems; let i = index">
      <div [ngClass]="{'content': !isExpanded, 'noContent' : isExpanded, 'sideNavClassHover' : folder.access_flg != 'N'}"
       (mouseover)="highlightRow(folder, folder.access_flg)" (mouseout)="selectedName = ''" *ngIf="folder.access_flg != 'H' && folder.access_flg != 'N'" (click)="folder.access_flg == 'N' ? '': onClick(folder) "
      >
        <div class="iconsh" >
          <!-- Icons -->
          <img 
            [src]="this.dashboardService.moduleKey != folder.module_key ?  folder.module_key == selectedName ? folder.hover_image : folder.active_image : folder.hover_image "
            class="a"
            [ngClass]="{'highlightedIcon' : this.dashboardService.moduleKey == folder.module_key ? true : false  }"
          >
        </div>
        <div class="title b" [ngClass]="{ 'titleofIcons': isExpanded }">
          <span style="white-space: nowrap;" *ngIf="isExpanded" [ngClass]="{'highlightedText' : this.dashboardService.moduleKey == folder.module_key ? true : false  }">
            {{folder.module_nm}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer"></div>
</div>