
  <div #GroupHBar style="font-family: Poppins !important">
    <main>
      <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
        <!-- <button mat-raised-button color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
        <div style="background-color: #ffff;">
            <icon-chart 
              [headerConfig]="headerConfig" 
              [iconList]="iconList" 
              [data]="data" 
              [pageKey]="pageKey" 
              [heading]="heading"
              chartName="GroupHBar" 
              [config]="GroupHBarChart" 
              [expand]="fs" 
              [fullscreen]="isFullscreen"
              [item]="item"
            ></icon-chart>
        </div>
        <div><loading *ngIf="isLoading"> </loading></div> 
       
        <div #GroupHBarChart [ngClass]="{'fullScreenScroll' : this.isFullscreen}">
          <div [style.display]="noData?'none':''" id="GroupedHbarChartDiv"></div>
          <div style="height: 500px; width: auto; " *ngIf="noData">
           <span class="bg-text" >There is no data for this time period</span></div>
       <div id="d3HBarTooltip">
        <div *ngIf="isTooltip &&  myType =='groupFullName'" style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData}}</div>
      
          <div class="container chart_tooltip"  *ngIf="isTooltip &&  myType!='groupFullName'">
            <div class="row chart_tooltip_header">
              <div class="col-12 d-flex flex-column mt-1 mb-1">
                <div class="chart_tooltip_header_heading">
                  No of defects ({{mytooltipData?.[1]?.group }} {{'Range: '+mytooltipData?.[1]?.range+' days' }})
                </div>
              </div>
            </div>
            <div class="mt-2 mb-5 pb-1" *ngIf="height > 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
              <div class="row" *ngFor="let data of mytooltipData;let i=index">
                <div class="col-12 d-flex m-auto  align-items-center">
                 
                  <div>
                    <div class="tooltip_outlay" [style.background]="data?.key=='E'?'#FFD953':'#FF842B'" ></div>
                  </div>
                  <div class="chart_tooltip_body_data ml-2">{{data.key=='E'?'Errors ':'Failures '}} : {{data?.value  ? numbedPipe(data?.value )  : data?.value }}   </div>
                 
                </div>
              </div>
            </div>
            <div class="mt-2 mb-5 pb-1" *ngIf="height < 200"  [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
              <div class="row" *ngFor="let data of mytooltipData;let i=index">
                <div class="col-12 d-flex m-auto  align-items-center">
                 
                  <div>
                    <div class="tooltip_outlay" [style.background]="data?.key=='E'?'#FFD953':'#FF842B'" ></div>
                  </div>
                  <div class="chart_tooltip_body_data ml-2">{{data.key=='E'?'Errors ':'Failures '}} : {{data?.value  ? numbedPipe(data?.value )  : data?.value }}   </div>
                 
                </div>
              </div>
            </div>
  
          </div>
        </div>

       </div>
      </article>
    </main>
  </div>