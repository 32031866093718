import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterState, RouterStateSnapshot } from '@angular/router';
// import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})

export class RouterHandlerService {
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private navigationService: NavigationService
  ) {}


  navDetailInfo(navDetail:any) {
    sessionStorage.setItem("navDetailInfo", JSON.stringify(navDetail));
  }

  storingPayloadAndFilter(config:any, allData:any, name:any) {
    let payloadData:any = {};
    let filterData:any = {};

    if(config?.customPayloadKey) {
      payloadData[config?.customPayloadKey] = config?.customPayloadValue;
    }
    
    if(config?.customFilterKey) {
      filterData[config?.customFilterKey] = config?.customFilterValue;
    }

    // Payload Passing Data
    if(config?.payloadData) {
      let pyldDta = config?.payloadData.split(',');
      pyldDta.forEach((m:any)=> {
        let fnd:any = this.fndAllData(allData, name);
        if(fnd) {
          payloadData[m] = fnd[m];
        }
      });
    }

    // Filter Passing Data
    if(config?.filterData) {
      let flrDta = config?.filterData.split(',');
      flrDta.forEach((m:any)=> {
        let fnd:any = this.fndAllData(allData, name);
        if(fnd) {
          filterData[m] = fnd[m];
        }
      })
    }

    if(Object.keys(filterData).length>0) sessionStorage.setItem("filterData", JSON.stringify(filterData));
    else sessionStorage.removeItem("filterData");

    if(Object.keys(payloadData).length>0) sessionStorage.setItem("payloadData", JSON.stringify(payloadData));
    else sessionStorage.removeItem("payloadData");
  }

  storingPayloadAndFilterForGrid(config:any, data:any, cardName:any) {
    let payloadData:any = {};
    let filterData:any = {};

    if(cardName) { 
      if(sessionStorage.getItem("payloadData")) {
        let payload:any = sessionStorage.getItem("payloadData");
        payloadData = JSON.parse(payload);
      }
      
      if(sessionStorage.getItem("filterData")) {
        let filter:any = sessionStorage.getItem("filterData");
        filterData = JSON.parse(filter);
      }
    }

    if(config?.customPayloadKey) {
      payloadData[config?.customPayloadKey] = config?.customPayloadValue;
    }
    
    if(config?.customFilterKey) {
      filterData[config?.customFilterKey] = config?.customFilterValue;
    }

    // Payload Passing Data
    if(config?.payloadData) {
      let pyldDta = config?.payloadData.split(',');
      pyldDta.forEach((m:any)=> {
        payloadData[m] = data[m];
      });
    }

    // Filter Passing Data
    if(config?.filterData) {
      let flrDta = config?.filterData.split(',');
      flrDta.forEach((m:any)=> {
          filterData[m] = data[m];
      })
    }

    if(Object.keys(filterData).length>0) sessionStorage.setItem("filterData", JSON.stringify(filterData));
    else sessionStorage.removeItem("filterData");

    if(Object.keys(payloadData).length>0) sessionStorage.setItem("payloadData", JSON.stringify(payloadData));
    else sessionStorage.removeItem("payloadData");
  }

  fndAllData(data:any, name:any) {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let updatesObj:any = Object.entries(element).map(([key, value]) => ({ key, value }));
      let fndData = updatesObj.find((o:any)=> o.value?.toString().toLowerCase() == name?.toString().toLowerCase());
      if(fndData) {
        return element
      }
    }

    return null;
  }

  routerHandler(cardName: any, cardId?: any, cardPage?:any) {
    
    const currentUrl = this.router.url;
    let sliceURL = currentUrl.slice(1);
    let spltUrl = sliceURL.split('/');
    let newUrl = `${currentUrl}/${cardName}/${cardId}`;

    if(cardPage) {
      this.router.navigate([`dashboard/${spltUrl[1]}/${spltUrl[2]}/${spltUrl[3]}/${cardPage}/${cardName}/${cardId}`]);
    } else {  
      this.router.navigateByUrl(newUrl);
    }
  }

}
