<div class="row mt-3">
    <div class="col-1 filter_heading">
        Selcted Filters:
    </div>
    <div class="col-11">
        <div class="row">
            <div class="filter_body d-flex" *ngFor="let data of dataObj">
                <div class="filter_name mt-2">{{data.name}}</div>
                <div class="ml-2 mt-2 filter_data_body">
                    <span class="filter_data_text">
                        {{data.currentValue}}/{{data.totalValue}}
                    </span>
                </div>
            </div>
            <div class="default_body"><a class="default_text btn" (click)="resetFilters()">X Reset To Default</a></div>
        </div>
    </div>
    <!-- <div class="col-2 default_body"> -->
    </div>