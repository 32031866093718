import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-config-type-selection',
  templateUrl: './config-type-selection.component.html',
  styleUrls: ['./config-type-selection.component.scss']
})
export class ConfigTypeSelectionComponent implements OnInit {
  configs: any;
  selectConfig: any
  isButtonClicked=false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private passedData: any,
    private dialogRef: MatDialogRef<ConfigTypeSelectionComponent>,
  ) {
    this.configs = this.passedData.configs;
  }
  
  ngOnInit(): void {}

  selectedConfig(event: any) {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;
      
      if(event) this.dialogRef.close(event);
      
      // Reset the button state after a certain delay (e.g., 2 seconds)
      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }

  close() {
    this.dialogRef.close('closeFromConfig');
  }
}
