import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { input } from 'aws-amplify';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-widget-header-config',
  templateUrl: './widget-header-config.component.html',
  styleUrls: ['./widget-header-config.component.scss']
})
export class WidgetHeaderConfigComponent implements OnInit {

  @Input('headerConfig') headerConfigs:any;
  @Input('item') item:any;


  @Input('pageKey') pageKey: any
  @Input('data') data: any
  @Input('config') config: any
  @Input('heading') heading: any
  @Input('chartName') chartName: string = ""
  @Input('expand') expand: any
  @Input('fullscreen') fullscreen: any
  @Input('lineData') lineData: any


  constructor(
    public filterService:FilterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    
  }
  
  headerConfig:any[] = []
  ngOnInit(): void {
    this.headerConfig = this.headerConfigs.filter((flr:any) => flr.add_header)
  }

  iconList(iconList:any) {
    return iconList.filter((flr:any) => flr.activated)
  }

  trackByFn(index: number, item: any) {
    return item.id; // Use a unique identifier for the item
  }

}
