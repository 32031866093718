<div style="display:flex;width:100%;height: 100%; overflow: hidden;">
    <!-- left menu -->
    <div class="left-panel">
        <!-- <div class="logo"></div> -->
        <div>
            <app-tree-menu [menuItems]="menuItems" (onSelect)="addItem($event)"
                *ngIf="menuItems.length>0"></app-tree-menu>
        </div>
    </div>

    <!-- Main panel -->
    <div style="flex:1;display:flex;flex-direction: column; background-color: beige;padding: 30px; overflow: auto;">
        <!-- title panel -->
        <!-- <div class="dashboard-title-panel ">
            <div style="width: 100%;  height: 100%; margin: 0 auto !important;background-color:#545454" >
            <img class="centered"  src="../../../../assets/SCRAII_Logo.png" alt="scrai logo">
        </div>
           
        </div> -->
        <!-- content panel -->
        <div id="Overview">
            <h1>Overview</h1>
            <P>Welcome to the SCRAII Dashboard User Manual! This Comprehensive guide will provide you with all the
                information you need to effectively use and navigate the dashboard. The dashboard is a powerful tool
                designed to provide you with valuable insights and data visualizations, allowing you to make informed
                decisions and track key metrics.
                In this Manual we will cover:</P>
            <h2 id="Introduction">Introduction</h2>
            <p>This User Manual is created for various dashboards like Data Processing Monitor, Data Quality Monitor,
                payment calculator, and file defects tracking. With its customizable widgets and colourful interface,
                you can track and visualize data processes, ensure data quality, analyse inventory trends, calculate
                payments, and monitor defects in a file.
            </p>
            <div>
                <img class="imghome" style="height: 30vw;" src="../../../../assets/dashboard.png" alt="scrai logo">
            </div>
        </div>
        <br>
        <div>
            <h2 id="How to Access the Dashboard">How to Access the Dashboard</h2>
            <p>Below mention image has the instructions to login to the Dashboard:
            </p>
            <div style="width: 100%; margin: 0 auto !important;">
                <img class="imghome" style="height: 30vw;" src="../../../../assets/loginpic.png" alt="scrai logo">
            </div>
        </div>
        <br>
        <div>
            <h2 id="Landing Page">Landing Page</h2>
            <ul>
                <li>Upon logging in, this landing page will be displayed by default as the initial interface for the
                    dashboard.</li>
                <li>User will expand the sidebar and select the desired dashboard to view.</li>
            </ul>

            <div style="width: 100%; margin: 0 auto !important;">
                <img class="imghome" style="height: 30vw;" src="../../../../assets/sideMenu.png" alt="scrai logo">
            </div>
            <br>
            <div style="width: 100%; margin: 0 auto !important;">
                <img class="imghome" style="height: 30vw;" src="../../../../assets/side.png" alt="scrai logo">
            </div>
            <br>
            <h2 id="Common Icons for Dashboards">Common Icons for Dashboards</h2>
            <div style="width: 100%; margin: 0 auto !important;">
                <img class="imghome" style="height: 30vw;" src="../../../../assets/icinfo.png" alt="scrai logo">
            </div>
            <br>
            <h2 id="Pre-selected filters for Dashboards">Pre-selected filters for Dashboards</h2>
            <table>
                <tr>
                    <th>Filters</th>
                    <th>Description</th>
                </tr>
                <tr>
                    <td>Brand</td>
                    <td>Name of the Brand</td>
                </tr>
                <tr>
                    <td>Source Type</td>
                    <td>Type of source. Ex: Hub, SP, Co-pay etc</td>
                </tr>
                <tr>
                    <td>Source</td>
                    <td>Name of the data partner</td>
                </tr>
                <tr>
                    <td>KPI</td>
                    <td>Key Performance Indicator</td>
                </tr>
                <tr>
                    <td>Reset to default</td>
                    <td>Removes all your changes in the report and sets it back to the last view of the report.</td>
                </tr>
                <tr>
                    <td>Time Cycle</td>
                    <td>A Time Cycle is used to present data with specific level of granularity. The granularity or the
                        level of details provided by the time cycle depends on the length or duration of the selected
                        level.
                        For Example: By selecting a weekly time cycle, the data for the KPIs will be presented in a
                        weekly basis or if the user selects monthly, the data will be presented in monthly granularity.
                        User can set the time cycle to Weekly, Monthly, Quarterly, Yearly depending on the specific
                        requirements.
                    </td>
                </tr>
            </table>


            <p>Note: When clicked on any filter, A Dropdown should be seen with the list of values. These filters can be
                modified in the filters section. If modified, and the user is navigating to a different tab, selected
                filters should be retained.</p>
            <p>Note: When you login and open the dashboard, you will see the data related to the selected brand, the
                filters will be applied by default and according to the applied filters data will be reflected.</p>
            <div style="width: 100%; margin: 0 auto !important;">
                <br><br>
                <h2 id="Operations Modules">Operations Modules</h2>
                There are two operational dashboards within Operations Module:
                <p></p>
                <img class="imghome" style="height: 30vw;" src="../../../../assets/dashboard.png" alt="scrai logo">
            </div>


            <br>
            <h2 id="Data Processing Monitor">Data Processing Monitor</h2>
            <p>This Dashboard is like a map that shows different colours to represent the status of different tasks
                related to data processing. It helps users to see how many files they have received, how many files are
                still waiting to be processed, how many files have been processed successfully, and how many files have
                failed. By using this dashboard, users can easily check and evaluate the progress and performance of
                their data processing tasks, making sure that everything is being handled effectively and accurately.
            </p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/datapro.png" alt="scrai logo">
            <br>
            <br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/douknow.png"
                alt="scrai logo">
            <br>
            <h2 id="Two Types of Dashboards">Two Types of dashboards</h2>
            <p>Here are two types of the dashboard:</p>
            <table>
                <tr>
                    <th class="table">Processed Date</th>
                    <th class="table"> Alfreds Futterkiste</th>
                </tr>
                <tr>
                    <td class="table">Time Period, it varies depending upon the time selected by the User.</td>
                    <td class="table">Source Type (Upon clicking source type, source names will reflect) and we can see
                        the status of each source name.</td>
                </tr>

            </table>
            <br>
            <h2 id="Dashboard - Processed Date">Dashboard - Processed Date</h2>
            <table>
                <tr>
                    <th>Dashboard Screen</th>
                    <th>Data Processing Monitor (Processed Date Screen)</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>A dual axis chart with date on X axis and Source type on Y axis.</td>
                </tr>
                <tr>
                    <td>Functionality</td>
                    <td>This dashboard helps user to understand the various file status.</td>
                </tr>
                <tr>
                    <td>X-Axis</td>
                    <td>Time Period, it varies depending upon the time selected by the User.</td>
                </tr>
                <tr>
                    <td>Y-Axis</td>
                    <td>Source Type (Upon clicking source type, source names will reflect) and we can see the status of
                        each source name.
                    </td>
                </tr>

            </table>
            <br>
            <h2 id='Dashboard-Expected Date'>Dashboard-Expected Date</h2>
            <table>
                <tr>
                    <th>Dashboard Screen</th>
                    <th>Data Processing Monitor (Expected Date Screen)</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>A dual axis chart with date on X axis and Source type on Y axis.</td>
                </tr>
                <tr>
                    <td>Functionality</td>
                    <td>This dashboard helps user to understand the various file status.</td>
                </tr>
                <tr>
                    <td>X-Axis</td>
                    <td>Time Period, it varies depending upon the time selected by the User.</td>
                </tr>
                <tr>
                    <td>Y-Axis</td>
                    <td>Source Type (Upon clicking source type, source names will reflect) and we can see the status of
                        each source name.
                    </td>
                </tr>

            </table>
            <br>
            <h2 id="Data Grid">Data Grid</h2>
            <table>
                <tr>
                    <th>Dashboard Screen</th>
                    <th>Data Processing Monitor (Data Grid)</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>This table holds the data used to populate the graphs.</td>
                </tr>
                <tr>
                    <td>Column</td>
                    <td>Source, Processing Status, Expected count, Received count, Success count, Failed count & Missing
                        count.</td>
                </tr>
                <tr>
                    <td>Functionality</td>
                    <td>1. Should be able to export in an excel.<br>
                        2. Should be able to view by different parameter as selected.<br>
                        3. Should be able to expand.
                    </td>
                </tr>


            </table>
            <p>Note: User should be able to view the data by changing the parameters.</p>
            <br>
            <h2 id="KPI (Key Performance Indicators) Cards">KPI (Key Performance Indicators) Cards</h2>
            <p>Below mentioned are the KPIs for both the screens.</p>
            <table>
                <tr>
                    <th>KPI Name</th>
                    <th>Description</th>
                </tr>
                <tr>
                    <td>Failed</td>
                    <td>A two KPI Card which reflects:<br>
                        &nbsp; • Total number of records whose result type is in fail.<br>
                        &nbsp; • Total number of records whose result type is error in a file.
                    </td>
                </tr>
                <tr>
                    <td>Missing</td>
                    <td>A two KPI Card which reflects:<br>
                        &nbsp;• Total number of files that are expected to be received at a particular date and time but
                        are not received.<br>
                        &nbsp;• Total number of files received after the expected date and time
                    </td>
                </tr>
                <tr>
                    <td>To be processed</td>
                    <td>A two KPI Card which reflects:<br>
                        &nbsp;• Total number of files that are received and yet to be processed.<br>
                        &nbsp;• Total number of processed files.

                    </td>

                </tr>
                <tr>
                    <td>Received</td>
                    <td>A two KPI Card which reflects:<br>
                        &nbsp;• Total number of files received.<br>
                        &nbsp;• Total number of expected to be received.

                    </td>
                </tr>
                <tr>
                    <td>Donut KPI Card</td>
                    <td>This card shows the compilation of all the KPI Card</td>
                </tr>

            </table>
            <br>
            <h2 id="Dispense Trend">Dispense Trend </h2>
            <p>This Dashboard provides comprehensive overview of the quantity, frequency, and the count of patients to
                whom the drug is delivered or dispensed by various SPs. Under Dispense Trend Dashboard we have 3 types
                of Dashboards: Dispense Trend, Dispense by location and Shipped Patients.
            </p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dispense.png" alt="scrai logo">
            <br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/dduk.png" alt="scrai logo">
            <br>
            <h2 id=" Dispense Trend">Dispense Trend</h2>
            <p> In dispense Trend we have two dashboards:</p>
            <p>1. Dispense by Source </p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dispense1.png" alt="scrai logo">
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dispense2.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard Screen
                    </th>
                    <th>Description</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>
                        The “Dispense by source” graph illustrates the quantity shipped for each source on different
                        dates.
                    </td>
                </tr>
                <tr>
                    <td>X-Axis</td>
                    <td>Time period. It varies depending on the time cycle selected by the user.
                    </td>
                </tr>
                <tr>
                    <td>Y-Axis</td>
                    <td>It displays a trend line indicating the unique count of shipped quantities (in percentage or
                        value) over time.
                    </td>

                </tr>
                <tr>
                    <td>Partial Period</td>
                    <td>It refers to a time interval that does not cover the entire duration of a given period or cycle.
                    </td>
                </tr>

            </table>
            <br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/movingduk.png"
                alt="scrai logo">
            <br>
            <h2 id="Moving Average">Moving Average</h2>
            <p> It represents the trend of the average shipments taking place between period selected by the user. It
                reflects the increase and decrease in the trend.</p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dbylocation.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard Screen
                    </th>
                    <th>Moving Average</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>
                        It represents the trend of the average shipments taking place between period selected by the
                        user. It reflects the increase and decrease in the trend.
                    </td>
                </tr>
                <tr>
                    <td>X-Axis</td>
                    <td>Time period. It varies depending on the time cycle selected by the user.
                    </td>
                </tr>
                <tr>
                    <td>Y-Axis</td>
                    <td>It displays a trend line indicating the unique count of shipped quantities.
                    </td>

                </tr>
                <tr>
                    <td>Partial Period</td>
                    <td>
                        It refers to a time interval that does not cover the entire duration of a given period or cycle.
                    </td>
                </tr>


            </table>
            <br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/movingduk.png"
                alt="scrai logo">
            <br>
            <h2 id="Dispense Trend by Location">Dispense Trend by Location</h2>
            <p>This dashboard is useful for users as it allows them to visually track and analyse the shipment
                distribution of an SP across multiple locations. By providing a clear breakdown of quantities shipped by
                each location through interactive map bubbles.
            </p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dbylocation.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard Screen
                    </th>
                    <th>Dispense Trend by location</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>
                        It represents the shipments done by each location of SP. using colour coded bubbles to denote
                        each location.
                    </td>
            </table>
            <br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/bubbleduk.png"
                alt="scrai logo">
            <br>
            <h2 id="Shipped Patients">Shipped Patients</h2>
            <p>A dashboard which provides detailed information related to shipments and shipped patients in terms of
                quantity shipped. Within this dashboard, there are two types Dashboards: “Shipped Patients” and
                “Shipment” dashboards
            </p>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/sps.png" alt="scrai logo">
            <br>
            <h2>Shipped Patients</h2>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/shippedimg.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard
                    </th>
                    <th>Shipped Patients</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>

                        A dual axis chart with primary and secondary Y-Axis with different variables. It helps the user
                        establish correlation between volume of shipments vs shipped patients at source level.<br>
                        A combination of line and bar chart.
                    </td>
                </tr>
                <tr>
                    <td>Primary Y-axis</td>
                    <td>Unique count of shipped patients denoted by trend line on the chart.

                    </td>
                </tr>
                <tr>
                    <td>Secondary Y-axis</td>
                    <td>Quantity dispensed denoted by vertical bars on the chart.

                    </td>

                </tr>
                <tr>
                    <td>X-axis</td>
                    <td>
                        Time period. It varies depending on the time cycle selected by the user.

                    </td>
                </tr>
                <tr>
                    <td>Legend</td>
                    <td>
                        Each variable is represented by a specified colour as displayed.

                    </td>
                </tr>
                <tr>
                    <td>Partial Period</td>
                    <td>
                        It refers to a time interval that does not cover the entire duration of a given period or cycle.
                    </td>
                </tr>
            </table><br>
            <h2>Shipments</h2>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/datagrid.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard
                    </th>
                    <th>Shipped Patients</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>

                        A dual axis chart with primary and secondary Y-Axis with different variables.It helps the user
                        establish a correlation between number of shipments vs shipped patients irrespective of
                        source<br>
                        A combination of line and bar chart.

                    </td>
                </tr>
                <tr>
                    <td>Primary Y-axis</td>
                    <td>Unique count of shipments denoted by trend line on the chart.

                    </td>
                </tr>
                <tr>
                    <td>Secondary Y-axis</td>
                    <td>Quantity dispensed denoted by vertical bars on the chart.

                    </td>

                </tr>
                <tr>
                    <td>X-axis</td>
                    <td>
                        Time period. It varies depending on the time cycle selected by the user.

                    </td>
                </tr>

                <tr>
                    <td>Legend</td>
                    <td>
                        Each variable is represented by a specified colour as displayed.

                    </td>
                </tr>
                <tr>
                    <td>Partial Period</td>
                    <td>
                        It refers to a time interval that does not cover the entire duration of a given period or cycle.
                    </td>
                </tr>

            </table>
            <br>
            <h2>Data Grid</h2>
            <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/datagrid.png" alt="scrai logo">
            <br>
            <table>
                <tr>
                    <th>Dashboard
                    </th>
                    <th>Dispense Trend (Data Grid)</th>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>

                        This table holds the data used to populate the graphs.

                    </td>
                </tr>
                <tr>
                    <td>Column</td>
                    <td>Source, Date, Quantity.

                    </td>
                </tr>
                <tr>
                    <td>Functionality</td>
                    <td>1. Should be able to export in an excel.<br>
                        2. Should be able to view by different parameter as selected.<br>
                        3. Should be able to expand.


                    </td>

                </tr>

            </table>
            <br>
            <p>Note: User should be able to view the data by changing the parameters.</p>
            <br>
            <h2 id="KPI (Key Performance Indicators) Card">KPI (Key Performance Indicators) Card</h2>
            <br>
            <table>
                <tr>
                    <th>KPI Name
                    </th>
                    <th>Description
                    </th>
                </tr>
                <tr>
                    <td>Quantity Shipped
                    </td>
                    <td>

                        The two KPI card shows the number of units dispensed, presented in bottles and tablets.It is
                        important to note that each bottle has a capacity of 30 tablets.

                    </td>
                </tr>
                <tr>
                    <td>Shipments
                    </td>
                    <td>
                        A one KPI card displays the count of unique shipments per brand.

                    </td>
                </tr>
                <tr>
                    <td>Shipped Patients
                    </td>
                    <td>A one KPI card displays the count of unique patients who have received a shipment.



                    </td>

                </tr>

            </table>
            <br>
            <p>Note: The KPI (Key Performance Indicators) Cards remain consistent across all the dispense trend
                dashboards.</p>
                <br>
             <h2 id="Inventory Module">Inventory Module</h2>
 <p>
 This Dashboard helps user understand whether there is a match between the Inventory quantity and Dispense quantity files, or if there are any discrepancies or gaps between them.
Within the Inventory module, there exists single dashboard:</p><br>
<img class="imghome" style="height: 30vw;" src="../../../../assets/inventory.png" alt="scrai logo">
<h2 id="Inventory Reconciliation">Inventory Reconciliation</h2>
<p> Within the Inventory Reconciliation dashboard, we have two types of dashboards. One provides information on “Dispense Quantity”, while the other focuses on “Inventory Reconciliation”.
</p>
<br>
	<p>Dispense Quantity</p><br>
    <img class="imghome" style="height: 30vw;" src="../../../../assets/IRline.png" alt="scrai logo">
    <br>
    <table>
        <tr>
          <th>Description</th>
          <th>Dispense Quantity</th>
        </tr>
        <tr>
          <td>Description</td>
          <td>The chart illustrates the correlation between the dispensed quantity and inventory dispensed at a specific point in time. Any differences between the two are visually represented in pink colour, appearing as a filler between the dotted purple line and plain purple line.</td>
        </tr>
        <tr>
          <td>Y-axis</td>
          <td>Unique count of dispensed inventory denoted by trend line on the chart.</td>
        </tr>
        <tr>
          <td>X-axis</td>
          <td>Time period. It varies depending on the time cycle selected by the user.</td>
        </tr>
        <tr>
          <td>Legend</td>
          <td>Each variable is represented by a specified pattern (dotted and plain line) as displayed.</td>
        </tr>
        <tr>
          <td>Partial Period</td>
          <td>It refers to a time interval that does not cover the entire duration of a given period or cycle.</td>
        </tr>
       
      </table>
      <br>
      <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/irduk.png"
                alt="scrai logo">
                <br>
  <h2>Inventory Reconciliation</h2>
<p>SP level view of the dashboard</p>
<br>
<img class="imghome" style="height: 30vw;" src="../../../../assets/slbg.png" alt="scrai logo">
<br>
<table>
    <tr>
      <th>Description</th>
      <th>Inventory Reconciliation</th>
    </tr>
    <tr>
      <td>Description</td>
      <td>This dashboard assists users in reconciling data between inventory quantity and dispensed quantity over any one-week period chosen by the user.</td>
    </tr>
    <tr>
      <td>Period</td>
      <td>The data is reflecting on weekly basis.</td>
    </tr>
   
  </table><br>
  <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/slduk.png" alt="scrai logo"><br>
          <p>Location level view of dashboard for the SP</p><br>
          <img class="imghome" style="height: 30vw;" src="../../../../assets/irlinechart.png" alt="scrai logo"><br>
          <table>
            <tr>
              <th>Description</th>
              <th>Inventory Reconciliation</th>
            </tr>
            <tr>
              <td>Description</td>
              <td>This Dashboard displays the data reconciliation between the inventory quantity file and the dispensed quantity file received during a specific week, focusing on the location level for a particular SP.</td>
            </tr>
            <tr>
              <td>Period</td>
              <td>The data is reflecting on weekly basis.</td>
            </tr>
           
          </table><br>
          <h2>Data Quality Monitor Module</h2>
            <p>This Dashboard ensures that data received by the users from multiple source is refined and any instance of missing, incorrect or inconsistent data can be tracked, highlighted and reported for immediate action
            </p><br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dashboarddqm.png" alt="scrai logo"><br>


            <ul>
                <li>To open any dashboard, User can click on “View” button.</li>
                <li>Dashboards under Data Quality Monitor:</li>
            </ul>
                <ul>
                    <li>Dispense Trend</li>
                    <li>Status Code Usage</li>
                    <li>Outstanding Defects</li>
                    <li>SP Validation Rules</li>
                    <li>Scorecard</li>
                    <li>Data Quality Insights</li>


                </ul>
                <br>
               <h2> Dispense Trend</h2>
               
                <p>This Dashboard provides comprehensive view of the quantity, frequency, and patient count to whom the drug is delivered or dispensed by different SPs. It provides data on a Weekly, Monthly, Quarterly, and Yearly basis, depending on the user’s selected time. Under Dispense Trend Dashboard, there are three types of dashboards available: Dispense Trend, Dispense by Location, and shipped patients.
                </p>
                <br>
            <img class="imghome" style="height: 30vw;" src="../../../../assets/dispenseDQM.png" alt="scrai logo"><br>
            <img class="imghome" style="height: 25vh;width: 50vw;" src="../../../../assets/dispensedukdqm.png" alt="scrai logo"><br>
        </div>
    </div>
</div>