import { Auth } from 'aws-amplify';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationService } from 'src/app/services/navigation.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: "app-impersonation",
  templateUrl: "./impersonation.component.html",
  styleUrls: ["./impersonation.component.scss"],
})
export class ImpersonationComponent implements OnInit, OnDestroy {

  homePageURL: string = "home";

	constructor(
		private router: Router,
		private route: ActivatedRoute,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private dashboardService: DashboardService,
    private dataService: DataService
	) {}

  allUsers:any = [];
  reqSubcription: Subscription[] = [];
  selectedUsername:any

  ngOnInit(): void {
    this.loadAllUserData()
  }

  impersonate() {
    this.dialog.closeAll();
    this.sessionService.setUser(this.selectedUsername.user_id);
    this.sessionService.setUsername(this.selectedUsername.user_id);

    // Remove Client Selection
    sessionStorage.removeItem("client")
    // sessionStorage.
    sessionStorage.removeItem("selectConfig")
    sessionStorage.removeItem("roles")

    // Display name
    this.sessionService.setUserDisplays(this.selectedUsername.user_id);

    // Header Name
    // let findUser:any = this.allUsers.find((res:any)=> res.username == this.selectedUsername.user_id)
    sessionStorage.setItem('userImperson', JSON.stringify(this.selectedUsername))

    // Redirect to CLient Selection Page
    setTimeout(() => {
      this.router.navigate([`${this.homePageURL}/`],{ queryParams: { l: '1' } });
    }, 200);

    // setTimeout(() => {
    //   window.location.href = this.homePageURL + "?l=1";
    // }, 200);
  }

  goBack() {
    this.navigationService.goBack();
    setTimeout(() => {
      location.reload()
    }, 100);
  }

  loadAllUserData() {  
    this.reqSubcription.push(this.dashboardService.getAllUserForImpersonate().subscribe((res:any) => {
      this.allUsers = res ? res : [] 
      this.selectedUsername = this.allUsers[0]
    }))
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }
}
