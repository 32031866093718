<hr class="line du-page-line-desktop" [ngStyle]="{ background: newColor }"/>

<div style="flex: 1 0 auto" class="overFlowForPage">
  
  <!-- Top Navbar -->
  <app-topnav 
    [pagename]="pagename" 
    [clientLogo]="clientLogo" 
    [prName]="prName" 
    [refreshTheDashboard]="refreshTheDashboard"
  ></app-topnav>
  
  <div style="position: relative;" class="du-page-line-mobile">
    <hr class="line" style="position: relative;" [ngStyle]="{ background: newColor }"/>
  </div>

  <div class="du-page-line-mobile">
    <mat-toolbar style="background-color: #fff;">
      <div class="image-container">
        <img [src]="styleLogo(clientLogo)" alt="logo">
      </div>
      <span class="example-spacer"></span>
      <app-alert-button *ngIf="widgetService.alertIcon"></app-alert-button>
      <!-- <app-filter-button *ngIf="widgetService.filterIcon"></app-filter-button> -->
    </mat-toolbar>

    <div *ngIf="prName"
        style="background-color: #F7F7F7;margin: 0 15px;padding: 10px;border-radius: 10px;"
        class="d-flex justify-content-between align-items-center">
      <span class="NewprName">{{ prName }}</span>
      <button mat-raised-button
          color="primary"
          style="background: #1363DF;"
          (click)="clickPrescriberList()">See Prescriber List
      </button>
    </div>
  </div>

  <div *ngIf="widgetService.pageHeaderWidgets.length"
      style="display: flex;margin: 0 20px 0 0px; align-items: center; height: 50px;">
    <div *ngFor="let widget of widgetService.pageHeaderWidgets"
        [style.flex]="widget.key === 100301 ? '' : ''"
        style="margin: 0 15px;overflow: auto;"
        [class.du-page-plain-text]="widget.key==100201"
        
        [class.ms-auto]="widget.key === 100700">
      <ng-container [app-widget]="{ item: widget }"></ng-container>
    </div>
    <!-- [class.tabview]="deviceDetectionService.isTablet && widget.key === 100301" -->
  </div>

  <div *ngIf="reloadPage" style="flex: 1 0 auto">
    <div *ngIf="dashboardPages.length" style="flex-grow: 1; padding: 0 5px 5px;">
      
      <!-- Tab Head -->
      <div *ngIf="dashboardPages.length > 1" style="flex-direction: column;">
        
        <!-- Design Tab 1 -->
        <mat-tab-group *ngIf="configTab == '1'"
            [@.disabled]="true"
            [disableRipple]="true"
            [selectedIndex]="selectedTabIndex"
            (selectedIndexChange)="seletedTab($event, dashboardPages)"
            class="tabDesignOne"
            fitInkBarToContent
            fitInkBarToContent>
          <mat-tab *ngFor="let page of dashboardPages">
            
            <ng-template mat-tab-label>
              <span (click)="clickTabChange()">{{ page.page_nm }}</span>
            </ng-template>

            <!-- More than One Tab -- Tab Content (i.e. Grid page) -->
            <ng-template matTabContent class="tabBody">
              <app-grid-page [pageInfo]="page" style="flex: 1 0 auto; height: 100%"></app-grid-page>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <!-- Design Tab 2 -->
        <mat-tab-group *ngIf="configTab == '2'"
            class="tabDesignTwo"
            [@.disabled]="true"
            [disableRipple]="true"
            [selectedIndex]="selectedTabIndex"
            (selectedIndexChange)="seletedTab($event, dashboardPages)">
          <mat-tab *ngFor="let page of dashboardPages; let idx=index">
            <ng-template mat-tab-label>
              <span [ngClass]="{ 'tabDesignTwoSelected': selectedTabIndex == idx }" (click)="clickTabChange()">
                {{ page.page_nm }}
              </span>
            </ng-template>

            <!-- More than One Tab -- Tab Content (i.e. Grid page) -->
            <ng-template matTabContent class="tabBody">
              <app-grid-page [pageInfo]="page" style="flex: 1 0 auto; height: 100%"></app-grid-page>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <!-- Desgin Tab 3 -->
        <mat-tab-group *ngIf="configTab == '3'"
            class="tabDesignThree"
            [@.disabled]="true"
            [disableRipple]="true"
            [selectedIndex]="selectedTabIndex"
            (selectedIndexChange)="seletedTab($event, dashboardPages)">
          <mat-tab *ngFor="let page of dashboardPages; let idx=index">
            <ng-template mat-tab-label>
              <span [ngClass]="{ 'tabDesignThreeSelected': selectedTabIndex == idx }" (click)="clickTabChange()">
                {{ page.page_nm }}
              </span>
            </ng-template>

            <!-- More than One Tab -- Tab Content (i.e. Grid page) -->
            <ng-template matTabContent class="tabBody">
              <app-grid-page [pageInfo]="page" style="flex: 1 0 auto; height: 100%"></app-grid-page>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <!-- Design Tab 4 -->
        <mat-tab-group class="custom-tab-group groupOfCss"
            *ngIf="configTab == '4'"
            fitInkBarToContent
            [@.disabled]="true"
            [disableRipple]="true"
            [selectedIndex]="selectedTabIndex"
            (selectedIndexChange)="seletedTab($event, dashboardPages)">
          <mat-tab *ngFor="let page of dashboardPages">
            <ng-template mat-tab-label>

              <div class="relSvg" style="position: relative;" (click)="clickTabChange()">
                <svg viewBox="98.131 168.337 199.483 47.954"
                    width="199.483"
                    height="47.954"
                    xmlns="http://www.w3.org/2000/svg">
                  <g transform="matrix(1.454693, 0, 0, 1.00074, 84.396833, 158.40268)">
                    <path d="M 10.5 18.318 C 10.5 14.315 13.219 11.068 16.572 11.068 L 127.573 11.068 C 129.959 11.068 132.122 12.737 133.105 15.331 L 144.823 46.263 C 146.64 51.061 143.703 56.5 139.291 56.5 L 16.572 56.5 C 13.219 56.5 10.5 53.254 10.5 49.25 L 10.5 18.318 Z"
                        style="stroke: rgb(225, 225, 225); fill: rgb(255, 255, 255); paint-order: fill; stroke-width: 1.62904px;"/>
                  </g>
                </svg>

                <div class="pageNameStyle"
                    [ngStyle]="{ 'font-weight': selectedTabName == page.page_nm ? 'bold' : 'normal' }">
                  {{ page.page_nm }}
                </div>
              </div>
            </ng-template>

            <!-- More than One Tab -- Tab Content (i.e. Grid page) -->
            <ng-template matTabContent class="tabBody">
              <app-grid-page [pageInfo]="page" style="flex: 1 0 auto; height: 100%"></app-grid-page>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <!-- Only One Tab --- Tab Content (i.e. Grid page) -->
      <div *ngIf="dashboardPages.length == 1">
        <app-grid-page [pageInfo]="dashboardPages[0]"
            style="flex: 1 0 auto; height: 100%"></app-grid-page>
      </div>

      <!-- <footer class="footer mt-auto" style="padding-top: 10px;">
        <div class="text-muted"
            style="text-align: center;font-family: 'Poppins';">{{ 'Copyright ©' + moment().year() + ' Data Unveil' }}
        </div>
      </footer> -->
    </div>
  </div>
</div>