<div class="ResPonsiveHighet" [style.height]="this.autoHeightFunc()">
  <gridster *ngIf="widgets?.length"
      [options]="options"
      class="pt-2 pb-3"
      style="background-color: #fff; padding-top: 0 !important;" id="gridster">

    <div class="du-talet" *ngIf="!isDesktop"
        style="min-height: 10px; height: auto; position: relative;">

      <app-text-notes *ngIf="textNotesWidget" [item]="textNotesWidget!"></app-text-notes>

      <div>
        <div class="d-flex justify-content-end px-3">
          <app-alert-button *ngIf="widgetService.alertIcon"></app-alert-button>
          <app-filter-button *ngIf="widgetService.filterIcon"></app-filter-button>
        </div>
      </div>

      <!-- COMMON WIDGETS LIKE FILTER, TIME CYCLE, INFORMATION ICON -->
      <div *ngIf="commonWidgets?.length"
          [ngStyle]="{'justify-content' : getCenterBetween()}"
          class="d-flex align-content-start flex-wrap"
          style="align-items: center; padding: 5px 15px 5px 15px;">
        <!-- Empty for Widgets -->
        <div *ngIf="commonWidgets.length === 1">
          <span></span>
        </div>

        <ng-container *ngFor="let widget of commonWidgets">
          <ng-container [app-widget]="{ item: widget, pageKey: pageInfo.page_key }"></ng-container>
        </ng-container>
      </div>

      <!-- KPI CARDS AND PRESCRIBER CARD -->
      <div *ngIf="kpiCardWidgets?.length || informationCardWidgets?.length"
          class="horizontal-scrollable"
          style="margin-left: 10px;">
        <div class="row text-center flex-nowrap overflowXAuto" style="padding: 10px">
          <div *ngFor="let widget of informationCardWidgets"
              class="cardss"
              [style.height]="showLessMore ? 'auto' : '125px'">
            <ng-container [app-widget]="{ item: widget, pageKey: pageInfo.page_key }"></ng-container>
          </div>

          <div *ngFor="let widget of kpiCardWidgets"
            #ssss
              [style.width]="widget.key === 100103 ? '400px' : widget.key === 100107 ? '300px' : '250px'"
              class="cards mb-1">
            <ng-container [app-widget]="{ item: widget, pageKey: pageInfo.page_key }"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <gridster-item 
      *ngFor="let widget of widgets" 
      [item]="widget" 
      [ngClass]="{
          highlight: !keysByType.nonHighlight.includes(widget.key)
      }"
      class="heightClass"
      
      [style.visibility]="!keysByType.visibilityHidden.includes(widget.key) ? 'block' : 'hidden'"
      #gridsterItemsss
    >
      <ng-container [app-widget]="{ item: widget, height: gridsterItemsss, pageKey: pageInfo.page_key }"
          (onSizeChange)="resize(widget)"></ng-container>
    </gridster-item>
  </gridster>

  <app-alerts-tab></app-alerts-tab>
  <app-dynamic-filter [item]="filterWidgetConfig"></app-dynamic-filter>
</div>

<app-loader-component *ngIf="showLoader"></app-loader-component>