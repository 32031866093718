import { Component, HostListener, OnInit } from '@angular/core';
import { SessionService } from './services/session.service';
import { IdleService } from './services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  lastActivityTimestamp: number = 0;
  debounceTimeout: any;

  constructor(
    private sessionService: SessionService,
    private idleService: IdleService
  ) {}

  ngOnInit(): void {}

  // @HostListener('document:mousemove')
  // @HostListener('document:keydown')
  // handleUserActivity() {
  //   this.authService.resetInactivityTimer();
  // }
  
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  handleUserActivity(event: MouseEvent | KeyboardEvent) {
    if(this.sessionService.getUsername()) {
      // Reset the debounce timeout
      clearTimeout(this.debounceTimeout);

      // Set a new debounce timeout
      this.debounceTimeout = setTimeout(() => {
        this.lastActivityTimestamp = Date.now();
        this.idleService.checkForInactivity();
      }, 1000); // Adjust the debounce time as needed
    }
  }
}
