<div class="widget-container"  *ngIf="item.config.chart_type =='stackedtwo'" #StackedAreaBartwoChartDiv>
    <app-stacked-areabar
    style="width: 100%;"
    [headerConfig]="item.config?.widget_header_config"
    [heading]="heading"
    [config]="config"
    [data]="data"
    [item]="item"
    [pageKey]="pageKey" >
    </app-stacked-areabar>
</div>

<div class="widget-container"  *ngIf="item.config.chart_type =='stackedmulti'" #StackedAreaBarmultiChartDiv>
    <app-stacked-areabar
    style="width: 100%;"
    [headerConfig]="item.config?.widget_header_config"
    [heading]="heading"
    [config]="config"
    [data]="data"
    [item]="item"
    [pageKey]="pageKey" >
    </app-stacked-areabar>
</div>


