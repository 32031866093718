import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges, AfterViewInit, AfterContentChecked, AfterViewChecked } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DonutChartComponent implements OnInit, OnChanges{
  @ViewChild('chartContainer',{static: true}) chartContainer!: ElementRef

  @Input('data') data: Array<any> = []
  @Input('dataColumn') valueColumn:string = 'data'
  @Input('colorColumn') colorColumn:string = 'color'
  @Input('donutWidth')  donutWidth:number = 10

  private width!: number;
  private height!: number;
  constructor() { }

  ngOnInit(): void {
    this.createChart()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue)
        this.createChart()
  }



  createChart() {
    const element = this.chartContainer.nativeElement
    this.width =  element.offsetWidth
    this.height = element.offsetHeight
    d3.select(element).selectAll('svg').remove()
    const svg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', this.height);
    var radius:number[] = []
    var arc:any[] = []
    var svgElement:any[] = []
    var path:any[] = []

    var pie = d3.pie()
    .value((d:any)=> { return d[this.valueColumn];})
    .sort(null);

    radius[0]= Math.min(this.width, this.height) / 2;
    for(let idx=1;idx<=this.data.length;idx++) {
      radius[idx]= radius[idx-1] - this.donutWidth;

      arc[idx] = d3.arc()
      .innerRadius(radius[idx] - this.donutWidth)
      .outerRadius(radius[idx]);

      svgElement[idx] = svg.append('g')
      .attr('transform', 'translate(' + (this.width / 2) + ',' + (this.height / 2) + ')');

      path[idx] = (svgElement[idx]).selectAll('path')
      .data(pie(this.data[idx-1]))
      .enter()
      .append('path')
      .attr('d', arc[idx])
      .attr('fill', (d:any, i:any) => {
        return this.data[idx-1][i][this.colorColumn];
      });
    }
}


updateChart() {
  }
}
