<span style="font-family: Poppins;font-size: 14px;margin: 8px 12px;position: absolute;">Comments: {{ numbedPipe(messages?.length) }}</span>
<div style="overflow: auto;margin: 27px 5px 0 ;min-width: 50px;width: 421px;height:295px">
  <div id="content">
    <div *ngFor="let message of messages; let i = index">
      <div style="display: flex; margin-top:8px;">
        <img class="imageCss" src="https://www.w3schools.com/howto/img_avatar.png" alt="Profile">
        <!-- menu for comments -->

        <mat-menu #menuBeforeCard="matMenu" xPosition="before">
          <button mat-menu-item style="text-align: left;" (click)="editComment(message,$event,i)">
            <mat-icon style="width: 20px;" svgIcon="edit"></mat-icon>
            <span style="font-family: 'poppins';font-size: 12px;">Edit</span>
          </button>

          <button mat-menu-item style="text-align: left;" (click)="DelDialog('300ms', '150ms')">
            <mat-icon style="width: 20px;" svgIcon="delete"></mat-icon>
            <span style="font-family: 'poppins';font-size: 12px;">Delete</span>
          </button>
        </mat-menu>

        <div class="card"
            style="background-color: #1d1d1d !important; color:#F6F8FB;margin-right:8px ;border-radius: 0px 5px 5px 3px;padding: 5px; position: relative;width: 80%;">
          <div style="display: flex;justify-content: space-between;">
            <div style="width: 212px;font-size: 10px;font-weight: 600; float: left;">
              <span style="font-family: 'Poppins'!important;">{{ message.employee_name || "No UserName" }}</span>
              <span *ngIf="employee_key._value == message.employee_key"
                  style="color: gray;margin: 2px;">(You)</span>
              <span *ngIf="editedChanges(message.update_timestamp)"
                  style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{ ' Edited' }}</span>
            </div>
            <div style="width: 82px;font-size: 9px;font-weight: 400; float: right;">
              <span style="font-family: 'Poppins'!important;">{{ (message.update_timestamp == null) ? getCurrentDate(message.insert_timestamp) : getCurrentDate(message.update_timestamp) }}</span>
            </div>
          </div>
          <div style="font-size: 12px;color:#ffffff;font-family: 'Poppins'!important;">
                  <span *ngIf="!edit || editIndex!=i"
                      [innerHtml]="getMSGHtml(message.comments)"></span>
            <textarea rows="4"
                cols="33"
                *ngIf="edit && editIndex==i"
                style="border: 1px solid rgb(221, 221, 221);border-radius: 10px;padding: 5px;width: 100%;"
                [(ngModel)]="editedValue"
                [maxlength]="maxMessageLength"
                type="text"
                id="edit"
                (keydown.enter)="SaveEdit(message ,i)"
                [value]="message.comments"></textarea>
            <div *ngIf="edit && editIndex==i"
                style="width: 100%;font-size: 9px;line-height: 0px;margin-top: 4px;text-align: right;">{{ editedValue.length + '/' + maxMessageLength }}
            </div>
            <div style="width: 100%;display: flex;justify-content: flex-end;">
                    <span *ngIf="edit && editIndex==i && editedValue==''"
                        style="color: #d02e2e;margin: auto;">Please type a message to continue</span>
              <button mat-icon-button
                  *ngIf="edit && editIndex==i"
                  color="primary"
                  (click)="SaveEdit(message ,i)">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-icon-button
                  *ngIf="edit && editIndex==i"
                  color="primary"
                  (click)="edit=false">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <!-- Delete Popup -->
          <ng-template #DeleteDialog>
            <div>
              <div style="width: 100%;background-color: #E8EDF3;height: 37px;display: flex;">
                <span style="font-size: 15px; width: inherit;padding: 10px;    font-weight: 600;">Delete comment</span>
                <button mat-icon-button
                    class="close-button"
                    [mat-dialog-close]="true"
                    style="margin-right: 1px;">
                  <mat-icon class="close-icon"
                      style=" height: auto !important; width: auto !important; margin-top: -18px;">
                    close
                  </mat-icon>
                </button>
              </div>
              <div style="height: 40px; margin: 10px auto; text-align: center;">
                Are you sure you want to delete this comment?
              </div>
              <div style=" margin: 0 auto; text-align: center;  padding: 10px;">
                <button mat-raised-button
                    style="background-color: white !important;color: #1363DF;border: 1px solid;"
                    mat-dialog-close
                    cdkFocusInitial>Cancel
                </button>
                <button mat-raised-button
                    style="background-color: white !important; color:red; border: 1px solid;margin-left: 25px;"
                    mat-dialog-close
                    (click)="deleteComment()">Delete
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <mat-icon [matMenuTriggerFor]="menuBeforeCard"
            *ngIf="employee_key._value == message.employee_key && !edit"
            style="align-self: center;cursor: pointer;"
            svgIcon="menu"
            (click)="getcomentData(message, i)"></mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="col-12" style="place-self: center;">
  <div class="bottomBoth">
    <div style="width: 100%; display: flex;">
      <div style="width: 90%;" class="matlined">
        <mat-form-field style="width:100% ;" appearance="outline">
          <textarea matInput
              id="comment"
              #searchInput="ngModel"
              class="firstTabinIRScrolls"
              cdkTextareaAutosize
              placeholder="Add comment here"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              [(ngModel)]="searchValue"
              [maxlength]="maxMessageLength"
              (click)="$event.stopPropagation()"
              (keydown.enter)="messageData(searchValue ,$event)"
              cdkAutosizeMaxRows="3"
              required >
          </textarea>
        </mat-form-field>
      </div>
      <div style="display: flex;align-items: center;width: 11%;font-size: 10px;margin-top: 5px;padding-left: 5px;font-weight: 600;">
        <span [ngStyle]="{'color':searchValue.length==maxMessageLength?'green':'white'}" >{{ searchValue.length + '/' + maxMessageLength }}</span></div>
    </div>
    <div style="display:flex;align-items:center;">
      <mat-icon class="matIcons"
          style="width: 36px;height: 36px;"
          (click)="messageData(searchValue, $event)"
          svgIcon="send"></mat-icon>
    </div>
  </div>
  <!-- <div *ngIf="searchInput.invalid && (searchInput.dirty || searchInput.touched)" style="height:20px">
    <span *ngIf="searchInput.errors?.['required']" style="color: red;">This field is required.</span>
    <span *ngIf="searchInput.errors?.['maxlength']" style="color: red;">Maximum {{ maxMessageLength }} characters allowed.</span>
  </div> -->
</div>