import { Injectable } from "@angular/core";
import { FilterService } from "./filter.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class UserActivityService {

  hierarchicalGridState:any = [];

  userStateData:any = {
    "username" : "",
    "selectedFilters" : {},
    "pageTab":0,
    "selectedType": ''
  }

  userStateForTimeCycleFilter:any = {
    "selectedFilters" : {},
  }
  
  userStateSingleDateFilter:any = {
    "selectedFilters" : {},
  }
  
  userStateForTimeCycleFilterSingle:any = {
    "selectedFilters" : {},
  }
  userStateForKPI:any = {
    "selectedFilters" : {},
  }

  constructor(
    private sessionService:SessionService,
    private filterService:FilterService  
  ) {
  }
  private key = 'userState';
  private userStateSingleDate = 'userStateSingleDate';
  private userStateTime = 'userStateTime';
  private userStateTimeSingle = 'userStateTimeSingle';
  private userStateKpiSingle = 'userStateKpiSingle';

  getData(): any {
    const data:any = sessionStorage.getItem(this.key);
    return JSON.parse(data) || this.userStateData;
  }

  getDataTimeCycle(): any {
    const data:any = sessionStorage.getItem(this.userStateTime);
    if(!data) {
      return {"selectedFilters" : {}}
    }
    return JSON.parse(data);
  }
  
  getDataTimeCycleSingle(): any {
    const data:any = sessionStorage.getItem(this.userStateTimeSingle);
    return JSON.parse(data) || this.userStateForTimeCycleFilterSingle;
  }
  
  getDataKpiSingle(): any {
    const data:any = sessionStorage.getItem(this.userStateKpiSingle);
    return JSON.parse(data) || this.userStateForKPI;
  }

  // Set Date for User filter
  setData(data: any): void {
    this.userStateData = this.getData()
    let userName = this.sessionService.getUser()
    this.userStateData['username'] = userName.username

    // Object into Array of Object
    let updatesObj:any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for(let i = 0; i <  updatesObj.length; i++) {
      if(updatesObj[i].key == "selectedFilters") {
        let Obj:any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res:any)=> {
          this.userStateData[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.userStateData[updatesObj[i].key] = updatesObj[i].value
      }
    }

    const serializedData:any = JSON.stringify(this.userStateData);
    sessionStorage.setItem(this.key, serializedData);
  }

  // time cycle based on filter 
  setDataTimeCycle(data: any): void {
    this.userStateForTimeCycleFilter = this.getDataTimeCycle()

    // Object into Array of Object
    let updatesObj:any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for(let i = 0; i <  updatesObj.length; i++) {
      if(updatesObj[i].key == "selectedFilters") {
        let Obj:any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res:any)=> {
          this.userStateForTimeCycleFilter[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.userStateForTimeCycleFilter[updatesObj[i].key] = updatesObj[i].value
      }
    }
    

    const serializedData:any = JSON.stringify(this.userStateForTimeCycleFilter);
    sessionStorage.setItem(this.userStateTime, serializedData);
  }

  // time cycle based on filter 
  setDataTimeCycleSingle(data: any): void {
    this.userStateForTimeCycleFilterSingle = this.getDataTimeCycleSingle()

    // Object into Array of Object
    let updatesObj:any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for(let i = 0; i <  updatesObj.length; i++) {
      if(updatesObj[i].key == "selectedFilters") {
        let Obj:any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res:any)=> {
          this.userStateForTimeCycleFilterSingle[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.userStateForTimeCycleFilterSingle[updatesObj[i].key] = updatesObj[i].value
      }
    }
    

    const serializedData:any = JSON.stringify(this.userStateForTimeCycleFilterSingle);
    sessionStorage.setItem(this.userStateTimeSingle, serializedData);
  }


  // KPI Single based on filter 
  setDataKpiSingle(data: any): void {
    this.userStateForKPI = this.getDataKpiSingle();

    // Object into Array of Object
    let updatesObj:any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for(let i = 0; i <  updatesObj.length; i++) {
      if(updatesObj[i].key == "selectedFilters") {
        let Obj:any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res:any)=> {
          this.userStateForKPI[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.userStateForKPI[updatesObj[i].key] = updatesObj[i].value
      }
    }
    

    const serializedData:any = JSON.stringify(this.userStateForKPI);
    sessionStorage.setItem(this.userStateKpiSingle, serializedData);
  }

  setUserStateSingleDate(data: any): void {
    const serializedData:any = JSON.stringify(data);
    sessionStorage.setItem(this.userStateSingleDate, serializedData);
  }
  
   
  getUserStateSingleDate(): any {
    const data:any = sessionStorage.getItem(this.userStateSingleDate);
    if(!data) {
      return
    }
    return JSON.parse(data);
  }
}
