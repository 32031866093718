import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { input } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-nested-widget',
  templateUrl: './nested-widget.component.html',
  styleUrls: ['./nested-widget.component.scss']
})
export class NestedWidgetComponent implements OnInit, OnDestroy {
  static key = 100807;

  @ViewChild("nestedwidget", { static: true }) nestedwidget!: ElementRef;
  @Input("item") item: any;
  @Input("height") height: any;
  @Input("data") data: any;
  @Input("pageKey") pageKey: any;

  @HostBinding("class.is-fullscreen") isFullscreen = false;
  @ViewChild("fs") fs!: ElementRef;

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();

  isActive: boolean = false;
  isLoading: boolean = true;
  noData: boolean = false;
  reqSubcription: Subscription[] = [];
  items: any;

  constructor(private filterService: FilterService,) {
    this.reqSubcription.push(
      this.filterService.chartshowBy.subscribe((res: any) => {
        
        if (res.widget == 100807) {
          this.isLoading = true
          this.changeChart(res.value);
        }

      })
    )
  }

  ngOnInit(): void {
    // debugger

    this.items = this.item?.["nested_widget_config"]?.[2]
    
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.nestedwidget.nativeElement);

  }

  fullscreenChanges(fullscreen: any) {
    this.isFullscreen = fullscreen;
    this.isActive = fullscreen;
  }

  sizeEmit() {};

  changeChart(index: any) {
    this.items = this.item["nested_widget_config"][Number(index)];
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
