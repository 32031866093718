<div class="widget-container" #SparklinesgridCharDiv>
    <app-Sparklines-grid
    [headerConfig]="this.item?.config?.widget_header_config"
    [heading]="heading"
    [config]="config"
    [item]="item"
    [data]="data"
    [rawData]="rawData"
    [fieldData]="fieldData"
    [commentsData]="commentsData"
    [pageKey]="pageKey"
       >
    </app-Sparklines-grid>
</div>
<!-- *ngIf="isDataLoaded"  -->