import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {

  @Input('filterData') filter: any = ''

  dataObj = [
    {
      name: "Territory Name",
      currentValue: 3,
      totalValue: 5
    },
    {
      name: "RX type 1",
      currentValue: 2,
      totalValue: 6
    },
    {
      name: "SP name",
      currentValue: 19,
      totalValue: 50
    },
    {
      name: "Payer name",
      currentValue: 35,
      totalValue: 50
    },
    {
      name: "Referral source",
      currentValue: 143,
      totalValue: 150
    },
    {
      name: "Current status",
      currentValue: 65,
      totalValue: 150
    },
    {
      name: "Prescriber",
      currentValue: 13,
      totalValue: 25
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  resetFilters(){
    
  }

}
