import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-comments-grid',
  template: `<div *ngIf="!isEditing">
  <div><span style="color:#101D42">{{ params.value}}</span></div>
</div>
<div *ngIf="isEditing">
          <textarea type="text" 
          [(ngModel)]="value"
          [maxlength]="comments_length"
          (keydown)="handleKeyDown($event)"
          (click)="$event.stopPropagation()"
          (ngModelChange)="onValueChanged($event)"  
          style="font-size: 12px;color:#101D42;" 
          class="form-control scrollDEM mb-1">
</textarea>
<span style="font-size: 10px;position: relative;display: flex;justify-content: end;" >{{(value ? value['length'] : 0)+ '/'+comments_length }}</span>
  </div>`,
  styleUrls: ['./comments-grid.component.scss']
})
export class CommentsGridComponent implements ICellRendererAngularComp {
  public params: any;
  public value: string = '';
  public isEditing: boolean = false;
  public editIndex:any
  comments_length:number=250

  agInit(params: ICellRendererParams<any, any>): void {
    debugger
    this.isEditing = this.params?.api?.isEditing || false
    this.params = params;
    this.value = this.params.value;
    this.comments_length=this.params?.comments_length||250
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    debugger
   
    if ( (this.params.api?.editIndex == this.params.node.rowIndex) && this.params.api?.isEditing) {
      this.isEditing = this.params.api.isEditing;
    }
    this.params = params;
    this.value = this.params.value;
    return true;
  }
  onValueChanged(event: any) {
    // console.log('Value changed:', event);
    this.params.setValue(event);  // Set the value programmatically
  }
  handleKeyDown(event: KeyboardEvent): void {
    event.stopPropagation()
    if (event.key === 'Enter') {
      event.preventDefault();
      // Optionally: Add custom logic for when Enter is pressed
    }
    // Add more conditions if needed
  }
}