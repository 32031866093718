import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationExtras, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CryptoService } from './crypto.service';
import { Location } from '@angular/common'
import * as _ from 'lodash';;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private routerPages: any = [];

  constructor(
    private router: Router, 
    private cryptoService: CryptoService,
    private location: Location
  ) {

    // Navigation Start
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart | any) => {

        if (event.navigationTrigger == 'popstate') {
          let navPag = sessionStorage.getItem('routerNavDetailPages');
          
          if (navPag) this.routerPages = JSON.parse(navPag);
          else this.routerPages = [];

          if (this.routerPages.length == 0) sessionStorage.removeItem('routerNavDetailPages');

          const routerPage = this.routerPages.slice().reverse();

          let fndPage = routerPage.find((o:any)=> o.url == event.url);
          if(fndPage) {
            
            if(fndPage.payload) sessionStorage.setItem("payloadData", fndPage.payload);
            else sessionStorage.removeItem("payloadData")

            if(fndPage.filter) sessionStorage.setItem("filterData", fndPage.filter);
            else sessionStorage.removeItem("filterData")

            sessionStorage.setItem("navDetailInfo", fndPage.page);
          } 
        }

      }
    );

    // Navigation End;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd | any) => {
        
        let navPag = sessionStorage.getItem('routerNavDetailPages');

        if (navPag) this.routerPages = JSON.parse(navPag);
        else this.routerPages = [];

        let fndRoutes = this.routerPages.find((o:any)=> o.url == event.urlAfterRedirects);
        if(!fndRoutes) {
          
          const navDetailInfoString: any = sessionStorage.getItem("navDetailInfo");

          if(!navDetailInfoString) return;

          let storeSession:any = {
            page: navDetailInfoString,
            url: event.urlAfterRedirects
          }
          
          // Filters Carry
          const filterDetail: any = sessionStorage.getItem("filterData");
          const payloadDetail: any = sessionStorage.getItem("payloadData");

          if(payloadDetail) storeSession['payload'] = payloadDetail;
          if(filterDetail) storeSession['filter'] = filterDetail;

          this.routerPages.push(storeSession);
          
          sessionStorage.setItem('routerNavDetailPages', JSON.stringify(this.routerPages));
        }
      }
    );

  }

  navigateTo(url: string, extras?: NavigationExtras): void {
    const encryptedUrl = this.encryptUrl(url);
    this.router.navigateByUrl(encryptedUrl, extras);
  }

  private encryptUrl(url: string): string {
    return this.cryptoService.encrypt(url); // Implement encryption logic
  }

  private decryptUrl(url: string): string {
    return this.cryptoService.decrypt(url); // Implement encryption logic
  }

  goBack(): void {
    this.location.back();
  }

  routerNavigate(url:any, queryParams:any) {

    const encryptedQueryParams:any = this.encrypedData(queryParams);

    const navigationExtras: NavigationExtras = {
      queryParams: encryptedQueryParams
    };
    
    this.router.navigate([`${url}`], navigationExtras);
  }

  encrypedData(queryParams:any) {
    const encryptedQueryParams:any = {};
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        if(key=='tab_id' || key=='record' || key=='acc_nm' || key == 'page' || key == 'hd_flg' || key == 'crds') {
          encryptedQueryParams[key] = queryParams[key];
        } else {
          encryptedQueryParams[key] = this.cryptoService.encrypt(queryParams[key]);
        }
      }
    }
    return encryptedQueryParams;
  }

  decryptData(queryParams:any) {
    const decryptedQueryParams:any = {};
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        if(key=='tab_id' || key=='record' || key=='acc_nm' || key == 'page' || key == 'hd_flg' || key == 'crds') {
          decryptedQueryParams[key] = queryParams[key];
        } else {
          decryptedQueryParams[key] = this.cryptoService.decrypt(queryParams[key]);
        }
      }
    }
    return decryptedQueryParams;
  }

  filterSingleState(keys:any) {

    if(sessionStorage.getItem('filterData')) {
      let filter:any = {};

      let navPag = sessionStorage.getItem('filterData');
      
      if (navPag) filter = JSON.parse(navPag);
      else filter = {};

      if(filter[keys]) delete filter[keys];

      if(Object.keys(filter).length>0) sessionStorage.setItem('filterData', JSON.stringify(filter));
      else sessionStorage.removeItem('filterData');
    }


    if(sessionStorage.getItem('routerNavDetailPages')) {
      let routerPages = [];
      let navPag = sessionStorage.getItem('routerNavDetailPages');
            
      if (navPag) routerPages = JSON.parse(navPag);
      else routerPages = [];
  
      if (this.routerPages.length == 0) return;
  
      routerPages.map((m:any)=> {
        if(m.filter) {
          let filter = JSON.parse(m.filter) || {};
          if(filter[keys]) {
            delete filter[keys];
            m.filter = JSON.stringify(filter);
          } 
        }
      })
  
      sessionStorage.setItem('routerNavDetailPages', JSON.stringify(routerPages));
    }

  }
  

  movePropertyToFirst(obj: any, key: string) {
    if (!obj.hasOwnProperty(key)) {
      return obj; // Key doesn't exist in the object, return the original object
    }

    // Create a new object with the specified key first
    let newObj = { [key]: obj[key] };

    // Merge the new object with the original object without the specified key
    return _.merge(newObj, _.omit(obj, key));
  }
}