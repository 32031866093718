<div #comboBar style="font-family: Poppins !important">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <div class="" style="background-color: #ffff;">

        <icon-chart [headerConfig]="headerConfig" [iconList]="iconList" [data]="data" [pageKey]="pageKey"
          [heading]="heading" chartName="comboBar" [config]="barContainer" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item">
        </icon-chart>


      </div>
      <div><loading *ngIf="isLoading"> </loading></div> 
      <div [style.display]="noData?'none':''"  #barContainer></div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
       <span class="bg-text" >There is no data for this time period</span></div>
      <div #lineChart>
        <div id="d3comboBarTooltip">

            <div class="container chart_tooltip" *ngIf="renderLinePopup && comboBarPopupData[1]?.length > 2">
              <div class="row  chart_tooltip_header">
                <div class="col-12 chart_tooltip_header_data mt-1 mb-1">
                    {{myType == 'bar'? removeUnderScore(props.yAxisLabelRight)  :  removeUnderScore(props.yAxisLabelLeft)}} ({{comboBarPopupData[0] | date: 'dd-MMM-yyyy' }})
                </div>
              </div>

              <div class="mt-2 mb-5 pb-2" *ngIf="height > 250" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                <div class="row" *ngFor="let data of comboBarPopupData[1];let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center">
                    <div>
                      <div class="tooltip_outlay" [style.background]="data[3]"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                    </div>
                    <div class="ml-2">
                      <img
                        src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                        *ngIf="data[2] == 'up'" />
                      <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                        *ngIf="data[2] == 'down'" />

                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt" >
                  more</div> -->
              </div>

              <div class="mt-2 mb-5 pb-2" *ngIf="height < 250" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
                <div class="row" *ngFor="let data of comboBarPopupData[1];let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center">
                    <div>
                      <div class="tooltip_outlay" [style.background]="data[3]"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                    </div>
                    <div class="ml-2">
                      <img
                        src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                        *ngIf="data[2] == 'up'" />
                      <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                        *ngIf="data[2] == 'down'" />

                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="showMore" (click)="openDialog()" class="d-flex justify-content-center showMore_txt">more</div> -->
              </div>
            </div>

            <div class="container chart_tooltip" *ngIf="renderLinePopup && comboBarPopupData[1]?.length <= 2">
              <div class="row chart_tooltip_header">
                <div class="col-12 chart_tooltip_header_data  mt-1 mb-1">
                    {{myType == 'bar'? removeUnderScore(props.yAxisLabelRight)  :  removeUnderScore(props.yAxisLabelLeft)}} ({{comboBarPopupData[0] | date: 'dd-MMM-yyyy' }})
                </div>
              </div>

              <div class="mt-2 mb-5 pb-2" *ngIf="height > 250" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-afterLess' :'chart_tooltip_bodyLess'">
                <div class="row" *ngFor="let data of comboBarPopupData[1];let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center">
                    <div>
                      <div class="tooltip_outlay" [style.background]="data[3]"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                    </div>
                    <div class="ml-2">
                      <img
                        src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                        *ngIf="data[2] == 'up'" />
                      <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                        *ngIf="data[2] == 'down'" />

                    </div>
                  </div>
                </div>


              </div>

              <div class="mt-2 mb-5 pb-2" *ngIf="height < 250" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100Less' :'chart_tooltip_body100Less'">
                <div class="row" *ngFor="let data of comboBarPopupData[1];let i=index">
                  <div class="col-12 d-flex m-auto  align-items-center">
                    <div>
                      <div class="tooltip_outlay" [style.background]="data[3]"></div>
                    </div>
                    <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
                    </div>
                    <div class="ml-2">
                      <img
                        src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                        *ngIf="data[2] == 'up'" />
                      <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                        *ngIf="data[2] == 'down'" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </article>
  </main>
</div>

<!-- Line chart Tooltip Popup -->
<ng-template #showMoreData>
  <div class="">
    <div class="row tooltip_popup_header">
      <div class="col-12 d-flex flex-column">
        <div class="row">
          <div class="chart_tooltip_header_data_in mt-1 ml-2">
            {{comboBarPopupData[0] | date: 'dd-MMM-yyyy' }}
          </div>
          <div class="chart_tooltip_header_heading1 mt-1 mb-2 ml-2">
            {{myType == 'bar'? removeUnderScore(props.yAxisLabelRight)  :  removeUnderScore(props.yAxisLabelLeft)}}
          </div>
        </div>
      </div>
    </div>
    <div class="row details-body"   style="height: 300px;overflow-x: hidden;overflow-y: auto;">
      <div class="col-12 d-flex mt-1 ml-2" *ngFor="let data of totalTooltipData[1];let i=index">
        <div>
          <div class="tooltip_outlay" [style.background]="data[3]"></div>
        </div>
        <div class="chart_tooltip_body_data ml-2">{{data[0]}} : {{data[1] ? numbedPipe(data[1]) : data[1] }}
        </div>
        <div class="ml-2">
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
            *ngIf="data[2] == 'up'" />
          <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
            *ngIf="data[2] == 'down'" />
        </div>
        <div>
        </div>
      </div>
    </div>
    <div [mat-dialog-close]="true" class="row details-body justify-content-center showLess_txt" >
      less</div>
  </div>

</ng-template>