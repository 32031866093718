import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter<void>();

  private timeout: any;

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: Event): void {
    this.timeout = setTimeout(() => {
      this.longPress.emit();
    }, 1000); // Adjust the time (in milliseconds) for a long-press event
  }

  @HostListener('touchend')
  @HostListener('touchleave')
  onTouchEnd(): void {
    clearTimeout(this.timeout);
  }
}