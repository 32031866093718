import { Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router, UrlHandlingStrategy } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { Auth } from "@aws-amplify/auth";
import { MatDialog } from "@angular/material/dialog";
import { ConstantsService } from "src/app/services/constants.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";
import { DatePipe, Location } from "@angular/common";
import { NavigationService } from "src/app/services/navigation.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { MixpanelService } from 'src/app/services/mixpanel.service';
import moment from "moment";
import { Subscription, concatMap, from } from "rxjs";
import { environment } from "src/environments/environment";
import { EnhancementService } from "src/app/services/enhancements-service";
import { ExportPPTService } from "src/app/services/export-ppt.service";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit, OnDestroy {
  
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  
  @Input("logoImage") logoImage = "";
  @Input("prName") prName = "";
  @Input("clientLogo") clientLogo = "";
  @Input("title") title = "";
  @Input("pagename") pagename = "";
  @Input("refreshTheDashboard") refreshTheDashboard:any;
  @Input("colorCode") colorCode = "";

  userName: string = "";
  toggleAlert: boolean = false;
  type: string = "linear-gradient";
  logo: any;
  line: any;
  moduleKey: any;
  clientKey: any;
  dashboardKey: any;
  modulePage: boolean = false;
  showAlerts: boolean = true;
  refreshPageData = false;
  locationLoaded = true;
  id: any;
  cardName: any = "";
  cardValue: any = "";
  cardId: any;
  locationName: any;
  environment:any;
  decryptedParams:any;
  routerLoader = false;
  isIpad = false;
  userEmail = '';
  clientLength:any= [];
  intervalId: any;
  lastRefreshDate:any;
  selectedBrandName:any;
  accountName:any;
  moduleName:any;

  usrImp:any;
  userInfo:any;
  gender:any;

  trash = "trash_delete";
  edit = "editIcon";
  cancel_x = "cancel_x";

  userProfile_logout = 'userProfile_logout'
  userProfile_bell = 'userProfile_bell'
  userProfile_manage = 'userProfile_manage'
  
  refreshData = false;
  refreshDate:any;
  reqSubcription: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private route: Router,
    private router: ActivatedRoute,
    private sessionService: SessionService,
    private dashboardService: DashboardService,
    private dataService: DataService,
    public filterService: FilterService,
    private navigationService: NavigationService,
    private mixpanelService: MixpanelService,
    public deviceDetectionService: DeviceDetectionService,
    public enhancementService: EnhancementService,
    public exportPPT: ExportPPTService
  ) {

    this.router.params.subscribe((p: any) => {
      this.cardName = p["cardName"] || '';
      this.moduleName = p['module'] || '';

      // Query Params
      this.router.queryParams.subscribe((params:any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
        
        // Module Or Dashboard
        if (this.dashboardKey) {
          this.modulePage = false;
        } else {
          this.modulePage = true;
        }
        
        this.accountName = params['acc_nm'] || '';
        
        if(params['page']) {
          this.locationName = '';
        }
        
        // If Card Name;
        if (this.cardName) {
          this.routerLoader = true;
        } else {
          
          this.enhancementService.allPageInformation.subscribe((res:any)=> {
            const headerName: any = sessionStorage.getItem("navbar_page_nm");
            let pageName: any = JSON.parse(headerName);

            if(res) {
              // Payment Calc Header Name
              if(params?.['record']=='yes' && this.dashboardKey==20230508080217) {
                this.pagename = 'Payment Details'
              } else {
                // TopNav Header Name
                let headerName = res?.find((o:any) => o.dashboard_key == this.dashboardKey);
                if(headerName) {
                  this.pagename = headerName?.dashboard_nm || headerName?.work_nm || '';
                } else {
                  this.pagename = pageName?.dashboard_nm || pageName?.work_nm;
                }
              }
            } else {
              this.pagename = pageName?.dashboard_nm || pageName?.work_nm;
            }
          })

          this.routerLoader = false;
        }
      });

    });

    this.environment = environment.topAlertSetting
    
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((r):any=> {
      this.lastRefreshDate = moment().format("MM/DD/YYYY")+ ' - ' + moment().format("HH:mm");
    }))

    let user:any = sessionStorage.getItem('userInformation')
    user = JSON.parse(user)

    this.userAttributes(user)
  }

  async pptExport(): Promise<void> {
    const gridster = document.querySelector('gridster');

    if (!gridster) {
      console.log('Gridster element not found.');
      return;
    }

    const gridsterItems = gridster.querySelectorAll('gridster-item');

    if (gridsterItems.length === 0) {
      console.log('No gridster items found.');
      return;
    }

    const itemsArray = Array.from(gridsterItems);
    itemsArray.pop();

    try {
      await this.exportPPT.exportToPpt(itemsArray as HTMLElement[], this.pagename);
    } catch (error) {
      console.error('Error exporting to PowerPoint:', error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['pagename']?.currentValue!=changes['pagename']?.previousValue) {
      document.title = changes['pagename'].currentValue ? `SCRAII - ${changes['pagename'].currentValue}` : 'SCRAII'
    }
  }
  
  ngOnInit(): void {

    this.lastRefreshDate = moment().format("MM/DD/YYYY")+ ' - ' + moment().format("HH:mm");

    this.reqSubcription.push(this.filterService.lastRefreshedDate.subscribe((r:any)=> {
      if(r) {
        if(this.dashboardKey == 20230508080217){
          this.selectedBrandName = r['brand_name'] || ''
        }
        this.refreshLastDate(r)
      }
    }))
    
    this.reqSubcription.push(this.filterService.topNavLocation.subscribe((r:any)=> {
      // debugger
      if (this.cardName == "pwt" && this.accountName) {
        this.locationName = r['account_name'] || this.locationName;
      }
    }))
    
    // Environment get from session
    let switchClient:any = sessionStorage.getItem('switchClient');
    this.clientLength = switchClient ? JSON.parse(switchClient) : [];

    this.userEmail = this.sessionService.getUsername();
    
  }
  styleLogo(styleLogo: any) {
    return styleLogo;
  }


  logoclicktoBack() {
    this.filterService.brandSelectWithBackNavigation = true;
    if (this.moduleKey) {
      
      let query = {
        cl_key: this.clientKey,
        md_key: this.moduleKey
      }
      
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}`, query)
    } else {
      this.route.navigate([`dashboard/`]);
    }
  }

  styleObject(title: any) {
    return { "background-image": "url(" + title + ")" };
  }

  previousPage() {
    this.navigationService.goBack();
  }

  onChange(event: any) {
    if (event.target.value === "logout") {
      this.logout();
    }
  }

  logout() {
    Auth.signOut();
    
    sessionStorage.clear();
    this.mixpanelService.track("Logout");
    this.dialog.closeAll();

    this.sessionService.setUser(null);
    this.sessionService.setUsername(null);
    this.sessionService.setLoginTime(null);

    // Clear history and navigate to login page
    const navigationExtras: NavigationExtras = {
      replaceUrl: true
    };

    // this.route.navigate(['/login'], navigationExtras);
    window.location.href = 'login';
  }

  userAttributes(res:any) {
     
    let userImper:any  = sessionStorage.getItem('userImperson')
    this.usrImp = JSON.parse(userImper)

    if(res) {
      this.userInfo = res ? res : {}
      this.userName = res.usr_display_nm;
      this.sessionService.userName = res.usr_display_nm;
      this.gender = res.gender || 'M';
    }
  }

  impersonation() {
    window.location.href = "/impersonation";
  }

  toggleAlertSettings() {
    this.toggleAlert = true;
    this.dataService.alertSettingToggle.next(true);
  }
  showNotifications() {
    this.showAlerts = false;
  }

  
  closeMenu() {
    this.trigger.closeMenu();
  }
  
  getRefreshData() {
    this.refreshPageData = true;
    this.filterService.filterQuery.next(true);
    this.lastRefreshDate = moment().format("MM/DD/YYYY")+ ' - ' + moment().format("HH:mm");
    setTimeout(() => {
      this.refreshPageData = false;
    }, 2000);
  }

  menuBar() {
    this.filterService.userMenuSideBar.next(true);
  }

  switchClient() {
    sessionStorage.removeItem("client");
    this.route.navigate([`home/`]);
  }
  
  refreshLastDate(brand:any) {
     

    if(brand) {
      let obj = {"brand_key" : brand.brand_key}
      this.reqSubcription.push(this.dashboardService.getRefreshedLastDate(obj).subscribe((res:any) => {
        this.filterService.refreshData = true;
        if(res) {
          this.refreshDate = res[0] ? moment(res[0].refresh_date).format("MM/DD/YYYY")+ ' - ' + moment(res[0].refresh_date).format("HH:mm"): '';
        }
      }))
    }
  }

  openAlertsPopupDQ() {
    let query = {
      tab_id: 0,
      cl_key: this.clientKey,
      md_key: 100101,
      ds_key: "20231130022815"
    }
    this.navigationService.routerNavigate(`dashboard/${'Operations'}/page/alert/config`, query);
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
    clearInterval(this.intervalId); // Clear the interval when the component is destroyed
  }
}
