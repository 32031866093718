<div *ngIf="data && dataLoaded" class="d-flex justify-content-center align-content-start" style="align-items: center;">
    <div style="color: #101D42">
        Alerts:
    </div>
    <div *ngFor="let a of data" style="padding-left: 7px;">
        <mat-icon (click)="matIconClick($event,a)" [customTooltip]="tooltipComponent"
            [matBadge]="a.Count == 1 ? '' : a.Count" matBadgeColor="warn" style="width: 24px; height: 24px;"
            [svgIcon]="a.icon" [style.color]="a.color"></mat-icon>
    </div>
</div>
<span *ngIf="!dataLoaded || !data" style="color: #8A98AB">
    <span style="color: #101D42">Alerts: </span> No alerts
</span>