import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Injectable, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core'
import * as _ from 'lodash';
import { Subscription } from 'rxjs'
import {  MapConfig, MapDataItem, StateService, albersUsa, Map, Legend } from '@dataunveil/ducharts'
import { FilterService } from 'src/app/services/filter.service'
import { MatDialog } from '@angular/material/dialog'
import { ModalComponent } from './modal/modal.component'
import { CommonModule } from '@angular/common'
import { TooltipComponent } from './tooltip.component'
import { FormsModule } from '@angular/forms'
import { ApiService } from 'src/app/services/api.service'
import { HttpClient } from '@angular/common/http'
import config from './config'
import { ChartsModule } from '../../shared/charts/charts.module'
import Widget from '../widget'


@Component({
  standalone: true,
  imports: [CommonModule, Map,Legend,TooltipComponent, FormsModule, TooltipComponent,forwardRef(() => ChartsModule) ],
  selector: 'app-dispense-map',
  template: `
    <div style="width:100%;">
      <main>
        <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
          <div class="row" style="background-color: #fff;">
            <icon-chart
                    [headerConfig]="headerConfig"
                    [data]="data"
                    [pageKey]="pageKey"
                    [heading]="heading"
                    [chartName]="divId"
                    [config]="el"
                    [expand]="fs"
                    [fullscreen]="isFullscreen"
                     (screenChanges)="fullscreenChanges($event)"
                    [item]="item"
            ></icon-chart>
          </div>
          <loading *ngIf="loader"></loading>

          <div class="widget-container" #el>
            <duc-map *ngIf="mapConfig" [config]="mapConfig" [data]="mapData">
              <app-dispense-map-tooltip [config]="config.tooltip"></app-dispense-map-tooltip>
            </duc-map>
            <div class="legend">
              <h3 class="header">{{ 'Source' }}</h3>
              <duc-legend class="dulegend"
                [style.height]="isFullscreen?'calc(92vh - 100px)':'610px'"
                [config]="$any(config.legend)"></duc-legend>
            </div>
          </div>
        </article>
      </main>
    </div>

  `,
  styleUrls: ['./dispense-map.component.scss','../widget.scss']
})
export class DispenseMapComponent  extends Widget {
  static override key = 100506;
  iconList: any = []
  isActive = false
  noData=false
  loader = true
  divId: string = 'bubbleMapDiv'
  mapConfig!: MapConfig
  mapData: MapDataItem[] = []
  projection = albersUsa()
  dialog: any
  headerConfig:any
  heading:any
  @ViewChild(Map) map!: Map
  
  constructor(protected stateService: StateService,
  
  ) {
    super()
    this.subs.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loader = true
      this.loadApiData()
    }))
  }
 @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChanges(event: any) {
    if (this.isFullscreen == true) {
        this.changeCanvasWidth(window.innerWidth - 300)
      }else{
          this.changeCanvasWidth(window.innerWidth - 500)
      }
  }
  fullscreenChanges(fullscreen:any){
    this.isFullscreen = fullscreen;
    this.isActive = fullscreen;
}
  changeCanvasWidth(newWidth: number): void {
    // Access the native canvas element
    const canvasElement: HTMLCanvasElement = this.el.nativeElement.querySelector('.basemap');

    // Set the new width dynamically
    canvasElement.style.width = `${newWidth}px`;
  }
  override  ngOnInit() {
    // debugger
    this.iconList = this.item.config['icon'] ? this.item.config['icon'] : this.iconList
    this.headerConfig = this.item?.config?.['widget_header_configuration']
   this.config = config

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height)
    })
    observer.observe(this.el.nativeElement)
    this.subs.push(this.stateService.get(this.config.map.id, 'selected')
      .subscribe((state: any) => this.toggleModal(state.selected)))

  }

  async loadedData () {
    const topology = await fetch('assets/data/us-2023.counties.albers.json').then(a => a.json())
    const cities = await fetch('assets/data/us.cities.json').then(a => a.json())
    const data = [...this.data]

    function compareState(a: any, b: any) {
      return (a / 1000 | 0) === (b / 1000 | 0)
    }

    this.mapData = this.format(data, cities)
    const maxValue = _.maxBy(this.mapData, 'value')?.value || 0
    this.mapConfig = {
      id: config.map.id,
      view: {
        ...config.map.view,
        topology,
        areas: ['nation', 'states', 'counties'],
        detailsByZoom: [{
          range: [4, 20],
          type: 'area',
          name: 'counties',
        }, {
          range: [1, 1.99],
          type: 'areaLabel',
          name: 'states',
          field: 'abbr',
        }, {
          range: [2, 8],
          type: 'areaLabel',
          name: 'states',
          field: 'name',
        }, {
          range: [18, 20],
          type: 'areaLabel',
          name: 'counties',
          field: 'name',
        }],
        areasFilter: [, (a: any, b: any) => a !== b, (a: any, b: any) => a !== b && compareState(a.id, b.id)],
        bubbles: true,
        valueRange: [0, maxValue!],
      }
    }
    this.loader = false
   
  }

  loadApiData() {
    let api = this.item.config['list_of_api'] || {}
    if (api && !this.filterService.isEmptyObject(this.filterService.baseQuery)) {

      let obj = { 'api_key': api.api_key }
      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.subs.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          if (res.length == 0) { this.noData = true } else { this.noData = false }
          this.data = this.prepareData(res)
          this.loadedData()
          return this.data
        }, (err: any) => {
          console.log('bubblemapMap Api ' + api.api_key + ' failing')
          this.loader = false
          this.noData = true
          this.data = []
        })
      )
    }
  }

  prepareData (data: any) {
    _.each(data, d => {
      d.locationname = d.locationname.toUpperCase()
    })
    return data
  }

  format(series: any, cities: any) {
    const bySourceByLocation: Record<string, Record<string, any>> = {}
    _.each(series, r => {
      const pos = cities[r.locationname]
      if (!pos) return

      const [city, state] = r.locationname.split(', ')
      const record = _.get(bySourceByLocation, `${r.sourcename}.${r.locationname}`)
      _.set(bySourceByLocation, `${r.sourcename}.${r.locationname}`, {
        label: r.sourcename,
        // @ts-ignore
        location: this.projection(pos),
        series: [...(record?.['series'] || []), { date: r.period, value: r.number_of_bottles }],
        value: (record?.['value'] || 0) + r.number_of_bottles,
        data: _.pick(r, ['data_source_dim_key', 'locationid', 'locationname']),
      })
    })

    return _.flatten(_.map(_.values(bySourceByLocation), v => _.values(v)))
  }

  toggleModal(items: any) {
    if (!items?.length) return

    this.dialog = this.dialogServiceMat.open(ModalComponent, {
      data: { items, colorScale: this.map.colorScale },
    })
    this.dialog.afterClosed().subscribe(this.onModalClose.bind(this))
  }

  onModalClose() {
    this.config.map = _.merge({}, this.config.map,
      { view: { selected: [] } })
  }

  override ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe())
  }
}