<div class="deletePopup" style="font-family: Poppins;border-radius: 10px;">
    <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;border-radius: 10px 10px 0 0;">
        <div class="p-2">
            <span style="font-size: 15px;font-weight: 600;">Delete {{this.paramsData}}</span>
        </div>
        <div class="p-2">
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);transition: none;" >close</mat-icon>
        </div>
    </div>
    <div class="my-2" style="text-align: center;font-weight: 500; font-size: 13px;">
        Are you sure you want to delete this <br> {{this.paramsData}}?
    </div>
    <div class="d-flex justify-content-around align-items-center" style="padding: 15px;">
        <div style="margin: 5px;">
            <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close>Cancel</button>
        </div>
        <div style="margin: 5px;">
            <button type="button" class="btn btn-danger btnDelete" (click)="deleteCommentToClose('1')">Delete {{this.paramsData}}</button>
        </div>
    </div>
</div>