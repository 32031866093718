export default {
  title: 'Territory Map',
  components: [{
    id: 'territory-map',
    view: {
      tooltip: 'territory-map-tooltip',
    },
  }, {
    id: 'territory-map-tooltip',
    data: {
      source: 'service',
    },
    view: {
      template: 'custom',
    },
  }],
}