import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CommonModule } from '@angular/common'
import * as d3 from 'd3'

import { CellInfo } from './cell-info'
import { Comments } from '../comments/comments'
import { MatComponentsModule } from 'src/app/mat-components.module'

const timeFormatter = d3.timeFormat('%a, %d %b %Y')

@Component({
  standalone: true,
  imports: [CellInfo, Comments, CommonModule, MatComponentsModule],
  selector: 'app-status-modal-dpm',
  template: `
    <div>
      <div class="header">
        <div class="date" style="font-family: 'poppins';">{{ timeFormatter(data.data.xKey) }}</div>
        <div class="text-end pt-1">
          <mat-icon [mat-dialog-close]="true">close</mat-icon>
        </div>
      </div>
      <div class="main">
        <app-status-cell-info-dpm class="detail"
            [header]='false'
            [data]="data.data"></app-status-cell-info-dpm>
        <app-status-comments [topic]="commentsTopic"
            [dateFormat]="data.dateFormat"
            [newCommentType]="data.item?.config?.type_of_page?.name"
            [maxMessageLength]='maxCommentLength'></app-status-comments>
      </div>
    </div>
  `,
  styles: [':host {display: block;background: #010103;color: white; border-radius: inherit;  .header {display: flex;flex-direction: row;justify-content: space-between ;padding: 0 10px;height: 36px;background-color: #FFFFFF33; border-radius: 10px 10px 0 0;  .date{margin: 10px 0px;}} .main{display:flex;  .detail{ height: 340px;overflow: auto;width: 340px;margin: 10px 3px;border-right: 1px dashed #727272;}}}']
})
export class Modal {
  constructor (@Inject(MAT_DIALOG_DATA) public data: any) {
    this.commentsTopic = Object.values(this.data.data.details)
    this.commentsTopic = this.commentsTopic[0]
    this.maxCommentLength = parseInt(data.item?.config?.max_comment_length)
  }

  header: boolean = false
  public timeFormatter = timeFormatter
  commentsTopic: any
  maxCommentLength: number
}