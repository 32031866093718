import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Component({
  selector: 'app-pending-patient-therapy-card',
  templateUrl: './pending-patient-therapy-card.component.html',
  styleUrls: ['./pending-patient-therapy-card.component.scss']
})
export class PendingPatientTherapyCardComponent implements OnInit {
  static key = 100110;
  
  @Input('item') item: any;
  @Input("pageKey") pageKey: any;
  
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>();
  @ViewChild('therapyDiv', { static: true }) therapyDiv!: ElementRef;
  @ViewChild("fs") fs!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  
  iconList: any;
  isActive: boolean = false;
  reqSubcription: Subscription[] = [];
  clientKey: any;
  loader = false;
  statusArray:any;
  allData:any; 
  choroplethData:any; 
  heading: any = "Cleopath Chart";

  constructor(
    private router: ActivatedRoute, 
    private filterService: FilterService,
    private navigationService: NavigationService,
    private dataService: DataService
  ) {

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loader = true;
      this.loadPatientJourneyStatus();
    }));
  }
  

  ngOnInit(): void {
    this.loader = true;
    
    this.getStateCodes();
    this.router.params.subscribe((p) => {
      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
      })
    })

    // this.router.params.subscribe((r: any) => {
    //   this.clientKey = r["clientKey"];
    // })

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.therapyDiv?.nativeElement);

    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        this.isFullscreen = true
      } else {
        this.isFullscreen = false
      }
    })
  }

  loadPatientJourneyStatus() {
    this.loadApiData();
  }

  loadApiData() {
    const obj = {
      api_key: 100124,
      report_typ: "D"
    }

    if (this.filterService.isEmptyObject(this.filterService.baseQuery) == false) { 
      this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((res: any) => {
        this.allData = res ? res : [];
        this.allData = _.orderBy(this.allData, ['state_cd', 'account_name'],['asc', 'asc']);
        setTimeout(() => {this.loader = false;}, 10);
      }, (err: any) => {
        this.allData = [];
        setTimeout(() => {this.loader = false;}, 10);
      }))
    }
  }

  getStateCodes() {
    this.dataService.getJson('choroplethChart').subscribe((res: any) => {
      this.choroplethData = res ? res : [];
    }, err => {
      this.choroplethData = [];
    })
  }

  // Kill Component
  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }
}
