import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';;
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-patient-value-getter',
  template: `

  <button mat-raised-button color="primary" [style.background]="this.color" [style.color]="textColor" style="width: 80px;">{{this.valueFormatted}}</button>

  `
})
export class ValueBtnComponent implements ICellRendererAngularComp, OnDestroy {
  
  params: any;
  reqSubcription: Subscription[] = []
  valueFormatted:any;
  color:any;
  textColor = '#fff';

  constructor(
    private dialog: MatDialog,
    public filterService: FilterService,
    private deviceDetectionService: DeviceDetectionService,
    private constantsService: ConstantsService
  ) {}

  agInit(params: ICellRendererParams<any>): void {
    // debugger
    this.params = params

    if(this.params.response) {
      let findIndex = this.params.response.rangeColorDynamic.findIndex((o:any)=> o.color == this.params['color'])
      if(findIndex!=-1 && findIndex>4) {
        this.textColor = '#000'
      }
    }

    this.valueFormatted = this.params.valueFormatted
    this.color = this.params['color']
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

}