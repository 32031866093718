import { Component } from '@angular/core'

@Component({
  standalone: true,
  selector: 'app-filter-button',
  template: `
    <div class="parallelogram"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight">
      <img class="matParallelogram" src="../../../../assets/funnel_filter.svg" alt="filter_icon"/>
    </div>
  `,
  styleUrls: ['./filter-button.scss'],
})
export class FilterButton {
  static key = 100700
}