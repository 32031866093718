import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';;
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-patients-flags',
  template: `

  <span *ngIf="!this.params.value || this.params.value=='N'">
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33301 2.33301V12.333" stroke="#8A98AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6663 9.65217C5.99967 12.0145 4.42467 7.28083 1.33301 9.65217C1.33301 7.21843 1.33301 4.70366 1.33301 2.26992C4.25599 0.0279564 6.33301 4.95437 10.6663 2.26991C10.6663 4.72446 10.6663 7.19763 10.6663 9.65217Z" stroke="#8A98AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>

  <span *ngIf="this.params.value=='Y'">
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33301 2.33301V12.333" stroke="#3AC97C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6663 9.65217C5.99967 12.0145 4.42467 7.28083 1.33301 9.65217C1.33301 7.21843 1.33301 4.70366 1.33301 2.26992C4.25599 0.0279564 6.33301 4.95437 10.6663 2.26991C10.6663 4.72446 10.6663 7.19763 10.6663 9.65217Z" fill="#3AC97C" stroke="#3AC97C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>


  `
})
export class PatientsFlagsComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams | any;

  constructor(
    private dialog: MatDialog,
    public filterService: FilterService
  ) {}
  
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams<any>): void {
    this.params = params;
    // debugger
  }

}