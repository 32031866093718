import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import { FieldType } from "@ngx-formly/core";
import moment from "moment";
import { FilterService } from "src/app/services/filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";
import { AppCustomDatepickerHeaderComponent } from "./app-custom-datepicker-header";
import { Subscription, debounceTime } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { NavigationService } from "src/app/services/navigation.service";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-timecycle-with-range",
  templateUrl: "./timecycle-with-range.component.html",
  styleUrls: ["./timecycle-with-range.component.scss"],
})
export class TimecycleWithRangeComponent extends FieldType implements OnInit, OnDestroy {

  dynamicSelect= new FormControl();
  opt: any
  valueProp = "_id";
  labelProp = "selectedDate";
  labelPropForQ = "quarter,year";
  label:any;
  fromLabel:any;
  toLabel:any;
  currentField:any;
  parentKey:any="";
  placeholder:any
  dBDefaultData:any

  quarterFromDefault:any

  quarterFromYear:any;
  quarterFromMonth:any;
  selectionMethod:any;

  onLoadDefault: any;
  onLoadDefaultYear: any;
  onLoadDefaultYearQuarter: any;

  yearFromDefault=new Date().getFullYear()
  currentYear=new Date().getFullYear()
  currentMonth = new Date().getMonth();

  yearFrom!:number;
  yearTo!:number;
  showQuarter:boolean=true;
  showQuarterTo:boolean=true;
  year10yearFrom!:number;
  year10yearTo!:number;
  totalYear:any = []


  selectedFromOption:any;
  selectedToOption:any;
  selectedRange:any

  dashboardKey:any

  reqSubcription: Subscription[] = [];
  showBy:any;
  // ----------------------------------

  selected!: Date | null;

  minFromDate!: Date;
  maxFromDate!: Date | null;
  minToDate!: Date | null;
  maxToDate!: Date;

  dbFromDate!:Date | any;

  constructor(
    private filterService:FilterService,
    private userActivityService:UserActivityService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private navigationService: NavigationService,
    private dashboardService: DashboardService
  ) {
    super()
  }

  exampleHeader = AppCustomDatepickerHeaderComponent
  dataSourceUrl:any
  defaultModel:any;
  selectedOption:any

  selectedType:any;

  selectedFromdate = new FormControl(new Date());
  selectedTodate = new FormControl(new Date());
  dbEndDate!:Date | any;
  brandStartDate!:Date | any;
  // Define a variable to hold the subscription
  private parentControlSubscription: Subscription | undefined;

  // From date selection Weekly and Daily
  fromDateChangeDay(type: string, event: MatDatepickerInputEvent<Date>) {
    this.filterService.filterApplyBtn();
    this.minToDate = event.value;
    this.selectedFromdate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'D': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

  }
  // From date selection Weekly and Daily
  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.filterService.filterApplyBtn();

    this.minToDate = event.value;
    this.selectedFromdate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'W': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

  }

  // To date selection Weekly and Daily
  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {

    this.filterService.filterApplyBtn();

    this.maxFromDate = event.value;
    this.selectedTodate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'W': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);
  }
  // To date selection Weekly and Daily
  toDateChangeDay(type: string, event: MatDatepickerInputEvent<Date>) {

    this.filterService.filterApplyBtn();
    
    this.maxFromDate = event.value;
    this.selectedTodate.patchValue(event.value);

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'D': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);
  }

  myFilter:any = (d: Date): boolean => {
     
    const day = d?.getDay();

    const birthday = new Date(this.dbFromDate);
    const day1 = birthday.getDay();

    const dayForEnd = moment(this.dbEndDate).day();
    // Prevent Saturday and Sunday from being selected.
    return day == day1;
  }

  myFilterEnd:any = (d: Date): boolean => {
    const day = d?.getDay();

    const birthday = new Date(this.dbEndDate);
    const day1 = birthday.getDay();

    const dayForEnd = moment(this.dbEndDate).day();
    // Prevent Saturday and Sunday from being selected.
    return day == day1;
  }

  // Enable Only Friday Dates
  filterFridayDates(date: Date | null): boolean {
    if (!date) {
      return false;
    }
    // Check if the day of the week is Friday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    return date.getDay() === 5;
  }

  // Month selection From
  monthFromSelected(event:any, dp:any, input:any) {
    dp.close();
    this.minToDate = event;
    this.selectedFromdate.patchValue(moment(event).startOf('month').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    this.filterService.filterApplyBtn();
    
    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)


    // User State
    let ab = { 'M': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

  }

  // Month Selection To
  monthToSelected(event:any, dp:any, input:any) {
    dp.close();
    this.maxFromDate = event;
    this.selectedTodate.patchValue(moment(event).endOf('month').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    this.filterService.filterApplyBtn();

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'M': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

  }

  // From Year Selection
  yearFromSelected(event:any, dp:any, input:any) {
    dp.close();
    this.minToDate = event;
    this.selectedFromdate.patchValue(moment(event).startOf('year').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    this.filterService.filterApplyBtn();

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'Y': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);
  }

  //  To Year Selection
  yearToSelected(event:any, dp:any, input:any) {
    dp.close();
    this.maxFromDate = event;
    this.selectedTodate.patchValue(moment(event).endOf('year').toDate());

    let ApplyDate = {
      "from_date" : this.selectedFromdate.value,
      "to_date" : this.selectedTodate.value,
    }

    this.filterService.filterApplyBtn();

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);
    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    // User State
    let ab = { 'Y': {"from_date" : this.selectedFromdate.value, "to_date" : this.selectedTodate.value}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })

    // Reset selected For Quarter Selection
    const serializedData:any = JSON.stringify(ApplyDate);
    sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);
  }

  Option: any[] = [{selectedDate : ''}];


  // For Quarter Selection
  fromOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];

  toOption: any[] = [
    {value:'Q1',months:['Jan','Feb','Mar'],"monthFound": '1,2,3'},
    {value:'Q2',months:['Apr','May','Jun'],"monthFound": '4,5,6'},
    {value:'Q3',months:['Jul','Aug','Sep'],"monthFound": '7,8,9'},
    {value:'Q4',months:['Oct','Nov','Dec'],"monthFound": '10,11,12'}
  ];


  startFrom:any;
  cardName:any;
  queryFilterData:any;
  isRestriction:any;
  isRecordDetail:any;
  tabId:any = 0;

  ngOnInit(): void {

    // dashboard key based on the router for brand start date
    

    this.route.params.subscribe((p: any) => {
      this.route.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
  
        this.dashboardKey = decryptedParams["ds_key"];
        this.tabId = params["tab_id"];
        this.cardName = p["cardName"]
        
        if(this.cardName=='comparison') {
          let endDate:any = sessionStorage.getItem("dashboardControl");
          let fndLookBackPeriod:any = JSON.parse(endDate)
          this.dashboardKey = fndLookBackPeriod?.work_key;
        }
      
        if(params?.['record']=='yes') {
          if(sessionStorage.getItem("filterData")) {
            let filter:any = sessionStorage.getItem("filterData")
            this.queryFilterData = JSON.parse(filter) || {}; 
          } else {
            this.queryFilterData = '';
          }
        } else {
          this.queryFilterData = '';
        }
      });

      
    });


    this.reqSubcription.push(this.filterService.kpiBreakDownSelectedType.subscribe((res:any)=> {
      this.selectedType = res
      
      debugger
      if(this.model && this.model[this.currentField.parentKey] && this.defaultModel) {
        this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
      }
    }))

    // show by 
    this.reqSubcription.push(
      this.filterService.showByChangeFilter.subscribe((res:any)=> {
        debugger
        let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> 'report_typ' == res.parentKey);
        if(!fndChild) {
          this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
        }
      })
    )

    this.opt = this.field.templateOptions || {};
    this.placeholder = this.opt.placeHolder
    this.label = this.opt.label
    this.fromLabel = this.opt.fromLabel
    this.toLabel = this.opt.toLabel
    this.currentField = this.field
    this.dataSourceUrl = this.opt.dataSourceUrl

    this.quarterFromYear = this.opt.quarterYear
    this.quarterFromMonth = this.opt.quarterMonth
    this.selectionMethod = this.opt.selectionMethod

    this.onLoadDefault = this.opt.onLoadDefault
    this.onLoadDefaultYear = this.opt.onLoadDefaultYear
    this.onLoadDefaultYearQuarter = this.opt.onLoadDefaultYearQuarter
    this.isRestriction = this.opt.isRestriction
    this.showBy = this.opt.showBy

    if(this.opt.selectedPath=='C') {
      this.selectedType=this.opt.time_cycle
      this.filterService.report_type = this.opt.time_cycle
    }
    this.startFrom = this.opt.startFrom || 'brand'
    this.isRecordDetail = this.opt.isRecordDetail || ''
    //Time
     


    this.reqSubcription.push(this.filterService.lastRefreshedDate.subscribe((brand:any)=> {
    if(this.startFrom!='db') {
      // Get Brand based Date
        if(brand) {
          this.reqSubcription.push(this.dataService.getBrandStartDate(brand).subscribe((data:any)=> {
            if(data!=null) {
              this.brandStartDate = moment(data[0].launch_date);
            }
          }));
        }
      }
    }))

    // Filter Reset Method using Subject
    this.filterService.formReset.subscribe((res: any) => {

      if(this.selectedType!='Q') {

        if(this.selectedType=='W' || this.selectedType=='D') {
          if(this.selectedType=='D') {
            this.selectedFromdate.patchValue(this.dbFromDate);
            this.selectedTodate.patchValue(this.endFriday());
          } else {
            this.selectedFromdate.patchValue(this.selectedStartFriday(this.dbFromDate));
            this.selectedTodate.patchValue(this.endFriday());
          }
        } else if(this.selectedType=='M') {
          this.selectedFromdate.patchValue(moment(this.dbFromDate).startOf('month').toDate());
          this.selectedTodate.patchValue(moment().endOf('month').toDate());
        } else if(this.selectedType=='Y') {
          this.selectedFromdate.patchValue(moment(this.dbFromDate).startOf('year').toDate());
          this.selectedTodate.patchValue(moment().endOf('year').toDate());
        }

        let ApplyDate = {
          "from_date" : this.selectedFromdate.value,
          "to_date" : this.selectedTodate.value,
        }

        if(this.currentField.parentKey == "") {
          // Set Value for Formly form
          this.field.formControl?.setValue(ApplyDate);
        }

        let ab = { [this.key as string]: ApplyDate }
        this.userActivityService.setData({ "selectedFilters": ab })
        this.filterService.selectedItem['timeCycle'][this.key as string] = ApplyDate

        // set Badges
        this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

        sessionStorage.removeItem('timeWithoutQuarterFromAndToSelected');

      } else {

        // Quarter Reset
        let ApplyDate = {
          "from_date" : moment(this.selectedFromOption.from_date).toDate(),
          "to_date" : moment(this.selectedToOption.to_date).toDate(),
        }

        if(this.currentField.parentKey == "") {
          // Set Value for Formly form
          this.field.formControl?.setValue(ApplyDate);
        }

        let ab = { [this.key as string]: ApplyDate }
        this.userActivityService.setData({ "selectedFilters": ab })

        sessionStorage.removeItem('timeQuarterFromAndToSelected');
        // set Badges
        this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)
      }
    })

    if (this.currentField?.parentKey != "") {
      (this.field.hooks as any).afterViewInit = (f: any) => {
        const parentControl = this.form.get(this.currentField.parentKey)//this.opt.parent_key);

        if (this.parentControlSubscription) {
          this.parentControlSubscription.unsubscribe(); // Unsubscribe if there's an existing subscription
        }

        this.parentControlSubscription = parentControl?.valueChanges.pipe(debounceTime(500)).subscribe((val: any) => {
          let selectedOption = this.model[this.key as string];
          this.defaultModel = val;

          let key = this.key as string;
          let fndChild:any = this.filterService.globalFilterFields.find((res:any)=> key == res.parentKey)
          if(fndChild) {
            this.model[fndChild.key] = ''
          }

          if(this.fndParent()) {
            if (this.dataSourceUrl == "") {
              this.opt.options = val
              let v = val.find((d: any) => d[this.valueProp] == selectedOption[this.valueProp])
              this.field.formControl?.setValue(v)
            } else {
              debugger
              this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
            }
          }
        })
        this.opt.options = []
      }
    } else {
      this.loadApiData(this.opt.method || "POST", this.dataSourceUrl, this.opt.parameter, this.model)
    }
  }

  isObjectNotArray(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  fndParent() {
    let data:any;
    if(this.model[this.currentField.parentKey].length) {
      data = this.model[this.currentField.parentKey].find((r:any)=> r[this.currentField.parentKey])
    } else if(this.isObjectNotArray(this.model[this.currentField.parentKey])) {
      data = this.model[this.currentField.parentKey][this.currentField.parentKey]
    } else if (this.model[this.currentField.parentKey]) {
    }

    return data
  }

  onloadCurrentQuarter:any; 

  loadApiData(method:string, url:string,key:string,data:any) {
    // debugger
    if(this.isRecordDetail=='yes') {
      let api:any = {api_key: 10019709};
      let quarterInfo = this.getQuarterInfo(new Date());
      
      this.reqSubcription.push(this.filterService.executeQuery(Object.assign({}, api, data['brand_key'])).subscribe((res:any)=> {
        if(res && res[0]) {
          this.onloadCurrentQuarter = res[0];
        } else {
          
          this.onloadCurrentQuarter = {qtr_start_dt: quarterInfo.startDate, qtr_end_dt: quarterInfo.endDate }
        }

        this.loadData(method,url,key,data)
      }, err => {
        this.onloadCurrentQuarter = {qtr_start_dt: quarterInfo.startDate, qtr_end_dt: quarterInfo.endDate }
        this.loadData(method,url,key,data);
      }))
    } else {
      this.loadData(method,url,key,data);
    }
    
  }

  // Load APi
  loadData(method:string, url:string,key:string,data:any) {

    // If SelectedType
    if(this.selectedType) {
      this.reqSubcription.push(this.filterService.loadData(method,url,key,data, this.dashboardService.dashboardKey, this.selectedType, this.dashboardService.getPageKey[Number(this.tabId)]).subscribe((data:any)=>{

         
        if(data!=null) {

          let dbFrom = moment(data[0]?.from_dt);
          let dbEnd = moment(data[0]?.to_dt);

          if(this.startFrom!='db') {
            dbFrom = this.brandStartDate
          }
          // debugger

          if(this.isRecordDetail=='yes') {
            // User state
            let userStateData: any = this.userActivityService.getData();
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'quarter_select');

            if(findData) {
              dbFrom = moment(findData.value?.from_date);
              dbEnd = moment(findData.value?.to_date);
            } else {
              dbFrom = moment(this.onloadCurrentQuarter?.qtr_start_dt);
              dbEnd = moment(this.onloadCurrentQuarter?.qtr_end_dt);
            }
          }

          this.dbFromDate = moment(dbFrom).toDate();
          this.dbEndDate = moment(dbEnd).toDate();

          this.minFromDate = moment(dbFrom).toDate();
          this.minToDate = moment(dbFrom).toDate();
          this.maxFromDate = moment().toDate();
          this.maxToDate = moment().toDate();

          // Selected Type Day and Week
          if(this.selectedType=='D') {

              let s = new FormControl()
              let y = new FormControl()
              s.patchValue(this.dbFromDate);
              y.patchValue(this.dbEndDate)

              // defulat data
              let ApplyDates = {
                "from_date" : s.value,
                "to_date" : y.value,
              }
              // Defalut Load for reset default
              sessionStorage.setItem('timeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

              // UserState
              let userStateData: any = this.userActivityService.getDataTimeCycle()
               
              let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
              let findData: any = updatesObj.find((o: any) => o.key == 'D')
              if(findData) {
                this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
                this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
              } else {
                this.selectedFromdate.patchValue(this.dbFromDate);
                this.selectedTodate.patchValue(this.dbEndDate);
              }

              let ApplyDate = {
                "from_date" : this.selectedFromdate.value,
                "to_date" : this.selectedTodate.value,
              }

              // Reset selected For Quarter Selection
              const serializedData:any = JSON.stringify(ApplyDate);
              sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

          }else if(this.selectedType=='W') {

              let start = new FormControl()
              let end = new FormControl()

              start.patchValue(this.selectedStartFriday(dbFrom));
              // this.selectedendFriday(this.dbEndDate)
              end.patchValue(moment(this.dbEndDate).toDate())

                 
              // defulat data
              let ApplyDates = {
                "from_date" : start.value,
                "to_date" : end.value,
              }
              // Defalut Load for reset default
              sessionStorage.setItem('timeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

              // UserState
              let userStateData: any = this.userActivityService.getDataTimeCycle()
               
              let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
              let findData: any = updatesObj.find((o: any) => o.key == 'W')
              if(findData) {
                this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
                this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
              } else {
                 
                this.selectedFromdate.patchValue(this.selectedStartFriday(dbFrom));
                this.selectedTodate.patchValue(moment(this.dbEndDate).toDate())
              }

              // ------------------------------------------------
            let singleTimeCycleQuarter:any = sessionStorage.getItem('singleTimeCycleQuarter');

            if(singleTimeCycleQuarter) {
              singleTimeCycleQuarter = JSON.parse(singleTimeCycleQuarter)

              this.selectedFromdate.patchValue(this.selectedStartFriday(dbFrom));
              this.selectedTodate.patchValue(moment(singleTimeCycleQuarter?.to_date).toDate());
            }
            // -----------------------------------------------------------


              let ApplyDate = {
                "from_date" : this.selectedFromdate.value,
                "to_date" : this.selectedTodate.value,
              }

              // Reset selected For Quarter Selection
              const serializedData:any = JSON.stringify(ApplyDate);
              sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);


          // Selected Type Month
          } else if(this.selectedType=='M') {

            let start = new FormControl()
            let end = new FormControl()

            start.patchValue(moment(dbFrom).startOf('month').toDate());
            end.patchValue(moment(dbEnd).endOf('month').toDate())

            // defulat data
            let ApplyDates = {
              "from_date" : start.value,
              "to_date" : end.value,
            }

            // Defalut Load for reset default
            sessionStorage.setItem('timeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

            // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycle()
             
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'M')
            if(findData) {
              this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
              this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
            } else {

              this.selectedFromdate.patchValue(moment(dbFrom).startOf('month').toDate());
              this.selectedTodate.patchValue(moment(dbEnd).endOf('month').toDate());
            }

            // ------------------------------------------------
            let singleTimeCycleQuarter:any = sessionStorage.getItem('singleTimeCycleQuarter');

            if(singleTimeCycleQuarter) {
              singleTimeCycleQuarter = JSON.parse(singleTimeCycleQuarter)

              this.selectedFromdate.patchValue(moment(dbFrom).startOf('month').toDate());
              this.selectedTodate.patchValue(moment(singleTimeCycleQuarter?.to_date).toDate());
            }
            // -----------------------------------------------------------

            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }

            // Reset selected For Quarter Selection
            const serializedData:any = JSON.stringify(ApplyDate);
            sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

          // Selected Type Year
          } else if(this.selectedType=='Y') {

            let start = new FormControl()
            let end = new FormControl()

            start.patchValue(moment(dbFrom).startOf('year').toDate());
            end.patchValue(moment(dbEnd).endOf('year').toDate())

            // defulat data
            let ApplyDates = {
              "from_date" : start.value,
              "to_date" : end.value,
            }
            // Defalut Load for reset default
            sessionStorage.setItem('timeWithoutQuarterFromAndToDefault', JSON.stringify(ApplyDates));

            // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycle()
             
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'Y')
            if(findData) {
              this.selectedFromdate.patchValue(moment(findData.value?.from_date).toDate());
              this.selectedTodate.patchValue(moment(findData.value?.to_date).toDate());
            } else {
              this.selectedFromdate.patchValue(moment(dbFrom).startOf('year').toDate());
              this.selectedTodate.patchValue(moment(dbEnd).endOf('year').toDate());
            }

            // -----------------------------------------------------------------------------------------------
            // Only come from kpi to control chart (only To Date should affect)
            let singleTimeCycleQuarter:any = sessionStorage.getItem('singleTimeCycleQuarter');

            if(singleTimeCycleQuarter) {
              singleTimeCycleQuarter = JSON.parse(singleTimeCycleQuarter)

              this.selectedFromdate.patchValue(moment(dbFrom).startOf('year').toDate());
              this.selectedTodate.patchValue(moment(singleTimeCycleQuarter?.to_date).toDate());
            }
            // --------------------------------------------------------------------------------

            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }

            // Reset selected For Quarter Selection
            const serializedData:any = JSON.stringify(ApplyDate);
            sessionStorage.setItem('timeWithoutQuarterFromAndToSelected', serializedData);

          // Selected Type Quarter
          } else if (this.selectedType=='Q') {
            this.yearFromDefault = moment(dbFrom).year();
            let quarterFromDefault = moment(dbFrom).month()
            // 2 Quarter convertion based on the month
            if(quarterFromDefault<=2) {
              this.quarterFromDefault = 'Q1'
            } else if (quarterFromDefault<=5) {
              this.quarterFromDefault = 'Q2'
            } else if (quarterFromDefault<=8) {
              this.quarterFromDefault = 'Q3'
            } else if (quarterFromDefault<=11) {
              this.quarterFromDefault = 'Q4'
            }

            let year = moment(dbEnd).year()
            // let month = moment(dbEnd).month()

            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const currentMonthIndex = moment(dbEnd).month();
            let month = monthNames[currentMonthIndex];

            // From DropDown and To DropDown
            this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFromDefault)]
            this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFromDefault)]
            this.toOption= [...this.addingQuarterwithYear(this.toOption, year)]
            this.toOption= [...this.dateFormatQuarter(this.toOption,year)]

            // Selected From and To Quarter
            this.yearFrom = this.yearFromDefault
            this.filterService.yearDefault = this.yearFromDefault

            this.yearTo = this.currentYear
            this.filterService.yearToDefault = this.currentYear

            // let selectedFromOption:any;
            // let selectedToOption:any;

            let selectedFromOption:any = this.fromOption.find((res: any) => res.value == this.quarterFromDefault);
            let selectedToOption:any = this.toOption.find((res:any)=> res.months?.toString().includes(month.toString()));


            // Reset Default For Quarter Selection
            let SetData = {"from_date" : selectedFromOption['quarter,year'],"to_date" : selectedToOption['quarter,year']}
            const serializedData:any = JSON.stringify(SetData);
            sessionStorage.setItem('timeQuarterFromAndToDefault', serializedData);


            let fieldKey:any = this.key as string

            // UserState
            let userStateData: any = this.userActivityService.getDataTimeCycle()
           
            let updatesObj: any = Object.entries(userStateData.selectedFilters).map(([key, value]) => ({ key, value }));
            let findData: any = updatesObj.find((o: any) => o.key == 'Q')

            if(findData) {
              // From
              if(findData.value?.from) {
                this.yearFrom = findData.value?.from.year
                this.filterService.yearDefault = this.yearFrom

                this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
                this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
                this.selectedFromOption = this.fromOption.find((res: any) => res.value == findData.value?.from.value);
              }

              // To
              if (findData.value?.to) {
                this.yearTo = findData.value?.to.year
                this.filterService.yearToDefault = this.yearTo

                this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
                this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
                this.selectedToOption = this.toOption.find((res:any)=> res.value == findData.value?.to.value);
              }
            } else {
              this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.quarterFromDefault);
              this.selectedToOption = this.toOption.find((res:any)=> res.months?.toString().includes(month.toString()));
            }

            // -----------------------------------------------------------------------------------------------
            // Only come from kpi to control chart (only To Date should affect)
            let singleTimeCycleQuarter:any = sessionStorage.getItem('singleTimeCycleQuarter');

            if(singleTimeCycleQuarter) {
              singleTimeCycleQuarter = JSON.parse(singleTimeCycleQuarter)

              // From
              this.yearFrom = this.yearFromDefault
              this.filterService.yearDefault = this.yearFromDefault

              this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
              this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
              this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.quarterFromDefault);

               // To
               if (singleTimeCycleQuarter?.to) {
                this.yearTo = singleTimeCycleQuarter?.to?.year
                this.filterService.yearToDefault = this.yearTo

                this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
                this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
                this.selectedToOption = this.toOption.find((res:any)=> res.value == singleTimeCycleQuarter?.to?.value);
              }

            }
            // -----------------------------------------------------------------

            this.cdr.detectChanges();

            // When onclose the filter
            let selected:any = {
              from : this.selectedFromOption,
              to : this.selectedToOption
            }
            sessionStorage.setItem('TimeCycleQuarter', JSON.stringify(selected))

            // From Quarter Default between gap should be disabled
            this.fromOption = this.defaultfromDisabled(this.fromOption)
            // to Quarter Default between gap should be disabled
            this.toOption = this.defaultToDisabled(this.toOption)

            // Reset selected For Quarter Selection
            let resetData = {
              "from_date" : this.selectedFromOption['quarter,year'],
              "to_date" : this.selectedToOption['quarter,year'],
            }
            const serializedData1:any = JSON.stringify(resetData);
            sessionStorage.setItem('timeQuarterFromAndToSelected', serializedData1);

          }
          this.cdr.detectChanges();

          // If Not in Quarter
          if(this.selectedType!='Q') {

            if(this.queryFilterData instanceof Array && this.queryFilterData?.length) {
              let selectedDate = this.queryFilterData.find((o:any)=> o.filterKey=='date');
                
              if(selectedDate) {
                let newDate=selectedDate[selectedDate.key].replace(/\s/g, '');
                newDate = moment(newDate).format('MM-DD-YYYY')
                this.selectedFromdate.patchValue(moment(newDate,'MM-DD-YYYY').toDate());
                this.selectedTodate.patchValue(moment(newDate,'MM-DD-YYYY').toDate());
              }
            }

            let ApplyDate = {
              "from_date" : this.selectedFromdate.value,
              "to_date" : this.selectedTodate.value,
            }
            // console.log(dbFrom, 'date')

            this.minFromDate = this.startFriday(dbFrom);
            this.maxFromDate = this.selectedTodate.value;
            this.minToDate = this.selectedFromdate.value;
            this.maxToDate = this.dbEndDate;

            // Set Value for Formly form
            this.field.formControl?.setValue(ApplyDate);
            // set Badges
            this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromdate.value).format('MM/DD/YY'),moment(this.selectedTodate.value).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

          // If In Quarter
          } else {

            // Apply Date for Data base through the formly form
            let ApplyDate = {
              "to_date" : moment(this.selectedToOption.to_date).toDate(),
              "from_date" : moment(this.selectedFromOption.from_date).toDate(),
            }
            // Set Value for Formly form
            this.field.formControl?.setValue(ApplyDate);
            // set Badges
            this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

          }
          this.cdr.detectChanges();
        }
      }))
    }
  }

  getQuarterInfo(date: Date): { quarter: number, startDate: Date, endDate: Date } {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based

    let quarter: number;
    let startDate: Date;
    let endDate: Date;

    if (1 <= month && month <= 3) {
      quarter = 1;
      startDate = new Date(year, 0, 1);  // January 1
      endDate = new Date(year, 2, 31);   // March 31
    } else if (4 <= month && month <= 6) {
      quarter = 2;
      startDate = new Date(year, 3, 1);  // April 1
      endDate = new Date(year, 5, 30);   // June 30
    } else if (7 <= month && month <= 9) {
      quarter = 3;
      startDate = new Date(year, 6, 1);  // July 1
      endDate = new Date(year, 8, 30);   // September 30
    } else {
      quarter = 4;
      startDate = new Date(year, 9, 1);  // October 1
      endDate = new Date(year, 11, 31);  // December 31
    }

    return { quarter, startDate, endDate };
  }

  defaultfromDisabled(data:any) {
    let monFrom = false
    data.forEach((res:any)=> {
        if(res.value == this.quarterFromDefault) {
          monFrom = true
        } else {
          if(!monFrom) {
            Object.assign(res,
              {"disabled": true})
          }
        }
      })
      monFrom = false
      return data
  }

  defaultToDisabled(data:any) {
    let mon = false
    data.forEach((res:any)=> {
      if(res.monthFound.includes(this.currentMonth.toString())) {
        mon = true
      } else {
        if(mon) {
          Object.assign(res,
            {"disabled": true})
        }
      }
    })
    mon = false
    return data
  }

  // endFriday() {
  //   const currentDates = moment();

  //   let day = currentDates.day() - moment(this.dbEndDate).day();

  //   if (day <= 0) {
  //     day += 7; // If today is Friday or later, add 7 days
  //   }

  //   let nextFridayFrom:any = currentDates.clone().add(day, 'days').toDate();

  //   return nextFridayFrom
  // }

  // selectedEndFriday() {
  //   const currentDate = moment();
  //   // Define the target day (0 for Sunday, 1 for Monday, 2 for Tuesday, and so on)
  //   const targetDay = moment(this.dbEndDate).day(); // For example, 0 represents Sunday

  //   // Calculate the difference in days between the current day and the target day
  //   const daysUntilTargetDay = (targetDay - currentDate.day() + 7) % 7;

  //   // Add the calculated days to the current date to find the next occurrence of the target day
  //   const nextOccurrence = currentDate.add(daysUntilTargetDay, 'days');

  //   return moment(nextOccurrence).toDate()
  // }

  endFriday() {
    const currentDate = moment();
    // Define the target day (0 for Sunday, 1 for Monday, 2 for Tuesday, and so on)
    const targetDay = moment(this.dbEndDate).day(); // For example, 0 represents Sunday

    // Calculate the difference in days between the current day and the target day
    const daysUntilTargetDay = (targetDay - currentDate.day() + 7) % 7;

    // Add the calculated days to the current date to find the next occurrence of the target day
    // const nextOccurrence = currentDate.add(daysUntilTargetDay, 'days');
    let nextOccurrence:any = currentDate.clone().add(daysUntilTargetDay, 'days').toDate();
    // let nextFridayFrom:any = currentDates.toDate();


    return nextOccurrence
    // return moment().toDate()
  }



  selectedStartFriday(dbFrom: any) {

    const currentDates = moment(dbFrom);

    let day = currentDates.day() - moment(this.dbFromDate).day();

    if (day <= 0) {
      day += 7; // If today is Friday or later, add 7 days
    }

    // let nextFridayFrom:any = currentDates.clone().subtract(day, 'days').toDate();
    let nextFridayFrom:any = currentDates.toDate();

    return nextFridayFrom;
  }

  startFriday(dbFrom:any) {

    const currentDates = moment(dbFrom);

    // current Week Selection From date
    let daysUntilFridayFrom: any = 7 + currentDates.day(); // Calculate days until Friday

    if (daysUntilFridayFrom <= 0) {
      daysUntilFridayFrom += 7; // If today is Friday or later, add 7 days
    }
    // let nextFridayFrom:any = currentDates.clone().subtract(daysUntilFridayFrom, 'days').toDate();
    let nextFridayFrom:any = currentDates.toDate();
    // this.minFromDate = nextFridayFrom;
    // this.minToDate = nextFridayFrom;

    return nextFridayFrom
  }

  OptionLoad() {
    let array:any = [{'selectedDate':''}]
    // array[0]['selectedDate'] = moment(this.selectedFromDate).format('MM/DD/YYYY') + ' - ' +  moment(this.selectedToDate).format('MM/DD/YYYY')
    return array
  }

  defaultSelectedFrom:any;
  defaultSelectedTo:any;

  // From Year Selection
  changeYear(year:any) {
     
    this.yearFrom=year || this.yearFromDefault;
    this.fromOption = [...this.changeYearWithQuarterFrom(this.fromOption)]
    this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
    this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
    this.selectedFromOption = this.fromOption.find((res: any) => res.value == this.selectedFromOption.value)
  }

  changeShowQuarter(){
    this.showQuarter=!this.showQuarter
    if (!this.showQuarter)
      this.year10yearFrom=this.yearFrom?12*Math.floor(this.yearFrom/12):12*Math.floor(this.yearFromDefault/12)
  }

  // From Year Selection
  changeYearTo(year:any) {
     
    this.yearTo=year || this.currentYear;
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
    this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
    this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
    this.selectedToOption = this.toOption.find((res:any)=> res.value==this.selectedToOption.value)
  }

  changeShowQuarterTo(){
    this.showQuarterTo=!this.showQuarterTo
    if (!this.showQuarterTo)
      this.year10yearTo=this.yearTo?12*Math.floor(this.yearTo/12):12*Math.floor(this.currentYear/12)
  }

  applyFilter(ctrl:any) {

    

    let selected:any = {
      from : this.selectedFromOption,
      to : this.selectedToOption
    }
    sessionStorage.setItem('TimeCycleQuarter', JSON.stringify(selected))

    let ApplyDate = {
      "to_date" : moment(this.selectedToOption.to_date).toDate(),
      "from_date" : moment(this.selectedFromOption.from_date).toDate(),
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);

    // User State
    let ab = { 'Q': {"from" : this.selectedFromOption, "to" : this.selectedToOption}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })


    // Reset selected For Quarter Selection
    let SetData = {
      "from_date" : this.selectedFromOption['quarter,year'],
      "to_date" : this.selectedToOption['quarter,year'],
    }
    const serializedData:any = JSON.stringify(SetData);
    sessionStorage.setItem('timeQuarterFromAndToSelected', serializedData);

    // set Badges
    this.filterService.setBadgeValue(this.key as string,moment(this.selectedFromOption.from_date).format('MM/DD/YY'),moment(this.selectedToOption.to_date).format('MM/DD/YY'),ApplyDate,"timeCycleSelection",this.showBy,'',this.selectedType)

    ctrl.close()
  }

  applyToFilter(ctrl:any) {

    
    sessionStorage.removeItem('singleTimeCycleQuarter');

    let selected:any = {
      from : this.selectedFromOption,
      to : this.selectedToOption
    }
    sessionStorage.setItem('TimeCycleQuarter', JSON.stringify(selected))

    let ApplyDate = {
      "from_date" : moment(this.selectedFromOption.from_date).toDate(),
      "to_date" : moment(this.selectedToOption.to_date).toDate(),
    }

    // Set Value for Formly form
    this.field.formControl?.setValue(ApplyDate);

    // User State
    let ab = { 'Q': {"from" : this.selectedFromOption, "to" : this.selectedToOption}}
    this.userActivityService.setDataTimeCycle({ "selectedFilters": ab })


    // Reset selected For Quarter Selection
    let SetData = {
      "from_date" : this.selectedFromOption['quarter,year'],
      "to_date" : this.selectedToOption['quarter,year'],
    }
    const serializedData:any = JSON.stringify(SetData);
    sessionStorage.setItem('timeQuarterFromAndToSelected', serializedData);

    // this.filterService.selectedItem['timeCycle'][this.key as string] = ApplyDate

    // set Badges
    this.filterService.setBadgeValue(
      this.key as string,
      moment(this.selectedFromOption.from_date).format('MM/DD/YY'),
      moment(this.selectedToOption.to_date).format('MM/DD/YY'),
      ApplyDate,
      "timeCycleSelection",
      this.showBy,
      '',
      this.selectedType
    )

    ctrl.close()
  }



  changeYearWithQuarterFrom(data:any) {

    // 2023 == 2023
    if(this.yearFromDefault < this.yearFrom) {
      if(this.yearFrom == this.selectedToOption.year) {
        let compWithTo = false;
        data.forEach((res:any)=> {
          if(res.value == this.selectedToOption.value) {
            Object.assign(res,{"disabled": false})
            compWithTo = true;
          } else {
            if(compWithTo) {
              Object.assign(res,{"disabled": true}) 
            } else {
              Object.assign(res,{"disabled": false})
            }
          }
        })
      } else {
        if(this.yearFrom > this.selectedToOption.year) {
          data.forEach((res:any)=> {
            Object.assign(res, {"disabled": true})
          })
        } else {
          data.forEach((res:any)=> {
            Object.assign(res, {"disabled": false})
          })
        }
      }    
    } else if(this.yearFromDefault == this.yearFrom) {
      if(this.yearFrom == this.selectedToOption.year) {
        let compWithTo = false;
        let compWith = false;
        data.forEach((res:any)=> {
          if(res.value == this.quarterFromDefault) {
            Object.assign(res,{"disabled": false})
            compWithTo = true;
          } else {

            if(compWithTo) {
              if(res.value == this.selectedToOption.value) {
                Object.assign(res,{"disabled": false})
                compWith = true;
              } else {
                if(compWith) {
                  Object.assign(res,{"disabled": true})
                } else {
                  Object.assign(res,{"disabled": false})
                }
              }
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
          
        })
      } else {
        let compWithTo = false;
        data.forEach((res:any)=> {
          if(res.value == this.selectedToOption.value) {
            Object.assign(res,{"disabled": false})
            compWithTo = true;
          } else {
            if(compWithTo) {
              Object.assign(res,{"disabled": false}) 
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
        })
      } 


    // } else if(this.yearFromDefault == this.yearFrom) {

    }
    return data
  }

  // year quarter change
  changeYearWithQuarterTo(data:any) {
    
    let yearToDefault = new Date().getFullYear();
    // let monthToDefault = new Date().getMonth();
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    let monthToDefault = monthNames[currentMonthIndex];

    //2023 >= 2023
    if(this.yearTo >= this.selectedFromOption.year) {

      // 2022 == 2022
      // when all year is same
      if(this.yearTo == this.selectedFromOption.year && this.yearTo == yearToDefault) {
        let date = false;
        let findCurMonth = false;

        data.forEach((res:any) => {
          // Q1 Q2 Q3 Q4
          if(res.months?.toString().includes(monthToDefault.toString())) {
            findCurMonth = true
            Object.assign(res,{"disabled": false})
          } else {
            if(!findCurMonth) {
              // != Q2
              if(res.value != this.selectedFromOption.value) {
                if(!date) {
                  Object.assign(res,{"disabled": true})
                } else {
                  Object.assign(res,{"disabled": false})
                }
              } else {
                Object.assign(res,{"disabled": false})
                date = true
              }
            } else {
              Object.assign(res,{"disabled": true})
            }
          }
        });
        date = false;
        findCurMonth = false;

      // when Toyear and selected from year both are same 
      } else if(this.yearTo == this.selectedFromOption.year) {
        let dat = false
        data.forEach((res:any) => {
          if(res.value == this.selectedFromOption.value) {
            dat = true
            Object.assign(res,{"disabled": false})
          } else {
            if(!dat) {
              Object.assign(res,{"disabled": true})
            } else {
              Object.assign(res,{"disabled": false})
            }
          }
        });
        dat = false
        // greater than of selected year
      } else if (this.yearTo > this.selectedFromOption.year) {
        
        if(this.yearTo == yearToDefault) {
          let dat = false
          data.forEach((res:any) => {
            if(res.months?.toString().includes(monthToDefault.toString())) {
              dat = true
              Object.assign(res,{"disabled": false})
            } else {
              Object.assign(res,{"disabled": false})
              if(!dat) {
              } else {
                Object.assign(res,{"disabled": true})
              }
            }
          });
          dat = false
        } else {
          data.forEach((res:any)=> {
            Object.assign(res, {"disabled": false})
          })
        }

      }

    } else if(this.yearTo < this.selectedFromOption.year) {
      data.forEach((res:any)=> {
        Object.assign(res, {"disabled": true})
      })
    }

    return data
  }

  addingQuarterwithYear(myData:any, year:any) {
    myData.forEach((res:any) => Object.assign(res,
      {"quarter,year": res.value + ' ' + year}
    ));
    return myData
  }

  dateFormatQuarter(arrData:any, year:any) {
    arrData.forEach((res:any)=> {
      if(res.value == 'Q1') {
        Object.assign(res,
          {"from_date": year+'-'+'01'+'-'+'01'},
          {"to_date": year+'-'+'03'+'-'+'31'},{"year": year})
      } else if (res.value == 'Q2') {
        Object.assign(res,
          {"from_date": year+'-'+'04'+'-'+'01'},
          {"to_date": year+'-'+'06'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q3') {
        Object.assign(res,
          {"from_date": year+'-'+'07'+'-'+'01'},
          {"to_date": year+'-'+'09'+'-'+'30'},{"year": year})
      } else if (res.value == 'Q4') {
        Object.assign(res,
          {"from_date": year+'-'+'10'+'-'+'01'},
          {"to_date": year+'-'+'12'+'-'+'31'},{"year": year})
      }
    })

    return arrData
  }


  closeCondition = false;

  onClose() {

    let selected:any = sessionStorage.getItem('TimeCycleQuarter');
    let previousFrom = JSON.parse(selected)

    if(previousFrom.from['quarter,year'] != this.selectedFromOption['quarter,year']) {

      this.yearFrom = previousFrom.from.year

      this.fromOption = [...this.changeYearWithQuarterFrom(this.fromOption)]
      this.fromOption= [...this.addingQuarterwithYear(this.fromOption, this.yearFrom)]
      this.fromOption= [...this.dateFormatQuarter(this.fromOption,this.yearFrom)]
      this.selectedFromOption = this.fromOption.find((res: any) => res.value == previousFrom.from.value)
    }
  }

  onCloseTo() {

    let selected:any = sessionStorage.getItem('TimeCycleQuarter');
    let previousFrom = JSON.parse(selected)

    if(previousFrom) {
      if(previousFrom.to['quarter,year'] != this.selectedToOption['quarter,year']) {

        this.yearTo = previousFrom?.to?.year

        this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
        this.toOption= [...this.addingQuarterwithYear(this.toOption, this.yearTo)]
        this.toOption= [...this.dateFormatQuarter(this.toOption,this.yearTo)]
        this.selectedToOption = this.toOption.find((res: any) => res.value == previousFrom.to.value)
      }
    }
  }

  onOpenTo() {
    this.toOption = [...this.changeYearWithQuarterTo(this.toOption)]
  }

  onOpen() {
    this.fromOption= [...this.changeYearWithQuarterFrom(this.fromOption)]
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
    if (this.parentControlSubscription) {
      this.parentControlSubscription.unsubscribe();
    }
  }
}