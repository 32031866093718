<div style="width:100%;height: 100%;">
    <main>
        <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: #fff;">
            <div style="background-color: #fff;">
                <icon-chart [headerConfig]="headerConfig" [iconList]="iconList" [data]="data" [pageKey]="pageKey"
                    [heading]="heading" [chartName]="'BulletChartDiv'" [config]="bulletChart" [expand]="fs"
                    [fullscreen]="isFullscreen" [item]="item" [exportcolumnDefs]="columnDefs"></icon-chart>
            </div>
            <div><loading *ngIf="isLoading"> </loading></div> 
            <div style="height: 200px; width: auto; " *ngIf="noData">
              <span class="bg-text" >There is no data for this time period</span></div>
            <div class="row mt-2" style="height: 100%;" #bulletChart  [style.display]="noData?'none':''">
                <div [class]=" item.config['chart_type']=='bullettable'?'col-6':'col-12'" style="padding-left: 15px !important;"  >
                    <div  style="width: 100%;"   #bulletChartContainer ></div>
                </div>
                <div class="col-6"   *ngIf="item.config['chart_type']=='bullettable'" style="padding-right: 20px !important;">
                    <ag-grid-angular style="width: 100%; height:70%; font-style: poppins !important;"
                        class="ag-theme-alpine bulletAggrid" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                        [rowHeight]="53" [headerHeight]="30" [rowData]="data" [suppressDragLeaveHidesColumns]="true"></ag-grid-angular>
                        <div class="card" style="padding: 14px 15px; background-color: #E8EAEE;    margin: 23px auto;">
                        <div style="margin-top: 6px;">
                            <span style="color: #101D4299;">Total Data Services:</span>
                            <span style="margin-right: 54px; color: #101D42; float: right; font-weight: 600;">{{ (enhancementService.currentQuarterFlag?0:coreAmount) | currency}}</span>
                        </div>
                        <div style="margin-top: 6px;">
                            <span style="color: #101D4299;">Other Services:</span>
                            <span *ngIf="!editData" style="color: #101D42;float: right;font-weight: 600;">
                                <span [ngStyle]="{'margin-right': enhancementService.currentQuarterFlag?'54px':'38px'}">{{(enhancementService.currentQuarterFlag?0:enhancedCount) | currency}}</span>
                                    <i  title="Edit" *ngIf="!enhancementService.currentQuarterFlag " class="iconFa fa fa-edit" (click)="editDatafn()" style="font-size: 15px;top:7px;color: red;"></i>
                            </span>

                            <span *ngIf="editData" class="float-right">
                                <i class="iconFa fa fa-dollar mr-1"></i>
                                <input style="width: 80px;border: 1px solid #E8EAEE;border-radius: 8px;margin-right: 12px;" type="number"  [(ngModel)]="enhancedCount" (change)="getInputData($event)"/>
                                <i *ngIf="editData" title="Cancel" class="iconFa fa fa-times" (click)="editData = false" style="font-size: 15px;top:7px;color: red;"></i> &nbsp;
                                <i *ngIf="editData" title="Apply" class="iconFa fa fa-check" (click)="saveData()" style="font-size: 15px;top:7px;color: green;"></i>
                            </span>
                        </div>
                        <div style="margin-top: 6px;">
                            <span style="color: #101D4299;">Total Payout:</span>
                            <span style="margin-right: 54px;color: #101D42;float: right;font-weight: 600;">{{(enhancementService.currentQuarterFlag?0:totalPayout) | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div #bulletChartTooltip>
            <div id="d3bulletTooltip">
                <!-- <div  style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize"> {{mytooltipData?.text}}</div> -->
                  <div class="container chart_tooltip" *ngIf="tooltip" >
                    <div class="row chart_tooltip_header">
                      <div class="col-12 d-flex flex-column">
                        <div class="chart_tooltip_header_heading mt-1">
                          {{this.item.config['chart_type']=='bullet'? mytooltipData?.file_type_name : mytooltipData?.file_type }}
                        </div>
                       
                      </div>
                    </div>
                    <div class="mt-2 mb-5" [ngClass]="(dataTurn < 250 && this.item.config['chart_type']=='bullet') ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
                      <div class=" mt-1 pb-1" >
                        <div class="w-100 d-flex   align-items-left" *ngIf="this.props.bar">
                         
                          <div>
                            <div class="tooltip_outlay" [style.background]=" (mytooltipData?.buffer_records < mytooltipData?.actual_records ) ?'#D11044':'#1360D8'" ></div>
                          </div>
                          <div class="chart_tooltip_body_data ml-2">{{"CURRENT" }} : {{this.item.config['chart_type']=='bullettable'?numberFormating(mytooltipData?.amount_payable): numbedPipe(mytooltipData?.actual_records)}}</div>
                          
                        </div>
                        <div class="w-100 d-flex    align-items-left">
                         
                            <div>
                              <div class="tooltip_outlay" [style.background]="'#FF9533'" ></div>
                            </div>
                            <div class="chart_tooltip_body_data ml-2">{{ this.item.config['chart_type']=='bullettable'?"MAX ":"BUFFER " }} : {{this.item.config['chart_type']=='bullettable'?numberFormating(mytooltipData?.max_payment) : numbedPipe(mytooltipData?.buffer_records)}}</div>
                            
                          </div>
                          <div class="w-100 d-flex   align-items-left"  *ngIf=" this.item.config['chart_type']=='bullet'">
                         
                            <div>
                              <div class="tooltip_outlay" [style.background]="'#E7EFF8'" ></div>
                            </div>
                            <div class="chart_tooltip_body_data ml-2">{{"MAX" }} : {{numbedPipe(mytooltipData?.max_records)}}</div>
                            
                          </div>
                      </div>
                    </div>
          
                  </div>
                </div> 
              </div>
        </article>
    </main>
</div>