<div class="container chart_tooltip">
  <div class="row chart_tooltip_header">
    <div class="col-12 chart_tooltip_header_heading pt-1 pb-1" *ngIf="date">
      {{tooltipHead ? tooltipHead : 'Quantity Shipped'}} ({{tooltipData[0] | date: 'dd-MMM-yyyy'}})
    </div>
    <div class="col-12 chart_tooltip_header_heading pt-1 pb-1" *ngIf="!date">
      {{tooltipHead ? tooltipHead : 'Quantity Shipped'}} ({{tooltipData[0]}})
    </div>
  </div>
  <div class="mt-2 mb-5 chart_tooltip_body">
    <div class="row pb-1">
      <div class="col-12 d-flex m-auto  align-items-center">
        <div>
          <div class="tooltip_outlay" [style.background]="tooltipData[2] ? tooltipData[2] : 'red'"></div>
        </div>
        <div class="chart_tooltip_body_data ml-2">
          {{numbedPipe(tooltipData[1])}}
        </div>
      </div>
    </div>
  </div>

</div>