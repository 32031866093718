import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as topojson from 'topojson';
import { FilterService } from 'src/app/services/filter.service';
import { BubblemapLineChartComponent } from '../bubblemap-line-chart/bubblemap-line-chart.component';
import { NewFilterService } from 'src/app/services/new-filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})
export class BubbleChartComponent implements OnInit, OnChanges {
  @ViewChild('lineChartContainer', { static: true }) lineChartContainer!: ElementRef
  @Input('item') item: any
  @Input('data') data: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @Input('pageKey') pageKey: any
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  bubbleData: any = [];
  props: any = {};
  iconList: any
  // chartHeight: number = 500
  renderLinePopup: boolean = false
  isActive = false;
  lineColour: any
  divId: any = 'bubbleMapDiv';
  selectedType = 'D'
  sourceName: any; locationName: any
  timeCycleData: any = []
  sName: any
  lName: any
  linecolor: any
  dataTurn: any
  tooltip = false
  initiateChart: boolean = false
  showBubblePopup: boolean = false
  bubbleTooltipData: any;
  currentLegendSelections: any = [];
  showBy: any
  constructor(private filterService: FilterService, private lineBubbleChart: BubblemapLineChartComponent, private newFilterService: NewFilterService) {
    this.newFilterService.showBy.subscribe((value: any) => {
      this.showBy = value
      // this.getBubbleChartData()
    })
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == false) {
      this.close();
    }
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
      this.close();
    }
  }
//loade Method
isLoading = false;
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() { this.isLoading = true;}
  
  openTooltip(sourceName: any, locationName: any, lineColour: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {

    if (this.renderLinePopup == true) {
      return undefined
    }

    this.selectedType = this.item.config?.time_cycle ? this.item.config.time_cycle[0].value : this.selectedType

    let hei = 0
    this.dataTurn = 0

    this.sourceName = sourceName
    this.locationName = locationName
    this.lineColour = lineColour
    this.dataTurn = chartWidth - myX

    hei = chartHeight - myY

    if (hei < 100) {
      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('bottom', hei + 'px')
        .style('top', 'unset')
    }
    else if (hei > 100) {

      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 'px')
        .style('bottom', 'unset')
    }

    if (this.dataTurn < 800) {
      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('right', (this.dataTurn) + 'px')
        .style('left', 'unset')
    }
    else if (this.dataTurn > 800) {

      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('left', (myX + 20) + 'px')
        .style('right', 'unset')
    }


    if (this.isFullscreen == true) {
      if (hei > 100) {
        d3.select("#d3BarTooltip")
          .style('top', myY + 'px')
      }
      else if (hei < 100) {
        d3.select("#d3BarTooltip")
          .style('top', myY - 150 + 'px')
      }
    } else {
    }
    this.renderLinePopup = true
  }

  closeFullscreen(): void {
    this.props.chartHeight = 500
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  close() {
    this.renderLinePopup = false
    // this.lineChartContainer.nativeElement.click();
  }


  private showTooltip(myType: any, myX: any, myY: any, myData: any, chartWidth: any, chartHeight: any, color: any): void {
    const obj = [myData.locationName, myData.quantityShipped + '/' + myData.sourceTotalQuantityShipped, color]
    this.bubbleTooltipData = obj

    d3.select("#d3BubbleTooltip")
      .style('visibility', 'visible')
      .style('position', 'absolute')
      .style('top', (myY + 5) + 'px')
      .style('left', (myX + 15) + 'px')
      .style('z-index', '9999')

    this.showBubblePopup = true

  }
  private hideTooltip(myType: any): void {
    this.showBubblePopup = false

  }
  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.timeCycleData = this.item.config?.time_cycle ? this.item.config.time_cycle : this.timeCycleData
    this.selectedType = this.item.config?.time_cycle ? this.item.config.time_cycle[0].value : this.selectedType
    this.initiateCharts()

    window.addEventListener('orientationchange', (event: any) => {
      this.getBubbleChartData()
      if (window.orientation === 0 || window.orientation === 180) {
        // Portrait orientation
        // Call your specific portrait mode function or perform actions
      } else if (window.orientation === 90 || window.orientation === -90) {
        // Landscape orientation
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getBubbleChartData();
      this.currentLegendSelections = [];
    }
  }



//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const width = mySection.clientWidth;
    const height = 500; // 500 is random height, height is fixed to data later on
    const svg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .attr('height', height);

    svg.append('rect').attr('class', 'mapBackground' + myClass);
    svg.append('rect').attr('class', 'legendBackground' + myClass);
    svg.append('rect').attr('class', 'legendSizeRect' + myClass);
    svg.append('line').attr('class', 'legendSizeLine' + myClass);
    svg.append('text').attr('class', 'title' + myClass + ' sourcesTitle' + myClass);

    const defs = svg.append('defs');

    defs.append('clipPath').attr('id', 'legendClip')
      .append('rect').attr('id', 'legendClipRect' + myClass);

    defs.append('clipPath').attr('class','mapClipPath' + myClass)
      .attr('id','mapClipPath')
      .append('rect').attr('class', 'mapClipRect' + myClass);

    const baseSvg = svg.append('g').attr('class', 'baseSvg' + myClass);
    const mapGroup = baseSvg.append('g').attr('class', 'mapGroup' + myClass);
    const mapPathsGroup = mapGroup.append('g').attr('class', 'mapPathsGroup' + myClass);
    mapGroup.append('g').attr('class', 'bubblesGroup' + myClass);

    mapPathsGroup.append('path').attr('class',  'puertoRicoPath' + myClass);

    const tooltipGroup = svg.append('g').attr('id', 'tooltipGroup' + myClass);

    tooltipGroup.append('rect').attr('class',  'wrapTooltipRect' + myClass);
    tooltipGroup.append('text').attr('class',  'wrapTooltipLabel' + myClass);

    const zoomButtonGroup = svg.append('g').attr('id', 'zoomButtonGroup' + myClass);

    zoomButtonGroup.append('rect').attr('class',  'zoomRectWhite' + myClass + ' refreshRect' + myClass);
    zoomButtonGroup.append('rect').attr('class',  'zoomRectWhite' + myClass + ' buttonRect' + myClass);
    zoomButtonGroup.append('rect').attr('class',  'zoomRectBlue' + myClass + ' inButton' + myClass);
    zoomButtonGroup.append('rect').attr('class',  'zoomRectBlue' + myClass + ' outButton' + myClass);
    zoomButtonGroup.append('svg').attr('class',  'refreshIconSvg' + myClass)
      .append('path').attr('class', 'refreshIconPath' + myClass);
    zoomButtonGroup.append('svg').attr('class',  'inIconSvg' + myClass)
      .append('path').attr('class', 'inIconPath' + myClass);
    zoomButtonGroup.append('svg').attr('class',  'outIconSvg' + myClass)
      .append('path').attr('class', 'outIconPath' + myClass);
    zoomButtonGroup.append('text').attr('class',  'zoomValueLabel' + myClass);
    this.initiateChart = true
  }
  // chart svg  plotChart rendering 
  plotChart(): void {

    const myChart = this;
    const myClass = myChart.divId;
    const svg: any = d3.select('#svg_' + myClass);
    const baseSvg = d3.select('.baseSvg' + myClass);
    const mapGroup = d3.select('.mapGroup' + myClass);
    const width = svg.node().getBoundingClientRect().width;
    const height =  myChart.props.chartHeight;
    let legendWidth = 308;
    let margins = {legend: 16, middle: 16, top: 0, tooltip: 10};
    let zoomButtonPanelWidth = 169;
    const zoomButtonPanelHeight = 36;
    if(myChart.props.legendTop){
      legendWidth = 0;
      margins.legend = 0;
      margins.top = 30;
      margins.middle = 0;
    }
    svg.attr("height", height);
    const plusIconPath = 'M18.6364 8.18184H11.8182V1.36364C11.8182 0.610908 11.2073 0 10.4545 0H9.54548C8.79275 0 8.18184 0.610908 8.18184 1.36364V8.18184H1.36364C0.610908 8.18184 0 8.79275 0 9.54548V10.4545C0 11.2073 0.610908 11.8182 1.36364 11.8182H8.18184V18.6364C8.18184 19.3891 8.79275 20 9.54548 20H10.4545C11.2073 20 11.8182 19.3891 11.8182 18.6364V11.8182H18.6364C19.3891 11.8182 20 11.2073 20 10.4545V9.54548C20 8.79275 19.3891 8.18184 18.6364 8.18184Z';
    const minusIconPath = 'M14.9091 1.85966e-05H9.45453C9 4.32323e-05 8.9658 0 8.36362 0L7.63638 1.85966e-05C7.0342 1.85966e-05 7 1.85967e-05 6.54547 1.85966e-05H1.09091C0.488726 1.85966e-05 0 0.488746 0 1.09093V1.81816C0 2.42035 0.488726 2.90907 1.09091 2.90907H6.54547C7 2.90907 7.0342 2.90929 7.63638 2.90929H8.36362C8.9658 2.90929 9 2.90907 9.45453 2.90907H14.9091C15.5113 2.90907 16 2.42035 16 1.81816V1.09093C16 0.488746 15.5113 1.85966e-05 14.9091 1.85966e-05Z';
    const refreshIconPath = 'M16.3662 3.63824L17.0735 2.93138V2.93138L16.3662 3.63824ZM20 8V9C20.5523 9 21 8.55228 21 8H20ZM19.609 12.7773C19.7622 12.2466 19.4561 11.6923 18.9255 11.5392C18.3948 11.3861 17.8406 11.6921 17.6874 12.2227L19.609 12.7773ZM21 2C21 1.44772 20.5523 1 20 1C19.4477 1 19 1.44772 19 2H21ZM14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9V7ZM0 10C0 15.5228 4.47715 20 10 20V18C5.58172 18 2 14.4183 2 10H0ZM10 0C4.47715 0 0 4.47715 0 10H2C2 5.58172 5.58172 2 10 2V0ZM17.0735 2.93138C15.2648 1.12139 12.7624 0 10 0V2C12.2104 2 14.2098 2.89514 15.6588 4.34511L17.0735 2.93138ZM15.6588 4.34511C16.4319 5.11872 17.3119 6.17306 18.0111 7.05263C18.3577 7.4887 18.6545 7.87487 18.8643 8.15174C18.9692 8.29008 19.0522 8.40091 19.1087 8.47676C19.1369 8.51468 19.1585 8.54384 19.1729 8.56331C19.1801 8.57304 19.1855 8.58035 19.189 8.58512C19.1908 8.58751 19.192 8.58925 19.1929 8.59035C19.1933 8.5909 19.1935 8.59129 19.1937 8.59151C19.1938 8.59162 19.1938 8.59169 19.1939 8.59172C19.1939 8.59174 19.1939 8.59173 19.1939 8.59173C19.1939 8.59172 19.1938 8.59169 20 8C20.8062 7.40831 20.8061 7.40826 20.8061 7.4082C20.8061 7.40817 20.806 7.4081 20.806 7.40803C20.8059 7.40789 20.8057 7.40771 20.8056 7.40749C20.8052 7.40705 20.8048 7.40644 20.8042 7.40567C20.8031 7.40413 20.8015 7.40194 20.7994 7.39911C20.7952 7.39344 20.7892 7.38521 20.7813 7.37454C20.7655 7.35319 20.7424 7.32207 20.7127 7.28211C20.6532 7.2022 20.5668 7.08687 20.4582 6.94364C20.2412 6.65737 19.9349 6.25871 19.5767 5.80809C18.8662 4.91428 17.9293 3.78774 17.0735 2.93138L15.6588 4.34511ZM10 20C14.5605 20 18.4055 16.9481 19.609 12.7773L17.6874 12.2227C16.7243 15.5605 13.6457 18 10 18V20ZM19 2V8H21V2H19ZM20 7H14V9H20V7Z';
    const zoomViewBox = '0 0 20 20';
    const refreshViewBox = '0 0 21 20';

    let sourceSet: any = new Set();

    myChart.bubbleData.data.forEach((d: any) => sourceSet.add(d[myChart.props.sourceVar]));
    sourceSet = Array.from(sourceSet);
    if (myChart.props.newLegendSelections.length != 0) {
      myChart.currentLegendSelections = myChart.props.newLegendSelections;
    } else {
      myChart.currentLegendSelections = sourceSet;
    }
    const lineColors: any = {};
    sourceSet.forEach((d: any, i: any) => lineColors[d] = myChart.props.colors[i])
    const chartData: any = myChart.bubbleData.data;
    let cityData: any =  myChart.bubbleData.cityCoords;
    cityData.map((m: any) => m.LocationName = m.city.toUpperCase() + ', ' + m.state_id);

    const bubbleData: any = [];
    const groupedBySource = Array.from(d3.group(chartData, (g: any) => g[myChart.props.sourceVar]));
    groupedBySource.forEach((d: any) => {
      const groupedByCity = Array.from(d3.rollup(d[1], (r: any) => d3.sum(r, (s: any) => s[myChart.props.bubbleVar]), (g: any) => g[myChart.props.locationVar]));
      groupedByCity.forEach((c: any) => {
        const matchingCity: any = cityData.find((f: any) => f.LocationName === c[0].toUpperCase());
        if(matchingCity === undefined){
          console.log('This location is not matched in the cityCoords data: ' + c[0])
        } else {
          bubbleData.push({
            sourceName: d[0],
            locationName: c[0],
            quantityShipped: c[1],
            coordinates: [matchingCity.lng, matchingCity.lat],
            sourceTotalQuantityShipped: d3.sum(chartData.filter((f: any) => f[myChart.props.sourceVar] === d[0]), (s: any) => s[myChart.props.bubbleVar]),
            locationTotalQuantityShipped: d3.sum(chartData.filter((f: any) => f[myChart.props.locationVar] === c[0]), (s: any) => s[myChart.props.bubbleVar])
          })
        }
      })
    })
    const valueExtent: any = d3.extent(bubbleData, (d: any) => d.quantityShipped);
    const tickScale = d3.scaleLinear().domain(valueExtent);
    const bubbleThresholds: any = tickScale.ticks(6);
    const rangeCount = bubbleThresholds.length + 1;
    const tickLength = (myChart.props.bubbleRange[1] - myChart.props.bubbleRange[0])/bubbleThresholds.length;
    let bubbleRange: any = [], currentTick = myChart.props.bubbleRange[0];
    for(let i: any = 0 ; i < rangeCount ; i++){
      bubbleRange.push(currentTick);
      currentTick += tickLength;
    }
    const bubbleScale = d3.scaleThreshold().domain(bubbleThresholds).range(bubbleRange);

    svg.select('.mapBackground' + myClass)
      .attr('width',width - legendWidth - margins.middle)
      .attr('height',height - margins.top)
      .attr('fill', '#DAE1EC')
      .attr('rx' ,12)
      .attr('ry', 12)
      .attr('y', margins.top);

    svg.select('.legendBackground' + myClass)
      .attr('x',width - legendWidth - 4)
      .attr('y', 1)
      .attr('width',myChart.props.legendTop ? 0 : legendWidth- 4)
      .attr('height',height - 2)
      .attr('stroke-width', '1px')
      .attr('stroke', '#E8EAEE')
      .attr('fill', 'white')
      .attr('rx' ,12)
      .attr('ry', 12);

    svg.select('#legendClipRect' + myClass)
      .attr('x',width - legendWidth - 1)
      .attr('y', 1)
      .attr('stroke-width', '1px')
      .attr('width', myChart.props.legendTop ? 0 :legendWidth- 2.5)
      .attr('height',height - 2.5)
      .attr('rx' ,12)
      .attr('ry', 12);

    svg.select('.legendSizeRect' + myClass)
      .attr('clip-path', 'url(#legendClip)')
      .attr('width',myChart.props.legendTop ? 0 : legendWidth- 2.5)
      .attr('height',145.5)
      .attr('fill', '#f8fafd')
      .attr('x',width - legendWidth -0.5)
      .attr('y', height);

    svg.select('.legendSizeLine' + myClass)
      .attr('x1',myChart.props.legendTop ? 0 :width - legendWidth - 0.5)
      .attr('x2',myChart.props.legendTop ? 0 :width  - 2.5)
      .attr('stroke-width', '1px')
      .attr('stroke', '#E8EAEE')
      .attr('y1', height)
      .attr('y2', height);

    svg.select('.sourcesTitle' + myClass)
      .attr('x', width - legendWidth - 2 + margins.legend)
      .attr('y', margins.legend + 6)
      .text(myChart.props.legendTop ? "" :myChart.props.sourcesTitle);

    svg.select('.wrapTooltipRect' + myClass)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('height', 15)
      .attr('fill',  '#343435');

    svg.select('.wrapTooltipLabel' + myClass)
      .attr('font-family', 'Poppins')
      .attr('font-weight', '500')
      .attr('font-size', '10')
      .attr('fill',  'white')
      .text('fill');


    svg.select('#zoomButtonGroup' + myClass)
      .attr('transform', 'translate(' + (width - legendWidth - margins.middle - 16 - zoomButtonPanelWidth) +
      ',' + (height - zoomButtonPanelHeight - 16 ) + ')');

    svg.selectAll('.zoomRectWhite' + myClass)
      .attr('width' , zoomButtonPanelHeight)
      .attr('height', zoomButtonPanelHeight)
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('fill', 'white')
      .on('click', function(){
        // @ts-ignore
        baseSvg.call(zoom.transform, d3.zoomIdentity);
        currentZoomLevel = 100;
      });

    svg.selectAll('.buttonRect' + myClass)
      .attr('x', zoomButtonPanelHeight + 7)
      .attr('width', 126)

    let currentZoomLevel: any = 100;
    svg.selectAll('.inButton' + myClass)
      .attr('x', zoomButtonPanelHeight + 7  + zoomButtonPanelHeight + 58)
      .attr('y', 4)
      .on('click', function(){
        currentZoomLevel += 10;
          // @ts-ignore
          zoom.scaleBy(baseSvg, 1.1);
          svg.select('.zoomValueLabel' + myClass).text(currentZoomLevel + '%');
      });

    svg.selectAll('.outButton' + myClass)
      .attr('x', zoomButtonPanelHeight + 7 + 4)
       .attr('y', 4)
      .on('click', function(){
        if(currentZoomLevel > 100){
          currentZoomLevel -= 10;
        }
        // @ts-ignore
        zoom.scaleBy(baseSvg, 0.9);
        svg.select('.zoomValueLabel' + myClass).text(currentZoomLevel + '%');
      });


    svg.selectAll('.zoomRectBlue' + myClass)
      .attr('width' , 28)
      .attr('height', 28)
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('fill', '#1363DF');

    svg.select('.inIconPath' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('fill', 'white')
      .attr( 'd', plusIconPath);

    svg.select('.inIconSvg' + myClass)
      .attr('x', zoomButtonPanelHeight + 7  + zoomButtonPanelHeight + 58 + 5)
      .attr('y', 4  + 5)
      .attr( 'pointer-events', 'none')
      .attr('viewBox', zoomViewBox)
      .attr( 'width', 18)
      .attr( 'height', 18);

    svg.select('.outIconPath' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('fill', 'white')
      .attr( 'd', minusIconPath);

    svg.select('.outIconSvg' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('x', zoomButtonPanelHeight + 7 + 6 + 5)
      .attr('y', 4 + 7 + 5)
      .attr('viewBox', zoomViewBox)
      .attr( 'width', 18)
      .attr( 'height', 18);

    svg.select('.refreshIconPath' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('fill', '#1363DF')
      .attr( 'd', refreshIconPath);

    svg.select('.refreshIconSvg' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('x',  4 + 5)
      .attr('y', 4 + 5)
      .attr('viewBox', refreshViewBox)
      .attr( 'width', 18)
      .attr( 'height', 18);

    svg.select('.zoomValueLabel' + myClass)
      .attr( 'pointer-events', 'none')
      .attr('x', zoomButtonPanelHeight + 7 + 63)
      .attr('y', (zoomButtonPanelHeight/2) + 4.5)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1363DF')
      .attr('font-size', '12px')
      .attr('font-weight', '700')
      .text('100%')

    if(myChart.props.legendTop){
      drawLegendTop();
    } else {
      drawSourceLegendRight();
    }

    const mapWidth = width - margins.middle - legendWidth;
    const mapHeight = height - zoomButtonPanelHeight - 40 - margins.top;

    const zoom = d3.zoom()
      .extent([[0, 0], [mapWidth, mapHeight]])
      .scaleExtent([1, 8])
      .translateExtent([[0, 0], [mapWidth, mapHeight]])
      .on('zoom', zoomed);

    svg.select('.mapClipRect' + myClass)
      .attr('width', mapWidth)
      .attr('height', mapHeight + 40);

    baseSvg
      .attr("transform", "translate(0," + margins.top + ")")
      .attr('clip-path', 'url(#mapClipPath)');

    // @ts-ignore
    baseSvg.call(zoom);

    const states = myChart.bubbleData.statesTopojson
    const feature: any = topojson.feature(states, states.objects.states_20m_2017);
    //@ts-ignore
    const projection: any = d3.geoAlbersUsa().fitSize([mapWidth - 40, mapHeight], feature);
    const path: any = d3.geoPath().projection(projection);

    const pRProjection =  d3.geoConicEqualArea()
      .rotate([66, 0])
      .center([0, 18])
      .parallels([8, 18])
      .fitSize([mapWidth - 40, mapHeight], feature);

    const pRPath: any = d3.geoPath().projection(pRProjection);

    svg.select('.puertoRicoPath' + myClass)
      .attr('fill', 'white')
      .attr('d', pRPath( feature.features.find((f: any) => f.properties.NAME === 'Puerto Rico')))

   const puertoRicoCoordinates = pRPath.centroid(feature.features.find((f: any) => f.properties.NAME === 'Puerto Rico'));

    const mapStatesGroup = svg
      .select('.mapPathsGroup' + myClass)
      .selectAll('.mapStatesGroup' + myClass)
      .data(feature.features)
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'mapStatesGroup' + myClass);
        enter.append('path').attr('class', 'statePath');
        enter.append('text').attr('class', 'statePathLabel');
        return enter;
      });

    mapStatesGroup.select('.statePath')
      .attr('fill', 'white')
      .attr('stroke', '#D9DDE4')
      .attr('d', path)
      .on('mouseover', function() {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject.parentElement).raise();
        d3.select(myObject).attr('stroke', '#1363DF');
      }) .on('mouseout', () => {
      d3.selectAll('.statePath').attr('stroke', '#D9DDE4');
    });

    mapStatesGroup.select('.statePathLabel')
      .attr("x", (d: any) =>  d.properties.NAME === 'Puerto Rico' ? puertoRicoCoordinates[0] :path.centroid(d)[0] + (d.properties.STUSPS === "FL" ? 10 : 0))
      .attr("y", (d: any) =>  d.properties.NAME === 'Puerto Rico' ? puertoRicoCoordinates[0] :path.centroid(d)[1])
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('font-size',10)
      .attr('font-weight', '300')
      .attr('fill', '#8A98AB')
      .text((d: any) => d.properties.STUSPS)


    bubbleData.map((m: any) => m.fontSize = getFontSize(d3.format(',')(m.quantityShipped), bubbleScale(m.quantityShipped) * 2));

    const bubbleGroup = svg
      .select('.bubblesGroup' + myClass)
      .selectAll('.bubbleGroup' + myClass)
      .data(bubbleData)
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'bubbleGroup' + myClass);
        enter.append('circle').attr('class', 'shipmentBubble');
        enter.append('text').attr('class', 'shipmentBubbleLabel');
        return enter;
      });

    bubbleGroup
       .attr('id', (d: any) => 'bubblesourcegroup' + sourceSet.indexOf(d.sourceName));

    bubbleGroup.select('.shipmentBubble')
      .attr('display', (d: any) => myChart.currentLegendSelections.indexOf(d.sourceName) > -1 ? 'block' : 'none')
      .attr('id', (d: any) => 'bubblesource' + sourceSet.indexOf(d.sourceName))
      .attr('r', (d: any) => bubbleScale(d.quantityShipped))
     // .attr("r", 1)
      .attr('fill', (d: any) => lineColors[d.sourceName])
      .attr('stroke', (d: any) => lineColors[d.sourceName])
      .attr('cursor','pointer')
      .on('click', function(event: any, d: any){
         myChart.openTooltip(d.sourceName, d.locationName, lineColors[d.sourceName], event.offsetX, event.offsetY, width, height);
      })
      .on('mouseover', function(event:any, d:any) {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject.parentElement).raise();
        d3.select(myObject).attr('stroke', '#1363DF');
        myChart.showTooltip('bubble', event.offsetX, event.offsetY, d, width, height, lineColors[d.sourceName]);
        svg.selectAll('#bubblesourcegroup' + sourceSet.indexOf(d.sourceName)).raise();

      }) .on('mouseout', () => {
        myChart.hideTooltip('bubble');
      svg.selectAll('.shipmentBubble').attr('stroke', (d: any) => lineColors[d.sourceName]);
    });

    bubbleGroup.select('.shipmentBubbleLabel')
      .attr('display', (d: any) => myChart.currentLegendSelections.indexOf(d.sourceName) > -1 ? 'block' : 'none')
      .attr('id', (d: any) => 'bubblesource' + sourceSet.indexOf(d.sourceName))
      .attr('y', (d: any) => (d.fontSize * 0.4) )
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('font-size', (d: any) => d.fontSize)
      .attr('font-weight', '500')
      .attr('fill', 'white')
      .text((d: any) => d3.format(',.0f')(d.quantityShipped))

    d3.forceSimulation(bubbleData)
     .force("collision", d3.forceCollide((d: any) => bubbleScale(d.quantityShipped) * 1.05))
      .force("x", d3.forceX((d: any) => (projection(d.coordinates) === null ? puertoRicoCoordinates[0] : projection(d.coordinates)[0])).strength(0.2))
      .force("y", d3.forceY((d: any) => (projection(d.coordinates) === null ? puertoRicoCoordinates[1] : projection(d.coordinates)[1])).strength(0.2))
      .on("tick", ticked);

    function ticked(){

      bubbleGroup
         .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')

    }

    function zoomed(event: any) {
      const {transform} = event;
      mapGroup.attr('transform', transform);
      mapGroup.attr('stroke-width', 1 / transform.k);
      svg.select('.zoomValueLabel' + myClass).text(parseInt(String(transform.k * 100),10) + '%');
    }

    function drawLegendTop(): void {

      const sourceData: any = [];

      Object.keys(lineColors).forEach((d: any, i: any) =>{
        const label = d.length > 30 ? d.substr(0, 30).trim() + '..' : d;
        sourceData.push({
          id: i,
          value: d,
          fill: lineColors[d],
          label: label,
          length: measureWidth(d.toUpperCase(), 12),
          valueLength:  measureWidth(label.toUpperCase(), 12)
        })
      })
      // draw legend group
      const legendGroup = svg
        .selectAll('.legendGroupTop' + myClass)
        .data(sourceData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroupTop' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + String(d.id).replace(/ /g, '') + myClass)
        .attr('cy', margins.top * 0.3)
        .attr('fill', (d: any) => d.fill)
        .attr('r', 4)
        .on('click',sourceLegendClick);;

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + String(d.id).replace(/ /g, '') + myClass)
        .attr('y', margins.top * 0.3 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) => String(d.value).toUpperCase())
        .on('click',sourceLegendClick);

      // legend functionality repeated through the project
      // legendX is the starting x position
      let legendX = 30;
      svg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        // set this x and circle x
        d3.select(myObject).attr('x', legendX);
        svg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        // calculate the width of the label and reset the legendX for next item
        legendX += (40 + measureWidth(String(d.value).toUpperCase(),12));
      });
      // centre legend across screen
      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }

    function sourceLegendClick(event: any, d: any){
      if(myChart.currentLegendSelections.indexOf(d.value) === -1){
        myChart.currentLegendSelections.push(d.value);
        svg.selectAll('#source' + d.id).attr('opacity', 1);
        svg.selectAll('#bubblesource' + d.id).attr('display', 'block');
      } else {
        myChart.currentLegendSelections = myChart.currentLegendSelections.filter((f: any) => f !== d.value);
        svg.selectAll('#source' + d.id).attr('opacity', 0.2);
        svg.selectAll('#bubblesource' + d.id).attr('display', 'none');
      }
    }
    function drawSourceLegendRight(){
      const legendRectWidth = legendWidth - 4;
      const legendMargin = 10;
      const halfLegend = (legendRectWidth - legendMargin)/2;
      const sourceRadius = 6;
      const sourceSpacing = 30;
      const textWidth = halfLegend - legendMargin - (sourceRadius * 2);
      const sourceData: any = [];
      const sources = lineColors;
      const sourceHeight = height  - (margins.legend * 2);

      Object.keys(sources).forEach((d: any, i: any) =>{
        const label = d.length > 30 ? d.substr(0, 30).trim() + '..' : d;
        sourceData.push({
          id: i,
          value: d,
          fill: sources[d],
          label: label,
          length: measureWidth(d.toUpperCase(), 12),
          valueLength:  measureWidth(label.toUpperCase(), 12)
        })
      })

      const sourceGroup = svg
        .selectAll('.sourceGroup' + myClass)
        .data(sourceData)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'sourceGroup' + myClass);
          enter.append('circle').attr('class', 'sourceCircle');
          enter.append('text').attr('class', 'sourceLabel');
          return enter;
        });

      let moveLeftIndex: any = null;
      sourceGroup
        .attr('transform', (d: any, i: any) => {
        let transformY: any = 50 + (i * sourceSpacing);
        let transformX = 0;
        if((transformY + sourceRadius) > sourceHeight){
          transformX = (legendRectWidth/2) - legendMargin;
          if(moveLeftIndex === null){moveLeftIndex = i}
          transformY = 50 + ((i - moveLeftIndex) * sourceSpacing);
        }
          return 'translate(' + transformX + ',' + transformY + ')';
      })
      sourceGroup.select('.sourceCircle')
        .attr('opacity', (d: any) => myChart.currentLegendSelections.indexOf(d.value) > -1 ? 1 : 0.2)
        .attr('id', (d: any) => 'source' + d.id)
        .attr('r', sourceRadius)
        .attr('fill', (d: any) => d.fill)
        .attr('cx', width - legendWidth + margins.legend + sourceRadius)
        .attr('cursor', 'pointer')
        .on('click',sourceLegendClick);


      sourceGroup.select('.sourceLabel')
        .attr('opacity', (d: any) => myChart.currentLegendSelections.indexOf(d.value) > -1 ? 1 : 0.2)
        .attr('id', (d: any) => 'source' + d.id)
        .attr('fill', '#101D42')
        .attr('font-size', '12px')
        .attr('font-weight', '500')
        .attr('x', width - legendWidth + margins.legend + (sourceRadius * 3) + 8)
        .attr('y',  4.5)
        .text((d: any) => measureWidth(d.label,12) < textWidth ? d.label : d.label.substr(0,16) + "..")
        .attr('cursor', (d: any) => measureWidth(d.label,12) < textWidth ? 'pointer' : 'default')
        .on('click',sourceLegendClick)
        .on('mousemove', (event: any, d: any) => {
          if (measureWidth(d.label,12) > textWidth){
            svg.select('.wrapTooltipRect' + myClass)
              .attr('width', d.valueLength + 5)
              .attr('x', (event.offsetX - 90) + 'px')
              .attr('y',( event.offsetY + 10 )+ 'px');

            svg.select('.wrapTooltipLabel' + myClass)
              .attr('x', (event.offsetX - 65) + 'px')
              .attr('y', (event.offsetY + 20.5) + 'px')
              .text(d.value);
          }
        })
        .on('mouseout', () => {
          svg.select('.wrapTooltipRect' + myClass)
            .attr('width', 0);
          svg.select('.wrapTooltipLabel' + myClass).text('');
        });

    }

    function getFontSize(myText: any, myCircumference: any){
      let fontSize = 16, itFits = false;
      while (itFits === false){
        const myWidth = measureWidth(myText, fontSize) + 4
        if(myWidth < myCircumference || fontSize < 8){
          itFits = true;
        } else {
          fontSize -= 1;
        }
      }
      return fontSize;

    }


    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
    this.stop(10).then(() => this.isLoading = false);
  }
  // property & data for the chart
  getBubbleChartData(): void {
    this.filterService.getSampleJsonData('DispenseTrendDataWeeklyBubble').subscribe((res: any) => {
      this.bubbleData = res
      this.bubbleData.data = this.data
      this.props = {
        colors: ["#8C23E6", "#E223E6", "#FFCD4A", "#645DD7", "gold", "gray", "purple", "orange","#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC"],
        bubbleRange: [16, 26],
        sourcesTitle: 'Sources',
        bubbleVar: this.showBy == "tablets" ?  this.config.value_column.toLowerCase() : "number_of_bottles",
        sourceVar: this.config.xAxisVar.toLowerCase(),
        locationVar:  this.config.yAxisVar.toLowerCase() ,
        chartHeight: 500,
        legendTop: false,
        newLegendSelections: this.currentLegendSelections
      }
      
      if (this.isFullscreen) {
        this.props.chartHeight = window.outerHeight - 60
      }
      if (this.bubbleData.data !== undefined && this.bubbleData.data.length > 0) {
        this.plotChart();
      } else {
        this.bubbleData.data = []
        this.plotChart();
      }

    })
  }

}
