<div style="width:100%;">

  <main>

    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">

      <!-- <button  mat-raised-button  color="primary" (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->

      <div>
        <icon-chart [headerConfig]="headerConfig" [iconList]="iconList" [data]="currentVisibleData" [pageKey]="pageKey"
          [heading]="heading" [chartName]="divId" [config]="BarChartContainer" [expand]="fs" [fullscreen]="isFullscreen"
          [item]="item">
        </icon-chart>
      </div>
      <div><loading *ngIf="isLoading"> </loading></div>
      
      <div [style.display]="noData?'none':''" id="barChart" #BarChartContainer style="width:100%;">
      </div>
      <div style="height: 500px; width: auto; " *ngIf="noData">
       <span class="bg-text" >There is no data for this time period</span></div>

      <div id="d3BarTooltip">
        <div class="container chart_tooltip" *ngIf="this.tooltipVisible">
          <div class="row chart_tooltip_header ">
            <div class="col-12 chart_tooltip_header_data mt-1 mb-1">
                Quantity Shipped ({{mytooltipData?.date | date: 'dd-MMM-yyyy'}})
            </div>
          </div>
          <div class="mt-1 mb-5 pb-2" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData.colours.movingAverage">
                  </div>
                </div>

                <div class="chart_tooltip_body_data ml-2"> Moving Average : {{mytooltipData?.movingAverage ?
                  numbedPipe(mytooltipData?.movingAverage) : 0 }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousMA == 'up'" />

                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousMA == 'down'" />
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex m-auto  align-items-center">
                <div>
                  <div class="tooltip_outlay" [style.background]="mytooltipData.colours.quantityShipped">
                  </div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> Quantity Shipped : {{mytooltipData?.quantityShipped ?
                  numbedPipe(mytooltipData?.quantityShipped) : mytooltipData?.quantityShipped }}
                </div>

                <div class="ml-2">
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-greenArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'up'" />
                  <img src="https://dudevtest-analytics-ui-s3.s3.amazonaws.com/assets/svgIcons/Vector-RedArrow.svg"
                    *ngIf="mytooltipData.comparedToPreviousQS == 'down'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
</div>